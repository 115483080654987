import { DateTime } from 'luxon';
import { SystemDateParser } from '../SystemDateParser';

export interface INonWorkingDay {
  dnl_id: string;
  dnl_fecha_no_laboral: string;
  dnl_activo: boolean;
}

export class NonWorkingDay {
  protected constructor(private id: string | null, private active: boolean, private date: DateTime) {}

  static identifiedBy(id: string | null, active: boolean, date: DateTime) {
    // Assertions aca
    return new this(id, active, date);
  }

  static buildFrom(NonWorkingDay: INonWorkingDay) {
    let parser = new SystemDateParser('dd/LL/yyyy');
    return this.identifiedBy(
        NonWorkingDay.dnl_id,
        NonWorkingDay.dnl_activo,
        parser.parse(NonWorkingDay.dnl_fecha_no_laboral)
    );
  }

  getId() {
    return this.id;
  }

  getDate() {
    return this.date;
  }

  getActive() {
    return this.active;
  }
}
