import * as React from 'react';
import { Announced } from '@fluentui/react/lib/Announced';
import { ITextFieldStyles } from '@fluentui/react/lib/TextField';
import {
  DetailsList,
  Selection,
  IColumn,
  CheckboxVisibility,
  SelectionMode,
} from '@fluentui/react/lib/DetailsList';
import { MarqueeSelection } from '@fluentui/react/lib/MarqueeSelection';
import { mergeStyles } from '@fluentui/react/lib/Styling';
import { routes } from '../../../common/constants';
import { DefaultButton, Icon, PrimaryButton } from 'office-ui-fabric-react';
import TimeTaxSupportSystemClient from '../../../system';
import MessageCourier from '../../../lib/MessageCourier';
import { IExampleItem } from '@fluentui/example-data';
import { Link } from '@fluentui/react';
import i18next, { t } from 'i18next';

const exampleChildClass = mergeStyles({
  display: 'block',
  marginBottom: '10px',
});

const textFieldStyles: Partial<ITextFieldStyles> = { root: { maxWidth: '300px' } };

export interface IDetailsListBasicExampleItem {
  key: number;
  name: string;
  value: number;
}

export interface IDetailsListBasicExampleState {
  items: IDetailsListBasicExampleItem[];
  selectionDetails: string;
  columns: IColumn[];
  announcedMessage?: string;
  isCalloutVisible: boolean;
}

interface INotificationsTable {
  notificationsList: any;
  notificationsFilteredList: any;
  getNotificationsFromTable: any;
  system?: TimeTaxSupportSystemClient;
  courier?: MessageCourier;
  projects?: any;
  toggleIsCalloutVisible: any;
  navigate: any;
  allSelected?: boolean | null;
  noneSelected?: boolean | null;
  onReadClick?: any;
  getActivityFromNotificacion: (act_id: string) => void;
  baseUrl: string;
}

export class NotificationsTable extends React.Component<INotificationsTable, IDetailsListBasicExampleState> {
  private _selection: Selection;
  private _allItems: IDetailsListBasicExampleItem[];
  //   private _columns: IColumn[];

  constructor(props: INotificationsTable) {
    super(props);

    this._selection = new Selection({
      onSelectionChanged: () => {
        this.setState({ selectionDetails: this._getSelectionDetails() });
      },
    });

    // Populate with items for demos.
    this._allItems = this.props.notificationsList;
    // for (let i = 0; i < this.props.data.length; i++) {
    //   this._allItems.push({
    //     key: i,
    //     name: 'Item ' + i,
    //     value: i,
    //   });
    // }

    const formatActivo = (item: any) => {
      if (item.not_activo === '1') {
        return <Icon iconName="CheckMark" className="color-success" />;
      } else {
        return <Icon iconName="Cancel" className="color-error" />;
      }
    };

    const formatLeido = (item: any) => {
      if (item.not_leido) {
        return <Icon iconName="CheckMark" className="color-success" />;
      } else {
        return <Icon iconName="Cancel" className="color-error" />;
      }
    };

    const redirectToNotification = (not: any) => {
      if (not) {
        let redirect = routes.calendar + '?' + not.not_entidad + '=' + not.not_entidad_id;
        return (
            <PrimaryButton
                text={t('Ver')}
                onClick={(e) => {
                  // props.navigate(redirect);
                  // props.onReadClick(not.not_id);
                  if (not.not_entidad == 'Actividad') {
                    props.getActivityFromNotificacion(not.not_entidad_id);
                  }
                }}
            />

            // <Link to={''} className="text-bold" onClick={(e) => props.navigate(redirect)}>
            //   {' '}
            //   {t('Ver')}{' '}
            // </Link>
        );
      }
    };

    const columns = [
      {
        key: 'asunto',
        fieldName: 'not_asunto',
        name: t('Asunto'),
        minWidth: 200,
        maxWidth: 300,
        data: 'string',
        onColumnClick: this._onColumnClick,
      },
      {
        key: 'texto',
        fieldName: 'not_texto',
        name: t('Notificacion'),
        minWidth: 300,
        maxWidth: 400,
        data: 'string',
        onColumnClick: this._onColumnClick,
      },
      {
        key: 'fecha',
        fieldName: 'not_fecha',
        name: t('Fecha'),
        minWidth: 100,
        maxWidth: 100,
        onColumnClick: this._onColumnClick,
      },
      // {
      //   key: 'activo',
      //   fieldName: 'not_activo',
      //   name: t('Activo'),
      //   minWidth: 40,
      //   maxWidth: 40,
      //   onColumnClick: this._onColumnClick,
      //   onRender: (item: any) => formatActivo(item),
      // },
      {
        key: 'leido',
        fieldName: 'not_leido',
        name: t('Leido'),
        minWidth: 40,
        maxWidth: 40,
        onColumnClick: this._onColumnClick,
        onRender: (item: any) => formatLeido(item),
      },
      {
        key: 'buttons',
        fieldName: '',
        name: '',
        minWidth: 50,
        maxWidth: 50,
        onColumnClick: this._onColumnClick,
        onRender: (item: any) => redirectToNotification(item),
      },
    ];

    this.state = {
      items: this._allItems,
      selectionDetails: this._getSelectionDetails(),
      columns,
      isCalloutVisible: false,
    };
  }

  private _renderItemColumn: any = (item: IExampleItem | any, index: number, column: IColumn) => {
    const fieldContent = item[column.fieldName as keyof IExampleItem] as string;
    switch (column.key) {
      case 'buttons':
        return (
            <DefaultButton
                text={t('Ver')}
                onClick={(e) => {}}
                // onClick={(e) => this.redirectToNotification(item)}
            />
        );
      default:
        return <span>{fieldContent ? fieldContent : ''}</span>;
    }
  };

  componentDidUpdate(prevProps: INotificationsTable, prevState: IDetailsListBasicExampleState) {
    if (this.props.allSelected !== prevProps.allSelected) {
      if (this.props.allSelected) {
        this._selection.setAllSelected(true);
      }
    }
    if (this.props.noneSelected !== prevProps.noneSelected) {
      if (this.props.noneSelected) {
        this._selection.setAllSelected(false);
      }
    }
  }

  public render(): JSX.Element {
    const { items, selectionDetails, columns } = this.state;

    //Acá no se si conviene usar este tipo de tabla de fluent o el componente que usamos en activitycardlist
    if (this.props.notificationsList && this.props.notificationsList.length > 0) {
      return (
          <div>
            <div className={exampleChildClass}>{selectionDetails}</div>
            <Announced message={selectionDetails} />
            {/* <Announced message={`Number of items after filter applied: ${items.length}.`} /> */}
            <MarqueeSelection selection={this._selection}>
              <DetailsList
                  items={
                    this.props.notificationsFilteredList
                        ? this.props.notificationsFilteredList
                        : this.props.notificationsList
                  }
                  // baseUrl={baseUrl}
                  columns={columns}
                  selectionMode={SelectionMode.multiple}
                  setKey="items"
                  isHeaderVisible={true}
                  selectionPreservedOnEmptyClick={true}
                  selection={this._selection}
                  ariaLabelForSelectionColumn="Toggle selection"
                  ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                  checkButtonAriaLabel="select row"
                  onItemInvoked={this.props.toggleIsCalloutVisible}
                  onRenderItemColumn={this._renderItemColumn}
                  // selection={this.props.allSelected ? selection.isAllSelected : ''}
                  checkboxVisibility={CheckboxVisibility.always}
                  onShouldVirtualize={() => false}
              />
            </MarqueeSelection>
          </div>
      );
    } else return <div>{t('No se han encontrado notificaciones')} </div>;
  }

  private toggleIsCalloutVisible = () => {
    this.setState({
      isCalloutVisible: !this.state.isCalloutVisible,
    });
  };

  private _getSelectionDetails(): string | undefined | any {
    const selectionCount = this._selection.getSelectedCount();

    let idList: any = [];
    for (let i = 0; i < this._selection.getSelectedCount(); i++) {
      let selection = this._selection.getSelection()[i];
      idList.push(selection);
    }
    if (this.props.getNotificationsFromTable) {
      this.props.getNotificationsFromTable(idList);
    }
    switch (selectionCount) {
      case 0:
        return t('0 notificaciones seleccionadas');
      case 1:
        //@ts-ignore
        return t('1 notificación seleccionada: ') + this._selection.getSelection()[0].not_asunto;
      default:
        return selectionCount + t(' notificaciones seleccionadas');
    }
  }

  private _onFilter = (ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, text: string): void => {
    this.setState({
      items: text ? this._allItems.filter((i) => i.name.toLowerCase().indexOf(text) > -1) : this._allItems,
    });
  };

  private _onItemInvoked = (item: IDetailsListBasicExampleItem): void => {};

  // private _onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
  //   const { columns, items } = this.state;
  //   const newColumns: IColumn[] = columns.slice();
  //   const currColumn: IColumn = newColumns.filter((currCol) => column.key === currCol.key)[0];
  //   newColumns.forEach((newCol: IColumn) => {
  //     if (newCol === currColumn) {
  //       currColumn.isSortedDescending = !currColumn.isSortedDescending;
  //       currColumn.isSorted = true;
  //       // this.setState({
  //       //   announcedMessage: `${currColumn.name} is sorted ${
  //       //     currColumn.isSortedDescending ? 'descending' : 'ascending'
  //       //   }`,
  //       // });
  //     } else {
  //       newCol.isSorted = false;
  //       newCol.isSortedDescending = true;
  //     }
  //   });
  //   // const newItems = _copyAndSort(items, currColumn.fieldName!, currColumn.isSortedDescending);
  //   // this.setState({
  //   //   columns: newColumns,
  //   //   items: newItems,
  //   // });
  // };

  private _onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
    const { columns, items } = this.state;
    const newColumns: IColumn[] = columns.slice();
    const currColumn: IColumn = newColumns.filter((currCol) => column.key === currCol.key)[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
        this.setState({
          announcedMessage: `${currColumn.name} is sorted ${
              currColumn.isSortedDescending ? 'descending' : 'ascending'
          }`,
        });
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });
    const newItems = _copyAndSort(items, currColumn.fieldName!, currColumn.isSortedDescending);
    this.setState({
      columns: newColumns,
      items: newItems,
    });
  };
}

function _copyAndSort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
  const key = columnKey as keyof T;
  return items
      .slice(0)
      .sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
}
