import CustomForm from '../../../lib/FormValidator/Form/CustomForm';
import { Company } from '../../../system/company/Company';
import { ProjectCompanyAssertions } from '../../../system/projects/ProjectCompanyAssertions';

class ProjectCompanyForm extends CustomForm {
  protected constructor(percentage: number, company: Company | null) {
    const pca = ProjectCompanyAssertions.build();
    super('ProjectCompany', pca);

    this.addInput('percentage', percentage);
    this.addInputAsserted('company', company);
  }

  private static build(percentage: number, company: Company | null) {
    const instance = new this(percentage, company);
    return instance;
  }

  static initialize() {
    return this.build(0, null);
  }

  static with(percentage: number, company: Company) {
    return this.build(percentage, company);
  }

  getEmpresaInput() {
    return this.getInputByName('company');
  }

  getPercentageDistributionInput() {
    return this.getInputByName('percentage');
  }

  getActivoInput() {
    return this.getInputByName('activo');
  }

  setPercentage(percentage: number) {
    this.getPercentageDistributionInput().setValue(percentage);
  }
}

export default ProjectCompanyForm;
