import React, { useState, useMemo } from 'react';
import { CARD_MODE, GRID_MODE, OPERATORS, TYPES } from '../../../../common/list-utils';
import { ActivityCardList } from '../../../Activity/List/ActivityCardList';
import { MailSectionGridList } from './MailSectionGridList';
import { ListColumnFilter } from '../../../Common/list-entities/ListColumnFilter';
import '../../../Activity/ActivityList.css';
import TimeTaxSupportSystemClient from '../../../../system';
import useSystemCall from '../../../../common/custom-hooks/useSystemCall';
import useAsyncDataCourier from '../../../../common/custom-hooks/useAsyncDataCourier';
import { Activity } from '../../../../system/activity/Activity';
import { CalendarEventCallout } from '../../../Calendar/CalendarEventCallout';
import MessageCourier from '../../../../lib/MessageCourier';
import { IProjectDescriptor } from '../../../../system/projects/ProjectDescriptor';
import Project from '../../../../system/projects/Project';
import { User } from '../../../../system/User';
import { useTranslation, Trans } from 'react-i18next';
import SystemConfiguration from '../../../../system/configuration/SystemConfiguration';
import { Mail } from '../../../../system/mail/Mail';
import { ContextualMenu, Dialog, DialogType, IComboBoxOption, IDropdownOption } from '@fluentui/react';
import { MailDialog } from '../../../Mail/MailDialog';

interface IMailList {
  system: TimeTaxSupportSystemClient;
  courier: MessageCourier;
  user: User;
  project: Project;
  projects: IProjectDescriptor[];
  changeBlockUI: (change: boolean) => void;
  config: SystemConfiguration;
}

export const MailSectionList = (props: IMailList) => {
  const { t, i18n } = useTranslation();
  const [mode, setMode] = useState(GRID_MODE);

  const baseUrl = React.useMemo(() => {
    return props.system.getMailSystem().getBaseUrlForDatagrid();
  }, [props]);

  const companyList = React.useMemo(() => {
    let ret: IComboBoxOption[] = [];
    if (props.project) {
      ret = props.project
          .getProjectCompanyList()
          .getCompanies()
          .filter((com) => {
            return com.isActive();
          })
          .map((com) => {
            return {
              key: com.getCompany().getName(),
              text: com.getCompany().getName(),
            };
          });
    }
    return ret;
  }, [props.project]);

  const collaboratorList = React.useMemo(() => {
    let ret: IComboBoxOption[] = [];
    if (props.project) {
      ret = props.project
          .getProjectTeam()
          .getCollaboratorTeamList()
          .filter((col) => {
            return col.isActive();
          })
          .map((col) => {
            return {
              key: String(col.getCollaborator().getEmail()).toLowerCase(),
              text: col.getCollaborator().fullName(),
            };
          });
    }
    return ret;
  }, [props.project]);

  const projectId = useMemo(() => {
    return props.project.getId();
  }, [props.project]);

  const quickFilters: ListColumnFilter[] = useMemo(
      () => [
        {
          quick: true,
          name: 'pro_id',
          type: TYPES.INPUT,
          placeholder: t(''),
          operator: OPERATORS.EQUAL,
          defaultValue: projectId,
          hidden: true,
        },

        {
          quick: true,
          name: 'mail_subject',
          type: TYPES.INPUT,
          placeholder: t('Asunto'),
          operator: OPERATORS.CONTAINS,
        },
        {
          quick: true,
          name: 'emp_nombre',
          type: TYPES.COMBOBOX,
          placeholder: t('Empresa'),
          operator: OPERATORS.EQUAL,
          contextForCombobox: companyList || [],
        },

        {
          // este filtro debera buscar en from y to a partir del colaborador
          quick: true,
          name: 'from_to_mails',
          type: TYPES.COMBOBOX,
          placeholder: t('Incluye a'),
          operator: OPERATORS.CONTAINS,
          contextForCombobox: collaboratorList || [],
        },
        {
          quick: true,
          name: 'tiene_docs',
          type: TYPES.CHECKBOX,
          labelForCheckbox: t('Tiene docs. adjuntos'),
          checkBoxValue: '1',
          operator: OPERATORS.EQUAL,
        },

        {
          quick: true,
          name: 'mail_fecha',
          type: TYPES.DATE,
          placeholder: t('Enviado despues del'),
          operator: OPERATORS.AFTERORON,
        },
      ],
      [companyList, collaboratorList, projectId]
  );

  const [mail, setMail] = React.useState<Mail | undefined>(undefined);
  const [showDialog, setShowDialog] = React.useState<boolean>(false);
  const onItemClick = async (item: any) => {
    props.changeBlockUI(true);
    try {
      const mail = await props.system.getMailSystem().getMailIdentifiedBy(item.mail_id);
      setMail(mail);
    } catch (error) {
      props.courier.messageError(error);
    }
    setShowDialog(true);
    props.changeBlockUI(false);
  };
  const onCloseDialog = () => {
    setShowDialog(false);
    setMail(undefined);
  };

  return (
      <>
        <MailSectionGridList
            keyList={'pro_mngr_mails_section_' + props.project.getId()}
            changeBlockUI={props.changeBlockUI}
            baseUrl={baseUrl}
            quickFilters={quickFilters}
            onClickCardMode={() => setMode(CARD_MODE)}
            onClickGridMode={() => setMode(GRID_MODE)}
            onItemClick={(item: any) => onItemClick(item)}
            courier={props.courier}
            mode={mode}
            project={props.project}
        ></MailSectionGridList>

        {mail && <MailDialog system={props.system} showDialog={showDialog} onClose={onCloseDialog} mail={mail}></MailDialog>}
      </>
  );
};
