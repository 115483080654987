import * as React from 'react';
import {
  ChartHoverCard,
  VerticalStackedBarChart,
  IVSChartDataPoint,
  IVerticalStackedChartProps,
  IVerticalStackedBarChartProps,
} from '@uifabric/charting';
import { DefaultPalette, IStyle, DefaultFontStyles } from '@fluentui/react/lib/Styling';
import { DirectionalHint } from '@fluentui/react';
import { CollaboratorActivityStatus } from '../../../system/panel/PanelSystem';
import { IGroupedVerticalBarChartData } from '@fluentui/react-charting';
import { Icon, Text } from '@fluentui/react';
import { useTranslation, Trans } from 'react-i18next';

interface IPanelVerticalStackedBarChart {
  data: CollaboratorActivityStatus;
}
const PanelProjectsVerticalStackedBarChart = (props: IPanelVerticalStackedBarChart) => {
  const { t, i18n } = useTranslation();
  const colorActivities = '#564aa3';

  const _chartData: IVerticalStackedChartProps[] = React.useMemo(() => {
    if (props.data.estadoDocumentos.length > 0) {
      const chartPoints = props.data.estadoDocumentos.map((item, idx) => {
        return [
          { data: item.cant_cargados, legend: 'Documentos Cargados', color: DefaultPalette.greenLight },
          { data: item.cant_pendientes, legend: 'Documentos Pendientes', color: DefaultPalette.yellowLight },
          { data: item.cant_vencidos, legend: 'Documentos Vencidos', color: '#e7584e' },
        ];
      });

      return chartPoints.map((item, idx) => {
        return { chartData: item, xAxisPoint: props.data.estadoDocumentos[idx].pro_codigo };
      });
    } else {
      const chartPoints = [
        [
          { data: 0, legend: t('Documentos Cargados'), color: DefaultPalette.greenLight },
          { data: 0, legend: t('Documentos Pendientes'), color: DefaultPalette.purpleLight },
          { data: 0, legend: t('Documentos Vencidos'), color: '#e7584e' },
        ],
      ];

      return chartPoints.map((item, idx) => {
        return { chartData: item, xAxisPoint: '' };
      });
    }

    // return props.data.estadoHoras.map((item, idx) => {
    //   let data: IVSChartDataPoint[] = () => {
    //     return [
    //       { chartData: item.horas, xAxisPoint: item.periodo }
    //     ]
    //   }

    //   const chartPoints: IVSChartDataPoint[] = props.data.estadoHoras.map((itemHours) => {

    //   })

    //   return data.map((chartPoint, idx) => {
    //     return {
    //       chartData: chartPoint,
    //       xAxisPoint: item.periodo
    //     }
    //   })
    // })
  }, [props.data]);

  const textStyle: IStyle = {
    fill: DefaultPalette.black,
    fontSize: '10px',
    lineHeight: '14px',
  };

  const customStyles: IVerticalStackedBarChartProps['styles'] = () => {
    return {
      chart: {
        paddingBottom: '45px',
      },
      chartLabel: {
        color: DefaultPalette.blueMid,
        ...DefaultFontStyles.large,
      },
      xAxisText: {
        ...textStyle,
      },
      root: {
        height: 150,
      },
    };
  };

  return (
      <div className="mt-2">
        <Text variant="medium" style={{ fontSize: '14px' }}>
          <Icon iconName={'ProtectedDocument'} className="text-icon"></Icon>
          {t('Documentos obligatorios')}
        </Text>

        {props.data.estadoDocumentos.length !== 0 && (
            <div className={`mt-1 xAxisVisible`}>
              <VerticalStackedBarChart
                  hideLegend={true}
                  data={_chartData}
                  noOfCharsToTruncate={12}
                  wrapXAxisLables
                  showXAxisLablesTooltip
                  barWidth={35}
                  yAxisTickCount={5}
                  // eslint-disable-next-line react/jsx-no-bind
                  styles={customStyles}
                  calloutProps={{
                    directionalHint: DirectionalHint.topCenter,
                  }}
                  // eslint-disable-next-line react/jsx-no-bind
                  yAxisTickFormat={(x: number | string) => `${x}`}
                  // margins={{ left: 50 }}
                  legendProps={{
                    allowFocusOnLegends: true,
                    styles: {
                      rect: {
                        borderRadius: '3px',
                      },
                    },
                  }}
                  // eslint-disable-next-line react/jsx-no-bind
                  onRenderCalloutPerDataPoint={(props) =>
                      props ? (
                          <ChartHoverCard
                              XValue={props.xAxisCalloutData}
                              Legend={props.legend}
                              YValue={`${props.yAxisCalloutData || props.data} documento(s)`}
                              color={props.color}
                          />
                      ) : null
                  }
              />
            </div>
        )}

        <div className="mt-1 ">
          {props.data.estadoDocumentos.length === 0 && (
              <Text variant="medium" className="fw-500 mt-2">
                <Icon iconName="CheckMark" className="text-icon success-color"></Icon>
                {t('No tienes documentos obligatorios')}
              </Text>
          )}
        </div>
      </div>
  );
};

export default PanelProjectsVerticalStackedBarChart;
