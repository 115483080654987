import React, { useState, useMemo } from 'react';
import { CARD_MODE, GRID_MODE, OPERATORS, TYPES, TYPE_CHECKBOX, TYPE_DATE } from '../../common/list-utils';
import { CollaboratorCardList } from './List/CollaboratorCardList';
import { CollaboratorGridList } from './List/CollaboratorGridList';
import { ListColumnFilter } from '../Common/list-entities/ListColumnFilter';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../common/constants';
// import './CollaboratorList.css';
import TimeTaxSupportSystemClient from '../../system';
import useAsyncDataCourier from '../../common/custom-hooks/useAsyncDataCourier';
import MessageCourier from '../../lib/MessageCourier';
import { useTranslation, Trans } from 'react-i18next';
import { IComboBoxOption } from '@fluentui/react';

interface ICollaboratorList {
  system: TimeTaxSupportSystemClient;
  courier: MessageCourier;
  changeBlockUI: (state: boolean) => void;
}

export const CollaboratorList = (props: ICollaboratorList) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [mode, setMode] = useState(GRID_MODE);

  const nodes = useAsyncDataCourier(
      () => props.system.getOrganizationChartSystem().getAllNodes(),
      [props.system],
      props.courier
  );

  const listJefatura =  useAsyncDataCourier(
    () => props.system.getOrganizationChartSystem().getLastLevelNodesss(),
    [props.system],
    props.courier
  )

  const listcargo = useAsyncDataCourier(
    () => props.system.listCargoTypeComboBoxOptions(),
    [props.system],
    props.courier
   );

   const listniveles:IComboBoxOption[] = [
    { key: "00000005", text: "User" },
    {key:"00000006",text:"Power User"},
    {key:"00000007",text:"Admin User"},
    {key:"00000010",text:"Directivo"} ];

  const baseUrl = React.useMemo(() => {
    return props.system.getCollaboratorSystem().getBaseUrlForDatagrid();
  }, [props]);

  const onItemClick = (item: any) => {
    navigate(`${routes.collaborator}/${item.col_id}`);
  };

  const quickFilters: ListColumnFilter[] = useMemo(
      () => [
        {
          quick: true,
          name: 'col_id_sap',
          type: TYPES.INPUT,
          placeholder: t('Cod. SAP'),
          operator: OPERATORS.CONTAINS,
        },
        {
          quick: true,
          name: 'col_nombre',
          type: TYPES.INPUT,
          placeholder: t('Nombre'),
          operator: OPERATORS.CONTAINS,
        },
        {
          quick: true,
          name: 'col_apellido',
          type: TYPES.INPUT,
          placeholder: t('Apellido'),
          operator: OPERATORS.CONTAINS,
        },
        {
          quick: true,
          name: 'cnih_porcentaje_excento',
          type: TYPE_CHECKBOX,
          labelForCheckbox: t('Proyecto fijo'),
          operator:  OPERATORS.CONTAINS,
        },
     //   {
      //    quick: true,
      //    name: 'col_email',
      //    type: TYPES.INPUT,
      //    placeholder: t('Email'),
      //    operator: OPERATORS.CONTAINS,
     //   },
        {
          quick: false,
          name: 'col_fecha_alta',
          type: TYPE_DATE,
          placeholder: t('Fecha ingreso'),
          operator: OPERATORS.CONTAINS,
        },
        {
          quick: false,
          name: 'col_activo',
          type: TYPE_CHECKBOX,
          labelForCheckbox: t('Activo'),
          operator:  OPERATORS.CONTAINS,
  
        },
        {
          quick: false,
          name: 'col_fecha_baja',
          type: TYPE_DATE,
          placeholder: t('Fecha baja'),
          operator: OPERATORS.CONTAINS,
        },
        {
          quick: false,
          name: 'direccion',
          type: TYPES.COMBOBOXTEXT,
          placeholder: t('direccion'),
          operator: OPERATORS.CONTAINS,
          contextForCombobox: listJefatura?.levelOne || [],
        },
        {
          quick: false,
          name: 'gerencia',
          type: TYPES.COMBOBOXTEXT,
          placeholder: t('gerencia'),
          operator: OPERATORS.CONTAINS,
          contextForCombobox: listJefatura?.levelTwo || [],
        },
        {
          quick: false,
          name: 'jefatura',
          type: TYPES.COMBOBOXTEXT,
          placeholder: t('Jefatura'),
          operator: OPERATORS.CONTAINS,
          contextForCombobox: listJefatura?.levelThree || [],
        },
        {
          quick: false, 
          name: 'cargo_nombre',
          type: TYPES.COMBOBOXTEXT,
          placeholder: t('Cargo'),
          operator: OPERATORS.CONTAINS,
          contextForCombobox:listcargo || [],
        },
        {
          quick: false,
          name: 'us_perid',
          type: TYPES.COMBOBOX_USER,
          placeholder: t('Tipo usuario'),
          operator: OPERATORS.CONTAINS,
          contextForCombobox: listniveles || [],
        },
      ],
      [nodes,listJefatura,listcargo]
  );
  return (
      <>
        {(nodes || listJefatura || listcargo)  && (
            <>
              {mode === GRID_MODE ? (
                  <CollaboratorGridList
                      changeBlockUI={props.changeBlockUI}
                      baseUrl={baseUrl}
                      quickFilters={quickFilters}
                      onItemClick={onItemClick}
                      onClickCardMode={() => setMode(CARD_MODE)}
                      onClickGridMode={() => setMode(GRID_MODE)}
                      mode={mode}
                      courier={props.courier}
                      keyList={'colaboradores'}
                      system={props.system}
                  ></CollaboratorGridList>
              ) : (
                  <CollaboratorCardList
                      changeBlockUI={props.changeBlockUI}
                      baseUrl={baseUrl}
                      quickFilters={quickFilters}
                      onItemClick={onItemClick}
                      onClickCardMode={() => setMode(CARD_MODE)}
                      onClickGridMode={() => setMode(GRID_MODE)}
                      courier={props.courier}
                      mode={mode}
                  ></CollaboratorCardList>
              )}
            </>
        )}
      </>
  );
};
