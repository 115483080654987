import * as React from 'react';
import { Callout, DirectionalHint, Icon, IFacepilePersona, IStyleFunctionOrObject } from '@fluentui/react';
import './Calendar.scss';
import { styles } from './CalendarStyles';
import { Activity } from '../../system/activity/Activity';
import TimeTaxSupportSystemClient from '../../system/TimeTaxSupportSystemClient';
import MessageCourier from '../../lib/MessageCourier';
import { ActivityMultipleOptionsDialog } from './ActivityDialog/ActivityMenuOptions/ActivityMultipleOptionsDialog';
import { User } from '../../system/User';
import { Dialog, DialogType, IDialogStyleProps, IDialogStyles } from '@fluentui/react/lib/Dialog';
import { ContextualMenu } from '@fluentui/react/lib/ContextualMenu';
import { CalendarActivityCalloutWrapper } from './CalendarActivityCalloutWrapper';
import { useTranslation, Trans } from 'react-i18next';

interface ICalendarActivityCallout {
  labelId: string;
  descriptionId: string;
  targetId: string;
  activity: Activity;
  system: TimeTaxSupportSystemClient;
  user: User;
  toggleIsCalloutVisible: () => void;
  eventData: any;
  openDialog: () => void;
  refreshEvents: () => void;
  courier: MessageCourier;
  isAvaliablePeriodForEditOrCreateActivity: boolean;
  openUnableModifyHoursDialog?: () => void;
  openDialogEdit?: () => void;
  changeBlockUI: (v: boolean) => void;
}

export const CalendarActivityCallout = (props: ICalendarActivityCallout) => {
  const { t, i18n } = useTranslation();
  const [showDeleteDialog, setShowDeleteDialog] = React.useState<boolean>(false);
  const [showAcceptTagDialog, setShowAcceptTagDialog] = React.useState<boolean>(false);
  const [showDeclineTagDialog, setShowDeclineTagDialog] = React.useState<boolean>(false);

  const [hoursToAccept, setHoursToAccept] = React.useState<number>(0);
  React.useEffect(() => {
    setHoursToAccept(props.activity.getHours());
  }, [props.activity]);

  const collaboratorActivity = React.useMemo(
      () => props.activity.getCollaboratorActivityList().find((colact) => colact.referencesUser(props.user)),
      [props.activity, props.user]
  );

  const pendingTag = React.useMemo(
      () => !!collaboratorActivity && !collaboratorActivity.getChecked(),
      [collaboratorActivity]
  );

  const acceptTag = async (allAfterThis: boolean = false, all: boolean = false) => {
    try {
      let colact = collaboratorActivity;
      if (colact && colact.getId()) {
        await props.system.getActivitySystem().acceptTag(colact.getId(), hoursToAccept, allAfterThis, all);
        props.refreshEvents();
        if (allAfterThis || all) {
          props.courier.messageSuccess(t('Las actividades fueron aceptadas de manera exitosa'));
        } else {
          props.courier.messageSuccess(t('La actividad fue aceptada de manera exitosa'));
        }
      }
    } catch (error) {
      props.courier.messageError(error);
    }
  };

  const declineTag = async (allAfterThis: boolean = false, all: boolean = false) => {
    try {
      let colact = collaboratorActivity;
      if (colact && colact.getId()) {
        await props.system.getActivitySystem().declineTag(colact.getId(), allAfterThis, all);
        props.refreshEvents();
        if (allAfterThis || all) {
          props.courier.messageSuccess(t('Las actividades fueron declinadas de manera exitosa'));
        } else {
          props.courier.messageSuccess(t('La actividad fue declinada de manera exitosa'));
        }
      }
    } catch (error) {
      props.courier.messageError(error);
    }
  };

  const deleteActivity = async (allAfterThis: boolean = false, all: boolean = false) => {
    try {
      await props.system.getActivitySystem().delete(props.activity, allAfterThis, all);
      props.refreshEvents();
      if (allAfterThis || all) {
        props.courier.messageSuccess(t('Las actividades se eliminaron de manera exitosa'));
      } else {
        props.courier.messageSuccess(t('La actividad se eliminó de manera exitosa'));
      }
    } catch (error) {
      props.courier.messageError(error);
    }
  };
  //  -------------------------------------------------------------------------------------------------------

  const dragOptions = {
    moveMenuItemText: t('Move'),
    closeMenuItemText: t('Close'),
    menu: ContextualMenu,
  };

  const dialogStyles: IStyleFunctionOrObject<IDialogStyleProps, IDialogStyles> = {};

  const modalProps = {
    isBlocking: true,
    dragOptions: dragOptions,
    styles: dialogStyles,
  };

  const dialogContentProps = React.useMemo(() => {
    return {
      type: DialogType.normal,
      title: props.activity.getName(),
      className: 'dialog-for-pending-tag',
    };
  }, [props]);

  return (
      <>
        {!pendingTag ? (
            <Callout
                className={styles.callout}
                ariaLabelledBy={props.labelId}
                ariaDescribedBy={props.descriptionId}
                role="dialog"
                gapSpace={0}
                directionalHint={DirectionalHint.topAutoEdge}
                target={`#${props.targetId}`}
                onDismiss={props.toggleIsCalloutVisible}
                setInitialFocus
            >
              <div className={styles.header}>
                {!pendingTag ? (
                    <Icon
                        onClick={() => props.openDialog()}
                        iconName="FullScreen"
                        className="text-icon cursor-pointer text-white"
                    />
                ) : (
                    <Icon iconName="AccountActivity" className="text-icon text-white" />
                )}
              </div>

              <CalendarActivityCalloutWrapper
                  labelId={props.labelId}
                  activity={props.activity}
                  system={props.system}
                  user={props.user}
                  descriptionId={props.descriptionId}
                  targetId={props.targetId}
                  toggleIsCalloutVisible={props.toggleIsCalloutVisible}
                  eventData={props.eventData}
                  refreshEvents={props.refreshEvents}
                  courier={props.courier}
                  changeBlockUI={props.changeBlockUI}
                  openDialog={props.openDialog}
                  openDialogEdit={props.openDialogEdit}
                  pendingTag={pendingTag}
                  isAvaliablePeriodForEditOrCreateActivity={props.isAvaliablePeriodForEditOrCreateActivity}
                  openUnableModifyHoursDialog={props.openUnableModifyHoursDialog}
              />
            </Callout>
        ) : (
            <Dialog
                hidden={false}
                onDismiss={props.toggleIsCalloutVisible}
                dialogContentProps={dialogContentProps}
                modalProps={modalProps}
                minWidth={400}
            >
              <CalendarActivityCalloutWrapper
                  labelId={props.labelId}
                  activity={props.activity}
                  system={props.system}
                  user={props.user}
                  descriptionId={props.descriptionId}
                  targetId={props.targetId}
                  toggleIsCalloutVisible={props.toggleIsCalloutVisible}
                  eventData={props.eventData}
                  refreshEvents={props.refreshEvents}
                  courier={props.courier}
                  openDialog={props.openDialog}
                  pendingTag={pendingTag}
                  isAvaliablePeriodForEditOrCreateActivity={props.isAvaliablePeriodForEditOrCreateActivity}
                  openUnableModifyHoursDialog={props.openUnableModifyHoursDialog}
                  changeBlockUI={props.changeBlockUI}
              />
            </Dialog>
        )}

        {/* ---------------------- Eliminar Dialog ---------------------- */}
        {props.activity.getReplicated_from_id() && (
            <ActivityMultipleOptionsDialog
                system={props.system}
                activity={props.activity}
                showDialog={showDeleteDialog}
                toggleShowDialog={() => {
                  setShowDeleteDialog(!showDeleteDialog);
                }}
                onAcceptCallback={deleteActivity}
                acceptText={t('Eliminar')}
            ></ActivityMultipleOptionsDialog>
        )}

        {/* ---------------------- Aceptar tag Dialog ---------------------- */}
        <ActivityMultipleOptionsDialog
            system={props.system}
            activity={props.activity}
            showDialog={showAcceptTagDialog}
            toggleShowDialog={() => {
              setShowAcceptTagDialog(!showAcceptTagDialog);
            }}
            onAcceptCallback={acceptTag}
            acceptText={t('Aceptar')}
        ></ActivityMultipleOptionsDialog>
        {/* ---------------------- Declinar tag Dialog ---------------------- */}
        <ActivityMultipleOptionsDialog
            system={props.system}
            activity={props.activity}
            showDialog={showDeclineTagDialog}
            toggleShowDialog={() => {
              setShowDeclineTagDialog(!showDeclineTagDialog);
            }}
            onAcceptCallback={declineTag}
            acceptText={t('Declinar')}
        ></ActivityMultipleOptionsDialog>
      </>
  );
};
