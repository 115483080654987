import CustomForm from './CustomForm';
import CustomInputIfc from './CustomInputIfc';
import { ErrorList } from './ErrorList';

interface IErrorListForForm {
  customForm: CustomForm;
}

const ErrorListForForm = (props: IErrorListForForm) => {
  return <ErrorList errors={props.customForm.getErrorList()} />;
};

export default ErrorListForForm;
