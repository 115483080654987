import XhrRequestHandler from '../../lib/XhrRequestHandler';
import { TaxOffice } from './TaxOffice';

export interface ITaxOffice {
  of_id: string;
  of_nombre: string;
  of_paisid: string;
}

class TaxOfficeSystem {
  private readonly entityBase = '/oficinas-fiscales';

  constructor(private requestHandler: XhrRequestHandler) {}

  async list(): Promise<TaxOffice[]> {
    const offices = await this.requestHandler.get<ITaxOffice[]>(this.entityBase);

    return offices.map((office) => TaxOffice.named(office.of_id, office.of_nombre));
  }
}

export default TaxOfficeSystem;
