import { Text, Icon } from '@fluentui/react';
import { FontWeights, ITextStyles } from '@fluentui/react';

const textBoldStyle = { fontWeight: FontWeights.semibold };
const boldStyle: Partial<ITextStyles> = {
  root: textBoldStyle,
};

interface IPillFluent {
  title: string;
  iconTitle?: string;
  series: { label: string; value: any; icon?: string }[];
  small?: boolean;
  minHeight?: number;
}

export const PillFluent = (props: IPillFluent) => {
  return (
      <>
        {!props.small ? (
            <div className="pill-fluent ms-depth-8">
              <div className="ms-Grid-col ms-md12 pill-fluent-title bg-hat-pill">
                <Text
                    variant="mediumPlus"
                    styles={boldStyle}
                    style={{ fontSize: '15px' }}
                    className="white-color mt-1"
                    nowrap={true}
                    block={true}
                >
                  {props.title}
                  {props.iconTitle && (
                      <Icon iconName={props.iconTitle} className={'text-icon-title-big float-right'} />
                  )}
                </Text>
              </div>
              {props.series.map((serie, i) => {
                return (
                    <div className="ms-Grid-col ms-md12 pill-fluent-item-container" key={i}>
                      <Text variant="medium" styles={boldStyle} nowrap={true} block={true}>
                        {serie.icon && <Icon iconName={serie.icon} className={'text-icon'} />}
                        <span className="smooth-label">{serie.label}:</span> {serie.value}
                      </Text>
                    </div>
                );
              })}
            </div>
        ) : (
            <div className="pill-fluent-small ms-depth-8" style={{ minHeight: props.minHeight }}>
              <div className="ms-Grid-col ms-md12 pill-fluent-title-small bg-hat-pill">
                <Text
                    variant="medium"
                    styles={boldStyle}
                    style={{ fontSize: '15px' }}
                    className="white-color "
                    nowrap={true}
                    block={true}
                >
                  {props.iconTitle && (
                      <Icon iconName={props.iconTitle} className={'text-icon-title-small float-right'} />
                  )}
                  {props.title}
                </Text>
              </div>
              {props.series.map((serie, i) => {
                return (
                    <div className="ms-Grid-col ms-md12 pill-fluent-item-container" key={i}>
                      <Text variant="smallPlus" styles={boldStyle} nowrap={true} block={true}>
                        {serie.icon && <Icon iconName={serie.icon} className={'text-icon-small'} />}
                        <span className="smooth-label">{serie.label}:</span> {serie.value}
                      </Text>
                    </div>
                );
              })}
            </div>
        )}
      </>
  );
};
