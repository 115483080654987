import {
  DocumentCard,
  DocumentCardActivity,
  DocumentCardDetails,
  DocumentCardPreview,
  DocumentCardTitle,
  IDocumentCardPreviewProps,
  DocumentCardType,
  IDocumentCardActivityPerson,
  DocumentCardStatus,
} from '@fluentui/react/lib/DocumentCard';
import { getTheme } from '@fluentui/react/lib/Styling';
import { DetailsListView } from '../../Common/DetailsList/DetailListView';
import { timeSince } from '../../../common/list-utils';
import { useTranslation, Trans } from 'react-i18next';

const theme = getTheme();
const { palette, fonts } = theme;

export const DocumentCardList = (props: any) => {
  const { t, i18n } = useTranslation();
  const previewPropsUsingIcon: IDocumentCardPreviewProps = {
    previewImages: [
      {
        previewIconProps: {
          iconName: 'Edit',
          styles: { root: { fontSize: fonts.superLarge.fontSize, color: palette.white } },
        },
        width: 144,
      },
    ],
    styles: { previewIcon: { backgroundColor: palette.themePrimary } },
  };

  const mapCard = (document: any, i: number) => {
    return (
        <div className="ms-Grid-col ms-sm6 ms-md12 ms-lg6 ms-xl4" key={i}>
          <DocumentCard className="size-document-card" type={DocumentCardType.compact}>
            <DocumentCardPreview {...previewPropsUsingIcon} className="size-logo" />
            <DocumentCardDetails>
              <DocumentCardTitle title={document.doc_nombre} shouldTruncate />
              <DocumentCardTitle
                  title={t('Proyecto: ') + document.pro_nombre}
                  className="proyect-title"
                  showAsSecondaryTitle
              />
              <DocumentCardTitle
                  title={t('Período: ') + document.pro_inicio + ' - ' + document.pro_fin}
                  className="proyect-title"
                  showAsSecondaryTitle
              />

              {/* <DocumentCardStatus statusIcon="attach" status=" 3 Documentos" /> */}
            </DocumentCardDetails>
          </DocumentCard>{' '}
        </div>
    );
  };

  return (
      <DetailsListView
          changeBlockUI={props.changeBlockUI}
          keyList={'documentos'}
          onClickCardMode={() => props.onClickCardMode()}
          onClickGridMode={() => props.onClickGridMode()}
          title={t('Documentos')}
          mode={props.mode}
          isGridList={false}
          // virtualize={false}
          listFilters={[]}
          quickFilters={props.quickFilters}
          baseUrl={props.baseUrl}
          mapCard={mapCard}
          courier={props.courier}
      ></DetailsListView>
  );
};
