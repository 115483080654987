import { OrganizationChartLevel } from './OrganizationChartLevel';
import { OrganizationChartNode } from './OrganizationChartNode';

export class OrganizationChartIntermediateNode implements OrganizationChartNode {
  protected constructor(
      private name: string,
      private id: string,
      private level: OrganizationChartLevel,
      private parent: OrganizationChartNode
  ) {}

  static named(name: string, id: string, level: OrganizationChartLevel, parent: OrganizationChartNode) {
    return new this(name, id, level, parent);
  }

  getId(): string {
    return this.id;
  }

  getName(): string {
    return this.name;
  }

  getLevel(): OrganizationChartLevel {
    return this.level;
  }

  path(): string {
    return `${this.parentPath()} > ${this.name}`;
  }

  parentPath(): string {
    return this.parent.path();
  }

  isIdentifiedBy(anId: string): boolean {
    return this.id === anId;
  }

  isIdentifiedAs(node: OrganizationChartNode): boolean {
    return node.isIdentifiedBy(this.id);
  }

  isDescendantOf(aNode: OrganizationChartNode): boolean {
    return this.isIdentifiedAs(aNode) || this.parent.isDescendantOf(aNode);
  }
}
