import React, { useState, useMemo } from 'react';
import { CARD_MODE, GRID_MODE, OPERATORS, TYPES } from '../../../../common/list-utils';
import { ActivityCardList } from '../../../Activity/List/ActivityCardList';
import { ActivitySectionGridList } from './ActivitySectionGridList';
import { ListColumnFilter } from '../../../Common/list-entities/ListColumnFilter';
import '../../../Activity/ActivityList.css';
import TimeTaxSupportSystemClient from '../../../../system';
import useSystemCall from '../../../../common/custom-hooks/useSystemCall';
import { Activity } from '../../../../system/activity/Activity';
import { CalendarEventCallout } from '../../../Calendar/CalendarEventCallout';
import MessageCourier from '../../../../lib/MessageCourier';
import { IProjectDescriptor } from '../../../../system/projects/ProjectDescriptor';
import { User } from '../../../../system/User';
import i18next, { t } from 'i18next';
import SystemConfiguration from '../../../../system/configuration/SystemConfiguration';
import PeriodDatesBlockedSystem from '../../../../system/period_dates_blocked/PeriodDatesBlockedSystem';

interface IActivityList {
  system: TimeTaxSupportSystemClient;
  courier: MessageCourier;
  user: User;
  project: IProjectDescriptor;
  projects: IProjectDescriptor[];
  changeBlockUI: (change: boolean) => void;
  config: SystemConfiguration;
  periodsBlocked: PeriodDatesBlockedSystem;

}

export const ActivitySectionList = (props: IActivityList) => {
  const [mode, setMode] = useState(GRID_MODE);
  const [filterPanel, setFilterPanel] = useState(false);

  const baseUrl = React.useMemo(() => {
    return props.system.getActivitySystem().getBaseUrlForDatagrid();
  }, [props.system]);

  const quickFilters: ListColumnFilter[] = useMemo(
      () => [
        {
          quick: true,
          name: 'act_nombre',
          type: TYPES.INPUT,
          placeholder: t('Nombre'),
          operator: OPERATORS.CONTAINS,
        },
        {
          quick: true,
          name: 'act_activo',
          type: TYPES.CHECKBOX,
          labelForCheckbox: t('Activos'),
          checkBoxValue: '1',
          defaultValue: '1',
          operator: OPERATORS.EQUAL,
        },
        {
          quick: true,
          name: 'owner_actividad_nombre',
          type: TYPES.INPUT,
          placeholder: t('Creador'),
          operator: OPERATORS.CONTAINS,
        },
        {
          quick: true,
          name: 'pro_id',
          type: TYPES.INPUT,
          placeholder: t('Proyecto id'),
          operator: OPERATORS.EQUAL,
          defaultValue: props.project.getId(),
          hidden: true,
        },
        {
          quick: false,
          name: 'act_inicio',
          type: TYPES.DATE,
          placeholder: t('Inicio posterior a'),
          operator: OPERATORS.BEFOREORON,
        },
      ],
      [props.project]
  );

  const projectCode = React.useMemo(() => props.project.getCode(), [props.project]);

  const [activity, setActivity] = React.useState<Activity | undefined>(undefined);

  // const projects = useSystemCall(props.system, (system) => system.getProjects());
  const commonContext = useSystemCall(props.system, (system) =>
      system.getActivitySystem().getCommonContext()
  );
  const [showDialog, setShowDialog] = React.useState<boolean>(false);

  const onItemClick = async (item: any) => {
    let activityFromAPI: Activity = await props.system.getActivitySystem().getActivity(item.act_id);
    setActivity(activityFromAPI);
    setShowDialog(true);
  };

  const [shouldUpdate, setShouldUpdate] = React.useState<boolean>(false);

  const refreshList = () => {
    setShouldUpdate(!shouldUpdate);
  };

  return (
      <>
        {mode == GRID_MODE ? (
            <ActivitySectionGridList
                keyList={'pro_mngr_act_section_' + props.project.getId()}
                changeBlockUI={props.changeBlockUI}
                baseUrl={baseUrl}
                quickFilters={quickFilters}
                onClickCardMode={() => setMode(CARD_MODE)}
                onClickGridMode={() => setMode(GRID_MODE)}
                onItemClick={(item: any) => onItemClick(item)}
                courier={props.courier}
                mode={mode}
                project={props.project}
            ></ActivitySectionGridList>
        ) : (
            <ActivityCardList
                keyList={'pro_mngr_act_section_' + projectCode}
                baseUrl={baseUrl}
                changeBlockUI={props.changeBlockUI}
                quickFilters={quickFilters}
                filtersData={null}
                filterPanel={filterPanel}
                onClickCardMode={() => setMode(CARD_MODE)}
                onClickGridMode={() => setMode(GRID_MODE)}
                courier={props.courier}
                onItemClick={(item: any) => onItemClick(item)}
                mode={mode}
            ></ActivityCardList>
        )}
        {activity && (
            <CalendarEventCallout
                periodsBlocked={props.periodsBlocked}
                system={props.system}
                projects={props.projects ? props.projects : []}
                user={props.user}
                eventData={null}
                targetId={''}
                labelId={''}
                descriptionId={''}
                toggleIsCalloutVisible={() => {}}
                isCalloutVisible={false}
                commonContext={commonContext}
                getEvents={() => {
                  refreshList();
                }}
                config={props.config}
                courier={props.courier}
                onlyShowDialogs={true}
                activityProvided={activity}
                showDialog={showDialog}
                toggleShowDialog={() => setShowDialog(!showDialog)}
                changeBlockUI={props.changeBlockUI}
                hideMenuOptions={true}
            />
        )}
      </>
  );
};
