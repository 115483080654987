import React from 'react';
import { Stack, DefaultPalette, IIconProps, IconButton } from '@fluentui/react';
import { Separator } from '@fluentui/react/lib/Separator';
import { Text } from '@fluentui/react/lib/Text';

import { boldStyle } from '../Common/TextStyles';
import { IContextualMenuProps } from '@fluentui/react/lib/ContextualMenu';
import { useTranslation } from 'react-i18next';

interface IBasicViewProps {
  title: string;
  useMenu?: boolean;
  menuProps?: IContextualMenuProps;
}

const BasicView: React.FC<IBasicViewProps> = (props) => {
  const { t } = useTranslation();
  const GlobalNavButtonIcon: IIconProps = { iconName: 'GlobalNavButton' };

  return (
      <Stack
          className="full-width"
          tokens={{ padding: 10 }}
          verticalFill
          styles={{ root: { backgroundColor: DefaultPalette.white } }}
      >
        <Stack.Item className="full-width" /*align={'start'}*/>
          <Text className="full-width" variant={'large'} styles={boldStyle} nowrap block>
            {props.title}
            {props.useMenu && (
                <IconButton
                    className="float-right menu-basic-view-btn"
                    iconProps={GlobalNavButtonIcon}
                    title={t('Opciones de actividad')}
                    ariaLabel="GlobalNavButton"
                    menuProps={props.menuProps}
                />
            )}
          </Text>
        </Stack.Item>

        <Separator />
        {props.children}
      </Stack>
  );
};

export default BasicView;
