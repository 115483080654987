import React, { useEffect, useState } from 'react';
import ViewWrapperWithCommandBar from '../../Layout/ViewWrapperWithCommandBar';
import Wizard from '../../Wizard/Wizard';
// import CompanySelectorAssistant from './Wizard/CompanySelectorAssistant';
import { DefaultPalette, ICommandBarItemProps, Text } from '@fluentui/react';
import { titleCommandBarButtonStyles, defaultCommandBarButtonStyles } from '../../../styles/button-styles';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../../common/constants';
// import ProjectSelectorAssistant from './Wizard/ProjectSelectorAssistant';
// import Ending from './Wizard/Ending';
// import DetailAssistant from './Wizard/DetailAssistant';
import { DateTime } from 'luxon';
import TimeTaxSupportSystemClient from '../../../system';
import MessageCourier from '../../../lib/MessageCourier';
import { useTranslation } from 'react-i18next';
import DateSelector from './Wizard/DateSelector';
import { DefenseFileBySettlement } from '../../../system/defense_file/DefenseFileBySettlement';
import { DefenseFileBySettlementUploadedDocument } from '../../../system/defense_file/DefenseFileBySettlementUploadedDocument';
import Ending from './Wizard/Ending';

interface ISettlementsViewProps {
  system: TimeTaxSupportSystemClient;
  courier: MessageCourier;
  changeBlockUI: any;
}

const MassiveDefenseView = (props: ISettlementsViewProps) => {
  const COLLABORATOR_KEY = 'colaborador';
  const COMPANY_KEY = 'empresa';

  const { t, i18n } = useTranslation();
  const [canGoNext, setCanGoNext] = useState(false);
  const [fromDate, setFromDate] = useState<DateTime>();
  const [toDate, setToDate] = useState<DateTime>();

  const [defenseGenerated, setDefenseGenerated] = useState<DefenseFileBySettlement>();
  const [uploadedDocuments, setUploadedDocuments] = useState<DefenseFileBySettlementUploadedDocument[]>([]);
  const [defenseById, setDefenseById] = useState<string>();

  const [hasGenerated, setHasGenerated] = useState<boolean>(false);
  const navigate = useNavigate();

  const Titles = [t('1. Empresa / período'), t('2. Finalización')];

  const Descriptions = [
    t('Selecciona la empresa usuaria a la que se ha liquidado, el período y el tipo de defensa'),
    t('La defensa está siendo generada...'),
  ];

  const setButtonDisability = (can: boolean) => {
    setCanGoNext(can);
  };

  const generateDefense = async () => {
    if (fromDate && toDate) {
      props.changeBlockUI(true);
      let from = fromDate.toJSDate();
      let _date = new Date(from.getFullYear(), from.getMonth(), 1);
      let _from = DateTime.fromJSDate(_date);
      let to = toDate.toJSDate();
      let date = new Date(to.getFullYear(), to.getMonth(), 1);
      let _to = DateTime.fromJSDate(date);
      try {
        const response = await props.system.getDefenseFileSystem().generateForAllCompanies(_from, _to);
        if (response) {
          setDefenseGenerated(response);
          setCanGoNext(true);
          props.courier.messageSuccess(t('La defensa fue generada exitosamente'));
        }
      } catch (error) {
        props.courier.messageError(error);
      } finally {
        props.changeBlockUI(false);
        setHasGenerated(true);
      }
    }
  };

  const itemsMenu = React.useMemo(
      (): ICommandBarItemProps[] => [
        {
          key: 'title',
          text: t('Defensa masiva'),
          iconProps: {
            iconName: 'ShieldSolid',
            color: DefaultPalette.themeLighterAlt,
            className: 'text-title-btn',
          },
          buttonStyles: titleCommandBarButtonStyles,
          className: 'text-title-btn',
        },
        {
          key: 'back',
          text: t('Volver'),
          onClick: () => navigate(`${routes.massive_defense_list}`),
          iconProps: { iconName: 'Back' },
          buttonStyles: defaultCommandBarButtonStyles,
        },
      ],
      [navigate]
  );

  const getDateFromDataSelector = (from: DateTime, to: DateTime) => {
    setFromDate(from);
    setToDate(to);
  };

  const Selectors = [
    <DateSelector
        system={props.system}
        courier={props.courier}
        getDataFromDateSelector={getDateFromDataSelector}
        generateDefense={generateDefense}
        setButtonDisability={setButtonDisability}
        uploadedDocuments={uploadedDocuments}
        hasGenerated={hasGenerated}
    />,
    <Ending
        system={props.system}
        // courier={props.courier}
        // setButtonDisability={setButtonDisability}
        // dataTable={uploadedDocuments}
        // downloadUploadedDocument={downloadUploadedDocument}
    />,
  ];
  return (
      <ViewWrapperWithCommandBar
          withBorderBottom
          title={''}
          items={itemsMenu}
          overflowButtonStyles={defaultCommandBarButtonStyles}
          variant="highligth"
          titleBody={t('Proyectos')}
      >
        <div className="padding-1">
          <div className="ms-Grid bg-white-h100" dir="ltr">
            <div className="ms-Grid-row">
              <div className="ms-Grid-col ms-sm12 mt-2 ml-1 defense-file-container-desc">
                <Text className="ml-10px defense-file-title">
                  {t('Asistente para la descarga de la defensa masiva de archivos')}
                </Text>
              </div>
            </div>
            <div className="ms-Grid-row">
              <div className="ms-Grid-col ms-sm12">
                <Wizard
                    componentsTitlesArray={Titles}
                    componentsDescriptions={Descriptions}
                    componentsArray={Selectors}
                    canGoNext={canGoNext}
                />
              </div>
            </div>
          </div>
        </div>
      </ViewWrapperWithCommandBar>
  );
};

export default MassiveDefenseView;
