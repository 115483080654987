import { t } from 'i18next'
import { DetailsListView, EntityGridListProps } from '../Common/DetailsList/DetailListView'
import { Icon } from 'office-ui-fabric-react'

export const ReportingMMIGridList = (props: EntityGridListProps) => {
  const columns = [
    {
      fieldName: 'mail_fecha',
      name: 'Fecha',
      minWidth: 100,
      maxWidth: 100,
    },
    {
      fieldName: 'mail_from',
      name: 'Emisor (Correo)',
      minWidth: 50,
      maxWidth: 100,
    },
    {
      fieldName: 'mail_to',
      name: 'Receptor (Correo)',
      minWidth: 50,
      maxWidth: 100,
    },
    {
      fieldName: 'mail_subject',
      name: 'Asunto',
      minWidth: 150,
      maxWidth: 200,
    },
    {
      fieldName: 'tiene_docs',
      name: 'Tiene documentos adjuntos',
      _onRender: (item: any) => {
        if (item.tiene_docs === false) return <Icon className="text-icon-small" iconName="Cancel" />
        else return <Icon className="text-icon-small success-color" iconName="Accept" />
      },
      minWidth: 50,
      maxWidth: 50,
    },
    {
      fieldName: 'emp_nombre',
      name: 'Empresa',
      minWidth: 100,
      maxWidth: 150,
    },
    {
      fieldName: 'proyecto',
      name: 'Proyecto',
      minWidth: 100,
      maxWidth: 150,
    },
    {
      fieldName: 'cantidad_ocurrencias',
      name: 'Multiproyecto',
      _onRender: (item: any) => {
        if (item.cantidad_ocurrencias === "1") return <Icon className="text-icon-small" iconName="Cancel" />
        else return <><Icon className="text-icon-small success-color" iconName="Accept" /> ({item.cantidad_ocurrencias})</>
      },
      minWidth: 50,
      maxWidth: 50,
    },
  ]
  return (
      <>
        <DetailsListView
            keyList={props.keyList}
            onClickCardMode={() => props.onClickCardMode()}
            onClickGridMode={() => props.onClickGridMode()}
            mode={props.mode}
            title={t('Correos (MMI)')}
            onItemClick={props.onItemClick}
            cols={columns}
            isGridList={true}
            listFilters={[]}
            quickFilters={props.quickFilters}
            baseUrl={props.baseUrl}
            changeBlockUI={props.changeBlockUI}
            courier={props.courier}
        ></DetailsListView>
      </>
  )
}
