export class SettlementProjectInPeriodItem {
  private constructor(
      private id: string,
      private numberOfDocuments: number,
      private amount: number,
      private description: string,
      private order: number
  ) {}

  static identifiedAs(
      id: string,
      numberOfDocuments: number,
      amount: number,
      description: string,
      order: number
  ) {
    return new this(id, numberOfDocuments, amount, description, order);
  }

  maxOrder(order: number): number {
    return this.order >= order ? this.order : order;
  }

  isIdentifiedBy(anId: string): boolean {
    return +this.id === +anId;
  }

  getId(): string {
    return this.id;
  }
}
