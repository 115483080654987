import { Icon, Text } from '@fluentui/react';
import React from 'react';
import DialogTemplate from '../Common/DialogTemplate';
import { useTranslation, Trans } from 'react-i18next';

export interface ConfirmDownloadPreSettlementProps {
  show: boolean;
  onClose: (accept: boolean) => void;
  periodo: string;
}

export const ConfirmDownloadPreSettlement = (props: ConfirmDownloadPreSettlementProps) => {
  const { t, i18n } = useTranslation();

  const cancel = () => {
    props.onClose(false);
  };

  const accept = () => {
    props.onClose(true);
  };

  return (
      <DialogTemplate
          showDialog={props.show}
          toggleShowDialog={() => props.onClose(false)}
          title={t('Descargar detalle de preliquidación')}
          onAccept={accept}
          onCancel={cancel}
          acceptText={t('Confirm')}
          cancelText={t('Cancel')}
          minWidth={600}
      >
        <div>
          <Trans>
            <Text block variant="medium">
              <Icon iconName="Warning" className="text-icon" />
              {t(
                      'Si el período seleccionado (') +
                  props.periodo +
                  t(') es extenso la descarga podría demorar algunos minutos, quieres descargarlo de todas formas? Podrás seguir utilizando la aplicación.'
                  )}
            </Text>
          </Trans>
        </div>
      </DialogTemplate>
  );
};
