import {
  DocumentCard,
  DocumentCardActivity,
  DocumentCardDetails,
  DocumentCardPreview,
  DocumentCardTitle,
  IDocumentCardPreviewProps,
  DocumentCardType,
  IDocumentCardActivityPerson,
} from '@fluentui/react/lib/DocumentCard';
import { getTheme } from '@fluentui/react/lib/Styling';
import { DetailsListView } from '../../Common/DetailsList/DetailListView';
import './CollaboratorList.css';

const theme = getTheme();
const { palette, fonts } = theme;

export const CollaboratorCardList = (props: any) => {
  const getPeople = (
      col_iniciales: string,
      col_nombreYApellido: string,
      owner_actividad_img_url: string
  ): IDocumentCardActivityPerson[] => {
    return [
      {
        name: col_nombreYApellido,
        profileImageSrc: owner_actividad_img_url,
        initials: col_iniciales,
      },
    ];
  };

  const previewPropsUsingIcon: IDocumentCardPreviewProps = {
    previewImages: [
      {
        previewIconProps: {
          iconName: 'ReminderPerson',
          styles: {
            root: {
              fontSize: fonts.superLarge.fontSize,
              color: '#0078d7',
              backgroundColor: palette.neutralLighterAlt,
            },
          },
        },
        width: 144,
      },
    ],
    styles: {
      previewIcon: { backgroundColor: palette.neutralLighterAlt },
    },
  };

  const mapCard = (collaborator: any, i: number) => {
    return (
        <div className="ms-Grid-col ms-sm6 ms-md12 ms-lg6 ms-xl4" key={i}>
          <DocumentCard className="size-document-card" type={DocumentCardType.compact}>
            <DocumentCardPreview {...previewPropsUsingIcon} className="size-logo" />

            <DocumentCardDetails>
              <DocumentCardActivity
                  activity={collaborator.cargo_nombre}
                  people={getPeople(
                      collaborator.col_iniciales,
                      collaborator.col_nombre + ' ' + collaborator.col_apellido,
                      collaborator.owner_actividad_img_url
                  )}
              />
              {/* <DocumentCardTitle title={`${project.pro_codigo}`} shouldTruncate /> */}
              {/* <DocumentCardTitle   title={collaborator.col_nombre + ' ' + collaborator.col_apellido} shouldTruncate /> */}
              <DocumentCardTitle
                  title={collaborator.col_email}
                  className="collaborator-email"
                  // showAsSecondaryTitle
              />
              <DocumentCardTitle
                  title={collaborator.sector}
                  className="collaborator-details"
                  showAsSecondaryTitle
              />
              {/* <DocumentCardStatus statusIcon="attach" status=" 3 Documentos" /> */}
            </DocumentCardDetails>
          </DocumentCard>{' '}
        </div>
    );
  };

  return (
      <DetailsListView
          keyList={'colaboradores'}
          onClickCardMode={() => props.onClickCardMode()}
          onClickGridMode={() => props.onClickGridMode()}
          title={'Colaboradores'}
          mode={props.mode}
          isGridList={false}
          // virtualize={false}
          listFilters={[]}
          quickFilters={props.quickFilters}
          baseUrl={props.baseUrl}
          mapCard={mapCard}
          changeBlockUI={props.changeBlockUI}
          courier={props.courier}
      ></DetailsListView>
  );
};

export default CollaboratorCardList;
