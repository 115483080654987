import { ProjectCompanyInDistribution } from './IProjectCompanyInDistribution';
import { ProjectCompanyJson } from './ProjectCompany';
import { ProjectCompanyDistributionAssertions } from './ProjectCompanyDistributionAssertions';

export class ProjectCompanyDistribution {
  private constructor(private companies: ProjectCompanyInDistribution[]) {}

  static forCompanies(companies: ProjectCompanyInDistribution[], billable: boolean) {
    const assertions = ProjectCompanyDistributionAssertions.build(billable);
    assertions.evaluateAllAssertions({ companies });
    return new this(companies);
  }

  static forCompaniesAsserted(companies: ProjectCompanyInDistribution[], billable: boolean) {
    return new this(companies);
  }

  asJson(): ProjectCompanyJson[] {
    return this.companies.map((company) => company.asJson());
  }

  getCompanies() {
    return this.companies;
  }
}
