import React, { useEffect, useState, useMemo } from 'react';
import { Stack, IStackTokens } from '@fluentui/react/lib/Stack';
import { Text } from '@fluentui/react/lib/Text';
import { Icon } from '@fluentui/react/lib/Icon';
import { CARD_MODE, GRID_MODE, OPERATORS, TYPES } from '../../../common/list-utils';
import { DefaultButton, PrimaryButton } from '@fluentui/react/lib/Button';
import { IDocumentCardPreviewProps, IDocumentCardActivityPerson } from '@fluentui/react/lib/DocumentCard';
import { DefaultPalette, getTheme } from '@fluentui/react/lib/Styling';
import { TestImages } from '@fluentui/example-data';
import axios from 'axios';
import { ListColumnFilter } from '../../Common/list-entities/ListColumnFilter';
import { IDatagrid } from '../../Common/DetailsList/DetailListView';
import { timeSince } from '../../../common/list-utils';
import { boldStyle } from '../../Common/TextStyles';
import TimeTaxSupportSystemClient from '../../../system';
import { IComboBoxOption, ICommandBarItemProps } from '@fluentui/react';
import { buttonStylesForProjects, defaultCommandBarButtonStyles } from '../../../styles/button-styles';
import { routes } from '../../../common/constants';
import { useNavigate } from 'react-router-dom';
import ViewWrapperWithCommandBar from '../../Layout/ViewWrapperWithCommandBar';
import { useTranslation, Trans } from 'react-i18next';
import MessageCourier from '../../../lib/MessageCourier';
import { DirectSettlementsGridList } from './List/DirectSettlementsGridList';
import useSystemCall from '../../../common/custom-hooks/useSystemCall';
import { DateTime } from 'luxon';

const stackTokens: IStackTokens = { childrenGap: 20 };
const theme = getTheme();
const { palette, fonts } = theme;

interface IDirectSettlementsList {
  system: TimeTaxSupportSystemClient;
  changeBlockUI: (state: boolean) => void;
  courier: MessageCourier;
}
export const DirectSettlementsList = (props: IDirectSettlementsList) => {
  const { t, i18n } = useTranslation();
  const [mode, setMode] = useState(GRID_MODE);
  const [filtersData, setFiltersData] = useState<any>();
  const [filterPanel, setFilterPanel] = useState(false);

  const navigate = useNavigate();

  const baseUrl = React.useMemo(() => {
    return props.system.getSettlementSystem().getBaseUrlForDatagridDirect();
  }, [props]);

  const onItemClick = (item: any) => {
    navigate(`${routes.direct_settlement}/${item.liq_id}`);
  };

  const commonContext = useSystemCall(props.system, (system) =>
      system.getActivitySystem().getCommonContext()
  );

  const collaboratorList = React.useMemo(() => {
    let ret: IComboBoxOption[] = [];
    if (commonContext) {
      ret = commonContext.colaboradores.map((col) => {
        return {
          key: col.fullName(),
          text: col.fullName(),
        };
      });
    }
    return ret;
  }, [commonContext]);

  const estadosOptions: IComboBoxOption[] = React.useMemo(() => {
    return [
      { key: 'Provisoria', text: 'Provisoria' },
      { key: 'Confirmada', text: 'Confirmada' },
      { key: 'Rechazada', text: 'Rechazada' },
    ];
  }, []);

  const quickFilters: ListColumnFilter[] = useMemo(
      () => [
        {
          quick: true,
          name: 'liq_id',
          type: TYPES.INPUT,
          placeholder: t('ID'),
          operator: OPERATORS.EQUAL,
        },
        {
          quick: true,
          name: 'col_nombre',
          type: TYPES.COMBOBOX,
          placeholder: t('Cargado por'),
          operator: OPERATORS.EQUAL,
          contextForCombobox: collaboratorList || [],
        },
        {
          quick: true,
          name: 'liq_activa',
          type: TYPES.CHECKBOX,
          labelForCheckbox: t('Activos'),
          checkBoxValue: '1',
          operator: OPERATORS.EQUAL,
        },
        // {
        //   quick: true,
        //   name: 'liq_desde',
        //   type: TYPES.DATE,
        //   placeholder: t('Período'),
        //   operator: OPERATORS.CONTAINS,
        //   // formatDate: (d) => (d ? DateTime.fromJSDate(d).toFormat('LL/yyyy') : ''),
        // },

        {
          quick: false,
          name: 'liq_fecha_carga',
          type: TYPES.DATE,
          placeholder: t('Generadas despues del'),
          operator: OPERATORS.BEFOREORON,
        },
        {
          quick: false,
          name: 'liq_estado',
          type: TYPES.DROPDOWN,
          placeholder: t('Estado'),
          operator: OPERATORS.EQUAL,
          contextForDropdown: estadosOptions,
        },

        {
          quick: false,
          name: 'liq_tipo',
          type: TYPES.CHECKBOX,
          checkBoxValue: 'D',
          placeholder: t('Tipo'),
          operator: OPERATORS.EQUAL,
          hidden: true,
          defaultValue: 'D',
        },
      ],
      [filtersData, collaboratorList]
  );

  const itemsMenuCalendar = React.useMemo(
      (): ICommandBarItemProps[] => [
        {
          key: 'add',
          text: t('Nuevo'),
          iconProps: { iconName: 'Add', color: DefaultPalette.themeLighterAlt },
          buttonStyles: buttonStylesForProjects,
          onClick: () => navigate(`${routes.directSettlementView}`),
        },
      ],
      [navigate]
  );

  return (
      <ViewWrapperWithCommandBar
          withBorderBottom
          title={''}
          items={itemsMenuCalendar}
          overflowButtonStyles={defaultCommandBarButtonStyles}
          variant="standard"
      >
        <>
          <DirectSettlementsGridList
              changeBlockUI={props.changeBlockUI}
              baseUrl={baseUrl}
              quickFilters={quickFilters}
              onClickCardMode={() => setMode(CARD_MODE)}
              onClickGridMode={() => setMode(GRID_MODE)}
              mode={mode}
              keyList={'liquidaciones_directas'}
              onItemClick={onItemClick}
              courier={props.courier}
          ></DirectSettlementsGridList>

          {/* {mode == GRID_MODE ? (
        ) : (
          <SettlementsCardList
            changeBlockUI={props.changeBlockUI}
            baseUrl={baseUrl}
            quickFilters={quickFilters}
            filterPanel={filterPanel}
            onClickCardMode={() => setMode(CARD_MODE)}
            onClickGridMode={() => setMode(GRID_MODE)}
            courier={props.courier}
            mode={mode}
            onItemClick={onItemClick}
          ></SettlementsCardList>
        )} */}
        </>
      </ViewWrapperWithCommandBar>
  );
};
