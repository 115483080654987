import React, { useEffect, useState, useMemo } from 'react';
import { Stack, IStackTokens } from '@fluentui/react/lib/Stack';
import { Text } from '@fluentui/react/lib/Text';
import { Icon } from '@fluentui/react/lib/Icon';
import { CARD_MODE, GRID_MODE, OPERATORS, TYPES } from '../../../common/list-utils';
import { DefaultButton, PrimaryButton } from '@fluentui/react/lib/Button';
// import { ArchivosDefensaCardList } from './List/ArchivosDefensaCardList';
// import { ArchivosDefensaGridList } from './List/ArchivosDefensaGridList';
import { ListColumnFilter } from '../../Common/list-entities/ListColumnFilter';
import { IDocumentCardPreviewProps, IDocumentCardActivityPerson } from '@fluentui/react/lib/DocumentCard';
import { DefaultPalette, getTheme } from '@fluentui/react/lib/Styling';
import { TestImages } from '@fluentui/example-data';
import axios from 'axios';
import { IDatagrid } from '../../Common/DetailsList/DetailListView';
import { timeSince } from '../../../common/list-utils';
import { boldStyle } from '../../Common/TextStyles';
import TimeTaxSupportSystemClient from '../../../system';
import { ICommandBarItemProps, IDropdownOption } from '@fluentui/react';
import { routes } from '../../../common/constants';
import { useNavigate } from 'react-router-dom';
import { buttonStylesForProjects, defaultCommandBarButtonStyles } from '../../../styles/button-styles';
import ViewWrapperWithCommandBar from '../../Layout/ViewWrapperWithCommandBar';
import { useTranslation, Trans } from 'react-i18next';
import MessageCourier from '../../../lib/MessageCourier';
import { ArchivosDefensaGridList } from '../Defense-file/List/ArchivosDefensaGridList';
import { ArchivosDefensaCardList } from '../Defense-file/List/ArchivosDefensaCardList';
import { MassiveDefenseGridList } from './MassiveDefenseGridList';

const stackTokens: IStackTokens = { childrenGap: 20 };
const theme = getTheme();
const { palette, fonts } = theme;

interface IArchivosDefensaListProps {
  system: TimeTaxSupportSystemClient;
  changeBlockUI: (state: boolean) => void;
  courier: MessageCourier;
}

export const MassiveDefenseList = (props: IArchivosDefensaListProps) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const [mode, setMode] = useState(GRID_MODE);
  const [filtersData, setFiltersData] = useState<any>();
  const [filterPanel, setFilterPanel] = useState(false);
  const [downloadUri, setDownloadUri] = useState('');

  const baseUrl = React.useMemo(() => {
    return props.system.getDefenseFileSystem().getBaseUrlForDatagridBySettlement();
  }, [props.system]);

  const mapContextEstadoForFilter = () => {
    var estadosMapped: IDropdownOption<any>[] = [
      { key: 'pendienteGeneracionEstructura', text: t('pendienteGeneracionEstructura') },
      { key: 'zipGenerado', text: t('zipGenerado') },
    ];
    return estadosMapped;
  };

  const quickFilters: ListColumnFilter[] = useMemo(
      () => [
        {
          quick: true,
          name: 'adl_id',
          type: TYPES.INPUT,
          placeholder: t('ID'),
          operator: OPERATORS.EQUAL,
        },
        {
          quick: true,
          name: 'adl_creado_por',
          type: TYPES.INPUT,
          placeholder: t('Creador'),
          operator: OPERATORS.CONTAINS,
        },
        {
          quick: true,
          name: 'emp_nombre',
          type: TYPES.INPUT,
          placeholder: t('Empresa nombre'),
          operator: OPERATORS.CONTAINS,
        },
        {
          quick: false,
          name: 'adl_fecha_carga',
          type: TYPES.DATE,
          placeholder: t('Inicio posterior a'),
          operator: OPERATORS.BEFOREORON,
        },
        {
          quick: false,
          name: 'adl_estado',
          type: TYPES.DROPDOWN,
          placeholder: t('Estado'),
          operator: OPERATORS.EQUAL,
          contextForDropdown: mapContextEstadoForFilter(),
        },
      ],
      []
  );

  const onItemClick = (item: any) => {
    navigate(`${routes.massive_defense}/${item.adl_id}`);
  };

  const itemsMenuCalendar = React.useMemo(
      (): ICommandBarItemProps[] => [
        {
          key: 'add',
          text: t('Nuevo'),
          iconProps: { iconName: 'Add', color: DefaultPalette.themeLighterAlt },
          buttonStyles: buttonStylesForProjects,
          onClick: () => navigate(`${routes.massive_defense_view}`),
        },
      ],
      [navigate]
  );

  return (
      <ViewWrapperWithCommandBar
          withBorderBottom
          title={''}
          items={itemsMenuCalendar}
          overflowButtonStyles={defaultCommandBarButtonStyles}
          variant="standard"
      >
        <>
          {mode === GRID_MODE ? (
              <MassiveDefenseGridList
                  baseUrl={baseUrl}
                  quickFilters={quickFilters}
                  onClickCardMode={() => setMode(CARD_MODE)}
                  onClickGridMode={() => setMode(GRID_MODE)}
                  onItemClick={onItemClick}
                  mode={mode}
                  keyList="defense_file_list"
                  changeBlockUI={props.changeBlockUI}
                  courier={props.courier}
              ></MassiveDefenseGridList>
          ) : (
              <ArchivosDefensaCardList
                  changeBlockUI={props.changeBlockUI}
                  baseUrl={baseUrl}
                  quickFilters={quickFilters}
                  filterPanel={filterPanel}
                  onClickCardMode={() => setMode(CARD_MODE)}
                  onClickGridMode={() => setMode(GRID_MODE)}
                  onItemClick={onItemClick}
                  mode={mode}
              ></ArchivosDefensaCardList>
          )}
        </>
      </ViewWrapperWithCommandBar>
  );
};
