import { DateTime } from 'luxon';
import AssertionEntity from '../../../lib/FormValidator/Assertion/AssertionEntity';
import CustomForm from '../../../lib/FormValidator/Form/CustomForm';
import CustomList from '../../../lib/FormValidator/Form/CustomList';
import { Collaborator } from '../../../system/collaborators/Collaborator';
import CollaboratorTeam from '../../../system/collaborators/collaborator_team/CollaboratorTeam';
import DocumentUploadControl from '../../../system/documents/DocumentUploadControl';
import SystemDocumentType from '../../../system/documents/SystemDocumentType';
import ProjectTeam from '../../../system/projects/ProjectTeam';
import ProjectDocumentControlAssertions from '../../../system/projects/ProjectDocumentControlAssertions';
import CollaboratorTeamForm from './CollaboratorTeamForm';
import DocumentUploadControlPending from '../../../system/documents/DocumentUploadControlPending';

export class ProjectConfigurationDocsForm extends CustomList<DocumentUploadControl> {
  static defaultBuild(propertyName: string, items: DocumentUploadControl[]) {
    const instance = new this(propertyName, items, new AssertionEntity());
    instance.evaluate();
    return instance;
  }

  static initialize(propertyName: string): ProjectConfigurationDocsForm {
    return this.defaultBuild(propertyName, []);
  }

  initializeItemForm() {
    return ProjectDocumentControlForm.initialize();
  }

  forItem(documentControlSelected: DocumentUploadControl): ProjectDocumentControlForm {
    return ProjectDocumentControlForm.with(documentControlSelected);
  }

  add(item: DocumentUploadControl) {
    super.add(item);
    return [...this.items];
  }

  replace(doc: DocumentUploadControl, docToReplace: DocumentUploadControl) {
    let toReplace = this.items.find((item) => item === docToReplace);
    if (toReplace) {
      toReplace.syncWith(doc);
    }
    return [...this.items];
  }

  static fromForm(customList: ProjectConfigurationDocsForm): DocumentUploadControl[] {
    return customList.getValue();
  }
}

export class ProjectDocumentControlForm extends CustomForm {
  static readonly CONCEPT = 'concept';
  static readonly DOCUMENT_TYPE = 'document_type';
  static readonly RESPONSIBLE = 'responsible';
  static readonly LIMITDATE = 'limitdate';

  protected constructor(
      concept: string,
      documentType: SystemDocumentType | null,
      responsible: Collaborator | null,
      limitDate: DateTime
  ) {
    const pdca = ProjectDocumentControlAssertions.build();
    super('ProjectDocumentControl', pdca);

    this.addInput(ProjectDocumentControlForm.CONCEPT, concept);
    this.addInput(ProjectDocumentControlForm.DOCUMENT_TYPE, documentType);
    this.addInput(ProjectDocumentControlForm.RESPONSIBLE, responsible);
    this.addInputAsserted(ProjectDocumentControlForm.LIMITDATE, limitDate);
  }

  private static build(
      concept: string,
      documentType: SystemDocumentType | null,
      responsible: Collaborator | null,
      limitDate: DateTime
  ) {
    const instance = new this(concept, documentType, responsible, limitDate);
    return instance;
  }

  static initialize() {
    return this.build('', null, null, DateTime.now());
  }

  static with(doc: DocumentUploadControl) {
    const instance = this.build(
        doc.getConcept(),
        doc.getDocumentType(),
        doc.getCollaborator(),
        doc.getLimitDate()
    );
    instance.evaluate();
    return instance;
  }

  getConceptInput() {
    return this.getInputByName(ProjectDocumentControlForm.CONCEPT);
  }

  getDocumentTypeInput() {
    return this.getInputByName(ProjectDocumentControlForm.DOCUMENT_TYPE);
  }

  getResponsibleInput() {
    return this.getInputByName(ProjectDocumentControlForm.RESPONSIBLE);
  }

  getLimitDateInput() {
    return this.getInputByName(ProjectDocumentControlForm.LIMITDATE);
  }

  buildDocumentControl(): DocumentUploadControl {
    return DocumentUploadControlPending.from(
        this.getDocumentTypeInput().getValue(),
        this.getResponsibleInput().getValue(),
        this.getConceptInput().getValue(),
        this.getLimitDateInput().getValue()
    );
  }
}
