import React from 'react';
import { IIconProps, Text } from '@fluentui/react';
import { IconButton } from '@fluentui/react/lib/Button';
import { CallableFn } from './CustomInput';
import CustomInputIfc from './CustomInputIfc';
import { useBoolean } from '@fluentui/react-hooks';
import { SHOW_HORAS_ESTIMADAS } from '../../../common/constants';
import { useTranslation, Trans } from 'react-i18next';

interface IButtonInput {
  checked: boolean;
  input: CustomInputIfc;
  onBlur: (input: CustomInputIfc) => void;
  onIcon: string;
  offIcon: string;
  onChangeExtended?: (input: CustomInputIfc) => void;
}

const ButtonInput = (props: IButtonInput) => {
  const { t, i18n } = useTranslation();
  const [updateFlag, setUpdateFlag] = React.useState(false);

  const updateViewAfterDoing = (anAction: CallableFn) => {
    setUpdateFlag((u) => {
      anAction();
      return !u;
    });
  };

  const onBlurCustomInput = () => {
    updateViewAfterDoing(() => {
      props.input.onBlur();
      props.onBlur(props.input);
    });
  };

  const onClick = () => {
    updateViewAfterDoing(() => {
      setChecked(!checked);
      props.input.setValue(checked);
      if (props.onChangeExtended) {
        props.onChangeExtended(props.input);
      }

      onBlurCustomInput();
    });
  };

  React.useEffect(() => {
    setChecked(props.checked);
  }, [props.checked]);

  const [checked, setChecked] = React.useState<boolean>(props.checked);
  const onIcon: IIconProps = {
    iconName: props.onIcon,
    className: 'iconResponsible iconForTeamSelected',
  };
  const offIcon: IIconProps = { iconName: props.offIcon, className: 'iconResponsible   iconForTeam' };

  return (
      <>
        <IconButton
            title={checked ? t('Responsable') : t('Participante')}
            onClick={onClick}
            iconProps={checked ? onIcon : offIcon}
            className="d-inline"
        />
        {!SHOW_HORAS_ESTIMADAS && (
            <Text variant="smallPlus" className="mt-1 d-inline legend-responsible-btn">
              {checked ? t('Responsable') : t('Participante')}
            </Text>
        )}
      </>
  );
};

export default ButtonInput;
