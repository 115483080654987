import React from 'react';
import FileFolder from './FileFolder';
import FileDropzone from './FileDropzone';

interface IPlainFileDropzoneProps {
  onDrop: (fileFolder: File[]) => Promise<void>;
  openOnClick?: boolean;
}

const PlainFileDropzone: React.FC<IPlainFileDropzoneProps> = (props) => {
  const onDrop = (folder: FileFolder) => {
    if (!folder.emptyChildren() || !folder.hasFiles()) {
      throw new Error('Expected plain files');
    }

    const files = folder.getFiles();
    return props.onDrop([...files]);
  };

  return (
      <FileDropzone onDrop={onDrop} openOnClick={props.openOnClick}>
        {props.children}
      </FileDropzone>
  );
};

export default PlainFileDropzone;
