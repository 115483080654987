import React, { useEffect, useState, useMemo } from 'react';
import { Stack, IStackTokens } from '@fluentui/react/lib/Stack';
import { Text } from '@fluentui/react/lib/Text';
import { Icon } from '@fluentui/react/lib/Icon';
import { CARD_MODE, GRID_MODE, OPERATORS, TYPES } from '../../../common/list-utils';
import { DefaultButton, PrimaryButton } from '@fluentui/react/lib/Button';
import { SettlementsCardList } from './List/SettlementsCardList';
import { SettlementsGridList } from './List/SettlementsGridList';
import { ListColumnFilter } from '../../Common/list-entities/ListColumnFilter';
import { IDocumentCardPreviewProps, IDocumentCardActivityPerson } from '@fluentui/react/lib/DocumentCard';
import { DefaultPalette, getTheme } from '@fluentui/react/lib/Styling';
import { TestImages } from '@fluentui/example-data';
import axios from 'axios';
import { IDatagrid } from '../../Common/DetailsList/DetailListView';
import { timeSince } from '../../../common/list-utils';
import { boldStyle } from '../../Common/TextStyles';
import TimeTaxSupportSystemClient from '../../../system';
import { ICommandBarItemProps } from '@fluentui/react';
import { buttonStylesForProjects, defaultCommandBarButtonStyles } from '../../../styles/button-styles';
import { routes } from '../../../common/constants';
import { useNavigate } from 'react-router-dom';
import ViewWrapperWithCommandBar from '../../Layout/ViewWrapperWithCommandBar';
import { useTranslation, Trans } from 'react-i18next';
import MessageCourier from '../../../lib/MessageCourier';

const stackTokens: IStackTokens = { childrenGap: 20 };
const theme = getTheme();
const { palette, fonts } = theme;

interface ISettlementsList {
  system: TimeTaxSupportSystemClient;
  changeBlockUI: (state: boolean) => void;
  courier: MessageCourier;
}
export const SettlementsList = (props: ISettlementsList) => {
  const { t, i18n } = useTranslation();
  const [mode, setMode] = useState(GRID_MODE);
  const [filtersData, setFiltersData] = useState<any>();
  const [filterPanel, setFilterPanel] = useState(false);

  const navigate = useNavigate();

  const baseUrl = React.useMemo(() => {
    return props.system.getSettlementSystem().getBaseUrlForDatagrid();
  }, [props]);

  const onItemClick = (item: any) => {
    navigate(`${routes.settlement}/${item.liq_id}`);
  };

  const quickFilters: ListColumnFilter[] = useMemo(
      () => [
        {
          quick: true,
          name: 'liq_id',
          type: TYPES.INPUT,
          placeholder: t('ID'),
          operator: OPERATORS.EQUAL,
        },
        {
          quick: true,
          name: 'emp_nombre',
          type: TYPES.INPUT,
          placeholder: t('Empresa nombre'),
          operator: OPERATORS.CONTAINS,
        },
        {
          quick: true,
          name: 'liq_creado_por',
          type: TYPES.INPUT,
          placeholder: t('Creador'),
          operator: OPERATORS.EQUAL,
        },
        {
          quick: true,
          name: 'liq_activa',
          type: TYPES.CHECKBOX,
          labelForCheckbox: t('Activos'),
          checkBoxValue: '1',
          operator: OPERATORS.EQUAL,
        },
        {
          quick: false,
          name: 'liq_fecha_carga',
          type: TYPES.DATE,
          placeholder: t('Inicio posterior a'),
          operator: OPERATORS.BEFOREORON,
        },
      ],
      [filtersData]
  );

  const itemsMenuCalendar = React.useMemo(
      (): ICommandBarItemProps[] => [
        {
          key: 'add',
          text: t('Nuevo'),
          iconProps: { iconName: 'Add', color: DefaultPalette.themeLighterAlt },
          buttonStyles: buttonStylesForProjects,
          onClick: () => navigate(`${routes.settlementView}`),
        },
      ],
      [navigate]
  );

  return (
      <ViewWrapperWithCommandBar
          withBorderBottom
          title={''}
          items={itemsMenuCalendar}
          overflowButtonStyles={defaultCommandBarButtonStyles}
          variant="standard"
      >
        <>
          {mode == GRID_MODE ? (
              <SettlementsGridList
                  changeBlockUI={props.changeBlockUI}
                  baseUrl={baseUrl}
                  quickFilters={quickFilters}
                  onClickCardMode={() => setMode(CARD_MODE)}
                  onClickGridMode={() => setMode(GRID_MODE)}
                  mode={mode}
                  keyList={'liquidaciones'}
                  onItemClick={onItemClick}
                  courier={props.courier}
              ></SettlementsGridList>
          ) : (
              <SettlementsCardList
                  changeBlockUI={props.changeBlockUI}
                  baseUrl={baseUrl}
                  quickFilters={quickFilters}
                  filterPanel={filterPanel}
                  onClickCardMode={() => setMode(CARD_MODE)}
                  onClickGridMode={() => setMode(GRID_MODE)}
                  courier={props.courier}
                  mode={mode}
                  onItemClick={onItemClick}
              ></SettlementsCardList>
          )}
        </>
      </ViewWrapperWithCommandBar>
  );
};
