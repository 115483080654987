import { ComboBox, IComboBoxOption, TextField , TooltipHost} from "@fluentui/react";
import { FormEvent, useEffect, useRef, useState } from "react";
import { convertHourToMin, convertMinToHour, FileUpload, formatDate, Iactivities, IconPapers, IListFile, LoadingPage } from "./FileUpload";
import { v4 as uuidv4 } from 'uuid';
import { DateCustom } from "../../lib/DateCustom";
import { useTranslation } from "react-i18next";
import { FormUploadHeader, HourMinuteInput, StatusIcon } from "./ImportAct";
import Custoneader from "./Custoneader";
import { AddIcon, IactError, IAddRows, ICollaborator, ICompanyPanel, UploadIcon } from "./InterfaceFormulario";
import { IActivityRecurring, IAddRecurrentActivity, IPlantillaActividadesXColaborador, IPosplantillaActividadesColaborador, ItipoConcepto } from "../../system/projects/ProjectsSystem";

export interface IactivitiesBody {
  activities:Iactivities[];
  col_id: string;
  exercise_period: string;
  timezone: string;
}

export interface IactivitiesResponse {
  data:Iactivities[];
  error:string;
  type:string;
}

function CargarPorFormulario(props: ICompanyPanel) {
  const headers = { idTransaccion:uuidv4(), msgTransaccion:"horas Total Collaborator" }
  const { t, i18n } = useTranslation();
    const [addRows,setAddRows] = useState<IAddRows[]>([]); 
    const [actError, setActError] = useState<IactError>({actVali:0,actvErro:0});
    const [desabled, setDesabled] = useState<boolean>(true);
    
    const [allCollaborators, setAllCollaborators] = useState<IComboBoxOption[]>([]);
    const [filteredCollaborators, setFilteredCollaborators] = useState<IComboBoxOption[]>([]);
    const [collaboratorValue, setCollaboratorValue] = useState<string>('');
    const [collaboratorId, setCollaboratorId] = useState<string>('');
    const [listFile, setListFile] = useState<IListFile[]>([]);

    const [periodo,setPeriod] = useState<Date>(DateCustom.getFirstDayOfThisMonth())
    const [horasTotal, setHorasTotal] = useState<number>(0);
    const [desableAddRow, setDesableAddRow] = useState<boolean>(false);
    const previousValuesMap = useRef<Map<string, number>>(new Map());

    const [mountBlock, setMountBlock] = useState<{[key: number]: number[]}>({});
    const [horasTotalCollaborator,setHorasTotalCollaborator] = useState<number>(0);
    const actividadRecurrentenbasic:IActivityRecurring  = {
      ar_id:"",ar_nombre_descripcion:"",ar_proy_id:"",key:"",
      text:"",ar_activo:1,ar_date_created:new Date(),ar_date_modified:new Date(),ar_eliminado:0,
      ar_nombre_act:"",ar_user_created:"web",ar_user_modified:"web"
    };

    const [listtipoConcept,setListtipoConcept] = useState<ItipoConcepto[]>([]);
    const [filterTipoConsept, setFilterTipoConsept] = useState<ItipoConcepto[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const getAllPeriodBlocks = async () => {
      const periodBlocks = await props.system.getAllPeriodsBlocked();
      const newMountBlocks = periodBlocks
          .map(data => ({ year: data.getFrom().year, month: data.getFrom().month }))
          .reduce((acc, { year, month }) => {
              if (!acc[year]) acc[year] = [];
              acc[year].push(month);
              return acc;
          }, {} as { [key: number]: number[] });
      setMountBlock(newMountBlocks);
  };
    
    const handleSelectCollaborator =async (option: IComboBoxOption | undefined) => {
      props.changeBlockUI(true);
      cleanActiveProject();
      setCollaboratorValue(option?.text || "");
      if (option?.key !== undefined) {
        const data = String(option.key);
        setCollaboratorId(data);
        const exercise_period = formatDate(periodo);
        await  getHorasTal(data,exercise_period);
      }
      props.changeBlockUI(false);
    };

    const getIdfolder= async (id:string)=>{
      const respuesta = await props.system.getActivitySystem().IdFolder(id);
      return respuesta;
    }

    const handleFilterCollaborator = (text: string) => {
      setCollaboratorValue(text);
      const data = allCollaborators.filter(g => g.text.toLowerCase().includes(text.toLowerCase()));
      setFilteredCollaborators(data);
    };

    const formatCollaboratorData = (data: ICollaborator[]) => {
      const result: IComboBoxOption[] = data.map(col => ({
        key: col.id,
        text: `${col.name} ${col.lastName}`,
        active: col.active,
        email: col.email
      }));
      if (!result) return;
      setAllCollaborators(result);
      setFilteredCollaborators(result);
    };

    const fetchCollaborators = async () => {
      try {
        const data = await props.system.getActivitySystem().getCommonContext();
        formatCollaboratorData(data.colaboradores as ICollaborator[]);
      } catch (error) {
        console.error('Error fetching collaborators:', error);
      }
    };

    const fetchProjects = async (userData:string,periodo:string,tipoConcept:string | number |undefined) => {
      try {
        const data = await props.system.getAllProjectsByCollaboratorCargarFormulario(userData,periodo,tipoConcept);
        const transformsci =  data.map(v=>({ key:v.getId(),text:v.getName(), code:v.getCode() }));
        return transformsci
      } catch (error) {
        console.error('Error fetching projects:', error);
      }
    };

    const getLisTipoConcept = async ()=>{
       try{
        const data = await props.system.getProjectSystem().gettipoConsepto(headers.idTransaccion);
        setListtipoConcept(data);
        setFilterTipoConsept(data);
       }catch(error){
        console.log('error fetching tipo concept', error);
       }
    };

    const validateRow = (row: IAddRows, id?: string) => {
      let descripcionError = "";
      const nombreActValido = /^[^\s]/.test(row.nombreAct) && !/\s{2,}/.test(row.nombreAct);
      const regex = /^(?:\d{1,3}:)?(\d{1,2}|undefined)?$/;
      const segundoRegex = /^:\d{1,2}$/;
      const valorMin =  convertHourToMin(row.horasCarg)
      const regexUndefine = /:undefined|undefined/;
    
      if ((!row.nombreAct && !row.horasCarg) || ( !row.nombrePro.text && !row.horasCarg) || (!row.tipoConcepto.text)) {
        descripcionError = "Los campos obligatorios (*) no pueden ser vacíos ";
      } else {
        if(!nombreActValido && (!row.nombrePro || !row.nombrePro.text)){
          descripcionError = "Los campos obligatorios (*) no pueden ser vacíos ";
        }else if (!nombreActValido) {
          descripcionError = "El nombre de la actividad no puede ser vacío. ";
        } else if (row.nombreAct.length <= 3) {
          descripcionError = "El nombre es muy corto. ";
        }else if (!row.nombrePro.text) {
          descripcionError = "Nombre de proyecto vacío. ";
        }
        if (!row.horasCarg) {
          descripcionError = "Horas está vacío. ";
        } else if(valorMin === 0){
          
          if( !row.nombreAct || row.nombreAct === ""){
            descripcionError = "Los campos obligatorios (*) no pueden ser vacíos ";
          }else if((valorMin === 0) && !row.nombrePro.text){
            descripcionError = "Los campos obligatorios (*) no pueden ser vacíos ";
          } else{
             descripcionError = "Hora no valida ";
          }
        }else if (!regex.test(row.horasCarg) && !segundoRegex.test(row.horasCarg) && regexUndefine.test(row.horasCarg)) {
          descripcionError = "Horas solo admite números. ";
        }

        
        if (id) {
          if (row.horasCarg === ":undefined" || row.horasCarg === "" || !row.horasCarg) {

            const prevValue = previousValuesMap.current.get(id) || 0;
            setHorasTotal(horasTotal + prevValue);
            previousValuesMap.current.delete(id);
          } else if (regex.test(row.horasCarg) || segundoRegex.test(row.horasCarg)) {
            const minIngresados = convertHourToMin(row.horasCarg);
            const prevValue = previousValuesMap.current.get(id) || 0;
            if ((horasTotal + prevValue - minIngresados) < 0) {
              descripcionError = "Ya no quedan horas disponibles.";
            }
            setHorasTotal(prevHorasTotal => prevHorasTotal + prevValue - minIngresados);
            previousValuesMap.current.set(id, minIngresados);
          }
        }
      }
    
      return { ...row, descripcionError, valida: descripcionError ? 0 : 1 };
    };

  
    const validateRowById = (rows: IAddRows[], id: string) => {

      const updatedRows = rows.map(row => row.id === id ? validateRow(row,row.id) : row);
      setAddRows(updatedRows);
      HandlerNumError(updatedRows);
    };
    
    const HandlerAddRows = () => {
      const newdata: IAddRows = {
        id: uuidv4(),
        nombrePro: { key: "", text: "" },
        nombreAct: "",
        horasCarg: "",
        file: "",
        valida: 2,
        descripcionError: "nueva fila",
        actividadRecurrente:"si",
        lisActRecurrente:[],
        actividadRecurrenteSelect:actividadRecurrentenbasic,
        tipoConcepto:{key:"",text:"",codigo:""},
        listaProyectos:[],
        filterListProyectos:[],
        descripcion:""
      };
      setAddRows([...addRows, newdata]);
      setDesabled(true);
    };

    const HandlercleanEverything = ()=>{
      setAddRows([]);
      setHorasTotal(horasTotalCollaborator);
      setDesabled(true);
      setLoading(false);
    }

    const HandlerDeleteRows = (id:string)=>{
      const result = addRows.filter(r=>r.id !== id);
      setAddRows(result);
      HandlerNumError(result);
      ///horas 
      const prevValue = previousValuesMap.current.get(id) || 0;
      setHorasTotal(horasTotal + prevValue);
      previousValuesMap.current.delete(id);
    }

    const HandlerNombreAct = (id: string, newValue: string | undefined,option:IActivityRecurring) => {
      const valuess = newValue || "";
      const updatedRows = addRows.map(r => r.id === id ? { ...r, nombreAct: valuess,actividadRecurrenteSelect:option } : r);
      validateRowById(updatedRows,id);
    };

    const HandlerNombreActNuevo = (id:string,text:string)=>{
      const updatedRows = addRows.map(r => r.id === id ? { ...r, nombreAct:text} : r);
        validateRowById(updatedRows, id);
    } 

    const HandlerContHoras = (id: string, newValue: string | undefined, type: 'hour' | 'minute') => {
      const updatedRows = addRows.map(row => {
        if (row.id === id) {
          const [currentHour, currentMinute] = row.horasCarg.split(':');
          const newHour = type === 'hour' ? newValue || '' : currentHour;
          const newMinute = type === 'minute' ? newValue || '' : currentMinute;
          return { ...row, horasCarg: `${newHour}:${newMinute}` };
        }
        return row;
      });
      validateRowById(updatedRows, id);
    };

    const onselectProject = async(option: IComboBoxOption | undefined, id: string, inputText: string | undefined) => {
      const updatedRows = addRows.map(r => r.id === id ? { ...r, nombrePro: option || { key: "", text: inputText || "" },nombreAct:""} : r);
      validateRowById(updatedRows, id);
      setLoading(true);
      const getListActvityRecurring = await props.system.getLisActivityRecurring(String(option?.key),id);
      if(getListActvityRecurring){
        const updatedRowss = updatedRows.map(r => r.id === id ? { ...r,lisActRecurrente:getListActvityRecurring} : r);
          validateRowById(updatedRowss, id);
      }
      setLoading(false);
    };
  
    const ProjectFilterChange = (text: string, id: string,listaProyect:IComboBoxOption[]) => {
      const updatedRows = addRows.map(r => r.id === id ? { ...r, nombrePro: { ...r.nombrePro, text: text || "" },
        filterListProyectos: listaProyect.filter(g => g.text.toLowerCase().includes(text.toLowerCase()))} : r);
        validateRowById(updatedRows, id);
    };

    const onselecTipoConcept = async(option: ItipoConcepto | undefined, id: string) => {
      const updatedRows = addRows.map(r => r.id === id ? { ...r,tipoConcepto:option || {key:"",text:"",codigo:""},
        nombrePro:{ key: "", text: ""},listaProyectos:[],filterListProyectos:[],nombreAct:"" }: r);
      validateRowById(updatedRows, id);
      const exercise_period = formatDate(periodo);
      setLoading(true);
      const data = await fetchProjects(collaboratorId,exercise_period,option?.key);
      if(data){
      const listProyectFiltrado = updatedRows.map(r => r.id === id ? { ...r,listaProyectos:data,filterListProyectos:data} : r);
      validateRowById(listProyectFiltrado, id);
      }
      setLoading(false);
    };
  
    const tipoConceptFilterChange = (text: string, id: string) => {
      const updatedRows = addRows.map(r => r.id === id ? { ...r, tipoConcepto: { ...r.tipoConcepto, text: text || "" } } : r);
      const data = listtipoConcept.filter(g => g.text.toLowerCase().includes(text.toLowerCase()));
      setFilterTipoConsept(data);
      validateRowById(updatedRows, id);
    };
    
    const handlerDescripcion = (data: string | undefined, id:string) => {
      const updatedRows = addRows.map(r => r.id === id? { ...r, descripcion:data || ""}:r);
      validateRowById(updatedRows,id);
    }

    const HandlerPeriodo = async (date:Date)=>{
      props.changeBlockUI(true);
      cleanActiveProject()
      setPeriod(date);
      const exercise_period = formatDate(date);
      await getHorasTal(collaboratorId,exercise_period);
      props.changeBlockUI(false);
    }

    const cleanActiveProject = ()=>{
     setHorasTotal(0);
     setAddRows([]);
     setDesabled(true);
     HandlerNumError([]);
    }

    const cleanActiveProjectsLaterSave = () => {
      setHorasTotal(0);
      setAddRows([]);
      setDesabled(true);
      HandlerNumError([]);
    }
    
    const handleFileSelect = async (file: File,project:IComboBoxOption,id:string) => {
       const idfolder:any = await  getIdfolder(String(project?.key));
      const idFile =await  props.system.documentTypeOtherId();
      if(idFile && idfolder){
        const data:IListFile ={ 
          path:file,
          id,
          file:{
        cmd_proid: project.key,
        cmd_cant_archivos: 1,
        cmd_cant_bytes_total: file.size,
        cmd_tipo: "proyecto",
        cargaDocumentosItems: [
          {
            cmdi_cant_bytes: file.size,
            cmdi_doc_uuid: "0",
            cmdi_nombre_archivo: file.name,
            cmdi_content_type: file.type,
            doctId: idFile,
            tipo: "proyecto"
          }
        ],
        folderId: String(idfolder?.pca_id)
        }
      }

      const updatedListFile = listFile.map(item => 
        item.id === data.id ? data : item
      );
      
      if (!listFile.some(item => item.id === data.id)) {
        updatedListFile.push(data);
      }
      
      setListFile(updatedListFile);
      }
    };

    const saveFile = async () => {
      for (const item of listFile) {
        try {
           await props.system.getActivitySystem().UploadfileForm(item.file, item.path);
        } catch (error) {
          console.error('Error uploading file:', error);
        }
      }
    };

    const HandlerNumError = (validados: IAddRows[])=>{
        const actVali = validados.filter(g=>g.valida === 1);
        const actvErro = validados.filter(g=>g.valida === 0);
        const actvEarring = validados.filter(g=>g.valida === 2);
        setActError({actVali:actVali.length,actvErro:actvErro.length});
       if((actvErro.length === 0) && (validados.length !== 0) && (actvEarring.length === 0)){
         setDesabled(false);
       }else{
        setDesabled(true);
       } 
    };

    const onsubmit = async (ev: FormEvent<HTMLFormElement>) => {
     ev.preventDefault();
     props.changeBlockUI(true);
        if(addRows){
          const tranForm = addRows.map(row =>{
              const numer = convertHourToMin(row.horasCarg as any);
              return {
                impai_nombre: row.nombreAct,
                impai_cantidad_horas : convertMinToHour(numer),
                impai_codigo_proyecto: row.nombrePro.code,
                impai_proid: row.nombrePro.key,
                impai_con_error: false,
                impai_descripcion_error: row.descripcionError,
                impai_descripcion: row.descripcion
              }
          });
          
          const body:IactivitiesBody = {
            activities:tranForm,
            col_id: collaboratorId, 
            exercise_period:DateCustom.formatDateYYYYMM(periodo), 
            timezone: "America/Lima" 
          };
          setDesabled(true)
          const numProjetct = addRows.length;
          const result = await props.system.getActivitySystem().saveActivity(headers,body); 
          await saveFile();
            if(result.error.length){
              props.courier.messageError(t('Hubo un error inesperado. Por favor, intenta nuevamente más tarde'));
              props.changeBlockUI(false);
            }else{
            //  cleanActiveProject();
              cleanActiveProjectsLaterSave();
              postPlantillaActividadesXColaborador();
              getHorasTal(collaboratorId,DateCustom.formatDateYYYYMM(periodo));
              props.courier.messageSuccess(t(`Se carg${numProjetct===1?"o":"aron"} ${numProjetct} fila${numProjetct===1?"":"s"} de manera exitosa`));
            } 
        }
      props.changeBlockUI(false);
    };

    const postPlantillaActividadesXColaborador = async () => {
      const parceData:IPosplantillaActividadesColaborador[] = await Promise.all(addRows.map( async row =>{
        let ar_id:string = row.actividadRecurrenteSelect?.ar_id ||"";
            if(ar_id === ""){
              const recuperation ={
                codigoConcepto:parseInt(row.tipoConcepto.key as string) ,
                codigoProyecto:parseInt(row.nombrePro.key as string),
                nombreActividad:row.nombreAct,
              }
              const {ar_id_data} = await props.system.postAddRecurrentActivity(recuperation,headers.idTransaccion);
              ar_id=ar_id_data;
            }
        return{
          paxc_col_id:collaboratorId,
          paxc_data:{
            ar_id:ar_id,
            proy_id:row.nombrePro.key,
            recurrent:row.actividadRecurrente,
            tc_id:row.tipoConcepto.key
          }
        }
      })) as any  ;
      
        await props.system.postPlantillaActividadesXColaborador( parceData,headers.idTransaccion);
      
    };


    const getHorasTal = async (col_id:string,exercise_period:string)=>{
      setDesableAddRow(true)
      const body = { col_id,  exercise_period}
      const result = await props.system.getActivitySystem().saveActivityResult(headers,body);
      const data = result as any
      if(data.data){
       setHorasTotal(data?.data?.pending_minutes);
       setHorasTotalCollaborator(data?.data?.pending_minutes);
       setDesableAddRow(()=>false);
      }
    };

    const getListActivityRecurring = async(collaboratorId:string)=>{
      setLoading(true);
      const uuidTransaccion=uuidv4();
      const exercise_period = formatDate(periodo);
      const data = await props.system.getAllProjectsByCollaboratorCargarFormulario(collaboratorId,exercise_period);
      const projectsList =  data.map(v=>({ key:v.getId(),text:v.getName(), code:v.getCode() }));
      const projectValided = projectsList.map(item=>item?.key);

        if(projectValided.length !== 0){
          const activity:IPlantillaActividadesXColaborador[] = await props.system.getPlantillaActividadesXColaborador(collaboratorId,headers.idTransaccion,projectValided);
          if(activity.length === 0) {
            HandlerAddRows();
          }else{
                const listActivity:IAddRows[] = await Promise.all (activity.map( async item =>{
                const getListActvityRecurring = await props.system.getLisActivityRecurring(String(item.paxc_data.proy_id),uuidTransaccion);
                const exercise_period = formatDate(periodo);
                const data = await fetchProjects(collaboratorId,exercise_period,item.paxc_data.tipoConcepto.tc_id)
                const newdata: IAddRows = {
                id: uuidv4(),
                nombrePro: { key: item.paxc_data.proy_id, text:item.paxc_data.pro_nombre },
                nombreAct: item.paxc_data.ar_nombre_act,
                horasCarg: "",
                file: "",
                valida: 2,
                descripcionError: "actividades recurrentes",
                actividadRecurrente:item.paxc_data.recurrent,
                lisActRecurrente:getListActvityRecurring,
                actividadRecurrenteSelect:{...actividadRecurrentenbasic,ar_id:item.paxc_data.ar_id},
                tipoConcepto:{key:item.paxc_data.tipoConcepto.tc_id,
                              text:item.paxc_data.tipoConcepto.tc_nombre,codigo:item.paxc_data.tipoConcepto.tc_codigo},
                listaProyectos:data || [],
                filterListProyectos:data || [],
                descripcion:""
              };
                return newdata;
              }))
              setAddRows(listActivity);
          }
        }
        setLoading(false);
    }
      
      useEffect(() => {
        if(collaboratorId ){
          getListActivityRecurring(collaboratorId)
        }
      },[periodo,collaboratorId]);

      useEffect(()=>{
        getLisTipoConcept();
        getAllPeriodBlocks();
        if(props.user){
          handleSelectCollaborator({key:props.user.getCollaboratorId(),text:props.user.getCollaborator().getName() +" "+ props.user.getCollaborator().getLastName()});
        }
        fetchCollaborators()
      },[]);
      
    return (
      <form className="ms-Grid" dir="ltr" style={{minHeight:"100%",fontFamily: 'Segoe UI, "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif'}} onSubmit={onsubmit}>
          <FormUploadHeader/>
          <Custoneader mountBlock={mountBlock} filteredCollaborators={filteredCollaborators} collaboratorValue = {collaboratorValue} handleSelectCollaborator = {handleSelectCollaborator}
          handleFilterCollaborator = {handleFilterCollaborator} periodo = {periodo} HandlerPeriodo = {HandlerPeriodo} horasTotal = {horasTotal}   addrowlength = {addRows.length}
          convertMinToHour ={convertMinToHour} desabled = {desabled}  UploadIcon = {UploadIcon} actError ={actError} tipoConcept = {listtipoConcept}
          desableAddRow = {desableAddRow}  HandlerAddRows = {HandlerAddRows}  AddIcon = {AddIcon} dataUser = {props.user} HandlercleanEverything={HandlercleanEverything}
          />
          <div >
           <div style={{ overflowX: "auto" }}>
                <div style={{ minWidth: "1180px",minHeight:"150px" ,maxWidth:"1250px" }}>
                    <div  style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    <span style={{ display: "flex", justifyContent: "center", width: "150px", textAlign: "center",margin: "0 5px" }}> Concepto (*)</span>
                    <span style={{ display: "flex", justifyContent: "center", width: "200px", textAlign: "center",margin: "0 5px" }}>Nombre del proyecto (*)</span>
                    <span style={{ display: "flex", justifyContent: "center", width: "200px", textAlign: "center",margin: "0 5px" }}>Nombre de la actividad (*)</span>
                    <span style={{ display: "flex", justifyContent: "center", width: "170px", textAlign: "center",margin: "0 5px" }}>Descripción </span>
                    <span style={{ display: "flex", justifyContent: "center", width: "120px", textAlign: "center",margin: "0 5px" }}>Horas a cargar (*)</span>
                    <span style={{ display: "flex", justifyContent: "center", width: "50px", textAlign: "center",margin: "0 5px" }}>Valida</span>
                    <span style={{ display: "flex", justifyContent: "center", width: "50px", textAlign: "center",margin: "0 5px" }}>Adjunto</span>
                    <span style={{ display: "flex", justifyContent: "center", width: "50px", textAlign: "center",margin: "0 5px" }}>Eliminar</span>
                    </div>
                    <hr />
                  <div style={{ position: 'relative' }}>
                    {loading && (<LoadingPage/>)}
                    {addRows.map(row => (
                        <div key={row.id}>
                            <div  style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                <div  style={{ width: "150px" ,margin: "0 5px" ,display: "flex", justifyContent: "center"}}>
                                  <ComboBox placeholder="Indique el concepto" options={filterTipoConsept} 
                                  text={row.tipoConcepto.text} allowFreeform onChange={(_,option)=>onselecTipoConcept(option as ItipoConcepto, row.id) }
                                  onInputValueChange={(text) => tipoConceptFilterChange(text || "",row.id)}
                                  calloutProps={{ styles: { calloutMain: { width: "150px" } } }}
                                  /> 
                                </div>
                                <div  style={{ width: "200px" ,margin: "0 5px" ,display: "flex", justifyContent: "center" }}>
                                  <ComboBox placeholder="Indique el proyecto" options={row.filterListProyectos} 
                                  text={row.nombrePro.text} allowFreeform onChange={(_,option)=>onselectProject(option, row.id, '') }
                                  onInputValueChange={(text) => ProjectFilterChange(text || "",row.id,row.listaProyectos)}
                                  calloutProps={{ styles: { calloutMain: { width: "200px" } } }}
                                  /> 
                                </div>
                                <div style={{ width: "200px" ,margin: "0 5px" ,display: "flex", justifyContent: "center" }}>
                                    <ComboBox placeholder="Indique el nombre de la actividad"
                                    options={row.lisActRecurrente} text={row.nombreAct} allowFreeInput  key={11111}
                                    onChange={(_,option)=>HandlerNombreAct(row.id, option?.text,option as any)}
                                    onInputValueChange={(text)=>HandlerNombreActNuevo(row.id,text)} /> 
                                </div>
                                <div style={{ width: "170px" ,margin: "0 5px" ,display: "flex", justifyContent: "center"}}>
                                    <TextField 
                                        placeholder="Escriba una Descripción" value={row.descripcion}
                                        onChange={(e,newValue)=>handlerDescripcion(newValue,row.id)}/>
                                </div>
                                <div style={{ width: "120px", textAlign: "center" ,marginLeft:"18px"}}>
                                  <div className="ms-Grid-col ms-sm12" >
                                     <HourMinuteInput horasCarg={row.horasCarg.split(':')[0]} onHourChange={HandlerContHoras} rowId={row.id} />
                                  </div>
                                </div>
                                <div  style={{ width: "50px" ,margin: "0 5px" ,display: "flex", justifyContent: "center"}}>
                                  <TooltipHost content={row.descripcionError }>
                                    <StatusIcon valida={row.valida} />
                                  </TooltipHost>
                                </div>
                                <div style={{ width: "50px" ,margin: "0 5px" ,display: "flex", justifyContent: "center"}}>
                                  <FileUpload disabled={row?.nombrePro?.key? false:true} onFileSelect={(file)=>handleFileSelect(file,row.nombrePro,row.id)} />
                                </div>
                                <div style={{ width: "50px", display: "flex", alignItems: "center", justifyContent: "center" ,margin: "0 5px"}}>
                                    <IconPapers key={"001"} color={"red"} nameIcon={"Delete"} size={"25px"} thickness={9} onClick={()=>HandlerDeleteRows(row.id)}/>
                                </div> 
                            </div>
                            <hr />
                        </div>
                    ))}
                    </div>
                </div>
            </div>
          </div>
        </form>
      );
}
  
export default CargarPorFormulario