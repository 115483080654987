export interface CompanyJson {
  emp_activa: boolean;
  emp_eliminada: boolean;
  emp_depto: null;
  emp_direccion: null;
  emp_distrito: null;
  emp_id: string;
  emp_imagen_url: null;
  emp_monedaid: string;
  emp_nombre: string;
  emp_nombre_comercial: string;
  emp_nro_admin_tributaria: string;
  emp_paisid: string;
  emp_provincia: null;
  emp_permitir_proyectos_facturables: boolean;
  emp_permitir_proyectos_no_facturables: boolean;
  emp_tipo: string;
  moneda: null;
  pais: null;
  selected?: boolean;
}

export class Company {
  private constructor(
      private active: boolean,
      private deleted: boolean,
      private depto: null,
      private address: null,
      private district: null,
      private id: string,
      private image_url: null,
      private monedaid: string,
      private name: string,
      private tradename: string,
      private nro_admin_tributaria: string,
      private paisid: string,
      private city: null,
      private allow_billable_project: boolean,
      private allow_no_billable_project: boolean,
      private type: string
  ) {}

  static fromJson(company: CompanyJson) {
    return new this(
        company.emp_activa,
        company.emp_eliminada,
        company.emp_depto,
        company.emp_direccion,
        company.emp_distrito,
        company.emp_id,
        company.emp_imagen_url,
        company.emp_monedaid,
        company.emp_nombre,
        company.emp_nombre_comercial,
        company.emp_nro_admin_tributaria,
        company.emp_paisid,
        company.emp_provincia,
        company.emp_permitir_proyectos_facturables,
        company.emp_permitir_proyectos_no_facturables,
        company.emp_tipo
    );
  }

  static toJson(_company: Company) {
    let ret: CompanyJson = {
      emp_activa: _company.getActive(),
      emp_eliminada: _company.getDeleted(),
      emp_depto: _company.getDepto(),
      emp_direccion: _company.getAddress(),
      emp_distrito: _company.getDistrict(),
      emp_id: _company.getId(),
      emp_imagen_url: _company.getImage_url(),
      emp_monedaid: _company.getMonedaid(),
      emp_nombre: _company.getName(),
      emp_nombre_comercial: _company.getTradename(),
      emp_nro_admin_tributaria: _company.getNro_admin_tributaria(),
      emp_paisid: _company.getPaisid(),
      emp_provincia: _company.getCity(),
      emp_permitir_proyectos_facturables: _company.getAllow_billable_project(),
      emp_permitir_proyectos_no_facturables: _company.getAllow_no_billable_project(),
      emp_tipo: _company.getType(),
      moneda: null,
      pais: null,
    };
    return ret;
  }

  isIdentifiedBy(anId: string): boolean {
    return +this.id === +anId;
  }

  isIdentifiedAs(aCompany: Company): boolean {
    return aCompany.isIdentifiedBy(this.id);
  }

  allowedBillable(billable: boolean): boolean {
    return billable ? this.allow_billable_project : this.allow_no_billable_project;
  }

  tradeNameLike(val: string | undefined): boolean {
    return !val || this.getTradename().toLowerCase().includes(val!.toLowerCase());
  }

  isOfType(aType: string): boolean {
    return this.type === aType;
  }

  // =======================
  // GETTERS
  // =======================
  getActive() {
    return this.active;
  }
  getDeleted() {
    return this.deleted;
  }
  getDepto() {
    return this.depto;
  }
  getAddress() {
    return this.address;
  }
  getDistrict() {
    return this.district;
  }
  getId() {
    return this.id;
  }
  getImage_url() {
    return this.image_url;
  }
  getMonedaid() {
    return this.monedaid;
  }
  getName() {
    return this.name;
  }
  getTradename() {
    return this.tradename;
  }
  getNro_admin_tributaria() {
    return this.nro_admin_tributaria;
  }
  getPaisid() {
    return this.paisid;
  }
  getCity() {
    return this.city;
  }
  getAllow_billable_project() {
    return this.allow_billable_project;
  }
  getAllow_no_billable_project() {
    return this.allow_no_billable_project;
  }
  getType(): string {
    return this.type;
  }
}
