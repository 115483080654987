import * as React from 'react';
import useAsyncDataCourier from '../../common/custom-hooks/useAsyncDataCourier';
import MessageCourier from '../../lib/MessageCourier';
import TimeTaxSupportSystemClient from '../../system';
import { User } from '../../system/User';
import {
  TreeChart,
  ITreeProps,
  TreeTraverse,
  NodesComposition,
  ITreeState,
  ITreeChartDataPoint,
} from '@fluentui/react-charting';
import i18next, { t } from 'i18next';
import { OrganizationChartNode } from '../../system/organization_chart/OrganizationChartNode';
import { CommandBar, Slider, DefaultPalette, Dropdown, IDropdownOption } from 'office-ui-fabric-react';
import { ICommandBarItemProps } from '@fluentui/react/lib/CommandBar';
import { defaultCommandBarButtonStyles, titleCommandBarButtonStyles } from '../../styles/button-styles';
import './treeChart.css';
import ViewWrapperWithCommandBar from '../Layout/ViewWrapperWithCommandBar';

interface ICompanyTreeProps {
  system: TimeTaxSupportSystemClient;
  courier: MessageCourier;
  changeBlockUI: (change: boolean) => void;
  user: User;
}

const DIRECCION_LEVEL_VALUE = 1;
const GERENCIA_LEVEL_VALUE = 2;
const JEFATURA_LEVEL_VALUE = 3;

const CompanyTreeView = (props: ICompanyTreeProps) => {
  const [treeData, setTreeData] = React.useState<ITreeChartDataPoint | undefined>(undefined);
  const [zoom, setZoom] = React.useState<number>(75);
  const [direcciones, setDirecciones] = React.useState<string[]>([]);
  const [direccionSeleccionada, setDireccionSeleccionada] = React.useState<string>(
      // 'Dirección de Capital Humano'
      // direcciones[0]
      ''
  );

  const itemsMenu = React.useMemo(
      (): ICommandBarItemProps[] => [
        {
          key: 'title',
          text: t('Organigrama'),
          iconProps: {
            iconName: 'Backlog',
            color: DefaultPalette.themeLighterAlt,
            className: 'text-title-btn',
          },
          buttonStyles: titleCommandBarButtonStyles,
          className: 'text-title-btn',
        },
      ],
      []
  );

  React.useEffect(() => {
    const getInfo = async () => {
      const nodes = await props.system.getOrganizationChartSystem().getAllNodes();
      if (nodes) {
        const nodosJefatura = nodes.filter((nodo) => nodo.getLevel().getLevel() === JEFATURA_LEVEL_VALUE);
        const nodosGerencia = nodes.filter((nodo) => nodo.getLevel().getLevel() === GERENCIA_LEVEL_VALUE);
        const nodosDirecciones = nodes.filter((nodo) => nodo.getLevel().getLevel() === DIRECCION_LEVEL_VALUE);

        setDirecciones(nodosDirecciones.map((nodo) => nodo.getName()));

        const nodoDireccion = direccionSeleccionada
            ? nodes.find(
                (nodo) =>
                    nodo.getLevel().getLevel() === DIRECCION_LEVEL_VALUE &&
                    nodo.getName() === direccionSeleccionada
            )
            : nodes.find(
                (nodo) =>
                    nodo.getLevel().getLevel() === DIRECCION_LEVEL_VALUE &&
                    nodo.getName() === nodosDirecciones[0].getName()
            );

        if (nodoDireccion) {
          const jefaturasMappeadas = nodosJefatura?.map((nodo) => {
            return {
              name: nodo.getName(),
              fill: '#96CDFF',
              children: [],
              node: nodo,
            };
          });

          const gerenciasMappeadas = nodosGerencia?.map((nodo) => {
            return {
              name: nodo.getName(),
              fill: '#0496FF',
              children: jefaturasMappeadas?.filter((nodoJefatura) => nodoJefatura.node.isDescendantOf(nodo)),
              node: nodo,
            };
          });

          const direccion = {
            name: nodoDireccion!.getName(),
            fill: '#006BA6',
            children: gerenciasMappeadas?.filter((nodoGerencia) =>
                nodoGerencia.node.isDescendantOf(nodoDireccion!)
            ),
            node: nodoDireccion,
          };

          setTreeData(undefined);
          // setTimeout(() => {
          //   setTreeData(direccion);
          // }, 50);
          setTreeData(direccion);
        }
      }
    };
    getInfo();
  }, [direccionSeleccionada]);

  // const items: ICommandBarItemProps[] = direcciones.map((dir) => {
  //   return {
  //     key: dir,
  //     text: dir,
  //     onClick: (_ev, item) => {
  //       console.log('key selected  >', dir);
  //       setDireccionSeleccionada(dir);
  //     },
  //   };
  // });

  // const items: ICommandBarItemProps[] = React.useMemo(() => {
  //   return direcciones.map((dir) => {
  //     return {
  //       key: dir,
  //       text: dir,
  //       onClick: () => {
  //         setDireccionSeleccionada(dir);
  //       },
  //       buttonStyles: defaultCommandBarButtonStyles,
  //     };
  //   });
  // }, [direcciones]);

  const opciones: IDropdownOption[] = React.useMemo(() => {
    return direcciones.map((dir) => {
      return {
        key: dir,
        text: dir,
      };
    });
  }, [direcciones]);

  // const [tree, setTree] = React.useState<any>(undefined);

  // const getTree = (nodes: OrganizationChartNode[] | undefined, level: number) => {
  //   let treeNodes;
  //   if (level === DIRECCION_LEVEL_VALUE) {
  //     const filteredNodes = nodes ? nodes.filter((node) => node.getLevel().getLevel() === level) : [];
  //     treeNodes = filteredNodes
  //       ? filteredNodes.map((node) => {
  //           return {
  //             name: node.getName(),
  //             // metric: '100%',
  //             fill: '#4F6BED',
  //             children: getTree(
  //               nodes!.filter(
  //                 (hijo) => hijo.isDescendantOf(node) && hijo.getLevel().getLevel() === GERENCIA_LEVEL_VALUE
  //               ),
  //               GERENCIA_LEVEL_VALUE
  //             ),
  //           };
  //         })
  //       : [];
  //   } else {
  //     treeNodes = nodes
  //       ? nodes.map((node) => {
  //           return {
  //             name: node.getName(),
  //             // metric: '100%',
  //             fill: '#4F6BED',
  //             children: getTree(
  //               nodes!.filter(
  //                 (hijo) => hijo.isDescendantOf(node) && hijo.getLevel().getLevel() === level + 1
  //               ),
  //               level + 1
  //             ),
  //           };
  //         })
  //       : [];
  //   }
  //   return treeNodes;
  // };

  // const getContent = () => {
  //   const content = {
  //     name: t('Compañia'),
  //     fill: '#0099BC',
  //     children: organizationTree,
  //   };

  //   // setTree(content);
  //   console.log(content);

  //   return content;
  // };

  // const items: ICommandBarItemProps[] = [
  //   {
  //     key: 'Dirección de Capital Humano',
  //     text: 'Dirección de Capital Humano',
  //     onClick: (_ev, item) => setDireccionSeleccionada(item!.key),
  //   },
  //   {
  //     key: 'Dirección de Logística',
  //     text: 'Dirección de Logística',
  //     onClick: (_ev, item) => setDireccionSeleccionada(item!.key),
  //   },
  //   {
  //     key: 'Dirección de Finanzas y Administracion',
  //     text: 'Dirección de Finanzas y Administracion',
  //     onClick: (_ev, item) => setDireccionSeleccionada(item!.key),
  //   },
  //   {
  //     key: 'Dirección Comercial y Mercadotecnia',
  //     text: 'Dirección Comercial y Mercadotecnia',
  //     onClick: (_ev, item) => setDireccionSeleccionada(item!.key),
  //   },
  //   {
  //     key: 'Dirección Asuntos Publicos, Com. y Sost.',
  //     text: 'Dirección Asuntos Publicos, Com. y Sost.',
  //     onClick: (_ev, item) => setDireccionSeleccionada(item!.key),
  //   },
  //   {
  //     key: 'Gerencia General',
  //     text: 'Gerencia General',
  //     onClick: (_ev, item) => setDireccionSeleccionada(item!.key),
  //   },
  //   {
  //     key: 'Gerencia de Excelencia Operacional',
  //     text: 'Gerencia de Excelencia Operacional',
  //     onClick: (_ev, item) => setDireccionSeleccionada(item!.key),
  //   },
  //   {
  //     key: 'Proyecto Trainee',
  //     text: 'Proyecto Trainee',
  //     onClick: (_ev, item) => setDireccionSeleccionada(item!.key),
  //   },
  // {
  // key: 'Dirección 1',
  // text: 'Dirección 1',
  // onClick: (_ev, item) => setDireccionSeleccionada(item!.key),
  // },
  // {
  // key: 'Dirección 1',
  // text: 'Dirección 1',
  // onClick: (_ev, item) => setDireccionSeleccionada(item!.key),
  // },
  // {
  // key: 'Dirección 1',
  // text: 'Dirección 1',
  // onClick: (_ev, item) => setDireccionSeleccionada(item!.key),
  // },
  // {
  // key: 'Dirección 1',
  // text: 'Dirección 1',
  // onClick: (_ev, item) => setDireccionSeleccionada(item!.key),
  // },
  // {
  // key: 'Dirección 1',
  // text: 'Dirección 1',
  // onClick: (_ev, item) => setDireccionSeleccionada(item!.key),
  // },
  // ];

  const onChange = (_ev, op) => {
    setDireccionSeleccionada(op?.key.toString()!);
    setTimeout(() => scrollToMiddle(), 20);
  };

  const scrollToMiddle = () => {
    const chart = document.getElementsByClassName('treeChart')[0];

    chart.scrollLeft = chart.scrollWidth / 12;
    // chart.scrollLeft = chart.scrollWidth / 8;
  };

  return (
      <>
        <ViewWrapperWithCommandBar title="" items={itemsMenu} withBorderBottom>
          <div style={{ width: '300px', padding: 16 }}>
            {direcciones && (
                <Dropdown
                    label={t('Direccion')}
                    selectedKey={direccionSeleccionada ? direccionSeleccionada : direcciones[0]}
                    // onChange={(_ev, op) => setDireccionSeleccionada(op?.key.toString()!)}
                    onChange={onChange}
                    options={opciones}
                    styles={{ dropdown: { marginBottom: '20px' } }}
                    placeholder={t('Seleccione una dirección')}
                />
            )}
            <Slider
                label={t('Zoom')}
                styles={{ container: { width: 200 } }}
                max={100}
                min={10}
                value={zoom}
                showValue
                onChange={(val) => setZoom(val)}
            />
          </div>
          <div id={'outerTree'} style={{ zoom: +zoom + '%' }}>
            {treeData && (
                <TreeChart
                    className="treeChart"
                    treeData={treeData}
                    composition={NodesComposition.long}
                    treeTraversal={TreeTraverse.levelOrder}
                    width={treeData.children?.length! * 400 + 1000}
                    height={1200}
                    // layoutWidth={3000}
                    styles={{
                      rectText: {
                        fontSize: 12,
                      },
                      rectMetricText: {
                        fontSize: 12,
                      },
                      rectNode: {
                        // width: 50,
                        padding: 2,
                      },
                      root: {
                        padding: 'auto',
                      },
                    }}
                />
            )}
          </div>
        </ViewWrapperWithCommandBar>
      </>
  );
};

export default CompanyTreeView;
