import React, { useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';

import { TextField, DatePicker, IDatePickerProps } from '@fluentui/react';
import { CallableFn } from './CustomInput';
import CustomForm from './CustomForm';
import ErrorListForInput from './ErrorListForInput';
import CustomInputIfc from './CustomInputIfc';
import { DateTime } from 'luxon';
import { DateCustom } from '../../DateCustom';
import { getLocalizedStrings } from '../../LocalizedStringsForDatePicker';

interface IDatePickerInput {
  input: CustomInputIfc;
  onChangeExtended?: (input: CustomInputIfc) => void;
  onBlur: (input: CustomInputIfc) => void;
  label: string;
  useTime?: boolean;
  propsInput?: IDatePickerProps;
}

export const DatePickerInput = (props: IDatePickerInput) => {
  const { t, i18n } = useTranslation();

  const [updateFlag, setUpdateFlag] = React.useState(false);

  const onFormatDate = (date?: Date): string => {
    if (!props.useTime) {
      return DateCustom.formatDateForDatePicker(date);
    } else {
      // todo
    }
    return !date ? '' : date.getDate() + '/' + (date.getMonth() + 1) + '/' + (date.getFullYear() % 100);
  };

  const updateViewAfterDoing = (anAction: CallableFn) => {
    setUpdateFlag((u) => {
      anAction();
      return !u;
    });
  };

  const onChangeCustomInput = (input: CustomInputIfc, value: Date) => {
    updateViewAfterDoing(() => {
      props.input.setValue(DateTime.fromJSDate(value));
      onBlurCustomInput();
    });
  };

  const onBlurCustomInput = () => {
    updateViewAfterDoing(() => {
      props.input.onBlur();
      props.onBlur(props.input);
    });
  };

  return (
      <DatePicker
          strings={getLocalizedStrings()}
          label={t(props.label)}
          formatDate={onFormatDate}
          value={props.input.getValue().toJSDate()}
          onSelectDate={(date) => {
            if (date) {
              onChangeCustomInput(props.input, date);
            }
          }}
          {...props.propsInput}
      />
  );
};
