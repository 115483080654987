import React from 'react';
import { DefaultPalette, ICommandBarItemProps } from '@fluentui/react';
import { routes } from '../../../common/constants';
import MessageCourier from '../../../lib/MessageCourier';
import { buttonStylesForProjects, defaultCommandBarButtonStyles } from '../../../styles/button-styles';
import TimeTaxSupportSystemClient from '../../../system';
import ViewWrapperWithCommandBar from '../../Layout/ViewWrapperWithCommandBar';
import { useNavigate, useParams } from 'react-router-dom';
import useAsyncDataCourier from '../../../common/custom-hooks/useAsyncDataCourier';
import { ProjectMiniPanelWrapper } from '../ProjectMiniPanel/ProjectMiniPanelWrapper';
import { IProjectDescriptor } from '../../../system/projects/ProjectDescriptor';
import { useTranslation, Trans } from 'react-i18next';
import { User } from '../../../system/User';

interface IProjectRequiredDocument {
  system: TimeTaxSupportSystemClient;
  courier: MessageCourier;
  user: User;
  changeBlockUI: (change: boolean) => void;
}

export const ProjectRequiredDocument = (props: IProjectRequiredDocument) => {
  const { t, i18n } = useTranslation();
  let { id } = useParams();
  const navigate = useNavigate();

  const projects = useAsyncDataCourier(() => props.system.getProjects(), [props.system], props.courier);

  // const [selectedProject, setSelectedProject] = React.useState<Project | undefined>(undefined);
  const [actualizateProject, setActualizateProject] = React.useState<boolean>(false);

  const selectedProject = useAsyncDataCourier(
      () => props.system.getProjectSystem().getById(id!),
      [props.system, id],
      props.courier
  );
  const onClickProject = (project: IProjectDescriptor) => () => {
    // setSelectedProject(project);
    navigate(`${routes.project_docs}/${project.getId()}`);
  };

  const [showMiniPanel, setShowMiniPanel] = React.useState<boolean>(
      localStorage.getItem('showMiniPanel') === '1' ? true : false
  );

  const itemsMenu = React.useMemo(
      (): ICommandBarItemProps[] => [
        {
          key: 'project',
          text: selectedProject?.getCode(),
          itemProps: {},
          iconProps: { iconName: 'Teamwork', color: DefaultPalette.themeLighterAlt },
          subMenuProps: {
            items: projects
                ? projects.map((project) => ({
                  key: project.getCode(),
                  text: project.getCode(),
                  onClick: onClickProject(project),
                }))
                : [],
          },
          buttonStyles: buttonStylesForProjects,
        },
        {
          key: 'manage',
          text: t('Gestión'),
          onClick: () => navigate(`${routes.project}/${selectedProject?.getId()}`),
          iconProps: { iconName: 'TaskManager' },
          buttonStyles: defaultCommandBarButtonStyles,
        },

        {
          key: 'download',
          text: t('Config'),
          onClick: () => navigate(`${routes.project_config}/${selectedProject?.getId()}`),
          iconProps: { iconName: 'Settings' },
          buttonStyles: defaultCommandBarButtonStyles,
        },
        {
          key: 'panel',
          text: t('Panel'),
          onClick: () => navigate(`/${routes.project_panel}/${selectedProject?.getId()}`),

          iconProps: { iconName: 'DashboardAdd' },
          buttonStyles: defaultCommandBarButtonStyles,
        },
      ],
      [selectedProject, projects, id, navigate]
  );

  const farItems: ICommandBarItemProps[] = React.useMemo(
      () => [
        {
          key: 'info',
          text: 'Info & MiniPanel',
          ariaLabel: 'Info',
          iconOnly: true,
          iconProps: { iconName: 'BIDashboard' },
          buttonStyles: defaultCommandBarButtonStyles,
          onClick: () => {
            localStorage.setItem('showMiniPanel', showMiniPanel ? '0' : '1');
            setShowMiniPanel(!showMiniPanel);
          },
        },
      ],
      [showMiniPanel]
  );

  return (
      <ViewWrapperWithCommandBar
          withBorderBottom
          title={''}
          items={itemsMenu}
          farItems={farItems}
          overflowButtonStyles={defaultCommandBarButtonStyles}
          variant="highligth"
          titleBody="Docs. obligatorios"
      >
        <div className="body-bg">
          <div className="ms-Grid" dir="ltr">
            {selectedProject && showMiniPanel && (
                <ProjectMiniPanelWrapper
                    selectedProject={selectedProject}
                    system={props.system}
                    courier={props.courier}
                    user={props.user}
                    actualizateProject={() => setActualizateProject(!actualizateProject)}
                    changeBlockUI={props.changeBlockUI}
                />
            )}

            <div className="ms-Grid-row ">
              <div className="ms-Grid-col ms-lg12">
                {/* {selectedProject && (
                <ProjectRequiredDocumentList
                  system={props.system}
                  project={selectedProject}
                ></ProjectRequiredDocumentList>
              )} */}
              </div>
            </div>
          </div>
        </div>
      </ViewWrapperWithCommandBar>
  );
};
