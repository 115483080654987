import CustomForm from '../../../lib/FormValidator/Form/CustomForm';
import CollaboratorTeam from '../../../system/collaborators/collaborator_team/CollaboratorTeam';
import { MailInspectorRulesTag } from '../../../system/mail/mail_inspector_rules_tag/MailInspectorRulesTag';
import ProjectCompanySaved from '../../../system/projects/ProjectCompanySaved';
import MailInspectorRuleTagAssertions from './MailInspectorRuleTagAssertions';
import { MIRTCompanyEmailForm } from './MailInspectorRuleTagCompanyEmails';
import { MIRTKeywordForm } from './MailInspectorRuleTagKeywordsForm';
import { MIRTTeamEmailForm } from './MailInspectorRuleTagTeamEmails';

class MailInspectorRuleTagForm extends CustomForm {
  static readonly ID = 'id';
  static readonly ACTIVE = 'active';
  static readonly PROJECT_COMPANY = 'projectCompany';
  static readonly KEYWORDS = 'keywords';
  static readonly TEAM_EMAILS = 'teamEmails';
  static readonly EXTERNAL_EMAILS = 'externalEmails';

  protected constructor(
      private id: string,
      private active: boolean,
      private companyInProyect: ProjectCompanySaved | null,
      private keywordForm: MIRTKeywordForm,
      private teamEmailsForm: MIRTTeamEmailForm,
      private companyEmailsForm: MIRTCompanyEmailForm
  ) {
    const mira = MailInspectorRuleTagAssertions.build();
    super('MailInspectorRuleTag', mira);
    this.addInputAsserted(MailInspectorRuleTagForm.ID, id);
    this.addInputAsserted(MailInspectorRuleTagForm.ACTIVE, active);
    this.addInputAsserted(MailInspectorRuleTagForm.PROJECT_COMPANY, companyInProyect);
    this.addFormInput(keywordForm);
    this.addFormInput(teamEmailsForm);
    this.addFormInput(companyEmailsForm);
  }

  private static build(
      id: string,
      active: boolean,
      companyInProyect: ProjectCompanySaved | null,
      keywordForm: MIRTKeywordForm,
      teamEmailsForm: MIRTTeamEmailForm,
      companyEmailsForm: MIRTCompanyEmailForm
  ) {
    const instance = new this(id, active, companyInProyect, keywordForm, teamEmailsForm, companyEmailsForm);

    return instance;
  }

  static initialize() {
    return this.build(
        'new',
        true,
        null,
        MIRTKeywordForm.initialize(),
        MIRTTeamEmailForm.initialize(),
        MIRTCompanyEmailForm.initialize()
    );
  }

  static with(mir: MailInspectorRulesTag) {
    return this.build(
        mir.getId(),
        mir.getActive(),
        mir.getCompanyInProject(),
        MIRTKeywordForm.with(mir.getKeywords()),
        MIRTTeamEmailForm.with(mir.getTeamEmails()),
        MIRTCompanyEmailForm.with(mir.getExternalEmails())
    );
  }

  getIdInput() {
    return this.getInputByName(MailInspectorRuleTagForm.ID);
  }

  getActiveInput() {
    return this.getInputByName(MailInspectorRuleTagForm.ACTIVE);
  }

  getProjectCompany() {
    return this.getInputByName(MailInspectorRuleTagForm.PROJECT_COMPANY);
  }

  getKeywords() {
    return this.keywordForm;
  }

  getTeamEmails() {
    return this.teamEmailsForm;
  }

  getCompanyEmails() {
    return this.companyEmailsForm;
  }

  toMailInspectorRuleTag() {
    const ret = MailInspectorRulesTag.identifiedBy(
        this.getIdInput().getValue(),
        this.getActiveInput().getValue(),
        this.getProjectCompany().getValue(),
        this.getTeamEmails().getInputTeamEmailsItems().getValue(),
        this.getCompanyEmails().getInputCompanyEmailsItems().getValue(),
        this.getKeywords().getInputKeywordItems().getValue()
    );
    return ret;
  }

  isNew() {
    return this.getIdInput().getValue() === 'new';
  }
}

export default MailInspectorRuleTagForm;
