import { DateTime } from 'luxon';
import { Company } from '../company/Company';
import { Collaborator } from '../collaborators/Collaborator';

export class Settlement {
  private constructor(
      private id: string,
      private from: string,
      private to: string,
      private status: string,
      private invoiceNumber: string | null,
      private active: boolean,
      private creationDate: DateTime,
      private company: Company | null,
      private collaborator: Collaborator | null,
      private total: string,
      private modeBillableDistribution: string
  ) {}

  static identifiedAs(
      id: string,
      from: string,
      to: string,
      status: string,
      invoiceNumber: string | null,
      active: boolean,
      creationDate: DateTime,
      company: Company | null,
      collaborator: Collaborator | null,
      total: string,
      modeBillableDistribution: string
  ) {
    return new this(
        id,
        from,
        to,
        status,
        invoiceNumber,
        active,
        creationDate,
        company,
        collaborator,
        total,
        modeBillableDistribution
    );
  }

  period() {
    return `${this.from} - ${this.to}`;
  }

  getId(): string {
    return this.id;
  }
  getFrom(): string {
    return this.from;
  }
  getTo(): string {
    return this.to;
  }
  getStatus(): string {
    return this.status;
  }
  getInvoiceNumber(): string | null {
    return this.invoiceNumber;
  }
  getActive(): boolean {
    return this.active;
  }
  getCreationDate(): DateTime {
    return this.creationDate;
  }
  getCompany(): Company | null {
    return this.company;
  }
  getCollaborator(): Collaborator | null {
    return this.collaborator;
  }

  getTotal(): string | null {
    return this.total;
  }

  getModeBillableDistribution(): string {
    return this.modeBillableDistribution;
  }

  setId(value: string) {
    this.id = value;
  }
  setFrom(value: string) {
    this.from = value;
  }
  setTo(value: string) {
    this.to = value;
  }
  setStatus(value: string) {
    this.status = value;
  }
  setInvoiceNumber(value: string | null) {
    this.invoiceNumber = value;
  }
  setActive(value: boolean) {
    this.active = value;
  }
  setCompany(value: Company | null) {
    this.company = value;
  }
  setCollaborator(value: Collaborator | null) {
    this.collaborator = value;
  }
}
