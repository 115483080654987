import { GRID_MODE } from '../../../common/list-utils';
import { DetailsListView, EntityGridListProps } from '../../Common/DetailsList/DetailListView';
import { Text } from '@fluentui/react/lib/Text';
import { FontIcon, Icon, TooltipHost } from '@fluentui/react';
import { DocumentContentTypeIcon } from '../../Proyectos/DocumentContentTypeIcon';
import { useTranslation, Trans } from 'react-i18next';
import React from 'react';
import { DateTime } from 'luxon';

export const DocumentGridList = (props: EntityGridListProps) => {
  const { t, i18n } = useTranslation();

  const formatActivo = (item: any) => {
    if (item.doc_activo) {
      return <Icon iconName="CheckMark" className="color-success" />;
    } else {
      return <Icon iconName="Cancel" className="color-error" />;
    }
  };

  const formatDateMM_YYYY = (item: any) => {
    if (item.doc_refiere_a_periodo) {
      return <span>{DateTime.fromFormat(item.doc_refiere_a_periodo, 'dd/LL/yyyy').toFormat('LL/yyyy')}</span>;
    }
  };

  const formatArchivo = (item: any) => {
    return (
        <span>
        <DocumentContentTypeIcon name={item.doc_nombre} /> &nbsp;{item.doc_nombre}
      </span>
    );
  };

  const columns = [
    { fieldName: 'doc_nombre', name: t('Nombre'), _onRender: (item: any) => formatArchivo(item) },
    { fieldName: 'doct_nombre', name: t('Tipo') },
    {
      fieldName: 'doc_refiere_a_periodo',
      name: t('Refiere a período'),
      _onRender: (item: any) => formatDateMM_YYYY(item),
    },
    { fieldName: 'pro_codigo', name: t('Proyecto codigo') },
    { fieldName: 'pro_nombre', name: t('Proyecto nombre') },
    { fieldName: 'doc_carga_fecha', name: t('Fecha de carga') },
    { fieldName: 'col_nombre', name: t('Cargado por') },
    { fieldName: 'act_nombre', name: t('Actividad') },
    { fieldName: 'doc_activo', name: t('Activo'), _onRender: (item: any) => formatActivo(item) },
  ];
  return (
      <>
        <DetailsListView
            changeBlockUI={props.changeBlockUI}
            keyList={props.keyList}
            onClickCardMode={() => props.onClickCardMode()}
            onClickGridMode={() => props.onClickGridMode()}
            mode={props.mode}
            title={t('Documentos')}
            cols={columns}
            isGridList={true}
            listFilters={[]}
            quickFilters={props.quickFilters}
            onItemClick={props.onItemClick}
            baseUrl={props.baseUrl}
            courier={props.courier}
        ></DetailsListView>
      </>
  );
};
