import ObjectError from './ObjectError';

class CustomObjectException extends Error {
  constructor(msg: string, private errorList: ObjectError[]) {
    super(msg);
    // TODO: revisar
    // Object.setPrototypeOf(this, CustomObjectException.prototype);
    this.name = 'CustomObjectException';
  }

  getErrorList() {
    return this.errorList;
  }
}

export default CustomObjectException;
