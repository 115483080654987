import { Facepile, Icon, IDropdownOption, IFacepilePersona, Persona, PersonaSize } from '@fluentui/react';
import { GRID_MODE } from '../../../common/list-utils';
import { DateCustom } from '../../../lib/DateCustom';
import { DetailsListView, EntityGridListProps } from '../../Common/DetailsList/DetailListView';
import { useTranslation, Trans } from 'react-i18next';
import React from 'react';

export const MassiveDefenseGridList = (props: EntityGridListProps) => {
  const { t, i18n } = useTranslation();

  const formatVigencia = (item: any) => {
    return (
        <span>
        {item.adl_desde} - {item.adl_hasta}
      </span>
    );
  };

  const formatEstado = (item: any) => {
    const state = item.adl_estado
    const tState = t(state.charAt(0).toUpperCase() + state.slice(1));
    if (state === 'iniciado') {
      return (
          <span>
          <Icon iconName="Shield" aria-label="En proceso" title={tState} className="color-inProcess" />{' '}
            &nbsp;{tState}
        </span>
      );
    } else if (state === 'zipGenerado') {
      return (
          <span>
          <Icon iconName="Shield" aria-label="Inactivo" title={tState} className="color-success" /> &nbsp;
            {tState}
        </span>
      );
    }
    else if( state === 'finalizado'){
      return(
          <span>
            <Icon iconName="Shield" aria-label="Inactivo" title={tState} className="color-success" /> &nbsp;
            {tState}
          </span>
      )
    }
    else if (state === 'descargando'){
      return(<span>
      <Icon iconName="CloudDownload" aria-label="En proceso" title={tState} className="color-inProcess" />{' '}
        &nbsp;{tState}
    </span>)
    }
    else if (state === 'descargado'){
      return(<span>
        <Icon iconName="Download" aria-label="En proceso" title={tState} className="color-inProcess" />{' '}
        &nbsp;{tState}
      </span>)
    }
    else if (state === 'zips-generados'){
      return(<span>
        <Icon iconName="ZipFolder" aria-label="En proceso" title={tState} className="color-inProcess" />{' '}
        &nbsp;{tState}
      </span>)
    }
    else return(<span>
      <Icon iconName="Shield" aria-label="En proceso" title={t(state)} className="color-inProcess" />{' '}
        &nbsp;{tState}
    </span>)
  };

  const columns = [
    {
      fieldName: 'adl_id',
      name: t('ID'),
      maxWidth: 60,
      minWidth: 60,
    },
    // {
    //   fieldName: 'col_nombre',
    //   name: t('Creador'),
    //   maxWidth: 100,
    //   minWidth: 100,
    // },
    // {
    //   fieldName: 'emp_nombre',
    //   name: t('Empresa nombre'),
    // },
    {
      fieldName: 'adl_desde',
      name: t('Vigencia'),
      maxWidth: 200,
      minWidth: 115,
      _onRender: (item: any) => formatVigencia(item),
    },
    {
      fieldName: 'adl_fecha_carga',
      name: t('Fecha de carga'),
      maxWidth: 100,
      minWidth: 100,
    },
    {
      fieldName: 'adl_estado',
      name: t('Estado'),
      _onRender: (item: any) => formatEstado(item),
    },
  ];
  return (
      <>
        <DetailsListView
            changeBlockUI={props.changeBlockUI}
            keyList={'defense_file_list'}
            onClickCardMode={() => props.onClickCardMode()}
            onClickGridMode={() => props.onClickGridMode()}
            mode={props.mode}
            title={t('Archivos de Defensa')}
            onItemClick={props.onItemClick}
            cols={columns}
            isGridList={true}
            listFilters={[]}
            quickFilters={props.quickFilters}
            baseUrl={props.baseUrl}
            courier={props.courier}
        />
      </>
  );
};
