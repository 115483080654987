import CryptoJS from 'crypto-js';

class FileHasher {
  private constructor(private file: File) {}

  static for(aFile: File): Promise<string> {
    const hasher = new this(aFile);
    return hasher.value();
  }

  async value(): Promise<string> {
    // return 'tJANIiZO0mgknrmT+JLdxg==';
    const hash = CryptoJS.algo.MD5.create();

    await this.processByChunk(async (data) => {
      hash.update(this.arrayBufferToWordArray(data));
    });

    return hash.finalize().toString(CryptoJS.enc.Base64);
  }

  private arrayBufferToWordArray(ab: ArrayBuffer) {
    const i8a = new Uint8Array(ab);
    const a: number[] = [];
    for (let i = 0; i < i8a.length; i += 4) {
      a.push((i8a[i] << 24) | (i8a[i + 1] << 16) | (i8a[i + 2] << 8) | i8a[i + 3]);
    }
    return CryptoJS.lib.WordArray.create(a, i8a.length);
  }

  private async processByChunk(processData: (data: ArrayBuffer) => Promise<unknown>): Promise<void> {
    const chunkSize = 1024 * 1024; // bytes
    let offset = 0;

    while (offset < this.file.size) {
      const partial = this.file.slice(offset, offset + chunkSize);
      const data = await partial.arrayBuffer();
      await processData(data);
      offset += chunkSize;
    }
  }
}

export default FileHasher;
