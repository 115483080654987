import { Icon } from '@fluentui/react'
import React, { useEffect, useState } from 'react'

interface ItoasErrorAndExit{
    message: string
    tipe:"error" | "exit";
    closeToast:(exit: number) => void
}

function ToastErrorAndExit({message,tipe,closeToast}:ItoasErrorAndExit) { 
    const[isExitError, setIsExitError] = useState<boolean>(true); 
    useEffect(() =>{
        if (tipe === "exit") {
              setIsExitError(false);
        }else{
            setIsExitError(true);
        }
    },[isExitError])
  return (
        <div className={isExitError? "toast_container_collaborator_error":"toast_container_collaborator"}>
          <div className={isExitError?"toast_message_collaborator_error":"toast_message_collaborator"}>
            <Icon
              iconName={isExitError?"ErrorBadge":"Accept"}
              className={isExitError?"icon_toast_collaborator_error":"icon_toast_collaborator"}
              style={{ fontSize: "17px" }}
            />
         {message}
          </div >
          <Icon iconName={"Clear"} onClick={()=>closeToast(0)} style={{cursor:"pointer"}}/>
        </div>
  )
}

export default ToastErrorAndExit