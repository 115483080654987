export class Validation {
  constructor(private condition: any, private error: string) {}

  validate(params: any) {
    return this.condition(params);
  }

  getError() {
    return this.error;
  }
}
