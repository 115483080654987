import { ComboBox, DefaultButton, PrimaryButton,Label, Icon, IComboBoxOption, IIconProps } from "@fluentui/react";
import { getLocalizedStrings } from "../../lib/LocalizedStringsForDatePicker";
import { CustomDatePicker, ImportAct } from "./ImportAct";
import { User } from "../../system/User";
import { IactError } from "./InterfaceFormulario";
import { useState } from "react";
import { ItipoConcepto } from "../../system/projects/ProjectsSystem";

interface ICustonearder{
    filteredCollaborators: IComboBoxOption[];
    collaboratorValue:string;
    handleSelectCollaborator:(option: IComboBoxOption | undefined) => Promise<void>;
    handleFilterCollaborator:(text: string) => void;
    periodo:Date;
    HandlerPeriodo:(date: Date) => void;
    horasTotal:number;
    convertMinToHour:(minutes: number) => string;
    desabled:boolean;
    UploadIcon:IIconProps;
    actError:IactError;
    tipoConcept:ItipoConcepto[];
    desableAddRow:boolean;
    HandlerAddRows:() => void;
    AddIcon:IIconProps;
    dataUser:User;
    mountBlock: {[key: number]: number[]};
    HandlercleanEverything:() => void;
    addrowlength:number;
}

const Custoneader = ({
    filteredCollaborators,
    collaboratorValue,
    handleSelectCollaborator,
    handleFilterCollaborator,
    periodo,
    HandlerPeriodo,
    horasTotal,
    convertMinToHour,
    desabled,
    UploadIcon,
    actError,
    tipoConcept,
    desableAddRow,
    HandlerAddRows,
    AddIcon,
    dataUser,
    mountBlock,
    HandlercleanEverything,
    addrowlength
  }:ICustonearder) =>{   
    return (
    <div className="ms-Grid-row">
      <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-between", alignItems: "center" }}>
        <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg7">
          <ComboBox
            label="Colaborador"
            className="ms-Grid-col ms-sm4 ms-md6 ms-lg4"
            options={filteredCollaborators}
            allowFreeform
            text={collaboratorValue}
            onChange={(_, option) => handleSelectCollaborator(option)}
            onInputValueChange={(text) => handleFilterCollaborator(text || "")}
            selectedKey={"00000006454"}
            disabled={dataUser.isDirectiveUser() || dataUser.isStandardUser()}
          />
{ /*         <DatePicker
            label="Periodo"
            className="ms-Grid-col ms-sm6 ms-md4 ms-lg4"
            strings={getLocalizedStrings()}
            formatDate={DateCustom.formatDateForDatePicker_MMYYYY}
            calendarProps={{ isDayPickerVisible: false }}
            value={periodo}
            onSelectDate={(e) => {
              if (e) {
                let _date = new Date(e.getFullYear(), e.getMonth(), 1);
                HandlerPeriodo(_date);
              }
            }}
          /> 
          */}
          <CustomDatePicker
            strings={getLocalizedStrings()}
            label="Periodo"
            value={periodo}
            blockedMonths={mountBlock}
            onSelectDate={(date)=> HandlerPeriodo(date as Date)}
        />
          <div className="ms-Grid-col ms-sm4">
            <Label>Horas pendientes</Label>
            <DefaultButton style={{ color: horasTotal > -1 ? "black" : "red", border: "none" }}>{convertMinToHour(horasTotal)}</DefaultButton>
          </div>
        </div>
        <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg4" style={{ display: "flex", justifyContent: "flex-end", gap: "20px", marginTop: "20px" }}>
          <PrimaryButton
            className="ms-Grid-col ms-sm12 ms-md6 ms-lg3"
            text="Cargar"  
            iconProps={UploadIcon}
            type="submit"
            disabled={desabled}
          />
        </div>
      </div>
      <div style={{margin:"70px "}}>
      </div>
      <div className="ms-Grid-row" style={{ padding: "30px 0px 10px 40px" }}>
        <h4 className="ms-Grid-col ms-sm11" >
          Tienes {actError.actVali} filas válidas y {actError.actvErro} filas con errores
        </h4>
      </div>
      <div className="ms-Grid-row" style={{ padding: "0px 30px" }}>
        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-between", alignItems: "center" }}>
          <div className="ms-Grid-col ms-sm9" style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
            <PrimaryButton
              disabled={tipoConcept.length === 0 ? true : desableAddRow ? true : false}
              text="Agregar fila"
              style={{ width: "150px", marginRight: "10px" }}
              iconProps={AddIcon}
              onClick={() => HandlerAddRows()}
            />
            <PrimaryButton disabled={addrowlength === 0?true:false} text="Eliminar todo" style={{ width: "130px" }}  onClick={()=>HandlercleanEverything()}/>
          </div>
          <div>
          {/*<ImportAct/>*/}  
          </div>
          <div className="ms-Grid-col ms-sm12">
            <div style={{display:"flex",flexDirection:"row",alignItems:"center",padding:"20px 20px"}}><Icon iconName="Info" style={{marginRight:"5px"}}/><div>Los documentos serán cargados al proyecto</div></div>    
          </div>
        </div>
      </div>
    </div>
  )};
  export default Custoneader;