import React, { useEffect, useState } from 'react';
import ViewWrapperWithCommandBar from '../Layout/ViewWrapperWithCommandBar';
import Wizard from '../Wizard/Wizard';
// import CompanySelectorAssistant from './Wizard/CompanySelectorAssistant';
import { DefaultPalette, ICommandBarItemProps, Text } from '@fluentui/react';
import { titleCommandBarButtonStyles, defaultCommandBarButtonStyles } from '../../styles/button-styles';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../common/constants';
// import ProjectSelectorAssistant from './Wizard/ProjectSelectorAssistant';
// import Ending from './Wizard/Ending';
// import DetailAssistant from './Wizard/DetailAssistant';
import useAsyncDataCourier from '../../common/custom-hooks/useAsyncDataCourier';
import { DateTime } from 'luxon';
import { Company } from '../../system/company/Company';
// import DocumentSelectorAssistant from './Wizard/DocumentSelectorAssistant';
// import './Wizard/wizardComponents.css';
import ProjectInSettlementPeriod from '../../system/settlement/ProjectInSettlementPeriod';
import TimeTaxSupportSystemClient from '../../system';
import MessageCourier from '../../lib/MessageCourier';
import { IProjectForDefenseFile } from '../../system/projects/ProjectsSystem';
import ProjectInSettlementItem from '../../system/settlement/ProjectInSettlementItem';
import { useTranslation, Trans } from 'react-i18next';
import TemplatePeriod from './Wizard/TemplatePeriod';
// import TotalToSettle from './Wizard/TotalToSettle';
// import { CostPerHourTable } from './Wizard/CostPerHourTable';
// import GenerateTemplate from './Wizard/GenerateTemplate';
// import { CollaboratorsWithNoRemunInPeriod } from './Wizard/CollaboratorsWithNoRemunInPeriod';
import { LockTemplate } from './Wizard/LockTemplate';
import { UploadTemplate } from './Wizard/UploadTemplate';
import UpdatedTemplate from './Wizard/UpdatedTemplate';
import { ICollaborator } from '../../system/collaborators/CollaboratorSystem';
import { Collaborator } from '../../system/collaborators/Collaborator';
import { IReportePreliquidacion } from '../../system/reporting/ReportingSystem';
import { User } from '../../system/User';

interface ISettlementsViewProps {
  system: TimeTaxSupportSystemClient;
  courier: MessageCourier;
  changeBlockUI: (change: boolean) => void;
  user: User;
}

const CostPerHourView = (props: ISettlementsViewProps) => {
  const { t, i18n } = useTranslation();
  const [canGoNext, setCanGoNext] = useState(false);

  const [showLockTemplate, setShowLockTemplate] = useState<boolean>(false);
  const [lockTemplateWasClosed, setLockTemplateWasClosed] = useState<boolean>(false);
  const [hasAccepted, setHasAccepted] = useState<boolean>(false);

  const [massiveRemunResponseList, setMassiveRemunResponseList] = useState<any>();

  const [month, setMonth] = useState<DateTime>(DateTime.fromJSDate(new Date()));

  const [generatedSettlementResponse, setGeneratedSettlementResponse] = useState<any>();

  const [lastConfirmatedSettlement, setLastConfirmatedSettlement] = useState<Date>();
  const [lastBlocked, setLastBlocked] = useState<Date>();

  const navigate = useNavigate();

  const openModal = () => {
    if (!lockTemplateWasClosed) setShowLockTemplate(true);
  };

  useEffect(() => {
    setLockTemplateWasClosed(false);
  }, [month]);

  const accept = () => {
    setHasAccepted(true);
    setShowLockTemplate(false);
  };

  const onCloseDialog = (confirm: boolean) => {
    setLockTemplateWasClosed(true);
  };

  const monthString = () => {
    let _month = month.toJSDate();
    let number = _month.getMonth() + 1;
    return number.toString();
  };

  const yearString = () => {
    let _year = month.toJSDate();
    let number = _year.getFullYear();
    return number.toString();
  };

  const Titles = [t('1. Período'), t('2. Importar planilla'), t('3. Finalizar')];
  const Descriptions = [t('Selecciona el mes y el año para importar una planilla.'), t(' '), t('')];

  const CompanySelectorData = {
    month: month,
  };

  const setButtonDisability = (can: boolean) => {
    setCanGoNext(can);
  };

  const itemsMenu = React.useMemo(
      (): ICommandBarItemProps[] => [
        {
          key: 'title',
          text: t('Importador de costo por hora desde planilla Excel'),
          iconProps: {
            iconName: 'ExcelLogo',
            color: DefaultPalette.themeLighterAlt,
            className: 'text-title-btn',
          },
          buttonStyles: titleCommandBarButtonStyles,
          className: 'text-title-btn',
        },
        {
          key: 'back',
          text: t('Volver'),
          onClick: () => navigate(`${routes.cost_per_hour_list}`),
          iconProps: { iconName: 'Back' },
          buttonStyles: defaultCommandBarButtonStyles,
        },
      ],
      [navigate]
  );

  const getDataFromTemplatePeriod = (month: DateTime) => {
    setMonth(month);
  };

  const getDataFromUploadTemplate = (list: any) => {
    setMassiveRemunResponseList(list);
  };

  const Selectors = [
    <TemplatePeriod
        system={props.system}
        courier={props.courier}
        CompanySelectorData={CompanySelectorData}
        getDataFromTemplatePeriod={getDataFromTemplatePeriod}
        setButtonDisability={setButtonDisability}
        month={month}
        openLockTemplate={openModal}
        lastTemplate={lastConfirmatedSettlement}
        openModal={openModal}
        hasAccepted={hasAccepted}
    />,
    <UploadTemplate
        system={props.system}
        courier={props.courier}
        user={props.user}
        changeBlockUI={props.changeBlockUI}
        month={monthString()}
        year={yearString()}
        setButtonDisability={setButtonDisability}
        getDataFromUploadTemplate={getDataFromUploadTemplate}
        openModal={openModal}
    />,
    <UpdatedTemplate massiveRemunResponseList={massiveRemunResponseList} />,
  ];
  return (
      <ViewWrapperWithCommandBar
          withBorderBottom
          title={''}
          items={itemsMenu}
          overflowButtonStyles={defaultCommandBarButtonStyles}
          variant="highligth"
          titleBody={t('Costos por hora')}
      >
        <div className="padding-1">
          <div className="ms-Grid bg-white-h100" dir="ltr">
            <div className="ms-Grid-row">
              <div className="ms-Grid-col ms-sm12 mt-2 ml-1 defense-file-container-desc">
                <Text className="ml-10px defense-file-title">
                  {t('Asistente para la importacion de planilla costo hora')}
                </Text>
              </div>
            </div>
            <div className="ms-Grid-row">
              <div className="ms-Grid-col ms-sm12">
                <Wizard
                    componentsTitlesArray={Titles}
                    componentsDescriptions={Descriptions}
                    componentsArray={Selectors}
                    canGoNext={canGoNext}
                />
              </div>
            </div>
          </div>
        </div>
        {month ? (
            <LockTemplate
                system={props.system}
                courier={props.courier}
                onClose={onCloseDialog}
                show={showLockTemplate}
                month={monthString()}
                changeBlockUI={props.changeBlockUI}
                accept={accept}
                // periodo={`${from?.toFormat('dd/LL/yyyy')} - ${to?.toFormat('dd/LL/yyyy')}`}
            ></LockTemplate>
        ) : null}
      </ViewWrapperWithCommandBar>
  );
};

export default CostPerHourView;
