import React from 'react';

import TimeTaxSupportSystemClient from '../../system/TimeTaxSupportSystemClient';
import MessageCourier from '../../lib/MessageCourier';
import { Dropdown, IDropdownOption } from '@fluentui/react/lib/Dropdown';
import { Company } from '../../system/company/Company';
import i18next, { t } from 'i18next';

interface ICompanySelectorProps {
  system: TimeTaxSupportSystemClient;
  courier: MessageCourier;
  default?: Company;
  onChangeSelected: (newType?: Company) => void;
  onlyBillableCompanies?: boolean;
  useAll?: boolean;
  value?: string | undefined;
}

interface ICompanySelectorState {
  companies: Company[];
  selected?: Company | string;
}

class CompanySelector extends React.Component<ICompanySelectorProps, ICompanySelectorState> {
  constructor(props: ICompanySelectorProps) {
    super(props);
    this.state = {
      companies: [],
      selected: this.props.default,
    };
  }

  async componentDidMount() {
    const companySystem = this.props.system.getCompanySystem();
    const companies = await companySystem.getList();
    this.setState({ companies: companies });
  }

  componentDidUpdate(prevProps: ICompanySelectorProps) {
    if (this.props.default !== prevProps.default) {
      this.setState({ selected: this.props.default });
    }
  }

  private getSelectedKey() {
    if (this.state.selected instanceof Company) {
      return this.state.selected.getId();
    }
    if (this.props.useAll && this.state.selected === '0') {
      return '0';
    }
    return '';
  }

  render() {
    const { companies, selected } = this.state;

    const options: IDropdownOption[] = companies
        .filter((t) => !this.props.onlyBillableCompanies || t.getAllow_billable_project())
        .map((t) => {
          return { key: t.getId(), text: t.getName() };
        });

    if (this.props.useAll) {
      options.unshift({ key: '0', text: 'Todas' });
    }

    return (
        <>
          <Dropdown
              label={t('Empresa')}
              onChange={this.onChangeSelection}
              selectedKey={this.props.value === '' ? '' : this.getSelectedKey()}
              options={options}
          />
        </>
    );
  }

  private onChangeSelection = (event: unknown, option?: IDropdownOption<any> | undefined) => {
    const selectedId = option ? (option.key as string) : '';
    const selected = this.state.companies.find((company) => company.isIdentifiedBy(selectedId));
    this.setState({ ...this.state, selected: selected?.getId() ? selected : '0' });
    this.props.onChangeSelected(selected);
  };
}

export default CompanySelector;
