import * as React from 'react';
import { Dialog, DialogType, DialogFooter } from '@fluentui/react/lib/Dialog';
import { PrimaryButton, DefaultButton } from '@fluentui/react/lib/Button';
import { ContextualMenu } from '@fluentui/react/lib/ContextualMenu';

const dragOptions = {
  moveMenuItemText: 'Move',
  closeMenuItemText: 'Close',
  menu: ContextualMenu,
};
// const modalPropsStyles = { main: { minWidth: 900 } };

interface IDialogTemplate {
  showDialog: boolean;
  toggleShowDialog: () => void;
  title: string;
  children: JSX.Element;
  onAccept: () => void;
  onCancel: () => void;
  acceptText: string;
  cancelText: string;
  minWidth?: number;
  maxWidth?: number;
  validateAccept?: () => boolean;
  hideCancelButton?: boolean;
  hideAcceptButton?: boolean;
}

const DialogTemplate = (props: IDialogTemplate) => {
  const modalProps = {
    isBlocking: true,
    // styles: modalPropsStyles,
    dragOptions: dragOptions,
  };

  const dialogContentProps = React.useMemo(() => {
    return {
      type: DialogType.normal,
      title: props.title,
    };
  }, [props]);

  return (
      <>
        <Dialog
            hidden={!props.showDialog}
            onDismiss={props.toggleShowDialog}
            dialogContentProps={dialogContentProps}
            modalProps={modalProps}
            minWidth={props.minWidth ? props.minWidth : 400}
            // className="animation-height"
        >
          {props.children}
          <DialogFooter>
            {!props.hideAcceptButton && (
                <PrimaryButton
                    disabled={props.validateAccept ? props.validateAccept() : false}
                    onClick={props.onAccept}
                    text={props.acceptText}
                />
            )}
            {!props.hideCancelButton && <DefaultButton onClick={props.onCancel} text={props.cancelText} />}
          </DialogFooter>
        </Dialog>
      </>
  );
};

export default DialogTemplate;
