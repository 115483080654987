import { DateTime } from 'luxon';
import {
  DatePicker,
  DefaultButton,
  DefaultPalette,
  Icon,
  IStackStyles,
  PrimaryButton,
  Text,
} from 'office-ui-fabric-react';
import React from 'react';
import { useParams } from 'react-router-dom';
import useAsyncDataCourier from '../../../common/custom-hooks/useAsyncDataCourier';
import { DateCustom } from '../../../lib/DateCustom';
import MessageCourier from '../../../lib/MessageCourier';
import TimeTaxSupportSystemClient from '../../../system';
import BasicChartPad from '../../Common/BasicChartPad';
import PanelDonutChart from '../../Panel/PanelCollaborators/PanelDonutChart';
import ProjectPanelAreaChart from '../../Proyectos/ProjectPanel/ProjectPanelAreaChart';
import { CollaboratorViewDetailList } from './CollaboratorViewDetailList';
import { useTranslation, Trans } from 'react-i18next';
import AmountByConceptExporter from '../../Panel/ExcelExport/AmountByConceptExporter';
import { getLocalizedStrings } from '../../../lib/LocalizedStringsForDatePicker';
import { ICollaboratorDetailList } from '../../../system/panel/PanelSystem';
import { OrganizationChartNode } from '../../../system/organization_chart/OrganizationChartNode';
import ProjectTableExporter from '../../Panel/ExcelExport/ProjectTableExporter';
import { User } from '../../../system/User';
import { HorizontalBarChart, IChartProps } from '@fluentui/react-charting';
// import PanelHorizontalBarChart from '../../Panel/PanelCollaborators/PanelHorizontalBarChart';
import PanelVerticalStackedBarChart from '../../Panel/PanelCollaborators/PanelVerticalStackedBarChart';
import PanelProjectsVerticalStackedBarChart from '../../Panel/PanelCollaborators/PanelProjectsVerticalStackedBarChart';
import AlertChartPad from '../../Common/AlertChartPad';
import { PillFluent } from '../../Common/PillFluent';
import { AlertPillFluent } from '../../Common/AlertPillFluent';

interface ICollaboratorPanelsProps {
  system: TimeTaxSupportSystemClient;
  courier: MessageCourier;
  changeBlockUI: (change: boolean) => void;
  from?: string | null | undefined;
  to?: string | null | undefined;
  user: User;
}

const CollaboratorPanels = (props: ICollaboratorPanelsProps) => {
  const { t, i18n } = useTranslation();
  let { collaboratorId } = useParams();

  const [fromDate, setFromDate] = React.useState<DateTime>(
      DateTime.fromJSDate(DateCustom.getFirstDayOfThisMonth())
  );
  const [toDate, setToDate] = React.useState<DateTime>(DateTime.fromJSDate(new Date()));

  const collaboratorPanel = useAsyncDataCourier(
      () => {
        const panelSystem = props.system.getPanelSystem();

        return collaboratorId
            ? panelSystem.getCollaboratorPanel(fromDate, toDate, collaboratorId)
            : panelSystem.getMyPanelData(fromDate, toDate);
      },
      [props.system, collaboratorId, fromDate, toDate],
      props.courier,
      props.changeBlockUI
  );

  const collaboratorActivityStatus = useAsyncDataCourier(
      () => {
        const panelSystem = props.system.getPanelSystem();
        let id = props.user.getCollaboratorId();
        return panelSystem.getCollaboratorActivityStatusByDate(collaboratorId ? collaboratorId : id, fromDate, toDate);
      },
      [props.system, collaboratorId, fromDate, toDate],
      props.courier,
      props.changeBlockUI
  );

  const pendingDocuments = () => {
    const projectList = collaboratorActivityStatus?.estadoDocumentos;
    let pendingDocs = 0;

    collaboratorActivityStatus?.estadoDocumentos.forEach((item) => {
      pendingDocs += item.cant_pendientes;
    });

    return pendingDocs;
  };

  const expiredDocuments = () => {
    const projectList = collaboratorActivityStatus?.estadoDocumentos;
    let expiredDocs = 0;

    collaboratorActivityStatus?.estadoDocumentos.forEach((item) => {
      expiredDocs += item.cant_vencidos;
    });

    return expiredDocs;
  };

  React.useEffect(() => {
    if (props.from) {
      let from = new Date(props.from);
      setFromDate(DateTime.fromJSDate(from));
    }
    if (props.to) {
      let to = new Date(props.to);
      setToDate(DateTime.fromJSDate(to));
    }
  }, [props.from, props.to]);

  const onChangeDate = (date: Date | null | undefined, name: string) => {
    if (date) {
      if (name === 'from') {
        setFromDate(DateTime.fromJSDate(date));
      }
      if (name === 'to') {
        setToDate(DateTime.fromJSDate(date));
      }
    }
  };

  const setDateLastMonth = () => {
    setFromDate(DateTime.fromJSDate(DateCustom.getFechaOneMonthAgo()));
    setToDate(DateTime.fromJSDate(new Date()));
  };

  const setDateLastSemester = () => {
    setFromDate(DateTime.fromJSDate(DateCustom.getFechaSixMonthsAgo()));
    setToDate(DateTime.fromJSDate(new Date()));
  };

  const setDateLastYear = () => {
    setFromDate(DateTime.fromJSDate(DateCustom.getFechaInitLastYear()));
    setToDate(DateTime.fromJSDate(DateCustom.getFechaEndLastYear()));
  };

  const buttonStyles: Partial<IStackStyles> = {
    root: { height: 32, fontSize: 12 },
  };

  const exportCollaboratorsComparison = (comparison: ICollaboratorDetailList[]) => {
    ProjectTableExporter.exportFor(comparison);
  };

  return (
      <div>
        

        <div className="ms-Grid-row mt-1">
          <div className="ms-Grid-col ms-sm3 ">
            <DatePicker
                strings={getLocalizedStrings()}
                label={t('Filtrar desde')}
                formatDate={DateCustom.formatDate}
                value={fromDate ? fromDate.toJSDate() : undefined}
                onSelectDate={(e) => onChangeDate(e, 'from')}
                className=""
                maxDate={toDate.toJSDate()}
            />
          </div>
          <div className="ms-Grid-col ms-sm3 ">
            <DatePicker
                strings={getLocalizedStrings()}
                label={t('Filtrar hasta')}
                formatDate={DateCustom.formatDate}
                value={toDate ? toDate.toJSDate() : undefined}
                onSelectDate={(e) => onChangeDate(e, 'to')}
                className=""
            />
          </div>
          { /*<div className="ms-Grid-col ms-sm3 ">
            <DatePicker
                strings={getLocalizedStrings()}
                label={t('Filtrar por mes')}
                formatDate={DateCustom.formatDateForDatePicker_MMYYYY}
                value={fromDate ? fromDate.toJSDate() : undefined}
                onSelectDate={(e) => {
                  if (e) {
                    let _date = new Date(e.getFullYear(), e.getMonth(), 1);
                    onChangeDate(_date, 'from');
                    let _dateTo = DateCustom.getLastDayOfMonth(_date);
                    onChangeDate(_dateTo, 'to');
                  }
                }}
                className=""
                //@ts-ignore
                calendarProps={{ isDayPickerVisible: false }}
            />
              </div> */}
        </div>
        <hr />
        <div
            className="ms-Grid-row mt-1 mb-1"
            style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}
        >
          <div className="ms-Grid-col ms-xl6 ms-lg12 ms-sm12">
            {collaboratorActivityStatus && (
                <PanelVerticalStackedBarChart data={collaboratorActivityStatus} user={props.user} />
            )}
          </div>

          <div className="ms-Grid-col ms-xl3 ms-lg3 ms-sm12">
            {collaboratorActivityStatus ? (
                <PanelProjectsVerticalStackedBarChart data={collaboratorActivityStatus} />
            ) : null}
          </div>

          <div className="ms-Grid-col ms-xl3 ms-lg9 ms-sm12">
            {collaboratorPanel && expiredDocuments() === 0 ? (
                <div className="ms-Grid-col ms-sm12">
                  <PillFluent
                      title={t('Docs. pendientes')}
                      iconTitle={'ProtectedDocument'}
                      series={[
                        {
                          label: t('Docs. obligatorios pendientes'),
                          value: pendingDocuments(),
                          icon: 'ProtectedDocument',
                        },
                      ]}
                      small={false}
                  ></PillFluent>
                  <div className="text-center mt-1">
                    {pendingDocuments() > 0 && (
                        <Text variant="medium" className="fw-500 error-color link-styles">
                          <Icon iconName="AlertSolid" className="text-icon"></Icon>
                          {t('Tienes documentos obligatorios vencidos')}
                        </Text>
                    )}

                    {pendingDocuments() === 0 && (
                        <Text variant="medium" className="fw-500">
                          <Icon iconName="CheckMark" className="text-icon success-color"></Icon>
                          {t('Estas al día con tu proyecto')}
                        </Text>
                    )}
                  </div>
                </div>
            ) : null}
            {collaboratorPanel && expiredDocuments() > 0 ? (
                <div className="ms-Grid-col ms-sm12">
                  <AlertPillFluent
                      title={t('Docs. vencidos')}
                      iconTitle={'AlertSolid'}
                      series={[
                        {
                          label: t('Docs. obligatorios vencidos'),
                          value: expiredDocuments(),
                          icon: 'Cancel',
                        },
                      ]}
                      small={false}
                  ></AlertPillFluent>
                </div>
            ) : null}
          </div>
        </div>
        <hr></hr>
        
        
        {/*<div className="ms-Grid-row mt-2">
          <div className="d-inline ml-8px">
            <DefaultButton
                onClick={setDateLastMonth}
                allowDisabledFocus
                styles={buttonStyles}
                text={t('Último mes')}
            />
          </div>
          <div className="d-inline ml-8px">
            <DefaultButton
                onClick={setDateLastSemester}
                allowDisabledFocus
                styles={buttonStyles}
                text={t('Último semestre')}
            />
          </div>
          <div className="d-inline ml-8px">
            <DefaultButton
                onClick={setDateLastYear}
                allowDisabledFocus
                styles={buttonStyles}
                text={t('Año anterior')}
            />
          </div>
        </div> */}

        {collaboratorPanel && (
            <>
              <div className="ms-Grid-row">
                <div className="ms-Grid-col ms-sm12">
                  <PrimaryButton
                      text={t('Exportar')}
                      iconProps={{ iconName: 'ExcelLogo' }}
                      className="float-right ml-2"
                      menuProps={{
                        items: [
                          {
                            key: 'exportSector',
                            text: t('Horas por sector'),
                            onClick: () =>
                                AmountByConceptExporter.exportFor(
                                    collaboratorPanel.hoursByCompany,
                                    ['Sector', 'Horas'],
                                    t('Horas por sector')
                                ),
                          },
                          {
                            key: 'exportProject',
                            text: t('Actividades por proyecto'),
                            onClick: () =>
                                AmountByConceptExporter.exportFor(
                                    collaboratorPanel.activitiesByProject,
                                    ['Proyecto', 'Actividades'],
                                    t('Actividades por proyecto')
                                ),
                          },
                          {
                            key: 'exportAcitivities',
                            text: t('Estado de actividades'),
                            onClick: () =>
                                AmountByConceptExporter.exportFor(
                                    collaboratorPanel.activities,
                                    ['Estado', 'Cantidad de actividades'],
                                    t('Estado de actividades')
                                ),
                          },
                          {
                            key: 'exportHours',
                            text: t('Horas por actividad'),
                            onClick: () =>
                                AmountByConceptExporter.exportFor(
                                    collaboratorPanel.activityHours,
                                    ['Actividades', 'Horas'],
                                    t('Horas por actividad')
                                ),
                          },
                        ],
                      }}
                  />
                </div>
              </div>
              <div className="ms-Grid-row mt-4">
                <div className="ms-Grid-col ms-lg6 ms-xl4 ms-sm12 mt-2-in-down-xl">
                  <div className="ms-Grid-col ms-sm10 ms-smPush1">
                    {/* <BasicChartPad
                  previewText={`${collaboratorPanel.activeCompanies}`}
                  mainText={t('Empresas')}
                  secondaryText={
                    t('Colaborando en ') +
                    collaboratorPanel.activeCompanies +
                    t(' empresas, 0 con actividades')
                  }
                  iconName="ProtectedDocument"
                /> */}
                    <PillFluent
                        title={t('Empresas')}
                        iconTitle={'ProtectedDocument'}
                        series={[
                          {
                            label: t('Colaborando en'),
                            value: collaboratorPanel.activeCompanies,
                            icon: 'ProtectedDocument',
                          },
                        ]}
                        small={false}
                    ></PillFluent>
                  </div>
                </div>
                <div className="ms-Grid-col ms-lg6 ms-xl4 ms-sm12 mt-2-in-down-xl">
                  <div className="ms-Grid-col ms-sm10 ms-smPush1">
                    {/* <BasicChartPad
                  previewText={`${collaboratorPanel.activeProjects}`}
                  mainText={t('Proyectos')}
                  secondaryText={
                    t('Participando en ') +
                    collaboratorPanel.activeProjects +
                    t('proyectos, ') +
                    collaboratorPanel.activitiesByProject.length +
                    t(' con actividades')
                  }
                  iconName="TeamProtectedDocument"
                /> */}
                    <PillFluent
                        title={t('Proyectos')}
                        iconTitle={'TeamProtectedDocument'}
                        series={[
                          {
                            label: t('Participando en'),
                            value: collaboratorPanel.activeProjects,
                            icon: 'TeamProtectedDocument',
                          },
                        ]}
                        small={false}
                    ></PillFluent>
                  </div>
                </div>
              </div>
              <div className="ms-Grid-row mt-5">
                <div className="ms-Grid-col ms-lg12 ms-xl4 ms-sm12">
                  {collaboratorPanel.hoursByCompany.length > 0 ? (
                      <PanelDonutChart
                          data={collaboratorPanel.hoursByCompany}
                          iconName={'AddGroup'}
                          titleChart={t('Horas dedicadas por empresa')}
                      ></PanelDonutChart>
                  ) : (
                      <div className="text-center mt-2">
                        <Text variant="medium" className="fw-500">
                          <Icon iconName="AddGroup" className="text-icon"></Icon>
                          {t('No hay horas registradas en areas para los filtros seleccionados')}
                        </Text>
                      </div>
                  )}
                </div>

                <div className="ms-Grid-col ms-lg12 ms-xl4 ms-sm12">
                  {collaboratorPanel.activitiesByProject.length > 0 ? (
                      <PanelDonutChart
                          data={collaboratorPanel.activitiesByProject}
                          iconName={'AccountActivity'}
                          titleChart={t('Actividades por proyectos')}
                          calloutText={t('Actividades')}
                      ></PanelDonutChart>
                  ) : (
                      <div className="text-center mt-2">
                        <Text variant="medium" className="fw-500">
                          <Icon iconName="AddGroup" className="text-icon"></Icon>
                          {t('No hay horas registradas en areas para los filtros seleccionados')}
                        </Text>
                      </div>
                  )}
                </div>
                <div className="ms-Grid-col ms-lg12 ms-xl4 ms-sm12">
                  {collaboratorPanel.docsByProject.length > 0 ? (
                      <PanelDonutChart
                          data={collaboratorPanel.docsByProject}
                          iconName={'TextDocument'}
                          titleChart={t('Documentos por proyectos')}
                          calloutText={t('Documentos')}
                      ></PanelDonutChart>
                  ) : (
                      <div className="text-center mt-2">
                        <Text variant="medium" className="fw-500">
                          <Icon iconName="AddGroup" className="text-icon"></Icon>
                          {t('No hay horas registradas en areas para los filtros seleccionados')}
                        </Text>
                      </div>
                  )}
                </div>
              </div>
              {collaboratorPanel.hoursByDay.length > 0 && (
                  <div className="mt-5 bg-white-h100" style={{ padding: '10px 10px 30px 10px' }}>
                    <ProjectPanelAreaChart
                        data={collaboratorPanel.hoursByDay}
                        title={t('Horas cargadas por ' + collaboratorPanel.header)}
                        color={DefaultPalette.blueLight}
                        calloutText={t('Horas')}
                        iconName="Clock"
                        showTitle={true}
                    ></ProjectPanelAreaChart>
                  </div>
              )}
              <div className="ms-Grid-row mt-2">
                <div className="ms-Grid-col ms-lg12 ms-xl4 ms-sm12 mt-2">
                  {collaboratorPanel.activities ? (
                      <PanelDonutChart
                          data={collaboratorPanel.activities}
                          iconName={'AccountActivity'}
                          titleChart={
                              collaboratorPanel.activities[0].amount +
                              collaboratorPanel.activities[1].amount +
                              collaboratorPanel.activities[2].amount +
                              t(' actividades')
                          }
                          calloutText={t('Actividades')}
                      ></PanelDonutChart>
                  ) : (
                      <div className="text-center mt-2">
                        <Text variant="medium" className="fw-500">
                          <Icon iconName="AddGroup" className="text-icon"></Icon>
                          {t('No hay horas registradas en areas para los filtros seleccionados')}
                        </Text>
                      </div>
                  )}
                </div>
                <div className="ms-Grid-col ms-lg12 ms-xl4 ms-sm12 mt-2">
                  {collaboratorPanel.activityHours.length > 0 ? (
                      <PanelDonutChart
                          data={collaboratorPanel.activityHours}
                          iconName={'Clock'}
                          titleChart={
                              collaboratorPanel.activityHours[0].amount +
                              collaboratorPanel.activityHours[1].amount +
                              t(' horas - actividades')
                          }
                      ></PanelDonutChart>
                  ) : (
                      <div className="text-center mt-2">
                        <Text variant="medium" className="fw-500">
                          <Icon iconName="AddGroup" className="text-icon"></Icon>
                          {t('No hay horas registradas en areas para los filtros seleccionados')}
                        </Text>
                      </div>
                  )}
                </div>
                <div className="ms-Grid-col ms-lg12 ms-xl4 ms-sm12 mt-2">
                  {collaboratorPanel.documents.length > 0 ? (
                      <PanelDonutChart
                          data={collaboratorPanel.documents}
                          iconName={'TextDocument'}
                          titleChart={
                              collaboratorPanel.documents[0].amount +
                              collaboratorPanel.documents[1].amount +
                              t(' documentos')
                          }
                          calloutText={t('Documentos')}
                      ></PanelDonutChart>
                  ) : (
                      <div className="text-center mt-2">
                        <Text variant="medium" className="fw-500">
                          <Icon iconName="AddGroup" className="text-icon"></Icon>
                          {t('No hay horas registradas en areas para los filtros seleccionados')}
                        </Text>
                      </div>
                  )}
                </div>
              </div>
              <div className="ms-Grid-row mt-5">
                <PrimaryButton
                    iconProps={{ iconName: 'ExcelLogo' }}
                    className="float-right ml-2"
                    onClick={() => exportCollaboratorsComparison(collaboratorPanel.projectDetail || [])}
                >
                  {t('Exportar')}
                </PrimaryButton>
              </div>
              <div className="mt-2">
                {collaboratorPanel.projectDetail.length > 0 ? (
                    <div className="mt-5 bg-white-h100">
                      <CollaboratorViewDetailList
                          data={collaboratorPanel.projectDetail.length > 0 ? collaboratorPanel.projectDetail : []}
                      />
                    </div>
                ) : (
                    <div className="text-center mt-2">
                      <Text variant="medium" className="fw-500">
                        <Icon iconName="AddGroup" className="text-icon"></Icon>
                        {t('No hay horas registradas en areas para los filtros seleccionados')}
                      </Text>
                    </div>
                )}
              </div>
            </>
        )}
      </div>
  );
};

export default CollaboratorPanels;
