import FileFolder from './FileFolder';

export interface IFileWithPath extends File {
  path: string;
}

class FileFolderBuilder {
  private baseFolders: FileFolder[] = [];
  private sortedBaseFoldersWithoutRoot: FileFolder[] = [];

  constructor(private files: IFileWithPath[]) {}

  static from(files: IFileWithPath[]) {
    return new this(files).value();
  }

  value(): FileFolder {
    this.generateBaseFolders();
    const root = this.findRootFromBaseFolders();
    this.addChildrenToRoot(root);

    return root;
  }

  private addChildrenToRoot(root: FileFolder) {
    this.sortedBaseFoldersWithoutRoot.forEach((baseFolder) => {
      this.addFolderToImmediateParent(baseFolder, root);
    });
  }

  private addFolderToImmediateParent(baseFolder: FileFolder, root: FileFolder) {
    const parent = this.findImmediateParentOf(baseFolder, root);
    if (!parent) throw new Error('No parent found');
    parent.addChild(baseFolder);
  }

  private findRootFromBaseFolders() {
    const tentativeRoot = this.findTentativeRoot();

    if (!tentativeRoot.isRoot()) {
      tentativeRoot.generateChildren();
      return FileFolder.withChild('/', tentativeRoot);
    }

    return tentativeRoot;
  }

  private findTentativeRoot() {
    const sortedBaseFolders = this.baseFolders.sort((a, b) => a.pathLengthDifferenceWith(b));
    let tentativeRoot = sortedBaseFolders[0];
    if (
        !tentativeRoot.isRoot() &&
        !sortedBaseFolders.every((f) => f === tentativeRoot || f.isChildOf(tentativeRoot))
    ) {
      tentativeRoot = FileFolder.root();
    }

    this.sortedBaseFoldersWithoutRoot = sortedBaseFolders.filter((folder) => folder !== tentativeRoot);

    return tentativeRoot;
  }

  private generateBaseFolders() {
    this.files.forEach((file) => {
      const path = this.pathForBaseFolder(file);
      this.addFileToBaseFolderWithPath(path, file);
    });
  }

  private pathForBaseFolder(file: IFileWithPath) {
    let path = file.path.substring(0, file.path.length - file.name.length);
    if (!path) {
      path = FileFolder.rootPath();
    } else {
      path = path.substring(0, path.length - 1);
    }
    return path;
  }

  private addFileToBaseFolderWithPath(pathOfBaseFolder: string, file: IFileWithPath) {
    let baseFolder = this.baseFolders.find((folder) => folder.pathEquals(pathOfBaseFolder));
    if (!baseFolder) {
      baseFolder = FileFolder.fromPath(pathOfBaseFolder);
      this.baseFolders.push(baseFolder);
    }
    baseFolder.addFile(file);
  }

  private findImmediateParentOf(folder: FileFolder, possibleParent: FileFolder) {
    if (!folder.isChildOf(possibleParent)) return undefined;
    let parent = possibleParent;
    if (possibleParent.emptyChildren()) return parent;

    possibleParent.forEachChild((deeperPossibleParent) => {
      const deeperParent = this.findImmediateParentOf(folder, deeperPossibleParent);
      if (deeperParent) parent = deeperParent;
    });

    return parent;
  }
}

export default FileFolderBuilder;
