import { DateTime } from 'luxon';
import XhrRequestHandler from '../../lib/XhrRequestHandler';
import { Company } from '../company/Company';
import OrganizationChartSystem, {
  IOrganizationChartCompleteNode,
} from '../organization_chart/OrganizationChartSystem';

interface IReportCompanyDataBase {
  colaboradores_cant: number;
  horas_check_pending: number;
  horas_chequeadas: number;
  importe_costo: number;
  moneda_simbolo: string;
  periodo: string;
}

export interface IReportePreliquidacion {
  concept?: any;
  sector: string;
  col_nombre_completo: string;
  col_email: string;
  col_id_sap: string;
  cargo_nombre: string;
  act_id: string;
  act_nombre: string;
  act_inicio: string;
  pro_id: string;
  pro_nombre: string;
  pro_codigo: string;
  empresa: string;
  exp_porcentaje: number;
  rcp_costo_hora: number;
  periodo: string;
  horas_confirmadas: number;
  horas_pendientes: number;
  importe_costo_neto: number;
  coef_valor_agregado: number;
  importe_costo_total: number;
  moneda_denominacion: string;
  moneda_simbolo: string;
}

export interface IReporteGralHoras {
  organigrama_path_nombre: string;
  direccion: string;
  gerencia: string;
  jefatura: string;
  colaborador_nombre: string;
  colaborador_email: string;
  colaborador_sap_id: string;
  colaborador_fecha_alta: string;
  colaborador_fecha_baja: string;
  horas: number;
  no_informa_motivo: string;
  modalidad: string;
  horas_esperadas: IReporteHorasEsperadas;
  cant_horas_esperadas: number;
  valor_agregado: string;
  cargo_desde: string; // "2023-09-23"
  cargo_hasta: string;
}

export interface IReporteHorasCumplimientoGeneral {
  direccion: string;
  cant_colaboradores: number;
  horas_cargadas_totales: number;
  horas_esperadas_totales: number;
  porcentaje_cumplimiento: number;
}

export interface IReporteHorasCumplimientoIndividual {
  direccion: string;
  cant_colaboradores: number;
  cant_colaboradores_horas_completas: number;
  cant_colaboradores_horas_incompletas: number;
  porcentaje_cumplimiento: number;
}

export interface IReporteProyectos {
  pro_id: string;
  pro_nombre_completo: string;
  sector: string;
  emp_nombre: string;
  cant_horas: number;
  cant_actividades: number;
  cant_colabs_activos: number;
  cant_colabs: number;
  pro_estado: string;
  pro_activo: string;
}

export interface ICollaboratorLicense {
  collic_activo: boolean;
  collic_colid: string;
  collic_dias_lic: string;
  collic_fin: string;
  collic_id: string;
  collic_inicio: string;
  collic_motivo: string;
  collic_tipolic: string;
  licenciaTipo: ILicenseType;
}

export interface ILicenseType {
  lictipo_activo: boolean;
  lictipo_descripcion: string;

  lictipo_id: string;
}

interface IReporteHorasEsperadas {
  diasLaborales: string[];
  horasEsperadas: number;
  plantilla: IReportePlantilla;
  licencias: ICollaboratorLicense[];
}

export interface IReportingDocsControlData {
  proyecto_id: string;
  proyecto: string;
  colaborador_responsable: string;
  estado: string;
  fecha_vencimiento: string;
  fecha_carga: string;
  tipo_doc: string;
  concepto: string;
  documento: string;
}

interface IReportePlantilla {
  colplan_horas_diarias: string;
}

export interface IReporteMMI {
  emp_nombre: string;
  proyecto: string;
  mail_from: string;
  mail_to: string;
  mail_subject: string;
  mail_fecha: DateTime;
  tiene_documentos: boolean;
  mail_id: string;
  cantidad_ocurrencias: string;
  mep_expid: string;
}

interface IReportCompanyDataResponse extends IReportCompanyDataBase {
  nodoOrganigrama: IOrganizationChartCompleteNode;
}

export interface IReportCompanyData extends IReportCompanyDataBase {
  sector: string;
  mockId?: string;
}

export class ReportingSystem {
  private readonly baseDVM = '/reportesDVM';

  constructor(
      private requestHandler: XhrRequestHandler,
      private organizationChartSystem: OrganizationChartSystem
  ) {}

  getDateParams(from?: DateTime, to?: DateTime, useQuestSymbol: boolean = true) {
    let params = '';
    if (from && to && useQuestSymbol) {
      params += `?desde=${from.day}-${from.month}-${from.year}&hasta=${to.day}-${to.month}-${to.year}`;
    } else if (from) {
      params += `?period=${from.day}-${from.month}-${from.year}`;
    }

    return params;
  }

  async getCompanyReport(
      company: Company | undefined,
      from: DateTime,
      to: DateTime
  ): Promise<IReportCompanyData[]> {
    const params = this.getDateParams(from, to);
    const data = await this.requestHandler.get<IReportCompanyDataResponse[]>(
        `${this.baseDVM}/empresas/${company ? company.getId() : '0'}${params}`
    );

    const levels = await this.organizationChartSystem.getLevels();
    return data.map((d) => ({
      ...d,
      sector: this.organizationChartSystem.mapNode(d.nodoOrganigrama, levels).path(),
    }));
  }

  async getPreliquidacionReport(
      from: DateTime,
      to: DateTime,
      concept: string
  ): Promise<IReportePreliquidacion[]> {
    const params = this.getDateParams(from, to) + '&concept=' + concept;
    const data = await this.requestHandler.get<IReportePreliquidacion[]>(
        `${this.baseDVM}/preliquidacion${params}`
    );
    return data;
  }

  public async getPreliquidacionForSettlementReport(
      period: DateTime,
      concept: string,
      settlementMode: string
  ): Promise<IReportePreliquidacion[]> {
    const params = this.getDateParams(period) + '&concept=' + concept + '&settlementMode=' + settlementMode;
    const data = await this.requestHandler.get<IReportePreliquidacion[]>(
        `${this.baseDVM}/preliquidacion-for-settlement${params}`
    );
    return data;
  }

  async getGralHorasReport(from: DateTime, to: DateTime, node: string = ''): Promise<IReporteGralHoras[]> {
    const params = this.getDateParams(from, to);
    const data = await this.requestHandler.get<IReporteGralHoras[]>(
        `${this.baseDVM}/gralHoras${params}&nodoOrganigrama=${node ? node : 'todos'}`
    );
    return data;
  }

  async getDocsControlReport(
      from: DateTime,
      to: DateTime,
      node: string = ''
  ): Promise<IReportingDocsControlData[]> {
    const params = this.getDateParams(from, to);
    const data = await this.requestHandler.get<IReportingDocsControlData[]>(
        `${this.baseDVM}/seguimiento-control-docs${params}&nodoOrganigrama=${node ? node : 'todos'}`
    );
    return data;
  }

  async getProjectReport(from: DateTime, to: DateTime, nodeId: string = ''): Promise<IReporteProyectos[]> {
    const params = this.getDateParams(from, to);
    const data = await this.requestHandler.get<IReporteProyectos[]>(
        `${this.baseDVM}/proyectos${params}&nodoOrganigrama=${nodeId ? nodeId : 'todos'}`
    );
    return data;
  }

  async getBillableDistributionReport(
      pro_codigo: string = '',
      pro_nombre: string = '',
      companyId: string = '',
      pro_owner_colid: string = '',
      pro_norid: string = ''
  ): Promise<any[]> {
    const data = await this.requestHandler.get<any[]>(
        `${this.baseDVM}/distribucion-facturable?emp_id=${companyId}&pro_nombre=${pro_nombre}&pro_codigo=${pro_codigo}&pro_owner_colid=${pro_owner_colid}&pro_norid=${pro_norid}`
    );
    return data;
  }

  async getMMIReport(
      from: DateTime,
      to: DateTime,
      nodeId: string = '',
      companyId: string = ''
  ): Promise<IReporteMMI[]> {
    const params = this.getDateParams(from, to);
    const data = await this.requestHandler.get<IReporteMMI[]>(
        `/mails/report-mails${params}&nodoOrganigrama=${nodeId ? nodeId : 'todos'}&empresa=${
            companyId ? companyId : 'todas'
        }`
    );
    return data;
  }
}
