import React from 'react';
import CustomInputIfc from '../../lib/FormValidator/Form/CustomInputIfc';

/**
 * Wrappea un CustomInput para que se
 * actualice el state al cambiar el valor del input
 */
export function useCustomInput<T extends CustomInputIfc>(
    inputGetter: () => T,
    dependencies: any[],
    stateChangerMethods: (keyof T)[] = ['setValue']
): T {
  const [input, setInput] = React.useState<CustomInputIfc>(inputGetter());
  const [state, setState] = React.useState(0);

  React.useEffect(() => {
    setInput(inputGetter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...dependencies]);

  const ret: unknown = {
    state: state,
  };

  let obj = input;
  const visited: string[] = [];
  while ((obj = Object.getPrototypeOf(obj)) && Object.getPrototypeOf(obj)) {
    const descriptors = Object.getOwnPropertyDescriptors(obj);
    for (const [propName, descriptor] of Object.entries(descriptors)) {
      const isMethod = typeof descriptor.value === 'function' && propName !== 'constructor';
      if (!isMethod || visited.includes(propName)) {
        continue;
      }

      const originalMethod = descriptor.value;
      if (stateChangerMethods.includes(propName as keyof T)) {
        descriptor.value = function (...args: any[]) {
          const oldValue = input.getValue();
          const result = originalMethod.apply(input, args);
          const value = input.getValue();
          if (oldValue !== value) {
            setState((s) => s + 1);
          }
          return result;
        };
      } else {
        descriptor.value = function (...args: any[]) {
          return originalMethod.apply(input, args);
        };
      }
      Object.defineProperty(ret, propName, descriptor);
      visited.push(propName);
    }
  }

  return ret as T;
}
