import React, { useMemo, useState } from 'react';
import {
  DefaultPalette,
  Facepile,
  ICommandBarItemProps,
  IFacepilePersona,
  Persona,
  PersonaSize,
  PrimaryButton,
  IDetailsListProps,
  DetailsList,
  DetailsRow,
  DetailsListLayoutMode,
  SelectionMode,
  IDropdownOption,
  IColumn,
  Icon,
  Text,
  OverflowButtonType,
} from '@fluentui/react';
import { routes } from '../../../common/constants';
import MessageCourier from '../../../lib/MessageCourier';
import { buttonStylesForProjects, defaultCommandBarButtonStyles } from '../../../styles/button-styles';
import TimeTaxSupportSystemClient from '../../../system';
import ViewWrapperWithCommandBar from '../../Layout/ViewWrapperWithCommandBar';
import { useNavigate, useParams } from 'react-router-dom';
import useAsyncDataCourier from '../../../common/custom-hooks/useAsyncDataCourier';
import { ProjectMiniPanelWrapper } from '../ProjectMiniPanel/ProjectMiniPanelWrapper';
import { IProjectDescriptor } from '../../../system/projects/ProjectDescriptor';
import { useTranslation, Trans } from 'react-i18next';
import { User } from '../../../system/User';
import { KEY_VIEW_CONTROL_DOCS_PROJECTS, KEY_VIEW_MAILS_PROJECTS } from '../ProjectView';
import SystemConfiguration from '../../../system/configuration/SystemConfiguration';
import { DetailsListView } from '../../Common/DetailsList/DetailListView';
import { CARD_MODE, GRID_MODE } from '../../../common/list-utils';
import { ListColumnFilter } from '../../Common/list-entities/ListColumnFilter';
import useAsyncDataFull from '../../../common/custom-hooks/useAsyncDataFull';
import { MailInspectorRulesTag } from '../../../system/mail/mail_inspector_rules_tag/MailInspectorRulesTag';
import MailInspectorRuleTagForm from './MailInspectorRuleTagForm';
import { MailInspectorRuleTagView } from './MailInspectorRuleTagView';
import { ProjectCompanyInDistribution } from '../../../system/projects/IProjectCompanyInDistribution';
import { facepilePersonas } from '@fluentui/example-data';

interface IProjectMassiveMailInspector {
  system: TimeTaxSupportSystemClient;
  courier: MessageCourier;
  user: User;
  changeBlockUI: (change: boolean) => void;
  config: SystemConfiguration;
}

export const ProjectMassiveMailInspector = (props: IProjectMassiveMailInspector) => {
  const { t, i18n } = useTranslation();
  let { id } = useParams();
  const navigate = useNavigate();

  const [mode, setMode] = useState(GRID_MODE);
  const [shouldUpdate, setShouldUpdate] = React.useState<boolean>(false);
  const [checkAvailableOptions, setCheckAvailableOptions] = React.useState<boolean>(false);
  const [checkMMI, setCheckMMI] = React.useState<boolean>(false);

  const [currentForm, setCurrentForm] = React.useState<MailInspectorRuleTagForm | undefined>(undefined);

  // const [keywords, setKeywords] = React.useState<string[]>([]);

  const projects = useAsyncDataCourier(() => props.system.getProjects(), [props.system], props.courier);

  const [actualizateProject, setActualizateProject] = React.useState<boolean>(false);

  const { data: selectedProject, loaded } = useAsyncDataFull(
      () => props.system.getProjectSystem().getById(id!),
      [props.system, id],
      props.courier,
      props.changeBlockUI
  );

  const onClickProject = (project: IProjectDescriptor) => () => {
    // setSelectedProject(project);
    alert('implementar');
    // navigate(`${routes.massive_mail_inspector}/${project.getId()}`);
  };

  const [showMiniPanel, setShowMiniPanel] = React.useState<boolean>(
      localStorage.getItem('showMiniPanel') === '1' ? true : false
  );

  const { data: hasMMIActive, loaded: loadedMMIActive } = useAsyncDataFull(
      () => props.system.getMailInspectorRulesTagSystem().hasMMIActive(id!),
      [props.system, checkMMI],
      props.courier,
      props.changeBlockUI
  );

  const activateMMI = async () => {
    try {
      const activeMMI = await props.system.getMailInspectorRulesTagSystem().activateMMIFor(id!);
      setCheckMMI(!checkMMI);
    } catch (e) {
      console.log(e);
    }
  };

  const mmiItems = useAsyncDataCourier(
      () => props.system.getMailInspectorRulesTagSystem().getRulesFor(id!),
      [props.system, checkMMI, shouldUpdate],
      props.courier
  );

  const onCheckAvailableOptions = () => {
    setCheckAvailableOptions(!checkAvailableOptions);
  };

  const itemsMenu = React.useMemo(
      (): ICommandBarItemProps[] => [
        {
          key: 'selectedProject',
          text: selectedProject?.getCode(),
          itemProps: {},
          iconProps: { iconName: 'Teamwork', color: DefaultPalette.themeLighterAlt },
          subMenuProps: {
            items: projects
                ? projects.map((selectedProject) => ({
                  key: selectedProject.getCode(),
                  text: selectedProject.getCode(),
                  onClick: onClickProject(selectedProject),
                }))
                : [],
          },
          buttonStyles: buttonStylesForProjects,
        },
        {
          key: 'manage',
          text: t('Gestión'),
          onClick: () => navigate(`${routes.project}/${selectedProject?.getId()}`),
          iconProps: { iconName: 'TaskManager' },
          buttonStyles: defaultCommandBarButtonStyles,
        },

        {
          key: 'download',
          text: t('Config'),
          onClick: () => navigate(`${routes.project_config}/${selectedProject?.getId()}`),
          iconProps: { iconName: 'Settings' },
          buttonStyles: defaultCommandBarButtonStyles,
        },
        {
          key: 'panel',
          text: t('Panel'),
          onClick: () => navigate(`/${routes.project_panel}/${selectedProject?.getId()}`),

          iconProps: { iconName: 'DashboardAdd' },
          buttonStyles: defaultCommandBarButtonStyles,
        },
        {
          key: 'viewRequiredDocuments',
          text: t('Docs. obligatorios'),
          iconProps: { iconName: 'ProtectedDocument' },
          onClick: () => navigate(`${routes.project}/${id}?view=${KEY_VIEW_CONTROL_DOCS_PROJECTS}`),
          buttonStyles: defaultCommandBarButtonStyles,
        },
      ],
      [selectedProject, projects, id, navigate]
  );

  const farItems: ICommandBarItemProps[] = React.useMemo(
      () => [
        {
          key: 'info',
          text: 'Info & MiniPanel',
          ariaLabel: 'Info',
          iconOnly: true,
          iconProps: { iconName: 'BIDashboard' },
          buttonStyles: defaultCommandBarButtonStyles,
          onClick: () => {
            localStorage.setItem('showMiniPanel', showMiniPanel ? '0' : '1');
            setShowMiniPanel(!showMiniPanel);
          },
        },
      ],
      [showMiniPanel]
  );

  const formatEquipo = (equipo: string[]) => {
    var getInitials = function (string: string) {
      var names = string.split(' '),
          initials = names[0].substring(0, 1).toUpperCase();

      if (names.length > 1) {
        initials += names[1].substring(0, 1).toUpperCase();
      }
      return initials;
    };
    let equipoPersonas: IFacepilePersona[] = equipo.map((persona: string) => {
      return {
        personaName: persona,
        imageInitials: getInitials(persona),
      };
    });

    if (equipo.length > 0) {
      return (
          <Facepile
              personas={equipoPersonas}
              personaSize={10}
              maxDisplayablePersonas={6}
              overflowButtonProps={{ ariaLabel: 'Ver más' }}
              overflowButtonType={OverflowButtonType.descriptive}
              onRenderPersona={(props) => (
                  <Persona {...props} size={PersonaSize.size24} onRenderPrimaryText={() => <></>}></Persona>
              )}
          />
      );
    } else {
      return '';
    }
  };

  const formatActivo = (item: MailInspectorRulesTag) => {
    if (item.getActive()) {
      return <Icon iconName="CheckMark" className="color-success" />;
    } else {
      return <Icon iconName="Cancel" className="color-error" />;
    }
  };

  const columns = [
    {
      key: 'company',
      name: t('Empresa'),
      fieldName: 'emp_nombre',
      minWidth: 150,
      maxWidth: 150,
      onRender: (item: MailInspectorRulesTag) => item.getCompanyInProject().getCompany().getName(),
    },
    {
      key: 'userCompany',
      name: t('Mails empresa usuaria'),
      fieldName: 'mire_emails_empresa_usuaria',
      minWidth: 110,
      maxWidth: 150,
      onRender: (item: MailInspectorRulesTag) => formatEquipo(item.getExternalEmails()),
    },
    {
      key: 'teamEmails',
      name: t('Emails equipo'),
      fieldName: 'mire_emails_equipo',
      minWidth: 110,
      maxWidth: 150,
      onRender: (item: MailInspectorRulesTag) => formatEquipo(item.getTeamEmails()),
    },
    {
      key: 'keys',
      name: t('Claves'),
      fieldName: 'mire_claves',
      minWidth: 200,
      maxWidth: 220,
      onRender: (item: MailInspectorRulesTag) => item.getKeywords().join(', '),
    },
    {
      key: 'active',
      name: t('Activo'),
      fieldName: 'mire_activo',
      minWidth: 60,
      maxWidth: 60,
      onRender: (item: MailInspectorRulesTag) => formatActivo(item),
    },
  ];

  const [rule, setRule] = React.useState<MailInspectorRulesTag | undefined>();

  const _onRenderRow: IDetailsListProps['onRenderRow'] = (propsDr) => {
    if (propsDr) {
      return (
          <div
              onClick={() => {
                if (mmiItems) {
                  const _rule = mmiItems[propsDr.itemIndex];
                  setRule(_rule);
                  setCurrentForm(MailInspectorRuleTagForm.with(_rule));
                }
              }}
          >
            <DetailsRow {...propsDr} />
          </div>
      );
    }
    return null;
  };

  const refreshList = () => {
    setShouldUpdate(!shouldUpdate);
    setCurrentForm(undefined);
  };

  const onCancel = () => {
    setCurrentForm(undefined);
  };

  const companiesAvailable: ProjectCompanyInDistribution[] = React.useMemo(() => {
    if (selectedProject && mmiItems && currentForm) {
      const projectCompanies = selectedProject.getProjectCompanyList().getCompanies();
      let companiesAvailable: ProjectCompanyInDistribution[] = [];
      projectCompanies.forEach((projectCompany) => {
        const exist = mmiItems.find((rule) => rule.getCompanyInProject().IsForSameCompanyAs(projectCompany));
        if (!exist) companiesAvailable.push(projectCompany);
      });

      if (!currentForm.isNew()) {
        companiesAvailable.push(currentForm.getProjectCompany().getValue());
      }

      return companiesAvailable;
    }

    return [];
  }, [currentForm, checkAvailableOptions]);

  const emailsAvailable: string[] = React.useMemo(() => {
    if (selectedProject && currentForm) {
      const projectEmails = selectedProject
          .getProjectTeam()
          .collaborators()
          .map((col) => {
            return col.getEmail().toLowerCase();
          });

      const formEmails = currentForm
          .getTeamEmails()
          .getInputTeamEmailsItems()
          .getValue()
          .map((email) => {
            return email.toLowerCase();
          });
      const emailsAvailable = projectEmails.filter((email) => {
        return !formEmails.includes(email);
      });

      return emailsAvailable;
    }

    return [];
  }, [currentForm, checkAvailableOptions]);

  return (
      <ViewWrapperWithCommandBar
          withBorderBottom
          title={''}
          items={itemsMenu}
          farItems={farItems}
          overflowButtonStyles={defaultCommandBarButtonStyles}
          variant="highligth"
          titleBody="Docs. obligatorios"
      >
        <div className="body-bg padding-1">
          <div className="ms-Grid" dir="ltr">
            {selectedProject && showMiniPanel && (
                <ProjectMiniPanelWrapper
                    selectedProject={selectedProject}
                    system={props.system}
                    courier={props.courier}
                    user={props.user}
                    actualizateProject={() => setActualizateProject(!actualizateProject)}
                    changeBlockUI={props.changeBlockUI}
                />
            )}

            {loadedMMIActive && (
                <>
                  {hasMMIActive ? (
                      <div className="ms-Grid-row mt-2">
                        {mmiItems && loaded && selectedProject && (
                            <>
                              <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl7 ms-xxl7 mmi-form">
                                <div className="ms-Grid-row">
                                  <div className="ms-Grid-col ms-sm9 ">
                                    <Text variant={'mediumPlus'} className={'fw-500'}>
                                      <Icon iconName="MailOptions" className="text-icon" />
                                      {t('Inspector Masivo de Correos Electrónicos')}
                                    </Text>
                                  </div>
                                  <PrimaryButton
                                      text={t('Nuevo')}
                                      onClick={() => {
                                        setRule(undefined);
                                        setCurrentForm(MailInspectorRuleTagForm.initialize());
                                      }}
                                      iconProps={{ iconName: 'Add' }}
                                      className="primary-button ml-1 float-right"
                                  />
                                </div>
                                <DetailsList
                                    className="mt-2"
                                    items={mmiItems}
                                    columns={columns}
                                    selectionMode={SelectionMode.none}
                                    layoutMode={DetailsListLayoutMode.justified}
                                    isHeaderVisible={true}
                                    onRenderRow={_onRenderRow}
                                    onShouldVirtualize={() => false}
                                />
                              </div>
                              {currentForm && (
                                  <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl5 ms-xxl5">
                                    <MailInspectorRuleTagView
                                        refreshList={refreshList}
                                        changeBlockUI={props.changeBlockUI}
                                        system={props.system}
                                        courier={props.courier}
                                        ruleForm={currentForm}
                                        rules={mmiItems}
                                        projectSelected={selectedProject}
                                        onCheckAvailableOptions={onCheckAvailableOptions}
                                        // keywords={keywords}
                                        // onChangeKeywords={(strList) => {
                                        //   setKeywords(strList);
                                        //   console.log(strList);
                                        // }}
                                        onCancel={onCancel}
                                        companiesAvailable={companiesAvailable}
                                        emailsAvailable={emailsAvailable}
                                    />
                                  </div>
                              )}
                            </>
                        )}
                      </div>
                  ) : (
                      <div className="ms-Grid-col ms-lg12">
                        <p>
                          {t(
                              'El Inspector Masivo de Correos Electrónicos (MMI) es una funcionalidad adicional que se incorpora al proyecto con el fin de capturar automáticamente los correos electrónicos y asociarlos al proyecto en función de las reglas que se establezcan.'
                          )}
                        </p>
                        <PrimaryButton
                            text={t('Activar MMI')}
                            onClick={activateMMI}
                            allowDisabledFocus
                            iconProps={{ iconName: 'Mail' }}
                            // className="login-button"
                        />
                      </div>
                  )}
                </>
            )}
          </div>
        </div>
      </ViewWrapperWithCommandBar>
  );
};
