import { Facepile, Icon, IDropdownOption, IFacepilePersona, Persona, PersonaSize } from '@fluentui/react';
import { GRID_MODE } from '../../../../common/list-utils';
import { DateCustom } from '../../../../lib/DateCustom';
// import { DetailsListView, EntityGridListProps } from '../../../Common/DetailsList/DetailListViewiew';
import { useTranslation, Trans } from 'react-i18next';
import { DetailsListView, EntityGridListProps } from '../../../Common/DetailsList/DetailListView';

export const SettlementsGridList = (props: EntityGridListProps) => {
  const { t, i18n } = useTranslation();

  const formatActivo = (item: any) => {
    if (item.liq_activa === true) {
      return <Icon iconName="CheckMark" className="color-success" />;
    } else {
      return <Icon iconName="Cancel" className="color-error" />;
    }
  };

  const formatVigencia = (item: any) => {
    return (
        <span>
        {item.liq_desde} - {item.liq_hasta}
      </span>
    );
  };

  const formatImporte = (item: any) => {
    return <span>${item.importe_total}</span>;
  };

  const columns = [
    {
      fieldName: 'liq_id',
      name: t('ID'),
      maxWidth: 60,
      minWidth: 60,
    },
    {
      fieldName: 'emp_nombre',
      name: t('Empresa nombre'),
      maxWidth: 280,
      minWidth: 120,
    },
    {
      fieldName: 'liq_desde',
      name: t('Vigencia'),
      maxWidth: 100,
      minWidth: 100,
      _onRender: (item: any) => formatVigencia(item),
    },
    {
      fieldName: 'liq_fecha_carga',
      name: t('Fecha de carga'),
      maxWidth: 100,
      minWidth: 100,
    },
    {
      fieldName: 'importe_total',
      name: t('Importe total'),
      _onRender: (item: any) => formatImporte(item),
      maxWidth: 80,
      minWidth: 80,
    },
    {
      fieldName: 'liq_estado',
      name: t('Estado'),
      maxWidth: 45,
      minWidth: 45,
    },
    {
      fieldName: 'liq_activa',
      name: t('Activa'),
      _onRender: (item: any) => formatActivo(item),
      maxWidth: 20,
      minWidth: 20,
    },
  ];
  return (
      <>
        <DetailsListView
            changeBlockUI={props.changeBlockUI}
            keyList={props.keyList}
            onClickCardMode={() => props.onClickCardMode()}
            onClickGridMode={() => props.onClickGridMode()}
            mode={props.mode}
            title={t('Liquidaciones')}
            // virtualize={true}
            cols={columns}
            isGridList={true}
            courier={props.courier}
            listFilters={[]}
            quickFilters={props.quickFilters}
            onItemClick={props.onItemClick}
            baseUrl={props.baseUrl}
        ></DetailsListView>
      </>
  );
};
