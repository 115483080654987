import React, { useState, useMemo, useEffect } from 'react';
import { IStackTokens } from '@fluentui/react/lib/Stack';
import { CARD_MODE, GRID_MODE, OPERATORS, TYPES } from '../../../common/list-utils';
import { DocumentCardList } from './DocumentCardList';
import { DocumentGridList } from './DocumentGridList';
import { ListColumnFilter } from '../../Common/list-entities/ListColumnFilter';
// import './ActivityList.css';
import TimeTaxSupportSystemClient from '../../../system';
import { ContextualMenu, Dialog, DialogType, IComboBoxOption, IDropdownOption } from '@fluentui/react';
import useSystemCall from '../../../common/custom-hooks/useSystemCall';
import Project from '../../../system/projects/Project';
import MessageCourier from '../../../lib/MessageCourier';
import { User } from '../../../system/User';
import { ProjectRelatedDocument } from '../../../system/documents/ProjectRelatedDocument';
import DocumentView from '../../Proyectos/DocumentView';
import { useTranslation, Trans } from 'react-i18next';
import ProjectDescriptor from '../../../system/projects/ProjectDescriptor';
import { useNavigate, useParams } from 'react-router-dom';
import { ICalendarProps } from '@fluentui/react';
import { DateCustom } from '../../../lib/DateCustom';

const stackTokens: IStackTokens = { childrenGap: 20 };

interface IDocumentList {
  system: TimeTaxSupportSystemClient;
  courier: MessageCourier;
  user: User;
  changeBlockUI: (change: boolean) => void;
}

export const DocumentList = (props: IDocumentList) => {
  const { t, i18n } = useTranslation();
  const [mode, setMode] = useState(GRID_MODE);
  const [filtersData, setFiltersData] = useState<any>();
  const [filterPanel, setFilterPanel] = useState(false);
  const projects = useSystemCall(props.system, (system) => system.getProjects());
  const [showDialog, setShowDialog] = React.useState<boolean>(false);

  const baseUrl = React.useMemo(() => {
    return props.system.getDocumentSystem().getBaseUrlForDatagrid();
  }, [props]);

  const commonContext = useSystemCall(props.system, (system) =>
      system.getActivitySystem().getCommonContext()
  );

  const collaboratorList = React.useMemo(() => {
    let ret: IComboBoxOption[] = [];
    if (commonContext) {
      ret = commonContext.colaboradores.map((col) => {
        return {
          key: col.fullName(),
          text: col.fullName(),
        };
      });
    }
    return ret;
  }, [commonContext]);

  const queryParams = new URLSearchParams(window.location.search);
  const cargadoPor = queryParams.get('col_nombre');

  const calendarProps: ICalendarProps = { isDayPickerVisible: false };

  const quickFilters: ListColumnFilter[] = useMemo(
      () => [
        {
          quick: true,
          name: 'doc_nombre',
          type: TYPES.INPUT,
          placeholder: t('Nombre'),
          operator: OPERATORS.CONTAINS,
        },
        {
          quick: true,
          name: 'doc_activo',
          type: TYPES.CHECKBOX,
          labelForCheckbox: t('Activos'),
          checkBoxValue: '1',
          operator: OPERATORS.EQUAL,
        },
        {
          quick: true,
          name: 'doci_carga_colid',
          type: TYPES.CHECKBOX,
          labelForCheckbox: t('Mis documentos'),
          checkBoxValue: props.user.getCollaborator().getId(),
          operator: OPERATORS.EQUAL,
        },

        {
          quick: true,
          name: 'pro_codigo',
          type: TYPES.INPUT,
          placeholder: t('Proyecto código'),
          operator: OPERATORS.CONTAINS,
        },
        {
          quick: true,
          name: 'pro_nombre',
          type: TYPES.INPUT,
          placeholder: t('Proyecto nombre'),
          operator: OPERATORS.CONTAINS,
        },
        {
          quick: true,
          name: 'col_nombre',
          type: TYPES.COMBOBOX,
          placeholder: t('Cargado por'),
          operator: OPERATORS.EQUAL,
          contextForCombobox: collaboratorList || [],
          defaultValue: cargadoPor ? cargadoPor : '',
        },

        {
          quick: true,
          name: 'doct_nombre',
          type: TYPES.INPUT,
          placeholder: t('Tipo'),
          operator: OPERATORS.CONTAINS,
        },
        {
          quick: true,
          name: 'doc_refiere_a_periodo',
          type: TYPES.DATE,
          placeholder: t('Refiere a período'),
          operator: OPERATORS.EQUAL,
          calendarProps: calendarProps,
          isMonthSelector: true,
          formatDate: DateCustom.formatDateMMYYYY,
          // defaultValue: DateCustom.getFirstDayOfThisMonth(),
        },
      ],
      [filtersData, props.user, collaboratorList]
  );

  const [projectRelatedDocument, setProjectRelatedDocument] = React.useState<
      ProjectRelatedDocument | undefined
  >(undefined);

  const [show, setShow] = React.useState<boolean>(false);

  const onClickItem = async (item: any) => {
    props.changeBlockUI(true);
    if (projects) {
      let project: ProjectDescriptor | Project | undefined = projects.find((pro) =>
          pro.isIdentifiedBy(item.pro_id)
      );
      if (!project) {
        if (props.user.isDirectiveUser() || props.user.hasPowerUserPrivileges()) {
          project = await props.system.getProjectSystem().getById(item.pro_id);
        }
      }

      if (project) {
        let _projectRelatedDocument = await props.system
            .getProjectSystem()
            .projectRelatedDocument(item.doc_id, project.getId());
        if (_projectRelatedDocument) {
          setProjectRelatedDocument(_projectRelatedDocument);
          setShow(true);
        }
      }
    }
    props.changeBlockUI(false);
  };

  const dragOptions = {
    moveMenuItemText: t('Move'),
    closeMenuItemText: t('Close'),
    menu: ContextualMenu,
  };

  const modalProps = {
    isBlocking: true,
    // styles: modalPropsStyles,
    dragOptions: dragOptions,
  };

  const dialogContentProps = React.useMemo(() => {
    return {
      type: DialogType.normal,
      title: t('Documento'),
    };
  }, [props]);

  return (
      <>
        {mode == GRID_MODE ? (
            <DocumentGridList
                baseUrl={baseUrl}
                changeBlockUI={props.changeBlockUI}
                quickFilters={quickFilters}
                onClickCardMode={() => setMode(CARD_MODE)}
                onClickGridMode={() => setMode(GRID_MODE)}
                mode={mode}
                onItemClick={onClickItem}
                keyList={'documentos'}
                courier={props.courier}
            ></DocumentGridList>
        ) : (
            <DocumentCardList
                baseUrl={baseUrl}
                changeBlockUI={props.changeBlockUI}
                quickFilters={quickFilters}
                filterPanel={filterPanel}
                onItemClick={onClickItem}
                onClickCardMode={() => setMode(CARD_MODE)}
                onClickGridMode={() => setMode(GRID_MODE)}
                courier={props.courier}
                mode={mode}
            ></DocumentCardList>
        )}

        {projectRelatedDocument && (
            <Dialog
                hidden={!show}
                onDismiss={() => setShow(!show)}
                dialogContentProps={dialogContentProps}
                modalProps={modalProps}
                minWidth={500}
            >
              <DocumentView
                  document={projectRelatedDocument}
                  courier={props.courier}
                  system={props.system}
                  notifyChange={() => {}}
                  user={props.user}
                  backToControlDocsSection={() => {}}
              ></DocumentView>
            </Dialog>
        )}
      </>
  );
};
