import { Icon, Text } from '@fluentui/react';
import { DateTime } from 'luxon';
import React, { useState } from 'react';
import DialogTemplate from '../../Common/DialogTemplate';
import { useTranslation, Trans } from 'react-i18next';
import { ICollaborator } from '../../../system/collaborators/CollaboratorSystem';
import { Collaborator } from '../../../system/collaborators/Collaborator';
import TimeTaxSupportSystemClient from '../../../system';
import MessageCourier from '../../../lib/MessageCourier';

export interface LockTemplateProps {
  system: TimeTaxSupportSystemClient;
  courier: MessageCourier;
  show: boolean;
  onClose: (accept: boolean) => void;
  month: string;
  changeBlockUI: (boolean: boolean) => void;
  accept: () => void;
  //   periodo: string;
}

export const LockTemplate = (props: LockTemplateProps) => {
  const { t, i18n } = useTranslation();

  const [showAllCollaborators, setShowAllCollaborators] = useState(false);

  const cancel = () => {
    props.onClose(false);
  };

  const accept = () => {
    props.accept();
  };

  // const lockActivities = async () => {
  //   props.changeBlockUI(true);
  //   try {
  //     //llamada al servicio
  //     let response = await props.system.getTemplateSystem().changeLock(props.month.plus({ months: 1 }));
  //     //mensaje del courier
  //     props.courier.messageSuccess(t('Se han bloqueado las actividades correctamente.'));
  //     console.log(t('Se han bloqueado las actividades correctamente.'));
  //   } catch (error) {
  //     //mensaje de error
  //     props.courier.messageError(error);
  //     props.courier.messageError(t('Error'));
  //   } finally {
  //     props.changeBlockUI(false);
  //     props.onClose(true);
  //   }
  // };

  return (
      <DialogTemplate
          showDialog={props.show}
          toggleShowDialog={() => accept()}
          title={t('Planilla de costos por hora')}
          onAccept={accept}
          onCancel={cancel}
          acceptText={t('Aceptar')}
          cancelText={t('Cancelar')}
          minWidth={600}
          hideCancelButton
      >
        <div>
          <Trans>
            <Text variant="large">{t('Se sobreescribirá el costo hora del mes seleccionado')}</Text>
            <br />
            <Text block variant="small" className="mt-2">
              <Icon iconName="Warning" className="text-icon" />
              {t(
                  `AVISO: Si importas el costo hora de un colaborador que ya tenia un costo hora para el período seleccionado, se sobreescribirá la información.`
              )}
            </Text>
            <Text block variant="small"></Text>
          </Trans>
        </div>
      </DialogTemplate>
  );
};
