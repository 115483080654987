import * as React from 'react';
import { GroupedVerticalBarChart, IGroupedVerticalBarChartData } from '@fluentui/react-charting';
import { Text, Icon } from '@fluentui/react';
import { TooltipInfo } from '../../Common/TootltipInfo';
import { IAmountByConcept } from '../../../system/panel/PanelSystem';

interface IPanelVerticalBarChart {
    data: IAmountByConcept[];
    iconName: string;
    concept: string;
    color: string;
    titleChart: string | JSX.Element;
    showTooltip?: boolean;
    tooltipTitle?: string;
    tooltipMessageBody?: string;
    onClickItem?: (item: IAmountByConcept) => void;
}

const PanelVerticalBarChart = (props: IPanelVerticalBarChart) => {
    const _data: IGroupedVerticalBarChartData[] = React.useMemo(() => {
        return props.data.map(
            (item, idx): IGroupedVerticalBarChartData => ({
                name: props.data.length >= 25 ? idx + item.name[0] : item.name,
                series: [
                    {
                        key: `${props.concept}_serie`,
                        data: item.amount,
                        color: props.color,
                        legend: props.concept,
                        xAxisCalloutData: item.name,
                        yAxisCalloutData: item.amount.toString(),
                        onClick: () => {
                            if (props.onClickItem) {
                                props.onClickItem(item);
                            }
                        },
                    },
                ],
            })
        );
    }, [props.color, props.concept, props.data]);

    return (
        <div className="mt-2">
            <Text className="ml-2" variant="medium" style={{ fontSize: '15px' }}>
                <Icon iconName={props.iconName} className="text-icon"></Icon>
                {props.titleChart}
            </Text>
            <div className="float-right">
                {props.showTooltip && props.tooltipTitle && props.tooltipMessageBody && (
                    <TooltipInfo
                        inform={props.tooltipTitle}
                        title={props.tooltipTitle}
                        messageBody={props.tooltipMessageBody}
                    ></TooltipInfo>
                )}
            </div>

            <div
                className={`mt-1 ${props.data.length <= 25 ? 'xAxisVisible' : ''}`}
                style={{ width: '100%', height: '100%' }}
            >
                <GroupedVerticalBarChart
                    noOfCharsToTruncate={12}
                    data={_data}
                    showYAxisGridLines
                    wrapXAxisLables
                    showXAxisLablesTooltip
                    yAxisTickCount={10}
                    barwidth={30}
                />
            </div>
        </div>
    );
};

export default PanelVerticalBarChart;
