import * as React from 'react';
import { ProgressIndicator, Text } from '@fluentui/react';
import { Dialog, DialogType } from 'office-ui-fabric-react';
import { t } from 'i18next';

interface IBasicBlockUI {
  blockUI: boolean;
}

const BasicBlockUI = (props: IBasicBlockUI) => {
  const modalProps = React.useMemo(
      () => ({
        isBlocking: true,
      }),
      []
  );

  // const dialogStyles: Partial<IDialogContentStyles> = { title: { backgroundColor: 'red !important' } };

  const dialogContentProps = {
    type: DialogType.largeHeader,
    titleProps: { id: 'block-dialog-id' },
  };

  const block = React.useMemo(() => {
    return props.blockUI;
  }, [props.blockUI]);

  return (
      <>
        {/* <DefaultButton text="Block UI" onClick={blockUI} allowDisabledFocus /> */}
        <Dialog hidden={!block} modalProps={modalProps} dialogContentProps={dialogContentProps}>
          <div className="text-center">
            <div>
              <Text variant="mediumPlus">{t('Cargando')}</Text>
            </div>

            <div className="mt-2">
              <ProgressIndicator />
            </div>
            <div>
              <Text variant="medium" className="mt-2">
                {t('Aguarda unos instantes')}
              </Text>
            </div>
          </div>
        </Dialog>
      </>
  );
};
export default BasicBlockUI;
