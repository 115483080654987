import CollaboratorSystem, { ICompanyPosition } from './CollaboratorSystem';

export class CompanyPosition {
  constructor(private system: CollaboratorSystem, private id: string, private name: string) {}

  static fromICompanyPosition(system: CollaboratorSystem, data: ICompanyPosition) {
    return new this(system, data.cargo_id, data.cargo_nombre);
  }

  static toICompanyPosition(companyPosition: CompanyPosition) {
    let ret: ICompanyPosition = {
      cargo_id: companyPosition.getId(),
      cargo_nombre: companyPosition.getName(),
      // TODO: Revisar el cargo
      cargo_activo: true,
    };
    return ret;
  }

  getId() {
    return this.id;
  }

  getName() {
    return this.name;
  }
}
