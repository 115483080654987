import ObjectError from './ObjectError';
import CustomObjectException from './CustomObjectException';
import AssertionItem from './AssertionItem';
import { IndexableObject } from './IndexableObject';

class AssertionEntity {
  private individualAssertions: IndexableObject<AssertionItem[]>;
  private objectAssertions: AssertionItem[];

  constructor() {
    this.individualAssertions = {};
    this.objectAssertions = [];
  }

  defineMultipleAssertionsForProperty(property: string, assertions: AssertionItem[]) {
    this.individualAssertions[property] = assertions;
  }

  defineAssertionForProperty(property: string, assertion: AssertionItem) {
    this.defineMultipleAssertionsForProperty(property, [assertion]);
  }

  defineAssertionForObject(assertion: AssertionItem) {
    this.objectAssertions.push(assertion);
  }

  evaluateAllAssertions(objectToEvaluate: IndexableObject) {
    const errorList: ObjectError[] = [];

    for (const key of Object.keys(this.individualAssertions)) {
      const value = objectToEvaluate[key];
      this.collectErrors(key, value, errorList);
    }

    this.collectObjectAssertionsErrors(objectToEvaluate, errorList);

    if (errorList.length > 0) {
      const msg = errorList.length === 1 ? errorList[0].getErrorMsg() : 'Errors';
      throw new CustomObjectException(msg, errorList);
    }
  }

  evaluateObject(objectToEvaluate: IndexableObject) {
    let errorList: ObjectError[] = [];

    this.collectObjectAssertionsErrors(objectToEvaluate, errorList);

    return errorList;
  }

  evaluateIndividual(objectPropertyToEvaluate: string, value: any) {
    let errorList: ObjectError[] = [];

    this.collectErrors(objectPropertyToEvaluate, value, errorList);

    return errorList;
  }

  private collectErrors(key: string, value: any, errorList: ObjectError[]) {
    const assertionList = this.individualAssertions[key];
    if (!assertionList) return;

    assertionList.forEach((assertion) => {
      // let value2 = assertionFn.getMethodFn(objectToEvaluate);
      // const evaluatedAssertion: AssertionItem = assertionFn.evaluateFn(value);
      assertion.collectError(value, errorList);
    });
  }

  private collectObjectAssertionsErrors(objectToEvaluate: IndexableObject, errorList: ObjectError[]) {
    this.objectAssertions.forEach((objectAssertion) => {
      objectAssertion.collectError(objectToEvaluate, errorList);
    });
  }
}

export default AssertionEntity;
