import { DateTime } from 'luxon';

class XhrRequestCache {
  private requestsToCache: string[];

  private constructor() {
    this.requestsToCache = [];
  }

  static with(requestsToCache: string[]) {
    const cache = new this();
    requestsToCache.forEach((request) => {
      cache.addRequestToCache(request);
    });
    return cache;
  }

  async getValue(request: string, asyncGetter: (url: string) => Promise<any>): Promise<any> {
    if (!this.shouldCache(request)) return asyncGetter(request);

    const cachedValue = localStorage.getItem(request);
    const now = DateTime.fromJSDate(new Date());

    if (cachedValue && JSON.parse(cachedValue).timestamp) {
      const tsCached = JSON.parse(cachedValue).timestamp;
      const dtCached = DateTime.fromFormat(tsCached, 'dd/LL/yyyy HH:mm');
      if (now.diff(dtCached, 'minutes').minutes < 15) {
        return JSON.parse(cachedValue).data;
      }
    }
    console.log(`cache miss (${request})`);
    const newValue = await asyncGetter(request);
    const _ts = now.toFormat('dd/LL/yyyy HH:mm');
    localStorage.setItem(request, JSON.stringify({ data: newValue, timestamp: _ts }));
    return newValue;
  }

  addRequestToCache(request: string) {
    this.requestsToCache.push(request);
  }

  clear() {
    this.requestsToCache.forEach((r) => {
      this.clearOf(r);
    });
  }

  clearOf(aRequest: string) {
    localStorage.removeItem(aRequest);
  }

  private shouldCache(request: string): boolean {
    return this.requestsToCache.some((r) => request === r);
  }
}

export default XhrRequestCache;
