import { DataGridCell, exportDataGrid } from 'devextreme/excel_exporter';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
import { APP_NAME } from '../common/constants';
import TimeTaxSupportSystemClient from '../system';
import { ExportingEvent } from 'devextreme/ui/data_grid';
import {IMAGE_LOGO_BASE64} from "../common/datagrid";

export const getPersonaInitialsColorFromName = (name: string) => {
    let avaliableColors = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 12, 14, 16, 17, 18, 19, 20, 21, 23, 24];
};

export const parseDateTimeForList = (system: TimeTaxSupportSystemClient | undefined, date: string) => {
    return system ? system.getDateParser().parseUTC(date).toFormat('dd/MM/yyyy HH:mm') : date;
};

export interface OptionsCustonizeCell {
    gridCell?: DataGridCell | undefined;
    excelCell?: any;
}
export interface EscalableExporterOptions {
    fileName: string;
    sheetName?: string;
    customizeCell?: (options: OptionsCustonizeCell) => void;
    exportColumnsHidden?: string[];
    title?: string;
    itemsCount?: number;
}

export const exportToExcel = async (e: ExportingEvent<any, any>, options: EscalableExporterOptions) => {
    e.component.beginUpdate();

    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet(options.sheetName ? options.sheetName : 'Main Sheet');

    if (options.exportColumnsHidden) {
        options.exportColumnsHidden.forEach((field) => {
            e.component.columnOption(field, 'visible', true);
        });
    }
    const ROW_INIT_ITEMS_DATA = 4;
    await exportDataGrid({
        component: e.component,
        worksheet,
        autoFilterEnabled: true,
        customizeCell: options.customizeCell,
        topLeftCell: { row: ROW_INIT_ITEMS_DATA, column: 1 },
    })
        .then((cellRange) => {
            // Primera fila, con imagen de la empresa
            const imageId = workbook.addImage({
                base64: getDataBase64(), // Reemplaza con tus datos de imagen en formato base64
                extension: 'png',
            });
            worksheet.addImage(imageId, {
                tl: { col: 0, row: 0 },
                ext: { width: 400, height: 100 },
            });

            const firstrow = worksheet.getRow(1);
            firstrow.height = 80; // Altura específica para las filas de datos
            firstrow.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFFFFFFF' } };

            // header  con nombre de la app
            const headerRow = worksheet.getRow(2);
            headerRow.getCell(1).value = APP_NAME;

            // titulo del reporte
            const titleRow = worksheet.getRow(3);
            worksheet.mergeCells(3, 1, 3, 8);

            titleRow.height = 30;
            titleRow.getCell(1).value = options.title ? options.title : '';
            titleRow.getCell(1).font = { name: 'Segoe UI Light', size: 22 };
            titleRow.getCell(1).alignment = { horizontal: 'center' };

            if (options.itemsCount) {
                const rowCount = options.itemsCount;
                for (let rowIndex = ROW_INIT_ITEMS_DATA; rowIndex < rowCount; rowIndex++) {
                    const row = worksheet.getRow(rowIndex);
                    row.height = 20; // Altura específica para las filas de datos
                }
            }
        })
        .then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
                saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `${options.fileName}.xlsx`);
            });
        })
        .then(() => {
            if (options.exportColumnsHidden) {
                options.exportColumnsHidden.forEach((field) => {
                    e.component.columnOption(field, 'visible', false);
                });
            }
            e.component.endUpdate();
        });

    e.cancel = true;
};

/**
 * Retrieves the base64 encoded image data for the brand company from local storage.
 * If the data is not found or cannot be parsed, it logs an error and returns a default image logo base64 string.
 *
 * @return {string} The base64 encoded data for the brand company image if available, otherwise the default image logo base64 string.
 */
export function getDataBase64(): string{

    let jsonString = localStorage.getItem('/system/config');
    // Verifica si la cadena JSON existe
    if (jsonString !== null) {
        try {
            // Intenta analizar la cadena JSON
            let jsonData = JSON.parse(jsonString);
            // La variable jsonData ahora contiene los datos en formato JSON
            // Si jsonData.data.brandCompanyBase64 no existe, se retorna IMAGE_LOGO_BASE64
            let brandCompanyBase64 = jsonData?.data?.brandCompanyBase64 || IMAGE_LOGO_BASE64;
            return brandCompanyBase64;
        } catch (e) {
            console.error("Parsing error:", e);
        }
    }

    return IMAGE_LOGO_BASE64;
}

export function getDataRequiereConceptType(): string{

    let jsonString = localStorage.getItem('/system/config');

    if (jsonString !== null) {
        try {
            let jsonData = JSON.parse(jsonString);
            let  requireConceptType  = jsonData?.data?.requireConceptType || "0";
            return requireConceptType;
        } catch (e) {
            console.error("Parsing error:", e);
        }
    }

    return "0";
}

