import * as React from 'react';
import {
  ChartHoverCard,
  VerticalStackedBarChart,
  IVSChartDataPoint,
  IVerticalStackedChartProps,
  IVerticalStackedBarChartProps,
} from '@uifabric/charting';
import { DefaultPalette, IStyle, DefaultFontStyles } from '@fluentui/react/lib/Styling';
import { DirectionalHint, Icon } from '@fluentui/react';
import { CollaboratorActivityStatus } from '../../../system/panel/PanelSystem';
import { IGroupedVerticalBarChartData } from '@fluentui/react-charting';
import { Text } from '@fluentui/react';
import { useTranslation, Trans } from 'react-i18next';
import { User } from '../../../system/User';

interface IPanelVerticalStackedBarChart {
  data: CollaboratorActivityStatus;
  user: User;
}
const PanelVerticalStackedBarChart = (props: IPanelVerticalStackedBarChart) => {
  const colorActivities = '#564aa3';
  const { t, i18n } = useTranslation();

  const _chartData: IVerticalStackedChartProps[] = React.useMemo(() => {
    const chartPoints = props.data.estadoHoras.map((itemHours, idx) => {
      if (props.user.hasPowerUserPrivileges()) {
        return [
          {
            data: itemHours.horas.horasCargadasPlantilla,
            legend: t('Horas Cargadas'),
            color: DefaultPalette.blueLight,
          },
          {
            data: itemHours.horas.horasLicencia,
            legend: t('Horas Licencia'),
            color: DefaultPalette.purpleLight,
          },
          { data: itemHours.horas.horasFaltantes, legend: t('Horas Faltantes'), color: '#db756e' },
          {
            data: itemHours.horas.horasExcedidas,
            legend: t('Horas Excedidas'),
            color: DefaultPalette.neutralSecondary,
          },
        ];
      } else {
        return [
          {
            data: itemHours.horas.horasCargadasPlantilla,
            legend: t('Horas Cargadas'),
            color: DefaultPalette.blueLight,
          },
          {
            data: itemHours.horas.horasLicencia,
            legend: t('Horas Licencia'),
            color: DefaultPalette.purpleLight,
          },
          { data: itemHours.horas.horasFaltantes, legend: t('Horas Faltantes'), color: '#db756e' },
          {
            data: itemHours.horas.horasExcedidas,
            legend: t('Horas Excedidas'),
            color: DefaultPalette.neutralSecondary,
          },
        ];
      }
    });

    return chartPoints.map((item, idx) => {
      return { chartData: item, xAxisPoint: props.data.estadoHoras[idx].periodo };
    });

    // return props.data.estadoHoras.map((item, idx) => {
    //   let data: IVSChartDataPoint[] = () => {
    //     return [
    //       { chartData: item.horas, xAxisPoint: item.periodo }
    //     ]
    //   }

    //   const chartPoints: IVSChartDataPoint[] = props.data.estadoHoras.map((itemHours) => {

    //   })

    //   return data.map((chartPoint, idx) => {
    //     return {
    //       chartData: chartPoint,
    //       xAxisPoint: item.periodo
    //     }
    //   })
    // })
  }, [props.data, t]);

  const textStyle: IStyle = {
    fill: DefaultPalette.black,
    fontSize: '10px',
    lineHeight: '14px',
  };

  const customStyles: IVerticalStackedBarChartProps['styles'] = () => {
    return {
      chart: {
        paddingBottom: '14px',
      },
      chartLabel: {
        color: DefaultPalette.blueMid,
        ...DefaultFontStyles.large,
      },
      xAxisText: {
        ...textStyle,
      },
      root: {
        height: 150,
      },
    };
  };

  return (
      <div className="mt-2">
        <Text className="ml-2" variant="medium" style={{ fontSize: '14px' }}>
          <Icon iconName={'Clock'} className="text-icon"></Icon>
          {t('Horas registradas por mes')}
        </Text>

        <div className={`mt-1 xAxisVisible`}>
          <VerticalStackedBarChart
              hideLegend={true}
              data={_chartData}
              noOfCharsToTruncate={12}
              wrapXAxisLables
              showXAxisLablesTooltip
              barWidth={15}
              yAxisTickCount={5}
              // eslint-disable-next-line react/jsx-no-bind
              styles={customStyles}
              calloutProps={{
                directionalHint: DirectionalHint.topCenter,
              }}
              // eslint-disable-next-line react/jsx-no-bind
              yAxisTickFormat={(x: number | string) => `${x} h`}
              margins={{ left: 50 }}
              legendProps={{
                // allowFocusOnLegends: true,
                styles: {
                  legend: {
                    marginTop: '0px',
                  },
                  rect: {
                    borderRadius: '3px',
                  },
                },
              }}
              isCalloutForStack={true}
              // eslint-disable-next-line react/jsx-no-bind
              // onRenderCalloutPerDataPoint={(props) =>
              //   props ? (
              //     <ChartHoverCard
              //       XValue={props.xAxisCalloutData}
              //       Legend={props.legend}
              //       YValue={`${props.yAxisCalloutData || props.data} h`}
              //       color={props.color}
              //     />
              //   ) : null
              // }
          />
        </div>
      </div>
  );
};

export default PanelVerticalStackedBarChart;
