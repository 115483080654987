import * as React from 'react';
import { GroupedVerticalBarChart, IGroupedVerticalBarChartData } from '@fluentui/react-charting';
import { Text, Icon } from '@fluentui/react';
import { DefaultPalette } from '@fluentui/react/lib/Styling';
import { mergeStyles } from '@fluentui/react/lib/Styling';
import { IProjectCollaboratorSummary } from '../../../system/projects/ProjectsSystem';
import { TooltipInfo } from '../../Common/TootltipInfo';
import { useTranslation, Trans } from 'react-i18next';

interface IProjectPanelGroupedBarCollaborator {
  collaboratorData: IProjectCollaboratorSummary[];
}
const ProjectPanelGroupedBarCollaborator = (props: IProjectPanelGroupedBarCollaborator) => {
  const { t, i18n } = useTranslation();
  const colorDocs = '#cddc39';
  const colorHours = '#2f80e7';
  const colorActivities = '#564aa3';

  const _data: IGroupedVerticalBarChartData[] = React.useMemo(() => {
    return props.collaboratorData.map((item) => {
      return {
        name: item.colaborador.col_nombre + ' ' + item.colaborador.col_apellido,
        series: [
          {
            key: 'activities_serie',
            data: item.activities,
            color: colorActivities,
            legend: t('Actividades'),
            xAxisCalloutData: t('Actividades'),
            yAxisCalloutData: item.activities.toString(),
          },

          {
            key: 'hours_serie',
            data: item.hours,
            color: colorHours,
            legend: t('Horas'),
            xAxisCalloutData: t('Horas'),
            yAxisCalloutData: item.hours.toString(),
          },
          {
            key: 'docs_serie',
            data: item.documents,
            color: colorDocs,
            legend: t('Documentos'),
            xAxisCalloutData: t('Documentos'),
            yAxisCalloutData: item.documents.toString(),
          },
        ],
      };
    });
  }, [props.collaboratorData]);

  return (
      <div className="mt-2">
        <Text className="ml-2" variant="medium">
          <Icon iconName="Group" className="text-icon"></Icon>
          {t('Estado de participación del equipo')}
        </Text>
        <div className="float-right">
          <TooltipInfo
              inform="ProjectPanelGroupedBarCollaborator"
              title={t('¿Cómo se calculan las series?')}
              messageBody={t(
                  'Se contabilizan horas, actividades y documentos cargados en las actividades que los colaboradores hayan aceptado'
              )}
          ></TooltipInfo>
        </div>

        <div className="xAxisVisible" style={{ width: '100%', height: '100%' }}>
          <GroupedVerticalBarChart
              noOfCharsToTruncate={12}
              data={_data}
              showYAxisGridLines
              wrapXAxisLables
              showXAxisLablesTooltip
              yAxisTickCount={10}
              barwidth={30}
              className="projectPanelVerticalBarChart"
              // barwidth={_data.length * 10}
          />
        </div>
      </div>
  );
};

export default ProjectPanelGroupedBarCollaborator;
