import { IUserProfile } from './AuthenticationSystem';

const powerUserType = 'pwrUser';
const adminUserType = 'adminUser';
const standardUserType = 'stdUser';
const directiveUserType = 'executive';

export class UserProfile {
  protected constructor(private id: string, private name: string, private type: string) {}

  static fromIUserProfile(profile: IUserProfile) {
    return new this(profile.per_id, profile.per_nombre, profile.per_nemotecnico);
  }

  isNamed(aName: string) {
    return this.name === aName;
  }

  isStandardUser(): boolean {
    return this.type === standardUserType;
  }

  isDirectiveUser(): boolean {
    return this.type === directiveUserType;
  }

  isPowerUser(): boolean {
    return this.type === powerUserType;
  }

  isAdminUser(): boolean {
    return this.type === adminUserType;
  }

  getName(): string {
    return this.name;
  }
}
