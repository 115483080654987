import React from 'react';
import { Separator } from '@fluentui/react/lib/Separator';
import { MarqueeSelection, Selection } from '@fluentui/react/lib/MarqueeSelection';
import { DetailsList, IColumn } from '@fluentui/react/lib/DetailsList';
import { DefaultPalette, Text } from '@fluentui/react';

import { ProjectRelatedDocument } from '../../system/documents/ProjectRelatedDocument';
import CollaboratorPersona from '../Collaborator/CollaboratorPersona';
import { Collaborator } from '../../system/collaborators/Collaborator';
import { DocumentContentTypeIcon } from './DocumentContentTypeIcon';
import { SizeInBytesLabel } from './SizeInBytesLabel';
import i18next, { t } from 'i18next';

const separatorStyles = { root: { height: '1px', padding: 0 } };

interface IDocumentForDisplay {
  key: string;
  name: string;
  type: string;
  contentType: string;
  collaborator: Collaborator;
  uploadedBy: string;
  uploadDate: string;
  sizeInBytes: number;
}

interface IProjectDocumentListProps {
  documents: ProjectRelatedDocument[];
  onSelect: (document: ProjectRelatedDocument) => void;
  onDragDocumentStart: (document: ProjectRelatedDocument[]) => void;
  onDragDocumentEnd: () => void;
  selected?: ProjectRelatedDocument;
  separatorOnTop?: boolean;
  noDocumentsMessage: string;
}

interface IProjectDocumentListState {
  sortedDocuments: IDocumentForDisplay[];
  selectedDocuments: ProjectRelatedDocument[];
  columns: IColumn[];
}

class ProjectDocumentList extends React.Component<IProjectDocumentListProps, IProjectDocumentListState> {
  private selection: Selection;

  constructor(props: IProjectDocumentListProps) {
    super(props);

    this.selection = new Selection({
      onSelectionChanged: this.onSelectionChanged,
    });
    this.state = {
      sortedDocuments: [],
      selectedDocuments: [],
      columns: this.buildColumns(),
    };
  }

  async componentDidMount() {
    const uploadedDocuments = this.props.documents.filter((document) => document.uploaded());
    const result = await this.transformUploadedDocuments(uploadedDocuments);

    this.setState({ sortedDocuments: result });
  }

  private async transformUploadedDocuments(
      uploadedDocuments: ProjectRelatedDocument[]
  ): Promise<IDocumentForDisplay[]> {
    const result: IDocumentForDisplay[] = [];
    for (const document of uploadedDocuments) {
      await this.transformDocumentToDisplay(document, result);
    }
    return result;
  }

  private async transformDocumentToDisplay(document: ProjectRelatedDocument, result: IDocumentForDisplay[]) {
    const typeDescription = await document.getTypeDescription();
    const uploadedBy = await document.getUploadedBy();

    result.push({
      name: document.getName(),
      key: document.getId(),
      type: typeDescription,
      contentType: document.getContentType(),
      uploadedBy: uploadedBy.fullName(),
      collaborator: uploadedBy,
      uploadDate: document.getUploadedDate(),
      sizeInBytes: document.getSizeInBytes(),
    });
  }

  render() {
    const { separatorOnTop } = this.props;
    const { columns, sortedDocuments } = this.state;

    return (
        <React.Fragment>
          {separatorOnTop && <Separator styles={separatorStyles} />}
          <div style={{ backgroundColor: DefaultPalette.white, minHeight: '20rem', maxHeight: '40rem' }}>
            {sortedDocuments.length > 0 ? (
                <MarqueeSelection selection={this.selection}>
                  <DetailsList
                      setKey="items"
                      items={sortedDocuments}
                      columns={columns}
                      selection={this.selection}
                      selectionPreservedOnEmptyClick={true}
                      // onRenderItemColumn={this._onRenderItemColumn}
                      onShouldVirtualize={() => false}
                      dragDropEvents={{
                        canDrag(item) {
                          return true;
                        },
                        onDragStart: (item: IDocumentForDisplay) => {
                          this.onDragStart(item);
                        },
                        onDragEnd: (item: IDocumentForDisplay) => {
                          this.onDragEnd(item);
                        },
                      }}
                      ariaLabelForSelectionColumn="Toggle selection"
                      ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                      checkButtonAriaLabel="select row"
                  />
                </MarqueeSelection>
            ) : (
                <div className="text-center no-documents-message-container">
                  <Text variant="medium">{this.props.noDocumentsMessage}</Text>
                </div>
            )}
          </div>
        </React.Fragment>
    );
  }

  private buildColumns(): IColumn[] {
    return [
      {
        key: 'name',
        name: t('Nombre'),
        fieldName: 'name',
        minWidth: 50,
        onColumnClick: this.onColumnClicked,
        onRender(item: IDocumentForDisplay) {
          return (
              <>
                <DocumentContentTypeIcon name={item.name} /> {item.name}
              </>
          );
        },
        isResizable: true,
      },
      {
        key: 'sizeInBytes',
        name: t('Tamaño'),
        fieldName: 'sizeInBytes',
        minWidth: 50,
        onColumnClick: this.onColumnClicked,
        onRender(item: IDocumentForDisplay) {
          return <SizeInBytesLabel size={item.sizeInBytes} />;
        },

        isResizable: true,
      },
      {
        key: 'uploadedBy',
        fieldName: 'uploadedBy',
        name: t('Cargado por'),
        minWidth: 50,
        onColumnClick: this.onColumnClicked,
        onRender(item: IDocumentForDisplay) {
          return <CollaboratorPersona collaborator={item.collaborator} small />;
        },
        isResizable: true,
      },
      {
        key: 'type',
        fieldName: 'type',
        name: t('Tipo'),
        minWidth: 50,
        onColumnClick: this.onColumnClicked,
        isResizable: true,
      },
      {
        key: 'uploadDate',
        fieldName: 'uploadDate',
        name: t('Fecha carga'),
        minWidth: 50,
        onColumnClick: this.onColumnClicked,
        isResizable: true,
      },
    ];
  }

  private onSelectionChanged = () => {
    const selectedDocuments = this.selection.getSelection() as IDocumentForDisplay[];
    const docs = selectedDocuments.map((selectedDoc) => this.findDocumentFromDisplay(selectedDoc));

    if (docs.length === 1) {
      this.props.onSelect(docs[0]);
    }
    this.setState({ selectedDocuments: docs });
  };

  private onDragStart = (documentInList: IDocumentForDisplay) => {
    const { selectedDocuments } = this.state;
    const document = this.findDocumentFromDisplay(documentInList);
    if (selectedDocuments.includes(document)) {
      return this.props.onDragDocumentStart([...selectedDocuments]);
    }
    this.props.onDragDocumentStart([document]);
  };

  private onDragEnd = (document: IDocumentForDisplay) => {
    this.props.onDragDocumentEnd();
  };

  private findDocumentFromDisplay(selectedDoc: IDocumentForDisplay): ProjectRelatedDocument {
    return this.props.documents.find((doc) => doc.isIdentifiedBy(selectedDoc.key))!;
  }

  private onColumnClicked = (ev: unknown, column: IColumn) => {
    const { columns } = this.state;
    let { sortedDocuments } = this.state;
    let isSortedDescending = column.isSortedDescending;

    // If we've sorted this column, flip it.
    if (column.isSorted) {
      isSortedDescending = !isSortedDescending;
    }

    // Sort the items.
    sortedDocuments = this.copyAndSort(sortedDocuments, column.key, isSortedDescending);

    // Reset the items and columns to match the state.
    this.setState({
      sortedDocuments: sortedDocuments,
      columns: columns.map((col) => {
        col.isSorted = col.key === column.key;

        if (col.isSorted) {
          col.isSortedDescending = isSortedDescending;
        }

        return col;
      }),
    });
  };

  private copyAndSort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
    const key = columnKey as keyof T;
    return [...items].sort((a: T, b: T) =>
        (isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1
    );
  }
}

export default ProjectDocumentList;
