import { ProjectCompanyListForm } from '../../Components/Proyectos/ProjectConfiguration/ProjectConfigurationDistributionForm';
import { CompanyJson, Company } from '../company/Company';
import { ProjectCompanyInDistribution } from './IProjectCompanyInDistribution';
import { ProjectCompanyAssertions } from './ProjectCompanyAssertions';

export interface ProjectCompanyJson {
  exp_id: string;
  exp_porcentaje: string;
  exp_empid: string;
  empresa: CompanyJson;
  __delete: boolean;
}

export class ProjectCompany extends ProjectCompanyInDistribution {
  private constructor(private percentage: number, private company: Company) {
    super();
  }

  static with(percentage: number, company: Company) {
    const assertions = ProjectCompanyAssertions.build();
    assertions.evaluateAllAssertions({ percentage, company });
    return new this(percentage, company);
  }

  asJson(): ProjectCompanyJson {
    return {
      exp_id: '',
      exp_empid: this.company.getId(),
      exp_porcentaje: this.percentage.toString(),
      empresa: Company.toJson(this.company),
      __delete: false,
    };
  }

  getCompany(): Company {
    return this.company;
  }

  getPercentage(): number {
    return this.percentage;
  }

  delete(list: ProjectCompanyListForm): void {
    list.deleteProjectCompany(this);
  }

  addTo(list: ProjectCompanyListForm, projectCompany: ProjectCompanyInDistribution): void {
    list.addProjectCompany(this, projectCompany);
  }

  collectCompanyInto(list: Company[]): void {
    list.push(this.getCompany());
  }

  affectTotalPercentage(partialSum: number): number {
    return partialSum + this.getPercentage();
  }

  setPercentage(percentage: number): void {
    this.percentage = percentage;
  }

  isActive(): boolean {
    return true;
  }
}
