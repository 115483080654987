import {
  DocumentCard,
  DocumentCardActivity,
  DocumentCardDetails,
  DocumentCardPreview,
  DocumentCardTitle,
  IDocumentCardPreviewProps,
  DocumentCardType,
  IDocumentCardActivityPerson,
  DocumentCardStatus,
} from '@fluentui/react/lib/DocumentCard';
import { getTheme } from '@fluentui/react/lib/Styling';
import { DetailsListView } from '../../../Common/DetailsList/DetailListView';
import { timeSince } from '../../../../common/list-utils';
import { useTranslation, Trans } from 'react-i18next';

const theme = getTheme();
const { palette, fonts } = theme;

export const SettlementsCardList = (props: any) => {
  const { t, i18n } = useTranslation();
  const getPeople = (
      col_iniciales: string,
      col_nombre: string,
      col_imagen_url: string
  ): IDocumentCardActivityPerson[] => {
    return [
      {
        name: col_nombre,
        profileImageSrc: col_imagen_url,
        initials: col_iniciales,
      },
    ];
  };

  const previewPropsUsingIcon: IDocumentCardPreviewProps = {
    previewImages: [
      {
        previewIconProps: {
          iconName: 'TableComputed',
          styles: { root: { fontSize: fonts.superLarge.fontSize, color: palette.white } },
        },
        width: 144,
      },
    ],
    styles: { previewIcon: { backgroundColor: palette.themePrimary } },
  };

  const mapCard = (archivoDefensa: any, i: number) => {
    return (
        <div className="ms-Grid-col ms-sm6 ms-md12 ms-lg6 ms-xl4" key={i}>
          <DocumentCard className="size-document-card" type={DocumentCardType.compact}>
            <DocumentCardPreview {...previewPropsUsingIcon} className="size-logo" />
            <DocumentCardDetails>
              <DocumentCardTitle title={archivoDefensa.emp_nombre} shouldTruncate />

              <DocumentCardActivity
                  activity={timeSince(archivoDefensa.liq_fecha_carga)}
                  people={getPeople(
                      archivoDefensa.col_iniciales,
                      archivoDefensa.col_nombre,
                      archivoDefensa.col_imagen_url
                  )}
              />
            </DocumentCardDetails>
          </DocumentCard>{' '}
        </div>
    );
  };

  return (
      <DetailsListView
          keyList={'liquidaciones'}
          changeBlockUI={props.changeBlockUI}
          onClickCardMode={() => props.onClickCardMode()}
          onClickGridMode={() => props.onClickGridMode()}
          title={t('Liquidaciones')}
          mode={props.mode}
          isGridList={false}
          courier={props.courier}
          listFilters={[]}
          quickFilters={props.quickFilters}
          baseUrl={props.baseUrl}
          mapCard={mapCard}
      ></DetailsListView>
  );
};
