import { downloadExcel } from 'react-export-table-to-excel';
import { IAmountByConcept } from '../../../system/panel/PanelSystem';

class AmountByConceptExporter {
  private constructor(
      private hours: IAmountByConcept[],
      private header: string[],
      private fileName: string,
      private sheetName: string
  ) {}

  static exportFor(
      hours: IAmountByConcept[],
      header: string[],
      fileName: string = 'Horas',
      sheetName: string = 'horas'
  ) {
    const exporter = new this(hours, header, fileName, sheetName);
    exporter.export();
  }

  export() {
    downloadExcel({
      fileName: this.fileName,
      sheet: this.sheetName,
      tablePayload: {
        header: this.header,
        body: this.getBody(),
      },
    });
  }

  private getBody() {
    return this.hours.map((item) => {
      return [item.name, item.amount];
    });
  }
}

export default AmountByConceptExporter;
