import * as React from 'react';
import useAsyncDataCourier from '../../../common/custom-hooks/useAsyncDataCourier';
import TimeTaxSupportSystemClient from '../../../system';
import MessageCourier from '../../../lib/MessageCourier';
import PanelProjectsBarChart from '../PanelProjectsBarChart';
import { Icon, PrimaryButton, Text } from 'office-ui-fabric-react';
import { PanelProjectsSelector } from '../PanelProjectsSelector';
import { DateTime } from 'luxon';
import { ICommandBarItemProps } from '@fluentui/react';
import { buttonStylesForProjects, defaultCommandBarButtonStyles } from '../../../styles/button-styles';

import { useNavigate } from 'react-router-dom';

import { routes } from '../../../common/constants';
import ViewWrapperWithCommandBar from '../../Layout/ViewWrapperWithCommandBar';
import { DateCustom } from '../../../lib/DateCustom';
import BasicChartPad from '../../Common/BasicChartPad';
import PanelDonutChart from '../PanelCollaborators/PanelDonutChart';
import { User } from '../../../system/User';
import { useTranslation, Trans } from 'react-i18next';
import { ICollaboratorsSummary, IPanelProjectSummary } from '../../../system/panel/PanelSystem';
import AmountByConceptExporter from '../ExcelExport/AmountByConceptExporter';
import { PanelProjectsProgressDetailList } from './PanelProjectsProgressDetailList';
import { PillFluent } from '../../Common/PillFluent';

interface IProjectPanel {
  system: TimeTaxSupportSystemClient;
  courier: MessageCourier;
  changeBlockUI: (change: boolean) => void;
  user: User;
}

export const PanelProjectsProgress = (props: IProjectPanel) => {
  const { t, i18n } = useTranslation();

  const [organizationNodeId, setOrganizationNodeId] = React.useState<string | undefined>(undefined);
  const [fromDate, setFromDate] = React.useState<DateTime | undefined>(undefined);
  const [toDate, setToDate] = React.useState<DateTime | undefined>(undefined);

  const [activitiesForProject, setActivitiesForProject] = React.useState<IPanelProjectSummary[]>([]);
  const [documentsForProject, setDocumentsForProject] = React.useState<IPanelProjectSummary[]>([]);
  const [collaboratorsSummary, setCollaboratorsSummary] = React.useState<ICollaboratorsSummary | undefined>(
      undefined
  );

  const navigate = useNavigate();

  const getPanelsData = async () => {
    if (fromDate && toDate && organizationNodeId) {
      props.changeBlockUI(true);
      const _activitiesForProject = await props.system
          .getPanelSystem()
          .getActivitiesForProject(organizationNodeId, fromDate, toDate);
      setActivitiesForProject(_activitiesForProject);

      const _documentsForProject = await props.system
          .getPanelSystem()
          .getDocumentsForProject(organizationNodeId, fromDate, toDate);
      setDocumentsForProject(_documentsForProject);

      const _collaboratorsSummary = await props.system
          .getPanelSystem()
          .getCollaboratorsSummary(fromDate, toDate, organizationNodeId);
      setCollaboratorsSummary(_collaboratorsSummary);
      props.changeBlockUI(false);
    }
  };

  const onClickSearch = async () => {
    getPanelsData();
  };

  // React.useEffect(() => {
  //   getPanelsData();
  // }, [props.system, organizationNodeId, fromDate, toDate]);

  const setSelectedOrganizationNodeId = (id: string) => {
    if (id) setOrganizationNodeId(id);
  };

  const setFrom = (date: DateTime) => {
    setFromDate(date);
  };

  const setTo = (date: DateTime) => {
    setToDate(date);
  };

  const items = React.useMemo(
      (): ICommandBarItemProps[] => [
        {
          key: 'project',
          text: t('Mi Panel'),
          onClick: () => navigate(`${routes.dashboard_own}`),
          itemProps: {},
          iconProps: { iconName: 'BIDashboard' },
          buttonStyles: defaultCommandBarButtonStyles,
        },
        {
          key: 'download',
          text: t('Avance de proyectos'),
          onClick: () => navigate(`${routes.dashboard}`),
          iconProps: { iconName: 'TeamWork' },
          buttonStyles: buttonStylesForProjects,
        },
        {
          key: 'panel_companies',
          text: t('Empresas'),
          onClick: () => navigate(`${routes.dashboard_empresas}`),
          iconProps: { iconName: 'CityNext2' },
          buttonStyles: defaultCommandBarButtonStyles,
        },
        {
          key: 'colabs',
          text: t('Colaboradores'),
          onClick: () => navigate(`${routes.dashboard_colaboradores}`),
          iconProps: { iconName: 'ReminderPerson' },
          buttonStyles: defaultCommandBarButtonStyles,
        },
      ],
      [navigate]
  );

  const getTotalProjectHs = () => {
    let hs = 0;
    collaboratorsSummary?.hoursForProjects.forEach((element) => {
      hs += element.amount;
    });
    return hs;
  };
  const getTotalActivities = () => {
    let total = 0;
    activitiesForProject?.forEach((element) => {
      total += element.amount;
    });
    return total;
  };

  const getTotalDocuments = () => {
    let total = 0;
    documentsForProject?.forEach((element) => {
      total += element.amount;
    });
    return total;
  };

  const getDetailsListItems = () => {
    let items = [];
  };

  React.useEffect(() => {
    getDetailsListItems();
  });

  return (
      <ViewWrapperWithCommandBar
          withBorderBottom
          title={''}
          items={items}
          overflowButtonStyles={defaultCommandBarButtonStyles}
          // overflowItems={overflowItems}
          variant="highligth"
          titleBody={t('Panel')}
      >
        <div className="ms-Grid padding-1" dir="ltr">
          <PanelProjectsSelector
              user={props.user}
              system={props.system}
              courier={props.courier}
              onChangeOrganizationNodeId={setSelectedOrganizationNodeId}
              onChangeFromDate={setFrom}
              onChangeToDate={setTo}
              onClickSearch={onClickSearch}
          />
          <hr></hr>
          {collaboratorsSummary &&
              collaboratorsSummary.hoursForProjects &&
              activitiesForProject &&
              activitiesForProject.length > 0 &&
              documentsForProject &&
              documentsForProject.length > 0 && (
                  <>
                    <div className="ms-Grid-row">
                      <div className="ms-Grid-col ms-sm12">
                        <PrimaryButton
                            text={t('Exportar')}
                            iconProps={{ iconName: 'ExcelLogo' }}
                            className="float-right ml-2"
                            menuProps={{
                              items: [
                                // {
                                //   key: 'exportSector',
                                //   text: 'Horas por sector',
                                //   onClick: () =>
                                //     AmountByConceptExporter.exportFor(
                                //       collaboratorsSummary,
                                //       ['Sector', 'Horas'],
                                //       'Horas por sector'
                                //     ),
                                // },
                                {
                                  key: 'exportSector',
                                  text: t('Horas por sector'),
                                  onClick: () =>
                                      AmountByConceptExporter.exportFor(
                                          collaboratorsSummary.hoursForLastLevelSector,
                                          ['Sector', 'Horas'],
                                          'Horas por sector'
                                      ),
                                },
                                {
                                  key: 'exportProject',
                                  text: t('Horas por proyecto'),
                                  onClick: () =>
                                      AmountByConceptExporter.exportFor(
                                          collaboratorsSummary.hoursForProjects,
                                          ['Proyecto', 'Horas'],
                                          'Horas por proyecto'
                                      ),
                                },
                                {
                                  key: 'exportCompanies',
                                  text: t('Horas por empresa'),
                                  onClick: () =>
                                      AmountByConceptExporter.exportFor(
                                          collaboratorsSummary.hoursForCompanies,
                                          ['Empresa', 'Horas'],
                                          'Horas por empresa'
                                      ),
                                },
                              ],
                            }}
                        />
                      </div>
                    </div>
                    <div className="ms-Grid-row mt-2">
                      <div className="ms-Grid-col ms-sm4">
                        {/* <BasicChartPad
                    previewText={getTotalProjectHs().toFixed(2)}
                    mainText={t('Hs / Proyecto')}
                    secondaryText={getTotalProjectHs().toFixed(2) + t(' horas totales registradas')}
                    iconName="Clock"
                  /> */}
                        <PillFluent
                            title={t('Hs / Proyecto')}
                            iconTitle={'Clock'}
                            series={[
                              { label: t('Horas totales'), value: getTotalProjectHs().toFixed(2), icon: 'Clock' },
                            ]}
                            small={false}
                        ></PillFluent>
                      </div>
                      <div className="ms-Grid-col ms-sm4">
                        {/* <BasicChartPad
                    previewText={getTotalActivities().toFixed(2)}
                    mainText={t('Actividades')}
                    secondaryText={getTotalActivities().toFixed(2) + t(' actividades registradas')}
                    iconName="AccountActivity"
                  /> */}
                        <PillFluent
                            title={t('Actividades')}
                            iconTitle={'AccountActivity'}
                            series={[
                              {
                                label: t('Actividades registradas'),
                                value: getTotalActivities().toFixed(2),
                                icon: 'AccountActivity',
                              },
                            ]}
                            small={false}
                        ></PillFluent>
                      </div>
                      <div className="ms-Grid-col ms-sm4">
                        {/* <BasicChartPad
                    previewText={getTotalDocuments()}
                    mainText={t('Documentos')}
                    secondaryText={getTotalDocuments() + t(' documentos registrados')}
                    iconName="TextDocument"
                  /> */}
                        <PillFluent
                            title={t('Documentos')}
                            iconTitle={'TextDocument'}
                            series={[
                              { label: t('Docs. registrados'), value: getTotalDocuments(), icon: 'TextDocument' },
                            ]}
                            small={false}
                        ></PillFluent>
                      </div>
                      <div className="ms-Grid-row mt-2">
                        <div className="ms-Grid-col ms-xl12 ms-xxl4">
                          {collaboratorsSummary.hoursForLastLevelSector.length > 0 ? (
                              <PanelDonutChart
                                  data={collaboratorsSummary.hoursForLastLevelSector}
                                  iconName={'AddGroup'}
                                  titleChart={t('Horas por areas')}
                              ></PanelDonutChart>
                          ) : (
                              <div className="text-center mt-2">
                                <Text variant="medium" className="fw-500">
                                  <Icon iconName="AddGroup" className="text-icon"></Icon>
                                  {t('No hay horas registradas en areas para los filtros seleccionados')}
                                </Text>
                              </div>
                          )}
                        </div>
                        <div className="ms-Grid-col ms-xl12 ms-xxl4">
                          {collaboratorsSummary.hoursForProjects.length > 0 ? (
                              <PanelDonutChart
                                  data={collaboratorsSummary.hoursForProjects}
                                  iconName={'TeamWork'}
                                  titleChart={t('Horas por proyectos')}
                              ></PanelDonutChart>
                          ) : (
                              <div className="text-center mt-2">
                                <Text variant="medium" className="fw-500">
                                  <Icon iconName="AddGroup" className="text-icon"></Icon>
                                  {t('No hay horas registradas en proyectos para los filtros seleccionados')}
                                </Text>
                              </div>
                          )}
                        </div>
                        <div className="ms-Grid-col ms-xl12 ms-xxl4">
                          {collaboratorsSummary.hoursForCompanies.length > 0 ? (
                              <PanelDonutChart
                                  data={collaboratorsSummary.hoursForCompanies}
                                  iconName={'CityNext2'}
                                  titleChart={t('Horas por empresas')}
                              ></PanelDonutChart>
                          ) : (
                              <div className="text-center mt-2">
                                <Text variant="medium" className="fw-500">
                                  <Icon iconName="CityNext2" className="text-icon"></Icon>
                                  {t('No hay horas registradas en empresas para los filtros seleccionados')}
                                </Text>
                              </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </>
              )}
          {activitiesForProject &&
              activitiesForProject.length > 0 &&
              collaboratorsSummary &&
              collaboratorsSummary.hoursForProjects.length > 0 && (
                  <PanelProjectsBarChart
                      color={'#3f51b5'}
                      data={collaboratorsSummary.hoursForProjects}
                      concept={t('Horas')}
                      iconName={'Clock'}
                      titleChart={t('Horas registradas por proyecto')}
                  ></PanelProjectsBarChart>
              )}
          {activitiesForProject && activitiesForProject.length > 0 ? (
              <PanelProjectsBarChart
                  color={'#564aa3'}
                  data={activitiesForProject}
                  concept={t('Actividades')}
                  iconName={'AccountActivity'}
                  titleChart={t('Actividades')}
              ></PanelProjectsBarChart>
          ) : (
              <div className="text-center mt-2">
                <Text variant="medium" className="fw-500">
                  <Icon iconName="AccountActivity" className="text-icon"></Icon>
                  {t('No hay actividades registradas para los filtros seleccionados')}
                </Text>
              </div>
          )}
          {documentsForProject && documentsForProject.length > 0 ? (
              <PanelProjectsBarChart
                  color={'#ffc107'}
                  data={documentsForProject}
                  concept={t('Documentos')}
                  iconName={'TextDocument'}
                  titleChart={t('Documentos')}
              ></PanelProjectsBarChart>
          ) : (
              <div className="text-center mt-2">
                <Text variant="medium" className="fw-500">
                  <Icon iconName="TextDocument" className="text-icon"></Icon>
                  {t('No hay documentos registrados para los filtros seleccionados')}
                </Text>
              </div>
          )}
          {/* <div className="ms-Grid-row mt-5">
          <PrimaryButton
            iconProps={{ iconName: 'ExcelLogo' }}
            className="float-right ml-2"
            onClick={() => exportCollaboratorsComparison(collaboratorPanel.projectDetail || [])}
          >
            {t('Exportar')}
          </PrimaryButton>
        </div> */}
          <div className="mt-2">
            {activitiesForProject.length > 0 ? (
                <div className="mt-5 bg-white-h100">
                  <PanelProjectsProgressDetailList
                      data={activitiesForProject.length > 0 ? activitiesForProject : []}
                  />
                </div>
            ) : (
                <div className="text-center mt-2">
                  <Text variant="medium" className="fw-500">
                    <Icon iconName="AddGroup" className="text-icon"></Icon>
                    {t('No hay horas registradas en areas para los filtros seleccionados')}
                  </Text>
                </div>
            )}
          </div>
        </div>
      </ViewWrapperWithCommandBar>
  );
};
