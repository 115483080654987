import React, { useEffect, useState, useMemo } from 'react';
import { IStackTokens, Stack } from '@fluentui/react/lib/Stack';
import { TextField } from '@fluentui/react/lib/TextField';
import { Toggle, DatePicker } from '@fluentui/react';
import { Text } from '@fluentui/react/lib/Text';
import { DateCustom } from '../../../lib/DateCustom';
import {
  Dropdown,
  DropdownMenuItemType,
  IDropdownStyles,
  IDropdownOption,
} from '@fluentui/react/lib/Dropdown';
import { DateTime } from 'luxon';
import TaxOfficeSelector from '../../TaxOffice/TaxOfficeSelector';
import {
  DefenseFileRequirement,
  INewDefenseFileRequirement,
} from '../../../system/defense_file/DefenseFileSystem';
import TimeTaxSupportSystemClient from '../../../system';
import MessageCourier from '../../../lib/MessageCourier';
import DefenseFileTaxOfficeRequirement from '../../../system/defense_file/DefenseFileTaxOfficeRequirement';
import DefenseFileGenericRequirement from '../../../system/defense_file/DefenseFileGenericRequirement';
import { useTranslation, Trans } from 'react-i18next';
import { getLocalizedStrings } from '../../../lib/LocalizedStringsForDatePicker';

interface IDocumentationSelector {
  system: TimeTaxSupportSystemClient;
  courier: MessageCourier;
  setButtonDisability: (can: boolean) => void;
  getDataFromDocumentationSelector: (
      requirementType: string,
      requirementData: DefenseFileRequirement
  ) => void;
}

const DocumentationSelector = (props: IDocumentationSelector) => {
  const { t, i18n } = useTranslation();
  const [requirementType, setRequirementType] = useState<any>();
  const [motive, setMotive] = useState<string>('');
  const [requirement, setRequirement] = useState<string>();
  const [fiscalOffice, setOficinaFiscal] = useState<any>('');
  const [requirementNumber, setNroRequirement] = useState<string>();
  const [requirementDate, setFechaRequirement] = useState<Date | any>(DateTime.fromJSDate(new Date())); //cambiarlo a date
  const [fiscalAgent, setFiscalAgent] = useState<string>();
  const [detail, setDetail] = useState<string>();

  const fiscalRequirement = 'RF';
  const otherMotive = 'OM';

  useEffect(() => {
    if (requirementType == otherMotive) {
      if (requirementType && motive) {
        props.setButtonDisability(true);
        let requirementData = DefenseFileGenericRequirement.forDescription(motive);
        props.getDataFromDocumentationSelector(requirementType, requirementData);
      } else {
        props.setButtonDisability(false);
      }
    }
  }, [requirementType, motive]);

  useEffect(() => {
    if (requirementType == fiscalRequirement) {
      if (requirement && fiscalOffice && requirementDate && requirementNumber && fiscalAgent && detail) {
        props.setButtonDisability(true);

        let requirementData = DefenseFileTaxOfficeRequirement.forDescription(
            requirementNumber,
            requirement,
            fiscalOffice,
            fiscalAgent,
            detail,
            requirementDate
        );
        props.getDataFromDocumentationSelector(requirementType, requirementData);
      } else {
        props.setButtonDisability(false);
      }
    }
  }, [requirement, fiscalOffice, requirementDate, requirementNumber, fiscalAgent, detail, requirementType]);

  useEffect(() => {
    if (!requirementType) {
      props.setButtonDisability(false);
    }
  }, [requirementType]);

  const options: IDropdownOption[] = [
    { key: fiscalRequirement, text: t('Requerimiento Fiscal') },
    { key: otherMotive, text: t('Otro Motivo') },
  ];

  const onChangeType = (event: unknown, option?: IDropdownOption<any> | undefined) => {
    setRequirementType(option ? String(option.key) : '');
  };

  const onChangeDate = (date: Date | null | undefined, name: string) => {
    if (date) setFechaRequirement(DateTime.fromJSDate(date));
  };

  const form = () => {
    if (requirementType == fiscalRequirement) {
      return (
          <>
            <div className="ms-Grid-row">
              <div className="ms-Grid-col  ms-sm6 mb-2">
                <TextField
                    label={t('Requerimiento')}
                    required
                    // eslint-disable-next-line react/jsx-no-bind
                    onBlur={(event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) =>
                        setRequirement(event?.target.value)
                    }
                />
              </div>
              <div className="ms-Grid-col  ms-sm6 mb-2">
                <TaxOfficeSelector
                    onChangeSelected={(o) => setOficinaFiscal(o)}
                    system={props.system}
                    courier={props.courier}
                />
              </div>
            </div>
            <div className="ms-Grid-row">
              <div className="ms-Grid-col  ms-sm6 mb-2">
                <TextField
                    label={t('Nro de Requerimiento')}
                    // eslint-disable-next-line react/jsx-no-bind
                    onBlur={(event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) =>
                        setNroRequirement(event?.target.value)
                    }
                />
              </div>
              <div className="ms-Grid-col  ms-sm6 mb-2">
                <DatePicker
                    strings={getLocalizedStrings()}
                    label={t('Fecha del requerimiento')}
                    formatDate={DateCustom.formatDateForDatePicker}
                    value={requirementDate.toJSDate()}
                    onSelectDate={(e) => onChangeDate(e, 'fecha')}
                    className=""
                />
              </div>
            </div>
            <div className="ms-Grid-row">
              <div className="ms-Grid-col  ms-sm6 mb-2">
                <TextField
                    label={t('Agente fiscal')}
                    // eslint-disable-next-line react/jsx-no-bind
                    onBlur={(event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) =>
                        setFiscalAgent(event?.target.value)
                    }
                />
              </div>
              <div className="ms-Grid-col  ms-sm6 mb-2">
                <TextField
                    label={t('Detalle')}
                    // eslint-disable-next-line react/jsx-no-bind
                    onBlur={(event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) =>
                        setDetail(event?.target.value)
                    }
                />
              </div>
            </div>
          </>
      );
    }
    if (requirementType == otherMotive) {
      return (
          <TextField
              label={t('Motivo del requerimiento')}
              multiline
              rows={3}
              // eslint-disable-next-line react/jsx-no-bind
              onBlur={(event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) =>
                  setMotive(event?.target.value)
              }
          />
      );
    }
  };

  return (
      <div className="mt-2">
        {/* <Text>Documenta el motive y/o los datos del requirement</Text> */}
        <div className="ms-Grid" dir="ltr">
          <div className="ms-Grid-row">
            <div className="ms-Grid-col  ms-sm8 mb-2">
              <Dropdown
                  placeholder={t('Tipo de requerimiento')}
                  options={options}
                  label={t('Tipo de requerimiento')}
                  onChange={onChangeType}
              />
            </div>
            <div className="ms-Grid-col  ms-sm8 ">{form()}</div>
          </div>
        </div>
      </div>
  );
};

export default DocumentationSelector;
