import React, { useEffect, useState } from 'react';
import { DetailTable } from './DetailTable';
import TimeTaxSupportSystemClient from '../../../../system';
import MessageCourier from '../../../../lib/MessageCourier';
import DialogTemplate from '../../../Common/DialogTemplate';
import { Icon, SpinButton } from '@fluentui/react';
import { TextField } from '@fluentui/react/lib/TextField';
import SingleFileDropzone from '../../../FileDropzone/SingleFileDropzone';
import { styles } from '../../../Calendar/CalendarStyles';
import ProjectInSettlementPeriod from '../../../../system/settlement/ProjectInSettlementPeriod';
import { IProjectForDefenseFile } from '../../../../system/projects/ProjectsSystem';

interface IDetailAssistant {
  tableData?: IProjectForDefenseFile[];
  setButtonDisability: (can: boolean) => void;
  addItemsToProjectInSettlementPeriod: any;
  getIdsFromTable: any;
  system: TimeTaxSupportSystemClient;
  courier: MessageCourier;
  detailItems: any;
  selectedProjects: ProjectInSettlementPeriod[];
  cleanDetailItems: any;
}

export interface IItem {
  amount: number | undefined;
  description: string | undefined | null;
  file: any;
  group: number;
}

interface IFormattedGroups {
  key: string;
  name: string;
  pro_nombre: string;
  periodo: string;
  colaboradores_cant: string;
  startIndex: number;
  count: number;
  idx: number;
  itemQty: number;
  itemsTotalAmount: number;
  itemsTotal: any;
  level: number;
}

const DetailAssistant = (props: IDetailAssistant) => {
  const [idArray, setIdArray] = useState<Array<string>>(['']);
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [amount, setAmount] = useState<number | undefined>(0);
  const [description, setDescription] = useState<string | null | undefined>();
  const [itemsForTable, setItemsForTable] = useState<any>([]);
  const [items, setItems] = useState<IItem[]>([]);
  const [file, setFile] = useState<any>();
  const [groupIdx, setGroupIdx] = useState<number>();
  const [formattedGroups, setFormattedGroups] = useState<IFormattedGroups | any>();

  const prefix = '$';

  useEffect(() => {
    props.cleanDetailItems();
  }, []);

  const getIds = (idArray: Array<string>) => {
    setIdArray(idArray);
  };

  const getTotalAmount = () => {
    let totalAmount: number = 0;
    if (items && items[0] && items[0].amount) {
      items.forEach((item: any) => {
        totalAmount += item.amount;
      });
    }
    return totalAmount;
  };

  const generateItem = () => {
    if (amount && description && (groupIdx || groupIdx === 0)) {
      let item = {
        amount: amount,
        description: description,
        file: file,
        group: groupIdx,
      };

      items.push(item);
      // _items.push({ amount: amount, description: description, file: file });

      props.getIdsFromTable(items);
      setItems(items);
      setTableDataGroup();
      props.addItemsToProjectInSettlementPeriod(item);
    }
  };

  useEffect(() => {
    if (getTotalAmount() > 0) {
      props.setButtonDisability(true);
    } else {
      props.setButtonDisability(false);
    }
  }, [items, getTotalAmount()]);

  useEffect(() => {
    setTableDataGroup();
  }, [props.tableData, props.detailItems, items]);

  const startIndex = (group: number) => {
    if (formattedGroups) {
      let startIndex: number = 0;
      for (let i = 0; group > i; i++) {
        startIndex += formattedGroups[i].itemsTotal.length;
      }
      return startIndex;
    } else return 0;
  };

  //Setea los items seleccionados de antes en la forma que lo quiere fluent para los grupos
  const setTableDataGroup = () => {
    let groups: IFormattedGroups[] = [];
    // let startIndex: number = 0;
    let idx = -1;
    if (props.tableData) {
      props.tableData.forEach((group) => {
        let itemsTotal: any = [];

        idx++;
        if (props.detailItems) {
          props.detailItems.forEach((item) => {
            if (item.group === idx) {
              itemsTotal.push(item);
            }
          });
        }
        let _startIndex = startIndex(idx);
        groups.push({
          key: group.pro_id,
          name: group.pro_codigo,
          pro_nombre: group.pro_nombre,
          periodo: group.periodo,
          colaboradores_cant: group.colaboradores_cant,
          startIndex: _startIndex,
          count: itemsTotal.length,
          idx: idx,
          itemsTotal: itemsTotal,
          level: 0,
          itemQty: 0,
          itemsTotalAmount: 0,
        });
      });
    }
    setFormattedGroups(groups);
  };

  const removeItem = (item: number, group: number) => {
    let _groups = formattedGroups;
    let _groups2 = props.selectedProjects;
    _groups[group].itemsTotal.splice(item, 1);
    setFormattedGroups(_groups);
    _groups2[group].removeItem(_groups2[group].getItems()[item]);
    itemsForTable.splice(item, 1);
  };

  const getNumericPart = (value: string | undefined): number | undefined => {
    if (value) {
      let onlyNumber = value.replace('$', '');
      if (isNaN(parseInt(onlyNumber))) {
        return undefined;
      } else {
        return parseInt(onlyNumber);
      }
    }
  };

  const onValidate = (value: string, event?: React.SyntheticEvent<HTMLElement>): string | void => {
    let numericValue = getNumericPart(value);
    if (numericValue !== undefined) {
      // numericValue = Math.min(numericValue, max);
      numericValue = Math.max(numericValue, 0);
      return prefix + String(numericValue);
    }
  };

  const onIncrement = (value: string, event?: React.SyntheticEvent<HTMLElement>): string | void => {
    const numericValue = getNumericPart(value);
    if (numericValue !== undefined) {
      return prefix + String(numericValue + 1);
    }
  };

  /** Decrement the value (or return nothing to keep the previous value if invalid) */
  const onDecrement = (value: string, event?: React.SyntheticEvent<HTMLElement>): string | void => {
    const numericValue = getNumericPart(value);
    if (numericValue !== undefined) {
      return prefix + String(Math.max(numericValue - 1, 0));
    }
  };

  const openDialog = (group: any) => {
    // setItems([]);
    setDescription(null);
    setFile(null);
    setShowDialog(true);
    setGroupIdx(group);
  };

  const formatItems = (items: IItem[]) => {
    let _items: any[] = [];
    items.forEach((item: IItem) => {
      _items.push({
        amount: item.amount,
        description: item.description,
        file: item.file,
        group: item.group,
        importe: item.amount,
        periodo: item.description,
      });
    });
    setItemsForTable(_items);
  };

  useEffect(() => {
    if (items) {
      formatItems(items);
    }
  }, [items]);

  const onChange = (event: React.SyntheticEvent<HTMLElement>, value?: string): void => {
    setAmount(getNumericPart(value));
  };

  const onDropFile = (file: File) => {
    setFile(file);
  };

  return (
      <div className="mt-2">
        <DialogTemplate
            showDialog={showDialog}
            toggleShowDialog={() => setShowDialog(false)}
            title={'Nuevo ítem'}
            onAccept={() => {
              generateItem();
              setShowDialog(false);
            }}
            onCancel={() => setShowDialog(false)}
            acceptText="Confirmar"
            cancelText="Cancelar"
            minWidth={600}
        >
          <div className="ms-Grid" dir="ltr">
            Ingresa un nuevo item para el proyecto "
            {groupIdx || groupIdx === 0 ? formattedGroups[groupIdx].pro_nombre : ''}"
            <div className="ms-Grid-row">
              <div className="ms-Grid-col ms-sm6 mt-2 mb-2">
                <SpinButton
                    label="Importe"
                    defaultValue={prefix + '0'}
                    min={0}
                    // max={max}
                    onValidate={onValidate}
                    onIncrement={onIncrement}
                    onDecrement={onDecrement}
                    // onBlur={(event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) =>
                    //   // setAmount(getNumericPart(event?.target.value))

                    // }
                    onChange={onChange}
                    // incrementButtonAriaLabel="Increase value by 2"
                    // decrementButtonAriaLabel="Decrease value by 2"
                    // styles={styles}
                />
              </div>
              <div className="ms-Grid-col ms-sm10 mb-2">
                <TextField
                    label="Descripción"
                    multiline
                    rows={3}
                    onBlur={(event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) =>
                        setDescription(event?.target.value)
                    }
                    // eslint-disable-next-line react/jsx-no-bind
                />
              </div>
              <div className="ms-Grid-col ms-sm12 mb-2">
                <SingleFileDropzone onDrop={(files) => onDropFile(files)} openOnClick>
                  <div className={styles.documentsSection}>
                    <div className={styles.sectionText}>
                      <Icon iconName="Attach" className={styles.iconForText} />{' '}
                      {/* {document.getFiles
                            ? document.getFiles().map((file, i) => {
                                return i > 0 ? ', ' + file.name : file.name;
                              })
                            :  */}
                      {/* '( */}
                      {file ? file.name : 'Click aquí o Arrastra y suelta documentos'}
                      {/* Click aquí o Arrastra y suelta documentos */}
                      {/* )' */}
                      {/* } */}
                    </div>
                    <div className={styles.sectionDelete}>
                      <Icon
                          iconName="Trash"
                          className={styles.iconDelete}
                          // onClick={() => onDeleteItem(i)}
                      />
                    </div>
                  </div>
                </SingleFileDropzone>
              </div>
            </div>
          </div>
        </DialogTemplate>
        {/* <Text>Selecciona las liquidaciones o proyectos que sustentarán la defensa</Text> */}
        <div className="ms-Grid" dir="ltr">
          <div className="ms-Grid-row">
            <div className="ms-Grid-col ms-sm12 mb-2">Importe total: ${getTotalAmount()}</div>
            <div className="ms-Grid-col ms-sm12 mb-2">
              {formattedGroups ? (
                  <DetailTable
                      setButtonDisability={props.setButtonDisability}
                      data={props.tableData}
                      groupData={formattedGroups}
                      getIdsFromTable={getIds}
                      openDialog={openDialog}
                      items={itemsForTable}
                      selectedProjects={props.selectedProjects}
                      removeItem={removeItem}
                  />
              ) : null}
            </div>
          </div>
          <div className="ms-Grid-row"></div>
        </div>
      </div>
  );
};

export default DetailAssistant;
