import * as React from 'react';
import { DefaultPalette, ICommandBarItemProps, Icon, PrimaryButton } from '@fluentui/react';
import TimeTaxSupportSystemClient from '../../system';
import { ColumnGroupedDetailsList, GroupedDetailList } from '../Common/GroupedDetailList/GroupedDetailList';
import { DateTime } from 'luxon';
import MessageCourier from '../../lib/MessageCourier';
import ViewWrapperWithCommandBar from '../Layout/ViewWrapperWithCommandBar';
import { defaultCommandBarButtonStyles, titleCommandBarButtonStyles } from '../../styles/button-styles';
import { ReportingFilters } from './ReportingFilters';
import { IReportePreliquidacion } from '../../system/reporting/ReportingSystem';
import ReportByPreliquidationExporter from './ReportByPreliquidationExporter';
import { Company } from '../../system/company/Company';
import { useTranslation } from 'react-i18next';
import { User } from '../../system/User';
import { ConfirmDownloadPreSettlement } from './ConfirmDownloadPreSettlement';

interface IReportingSettlementPreliquidation {
  system: TimeTaxSupportSystemClient;
  courier: MessageCourier;
  changeBlockUI: (state: boolean) => void;
  user: User;
}

export const ReportingSettlementPreliquidation = (props: IReportingSettlementPreliquidation) => {
  const { t, i18n } = useTranslation();
  const [companySelected, setCompanySelected] = React.useState<Company>();
  const [from, setFrom] = React.useState<DateTime | undefined>(undefined);
  const [to, setTo] = React.useState<DateTime | undefined>(undefined);
  const [concept, setConcept] = React.useState<string>('');
  const [items, setItems] = React.useState<IReportePreliquidacion[]>([]);

  const columns: ColumnGroupedDetailsList[] = [
    // {
    //   key: 'empresa',
    //   name: t('Empresa'),
    //   fieldName: 'empresa',
    //   couldGroup: true,
    //   minWidth: 200,
    //   maxWidth: 200,
    // },
    // {
    //   key: 'col_nombre_completo',
    //   name: t('Colaborador'),
    //   fieldName: 'col_nombre_completo',
    //   couldGroup: true,
    //   minWidth: 200,
    //   maxWidth: 200,
    // },
    // {
    //   key: 'sector',
    //   name: t('Sector'),
    //   fieldName: 'sector',
    //   couldGroup: true,
    //   minWidth: 500,
    // },
    // {
    //   key: 'pro_codigo',
    //   name: t('Proyecto'),
    //   fieldName: 'pro_codigo',
    //   couldGroup: true,
    //   minWidth: 350,
    //   onRender: (item: any) => {
    //     return (
    //       <span>
    //         {item.pro_codigo} - {item.pro_nombre}
    //       </span>
    //     );
    //   },
    // },
    // {
    //   key: 'act_nombre',
    //   name: t('Actividad'),
    //   fieldName: 'act_nombre',
    //   couldGroup: true,
    //   minWidth: 350,
    // },
    {
      key: 'concept',
      name: t('Concepto'),
      fieldName: 'concept',
      couldGroup: true,
      minWidth: 350,
    },

    {
      key: 'periodo',
      name: t('Período'),
      fieldName: 'periodo',
      couldGroup: true,
      minWidth: 100,
      maxWidth: 100,
    },
    {
      key: 'horas_pendientes',
      name: t('Horas pendientes'),
      fieldName: 'horas_pendientes',
      className: 'ta-end',

      minWidth: 100,
      maxWidth: 100,
      couldTotal: true,
      onRenderHeader: (item: any) => {
        return (
            <span>
            <Icon iconName="Clock" title={t('Horas')} /> &nbsp; {t('Pendientes')}
          </span>
        );
      },
      formatTotal: (number: number) => {
        return <span>{number.toFixed(2)}</span>;
      },
    },
    {
      key: 'horas_confirmadas',
      name: t('Horas chequeadas'),
      fieldName: 'horas_confirmadas',
      className: 'ta-end',
      couldTotal: true,
      minWidth: 100,
      maxWidth: 100,
      formatTotal: (number: number) => {
        return <span>{number.toFixed(2)}</span>;
      },

      onRenderHeader: (item: any) => {
        return (
            <span>
            <Icon iconName="Clock" title={t('Horas')} /> &nbsp; {t('Confirmadas')}
          </span>
        );
      },
    },
    // {
    //   key: 'rcp_costo_hora',
    //   name: t('Costo por hora'),
    //   fieldName: 'rcp_costo_hora',
    //   couldGroup: true,
    //   minWidth: 100,
    //   maxWidth: 100,
    //   className: 'ta-end',
    //   onRender: (item: any) => {
    //     return (
    //       <span>
    //         {item.moneda_simbolo} {item.rcp_costo_hora}
    //       </span>
    //     );
    //   },
    // },
    // {
    //   key: 'exp_porcentaje',
    //   name: t('Porcentaje'),
    //   fieldName: 'exp_porcentaje',
    //   couldGroup: true,
    //   minWidth: 100,
    //   maxWidth: 100,
    //   className: 'ta-end',
    // },
    {
      key: 'importe_costo_total',
      name: t('Importe'),
      fieldName: 'importe_costo_total',
      className: 'ta-end',

      couldTotal: true,
      minWidth: 100,
      maxWidth: 100,
      // formatTotal: (number: number) => {
      //   return <span>{+number.toFixed(2)}</span>;
      // },

      onRender: (item: any) => {
        return (
            <span>
            {item.moneda_simbolo} {Number(item.importe_costo_total).toFixed(2)}
          </span>
        );
      },
    },
  ];

  const itemsMenu = React.useMemo(
      (): ICommandBarItemProps[] => [
        {
          key: 'title',
          text: t('Reporte de preliquidación'),
          iconProps: {
            iconName: 'ReminderPerson',
            color: DefaultPalette.themeLighterAlt,
            className: 'text-title-btn',
          },
          buttonStyles: titleCommandBarButtonStyles,
          className: 'text-title-btn',
        },
      ],
      []
  );

  function exportReport(items: IReportePreliquidacion[]): void {
    // const fileName = `Reporte ${companySelected?.getName()}`;
    ReportByPreliquidationExporter.exportFor(items, concept);
  }

  const onClickSearch = async () => {
    props.changeBlockUI(true);
    try {
      if (from && to) {
        const _items = await props.system.getReportingSystem().getPreliquidacionReport(from, to, concept);
        setItems(_items);
        props.courier.messageSuccess('Reporte generado con éxito');
      } else {
        props.courier.messageError('Error buscando el reporte');
      }
    } catch (error) {
      props.courier.messageError(error);
    }
    props.changeBlockUI(false);
  };

  const [showConfirmDownload, setShowConfirmDownload] = React.useState<boolean>(false);

  const openConfirmDownloadPresettlement = () => {
    setShowConfirmDownload(true);
  };

  const downloadPresettlement = async () => {
    props.changeBlockUI(true);
    try {
      if (from && to) {
        let uri = await props.system.getSettlementSystem().getDownloadPresettlementExcelURI(from, to);
        window.open(uri, '_blank');
        // window.location.assign(uri);
        props.courier.messageSuccess(t('Se ha descargado la planilla modelo con éxito'));
      }
    } catch (error) {
      props.courier.messageError(error);
    } finally {
      props.changeBlockUI(false);
    }
  };

  const onCloseDialog = (confirm: boolean) => {
    if (confirm) {
      downloadPresettlement();
    }
    setShowConfirmDownload(false);
  };

  return (
      <ViewWrapperWithCommandBar
          withBorderBottom
          title={''}
          items={itemsMenu}
          overflowButtonStyles={defaultCommandBarButtonStyles}
          variant="highligth"
          titleBody={t('Reporte de preliquidación')}
      >
        <div className="padding-1">
          <div className="ms-Grid" dir="ltr">
            <div className="ms-Grid-row">
              <div className="ms-Grid-col ms-sm12">
                <ReportingFilters
                    system={props.system}
                    courier={props.courier}
                    onChangeFromDate={(d) => setFrom(d)}
                    onChangeToDate={(d) => setTo(d)}
                    onChangeConcept={(d) => setConcept(d)}
                    onChangeCompany={(c) => setCompanySelected(c)}
                    onClickSearch={onClickSearch}
                    showCompanyFilter={false}
                    useConcept={true}
                />
                {items && items.length > 0 && (
                    <div className="ms-Grid-row ">
                      <PrimaryButton
                          iconProps={{ iconName: 'ExcelLogo' }}
                          className="float-right ml-2"
                          onClick={() => exportReport(items)}
                          text={t('Exportar')}
                      />
                    </div>
                )}{' '}
                {props.user.hasPowerUserPrivileges() && (
                    <PrimaryButton
                        iconProps={{ iconName: 'ExcelLogo' }}
                        onClick={() => openConfirmDownloadPresettlement()}
                        text={t('Descargar detalle de preliquidación')}
                        disabled={true}
                    />
                )}{' '}
                {items && <GroupedDetailList columns={columns} items={items} hideGroupBy={true} />}
              </div>
            </div>
            <ConfirmDownloadPreSettlement
                onClose={onCloseDialog}
                show={showConfirmDownload}
                periodo={`${from?.toFormat('dd/LL/yyyy')} - ${to?.toFormat('dd/LL/yyyy')}`}
            ></ConfirmDownloadPreSettlement>
          </div>
        </div>
      </ViewWrapperWithCommandBar>
  );
};
