import { useState, useEffect } from 'react';
import MessageCourier from '../../lib/MessageCourier';

/**
 * @TODO buscar un mejor nombre
 */
function useAsyncDataFull<T>(
    dataPromise: () => Promise<T | undefined>,
    dependencies: any[],
    courier?: MessageCourier,
    utilizeBlockUI?: (change: boolean) => void
) {
  const [data, setData] = useState<T>();
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [reloadFlag, setReloadFlag] = useState(false);
  const [error, setError] = useState<unknown>();

  const reload = () => {
    setReloadFlag((p) => !p);
  };

  useEffect(() => {
    let active = true;
    load();
    return () => {
      active = false;
    };

    async function load() {
      if (utilizeBlockUI) utilizeBlockUI(true);
      try {
        setError(undefined);
        setData(undefined); // this is optional
        setLoading(true);
        const res = await dataPromise();
        if (!active) {
          return;
        }
        setData(res);
      } catch (e) {
        setError(e);
      } finally {
        setLoading(false);
        setLoaded(true);
      }
      if (utilizeBlockUI) utilizeBlockUI(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...dependencies, reloadFlag]);

  const hasError = !loading && error;
  if (hasError && courier) {
    courier.messageError(error);
  }

  return { data, loading, loaded, reload, error };
}

export default useAsyncDataFull;
