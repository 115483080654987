import React, { useEffect, useState, useMemo } from 'react';
import { Text } from '@fluentui/react/lib/Text';
import { IStackTokens, Stack } from '@fluentui/react/lib/Stack';
import { Toggle, DatePicker, TextField, DefaultButton, PrimaryButton, Icon } from '@fluentui/react';
import { DateCustom } from '../../../../lib/DateCustom';
import {
  Dropdown,
  DropdownMenuItemType,
  IDropdownStyles,
  IDropdownOption,
} from '@fluentui/react/lib/Dropdown';
import TimeTaxSupportSystemClient from '../../../../system';
import CompanySelector from '../../../Company/CompanySelector';
import { DateTime } from 'luxon';
import MessageCourier from '../../../../lib/MessageCourier';
import { Company } from '../../../../system/company/Company';
import { defenseFileAssistantDropdownValues, routes } from '../../../../common/constants';
import { useNavigate } from 'react-router-dom';
import i18next, { t } from 'i18next';

// interface IProjectConfiguration {
//   setButtonDisability?: any;
//   system: TimeTaxSupportSystemClient;
//   CompanySelectorData?: any;
//   getDataFromEnding?: (company: Company, fromDate: DateTime, toDate: DateTime) => void;
//   courier: MessageCourier;
// }

const Ending = (props: any) => {
  const navigate = useNavigate();
  const [invoiceNumber, setInvoiceNumber] = useState<boolean>(false);
  return (
      <div>
        {/* <Text>Selecciona la empresa usuaria a la que se ha liquidado, el período y el tipo de defensa</Text> */}
        <div className="ms-Grid mt-2" dir="ltr">
          <div className="ms-Grid-row">
            <div className="ms-Grid-col  ms-sm6">
              <TextField
                  label={t('Nro. Factura')}
                  placeholder={t('Nro. Factura')}
                  onChange={(e: any) => {
                    const val = e.target.value;
                    props.getInvoiceNumber(val);
                    setInvoiceNumber(true);
                  }}
              />
              <div className="ms-Grid-col  ms-sm6"></div>
              <div className=" mt-2">
                <PrimaryButton
                    text={t('Generar liquidacion')}
                    onClick={props.generateSettlement}
                    disabled={!invoiceNumber}
                />
              </div>

              {props.settlementResponse ? (
                  <>
                    <Text>
                      <Icon iconName="CheckMark" className="text-icon success-color"></Icon>
                      {t('Se ha generado la liquidación indirecta ')}
                      {props.settlementResponse ? props.settlementResponse.id : null}
                    </Text>

                    <br />
                    <PrimaryButton
                        className="mt-2"
                        onClick={() => navigate(`${routes.settlement}/${props.settlementResponse.id}`)}
                    >
                      {t('Ver detalle')}
                    </PrimaryButton>
                  </>
              ) : null}
            </div>
          </div>
        </div>
      </div>
  );
};

export default Ending;
