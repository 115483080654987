import { Facepile, Icon, IDropdownOption, IFacepilePersona, Persona, PersonaSize } from '@fluentui/react';
import { GRID_MODE } from '../../../common/list-utils';
import { DateCustom } from '../../../lib/DateCustom';
import { parseDateTimeForList } from '../../../lib/Util';
import { DetailsListView, EntityGridListProps } from '../../Common/DetailsList/DetailListView';
import { useTranslation, Trans } from 'react-i18next';

export const ActivityGridList = (props: EntityGridListProps) => {
  const { t, i18n } = useTranslation();
  const formatActivo = (item: any) => {
    if (item.act_activo === '1') {
      return <Icon iconName="CheckMark" className="color-success" />;
    } else {
      return <Icon iconName="Cancel" className="color-error" />;
    }
  };

  const formatDuracion = (item: any) => {
    return <span>{DateCustom.formatHorasToString(item.act_horas)}</span>;
  };

  const formatEquipo = (item: any) => {
    let equipo = item.equipo_actividad.split(' | ');
    var getInitials = function (string: string) {
      var names = string.split(' '),
          initials = names[0].substring(0, 1).toUpperCase();

      if (names.length > 1) {
        initials += names[1].substring(0, 1).toUpperCase();
      }
      return initials;
    };

    let equipoPersonas: IFacepilePersona[] = equipo.map((persona: string) => {
      return {
        personaName: persona,
        imageInitials: getInitials(persona),
      };
    });

    return (
        <Facepile
            personas={equipoPersonas}
            personaSize={10}
            maxDisplayablePersonas={6}
            overflowButtonType={1}
            overflowButtonProps={{ ariaLabel: 'Ver más' }}
            onRenderPersona={(props) => (
                <Persona {...props} size={PersonaSize.size24} onRenderPrimaryText={() => <></>}></Persona>
            )}
        />
    );
  };

  const columns = [
    {
      fieldName: 'act_nombre',
      name: t('Nombre'),
    },
    {
      fieldName: 'act_inicio',
      name: t('Inicio'),
      _onRender: (item: any) => parseDateTimeForList(props.system, item.act_inicio),
      maxWidth: 110,
      minWidth: 110,
    },
    {
      fieldName: 'owner_actividad_nombre',
      name: t('Creador'),
    },
    {
      fieldName: 'pro_codigo',
      name: t('Proyecto Codigo'),
      maxWidth: 90,
      minWidth: 90,
    },
    {
      fieldName: 'pro_nombre',
      name: t('Proyecto nombre'),
    },
    {
      fieldName: 'act_horas',
      name: t('Duracion'),
      _onRender: (item: any) => formatDuracion(item),
      maxWidth: 50,
      minWidth: 50,
    },
    {
      fieldName: 'act_activo',
      name: t('Activo'),
      _onRender: (item: any) => formatActivo(item),
      maxWidth: 20,
      minWidth: 20,
    },
    {
      fieldName: 'equipo_actividad',
      name: t('Equipo'),
      _onRender: (item: any) => formatEquipo(item),
      onRenderPersona: () => <></>,
      minWidth: 180,
      maxWidth: 180,
    },
  ];
  return (
      <>
        <DetailsListView
            keyList={props.keyList}
            onClickCardMode={() => props.onClickCardMode()}
            onClickGridMode={() => props.onClickGridMode()}
            mode={props.mode}
            title={t('Actividades')}
            onItemClick={props.onItemClick}
            shouldUpdate={props.shouldUpdate}
            cols={columns}
            isGridList={true}
            listFilters={[]}
            quickFilters={props.quickFilters}
            baseUrl={props.baseUrl}
            changeBlockUI={props.changeBlockUI}
            courier={props.courier}
        ></DetailsListView>
      </>
  );
};
