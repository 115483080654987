export interface IDefenseFileGenericRequirement {
  ardrg_descripcion: string;
  ardrg_id: string;
}

export type INewDefenseFileGenericRequirement = Pick<IDefenseFileGenericRequirement, 'ardrg_descripcion'>;

class DefenseFileGenericRequirement {
  protected constructor(private description: string) {}

  static forDescription(description: string) {
    return new this(description);
  }

  public toGenerationObject(): INewDefenseFileGenericRequirement {
    return {
      ardrg_descripcion: this.description,
    };
  }

  public getDescription(): string {
    return this.description;
  }
}

export default DefenseFileGenericRequirement;
