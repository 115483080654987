import TimeTaxSupportSystemClient from '../../../system';
import { DateTime } from 'luxon';
import MessageCourier from '../../../lib/MessageCourier';
import { IReporteHorasCumplimientoIndividual } from '../../../system/reporting/ReportingSystem';
import { useTranslation } from 'react-i18next';
import DataGrid, {
  Scrolling,
  Column,
  Summary,
  SortByGroupSummaryInfo,
  Export,
  SearchPanel,
  TotalItem,
} from 'devextreme-react/data-grid';

import { User } from '../../../system/User';
import { EscalableExporterOptions, exportToExcel } from '../../../lib/Util';
import Button from 'devextreme-react/button';

interface IReportingSettlementHoursSector {
  system: TimeTaxSupportSystemClient;
  courier: MessageCourier;
  changeBlockUI: (state: boolean) => void;
  user: User;
  items: IReporteHorasCumplimientoIndividual[];
  from: DateTime;
  to: DateTime;
  resetTableAfterExport: () => void;
}

export const ReportHoursSectorCumplimientoIndividual = (props: IReportingSettlementHoursSector) => {
  const { t } = useTranslation();

  const onExportingCumplimientoIndividual = async (e: any) => {
    let options: EscalableExporterOptions = {
      fileName: `cumplimiento-individual-de-horas-sector-de-${props.from?.toFormat(
          'dd-LL-yyyy'
      )}-a-${props.to?.toFormat('dd-LL-yyyy')}`,
      sheetName: t('Cumplimiento individual de horas por sector'),
    };
    await exportToExcel(e, options);
    props.resetTableAfterExport();
  };

  return (
      <>
        <Button
            className="btn-dinamic-table"
            icon="help"
            hint="Si la cantidad de colaboradores es mayor a la esperada, probablemente se
          debe a colaboradores con más de un cargo. Los números son relativos a la cantidad de cargos distintos."
        />

        <DataGrid
            height={620}
            width="100%"
            keyExpr={'direccion'}
            dataSource={props.items}
            showBorders={true}
            wordWrapEnabled={true}
            onExporting={onExportingCumplimientoIndividual}
        >
          <SearchPanel visible={true} placeholder={t('Buscar...')} />
          <Scrolling mode="virtual" />
          <Column dataField="direccion" width={650} caption="Direccion" />
          <Column dataField="cant_colaboradores" caption="# Colaboradores" width={120} />
          <Column dataField="cant_colaboradores_horas_completas" caption="# Completaron registro de horas" />
          <Column
              dataField="cant_colaboradores_horas_incompletas"
              caption="# No completaron registro de horas"
          />
          <Column dataField="porcentaje_cumplimiento" caption="% de cumplimiento" />
          <Summary>
            <TotalItem column="cant_colaboradores" summaryType="sum" displayFormat={`{0}`} />

            <TotalItem column="cant_colaboradores_horas_completas" summaryType="sum" displayFormat={`{0}`} />
            <TotalItem column="cant_colaboradores_horas_incompletas" summaryType="sum" displayFormat={`{0}`} />
          </Summary>
          <SortByGroupSummaryInfo sortOrder={'desc'} summaryItem="sum" />
          <Export enabled={true} />
        </DataGrid>
      </>
  );
};
