import SystemDocumentType from './SystemDocumentType';

export class ActivityDocumentToSubmit {
  private filesToUpload: File[];

  protected constructor(private type: SystemDocumentType) {
    this.filesToUpload = [];
  }

  static forType(type: SystemDocumentType) {
    return new this(type);
  }

  addFile(file: File) {
    this.filesToUpload.push(file);
  }

  isEmpty(): boolean {
    return this.filesToUpload.length === 0;
  }

  getFiles() {
    return [...this.filesToUpload];
  }

  getTypeName() {
    return this.type.getName();
  }

  getType(): SystemDocumentType {
    return this.type;
  }
}
