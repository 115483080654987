import { DateTime } from 'luxon';
import { DateCustom } from '../../../lib/DateCustom';
import XhrRequestHandler from '../../../lib/XhrRequestHandler';
import { IMailInspectorRulesTag, MailInspectorRulesTag } from './MailInspectorRulesTag';

export class MailInspectorRulesTagSystem {
  private readonly base = '/mail-inspector-reglas-etiquetados';
  constructor(private requestHandler: XhrRequestHandler) {}

  getBaseUrlForDatagridInProject(projectId: string) {
    return this.requestHandler.requestUrl(`${this.base}/datagrid-for-project/${projectId}`);
  }

  async getMailIdentifiedBy(id: string): Promise<MailInspectorRulesTag> {
    const data = await this.requestHandler.get<IMailInspectorRulesTag>(`${this.base}/${id}`);
    return MailInspectorRulesTag.buildFrom(data);
  }

  async hasMMIActive(projectId: string): Promise<boolean> {
    return await this.requestHandler.get<boolean>(`${this.base}/has-mmi-active/${projectId}`);
  }

  async activateMMIFor(projectId: string): Promise<boolean> {
    return await this.requestHandler.get<boolean>(`${this.base}/activate-mmi-for/${projectId}`);
  }

  async getRulesFor(projectId: string): Promise<MailInspectorRulesTag[]> {
    const data = await this.requestHandler.get<IMailInspectorRulesTag[]>(
        `${this.base}/get-rules-for-project/${projectId}`
    );

    return data.map((d) => MailInspectorRulesTag.buildFrom(d));
  }

  async save(mire: MailInspectorRulesTag) {
    const saveData = mire.toJson();
    const savedMIRResult = await this.requestHandler.post<IMailInspectorRulesTag, IMailInspectorRulesTag>(
        this.base,
        saveData
    );
    return MailInspectorRulesTag.buildFrom(savedMIRResult);
  }
}
