import * as React from 'react';
import { DefaultPalette, ICommandBarItemProps } from '@fluentui/react';
import TimeTaxSupportSystemClient from '../../system';
import { DateTime } from 'luxon';
import MessageCourier from '../../lib/MessageCourier';
import ViewWrapperWithCommandBar from '../Layout/ViewWrapperWithCommandBar';
import { defaultCommandBarButtonStyles, titleCommandBarButtonStyles } from '../../styles/button-styles';
import { ReportingFilters } from './ReportingFilters';
import {
  IReporteGralHoras,
  IReporteHorasCumplimientoGeneral,
  IReporteHorasCumplimientoIndividual,
} from '../../system/reporting/ReportingSystem';
import { useTranslation } from 'react-i18next';
import TabPanel, { Item } from 'devextreme-react/tab-panel';

import { User } from '../../system/User';
import { ReportHoursSectorDetail } from './HoursSector/ReportHoursSectorDetail';
import { ReportHoursSectorCumplimientoIndividual } from './HoursSector/ReportHoursSectorCumplimientoIndividual';
import { ReportHoursSectorCumplimientoGeneral } from './HoursSector/ReportHoursSectorCumplimientoGeneral';

interface IReportingSettlementHoursSector {
  system: TimeTaxSupportSystemClient;
  courier: MessageCourier;
  changeBlockUI: (state: boolean) => void;
  user: User;
}

export const ReportingSettlementHoursSectorDE = (props: IReportingSettlementHoursSector) => {
  const { t } = useTranslation();
  const [nodeId, setNodeId] = React.useState<string>('');
  const [reset, setReset] = React.useState<boolean>(true);
  const [from, setFrom] = React.useState<DateTime | undefined>(undefined);
  const [to, setTo] = React.useState<DateTime | undefined>(undefined);
  const [items, setItems] = React.useState<IReporteGralHoras[]>([]);
  const [itemsCumplimientoIndividual, setItemsCumplimientoIndividual] = React.useState<
      IReporteHorasCumplimientoIndividual[]
  >([]);

  const [itemsCumplimientoGeneral, setItemsCumplimientoGeneral] = React.useState<
      IReporteHorasCumplimientoGeneral[]
  >([]);

  const itemsMenu = React.useMemo(
      (): ICommandBarItemProps[] => [
        {
          key: 'title',
          text: t('Reporte de horas por sector'),
          iconProps: {
            iconName: 'ReminderPerson',
            color: DefaultPalette.themeLighterAlt,
            className: 'text-title-btn',
          },
          buttonStyles: titleCommandBarButtonStyles,
          className: 'text-title-btn',
        },
      ],
      []
  );

  const resetTableAfterExport = () => {
    setReset(false);
    setTimeout(() => {
      setReset(true);
    }, 50);
  };

  const mapCumplimientoGral = (_items: IReporteGralHoras[]) => {
    const groupByDireccion = _items.reduce((acc, item) => {
      const direccion = item.direccion;
      if (!acc[direccion]) {
        let pct = parseFloat(((item.horas / item.cant_horas_esperadas) * 100).toFixed(2));

        acc[direccion] = {
          direccion: direccion,
          cant_colaboradores: 1,
          horas_cargadas_totales:
              item.horas > item.cant_horas_esperadas ? item.cant_horas_esperadas : item.horas,
          horas_esperadas_totales: item.cant_horas_esperadas,
          porcentaje_cumplimiento: pct > 100 ? 100 : pct,
        };
      } else {
        acc[direccion].cant_colaboradores++;
        acc[direccion].horas_cargadas_totales +=
            item.horas > item.cant_horas_esperadas ? item.cant_horas_esperadas : item.horas;
        acc[direccion].horas_esperadas_totales += item.cant_horas_esperadas;
        let hrsCargadasTotal = acc[direccion].horas_cargadas_totales;
        let hrsEsperadasTotal = acc[direccion].horas_esperadas_totales;
        let pct = parseFloat(((hrsCargadasTotal / hrsEsperadasTotal) * 100).toFixed(2));
        acc[direccion].porcentaje_cumplimiento = pct > 100 ? 100 : pct;
      }
      return acc;
    }, {});

    let result: IReporteHorasCumplimientoGeneral[] = Object.values(groupByDireccion);
    setItemsCumplimientoGeneral(result);
  };

  const mapCumplimientoIndividual = (_items: IReporteGralHoras[]) => {
    const groupByDireccion = _items.reduce((acc, item) => {
      const direccion = item.direccion;
      if (!acc[direccion]) {
        acc[direccion] = {
          direccion: direccion,
          cant_colaboradores: 1,
          cant_colaboradores_horas_completas: +(item.horas >= item.cant_horas_esperadas),
          cant_colaboradores_horas_incompletas: +(item.horas < item.cant_horas_esperadas),
          porcentaje_cumplimiento: +(item.horas >= item.cant_horas_esperadas) ? 100 : 0,
        };
      } else {
        acc[direccion].cant_colaboradores++;
        acc[direccion].cant_colaboradores_horas_completas += +(item.horas >= item.cant_horas_esperadas);
        acc[direccion].cant_colaboradores_horas_incompletas += +(item.horas < item.cant_horas_esperadas);
        let cantColTotal = acc[direccion].cant_colaboradores;
        let cantHsCompletas = acc[direccion].cant_colaboradores_horas_completas;
        let pct = parseFloat(((cantHsCompletas / cantColTotal) * 100).toFixed(2));
        acc[direccion].porcentaje_cumplimiento = pct > 100 ? 100 : pct;
      }
      return acc;
    }, {});

    let result: IReporteHorasCumplimientoIndividual[] = Object.values(groupByDireccion);
    setItemsCumplimientoIndividual(result);
  };

  const onClickSearch = async () => {
    props.changeBlockUI(true);
    try {
      if (from && to) {
        const _items = await props.system.getReportingSystem().getGralHorasReport(from, to, nodeId);
        setItems(_items);
        mapCumplimientoGral(_items);
        mapCumplimientoIndividual(_items);
        props.courier.messageSuccess(t('Reporte generado con éxito'));
      } else {
        props.courier.messageError(t('Debes seleccionar un sector'));
      }
    } catch (error) {
      props.courier.messageError(error);
    }
    props.changeBlockUI(false);
  };

  return (
      <ViewWrapperWithCommandBar
          withBorderBottom
          title={''}
          items={itemsMenu}
          overflowButtonStyles={defaultCommandBarButtonStyles}
          variant="highligth"
          titleBody={t('Reporte de horas por sector')}
      >
        <div className="padding-1 bg-white">
          <div className="ms-Grid" dir="ltr">
            <div className="ms-Grid-row">
              <div className="ms-Grid-col ms-sm12">
                <ReportingFilters
                    system={props.system}
                    courier={props.courier}
                    onChangeFromDate={(d) => setFrom(d)}
                    onChangeToDate={(d) => setTo(d)}
                    onChangeCompany={(c) => {}}
                    onChangeNode={(c) => setNodeId(c)}
                    onClickSearch={onClickSearch}
                    showSectorFilter={true}
                    user={props.user}
                />
                {reset && items && items.length > 0 && (
                    <div className="ms-Grid-row mt-2">
                      <div className="ms-Grid-col ms-sm12">
                        <TabPanel id="tabPanel" deferRendering={false}>
                          <Item title="Detalle">
                            {from && to && (
                                <ReportHoursSectorDetail
                                    system={props.system}
                                    courier={props.courier}
                                    changeBlockUI={props.changeBlockUI}
                                    user={props.user}
                                    items={items}
                                    from={from}
                                    to={to}
                                    resetTableAfterExport={resetTableAfterExport}
                                />
                            )}
                          </Item>
                          <Item title="Cumplimiento general por sector">
                            {from && to && (
                                <ReportHoursSectorCumplimientoGeneral
                                    system={props.system}
                                    courier={props.courier}
                                    changeBlockUI={props.changeBlockUI}
                                    user={props.user}
                                    items={itemsCumplimientoGeneral}
                                    from={from}
                                    to={to}
                                    resetTableAfterExport={resetTableAfterExport}
                                />
                            )}
                          </Item>
                          <Item title="Cumplimiento individual por sector">
                            {from && to && (
                                <ReportHoursSectorCumplimientoIndividual
                                    system={props.system}
                                    courier={props.courier}
                                    changeBlockUI={props.changeBlockUI}
                                    user={props.user}
                                    items={itemsCumplimientoIndividual}
                                    from={from}
                                    to={to}
                                    resetTableAfterExport={resetTableAfterExport}
                                />
                            )}
                          </Item>
                        </TabPanel>
                      </div>
                    </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </ViewWrapperWithCommandBar>
  );
};
