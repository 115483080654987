import { Icon, Text } from '@fluentui/react';
import React, { useState } from 'react';
import DialogTemplate from '../../../Common/DialogTemplate';
import { useTranslation, Trans } from 'react-i18next';
import { ICollaborator } from '../../../../system/collaborators/CollaboratorSystem';
import { Collaborator } from '../../../../system/collaborators/Collaborator';

export interface CollaboratorsWithNoRemunInPeriodProps {
  show: boolean;
  onClose: (accept: boolean) => void;
  //   periodo: string;
  collaboratorList: Collaborator[];
}

export const CollaboratorsWithNoRemunInPeriod = (props: CollaboratorsWithNoRemunInPeriodProps) => {
  const { t, i18n } = useTranslation();

  const [showAllCollaborators, setShowAllCollaborators] = useState(false);

  const cancel = () => {
    props.onClose(false);
  };

  const accept = () => {
    props.onClose(false);
  };

  const collaboratorNames = props.collaboratorList
      .slice(0, showAllCollaborators ? props.collaboratorList.length : 10) // Limita la cantidad de elementos
      .map((collaborator: Collaborator) => <li key={collaborator.getId()}>{collaborator.fullName()}</li>);

  return (
      <DialogTemplate
          showDialog={props.show}
          toggleShowDialog={() => props.onClose(false)}
          title={
              t('Estos colaboradores no tienen remuneracion definida para el periodo seleccionado') +
              ' (' +
              props.collaboratorList.length +
              ')'
          }
          onAccept={accept}
          onCancel={cancel}
          acceptText={t('Confirm')}
          cancelText={t('Cancel')}
          minWidth={600}
      >
        <div>
          <Trans>
            <Text block variant="medium">
              <Icon iconName="Warning" className="text-icon" />
              <ul>{collaboratorNames}</ul>
            </Text>
            {props.collaboratorList.length > 10 &&
                !showAllCollaborators && ( // Mostrar botón "Ver más"
                    <Text
                        block
                        variant="medium"
                        className="ms-Button--link pointer blue"
                        onClick={() => setShowAllCollaborators(true)}
                    >
                      {t('Ver más')}
                    </Text>
                )}
            {showAllCollaborators && (
                <Text
                    block
                    variant="medium"
                    className="ms-Button--link pointer blue"
                    onClick={() => setShowAllCollaborators(false)}
                >
                  {t('Ver menos')}
                </Text>
            )}
          </Trans>
        </div>
      </DialogTemplate>
  );
};
