import React, { useEffect } from 'react';
import { IStackStyles, IStackTokens, Stack, Text, Link, Icon } from '@fluentui/react';
import { useNavigate } from 'react-router-dom';

// import logo from '../logo.svg';
import { boldStyle } from './Common/TextStyles';
import { PillFluent } from './Common/PillFluent';
import { APP_VERSION, routes } from '../common/constants';
import { useTranslation, Trans } from 'react-i18next';

import tutorialsJSON from '../tutorialVideos/tutorials.json';
import newsJSON from '../news/news.json';

const stackTokens: IStackTokens = { childrenGap: 15 };

const mainStackStyles: Partial<IStackStyles> = {
  root: {
    width: '100%',
    height: '100%',
    margin: '0 auto',
    textAlign: 'center',
    color: '#605e5c',
  },
};

interface ITutorialProps {
  video?: string | null;
}

const Tutorial = (props: ITutorialProps) => {
  const { t, i18n } = useTranslation();

  const navigate = useNavigate();

  const getTutorialTitle = () => {
    if (props.video) {
      let tutorial;
      if (props.video.includes('tutorial')) {
        tutorial = tutorialsJSON.tutorials.find((t) => t.id === props.video);
      } else if (props.video.includes('news')) {
        let json = newsJSON.news.find((j) => j.update === APP_VERSION);
        if (json) {
          tutorial = json.updateNews.find((n) => n.id === props.video);
        }
      }
      return tutorial ? tutorial.title : '';
    }
  };

  const getTutorialUrl = () => {
    if (props.video) {
      let tutorial;
      if (props.video.includes('tutorial')) {
        tutorial = tutorialsJSON.tutorials.find((t) => t.id === props.video);
      } else if (props.video.includes('news')) {
        let json = newsJSON.news.find((j) => j.update === APP_VERSION);
        if (json) {
          tutorial = json.updateNews.find((n) => n.id === props.video);
        }
      }

      return tutorial ? tutorial.url : '';
    }
  };

  const getTutorialVideos = () => {
    // let tutorialJSON = tutorialsJSON;
    // tutorialJSON = JSON.parse(JSON.stringify(tutorialJSON));

    return tutorialsJSON.tutorials.map((tutorial) => {
      return (
          <div className="ms-Grid-col ms-sm6 tutorial-video-box" id={tutorial.id}>
            <Text variant="large" className={'tutorial-text-header'}>
              {/* {t('Para tu comodidad mejoramos el proceso de ')} */}
              <span style={{ fontWeight: 'bold' }}>{t(tutorial.title)}</span>
            </Text>
            <br></br>
            <div>
              <Link
                  style={{ textAlign: 'center' }}
                  onClick={() => navigate(`${routes.help}?video=${tutorial.id}`)}
              >
                <img src={tutorial.image} alt="subida-de-archivos" className="tutorial-video" />
              </Link>
            </div>
          </div>
      );
    });
  };

  return (
      <div className="ms-Grid" dir="ltr">
        {/* <div className="ms-Grid-row mt-2">
        <div className="ms-Grid-col ms-md4"></div>
        <div className="ms-Grid-col ms-md4">
          <PillFluent
            title={'Usuarios'}
            iconTitle={'Contact'}
            series={[
              { label: 'Totales', value: 425, icon: 'Group' },
              { label: 'Activos', value: 268, icon: 'ReminderGroup' },
              { label: 'Bloqueados', value: 3, icon: 'BlockContact' },
            ]}
          ></PillFluent>
        </div>
      </div> */}

        <Stack
            horizontalAlign="center"
            verticalAlign="center"
            verticalFill
            styles={mainStackStyles}
            tokens={stackTokens}
        >
          {props.video ? (
              <>
                <Text className="mb-2 mt-5" variant="xxLarge" styles={boldStyle}>
                  {getTutorialTitle()}
                </Text>
                {getTutorialUrl() && (
                    <video autoPlay controls className="mt-5 only-tutorial-video">
                      <source src={`${getTutorialUrl()}`} type="video/mp4"></source>
                    </video>
                )}
              </>
          ) : (
              <>
                <Text variant="xLarge" styles={boldStyle} style={{ fontSize: '24px' }} className="mt-5">
                  <Icon iconName="AlertSolid" className={'text-icon'} />
                  {t('Tutorial de uso')}
                </Text>
                <Text variant="large" className={'text-principal'}>
                  {t('Para tu comodidad creamos tutoriales para ')}
                  <span style={{ fontWeight: 'bold' }}>{t('conocer como funciona la aplicación')}</span>
                </Text>
                <br></br>
                <a
                    className="link-styles"
                    style={{ marginTop: '5px', fontSize: '20px', marginBottom: '5px' }}
                    href="https://drive.google.com/file/d/1Qb0y2rBy_A3xf4hPj5xZSo5qsyUxaBaO/view"
                    target="_blank"
                    rel="noreferrer"
                >
                  {/* {t('Fijate como hacerlo')} */}
                </a>
                <div className="ms-Grid-col ms-sm7">
                  <div className="ms-Grid-row">{getTutorialVideos()}</div>
                </div>
              </>
          )}
        </Stack>
      </div>
  );
};

export default Tutorial;
