import * as React from 'react';
import { IChartProps, ILineChartPoints, AreaChart } from '@uifabric/charting';
import { DefaultPalette } from '@fluentui/react/lib/Styling';
import { IQuantityByDate } from '../../../system/projects/ProjectsSystem';
import { Icon, Text } from '@fluentui/react';
import { DateTime } from 'luxon';

interface IProjectPanelAreaChart {
  data: IQuantityByDate[];
  title: string;
  color?: string;
  iconName?: string;
  calloutText?: string;
  showTitle?: boolean;
}

const ProjectPanelAreaChart = (props: IProjectPanelAreaChart) => {
  const generateData = React.useCallback(() => {
    return props.data.map((quantity) => {
      let ret = {
        x: DateTime.fromFormat(quantity.fecha, 'yyyy-LL-dd').toJSDate(),
        y: quantity.qty,
      };
      return ret;
    });
  }, [props.data]);

  const MaxValue: number = React.useMemo(
      () => props.data.reduce((p, d) => (d.qty > p ? d.qty : p), 0),
      [props]
  );

  const data = React.useMemo((): IChartProps => {
    const points: ILineChartPoints[] = [
      {
        data: generateData(),
        legend: `${props.calloutText ? props.calloutText : props.title.toLocaleLowerCase()}`,
        color: props.color || DefaultPalette.blue,
      },
    ];
    return {
      chartTitle: props.title,
      lineChartData: points,
    };
  }, [props, generateData]);

  const rootStyle = {
    width: `100%`,
    height: `${150}px`,
    // margin: 'auto',
  };

  const total = React.useMemo(() => props.data.reduce((previous, d) => previous + d.qty, 0), [props]);
  const totalMessage = React.useMemo(() => `${total} ${props.title.toLocaleLowerCase()}`, [props, total]);

  return (
      <div style={rootStyle}>
        {props.showTitle ? (
            <Text className="ml-2" variant="medium">
              <Icon iconName={props.iconName ? props.iconName : 'Horas'} className="text-icon"></Icon>{' '}
              {props.title}
            </Text>
        ) : (
            ''
        )}

        <AreaChart
            data={data}
            strokeWidth={2}
            yMaxValue={MaxValue ? MaxValue : 0}
            hideLegend={true}
            showXAxisLablesTooltip={false}
            hideTooltip={false}
            svgProps={{ 'aria-hidden': 'true' }}
            margins={{ top: 20, bottom: 25, left: 30, right: 10 }}
            tickFormat={'%d/%m'}
            // tickValues={[new Date(props.data[0].fecha), new Date(props.data[props.data.length - 1].fecha)]}
            // wrapXAxisLables={true}
        />
        <div className="ms-Grid" dir="ltr">
          <div className="ms-Grid-row">
            <div className="ms-Grid-col ms-sm12">
              {/* <Text className="fs-for-chart ml-2" nowrap block title={totalMessage}>
              {totalMessage}
            </Text> */}
            </div>
          </div>
        </div>
      </div>
  );
};

export default ProjectPanelAreaChart;
