import React from 'react';
import { Text, Icon } from '@fluentui/react';

// import logo from '../logo.svg';
import { useTranslation } from 'react-i18next';
import DialogTemplate from './DialogTemplate';
import tutorialsJSON from '../../tutorialVideos/tutorials.json';
import { APP_VERSION, routes } from '../../common/constants';

interface IHelperModal {
  open: boolean;
  onClose: () => void;
  tutorialsId: string[];
}

const HelperModal = (props: IHelperModal) => {
  const { t, i18n } = useTranslation();

  const helps = React.useMemo(() => {
    let ret: any = [];
    console.log(props.tutorialsId)
    props.tutorialsId.forEach((tId) => {
      const toAdd = tutorialsJSON.tutorials.find((tj) => tj.id === tId);
      console.log(toAdd)
      if (toAdd) ret.push(toAdd);
    });
    return ret;
  }, []);

  return (
      <DialogTemplate
          showDialog={props.open}
          toggleShowDialog={() => props.onClose()}
          title={t('Ayuda')}
          onAccept={() => props.onClose()}
          hideCancelButton={true}
          onCancel={() => {}}
          acceptText={t('Entendido')}
          cancelText={t('')}
          minWidth={600}
      >
        <div className="align-center">
          {helps.map((help, index) => {
            return (
                <div className="mt-2" key={index}>
                  <Text variant="large" className="fw-500 d-block text-center">
                    <Icon iconName="news" className="text-icon"></Icon>
                    {help.title}
                  </Text>
                  <div className="text-center">
                    <a
                        style={{ textAlign: 'center' }}
                        href={`${routes.help}?video=${help.id}`}
                        target="_blank"
                        rel="noreferrer"
                    >
                      <img src={help.image} className="tutorial-video" />
                    </a>
                  </div>

                  {/* {help.url && (
      <div className="text-center">
        <video muted autoPlay controls loop className="mt-2 max-width-50 text-center">
          <source src={`/${help.url}`} type="video/mp4"></source>
        </video>
      </div>
    )} */}

                  <hr />
                </div>
            );
          })}
        </div>
      </DialogTemplate>
  );
};

export default HelperModal;
