import { DatePicker, IDropdownOption, PrimaryButton, TextField } from '@fluentui/react';
import React, { Dispatch, SetStateAction } from 'react';
import useForceUpdate from '../../common/custom-hooks/useForceUpdate';
import CustomInputIfc from '../../lib/FormValidator/Form/CustomInputIfc';
import InputField from '../../lib/FormValidator/Form/InputField';
import ToggleInput from '../../lib/FormValidator/Form/ToggleInput';
import MessageCourier from '../../lib/MessageCourier';
// import { CostPerHour } from "../../system/license_type/CostPerHour";
import TimeTaxSupportSystemClient from '../../system/TimeTaxSupportSystemClient';
import CostPerHourForm from './CostPerHourForm';
import { DatePickerInput } from '../../lib/FormValidator/Form/DatePickerInput';
import useAsyncDataCourier from '../../common/custom-hooks/useAsyncDataCourier';
import DataDropdown from '../../lib/FormValidator/Form/DataDropdown';
import { formatStartingSelection } from '../../common/list-utils';
import { DateCustom } from '../../lib/DateCustom';

interface ICostPerHourDetailViewProps {
  system: TimeTaxSupportSystemClient;
  form: CostPerHourForm | undefined;
  courier: MessageCourier;
  onBlurCustomInput: (customInput: CustomInputIfc) => void;
}

const CostPerHourDetailView = ({ system, form, courier, onBlurCustomInput }: ICostPerHourDetailViewProps) => {
  const newForm = form?.getIdInput().getValue() === '';

  const collaborators = useAsyncDataCourier(
      () => system.getCollaboratorSystem().getCollaborators(),
      [system],
      courier
  );

  const collaboratorOptions: IDropdownOption<any>[] = React.useMemo(() => {
    return collaborators
        ? collaborators
            .sort((a, b) => a.getName().localeCompare(b.getName())) // Ordenar colaboradores alfabéticamente por nombre
            .map((col) => {
              return {
                key: col.getId(),
                text: col.getName() + ' ' + col.getLastName(),
                data: col.getId(),
              };
            })
        : [];
  }, [collaborators]);

  return (
      <>
        {form && (
            <div className="">
              <div className="ms-Grid mt-2" dir="ltr">
                <div className="ms-Grid-row">
                  <div className="ms-Grid-col ms-sm6 form">
                    <div className="ms-Grid-col ms-sm6 form">
                      {!newForm && (
                          <div className="formItem">
                            <TextField label="Id" value={form.getIdInput().getValue()} readOnly={true} />
                          </div>
                      )}
                      <div className="formItem">
                        <InputField
                            input={form.getYearInput()}
                            label="Año"
                            onBlur={onBlurCustomInput}
                            readOnly={!newForm}
                        />
                      </div>
                      <div className="formItem">
                        <InputField
                            input={form.getMonthInput()}
                            label="Mes"
                            onBlur={onBlurCustomInput}
                            readOnly={!newForm}
                            // formatDate={DateCustom.formatDateForDatePicker_MMYYYY}
                        />
                      </div>
                      <div className="formItem">
                        <InputField
                            label="Costo por hora"
                            input={form.getCostPHInput()}
                            onBlur={onBlurCustomInput}
                        />
                      </div>
                    </div>
                    <div className="ms-Grid-col ms-sm6 form">
                      <div className="formItem">
                        {/* <InputField
              label="Colaborador Id"
              input={form.getColidInput()}
              onBlur={onBlurCustomInput}
            /> */}

                        {collaboratorOptions && collaboratorOptions.length > 0 ? (
                            <DataDropdown
                                input={form.getColidInput()}
                                label="Colaborador"
                                onBlur={onBlurCustomInput}
                                options={collaboratorOptions}
                                startingSelection={formatStartingSelection(
                                    form.getColidInput().getValue(),
                                    collaboratorOptions
                                )}
                            />
                        ) : null}
                      </div>
                      <div className="formItem">
                        <InputField
                            label="Coeficiente valor agregado"
                            input={form.getCoefInput()}
                            onBlur={onBlurCustomInput}
                        />
                      </div>

                      <div className="formItem">
                        <InputField
                            label="Nombre valor agregado"
                            input={form.getAVNameInput()}
                            onBlur={onBlurCustomInput}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        )}
      </>
  );
};

export default CostPerHourDetailView;
