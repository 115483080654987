import { useEffect, useState } from 'react';
import { DatePicker, Label, PrimaryButton } from '@fluentui/react';
import { IDropdownOption } from '@fluentui/react/lib/Dropdown';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import TimeTaxSupportSystemClient from '../../../../system';
import MessageCourier from '../../../../lib/MessageCourier';
import { DateCustom } from '../../../../lib/DateCustom';
import { defenseFileAssistantDropdownValues } from '../../../../common/constants';
import { getLocalizedStrings } from '../../../../lib/LocalizedStringsForDatePicker';
import { CompanyMultiSelector } from '../../../Company/CompanyMultiSelector';

interface IDateSelector {
  setButtonDisability: (can: boolean) => void;
  system: TimeTaxSupportSystemClient;
  // CompanySelectorData: any;
  getDataFromDateSelector: (from: DateTime, to: DateTime) => void;
  courier: MessageCourier;
  generateDefense: () => void;
  uploadedDocuments: any;
  hasGenerated: boolean;
}

const DateSelector = (props: IDateSelector) => {
  const { t, i18n } = useTranslation();
  const [fromDate, setFromDate] = useState<DateTime>(DateTime.fromJSDate(DateCustom.getFechaOneMonthAgo()));
  const [toDate, setToDate] = useState<DateTime>(DateTime.fromJSDate(new Date()));

  useEffect(() => {
    let from = fromDate.toJSDate();
    let _date = new Date(from.getFullYear(), from.getMonth(), 1);
    let _from = DateTime.fromJSDate(_date);
    setFromDate(_from);
    let to = toDate.toJSDate();
    let date = new Date(to.getFullYear(), to.getMonth(), 1);
    let _to = DateTime.fromJSDate(date);
    setToDate(_to);
  }, []);

  useEffect(() => {
    props.getDataFromDateSelector(fromDate, toDate);
  }, [fromDate, toDate]);

  useEffect(() => {
    if (props.uploadedDocuments && props.uploadedDocuments.length > 0) {
      props.setButtonDisability(true);
    }
  }, [props.uploadedDocuments]);

  const options: IDropdownOption[] = [
    { key: defenseFileAssistantDropdownValues.forProjects, text: t('Por proyectos') },
    {
      key: defenseFileAssistantDropdownValues.forIndirectsSettlements,
      text: t('Por liquidaciones indirectas'),
      disabled: true,
    },
  ];

  const onChangeDate = (date: Date | null | undefined, name: string) => {
    if (date) {
      if (name === 'from') {
        setFromDate(DateTime.fromJSDate(date));
      }
      if (name === 'to') {
        setToDate(DateTime.fromJSDate(date));
      }
    }
  };

  return (
      <div>
        {/* <Text>Selecciona la empresa usuaria a la que se ha liquidado, el período y el tipo de defensa</Text> */}
        <div className="ms-Grid mt-2" dir="ltr">
          <div className="ms-Grid-row">
            <div className="ms-Grid-col  ms-sm6">
              {/* <CompanySelector
              onChangeSelected={(c) => setCompany(c)}
              system={props.system}
              courier={props.courier}
              onlyBillableCompanies
              default={company}
            /> */}
            </div>
          </div>
          <div className="ms-Grid-row ">
            <div className="ms-Grid-col ms-sm3 ">
              <DatePicker
                  strings={getLocalizedStrings()}
                  label={t('Desde')}
                  formatDate={DateCustom.formatDateForDatePicker_MMYYYY}
                  value={fromDate.toJSDate()}
                  onSelectDate={(e) => onChangeDate(e, 'from')}
                  calendarProps={{ isDayPickerVisible: false }}
                  className=""
              />
            </div>
            <div className="ms-Grid-col ms-sm3 ">
              <DatePicker
                  strings={getLocalizedStrings()}
                  label={t('Hasta')}
                  formatDate={DateCustom.formatDateForDatePicker_MMYYYY}
                  calendarProps={{ isDayPickerVisible: false }}
                  value={toDate.toJSDate()}
                  onSelectDate={(e) => onChangeDate(e, 'to')}
                  className=""
                  minDate={fromDate.toJSDate()}
              />
            </div>
          </div>
          <div className="ms-Grid-row ">
            <div className="ms-Grid-col ms-sm6 ">
              <CompanyMultiSelector
                  system={props.system}
                  courier={props.courier}
                  onChangeSelected={(e) => console.log(e)}
                  disabled
              />
            </div>
            <div className="ms-Grid-col ms-sm3 ">
              <Label className="invisible">{t('Generar')}</Label>
              <PrimaryButton
                  text={t('Generar')}
                  // onClick={() => sendData()}
                  onClick={() => {
                    props.generateDefense();
                  }}
                  disabled={toDate >= fromDate ? false : true}
              ></PrimaryButton>
            </div>
          </div>
          <div className="ms-Grid-row">
{/*            <div className="ms-Grid-col ms-sm12 ">
              {(props.uploadedDocuments && props.uploadedDocuments.length > 0) || !props.hasGenerated
                  ? null
                  : t('No hay documentos en el período seleccionado')}
            </div> /*/}
          </div>
          {/* <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-sm6 ">
            <Dropdown
              placeholder="Tipo de defensa"
              label={t('Tipo')}
              options={options}
              onChange={onChangeType}
              defaultSelectedKey={type}
            />
          </div>
        </div> */}
        </div>
      </div>
  );
};

export default DateSelector;
