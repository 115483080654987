import {
  CheckboxVisibility,
  DefaultButton,
  DefaultPalette,
  DetailsList,
  DetailsListLayoutMode,
  IColumn,
  Icon,
  PrimaryButton,
  Text,
} from '@fluentui/react';
import { ICommandBarItemProps } from '@fluentui/react/lib/CommandBar';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { routes } from '../../../common/constants';
import MessageCourier from '../../../lib/MessageCourier';
import { defaultCommandBarButtonStyles, titleCommandBarButtonStyles } from '../../../styles/button-styles';
import TimeTaxSupportSystemClient from '../../../system';
import SystemUploadedDocument from '../../../system/documents/SystemUploadedDocument';
import { Settlement } from '../../../system/settlement/Settlement';
import { SettlementProjectInPeriod } from '../../../system/settlement/SettlementProjectInPeriod';
import { SettlementProjectInPeriodItem } from '../../../system/settlement/SettlementProjectInPeriodItem';
import { SettlementRelatedDocument } from '../../../system/settlement/SettlementRelatedDocument';
import ViewWrapperWithCommandBar from '../../Layout/ViewWrapperWithCommandBar';
import { useTranslation } from 'react-i18next';

interface ISettlementDetail {
  system: TimeTaxSupportSystemClient;
  courier: MessageCourier;
}

interface ISummaryResponse {
  cant_proyectos: number;
  importe_total: number;
}

interface IFormattedGroups {
  key: string;
  name: string;
  startIndex: number;
  count: number;
  level?: number;
  isCollapsed?: boolean;
}

const SettlementDetail = (props: ISettlementDetail) => {
  const { t } = useTranslation();
  const [settlementResponse, setSettlementResponse] = useState<Settlement>();
  const [summaryResponse, setSummaryResponse] = useState<ISummaryResponse>();
  const [settlementProject, setSettlementProject] = useState<SettlementProjectInPeriod[]>();
  const [items, setItems] = useState<SettlementProjectInPeriodItem[]>([]);
  const [relatedDocuments, setRelatedDocuments] = useState<SettlementRelatedDocument[]>();
  const [showDocuments, setShowDocuments] = useState<boolean>(false);
  const [availableDocuments, setAvailableDocuments] = useState<SystemUploadedDocument[]>();
  let { id } = useParams();
  const navigate = useNavigate();

  const formatGroups = () => {
    if (settlementProject) {
      let groups: IFormattedGroups[] = [];
      let startIdx: number = 0;
      settlementProject.forEach((project: SettlementProjectInPeriod) => {
        let name =
            '(' +
            project.getProject().getCode() +
            ') - ' +
            project.getProjectName() +
            t(' Período: ') +
            project.getPeriod();
        groups.push({
          key: project.getId(),
          name: name,
          startIndex: startIdx,
          count: project.getItems().length,
          level: 0,
          isCollapsed: true,
        });
        startIdx += project.getItems().length;
      });
      return groups;
    }
  };

  const getSettlementResponse = async () => {
    if (id) {
      const settlement = await props.system.getSettlementSystem().getById(id);
      setSettlementResponse(settlement);
    }
  };

  const getSummaryResponse = async () => {
    if (id) {
      const settlement = await props.system.getSettlementSystem().getSummaryById(id);
      setSummaryResponse(settlement);
    }
  };

  const getItems = async (settlement: Settlement) => {
    const settlementProject = await props.system.getSettlementSystem().getItemsOf(settlement);
    setSettlementProject(settlementProject);
  };

  const getRelatedDocuments = async (settlement: Settlement) => {
    const documents = await props.system.getSettlementSystem().relatedDocumentsOf(settlement);
    setRelatedDocuments(documents);
  };

  const getSettlementPDF = async (settlement: Settlement) => {
    await props.system.getSettlementSystem().downloadSettlementDocument(settlement);
  };

  const getAvailableDocuments = async (settlement: Settlement) => {
    const documents = await props.system.getSettlementSystem().availableDocuments(settlement);
    setAvailableDocuments(documents);
  };

  const deleteRelatedDocument = async (document: SettlementRelatedDocument) => {
    await props.system.getSettlementSystem().deleteRelatedDocument(document);
    if (settlementResponse) {
      getRelatedDocuments(settlementResponse);
      getAvailableDocuments(settlementResponse);
    }
  };

  const addRelatedDocument = async (settlement: Settlement, document: SystemUploadedDocument) => {
    if (settlementResponse) await props.system.getSettlementSystem().addRelatedDocument(settlement, document);
    if (settlementResponse) {
      getRelatedDocuments(settlementResponse);
      getAvailableDocuments(settlementResponse);
    }
  };

  useEffect(() => {
    if (id) {
      getSettlementResponse();
      getSummaryResponse();
    }
  }, [id]);

  useEffect(() => {
    if (settlementResponse) {
      getItems(settlementResponse);
      getAvailableDocuments(settlementResponse);
    }
  }, [settlementResponse]);

  const formatDate = () => {
    if (settlementResponse) {
      let date = settlementResponse.getCreationDate();
      return date.day + '/' + date.month + '/' + date.year + ' ' + date.hour + ':' + date.minute;
    }
  };

  const formatItemList = () => {
    if (settlementResponse && settlementProject) {
      let _items: Array<any> = [];
      settlementProject.forEach((project) => {
        project.getItems().forEach((item) => {
          _items.push(item);
        });
      });
      setItems(_items);
    }
  };

  useEffect(() => {
    formatItemList();
  }, [settlementProject]);

  const _itemColumns = [
    {
      key: 'order',
      name: t('Orden'),
      fieldName: 'order',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
    },

    {
      key: 'description',
      name: t('Descripción'),
      fieldName: 'description',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
    },
    {
      key: 'amount',
      name: t('Importe'),
      fieldName: 'amount',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
    },
    {
      key: 'numberOfDocuments',
      name: t('Documentos'),
      fieldName: 'numberOfDocuments',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
    },
  ];

  const _documentColumns2 = React.useMemo(
      (): any => [
        {
          key: 'name',
          name: t('Documentos Seleccionables'),
          fieldName: 'name',
          minWidth: 100,
          maxWidth: 200,
          isResizable: true,
        },
      ],
      [availableDocuments]
  );

  const _documentColumns = React.useMemo(
      (): any => [
        {
          key: 'name',
          name: t('Documentos Seleccionados'),
          fieldName: 'name',
          minWidth: 100,
          maxWidth: 200,
          isResizable: true,
        },
      ],
      [relatedDocuments]
  );

  const itemsMenuCalendar = React.useMemo(
      (): ICommandBarItemProps[] => [
        {
          key: 'title',
          text: t('Liquidaciones indirectas'),
          iconProps: {
            iconName: 'ReminderPerson',
            color: DefaultPalette.themeLighterAlt,
            className: 'text-title-btn',
          },
          buttonStyles: titleCommandBarButtonStyles,
          className: 'text-title-btn',
        },
        {
          key: 'back',
          text: t('Volver'),
          iconProps: {
            iconName: 'Back',
            color: DefaultPalette.themeLighterAlt,
            className: 'text-title-btn',
          },
          buttonStyles: titleCommandBarButtonStyles,
          className: 'text-title-btn',
          onClick: () => {
            navigate(`${routes.settlement_list}`);
          },
        },
        {
          key: 'modificar',
          text: t('Modificar'),
          iconProps: { iconName: 'Edit', color: DefaultPalette.themeLighterAlt },
          buttonStyles: titleCommandBarButtonStyles,
        },
      ],
      []
  );

  const _renderDocumentColumn: any = (
      document: SettlementRelatedDocument,
      index: number,
      column: IColumn
  ) => {
    if (settlementResponse) {
      return (
          <div className="ms-Grid-row">
            <div className="ms-Grid-col ms-sm6">{document.name()}</div>
            <div className="ms-Grid-col ms-sm6" style={{ textAlign: 'right' }}>
              <PrimaryButton
                  style={{ marginRight: '5px' }}
                  iconProps={{ iconName: 'Remove' }}
                  onClick={() => {
                    deleteRelatedDocument(document);
                    // getRelatedDocuments(settlementResponse);
                    getAvailableDocuments(settlementResponse);
                    // getRelatedDocuments(settlementResponse);
                  }}
              ></PrimaryButton>
              <DefaultButton
                  text={t('Ver')}
                  iconProps={{ iconName: 'DocumentSearch' }}
                  onClick={() => navigate(routes.documents + '/' + document.getId())}
              ></DefaultButton>
            </div>
          </div>
      );
    }
  };

  const _renderDocumentColumn2: any = (document: SystemUploadedDocument, index: number, column: IColumn) => {
    if (settlementResponse) {
      return (
          <div className="ms-Grid-row">
            <div className="ms-Grid-col ms-sm6">{document.getName()}</div>
            <div className="ms-Grid-col ms-sm6" style={{ textAlign: 'right' }}>
              <PrimaryButton
                  iconProps={{ iconName: 'Add' }}
                  style={{ marginRight: '5px' }}
                  onClick={() => {
                    addRelatedDocument(settlementResponse, document);
                    // getRelatedDocuments(settlementResponse);
                    getAvailableDocuments(settlementResponse);
                  }}
              ></PrimaryButton>
              <DefaultButton
                  text={t('Ver')}
                  iconProps={{ iconName: 'DocumentSearch' }}
                  onClick={() => navigate(routes.documents + '/' + document.getId())}
              ></DefaultButton>
            </div>
          </div>
      );
    }
  };

  return (
      <ViewWrapperWithCommandBar
          withBorderBottom
          title={''}
          items={itemsMenuCalendar}
          overflowButtonStyles={defaultCommandBarButtonStyles}
          variant="highligth"
          titleBody={t('Liquidaciones')}
      >
        {settlementResponse && summaryResponse && (
            <div className="padding-1">
              <div className="ms-Grid bg-white  padding-1 " dir="ltr">
                <div className="ms-Grid-row">
                  <div className="ms-Grid-col ms-lg6 ms-sm6">
                    <Text variant="large" className="fw-500">
                      {t('Detalle de liquidacion indirecta: ') + id}
                    </Text>
                    <div className="ms-Grid-row mt-2">
                      <div className="ms-Grid-col ms-lg12 ms-sm12">
                        {' '}
                        <Text variant="medium">
                          <Icon iconName="CityNext2" className="text-icon"></Icon>
                          {t('Empresa: ')}
                          <span className="fw-500">{settlementResponse.getCompany()?.getName()}</span>
                        </Text>
                      </div>
                      <div className="ms-Grid-col ms-lg12 ms-sm12 mt-1">
                        {' '}
                        <Text variant="medium">
                          <Icon iconName="PublicCalendar" className="text-icon"></Icon>
                          {t('Período: ')}
                          <span className="fw-500">{settlementResponse.period()}</span>
                        </Text>
                      </div>
                      <div className="ms-Grid-col ms-lg12 ms-sm12 mt-1">
                        {' '}
                        <Text variant="medium">
                          <Icon iconName={'TeamWork'} className="text-icon"></Icon>
                          {t('Cantidad de proyectos: ')}
                          <span className="fw-500">{summaryResponse.cant_proyectos}</span>
                        </Text>{' '}
                      </div>
                    </div>
                    <div className="ms-Grid-row ">
                      <div className="ms-Grid-col ms-lg12 ms-sm12 mt-1">
                        {' '}
                        <Text variant="medium">
                          <Icon iconName="DocumentApproval" className="text-icon"></Icon>
                          {t('Nro. Factura: ')}
                          <span className="fw-500"> {settlementResponse.getInvoiceNumber()} </span>
                        </Text>
                      </div>
                      <div className="ms-Grid-col ms-lg12 ms-sm12 mt-1">
                        {' '}
                        <Text variant="medium">
                          <Icon iconName="Market" className="text-icon"></Icon>
                          {t('Estado: ')}
                          <span className="fw-500">{settlementResponse.getStatus()}</span>
                        </Text>
                      </div>
                      <div className="ms-Grid-col ms-lg12 ms-sm12 mt-1">
                        {' '}
                        <Text variant="medium">
                          <Icon iconName="Money" className="text-icon"></Icon>
                          {t('Importe total: ')}
                          <span className="fw-500">${summaryResponse.importe_total}</span>
                        </Text>
                      </div>
                    </div>
                    <div className="ms-Grid-row ">
                      <div className="ms-Grid-col ms-lg12 ms-sm12 mt-1">
                        {' '}
                        <Text variant="medium">
                          <Icon iconName="EventDate" className="text-icon"></Icon>
                          {t('Creada el: ')}
                          <span className="fw-500">{formatDate()}</span>
                        </Text>
                      </div>
                    </div>
                    <div className="ms-Grid-row mt-2">
                      <div className="ms-Grid-col ms-sm8">
                        <PrimaryButton
                            text={t('Descargar documento')}
                            iconProps={{ iconName: 'Download', color: DefaultPalette.themeLighterAlt }}
                            onClick={() => getSettlementPDF(settlementResponse)}
                        ></PrimaryButton>
                      </div>
                      <div className="ms-Grid-col ms-sm4"></div>
                    </div>
                  </div>
                  <div className="ms-Grid-col ms-sm6">
                    <div className="ms-Grid-row">
                      <div className="ms-Grid-col ms-sm2">
                        <DefaultButton
                            text={t('Recargar items')}
                            iconProps={{ iconName: 'Refresh' }}
                            onClick={() => getItems(settlementResponse)}
                        ></DefaultButton>
                      </div>
                      <div className="ms-Grid-col ms-sm12">
                        <DetailsList
                            className="projects-table-detail"
                            items={items}
                            groups={formatGroups()}
                            columns={_itemColumns}
                            groupProps={{
                              showEmptyGroups: false,
                              isAllGroupsCollapsed: true,
                            }}
                            setKey="set"
                            layoutMode={DetailsListLayoutMode.justified}
                            checkboxVisibility={CheckboxVisibility.hidden}
                            //   selection={_selection}
                            selectionPreservedOnEmptyClick={false}
                            //   onItemInvoked={_onItemInvoked}
                            compact
                            onShouldVirtualize={() => false}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="ms-Grid-row">
                  <div className="ms-Grid-col ms-sm12 mt-2">
                    <DefaultButton
                        text={
                          !showDocuments ? t('Ver documentos adicionales') : t('Ocultar documentos adicionales')
                        }
                        onClick={() => {
                          getRelatedDocuments(settlementResponse);
                          setShowDocuments(!showDocuments);
                        }}
                        iconProps={{ iconName: 'RedEye' }}
                    ></DefaultButton>
                    <DefaultButton
                        style={{ marginLeft: '8px' }}
                        iconProps={{ iconName: 'Save' }}
                        text={!showDocuments ? t('Actualizar documentos') : t('Actualizar documentos')}
                        onClick={() => {
                          // getRelatedDocuments(settlementResponse);
                          getRelatedDocuments(settlementResponse);
                          getAvailableDocuments(settlementResponse);
                          // setShowDocuments(!showDocuments);
                        }}
                    ></DefaultButton>
                  </div>
                  <div className="ms-Grid-col ms-sm6 mt-2">
                    {relatedDocuments && showDocuments ? (
                        <DetailsList
                            className="projects-table"
                            items={relatedDocuments}
                            columns={_documentColumns}
                            setKey="set"
                            layoutMode={DetailsListLayoutMode.justified}
                            checkboxVisibility={CheckboxVisibility.hidden}
                            //   selection={_selection}
                            selectionPreservedOnEmptyClick={true}
                            ariaLabelForSelectionColumn="Toggle selection"
                            ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                            checkButtonAriaLabel="select row"
                            //   onItemInvoked={_onItemInvoked}
                            onRenderItemColumn={_renderDocumentColumn}
                            onShouldVirtualize={() => false}
                        />
                    ) : null}
                  </div>
                  <div className="ms-Grid-col ms-sm6 mt-2">
                    {availableDocuments && showDocuments ? (
                        <DetailsList
                            className="projects-table"
                            items={availableDocuments}
                            columns={_documentColumns2}
                            setKey="set"
                            layoutMode={DetailsListLayoutMode.justified}
                            checkboxVisibility={CheckboxVisibility.hidden}
                            //   selection={_selection}
                            selectionPreservedOnEmptyClick={true}
                            ariaLabelForSelectionColumn="Toggle selection"
                            ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                            checkButtonAriaLabel="select row"
                            //   onItemInvoked={_onItemInvoked}
                            onRenderItemColumn={_renderDocumentColumn2}
                            onShouldVirtualize={() => false}
                        />
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
        )}
      </ViewWrapperWithCommandBar>
  );
};

export default SettlementDetail;
