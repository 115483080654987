import { DialogType, Dialog, IDialogContentStyles, IDialogStyles, Icon } from '@fluentui/react';
import { ContextualMenu } from '@fluentui/react/lib/ContextualMenu';
import React, { ReactNode } from 'react';
import "./ProyectoEnlace.css";

//modal de confirmacion
type ModalProps = {
  title?: string;
  message?: string;
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
};

export const ModalConfirm: React.FC<ModalProps> = ({ isOpen, onClose, onConfirm,title,message }) => {
  if (!isOpen) return null;

  return (
    <div className="Customodal-overlay">
      <div className="Customodal">
        <div className="Customodal-header">
          <Icon iconName='IncidentTriangle' style={{fontSize:"25px",color:"#ffd966"}} />
          <h2> {title} </h2>
        </div>
        <div className="Customodal-body">
          <p> {message} </p>
        </div>
        <div className="Customodal-footer">
          <button className="Custobtn-confirm" onClick={onConfirm}>Si</button>
          <button className="Custobtn-cancel" onClick={onClose}>Cancelar</button>
        </div>
      </div>
    </div>
  );
}; 

//modal multiuso
interface ICustomModal {
  hidden?: boolean;
  minWidth?: number;
  minHeight?: number;
  onDismiss?: () => void;
  title?: string;
  children?: ReactNode;
}

function CustomModal({ hidden, minWidth = 400, onDismiss, title, children,minHeight=500 }: ICustomModal) {
  const dragOptions = {
    moveMenuItemText: 'Move',
    closeMenuItemText: 'Close',
    menu: ContextualMenu,
  };

  const dialogStyles: Partial<IDialogStyles> = {
    main: {
      height: minHeight, 
    },
  };

  const dialogContentStyles: Partial<IDialogContentStyles> = {
    innerContent: {
      height: '100%',
    },
  };

  return (
    <Dialog
      hidden={!hidden}
      minWidth={minWidth}
      onDismiss={onDismiss}
      dialogContentProps={{ type: DialogType.normal, title, styles: dialogContentStyles }}
      modalProps={{ isBlocking: true, dragOptions: dragOptions, styles: dialogStyles }}
    >
      {children}
    </Dialog>
  );
}

export default CustomModal;
