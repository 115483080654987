import React, { useState } from 'react';
import {
  Stack,
  Checkbox,
  Toggle,
  DefaultButton,
  PrimaryButton,
  ITextFieldStyles,
  TextField,
  Text,
  Link,
  IChoiceGroupOption,
  ChoiceGroup,
  Dropdown,
  Label,
  DefaultPalette,
  IComboBoxOption,
  IComboBoxStyles,
  VirtualizedComboBox,
  IComboBox,
} from '@fluentui/react';

import { ProjectSelector } from './ProjectSelector';
import { DropdownGroup } from './DropdownGroup';

import { ICommandBarItemProps } from '@fluentui/react/lib/CommandBar';

import ViewWrapperWithCommandBar from '../Layout/ViewWrapperWithCommandBar';
import { buttonStylesFor } from '../../styles/button-styles';

import axios from 'axios';
import TimeTaxSupportSystemClient from '../../system';
import MessageCourier from '../../lib/MessageCourier';
import useAsyncDataCourier from '../../common/custom-hooks/useAsyncDataCourier';
import { useBoolean } from '@fluentui/react-hooks';

const stackTokens = { childrenGap: 10, marginLeft: 10 };
const narrowTextFieldStyles: Partial<ITextFieldStyles> = { fieldGroup: { width: 300 } };

const comboBoxStyles: Partial<IComboBoxStyles> = { root: { maxWidth: '300px' } };
interface waitUntilStopTypingInterface {
  typing: Boolean;
  typingTimeout: any;
}

interface IBasicInputsView {
  system: TimeTaxSupportSystemClient;
  courier: MessageCourier;
}

interface ActivityFromExcelForm {
  date: string;
  timeRange: string;
  hours: string;
  name: string;
  projectName: string;
  projectCode: string;
  [index: string]: any;
}

export const BasicInputsView = (props: IBasicInputsView) => {
  const projects = useAsyncDataCourier(() => props.system.getProjects(), [props.system], props.courier);

  React.useEffect(() => {}, [projects]);

  const [TextFieldValue, setTextFieldValue] = React.useState('');
  const [asyncOptions, setAsyncOptions] = React.useState<IComboBoxOption[]>([]);
  const [comboBoxKeyValue, setComboBoxKeyValue] = React.useState('');

  const organizationChartLastLevelNodes = useAsyncDataCourier(
      () => props.system.getOrganizationChartSystem().getLastLevelNodes(),
      [props.system],
      props.courier
  );

  const organizationChartNodes = useAsyncDataCourier(
      () => props.system.getOrganizationChartSystem().getAllNodes(),
      [props.system],
      props.courier
  );

  const [waitUntilStopTyping, setWaitUntilStopTyping] = React.useState<waitUntilStopTypingInterface>({
    typing: false,
    typingTimeout: 500,
  });

  function _onChangeCheckBox(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, isChecked?: boolean) {
    console.log(`The option has been changed to ${isChecked}.`);
  }

  function _onToggle(ev: React.MouseEvent<HTMLElement>, checked?: boolean) {
    console.log('toggle is ' + (checked ? 'checked' : 'not checked'));
  }

  function _alertClicked(): void {
    alert('Clicked');
  }

  const onChangeTextFieldValue = React.useCallback(
      (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        setTextFieldValue(newValue || '');
      },
      []
  );

  function _onChangeRadio(
      ev?: React.FormEvent<HTMLElement | HTMLInputElement> | undefined,
      option?: IChoiceGroupOption | undefined
  ): void {
    console.dir(option);
  }

  const options: IChoiceGroupOption[] = [
    { key: 'A', text: 'Option A' },
    { key: 'B', text: 'Option B' },
    { key: 'C', text: 'Option C' },
    { key: 'D', text: 'Option D' },
  ];

  const optionsx: IComboBoxOption[] = [];
  for (let i = 0; i < 1000; i++) {
    optionsx.push({
      key: `${i}`,
      text: `Option ${i}`,
    });
  }

  let optionAsync: IComboBoxOption[] = [];

  const baseUrl = 'http://bart2.tecdata/colaboradores/autocomplete/';

  const setAsyncOptionItems = async (fieldName: String) => {
    if (fieldName) {
      if (waitUntilStopTyping.typingTimeout) {
        clearTimeout(waitUntilStopTyping.typingTimeout);
      }

      setWaitUntilStopTyping({
        typing: false,
        typingTimeout: setTimeout(async () => {
          const fieldValue = `${fieldName}`;
          const url = `${baseUrl}` + fieldValue;
          const response = await axios.get<[]>(url, { withCredentials: true });
          let optionArr: IComboBoxOption[] = [];

          if (response && response.data) {
            optionArr = response.data.map(({ label, value }) => {
              return { key: value, text: label };
            });
          }

          setAsyncOptions(optionArr);
        }, 800),
      });
    }
  };

  const onChangeComboBox = (
      event: React.FormEvent<IComboBox>,
      option?: IComboBoxOption,
      index?: number,
      value?: string
  ) => {
    console.log(event, 'event');
    console.log(option, 'option');
    console.log(index, 'index');
    console.log(value, 'value');

    if (option && option.key) setComboBoxKeyValue(option.key + '');
  };

  const items = React.useMemo(
      (): ICommandBarItemProps[] => [
        {
          key: 'project',
          text: 'Nuevo',
          itemProps: {},
          iconProps: { iconName: 'Add', color: DefaultPalette.themeLighterAlt },

          /**
           * @TODO usar theme en lugar de default pallete
           */
          buttonStyles: buttonStylesFor({
            backgroundColor: DefaultPalette.themePrimary,
            backgroundColorFocused: DefaultPalette.themeSecondary,
            textColor: DefaultPalette.themeLighterAlt,
            iconsColor: DefaultPalette.themeLighterAlt,
          }),
        },
        {
          key: 'newItem',
          text: 'Nuevo',
          iconProps: { iconName: 'Add' },
          subMenuProps: {
            items: [
              {
                key: 'emailMessage',
                text: 'Hito',
                iconProps: { iconName: 'TaskSolid' },
              },
            ],
          },
          // buttonStyles: defaultCommandBarButtonStyles,
        },
        {
          key: 'upload',
          text: 'Cargar',
          iconProps: { iconName: 'Upload' },
          // buttonStyles: defaultCommandBarButtonStyles,
        },
        {
          key: 'download',
          text: 'Descargar',
          iconProps: { iconName: 'Download' },
          // buttonStyles: defaultCommandBarButtonStyles,
        },
      ],
      []
  );

  const farItems: ICommandBarItemProps[] = React.useMemo(
      () => [
        {
          key: 'info',
          text: 'Info',
          // This needs an ariaLabel since it's icon-only
          ariaLabel: 'Info',
          iconOnly: true,
          iconProps: { iconName: 'Info' },
          // buttonStyles: defaultCommandBarButtonStyles,
        },
      ],
      []
  );

  const itemList = [
    {
      label: 'label1',
      value: 'value1',
      group: 'group1',
      description: 'Esta es una descripcion de prueba',
    },
    {
      label: 'label2',
      value: 'value2',
      group: 'group1',
    },
    {
      label: 'label3',
      value: 'value3',
      group: 'group1',
      description: 'Esta es una descripcion muy larga para ver si entra bien y como se ve',
    },
    {
      label: 'label4',
      value: 'value4',
      group: 'group2',
    },
    {
      label: 'label5',
      value: 'value5',
      group: 'group2',
      description: 'Esta es una descripcion',
    },
    {
      label: 'label6',
      value: 'value6',
      group: 'group3',
    },
    {
      label: 'label7',
      value: 'value7',
      group: 'group3',
      description: 'Esta es una descripcion muy larga para ver si entra bien y como se ve',
    },
  ];

  const [firstGroupShowed, setFirstGroupShowed] = useState(false);

  const KEY_DATE = 0;
  const KEY_TIME_RANGE = 1;
  const KEY_HOURS = 2;
  const KEY_ACTIVITY_NAME = 3;
  const KEY_PROJECT_NAME = 4;
  const KEY_PROJECT_CODE = 5;

  const RANGE_TO = 'to';
  const RANGE_FROM = 'from';
  const getTimeByRange = (range: string, by: string) => {
    let time = '';
    if (by === RANGE_TO) {
      time = range.split('-')[0].trim();
    }

    if (by === RANGE_FROM) {
      time = range.split('-')[1].trim();
    }
    console.log('time > ', time);
    return time;
  };

  // const test = async() => {
  //   let test1 = await props.system.getOrganizationChartSystem().getAllNodes()
  //   console.log(test1)
  // }

  // useEffect(()=>{
  //   test()
  // })

  const [allowFreeform, { toggle: toggleAllowFreeform }] = useBoolean(true);

  return (
      <ViewWrapperWithCommandBar title="Inputs básicos" items={items} farItems={farItems}>
        <div className="padding-1">
          <Stack horizontal>
            <h3>Inputs basicos</h3>
          </Stack>

          <Stack horizontal></Stack>

          {/* <OrganizationChartDropdown
          // system={props.system}
          options={allowFreeform ? organizationChartNodes : organizationChartLastLevelNodes}
          // onSelectedKey={(e: any) => console.log(e)}
          // onChange={(
          //   event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string
          // ) => {
          //   if (option) {
          //     setSelectedKey(option.key);
          //     console.log(option.key)
          //   };
          // }}
        /> */}
          <Toggle label="Cambiar tipo de dropdown" checked={allowFreeform} onChange={toggleAllowFreeform} />
          <Stack tokens={stackTokens}>
            <Stack.Item>
              <ProjectSelector system={props.system} courier={props.courier} />
            </Stack.Item>
            <input
                type={'checkbox'}
                onChange={() => {
                  setFirstGroupShowed(!firstGroupShowed);
                }}
            />
            <Stack.Item>
              <DropdownGroup
                  system={props.system}
                  courier={props.courier}
                  list={itemList}
                  firstGroupShowed={true}
                  defaultDropdownName={'Se puede elegir lo que dice aca'}
                  onClick={(item: any) => console.log(item)}
                  showGroup={'group3'}
              />
            </Stack.Item>

            <Stack.Item>
              <TextField
                  label="TextField"
                  value={TextFieldValue}
                  onChange={onChangeTextFieldValue}
                  styles={narrowTextFieldStyles}
                  // disabled
                  // readOnly
                  // defaultValue="default-value"
                  errorMessage="Error message"
              />
            </Stack.Item>
            <hr />
            <Stack.Item>
              <Checkbox
                  label="Checkbox"
                  // disabled={true or false (default) }
                  // defaultChecked
                  onChange={_onChangeCheckBox}
              />
            </Stack.Item>
            <hr />

            <Stack.Item>
              <Toggle label="Toggle" defaultChecked onText="On" onChange={_onToggle} />
            </Stack.Item>
            <hr />

            <Stack.Item>
              <DefaultButton
                  text="Standard"
                  onClick={_alertClicked}
                  allowDisabledFocus
                  // disabled={ true or false (default) }
              />
            </Stack.Item>
            <hr />

            <Stack.Item>
              <PrimaryButton
                  text="Primary"
                  onClick={_alertClicked}
                  allowDisabledFocus
                  // disabled={ true or false (default) }
              />
            </Stack.Item>

            <Stack.Item>
              <Label>Texto & Link</Label>
              <Text>
                Cuando tiene href se comporta como un
                <Link href="https://google.com" underline>
                  {' <a>'} tag{' '}
                </Link>
                Pero cuando no, se comporta como un
                <Link onClick={_alertClicked}> {' <button>'} tag</Link>
              </Text>
            </Stack.Item>

            <Stack.Item>
              <ChoiceGroup
                  defaultSelectedKey="B"
                  options={options}
                  onChange={_onChangeRadio}
                  label="Radio button"
              />
            </Stack.Item>

            <Stack.Item>
              <Dropdown
                  placeholder="Select an option"
                  label="Dropdown"
                  options={[
                    { key: 'A', text: 'Option a', title: 'I am option a.' },
                    { key: 'B', text: 'Option b' },
                    { key: 'C', text: 'Option c', disabled: true },
                    { key: 'D', text: 'Option d' },
                    { key: 'E', text: 'Option e' },
                  ]}
                  required
                  styles={narrowTextFieldStyles}
              />
            </Stack.Item>

            <Stack.Item>
              <VirtualizedComboBox
                  defaultSelectedKey={comboBoxKeyValue}
                  label="ComboBox async"
                  allowFreeform
                  autoComplete="off"
                  options={asyncOptions}
                  dropdownMaxWidth={250}
                  openOnKeyboardFocus={true}
                  useComboBoxAsMenuWidth
                  onInputValueChange={setAsyncOptionItems}
                  onChange={onChangeComboBox}
                  styles={comboBoxStyles}
              />
            </Stack.Item>

            <Stack.Item>
              {/* <BasicBlockUI
            /> */}
            </Stack.Item>
          </Stack>
        </div>
      </ViewWrapperWithCommandBar>
  );
};
