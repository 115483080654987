import React from 'react';
import MessageCourier from '../../lib/MessageCourier';
import TimeTaxSupportSystemClient from '../../system';
import { useNavigate, useParams } from 'react-router-dom';
import useSystemCall from '../../common/custom-hooks/useSystemCall';
import DocumentUploadControlSaved from '../../system/documents/DocumentUploadControlSaved';
import DocumentUploadControl from '../../system/documents/DocumentUploadControl';
import { DefaultPalette, ICommandBarItemProps, Label, Pivot, PivotItem, Text } from '@fluentui/react';
import ViewWrapperWithCommandBar from '../Layout/ViewWrapperWithCommandBar';
import { defaultCommandBarButtonStyles, titleCommandBarButtonStyles } from '../../styles/button-styles';
import { routes } from '../../common/constants';
import useAsyncDataCourier from '../../common/custom-hooks/useAsyncDataCourier';
import { useTranslation, Trans } from 'react-i18next';

interface IControlDocument {
  courier: MessageCourier;
  system: TimeTaxSupportSystemClient;
}

const ControlDocument = (props: IControlDocument) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  let { proId } = useParams();
  let { id } = useParams();

  const [documentControl, setDocumentControl] = React.useState<DocumentUploadControl | undefined>(undefined);

  const projectSelected = useAsyncDataCourier(
      () => props.system.getProjectSystem().getById(proId!),
      [props.system, proId],
      props.courier
  );
  // const projectSelected = React.useMemo(() => {
  //   return projects?.find((item) => !!proId && item.isIdentifiedBy(proId));
  // }, [proId, projects]);

  const getDocumentControl = async () => {
    if (projectSelected && id) {
      let docControl = await props.system
          .getProjectSystem()
          .getDocumentControlIdentifiedBy(id, projectSelected);
      setDocumentControl(docControl);
    }
  };

  React.useEffect(() => {
    getDocumentControl();
  }, [projectSelected]);

  const itemsMenu = React.useMemo(
      (): ICommandBarItemProps[] => [
        {
          key: 'title',
          text: t('Documento obligatorio'),
          iconProps: {
            iconName: 'ProtectedDocument',
            color: DefaultPalette.themeLighterAlt,
            className: 'text-title-btn',
          },
          buttonStyles: titleCommandBarButtonStyles,
          className: 'text-title-btn',
        },
        {
          key: 'back',
          text: t('Volver'),
          onClick: () => navigate(`/${routes.project_docs}/${proId}`),
          iconProps: { iconName: 'Back' },
          buttonStyles: defaultCommandBarButtonStyles,
        },
      ],
      []
  );

  return (
      <ViewWrapperWithCommandBar
          withBorderBottom
          title={''}
          items={itemsMenu}
          overflowButtonStyles={defaultCommandBarButtonStyles}
          variant="highligth"
          titleBody={t('Proyectos')}
      >
        <div className="padding-1">
          {documentControl && (
              <div className="ms-Grid bg-white-h100" dir="ltr">
                <div className="ms-Grid-row">
                  <div className="ms-Grid-col ms-sm12">
                    <Text variant="mediumPlus" className="mt-2">
                      {documentControl.getConcept()}
                    </Text>
                  </div>
                </div>
                <div className="ms-Grid-row">
                  <div className="ms-Grid-col ms-sm12"></div>
                </div>
              </div>
          )}
        </div>
      </ViewWrapperWithCommandBar>
  );
};

export default ControlDocument;
