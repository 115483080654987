import ObjectError from './ObjectError';

type AssertionClosure = (value: any) => boolean;

class AssertionItem {
  private constructor(
      private assertionClosure: AssertionClosure,
      private objectProperty: string,
      private errorMessage: string
  ) {}

  static forClosure(assertionClosure: AssertionClosure, objectProperty: string, errorMessage: string) {
    return new this(assertionClosure, objectProperty, errorMessage);
  }

  collectError(value: any, errorList: ObjectError[]) {
    if (!this.assertionClosure(value)) {
      errorList.push(this.toError());
    }
  }

  private toError(): ObjectError {
    return new ObjectError(this.errorMessage, this.objectProperty);
  }
}

export default AssertionItem;
