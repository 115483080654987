import React from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import { Text } from '@fluentui/react/lib/Text';
import { TextField } from '@fluentui/react/lib/TextField';
import { DefaultButton, PrimaryButton } from '@fluentui/react/lib/Button';
import { Checkbox } from '@fluentui/react';
import { Link } from '@fluentui/react';

import TimeTaxSupportSystemClient from '../system/TimeTaxSupportSystemClient';
import { User } from '../system/User';
import { MessageReceiver } from '../lib/MessageReceiver';
import MessageCourier from '../lib/MessageCourier';
import { APP_NAME, LOGO_PATH, routes } from '../common/constants';
import { t } from 'i18next';
import { useSearchParams } from 'react-router-dom';
import { TOTPDialog } from './TOTPDialog';
import { IQRurl } from '../system/AuthenticationSystem';

interface ILoginViewProps {
  system: TimeTaxSupportSystemClient;
  tokenGetter: () => Promise<string>;
  onLogin: (user: User) => void;
  changeBlockUI: (change: boolean) => void;
  errorCodeRetrieved: any;
}

interface ILoginViewState {
  user: User | undefined;
  email: string | null;
  password: string | null;
  showLoginForm: boolean;
  showTOTPDialog: boolean;
  showTOTPDialogError: boolean;
  hasBeenVinculated: boolean;
  qrValue: string;
  formErrors: { [index: string]: any; email: string; password: string };
  errorMessage: string;
  emailValid: boolean;
  passwordValid: boolean;
  formValid: boolean;
  [index: string]: any;
}

const errorTextStyles = {
  root: {
    textAlign: 'center',
    marginBottom: '10px',
    color: 'red',
  },
};
class LoginView extends React.Component<ILoginViewProps, ILoginViewState> implements MessageReceiver {
  private courier: MessageCourier;
  constructor(props: ILoginViewProps) {
    super(props);
    this.state = {
      user: undefined,
      qrValue: '',
      email: '',
      password: '',
      formErrors: { email: '', password: '' },
      hasBeenVinculated: true,
      showLoginForm: false,
      showTOTPDialog: false,
      showTOTPDialogError: false,
      emailValid: false,
      passwordValid: false,
      formValid: false,
      errorMessage: '',
      checked: false,
      isWriting: false,
    };

    this.courier = new MessageCourier();
  }

  componentDidMount(): void {
    if (localStorage.getItem('email') !== '' && localStorage.getItem('email') !== null) {
      this.setState({
        email: localStorage.getItem('email') != null ? localStorage.getItem('email') : '',
        password: '',
        checked: true,
      });
      this.validateForm();
    }

    this.courier.subscribe(this);
  }

  receiveError(message: string, _error: unknown): void {
    this.setState({ errorMessage: message });
  }
  receiveSuccess(message: string): void {
    throw new Error('Method not implemented.');
  }

  passwordIsValid(): boolean {
    return !!this.state.password && this.state.password.length >= 6;
  }

  emailIsValid() {
    if (this.state.email) return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.state.email);
    else return false;

    // return (this.state.email && this.state.email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/))
  }

  showForm = () => {
    this.setState({ showLoginForm: true });

    if (localStorage.getItem('email') !== '' && localStorage.getItem('email') !== null) {
      let fieldValidationErrors = this.state.formErrors;
      // let emailValid = this.state.emailValid;
      // let passwordValid = this.state.passwordValid;

      let emailValid = this.emailIsValid();
      fieldValidationErrors.email = emailValid || this.state.isWriting ? '' : t('Email es inválido');

      let passwordValid = this.passwordIsValid();
      fieldValidationErrors.password =
          passwordValid || this.state.isWriting ? '' : t('Contraseña es demasiado corta');

      this.setState(
          {
            formErrors: fieldValidationErrors,
            emailValid: emailValid,
            passwordValid: passwordValid,
          },
          this.validateForm
      );
    }
  };

  validateField = (e: any) => {
    const fieldName = e.target.name;
    const value = e.target.value;
    let fieldValidationErrors = this.state.formErrors;
    let emailValid = this.state.emailValid;
    let passwordValid = this.state.passwordValid;

    switch (fieldName) {
      case 'email':
        emailValid = value.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/);
        fieldValidationErrors.email = emailValid ? '' : t('Email es inválido');
        break;
      case 'password':
        passwordValid = value.length >= 6;
        fieldValidationErrors.password = passwordValid ? '' : t('Contraseña es demasiado corta');
        break;
      default:
        break;
    }
    this.setState(
        {
          formErrors: fieldValidationErrors,
          emailValid: emailValid,
          passwordValid: passwordValid,
        },
        this.validateForm
    );
  };

  validateForm() {
    this.setState({ formValid: this.state.emailValid && this.state.passwordValid });
  }

  handleUserInput = (e: any) => {
    this.setState({ errorMessage: '' });
    const name = e.target.name;
    const value = e.target.value;
    let _state = { ...this.state };
    _state[name] = value;
    this.setState({ [name]: value });
  };

  showErrors = (validationField: string) => {
    if (!this.state.isWriting) {
      return (
          <div className="formErrors">
            {Object.keys(this.state.formErrors).map((fieldName, i) => {
              if (fieldName === validationField) {
                if (this.state.formErrors[fieldName].length > 0 && !this.state.isWriting) {
                  return (
                      <Text
                          variant="mediumPlus"
                          nowrap
                          block
                          key={i}
                          style={{
                            textAlign: 'center',
                            marginBottom: '10px',
                            color: 'red',
                          }}
                      >
                        {fieldName.charAt(0).toUpperCase() + fieldName.slice(1)}{' '}
                        {this.state.formErrors[fieldName]}
                      </Text>
                  );
                } else {
                  return '';
                }
              }
            })}
          </div>
      );
    }
  };

  rememberMe = () => {
    localStorage.setItem('email', this.state.checked && this.state.email ? this.state.email : '');
  };

  onChangeCheckbox = () => {
    this.setState({ checked: !this.state.checked });
  };

  getMsgByCodeRetrieved = () => {
    switch (+this.props.errorCodeRetrieved) {
      case 601:
        return t('Credenciales invalidas **');
    }
  };

  render() {
    const { errorMessage } = this.state;

    return (
        <form
            className="form-back"
            onSubmit={(e) => {
              e.preventDefault();
              this.rememberMe();
              this.onSubmit();
            }}
        >
          <div className="ms-Grid page-body " style={{ padding: '0px 20px' }} dir="ltr">
            <div className="ms-Grid-row">
              <div
                  className="ms-Grid-col ms-xl3 ms-lg6 ms-sm12"
                  style={{ margin: '0 auto', textAlign: 'center', float: 'none' }}
              >
                <div
                    className="login-container panel"
                    style={{
                      width: '320px',
                      height: '100%',
                      marginRight: 'auto',
                      marginLeft: 'auto',
                      minHeight: '320px',
                      display: 'block',
                    }}
                >
                  <div className="panel-heading" style={{ width: '100%', padding: '20px 0' }}>
                    <img src={LOGO_PATH} alt="logo" className="tts-logo"></img>
                  </div>
                  <div
                      style={{
                        width: '70%',
                        margin: 'auto',
                        marginTop: '20px',
                        marginBottom: '20px',
                      }}
                  >
                    <PrimaryButton
                        text={t('Ingreso corporativo')}
                        onClick={this.onCorporateSignInClicked}
                        allowDisabledFocus
                        className="login-button"
                        style={{ height: '70px' }}
                    />
                    <DefaultButton
                        text={t('Ingreso con credenciales')}
                        onClick={this.showForm}
                        allowDisabledFocus
                        className="login-button"
                        style={{ borderColor: '#909fa72f', backgroundColor: '#f5f3f3' }}
                    />
                    <div
                        className={this.state.showLoginForm ? 'show' : 'default'}
                        style={{ marginTop: '10px' }}
                    >
                      <TextField
                          // styles={inputFieldStyles}
                          placeholder={t('Email')}
                          className="login-input-field"
                          value={this.state.email ? this.state.email : ''}
                          onChange={(e) => {
                            this.handleUserInput(e);
                            this.validateField(e);
                            this.setState({ isWriting: true });
                          }}
                          onBlur={(e) => {
                            // this.handleUserInput(e);
                            this.validateField(e);
                            this.setState({ isWriting: false });
                          }}
                          name="email"
                          errorMessage={!this.state.isWriting ? this.state.formErrors.email : ''}
                          // iconProps={mailIcon}
                      />
                      {/* {this.showErrors('email')} */}
                      <TextField
                          // styles={inputFieldStyles}
                          placeholder={t('Contraseña')}
                          className="login-input-field"
                          value={this.state.password ? this.state.password : ''}
                          name="password"
                          canRevealPassword
                          revealPasswordAriaLabel="Mostrar contraseña"
                          onChange={(e) => {
                            this.handleUserInput(e);
                            this.validateField(e);
                            this.setState({ isWriting: true });
                          }}
                          onBlur={(e) => {
                            // this.handleUserInput(e);
                            this.validateField(e);
                            this.setState({ isWriting: false });
                          }}
                          type="password"
                          errorMessage={!this.state.isWriting ? this.state.formErrors.password : ''}
                          // iconProps={lockIcon}
                      />
                      {/* {this.showErrors('password')} */}
                      <div style={{ display: 'block', float: 'left' }}>
                        <Checkbox
                            label={t('Recordarme')}
                            className="remember-user-checkbox"
                            checked={this.state.checked}
                            onChange={this.onChangeCheckbox}
                        />
                      </div>
                      <PrimaryButton
                          className="iniciar-sesion-btn"
                          text={t('Iniciar Sesión')}
                          allowDisabledFocus
                          disabled={!this.state.formValid}
                          type="submit"
                      />
                      <div
                          style={{
                            display: 'block',
                            textAlign: 'center',
                            marginBottom: '25px',
                          }}
                      >
                        <Link href={routes.recover_password} className="forgot-password">
                          {t('Olvidaste tu contraseña?')}
                        </Link>
                      </div>
                      <Text variant="mediumPlus" nowrap block styles={errorTextStyles}>
                        {errorMessage}
                      </Text>
                    </div>
                    {this.props.errorCodeRetrieved && (
                        <Text variant="mediumPlus" nowrap block className="mt-1 error-color">
                          {this.getMsgByCodeRetrieved()}
                        </Text>
                    )}
                  </div>
                  <div
                      className="panel-footer"
                      style={{ width: '320px', marginRight: 'auto', marginLeft: 'auto', display: 'block' }}
                  >
                    <Text
                        variant="medium"
                        nowrap
                        block
                        style={{
                          textAlign: 'center',
                        }}
                    >
                      © {new Date().getFullYear()} - {APP_NAME}
                    </Text>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <TOTPDialog
              show={this.state.showTOTPDialog}
              hasBeenVinculated={this.state.hasBeenVinculated}
              onAccept={(code) => this.onSendTOTPCode(code)}
              onCancel={() => this.onCancelTOTPDialog()}
              showTOTPDialogErrorCode={this.state.showTOTPDialogErrorCode}
              QRValue={this.state.qrValue}
          ></TOTPDialog>
        </form>
    );
  }

  private async onSendTOTPCode(code: string) {
    this.props.changeBlockUI(true);
    try {
      const user = await this.props.system.getAuthenticationSystem().validateCode2FA(code);
      this.props.onLogin(user);
      this.setState({ showTOTPDialog: false });
    } catch (e) {
      console.log(e);
      this.setState({ showTOTPDialogErrorCode: true });
    }
    this.props.changeBlockUI(false);
  }

  private onCancelTOTPDialog() {
    this.setState({ showTOTPDialog: false });
  }

  private async onSubmit() {
    this.props.changeBlockUI(true);

    try {
      if (this.state.email && this.state.password) {
        const token = await this.props.tokenGetter();
        const statusResponse = await this.props.system.signIn(this.state.email, this.state.password, token);

        if (statusResponse.isOk()) {
          const user = statusResponse.getData() as User;
          this.props.onLogin(user);
          this.setState({ user: user });
        }

        if (statusResponse.shouldShowQRAuth()) {
          const qr = statusResponse.getData() as IQRurl;
          this.setState({ showTOTPDialog: true });
          this.setState({ qrValue: qr.qrUrl });
          this.setState({ hasBeenVinculated: false });
        }

        if (statusResponse.requireTOTPCode()) {
          this.setState({ showTOTPDialog: true });
        }
      }
    } catch (e) {
      this.courier.messageError(e);
      this.setState({ password: '' });
    }
    this.props.changeBlockUI(false);
  }

  private onCorporateSignInClicked = () => {
    return this.props.system.corporateSignIn();
  };
}

interface ILoginViewWrapperProps {
  system: TimeTaxSupportSystemClient;
  tokenAction: string;
  onLogin: (user: User) => void;
  changeBlockUI: (change: boolean) => void;
}

const LoginViewWrapper = (props: ILoginViewWrapperProps) => {
  const captcha = useGoogleReCaptcha();
  const getToken = async () => {
    return captcha.executeRecaptcha ? await captcha.executeRecaptcha(props.tokenAction) : '';
  };

  const [searchParams] = useSearchParams();
  const errorCodeRetrieved = searchParams.get('error');

  return (
      <LoginView
          errorCodeRetrieved={errorCodeRetrieved}
          system={props.system}
          tokenGetter={getToken}
          onLogin={props.onLogin}
          changeBlockUI={props.changeBlockUI}
      />
  );
};

export default LoginViewWrapper;
