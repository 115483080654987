import { DateTime } from 'luxon';
import { SystemDateParser } from '../../SystemDateParser';
import { ProjectCompanyDistribution } from '../../projects/ProjectCompanyDistribution';
import ProjectCompanySaved from '../../projects/ProjectCompanySaved';
import { ProjectCompanyJson } from '../../projects/ProjectCompany';

export interface IMailInspectorRulesTag {
  mire_id: string;
  mire_expid: string;
  mire_emails_equipo: string;
  mire_emails_empresa_usuaria: string;
  mire_claves: string;
  mire_activo: boolean;
  empresaXProyecto: ProjectCompanyJson;
}

export class MailInspectorRulesTag {
  protected constructor(
      private id: string,
      private active: boolean,
      private companyInProject: ProjectCompanySaved,
      private teamEmails: string[],
      private externalEmails: string[],
      private keywords: string[]
  ) {}

  static identifiedBy(
      id: string,
      active: boolean,
      companyInProject: ProjectCompanySaved,
      teamEmails: string[],
      externalEmails: string[],
      keywords: string[]
  ) {
    return new this(id, active, companyInProject, teamEmails, externalEmails, keywords);
  }

  static buildFrom(rule: IMailInspectorRulesTag) {
    const teamEmails = rule.mire_emails_equipo !== '' ? rule.mire_emails_equipo.split(',') : [];
    const externalEmails =
        rule.mire_emails_empresa_usuaria !== '' ? rule.mire_emails_empresa_usuaria.split(',') : [];
    const keywords = rule.mire_claves !== '' ? rule.mire_claves.split(',') : [];

    let projectCompany: ProjectCompanySaved = ProjectCompanySaved.fromJson(rule.empresaXProyecto);
    return this.identifiedBy(
        rule.mire_id,
        rule.mire_activo,
        projectCompany,
        teamEmails,
        externalEmails,
        keywords
    );
  }

  getId() {
    return this.id;
  }
  getActive() {
    return this.active;
  }

  getCompanyInProject() {
    return this.companyInProject;
  }
  getTeamEmails() {
    return [...this.teamEmails];
  }
  getExternalEmails() {
    return [...this.externalEmails];
  }
  getKeywords() {
    return [...this.keywords];
  }

  toJson() {
    const ret: IMailInspectorRulesTag = {
      mire_id: this.getId(),
      mire_activo: this.getActive(),
      mire_expid: this.getCompanyInProject().getId(),
      mire_emails_equipo: this.getTeamEmails().join(',').toLowerCase(),
      mire_emails_empresa_usuaria: this.getExternalEmails().join(',').toLowerCase(),
      mire_claves: this.getKeywords().join(','),
      empresaXProyecto: this.getCompanyInProject().asJson(),
    };
    return ret;
  }
}
