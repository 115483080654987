import React from 'react';
import { Stack } from '@fluentui/react';
import { CommandBar, ICommandBarItemProps } from '@fluentui/react/lib/CommandBar';
import { IButtonStyles } from '@fluentui/react/lib/Button';
import { Text } from '@fluentui/react/lib/Text';

import { themeLight } from '../../styles/colors';
import { textBoldStyle } from '../Common/TextStyles';
import { buttonStylesFor } from '../../styles/button-styles';
import i18next, { t } from 'i18next';

const commandBarStyles = { height: '2rem' };
const backgroundSecondaryStyles = { backgroundColor: themeLight.backgroundSecondary };

const commandBarButtonSecondaryButtonStyles = buttonStylesFor({
  backgroundColor: themeLight.backgroundSecondary,
  backgroundColorFocused: themeLight.backgroundSecondaryFocused,
});

interface IViewWrapperWithCommandBarBaseProps {
  items: ICommandBarItemProps[];
  title: string;
  overflowItems?: ICommandBarItemProps[];
  farItems?: ICommandBarItemProps[];
  overflowButtonStyles?: IButtonStyles;
  withBorderBottom?: boolean;
  titleBody?: string;
}

const HighligthViewWrapperWithCommandBar: React.FC<IViewWrapperWithCommandBarBaseProps> = ({ ...props }) => {
  return (
      <React.Fragment>
        <div className="full-width full-height" style={{ ...backgroundSecondaryStyles }}>
          <div className="full-width" style={{ height: '6%' }}>
            <CommandBar
                items={props.items}
                overflowItems={props.overflowItems}
                overflowButtonProps={{ ariaLabel: 'Más opciones', styles: props.overflowButtonStyles }}
                farItems={props.farItems}
                ariaLabel="Inbox actions"
                primaryGroupAriaLabel="Email actions"
                farItemsGroupAriaLabel="More actions"
                className={props.withBorderBottom ? 'full-width border-bottom-nav' : 'full-width'}
                styles={{
                  root: { ...commandBarStyles, ...backgroundSecondaryStyles, padding: '10px' },
                }}
            />
          </div>
          <div>{props.children}</div>
        </div>
      </React.Fragment>
  );
};

const highligthedUnstyledButtons = (items?: ICommandBarItemProps[]): ICommandBarItemProps[] | undefined => {
  return (
      items &&
      items.map((item) => {
        const hasButtonStyles = !!item.buttonStyles;

        return {
          ...item,
          buttonStyles: hasButtonStyles ? item.buttonStyles : commandBarButtonSecondaryButtonStyles,
        };
      })
  );
};

const StandardViewWrapperWithCommandBar: React.FC<IViewWrapperWithCommandBarBaseProps> = ({ ...props }) => {
  const items = React.useMemo(() => highligthedUnstyledButtons(props.items), [props.items])!;
  const farItems = React.useMemo(() => highligthedUnstyledButtons(props.farItems), [props.farItems]);

  return (
      <Stack verticalFill>
        <div className="full-width view-wrapper-command-bar-wrapper">
          <CommandBar
              items={items}
              overflowItems={props.overflowItems}
              overflowButtonProps={{ ariaLabel: 'Más opciones', styles: props.overflowButtonStyles }}
              farItems={farItems}
              ariaLabel="Inbox actions"
              primaryGroupAriaLabel="Email actions"
              farItemsGroupAriaLabel="More actions"
              className="full-width"
              styles={{
                root: {
                  ...commandBarStyles,
                  ...backgroundSecondaryStyles,
                  padding: '0.4rem',
                  paddingLeft: '1rem',
                },
              }}
          />
        </div>
        {props.title && (
            <Text
                variant="xLarge"
                block
                nowrap
                styles={{ root: { ...textBoldStyle, paddingLeft: '1rem', paddingTop: '1.5rem' } }}
            >
              {t(props.title)}
            </Text>
        )}
        {props.children}
      </Stack>
  );
};

interface IViewWrapperWithCommandBarProps extends IViewWrapperWithCommandBarBaseProps {
  variant?: 'standard' | 'highligth';
}

const ViewWrapperWithCommandBar: React.FC<IViewWrapperWithCommandBarProps> = ({
                                                                                variant = 'standard',
                                                                                ...props
                                                                              }) => {
  const highligth = variant === 'highligth';

  return (
      <React.Fragment>
        {highligth ? (
            <HighligthViewWrapperWithCommandBar {...props} />
        ) : (
            <StandardViewWrapperWithCommandBar {...props} />
        )}
      </React.Fragment>
  );
};

export default ViewWrapperWithCommandBar;
