import React, { useEffect, useState, useMemo } from 'react';
import { IStackTokens, Stack } from '@fluentui/react/lib/Stack';
import { Text } from '@fluentui/react/lib/Text';
import {
  Dropdown,
  DropdownMenuItemType,
  IDropdownStyles,
  IDropdownOption,
} from '@fluentui/react/lib/Dropdown';
import { Toggle } from '@fluentui/react/lib/Toggle';
import { DefenseFileAssistantTable } from './DefenseFileAssistantTable';
import Project from '../../../system/projects/Project';
import TimeTaxSupportSystemClient from '../../../system';
import MessageCourier from '../../../lib/MessageCourier';
import { useTranslation, Trans } from 'react-i18next';

interface ISettlementsSelector {
  tableData: any;
  setButtonDisability: (can: boolean) => void;
  type: string;
  getIdsFromTable: any;
  system: TimeTaxSupportSystemClient;
  courier: MessageCourier;
}

const SettlementsSelector = (props: ISettlementsSelector) => {
  const { t, i18n } = useTranslation();
  const [activityReport, setActivityReport] = useState<boolean>(true);
  const [idArray, setIdArray] = useState<Array<string>>(['']);

  function _onChange(ev: React.MouseEvent<HTMLElement>, checked?: boolean) {
    setActivityReport(checked ? true : false);
  }

  const getIds = (idArray: Array<string>) => {
    setIdArray(idArray);
  };

  useEffect(() => {
    props.getIdsFromTable(idArray, activityReport);
  }, [idArray, activityReport]);

  return (
      <div className="mt-2">
        {/* <Text>Selecciona las liquidaciones o proyectos que sustentarán la defensa</Text> */}
        <div className="ms-Grid" dir="ltr">
          <div className="ms-Grid-row">
            <div className="ms-Grid-col  ms-sm12">
              <Toggle
                  onChange={_onChange}
                  onText={t('Con reporte de actividades')}
                  offText={t('Sin reporte de actividades')}
                  defaultChecked
              />
              {/* <span>{selectedProjects + ' proyectos seleccionados'}</span> */}
            </div>
            <div className="ms-Grid-col ms-sm12 mb-2">
              <DefenseFileAssistantTable
                  setButtonDisability={props.setButtonDisability}
                  data={props.tableData}
                  type={props.type}
                  getIdsFromTable={getIds}
                  activityReport={activityReport}
              />
            </div>
          </div>
          <div className="ms-Grid-row"></div>
        </div>
      </div>
  );
};

export default SettlementsSelector;
