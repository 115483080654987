import React from 'react';

import { Dropdown, IDropdownOption } from '@fluentui/react/lib/Dropdown';
import { Collaborator } from '../../system/collaborators/Collaborator';
import i18next, { t } from 'i18next';

interface ICollaboratorSelectorListProps {
  list: Collaborator[];
  default?: Collaborator;
  onChangeSelected: (newType?: Collaborator) => void;
  disabled?: boolean;
}

interface ICollaboratorSelectorListState {
  selected?: Collaborator;
}

class CollaboratorSelectorList extends React.Component<
    ICollaboratorSelectorListProps,
    ICollaboratorSelectorListState
> {
  constructor(props: ICollaboratorSelectorListProps) {
    super(props);
    this.state = {
      selected: this.props.default,
    };
  }

  componentDidUpdate(prevProps: ICollaboratorSelectorListProps) {
    if (this.props.default !== prevProps.default) {
      this.setState({ selected: this.props.default });
    }
  }

  render() {
    const { list } = this.props;
    const { selected } = this.state;

    const options: IDropdownOption[] = list.map((t) => ({ key: t.getId(), text: t.fullName() }));
    return (
        <>
          <Dropdown
              label={t('Colaborador')}
              onChange={this.onChangeSelection}
              selectedKey={selected && selected.getId()}
              options={options}
              disabled={this.props.disabled ? this.props.disabled : false}
          />
        </>
    );
  }

  private onChangeSelection = (event: unknown, option?: IDropdownOption<any> | undefined) => {
    const selectedId = option ? (option.key as string) : '';
    const selected = this.props.list.find((collaborator) => collaborator.isIdentifiedBy(selectedId));
    this.setState({ selected });
    this.props.onChangeSelected(selected);
  };
}

export default CollaboratorSelectorList;
