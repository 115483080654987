import XhrRequestHandler, { getQueryParamsExpression } from '../../lib/XhrRequestHandler';
import { ActivityJson } from './ActivitySystem';

export interface DuplicateActivityData {
  act_inicio: Date | string;
}

export interface ReplicateActivityData {
  desde: Date | string;
  hasta: Date | string;
  frecuencia: string | number;
  diasAReplicar: string[];
  taggeaEquipo?: boolean;
  // FORMATO HH:MM
  horaInicio?: string;
  // FORMATO HH:MM
  horaFin?: string;
  diaDelMes?: number;
  anteriorDiaHabil?: boolean;
}

export class ActivitySystemOptions {
  private readonly activityBase = '/actividades';
  private readonly activityDVMBase = '/actividadDVM';

  constructor(private requestHandler: XhrRequestHandler) {}

  async delete(id: string, allAfterThis: boolean = false, all: boolean = false) {
    const query = getQueryParamsExpression([
      { name: 'allAfterThis', value: allAfterThis },
      { name: 'all', value: all },
    ]);
    return await this.requestHandler.delete<ActivityJson>(`${this.activityBase}/${id}${query}`);
  }

  async reactivate(id: string) {
    return await this.requestHandler.put<null, any>(`${this.activityBase}/reactivar/${id}`, {});
  }

  async duplicate(id: string, data: DuplicateActivityData) {
    return await this.requestHandler.put<DuplicateActivityData, any>(
        `${this.activityBase}/duplicate/${id}`,
        data
    );
  }

  async replicate(id: string, data: ReplicateActivityData) {
    return await this.requestHandler.put<ReplicateActivityData, any>(`${this.activityBase}/replicate/${id}`, {
      ...data,
      // hasta: DateCustom.ParseDateFromApiNoTime(DateCustom.DateObjectToDMAStr(data.hasta as Date)),
    });
  }

  async calculateReplica(id: string, data: ReplicateActivityData) {
    return await this.requestHandler.put<any, any>(`${this.activityBase}/calc-replicate/${id}`, {
      ...data,
      // hasta: DateCustom.ParseDateFromApiNoTime(DateCustom.DateObjectToDMAStr(data.hasta as Date)),
    });
  }

  async getReplicateContext(desde: string | Date, act_id: string) {
    let toPost = {
      desde: desde,
    };
    return await this.requestHandler.put<{ position: string }, { desde: string | Date }>(
        `${this.activityBase}/replicate-context/${act_id}`,
        toPost
    );
  }

  async getQtyPendingReplicatedActivities(act_id: string) {
    return await this.requestHandler.get<any>(`${this.activityBase}/has-replicas-pending/${act_id}`);
  }

  async getPendingReplicatedActivities(act_id: string) {
    return await this.requestHandler.get<any>(`${this.activityBase}/get-replicas-pending/${act_id}`);
  }
}

export default ActivitySystemOptions;
