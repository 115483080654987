interface IFilters {
  [index: string]: string;
}

export interface IDetailsListStatus {
  [index: string]: any;
  page: number;
  filters: IFilters;
  sortList: any;
}

export class DetailListStatus {
  // private key: string = '';
  // private page: number = 0;
  // private filters: { [index: string]: string } = {};
  // private sortList: any[] = [];

  public static getDetailListStatus(key: string) {
    let _filters: { [index: string]: string } = {};

    let ret = {
      page: 0,
      filters: _filters,
      sortList: {},
    };
    let detailsListStatusStorage = localStorage.getItem('detailsListStatus');
    let detailsListStatus: any = {};

    if (detailsListStatusStorage) {
      detailsListStatus = JSON.parse(detailsListStatusStorage);
      if (detailsListStatus && detailsListStatus[key]) {
        let _filtersAux = detailsListStatus[key].filters;
        _filtersAux.forEach((filterItem: any) => {
          _filters[filterItem.prop] = filterItem.value;
        });
        detailsListStatus[key].filters = _filters;
        ret = detailsListStatus[key];
      }
    }

    return ret;
  }

  public static setDetailListStatus(key: string, page: number, filters: IFilters, sortList: any) {
    let detailsListStatusStorage = localStorage.getItem('detailsListStatus');
    let detailsListStatus = {} as IDetailsListStatus;
    if (detailsListStatusStorage) {
      detailsListStatus = JSON.parse(detailsListStatusStorage);
    }

    let _filtersAux: any[] = [];
    for (let prop in filters) {
      if (filters[prop]) {
        _filtersAux.push({ prop: prop, value: filters[prop] });
      }
    }

    detailsListStatus[key] = {
      page: page,
      filters: _filtersAux,
      sortList: sortList,
    };
    if (key) {
      localStorage.setItem('detailsListStatus', JSON.stringify(detailsListStatus));
    }
  }

  public static setFilters(key: string, filters: IFilters) {
    let detailsListStatusStorage = localStorage.getItem('detailsListStatus');
    let detailsListStatus = {} as IDetailsListStatus;
    if (detailsListStatusStorage) {
      detailsListStatus = JSON.parse(detailsListStatusStorage);
    }

    let _filtersAux: any[] = [];
    for (let prop in filters) {
      if (filters[prop]) {
        _filtersAux.push({ prop: prop, value: filters[prop] });
      }
    }

    detailsListStatus[key] = {
      page: detailsListStatus[key].page ? detailsListStatus[key].page : 1,
      filters: _filtersAux,
      sortList: detailsListStatus[key].sortList ? detailsListStatus[key].sortList : {},
    };

    localStorage.setItem('detailsListStatus', JSON.stringify(detailsListStatus));
  }

  public static cleanFilters(){
    localStorage.setItem('detailsListStatus', JSON.stringify({}));
  }

}
