import { Facepile, Icon, IDropdownOption, IFacepilePersona, Persona, PersonaSize } from '@fluentui/react';
import React from 'react';
import { GRID_MODE } from '../../../common/list-utils';
import { OrganizationChartNode } from '../../../system/organization_chart/OrganizationChartNode';
import { DetailsListView, EntityGridListProps } from '../../Common/DetailsList/DetailListView';
import { useTranslation, Trans } from 'react-i18next';
import { User } from '../../../system/User';

interface IProjectGridList extends EntityGridListProps {
  user: User;
}

export const ProjectGridList = (props: IProjectGridList) => {
  const { t, i18n } = useTranslation();
  const formatActivo = (item: any) => {
    if (item.pro_activo === '1') {
      return <Icon iconName="CheckMark" className="color-success" />;
    } else {
      return <Icon iconName="Cancel" className="color-error" />;
    }
  };

  const formatEquipo = (item: any) => {
    let equipo = item.equipo.split(' | ');

    var getInitials = function (string: string) {
      var names = string.split(' '),
          initials = names[0].substring(0, 1).toUpperCase();

      if (names.length > 1) {
        initials += names[1].substring(0, 1).toUpperCase();
      }
      return initials;
    };

    let equipoPersonas: IFacepilePersona[] = equipo.map((persona: string) => {
      return {
        personaName: persona,
        imageInitials: getInitials(persona),
      };
    });

    return (
        <Facepile
            personas={equipoPersonas}
            personaSize={10}
            maxDisplayablePersonas={4}
            overflowButtonType={1}
            overflowButtonProps={{ ariaLabel: 'Ver más' }}
            onRenderPersona={(props) => (
                <Persona {...props} size={PersonaSize.size24} onRenderPrimaryText={() => <></>}></Persona>
            )}
        />
    );
  };

  const formatCreador = (item: any) => {
    let equipo = item.creador;

    var getInitials = function (string: string) {
      var names = string.split(' '),
          initials = names[0].substring(0, 1).toUpperCase();

      if (names.length > 1) {
        initials += names[1].substring(0, 1).toUpperCase();
      }
      return initials;
    };

    let equipoPersonas: IFacepilePersona[] = [
      {
        personaName: equipo,
        imageInitials: getInitials(equipo),
      },
    ];

    return (
        <Facepile
            personas={equipoPersonas}
            personaSize={10}
            maxDisplayablePersonas={4}
            overflowButtonType={1}
            overflowButtonProps={{ ariaLabel: 'Ver más' }}
            onRenderPersona={(props) => (
                <Persona {...props} size={PersonaSize.size24} onRenderPrimaryText={() => <></>}></Persona>
            )}
        />
    );
  };

  const formatCodigo = (item: any) => {
    if (item.pro_estado === 'En proceso') {
      return (
          <span>
          <Icon
              iconName="CircleShapeSolid"
              aria-label="En proceso"
              title={t('En proceso')}
              className="color-inProcess"
          />{' '}
            &nbsp;{item.pro_codigo}
            {item.es_favorito && (
                <Icon iconName="FavoriteStarFill" title={t('Favorito')} className="fav-color text-icon-fav" />
            )}
        </span>
      );
    } else if (item.pro_estado === 'Inactivo') {
      return (
          <span>
          <Icon
              iconName="CircleShapeSolid"
              aria-label="Inactivo"
              title={t('Inactivo')}
              className="color-inactive"
          />{' '}
            &nbsp;{item.pro_codigo}
        </span>
      );
    } else if (item.pro_estado === 'Inicial') {
      return (
          <span>
          <Icon
              iconName="CircleShapeSolid"
              aria-label="Inicial"
              title={t('Inicial')}
              className="color-initial"
          />{' '}
            &nbsp;{item.pro_codigo}
        </span>
      );
    } else {
      return (
          <span>
          <Icon
              iconName="CircleShapeSolid"
              aria-label="Finalizado"
              title={t('Finalizado')}
              className="color-finished"
          />{' '}
            &nbsp;{item.pro_codigo}
        </span>
      );
    }
  };

  const formatVigencia = (item: any) => {
    return (
        <span title={`${item.pro_inicio} - ${item.pro_fin}`}>
        {item.pro_inicio} - {item.pro_fin}
      </span>
    );
  };

  const formatNombre = (item: any) => {
    return <span title={`${item.pro_nombre}`}>{item.pro_nombre}</span>;
  };

  const columns = [
    {
      fieldName: 'pro_codigo',
      name: t('Codigo'),
      maxWidth: 110,
      minWidth: 110,
      _onRender: formatCodigo,
    },
    { fieldName: 'pro_nombre', name: t('Nombre'), minWidth: 150, _onRender: formatNombre },
    {
      fieldName: 'pro_inicio',
      name: t('Vigencia'),
      maxWidth: 100,
      minWidth: 100,
      _onRender: formatVigencia,
    },
    {
      fieldName: 'nor_nombre',
      name: t('Jefatura'),
      // _onRender: formatSector,
      minWidth: 180,
    },
    // { fieldName: 'responsable_nombre', name: t('Responsable') },
    { fieldName: 'empresas', name: t('Empresas') },
    {
      fieldName: 'pro_activo',
      name: t('Activo'),
      _onRender: formatActivo,
      maxWidth: 20,
      minWidth: 20,
    },
    {
      fieldName: 'creador',
      name: t('Creador'),
      _onRender: formatCreador,
      minWidth: 30,
      maxWidth: 30,
    },

    {
      fieldName: 'equipo',
      name: t('Equipo'),
      _onRender: formatEquipo,
      minWidth: 110,
      maxWidth: 110,
    },
  ];

  return (
      <>
        <DetailsListView
            keyList={props.keyList}
            changeBlockUI={props.changeBlockUI}
            onClickCardMode={() => props.onClickCardMode()}
            onClickGridMode={() => props.onClickGridMode()}
            mode={props.mode}
            title={t('Proyectos')}
            onItemClick={props.onItemClick}
            cols={columns}
            isGridList={true}
            listFilters={[]}
            courier={props.courier}
            quickFilters={props.quickFilters}
            baseUrl={props.baseUrl}
        ></DetailsListView>
      </>
  );
};
