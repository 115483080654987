import * as React from 'react';
import { Dialog, DialogType, DialogFooter } from '@fluentui/react/lib/Dialog';
import { PrimaryButton, DefaultButton } from '@fluentui/react/lib/Button';
import { ContextualMenu, IContextualMenuProps } from '@fluentui/react/lib/ContextualMenu';
import { Text, Icon, IconButton, IIconProps, Link, TextField } from '@fluentui/react';
import { useConst } from '@fluentui/react-hooks';
import TimeTaxSupportSystemClient from '../../system';
import MessageCourier from '../../lib/MessageCourier';
import { DateTime } from 'luxon';
import DocumentUploadControl, {
  DocumentUploadControlSaved,
} from '../../system/documents/DocumentUploadControl';
import CollaboratorPersona from '../Collaborator/CollaboratorPersona';
import SystemDocumentType from '../../system/documents/SystemDocumentType';
import { Toggle } from '@fluentui/react';
import { useTranslation, Trans } from 'react-i18next';

interface IUnassignDocumentControlDialog {
  documentName: string;
  onConfirmUnassignDocument: () => Promise<void>;
  show: boolean;
  toggleShowUnassignDocumentControlDialog: () => void;
}

export const UnassignDocumentControlDialog = (props: IUnassignDocumentControlDialog) => {
  const { t, i18n } = useTranslation();
  const dialogContentProps = React.useMemo(() => {
    return {
      type: DialogType.normal,
      title: t('Documentos obligatorios'),
    };
  }, [props]);

  const [docAssignmentConfirmationText, setDocAssignmentConfirmationText] = React.useState('');

  const [errorValidacion, setErrorValidacion] = React.useState('');

  const onChangeDocAssignmentConfirmationText = React.useCallback(
      (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        if (!newValue || newValue.length <= 50) {
          setDocAssignmentConfirmationText(newValue || '');
        }
      },
      []
  );

  const validateConfirmation = () => {
    if (docAssignmentConfirmationText === 'CONFIRMO DESASIGNACION DEL DOCUMENTO') {
      setErrorValidacion('');
    } else {
      setErrorValidacion("Texto de validación debe decir 'CONFIRMO DESASIGNACION DEL DOCUMENTO'");
    }
  };

  const dragOptions = {
    moveMenuItemText: t('Move'),
    closeMenuItemText: t('Close'),
    menu: ContextualMenu,
  };

  const modalProps = {
    isBlocking: true,
    // styles: modalPropsStyles,
    dragOptions: dragOptions,
  };

  return (
      <Dialog
          hidden={!props.show}
          onDismiss={props.toggleShowUnassignDocumentControlDialog}
          dialogContentProps={dialogContentProps}
          modalProps={modalProps}
          minWidth={500}
      >
        <Text variant="mediumPlus">
          {t('Por favor confirme la desasignación de este documento obligatorio de')}{' '}
          <span className="fw-500">{props.documentName}</span>
        </Text>
        <TextField
            value={docAssignmentConfirmationText}
            placeholder="CONFIRMO DESASIGNACION DEL DOCUMENTO"
            onChange={onChangeDocAssignmentConfirmationText}
            onBlur={validateConfirmation}
            errorMessage={errorValidacion}
            className="mt-2"
        />

        <DialogFooter>
          <PrimaryButton
              onClick={() => {
                if (errorValidacion === '') {
                  props.onConfirmUnassignDocument();
                }
              }}
              text={t('Desasignar')}
          />
          <DefaultButton
              onClick={() => {
                props.toggleShowUnassignDocumentControlDialog();
                setDocAssignmentConfirmationText('');
              }}
              text={t('Cancelar')}
          />
        </DialogFooter>
      </Dialog>
  );
};
