import React from 'react';
import { useTranslation } from 'react-i18next';

import { DatePicker } from '@fluentui/react';
import { CallableFn } from './CustomInput';
import ErrorListForInput from './ErrorListForInput';
import CustomInputIfc from './CustomInputIfc';
import { DateTime } from 'luxon';
import { DateCustom } from '../../DateCustom';
import { getLocalizedStrings } from '../../LocalizedStringsForDatePicker';

interface IInputFieldForStartEnd {
  inputStart: CustomInputIfc;
  inputEnd: CustomInputIfc;
  startLabel: string;
  endLabel: string;
  onBlur: (input: CustomInputIfc) => void;
  useTime?: boolean;
}

export const InputFieldForStartEnd = (props: IInputFieldForStartEnd) => {
  const { t, i18n } = useTranslation();

  const [updateFlag, setUpdateFlag] = React.useState(false);

  const onFormatDate = (date?: Date): string => {
    if (!props.useTime) {
      return DateCustom.formatDateForDatePicker(date);
    } else {
      // todo
    }
    return !date ? '' : date.getDate() + '/' + (date.getMonth() + 1) + '/' + (date.getFullYear() % 100);
  };

  let periodInterval = {
    start: props.inputStart.getValue(),
    end: props.inputEnd.getValue(),
  };

  const updateViewAfterDoing = (anAction: CallableFn) => {
    setUpdateFlag((u) => {
      anAction();
      return !u;
    });
  };

  const onChangeCustomInput = (input: CustomInputIfc, value: Date) => {
    updateViewAfterDoing(() => {
      if (input === props.inputStart) {
        periodInterval.start = DateTime.fromJSDate(value);
      } else {
        periodInterval.end = DateTime.fromJSDate(value);
      }
      props.inputStart.setValue(periodInterval.start);
      props.inputEnd.setValue(periodInterval.end);
      onBlurCustomInput();
    });
  };

  const onBlurCustomInput = () => {
    updateViewAfterDoing(() => {
      props.inputStart.onBlur();
      props.inputEnd.onBlur();
      props.onBlur(props.inputStart);
      props.onBlur(props.inputEnd);
    });
  };

  return (
      <>
        <div className="ms-Grid-col ms-sm6">
          <DatePicker
              strings={getLocalizedStrings()}
              label={t(props.startLabel)}
              formatDate={onFormatDate}
              value={props.inputStart.getValue().toJSDate()}
              onSelectDate={(date) => {
                if (date) {
                  onChangeCustomInput(props.inputStart, date);
                }
              }}
          />
          {props.inputStart.hasErrors() && <ErrorListForInput customInput={props.inputStart} />}{' '}
        </div>
        <div className="ms-Grid-col ms-sm6">
          <DatePicker
              strings={getLocalizedStrings()}
              label={t(props.endLabel)}
              formatDate={onFormatDate}
              value={props.inputEnd.getValue().toJSDate()}
              onSelectDate={(date) => {
                if (date) {
                  onChangeCustomInput(props.inputEnd, date);
                }
              }}
          />
          {props.inputEnd.hasErrors() && <ErrorListForInput customInput={props.inputEnd} />}{' '}
        </div>
      </>
  );
};
