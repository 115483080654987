import { DateTime } from 'luxon';
import { Collaborator } from '../collaborators/Collaborator';
import SystemDocumentType from './SystemDocumentType';
import DocumentUploadControl, { IDocumentControl } from './DocumentUploadControl';
import DocumentUploadControlVisitor from '../projects/DocumentUploadControlVisitor';
import {
  ProjectConfigurationDocsForm,
  ProjectDocumentControlForm,
} from '../../Components/Proyectos/ProjectConfiguration/ProjectConfigurationDocsForm';

class DocumentUploadControlPending implements DocumentUploadControl {
  constructor(
      private documentType: SystemDocumentType,
      private responsible: Collaborator,
      private concept: string,
      private limitDate: DateTime
  ) {}

  accept(visitor: DocumentUploadControlVisitor<any>) {
    return visitor.visitDocumentUploadControlPending(this);
  }

  static from(
      documentType: SystemDocumentType,
      responsible: Collaborator,
      concept: string,
      limitDate: DateTime
  ) {
    return new this(documentType, responsible, concept, limitDate);
  }

  toIDocumentControl(): IDocumentControl {
    return {
      ccdp_doctid: this.documentType.getId(),
      ccdp_fecha_limite: String(this.limitDate.toUTC().toISODate()),
      ccdp_responsable_provisorio: this.responsible.getId(),
      ccdp_concepto: this.concept,
    };
  }

  getDocumentType(): SystemDocumentType {
    return this.documentType;
  }

  getCollaborator(): Collaborator {
    return this.responsible;
  }

  getConcept(): string {
    return this.concept;
  }

  getLimitDate(): DateTime {
    return this.limitDate;
  }

  isUploaded(): boolean {
    return false;
  }
  isExpired(): boolean {
    return this.getLimitDate().diffNow().days > 0;
  }
  syncWith(doc: DocumentUploadControl): this {
    this.documentType = doc.getDocumentType();
    this.responsible = doc.getCollaborator();
    this.concept = doc.getConcept();
    this.limitDate = doc.getLimitDate();
    return this;
  }
}

export default DocumentUploadControlPending;
