import * as React from 'react';
import { Text } from '@fluentui/react/lib/Text';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { ICommandBarItemProps } from '@fluentui/react/lib/CommandBar';
import ViewWrapperWithCommandBar from '../../Layout/ViewWrapperWithCommandBar';
import { DefaultPalette, DefaultButton, Icon, PrimaryButton } from '@fluentui/react';
import {
  buttonStylesFor,
  buttonStylesForProjects,
  defaultCommandBarButtonStyles,
  titleCommandBarButtonStyles,
} from '../../../styles/button-styles';
import useSystemCall from '../../../common/custom-hooks/useSystemCall';
import { useState } from 'react';
import TimeTaxSupportSystemClient from '../../../system';
import MessageCourier from '../../../lib/MessageCourier';
import useAsyncData from '../../../common/custom-hooks/useAsyncData';
import { DefenseFile } from '../../../system/defense_file/DefenseFile';
import { DefenseFileState } from '../../../system/defense_file/DefenseFileSystem';
import { routes } from '../../../common/constants';
import { useTranslation, Trans } from 'react-i18next';

// import TimeTaxSupportSystemClient from './system/TimeTaxSupportSystemClient';
// import Environment from './lib/Environment';

interface IArchivosDefensaViewProps {
  system: TimeTaxSupportSystemClient;
  courier: MessageCourier;
  changeBlockUI: (change: boolean) => void;
}

const ArchivosDefensaView = (props: any | IArchivosDefensaViewProps) => {
  const { t, i18n } = useTranslation();
  let { id } = useParams();
  const navigate = useNavigate();

  const [shouldRefreshDefenseFile, setShouldRefreshDefenseFile] = React.useState<boolean>(false);
  const defenseFile: DefenseFile | undefined = useAsyncData(
      () => props.system.defenseFileSystem.getById(id),
      [id, props.system.defenseFileSystem, shouldRefreshDefenseFile]
  );

  const generateStructure = async () => {
    props.changeBlockUI(true);
    try {
      await props.system.defenseFileSystem.generateStructure(defenseFile);
      props.courier.messageSuccess(t('Se ha generado la estructura de reportes y archivos correctamente '));
      setShouldRefreshDefenseFile(!shouldRefreshDefenseFile);
    } catch (error) {
      props.courier.messageError(error);
    }
    props.changeBlockUI(false);
  };

  const generateZip = async () => {
    props.changeBlockUI(true);
    try {
      await props.system.defenseFileSystem.generateZippedFile(defenseFile);
      props.courier.messageSuccess(t('Se ha generado el archivo zip correctamente '));
      setShouldRefreshDefenseFile(!shouldRefreshDefenseFile);
    } catch (error) {
      props.courier.messageError(error);
    }
    props.changeBlockUI(false);
  };

  const downloadZip = async () => {
    props.changeBlockUI(true);
    if (defenseFile) {
      try {
        let uri = await props.system.defenseFileSystem.downloadDefenseFileZipped(defenseFile.getId());
        window.location.assign(uri);
        props.courier.messageSuccess(t('Se ha descargado el archivo zip correctamente '));
        setShouldRefreshDefenseFile(!shouldRefreshDefenseFile);
      } catch (error) {
        props.courier.messageError(error);
      }
    }
    props.changeBlockUI(false);
  };

  // const defenseFileZip = useAsyncData(
  //   () => props.system.defenseFileSystem.generateZippedFile(defenseFile),
  //   [defenseFile, props.system.defenseFileSystem]
  // );

  // const GenerateStructure = useAsyncData(
  //   () => props.system.defenseFileSystem.generateStructure(defenseFile),
  //   [defenseFile, props.system.defenseFileSystem]
  // );

  //   const environment = new Environment();
  // const system = TimeTaxSupportSystemClient.start(environment);

  // const GetDefenseFile = useSystemCall(system, (system) =>
  //   system && id ? system.defenseFileSystem.getById(id) : null
  // );

  // React.useEffect(() => {
  //   setDefenseFile(defenseFile);
  // }, [GetDefenseFile, id]);

  const itemsMenu = React.useMemo(
      (): ICommandBarItemProps[] => [
        {
          key: 'title',
          text: t('Archivo de defensa'),
          iconProps: {
            iconName: 'Shield',
            color: DefaultPalette.themeLighterAlt,
            className: 'text-title-btn',
          },
          buttonStyles: titleCommandBarButtonStyles,
          className: 'text-title-btn',
        },
        {
          key: 'back',
          text: t('Volver'),
          iconProps: {
            iconName: 'Back',
            color: DefaultPalette.themeLighterAlt,
            className: 'text-title-btn',
          },
          buttonStyles: titleCommandBarButtonStyles,
          className: 'text-title-btn',
          onClick: () => {
            navigate(`${routes.defense_file}`);
          },
        },
      ],
      [navigate]
  );

  return (
      <ViewWrapperWithCommandBar
          withBorderBottom
          title={''}
          items={itemsMenu}
          overflowButtonStyles={defaultCommandBarButtonStyles}
          variant="highligth"
          titleBody={t('Archivos de defensa')}
      >
        {defenseFile && (
            <div className="padding-1">
              <div className="ms-Grid padding-1 bg-white" dir="ltr">
                <div className="ms-Grid-row">
                  <div className="ms-Grid-col ms-lg12 ms-sm12">
                    <Text variant="large" className="fw-500">
                      {t('Detalle del archivo de defensa: ') + defenseFile.getId()}
                    </Text>
                  </div>
                </div>
                <div className="ms-Grid-row mt-1">
                  <div className="ms-Grid-col ms-lg12 ms-sm12">
                    <Text variant="medium">
                      <Icon iconName="PublicCalendar" className="text-icon"></Icon> {t('Periodo:')}{' '}
                      {defenseFile.getFrom().toFormat('dd/LL/yyyy')} -{' '}
                      {defenseFile.getTo().toFormat('dd/LL/yyyy')}{' '}
                    </Text>
                  </div>
                </div>

                <div className="ms-Grid-row mt-1">
                  <div className="ms-Grid-col ms-lg12 ms-sm12">
                    <Text variant="medium">
                      <Icon iconName="CityNext2" className="text-icon"></Icon> {t('Empresa:')}{' '}
                      {defenseFile.getCompany().getName()}
                    </Text>
                  </div>
                </div>

                <div className="ms-Grid-row mt-1">
                  <div className="ms-Grid-col ms-lg12 ms-sm12">
                    {defenseFile.isProjectType() ? (
                        <Text variant="medium">
                          <Icon iconName={'TeamWork'} className="text-icon"></Icon> {t('Tipo: Proyecto')}
                        </Text>
                    ) : (
                        <Text variant="medium">
                          <Icon iconName={'TableComputed'} className="text-icon"></Icon>{' '}
                          {t('Tipo: Liquidacion indirecta')}
                        </Text>
                    )}
                  </div>
                </div>
                <div className="ms-Grid-row mt-1">
                  <div className="ms-Grid-col ms-lg12 ms-sm12">
                    {defenseFile.getIncludesActivities() ? (
                        <Text variant="medium">
                          <Icon iconName="CheckMark" className="success-color text-icon"></Icon>{' '}
                          {t('Incluye actividades')}
                        </Text>
                    ) : (
                        <Text variant="medium">
                          <Icon iconName="Cancel" className="error-color text-icon"></Icon>{' '}
                          {t('No incluye actividades')}
                        </Text>
                    )}
                  </div>
                </div>
                <div className="ms-Grid-row mt-1">
                  <div className="ms-Grid-col ms-lg12 ms-sm12">
                    <Text variant="medium">
                      <Icon iconName="Market" className="text-icon"></Icon> {t('Estado:')}{' '}
                      {defenseFile.getStatusTitle()}
                    </Text>
                  </div>
                </div>
                <div className="ms-Grid-row mt-1">
                  <div className="ms-Grid-col ms-lg12 ms-sm12">
                    <Text variant="medium">
                      <Icon iconName="Upload" className="text-icon"></Icon> {t('Cargado el:')}{' '}
                      {defenseFile.getUploadedOn().toFormat('dd/LL/yyyy')}
                    </Text>
                  </div>
                </div>
                <div className="ms-Grid-row mt-1">
                  <div className="ms-Grid-col ms-lg12 ms-sm12">
                    <Text variant="medium">
                      <Icon iconName="ReminderPerson" className="text-icon"></Icon> {t('Cargado por:')}{' '}
                      {defenseFile.getUploadedBy().fullName()}
                    </Text>
                  </div>
                </div>

                <div className="ms-Grid-row mt-2 ">
                  <div className="ms-Grid-col ms-lg12 ms-sm12">
                    {defenseFile.getState() === DefenseFileState.pendingStructure && (
                        <PrimaryButton
                            iconProps={{ iconName: 'ReportLibraryMirrored', color: DefaultPalette.themeLighterAlt }}
                            onClick={() => generateStructure()}
                        >
                          {' '}
                          {t('Generar estructura')}
                        </PrimaryButton>
                    )}
                    {defenseFile.getState() === DefenseFileState.pendingZip && (
                        <PrimaryButton
                            iconProps={{
                              className: 'mr-1',
                              iconName: 'ZipFolder',
                              color: DefaultPalette.themeLighterAlt,
                            }}
                            onClick={() => generateZip()}
                        >
                          {t('Generar Zip')}
                        </PrimaryButton>
                    )}
                    {defenseFile.getState() === DefenseFileState.zipGenerated && (
                        <PrimaryButton
                            iconProps={{
                              className: 'mr-1',
                              iconName: 'ZipFolder',
                              color: DefaultPalette.themeLighterAlt,
                            }}
                            onClick={() => downloadZip()}
                        >
                          {t('Descargar Zip')}
                        </PrimaryButton>
                    )}
                  </div>
                </div>
              </div>
            </div>
        )}
      </ViewWrapperWithCommandBar>
  );
};

export default ArchivosDefensaView;
