import React from 'react';
import Dialog, { DialogFooter } from '@fluentui/react/lib/Dialog';
import { TextField } from '@fluentui/react/lib/TextField';
import { DefaultButton, PrimaryButton } from '@fluentui/react/lib/Button';
import { useTranslation, Trans } from 'react-i18next';

interface ISimpleFolderChangeDialog {
  hidden: boolean;
  text: string;
  initialValue?: string;
  onAccept: (value: string) => void;
  onCancel: () => void;
}

const SimpleFolderChangeDialog: React.FC<ISimpleFolderChangeDialog> = (props) => {
  const { t, i18n } = useTranslation();
  const [value, setValue] = React.useState<string>('');

  React.useEffect(() => {
    if (props.initialValue !== undefined) {
      setValue(props.initialValue);
    }
  }, [props.initialValue]);

  const onSubmit = () => {
    props.onAccept(value);
    setValue('');
  };

  return (
      <Dialog hidden={props.hidden} onDismiss={props.onCancel}>
        <div>{props.text}</div>
        <form
            onSubmit={(e) => {
              e.preventDefault();
              onSubmit();
            }}
        >
          <div>
            <TextField label="" required value={value} onChange={(e, newValue) => setValue(newValue || '')} />
          </div>
          <DialogFooter>
            <PrimaryButton
                // onClick={() => {
                //   props.onAccept(value);
                //   setValue('');
                // }}
                disabled={!value}
                text={t('Aceptar')}
                type="submit"
            />
            <DefaultButton onClick={props.onCancel} text={t('Cancelar')} />
          </DialogFooter>
        </form>
      </Dialog>
  );
};

export default SimpleFolderChangeDialog;
