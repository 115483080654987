import AssertionEntity from '../../lib/FormValidator/Assertion/AssertionEntity';
import AssertionItem from '../../lib/FormValidator/Assertion/AssertionItem';
import CollaboratorTeam from '../collaborators/collaborator_team/CollaboratorTeam';
import { ITeam } from './ProjectTeam';

export class ProjectTeamAssertions extends AssertionEntity {
  private constructor() {
    super();
  }

  static build() {
    const instance = new this();
    instance.addAssertionForCollaboratorTeamList();
    instance.addAssertionForCollaboratorResponsible();
    return instance;
  }

  addAssertionForCollaboratorTeamList(key = 'collaboratorTeamList') {
    this.defineMultipleAssertionsForProperty(key, [
      AssertionItem.forClosure(
          (collaboratorTeamList: CollaboratorTeam[]) => {
            return collaboratorTeamList.filter((col) => !col.get__delete()).length !== 0;
          },
          key,
          'Empty team is not allowed'
      ),
      AssertionItem.forClosure(
          (collaboratorTeamList: CollaboratorTeam[]) => {
            return collaboratorTeamList.every((collaborator: CollaboratorTeam) =>
                collaboratorTeamList.every(
                    (anotherCollaborator: CollaboratorTeam) =>
                        collaborator === anotherCollaborator ||
                        !collaborator.isForSameCollaboratorAs(anotherCollaborator)
                )
            );
          },
          key,
          'Repeated collaborators are not allowed'
      ),
    ]);
  }

  addAssertionForCollaboratorResponsible() {
    this.defineAssertionForObject(
        AssertionItem.forClosure(
            (team: ITeam) => {
              return team.collaboratorTeamList.some((col) => col.collaboratorIs(team.collaboratorResponsible));
            },
            '',
            'The team leader must be a member of the team'
        )
    );
  }
}
