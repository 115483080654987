import { DateTime } from 'luxon';
import { Collaborator } from '../collaborators/Collaborator';
import SystemDocumentType from './SystemDocumentType';
import DocumentUploadControlPending from './DocumentUploadControlPending';
import DocumentUploadControl, {
  DocumentUploadControlSaved,
  IDocumentControlSaved,
} from './DocumentUploadControl';
import DocumentUploadControlVisitor from '../projects/DocumentUploadControlVisitor';

class DocumentUploadControlPendingSaved implements DocumentUploadControlSaved {
  private constructor(private id: string, private control: DocumentUploadControlPending) {}

  static from(id: string, control: DocumentUploadControlPending) {
    return new this(id, control);
  }

  toIDocumentControl(): IDocumentControlSaved {
    const data = this.control.toIDocumentControl();
    return { ...data, ccdp_id: this.id };
  }

  getId(): string {
    return this.id;
  }

  accept(visitor: DocumentUploadControlVisitor): any {
    return visitor.visitDocumentUploadControlPendingSaved(this);
  }

  getDocumentType(): SystemDocumentType {
    return this.control.getDocumentType();
  }

  getCollaborator(): Collaborator {
    return this.control.getCollaborator();
  }

  getConcept(): string {
    return this.control.getConcept();
  }

  getLimitDate(): DateTime {
    return this.control.getLimitDate();
  }

  setControl(control: DocumentUploadControlPending) {
    this.control = control;
  }
  isUploaded(): boolean {
    return false;
  }
  isExpired(): boolean {
    return this.getLimitDate().diffNow().days > 0;
  }

  syncWith(doc: DocumentUploadControl): this {
    this.control.syncWith(doc);
    return this;
  }
}

export default DocumentUploadControlPendingSaved;
