import SystemClient from '../../system';
import useAsyncData from './useAsyncData';

type SystemDataPromise<T> = (sys: SystemClient) => Promise<T>;

function useSystemCall<T>(system: SystemClient, dataPromise: SystemDataPromise<T>) {
  // TODO: usar un provider y no recibir el system
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useAsyncData(() => dataPromise(system), [system]);
}

export default useSystemCall;
