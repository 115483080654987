export class DefenseFileBySettlementUploadedDocument {
  private constructor(private id: string, private remotePath: string, private description: string) {}

  static from(id: string, remotePath: string, description: string) {
    return new this(id, remotePath, description);
  }

  getId(): string {
    return this.id;
  }

  getDescription(): string{
    return this.description
  }
}
