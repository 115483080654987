import React from 'react';
import { Text } from '@fluentui/react/lib/Text';
import i18next, { t } from 'i18next';

import { Toggle, Dropdown, TextField, Icon, DatePicker } from '@fluentui/react';

interface IProjectStatus {
  status: string;
  textVariant?: string;
}
export const ProjectStatus = (props: IProjectStatus) => {
  const getClassNameByStatus = () => {
    if (props.status === 'En proceso') return 'text-icon color-inProcess';
    if (props.status === 'Provisoria') return 'text-icon color-inProcess';
    if (props.status === 'Rechazada') return 'text-icon color-inactive';
    if (props.status === 'Inactivo') return 'text-icon color-inactive';
    if (props.status === 'Finalizado') return 'text-icon color-initial';
    return 'text-icon color-finished';
  };

  return (
      <>
        <Text>
          <Icon
              iconName="CircleShapeSolid"
              aria-label={props.status}
              title={t(props.status)}
              className={getClassNameByStatus()}
          />
          {t(props.status)}
        </Text>
      </>
  );
};
