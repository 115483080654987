import * as React from 'react';
import { IChartProps, ILineChartPoints, AreaChart } from '@uifabric/charting';
import { DefaultPalette } from '@fluentui/react/lib/Styling';
import { IQuantityByWeek } from '../../../system/projects/ProjectsSystem';
import { Text } from '@fluentui/react';

interface IProjectMiniPanelChartProps {
  data: IQuantityByWeek[];
  title: string;
  color?: string;
}

const ProjectMiniPanelChart = (props: IProjectMiniPanelChartProps) => {
  const generateData = React.useCallback(() => {
    return props.data.map((quantity) => {
      let ret = {
        x: new Date(quantity.start),
        y: quantity.qty,
      };
      return ret;
    });
  }, [props.data]);

  const MaxValue = React.useMemo(() => props.data.reduce((p, d) => (d.qty > p ? d.qty : p), 0), [props]);

  const data = React.useMemo((): IChartProps => {
    const points: ILineChartPoints[] = [
      { data: generateData(), legend: props.title, color: props.color || DefaultPalette.blue },
    ];
    return {
      chartTitle: props.title,
      lineChartData: points,
    };
  }, [props, generateData]);

  const rootStyle = {
    width: `100%`,
    height: `${100}px`,
    // margin: 'auto',
  };

  const total = React.useMemo(() => props.data.reduce((previous, d) => previous + d.qty, 0), [props]);
  const totalMessage = React.useMemo(() => `${total} ${props.title.toLocaleLowerCase()}`, [props, total]);

  return (
      <>
        {props.data.length > 0 ? (
            <div style={rootStyle}>
              <div className="ms-Grid" dir="ltr" style={{display: 'block'}}>
                <div className="ms-Grid-row">
                  <div className="ms-Grid-col ms-sm12">
                    <Text className="fs-for-minipanel-chart" nowrap block title={totalMessage}>
                      {totalMessage}
                    </Text>
                  </div>
                </div>
              </div>
              <AreaChart
                  data={data}
                  strokeWidth={2}
                  yMaxValue={MaxValue ? MaxValue : 0}
                  hideLegend={true}
                  showXAxisLablesTooltip={false}
                  hideTooltip={false}
                  svgProps={{ 'aria-hidden': 'true' }}
                  margins={{ top: 20, bottom: 25, left: 30, right: 10 }}
                  tickFormat={'%d/%m'}
                  tickValues={[new Date(props.data[0].start), new Date(props.data[props.data.length - 1].start)]}
              />
            </div>
        ) : (
            <div style={rootStyle} className="mt-4">
              <Text className="fw-500" variant="small">
                No hay datos
              </Text>
            </div>
        )}
      </>
  );
};

export default ProjectMiniPanelChart;
