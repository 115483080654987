import React from 'react';
import { DefaultButton } from '@fluentui/react/lib/Button';
import { IPivotStyleProps, IPivotStyles, IStyleFunctionOrObject, Pivot, PivotItem } from '@fluentui/react';
import { Text } from '@fluentui/react/lib/Text';
import i18next, { t } from 'i18next';

interface WizardProps {
  componentsArray: Array<any>;
  componentsDescriptions?: Array<string>;
  componentsTitlesArray: Array<string>;
  canGoNext: boolean;
  doSomethingOnNextStep?: number; //El número de paso en el que se ejecuta determinada acción al tocar siguiente. Empezando desde 1
  onNextClick?: any;
}

const Wizard = (props: WizardProps) => {
  const [selectedKey, setSelectedKey] = React.useState(0);
  const onNextClick = () => {
    setSelectedKey((selectedKey + 1) % props.componentsArray.length);
    if (props.doSomethingOnNextStep && selectedKey === props.doSomethingOnNextStep - 1) {
      props.onNextClick();
    }
  };

  const onPreviousClick = () => {
    if (selectedKey > 0) {
      setSelectedKey((selectedKey - 1) % props.componentsArray.length);
    }
  };

  const handleLinkClick = (item?: PivotItem) => {
    if (
        item &&
        (selectedKey > parseInt(item.props.itemKey!) ||
            (selectedKey < parseInt(item.props.itemKey!) &&
                props.canGoNext &&
                selectedKey + 1 === parseInt(item.props.itemKey!)))
    ) {
      setSelectedKey(parseInt(item.props.itemKey!));
    }
  };

  const Steps = () => {
    if (props.componentsArray) {
      return props.componentsArray.map((component: any, i: number) => {
        return (
            <PivotItem
                headerButtonProps={{
                  className: 'BUTTON-PIVOT-DEFENSE-FILE',
                }}
                headerText={props.componentsTitlesArray[i]}
                itemKey={i.toString()}
                onClick={() => setSelectedKey(i)}
                onRenderItemLink={() => <div className="pivotItem">{props.componentsTitlesArray[i]}</div>}
            >
              <>{component}</>
            </PivotItem>
        );
      });
    }
  };

  const description = (idx: number) => {
    if (props.componentsDescriptions) {
      return props.componentsDescriptions[idx];
    }
  };

  const pivotStyles: IStyleFunctionOrObject<IPivotStyleProps, IPivotStyles> = {
    link: {
      backgroundColor: '#f3f2f1',
      fontSize: 16,
    },
    linkIsSelected: {
      selectors: {
        ':before': {
          height: '6px', // was previously defaulted at 2px
        },
      },
    },
  };

  return (
      <div className="padding-1">
        <div className="ms-Grid" dir="ltr">
          <div className="ms-Grid-row">
            <div className="ms-Grid-col ms-sm12">
              <Pivot
                  className="defense-pivot"
                  overflowBehavior={'menu'}
                  aria-label="Override Selected Item Pivot Example"
                  selectedKey={String(selectedKey)}
                  onLinkClick={handleLinkClick}
                  styles={pivotStyles}
                  linkFormat="tabs"
                  linkSize={'large'}
                  overflowButtonAs={(props) => (
                      <div className="mt-1">
                        {' '}
                        <Text className="description-defense-file">{description(selectedKey)}</Text>
                      </div>
                  )}
                  // onRenderItemLink={()=>(<div>test</div>)}
              >
                {Steps()}
                {/* <PivotItem headerText={'Final'} itemKey={props.componentsArray.length.toString()}>
          <>{props.lastComponent}</>
        </PivotItem> */}
              </Pivot>
              {/* eslint-disable-next-line react/jsx-no-bind */}
              {props.componentsArray && selectedKey < props.componentsArray.length - 1 ? (
                  <DefaultButton className="wizard-btn" onClick={onNextClick} disabled={!props.canGoNext}>
                    {t('Siguiente')}
                  </DefaultButton>
              ) : null}
              {selectedKey > 0 ? (
                  <DefaultButton className="wizard-btn" onClick={onPreviousClick}>
                    {t('Anterior')}
                  </DefaultButton>
              ) : null}
            </div>
          </div>
        </div>
      </div>
  );
};

export default Wizard;
