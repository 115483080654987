import { FontWeights, ITextStyles, mergeStyles, DefaultPalette } from '@fluentui/react';

export const textBoldStyle = { fontWeight: FontWeights.semibold };

export const boldStyle: Partial<ITextStyles> = {
  root: textBoldStyle,
};

export const iconClass = mergeStyles({
  fontSize: 20,
  height: 20,
  width: 20,
  margin: '0px 10px 0px 0px',
});

export const textBeforeIconStyle: Partial<ITextStyles> = {
  root: { margin: '0px 0px 5px 0px' },
};

export const colorTTSStyle: Partial<ITextStyles> = {
  root: { color: DefaultPalette.themePrimary },
};

export const gridClass = mergeStyles({
  width: '100%',
});

export const rowAlignToLeftClass = mergeStyles({
  textAlign: 'left',
  marginBottom: '10px !important',
});
