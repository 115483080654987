import React from 'react';
import ErrorListForInput from './ErrorListForInput';
import { TextField } from '@fluentui/react';
import { useTranslation, Trans } from 'react-i18next';
import CustomForm from './CustomForm';
import CustomInput, { CallableFn } from './CustomInput';
import CustomInputIfc from './CustomInputIfc';
import { ITextFieldProps } from 'office-ui-fabric-react/lib/TextField';

interface IInputField {
  input: CustomInputIfc;
  onChangeExtended?: (input: CustomInputIfc) => void;
  onBlur: (input: CustomInputIfc) => void;
  label: string;
  propsInput?: ITextFieldProps;
  readOnly?: boolean;
}

const InputField = (props: IInputField) => {
  const { t, i18n } = useTranslation();

  const [updateFlag, setUpdateFlag] = React.useState(false);

  const updateViewAfterDoing = (anAction: CallableFn) => {
    setUpdateFlag((u) => {
      anAction();
      return !u;
    });
  };

  const onChangeCustomInput = (val: any) => {
    updateViewAfterDoing(() => {
      props.input.setValue(val);
    });
    if (props.onChangeExtended) {
      props.onChangeExtended(props.input);
    }
  };

  const onBlurCustomInput = () => {
    updateViewAfterDoing(() => {
      props.input.onBlur();
      props.onBlur(props.input);
    });
  };

  return (
      <>
        <TextField
            label={t(props.label)}
            name={props.input.getName()}
            value={props.input.getValue()}
            onChange={(ev) => onChangeCustomInput(ev.currentTarget.value)}
            onBlur={(ev) => onBlurCustomInput()}
            readOnly={props.readOnly}
            {...props.propsInput}
        />
        {props.input.hasErrors() && <ErrorListForInput customInput={props.input} />}
      </>
  );
};

export default InputField;
