import { Icon, Text } from '@fluentui/react';
import React from 'react';
import DialogTemplate from '../Common/DialogTemplate';
import { useTranslation, Trans } from 'react-i18next';

export interface CalendarNoProjectsDialogProps {
  show: boolean;
  onClose: (accept: boolean) => void;
}

export const CalendarNoProjectsDialog = (props: CalendarNoProjectsDialogProps) => {
  const { t, i18n } = useTranslation();

  const cancel = () => {
    props.onClose(false);
  };

  const accept = () => {
    props.onClose(true);
  };

  return (
      <DialogTemplate
          showDialog={props.show}
          toggleShowDialog={() => props.onClose(false)}
          title={t('No participas en ningun proyecto ')}
          onAccept={accept}
          onCancel={cancel}
          acceptText={t('Ir a crear proyecto')}
          cancelText={t('Ver calendario')}
          minWidth={600}
      >
        <div>
          <Trans>
            <Text block variant="medium">
              <Icon iconName="AlertSolid" className="text-icon" />
              {t('No participas en ningun proyecto, por lo tanto no puedes crear actividades')}
            </Text>
          </Trans>
        </div>
      </DialogTemplate>
  );
};
