import * as React from 'react';
import { DefaultPalette } from '@fluentui/react/lib/Styling';
import useAsyncDataCourier from '../../../common/custom-hooks/useAsyncDataCourier';
import TimeTaxSupportSystemClient from '../../../system';
import MessageCourier from '../../../lib/MessageCourier';
import PanelProjectsBarChart from '../PanelProjectsBarChart';
import { Icon, PrimaryButton, Text } from 'office-ui-fabric-react';
import { PanelCompaniesSelector } from './PanelCompaniesSelector';
import { DateTime } from 'luxon';
import { ICommandBarItemProps } from '@fluentui/react';
import { buttonStylesForProjects, defaultCommandBarButtonStyles } from '../../../styles/button-styles';

import { useNavigate } from 'react-router-dom';

import { routes } from '../../../common/constants';
import ViewWrapperWithCommandBar from '../../Layout/ViewWrapperWithCommandBar';
import { DateCustom } from '../../../lib/DateCustom';
import BasicChartPad from '../../Common/BasicChartPad';
import PanelDonutChart from '../PanelCollaborators/PanelDonutChart';
import PanelCompaniesAreaChart from './PanelCompaniesAreaChart';
import { User } from '../../../system/User';
import { useTranslation, Trans } from 'react-i18next';
import {
  ICollaboratorsSummary,
  IPanelCompanySummary,
  IPanelProjectSummary,
} from '../../../system/panel/PanelSystem';
import AmountByConceptExporter from '../ExcelExport/AmountByConceptExporter';
import { PillFluent } from '../../Common/PillFluent';

interface ICompanyPanel {
  system: TimeTaxSupportSystemClient;
  courier: MessageCourier;
  changeBlockUI: (change: boolean) => void;
  user: User;
  useAllSector?: boolean;
}

export const PanelCompanies = (props: ICompanyPanel) => {
  const { t, i18n } = useTranslation();
  const [organizationNodeId, setOrganizationNodeId] = React.useState<string | undefined>(undefined);
  const [companyId, setCompanyId] = React.useState<string[]>([]);
  const [fromDate, setFromDate] = React.useState<DateTime | undefined>(undefined);
  const [toDate, setToDate] = React.useState<DateTime | undefined>(undefined);
  const navigate = useNavigate();

  const [hoursPerCompaniesSummary, setHoursPerCompaniesSummary] = React.useState<IPanelCompanySummary[]>([]);
  const [collaboratorsSummary, setCollaboratorsSummary] = React.useState<ICollaboratorsSummary | undefined>(
      undefined
  );

  const getPanelsData = async () => {
    if (fromDate && toDate && organizationNodeId) {
      props.changeBlockUI(true);
      const _hoursPerCompanies = await props.system
          .getPanelSystem()
          .getHoursPerCompany(fromDate, toDate, companyId);
      setHoursPerCompaniesSummary(_hoursPerCompanies);

      const _collaboratorsSummary = await props.system
          .getPanelSystem()
          .getCollaboratorsSummary(fromDate, toDate, organizationNodeId);
      setCollaboratorsSummary(_collaboratorsSummary);
      props.changeBlockUI(false);
    }
  };

  const onClickSearch = async () => {
    getPanelsData();
  };

  // React.useEffect(() => {
  //   getPanelsData();
  // }, [props.system, fromDate, toDate, companyId, organizationNodeId]);

  const setSelectedOrganizationNodeId = (id: string) => {
    setOrganizationNodeId(id);
  };

  const setSelectedCompanyId = (companies: string[]) => {
    setCompanyId(companies);
  };

  const setFrom = (date: DateTime) => {
    setFromDate(date);
  };

  const setTo = (date: DateTime) => {
    setToDate(date);
  };

  const items = React.useMemo(
      (): ICommandBarItemProps[] => [
        {
          key: 'project',
          text: t('Mi Panel'),
          onClick: () => navigate(`${routes.dashboard_own}`),
          itemProps: {},
          iconProps: { iconName: 'BIDashboard' },
          buttonStyles: defaultCommandBarButtonStyles,
        },
        {
          key: 'download',
          text: t('Avance de proyectos'),
          onClick: () => navigate(`${routes.dashboard}`),
          iconProps: { iconName: 'TeamWork' },
          buttonStyles: defaultCommandBarButtonStyles,
        },
        {
          key: 'panel_companies',
          text: t('Empresas'),
          onClick: () => navigate(`${routes.dashboard_empresas}`),
          iconProps: { iconName: 'CityNext2' },
          buttonStyles: buttonStylesForProjects,
        },
        {
          key: 'colabs',
          text: t('Colaboradores'),
          onClick: () => navigate(`${routes.dashboard_colaboradores}`),
          iconProps: { iconName: 'ReminderPerson' },
          buttonStyles: defaultCommandBarButtonStyles,
        },
      ],
      [navigate]
  );

  const getTotalProjectHs = () => {
    let hs = 0;
    collaboratorsSummary?.hoursForProjects.forEach((element) => {
      hs += element.amount;
    });
    return hs;
  };

  return (
      <ViewWrapperWithCommandBar
          withBorderBottom
          title={''}
          items={items}
          overflowButtonStyles={defaultCommandBarButtonStyles}
          // overflowItems={overflowItems}
          variant="highligth"
          titleBody={t('Panel')}
      >
        <div className="ms-Grid padding-1" dir="ltr">
          <PanelCompaniesSelector
              user={props.user}
              system={props.system}
              courier={props.courier}
              onChangeCompanyId={setSelectedCompanyId}
              onChangeOrganizationNodeId={setSelectedOrganizationNodeId}
              onChangeFromDate={setFrom}
              onChangeToDate={setTo}
              onClickSearch={onClickSearch}
              useAllSector={true}
          />
          <hr></hr>

          {collaboratorsSummary && collaboratorsSummary.hoursForProjects.length > 0 && (
              <>
                <div className="ms-Grid-row">
                  <div className="ms-Grid-col ms-sm12">
                    <PrimaryButton
                        text={t('Exportar')}
                        iconProps={{ iconName: 'ExcelLogo' }}
                        className="float-right ml-2"
                        menuProps={{
                          items: [
                            // {
                            //   key: 'exportSector',
                            //   text: 'Horas por sector',
                            //   onClick: () =>
                            //     AmountByConceptExporter.exportFor(
                            //       collaboratorsSummary,
                            //       ['Sector', 'Horas'],
                            //       'Horas por sector'
                            //     ),
                            // },
                            {
                              key: 'exportSector',
                              text: t('Horas por sector'),
                              onClick: () =>
                                  AmountByConceptExporter.exportFor(
                                      collaboratorsSummary.hoursForLastLevelSector,
                                      ['Sector', 'Horas'],
                                      'Horas por sector'
                                  ),
                            },
                            {
                              key: 'exportProject',
                              text: t('Horas por proyecto'),
                              onClick: () =>
                                  AmountByConceptExporter.exportFor(
                                      collaboratorsSummary.hoursForProjects,
                                      ['Proyecto', 'Horas'],
                                      'Horas por proyecto'
                                  ),
                            },
                            {
                              key: 'exportCompanies',
                              text: t('Horas por empresa'),
                              onClick: () =>
                                  AmountByConceptExporter.exportFor(
                                      collaboratorsSummary.hoursForCompanies,
                                      ['Empresa', 'Horas'],
                                      'Horas por empresa'
                                  ),
                            },
                          ],
                        }}
                    />
                  </div>
                </div>
                <div
                    className="ms-Grid-row"
                    style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                >
                  <div className="ms-Grid-col ms-xl8 ms-xxl4">
                    {collaboratorsSummary.hoursForCompanies.length > 0 ? (
                        <PanelDonutChart
                            data={collaboratorsSummary.hoursForCompanies}
                            iconName={'AddGroup'}
                            titleChart={t('Horas por empresa')}
                        ></PanelDonutChart>
                    ) : (
                        <div className="text-center mt-2">
                          <Text variant="medium" className="fw-500">
                            <Icon iconName="AddGroup" className="text-icon"></Icon>
                            {t('No hay horas registradas en areas para los filtros seleccionados')}
                          </Text>
                        </div>
                    )}
                  </div>
                  <div className="ms-Grid-col ms-xl8 ms-xxl4">
                    {collaboratorsSummary.hoursForLastLevelSector.length > 0 ? (
                        <PanelDonutChart
                            data={collaboratorsSummary.hoursForLastLevelSector}
                            iconName={'AddGroup'}
                            titleChart={t('Horas por areas')}
                        ></PanelDonutChart>
                    ) : (
                        <div className="text-center mt-2">
                          <Text variant="medium" className="fw-500">
                            <Icon iconName="AddGroup" className="text-icon"></Icon>
                            {t('No hay horas registradas en areas para los filtros seleccionados')}
                          </Text>
                        </div>
                    )}
                  </div>
                  <div className="ms-Grid-col ms-sm4">
                    {/* <BasicChartPad
                  previewText={getTotalProjectHs().toFixed(2)}
                  mainText={t('Hs / Proyecto')}
                  secondaryText={getTotalProjectHs().toFixed(2) + t(' horas totales registradas')}
                  iconName="Clock"
                /> */}
                    <PillFluent
                        title={t('Hs / Proyecto')}
                        iconTitle={'Clock'}
                        series={[{ label: t('Horas totales'), value: getTotalProjectHs().toFixed(2), icon: 'Clock' }]}
                        small={false}
                    ></PillFluent>
                  </div>
                </div>
              </>
          )}
          {collaboratorsSummary && collaboratorsSummary.hoursForProjects.length > 0 && (
              <PanelProjectsBarChart
                  color={'#564aa3'}
                  data={collaboratorsSummary.hoursForProjects}
                  concept={t('Proyectos')}
                  iconName={'AccountActivity'}
                  titleChart={t('Proyectos')}
              ></PanelProjectsBarChart>
          )}
          {hoursPerCompaniesSummary && hoursPerCompaniesSummary.length > 0 ? (
              <PanelCompaniesAreaChart
                  data={hoursPerCompaniesSummary}
                  title={t('Horas por empresa')}
                  color={DefaultPalette.blueLight}
                  iconName={'Clock'}
              />
          ) : (
              <div className="text-center mt-2">
                <Text variant="medium" className="fw-500">
                  <Icon iconName="Clock" className="text-icon"></Icon>
                  {t('No hay horas registradas en empresas para los filtros seleccionados')}
                </Text>
              </div>
          )}
        </div>
      </ViewWrapperWithCommandBar>
  );
};
