import Utils from '../Common/Utils';

class TTSEvent {
  constructor() {}

  isNewEvent = (event: any /*ActividadEvent*/) => {
    return (
        event.extendedProps &&
        event.extendedProps.data &&
        (!event.extendedProps.data.id || isNaN(+event.extendedProps.data.id)) &&
        (!event.extendedProps.data.fromOutlook ||
            (event.extendedProps.data.fromOutlook && event.extendedProps.data.isNewFromOutlook)) &&
        !event.extendedProps.data.isLicenseDay &&
        !event.extendedProps.data.isNonWorkingDay
    );
  };

  isLicenseEvent = (event: any /*ActividadEvent*/) => {
    return event.extendedProps.data.isLicenseDay;
  };

  isNewEventFromOutlook = (event: any /*ActividadEvent*/) => {
    return event.extendedProps.data.fromOutlook && event.extendedProps.data.isNewFromOutlook;
  };

  getDuraciones = () => {
    let durationOptions: any[] = [];
    let duracion = 15;
    while (duracion <= 60 * 8) {
      durationOptions.push({ key: duracion, text: Utils.getLabelForDuracion(duracion) });
      duracion += 15;
    }
    return durationOptions;
  };

  private roundMinutes = (date: Date) => {
    let esPasadaMediaHora = Math.round(date.getMinutes() / 60);

    date.setHours(date.getHours());

    if (esPasadaMediaHora === 1) {
      date.setMinutes(30, 0, 0);
    } else {
      date.setMinutes(0, 0, 0);
    }
    return date;
  };

  roundHoraInicial = () => {
    var date = new Date(); // 4:55
    return this.roundMinutes(date); // 5:00
  };
}

export default TTSEvent;
