import React from 'react';
import { Icon } from '@fluentui/react/lib/Icon';
import { getFileTypeIconProps } from '@fluentui/react-file-type-icons';

interface IDocumentContentTypeIconProps {
  name: string;
}

export class DocumentContentTypeIcon extends React.Component<IDocumentContentTypeIconProps> {
  render() {
    const { name } = this.props;
    if (!name) return null;
    const extension = name.split('.').pop();

    return (
        <Icon
            {...getFileTypeIconProps({ extension: extension, size: 16 })}
            styles={{ root: { position: 'relative', top: '4px' } }}
        />
    );
  }
}
