import React from 'react';
import {
  DetailsList,
  Text,
  DefaultPalette,
  SelectionMode,
  DetailsListLayoutMode,
  IColumn,
  Icon,
  Toggle,
  DetailsRow,
  IDetailsListProps,
  DefaultButton,
} from '@fluentui/react';

import { ICommandBarItemProps } from '@fluentui/react/lib/CommandBar';

import ViewWrapperWithCommandBar from '../../Layout/ViewWrapperWithCommandBar';
import { titleCommandBarButtonStyles } from '../../../styles/button-styles';

import TimeTaxSupportSystemClient from '../../../system';
import MessageCourier from '../../../lib/MessageCourier';
import PlainFileDropzone from '../../FileDropzone/PlainFileDropzone';
import * as XLSX from 'xlsx';
import { DateTime } from 'luxon';
import useAsyncDataCourier from '../../../common/custom-hooks/useAsyncDataCourier';
import { User } from '../../../system/User';
import { Collaborator } from '../../../system/collaborators/Collaborator';
import { PrimaryButton } from 'office-ui-fabric-react';
import useSystemCall from '../../../common/custom-hooks/useSystemCall';
import CollaboratorSelectorList from '../../Collaborator/CollaboratorSelectorList';
// import { EditActivityAssistantDialog } from './EditActivityAssistantDialog';
import Project from '../../../system/projects/Project';
import { useTranslation } from 'react-i18next';

interface IUploadTemplate {
  system: TimeTaxSupportSystemClient;
  courier: MessageCourier;
  user: User;
  changeBlockUI: (change: boolean) => void;
  month: string;
  year: string;
  setButtonDisability: (boolean: boolean) => void;
  getDataFromUploadTemplate: (list: any) => void;
  openModal: () => void;
}

interface ActivityFromExcelForm {
  date: string;
  timeRange: string;
  hours: string;
  name: string;
  projectName: string;
  projectCode: string;
  [index: string]: any;
}

export interface CostPerHourFromExcelFormToPost {
  remun_sap_id: string;
  remun_mes: string;
  remun_anio: string;
  remun_costo_hora: string;
  remun_coef_valor_agr: string;
  remun_nombre_valor_agr: 'AVA' | 'BVA';
}

interface MassiveRemunResponse {
  cant_error: number;
  cant_ok: number;
  error: any[];
}

export const UploadTemplate = (props: IUploadTemplate) => {
  const { t, i18n } = useTranslation();
  const KEY_COD_SAP = 0;
  const KEY_COST_HORA = 1;
  const KEY_COEF_VAL_AGR = 2;
  const KEY_NOMBRE_COEF_VAL_AGR = 3;
  // const DATE_FORMAT_PROVIDED_FROM_EXCEL = 'L/d/yy H:mm';
  const DATE_FORMAT_PROVIDED_FROM_EXCEL = 'yyyy-LL-dd H:mm';
  const DATE_FORMAT_DMY = 'dd/LL/yyyy HH:mm';
  const RANGE_TO = 'to';
  const RANGE_FROM = 'from';

  const EXCEL_RANGE_TOP_RIGHT = 'C10';
  const EXCEL_RANGE_BOTTOM_LEFT = 'J1011';

  const [collaboratorAssigned, setCollaboratorAssigned] = React.useState<Collaborator | undefined>(
      props.user.getCollaborator()
  );

  const [showOk, setShowOk] = React.useState<boolean>(false);
  const [showDialog, setShowDialog] = React.useState<boolean>(false);
  const [shouldRevalidate, setShouldRevalidate] = React.useState<boolean>(false);
  const [massiveRemunResponseList, setMassiveRemunResponseList] = React.useState<MassiveRemunResponse>();
  const [costPerHourToPost, setCostPerHourToCost] = React.useState<CostPerHourFromExcelFormToPost[]>([]);
  const [fileName, setFileName] = React.useState<string>('');

  // console.log(costPerHourToPost);

  React.useEffect(() => {
    if (massiveRemunResponseList && massiveRemunResponseList?.cant_ok > 0) {
      props.setButtonDisability(true);
    } else {
      props.setButtonDisability(false);
    }
    props.getDataFromUploadTemplate(massiveRemunResponseList);
  }, [massiveRemunResponseList]);

  React.useEffect(() => {
    props.openModal();
  }, [props.month]);

  const onDropFiles = (files: File[]): Promise<void> => {
    const f = files[0];
    var name = f.name;
    setFileName(name);
    const reader = new FileReader();
    reader.onload = async (evt) => {
      if (evt && evt.target) {
        const bstr = evt.target.result;
        const wb = XLSX.read(bstr, { type: 'binary' });

        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];

        ws['!ref'] = `${EXCEL_RANGE_TOP_RIGHT}:${EXCEL_RANGE_BOTTOM_LEFT}`;
        const data = XLSX.utils.sheet_to_json<ActivityFromExcelForm>(ws, {
          blankrows: false,
          raw: false,
        });

        let _costPerHourToPost: CostPerHourFromExcelFormToPost[] = [];

        for (const act of data) {
          let keys: string[] = Object.keys(act);

          if (keys.length > 3) {
            let sap_id = act[keys[KEY_COD_SAP]];
            let coef_va = act[keys[KEY_COEF_VAL_AGR]];
            let coef_ch = act[keys[KEY_COST_HORA]];
            let nombre_va = act[keys[KEY_NOMBRE_COEF_VAL_AGR]];

            let act_for_post: CostPerHourFromExcelFormToPost = {
              remun_sap_id: sap_id,
              remun_mes: props.month,
              remun_anio: props.year,
              remun_costo_hora: coef_ch,
              remun_coef_valor_agr: coef_va,
              remun_nombre_valor_agr: nombre_va,
            };
            // await hasError(act_for_post);

            _costPerHourToPost.push(act_for_post);
          }
        }

        setCostPerHourToCost(_costPerHourToPost);
      }
    };
    reader.readAsBinaryString(f);

    return Promise.resolve();
  };

  const [columns, setColumns] = React.useState<IColumn[]>([]);

  React.useEffect(() => {
    const updatedColumns: IColumn[] = [
      {
        key: 'remun_sap_id',
        name: t('Sap id'),
        fieldName: 'remun_sap_id',
        minWidth: 200,
        maxWidth: 200,
        className: 'fs-for-small-pill mt-2px',
      },
      {
        key: 'remun_costo_hora',
        name: t('Costo hora'),
        fieldName: 'remun_costo_hora',
        minWidth: 150,
        maxWidth: 150,
        className: 'fs-for-small-pill mt-2px',
      },
      {
        key: 'remun_coef_valor_agr',
        name: t('Coeficiente valor agregado'),
        fieldName: 'remun_coef_valor_agr',
        minWidth: 150,
        maxWidth: 150,
        className: 'fs-for-small-pill mt-2px',
      },
      {
        key: 'remun_nombre_valor_agr',
        name: t('Nombre valor agregado'),
        fieldName: 'remun_nombre_valor_agr',
        minWidth: 150,
        maxWidth: 150,
        className: 'fs-for-small-pill mt-2px',
      },
      {
        key: 'remun_estado',
        name: t('Estado'),
        fieldName: 'remun_estado',
        minWidth: 100,
        maxWidth: 100,
        className: 'fs-for-small-pill mt-2px',
        onRender: (item: any) => formatActivo(item),
      },
      {
        key: 'remun_error',
        name: t('Error'),
        fieldName: 'remun_error',
        minWidth: 300,
        maxWidth: 300,
        className: 'fs-for-small-pill mt-2px',
        // onRender: (item: any) => formatActivo(item),
      },
    ];

    setColumns(updatedColumns);
  }, [costPerHourToPost, massiveRemunResponseList]);

  const formatActivo = (item: any) => {
    // console.log(item);
    if (item.remun_estado === true) {
      return <Icon iconName="CheckMark" className="color-success" />;
    } else if (item.remun_estado === false) {
      return <Icon iconName="Cancel" className="color-error" />;
    }
  };

  const _onRenderRow: IDetailsListProps['onRenderRow'] = (props) => {
    if (props) {
      return (
          <div>
            <DetailsRow {...props} />
          </div>
      );
    }
    return null;
  };

  const resetImport = () => {
    setShowOk(false);
    setShowDialog(false);
    setShouldRevalidate(false);
    setCostPerHourToCost([]);
    setFileName('');
    setMassiveRemunResponseList(undefined);
  };

  const downloadModelExcel = async () => {
    props.changeBlockUI(true);
    if (collaboratorAssigned) {
      try {
        let uri = props.system.getCostPerHourSystem().getDownloadModelExcelURI();
        // window.location.assign(uri);
        window.open(uri, '_blank');

        props.courier.messageSuccess(t('Se ha descargado la planilla modelo con éxito'));
      } catch (error) {
        props.courier.messageError(error);
      }
    }
    props.changeBlockUI(false);
  };

  const postTemplateList = async () => {
    props.changeBlockUI(true);
    let cantOk;
    let cantError;
    try {
      let response = await props.system.getCostPerHourSystem().processMassiveRemun(costPerHourToPost);
      setMassiveRemunResponseList(response);
      cantOk = response.cant_ok;
      cantError = response.cant_error;
      // window.location.assign(uri);
      props.courier.messageSuccess(
          t('De los ') +
          (cantOk + cantError) +
          t(' costos por hora, se importaron ') +
          cantOk +
          t(' de manera exitosa') +
          (cantError > 0 ? t(', y ') + cantError + t(' con error') : '')
      );
    } catch (error) {
      props.courier.messageError(error);
    }

    props.changeBlockUI(false);
  };

  const getErrorsIdList = () => {
    let remunSapIdsWithErrors: any = [];
    if (massiveRemunResponseList) {
      const remunEstadoField = 'remun_estado';
      const remunError = 'remun_error';

      Object.keys(costPerHourToPost).forEach((key: string) => {
        const field = costPerHourToPost[key];
        const matchingError = massiveRemunResponseList.error.find(
            (error) =>
                error.remun.remun_sap_id === field.remun_sap_id &&
                error.remun.remun_anio === field.remun_anio &&
                error.remun.remun_coef_valor_agr === field.remun_coef_valor_agr &&
                error.remun.remun_mes === field.remun_mes &&
                error.remun.remun_nombre_valor_agr === field.remun_nombre_valor_agr &&
                error.remun.remun_costo_hora === field.remun_costo_hora
        );
        // console.log(matchingError);
        if (matchingError) {
          field[remunEstadoField] = false;
          field[remunError] = matchingError.msgError;
        } else {
          field[remunEstadoField] = true;
          field[remunError] = '';
        }
      });
    }
  };

  React.useEffect(() => {
    getErrorsIdList();
  }, [massiveRemunResponseList]);

  return (
      <div>
        {' '}
        {/* <ViewWrapperWithCommandBar title="" items={itemsMenu} withBorderBottom> */}
        <div className="padding-1">
          <div className="ms-Grid" dir="ltr">
            <div className="ms-Grid-row ">
              <div className="ms-Grid-col ms-md12 ">
                <DefaultButton
                    iconProps={{ iconName: 'Download' }}
                    className="float-right ml-2"
                    onClick={downloadModelExcel}
                >
                  {t('Descargar plantilla Excel')}
                </DefaultButton>

                <PrimaryButton
                    iconProps={{ iconName: 'ExcelLogo' }}
                    className=" float-right ml-2"
                    disabled={costPerHourToPost.length > 0 ? !true : !false}
                    onClick={postTemplateList}
                >
                  {t('Importar')}
                </PrimaryButton>

                {fileName && (
                    <DefaultButton
                        iconProps={{ iconName: 'Cancel' }}
                        className="float-right"
                        onClick={resetImport}
                    >
                      {t('Cancelar importación')}
                    </DefaultButton>
                )}
              </div>
            </div>

            <div className="ms-Grid-row mt-2 ">
              <div className="ms-Grid-col ms-md6 ">
                <Text variant="medium">{t('Arrastra y suelta el archivo de excel a importar aquí')}</Text>
              </div>
            </div>
            <div className="ms-Grid-row mt-1 ">
              <div className="ms-Grid-col ms-md6 ">
                <Text variant="small">{fileName}</Text>
              </div>
            </div>

            <PlainFileDropzone onDrop={(files) => onDropFiles(files)} openOnClick>
              <div className="child-dropzone"></div>
            </PlainFileDropzone>

            <div className="ms-Grid-row mt-2 ml-1">
              {massiveRemunResponseList && (
                  <Text variant="medium">
                    {t('Tienes ') +
                        massiveRemunResponseList?.cant_ok +
                        t(' costos por hora válidos y ') +
                        massiveRemunResponseList?.cant_error +
                        t(' costos por hora con errores.')}
                  </Text>
              )}
            </div>

            {costPerHourToPost.length > 0 && (
                <div className="ms-Grid-row">
                  <DetailsList
                      className=""
                      items={showOk ? costPerHourToPost : costPerHourToPost}
                      columns={columns}
                      selectionMode={SelectionMode.none}
                      layoutMode={DetailsListLayoutMode.justified}
                      isHeaderVisible={true}
                      onRenderRow={_onRenderRow}
                      onShouldVirtualize={() => false}
                  />
                </div>
            )}
          </div>
        </div>
      </div>
  );
};
