import React, { useEffect, useState } from 'react';
// import CompanySelectorAssistant from './Wizard/CompanySelectorAssistant';
import {
  ComboBox,
  DatePicker,
  DefaultButton,
  DefaultPalette,
  IComboBox,
  IComboBoxOption,
  ICommandBarItemProps,
  Icon,
  PrimaryButton,
  Text,
  TextField,
  Toggle,
} from '@fluentui/react';
import { useNavigate } from 'react-router-dom';

import { routes } from '../../common/constants';
import TimeTaxSupportSystemClient from '../../system';
import MessageCourier from '../../lib/MessageCourier';
import { t } from 'i18next';
import ViewWrapperWithCommandBar from '../Layout/ViewWrapperWithCommandBar';
import {
  defaultCommandBarButtonStyles,
  buttonStylesForProjects,
  titleCommandBarButtonStyles,
} from '../../styles/button-styles';
import { getLocalizedStrings } from '../../lib/LocalizedStringsForDatePicker';
import { DateCustom } from '../../lib/DateCustom';
import { DateTime } from 'luxon';
import {
  CollaboratorLicense,
  ICollaboratorLicense,
  LicenseType,
} from '../../system/collaborators/collaborator_license/CollaboratorLicense';
import { useBoolean } from '@fluentui/react-hooks';
import Wizard from '../Wizard/Wizard';
import LicenseSelectors from './Wizard/LicenseSelectors';
import Ending from './Wizard/Ending';
import HelperModal from '../Common/HelperModal';
import { HeatMapExample } from '../Common/HeatMapExample';

interface ILicensesView {
  system: TimeTaxSupportSystemClient;
  courier: MessageCourier;
  changeBlockUI: any;
}

const LicensesView = (props: ILicensesView) => {
  const navigate = useNavigate();

  const [licenseGenerated, setLicenseGenerated] = useState<ICollaboratorLicense>();
  const [fromDate, setFromDate] = useState<DateTime>(DateTime.fromJSDate(new Date()));
  const [toDate, setToDate] = useState<DateTime>(DateTime.fromJSDate(new Date()));
  const [selectedLicenseType, setSelectedLicenseType] = useState<string>();
  const [reason, setReason] = useState<string>();
  const [active, { toggle: toggleActive }] = useBoolean(true);
  const [canGoNext, setCanGoNext] = useState(false);
  const [licenseTypes, setLicenseTypes] = useState<LicenseType[]>();
  const [formattedOptions, setFormattedOptions] = useState<IComboBoxOption[]>([]);

  const [openHelpDialog, setOpenHelpDialog] = React.useState(false);

  const _farItems: ICommandBarItemProps[] = [
    {
      key: 'help',
      text: t('Ayuda'),
      iconProps: { iconName: 'Lightbulb', color: DefaultPalette.themeLighterAlt },
      onClick: () => {
        setOpenHelpDialog(true);
      },
      buttonStyles: titleCommandBarButtonStyles,
    },
  ];

  useEffect(() => {
    getLicenseTypes();
  }, []);

  useEffect(() => {
    getFormattedTypes();
  }, [licenseTypes]);

  const getFormattedTypes = () => {
    if (licenseTypes) {
      let formatted: IComboBoxOption[] = [];
      licenseTypes.forEach((element) => {
        if (element && element.getActive() && element.getId()) {
          formatted.push({ key: element.getId(), text: element.getDescription() });
        }
      });
      setFormattedOptions(formatted);
    }
  };

  const getLicenseTypes = async () => {
    props.changeBlockUI(true);
    try {
      const response = await props.system.getCollaboratorLicenseSystem().getLicenseTypes();
      if (response) {
        // response.forEach((element:LicenseType) => {
        //   if(element.getActive()){

        //   }
        // });
        setLicenseTypes(response);
        // props.courier.messageSuccess(t('La defensa fue generada exitosamente'));
      }
    } catch (error) {
      props.courier.messageError(error);
    } finally {
      props.changeBlockUI(false);
    }
  };

  const Titles = [t('1. Período/Motivo'), t('2. Finalización')];

  const Descriptions = [
    t('Selecciona la fecha de la licencia, el tipo y el motivo.'),
    t('Licencia generada'),
  ];

  const setButtonDisability = (can: boolean) => {
    setCanGoNext(can);
  };

  useEffect(() => {
    if (hasCompletedData() && licenseGenerated) {
      setButtonDisability(true);
    }
  }, [reason, selectedLicenseType, licenseGenerated]);

  const itemsMenu = React.useMemo(
      (): ICommandBarItemProps[] => [
        {
          key: 'title',
          text: t('Licencias'),
          iconProps: {
            iconName: 'EventDateMissed12',
            color: DefaultPalette.themeLighterAlt,
            className: 'text-title-btn',
          },
          buttonStyles: titleCommandBarButtonStyles,
          className: 'text-title-btn',
        },
        {
          key: 'back',
          text: t('Volver'),
          onClick: () => navigate(`${routes.licenses}`),
          iconProps: { iconName: 'Back' },
          buttonStyles: defaultCommandBarButtonStyles,
        },
        {
          key: 'save',
          text: t('Guardar'),
          iconProps: { iconName: 'Save', color: DefaultPalette.themeLighterAlt },
          buttonStyles:
              fromDate && toDate && selectedLicenseType && reason && fromDate <= toDate
                  ? buttonStylesForProjects
                  : titleCommandBarButtonStyles,
          onClick: () => {
            generateLicense();
          },
          disabled: fromDate && toDate && selectedLicenseType && reason && fromDate <= toDate ? false : true,
        },
      ],
      [navigate, fromDate, toDate, selectedLicenseType, reason]
  );

  const onChangeDate = (date: Date | null | undefined, name: string) => {
    if (date) {
      if (name === 'from') {
        setFromDate(DateTime.fromJSDate(date));
      }
      if (name === 'to') {
        setToDate(DateTime.fromJSDate(date));
      }
    }
  };

  const hasCompletedData = () => {
    if (fromDate && toDate && selectedLicenseType && reason && fromDate <= toDate) {
      return true;
    } else return false;
  };

  const generateLicense = async () => {
    if (hasCompletedData() && selectedLicenseType && reason) {
      props.changeBlockUI(true);
      try {
        const response = await props.system
            .getCollaboratorLicenseSystem()
            .generateLicense(fromDate, toDate, selectedLicenseType, reason, active);
        if (response) {
          setLicenseGenerated(response);
          props.courier.messageSuccess(t('La licencia fue generada exitosamente'));
          navigate(routes.licenses + '/' + response.collic_id);
        }
      } catch (error) {
        props.courier.messageError(error);
      } finally {
        props.changeBlockUI(false);
      }
    }
  };

  const Selectors = [
    <LicenseSelectors
        system={props.system}
        courier={props.courier}
        changeBlockUI={props.changeBlockUI}
        onChangeDate={onChangeDate}
        generateLicense={generateLicense}
        setButtonDisability={hasCompletedData() ? true : false}
        setSelectedLicenseType={setSelectedLicenseType}
        setReason={setReason}
        toggleActive={toggleActive}
        fromDate={fromDate}
        toDate={toDate}
        active={active}
        hasCompletedData={hasCompletedData}
    />,
    <Ending system={props.system} license={licenseGenerated?.collic_id} />,
  ];

  return (
      <ViewWrapperWithCommandBar
          withBorderBottom
          title={''}
          items={itemsMenu}
          overflowButtonStyles={defaultCommandBarButtonStyles}
          variant="highligth"
          titleBody={t('Licencias')}
          farItems={_farItems}
      >
        {licenseTypes ? (
            <div className="padding-1">
              {/* <Text variant="large" className="ml-10px mt-2">
            <Icon iconName="EventDateMissed12" className="text-icon"></Icon>
            {t('Generación de licencias')}
          </Text> */}
              <div className="ms-Grid bg-white-h100" dir="ltr">
                <div className="ms-Grid-row mt-2 ">
                  <div className="ms-Grid-col ms-sm3 ">
                    <DatePicker
                        strings={getLocalizedStrings()}
                        label={t('Desde')}
                        formatDate={DateCustom.formatDateForDatePicker}
                        value={fromDate.toJSDate()}
                        onSelectDate={(e) => {
                          onChangeDate(e, 'from');
                          const _fromDate = DateTime.fromJSDate(e!);
                        }}
                        // calendarProps={{ isDayPickerVisible: false }}
                        className=""
                    />
                  </div>
                  <div className="ms-Grid-col ms-sm3 ">
                    <DatePicker
                        strings={getLocalizedStrings()}
                        label={t('Hasta')}
                        formatDate={DateCustom.formatDateForDatePicker}
                        // calendarProps={{ isDayPickerVisible: false }}
                        value={toDate.toJSDate()}
                        onSelectDate={(e) => {
                          onChangeDate(e, 'to');
                          const _toDate = DateTime.fromJSDate(e!);
                        }}
                        className=""
                        minDate={fromDate.toJSDate()}
                    />
                  </div>
                </div>
                <div className="ms-Grid-row ">
                  <div className="ms-Grid-col ms-sm3 ">
                    <ComboBox
                        options={formattedOptions}
                        label={t('Tipo')}
                        className=""
                        onChange={(
                            event: React.FormEvent<IComboBox>,
                            option?: IComboBoxOption | undefined,
                            index?: number | undefined,
                            value?: string | undefined
                        ) => {
                          if (option && typeof option.key == 'string') {
                            setSelectedLicenseType(option.key);
                          }
                        }}
                    />
                  </div>
                  <div className="ms-Grid-col ms-sm3 ">
                    <TextField
                        label={t('Motivo')}
                        className=""
                        onChange={(e) => setReason(e.currentTarget.value)}
                    />
                  </div>
                </div>
                <div className="ms-Grid-row ">
                  <div className="ms-Grid-col ms-sm3 ">
                    <Toggle label={t('Activo')} className="" checked={active} onChange={toggleActive} disabled />
                  </div>
                </div>
                {/* <div className="ms-Grid-row mt-2">
              <div className="ms-Grid-col ms-sm6 ">
                <DefaultButton
                  text={t('Generar')}
                  className=""
                  onClick={() => generateLicense()}
                  disabled={hasCompletedData() ? false : true}
                />
              </div>
            </div> */}
                {licenseGenerated ? (
                    <div className="ms-Grid-row mt-2">
                      <div className="ms-Grid-col ms-sm12 ">
                        <Icon iconName="Accept" className="text-icon color-success"></Icon>
                        <Text variant="medium"> {t('La licencia fue generada exitosamente')}</Text>
                        <PrimaryButton
                            className="ml-10px"
                            text={t('Ver detalle')}
                            onClick={() => navigate(routes.licenses + '/' + licenseGenerated.collic_id)}
                        />
                      </div>
                    </div>
                ) : null}
              </div>
            </div>
        ) : null}

        <HelperModal
            open={openHelpDialog}
            onClose={() => setOpenHelpDialog(false)}
            tutorialsId={['tutorial-licencias']}
        ></HelperModal>
      </ViewWrapperWithCommandBar>
  );
};

export default LicensesView;
