import React from 'react';
import { DefaultPalette } from '@fluentui/react';
import { ICommandBarItemProps } from '@fluentui/react/lib/CommandBar';
import { Nav, INavLinkGroup, INavLink } from '@fluentui/react/lib/Nav';
import { DISABLE_MMI_OPTION, routes } from '../../common/constants';

import ViewWrapperWithCommandBar from '../Layout/ViewWrapperWithCommandBar';
import { themeLight } from '../../styles/colors';
import { buttonStylesFor } from '../../styles/button-styles';
import ProjectDocumentsView from './ProjectDocumentsView';
import TimeTaxSupportSystemClient from '../../system';
import MessageCourier from '../../lib/MessageCourier';
import useAsyncDataCourier from '../../common/custom-hooks/useAsyncDataCourier';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { ActivitySectionList } from './ProjectManagment/ActivitySection/ActivitySectionList';
import { MailSectionList } from './ProjectManagment/MailSection/MailSectionList';
import { DefenseFileSectionList } from './ProjectManagment/DefenseFileSection/DefenseFileSectionList';

import { User } from '../../system/User';
import { ProjectMiniPanelWrapper } from './ProjectMiniPanel/ProjectMiniPanelWrapper';
import { ProjectRequiredDocumentList } from './ProjectRequiredDocument/ProjectRequiredDocumentList';
import { DocumentUploadControlSaved } from '../../system/documents/DocumentUploadControl';
import { IProjectDescriptor } from '../../system/projects/ProjectDescriptor';
import SystemConfiguration from '../../system/configuration/SystemConfiguration';
import PeriodDatesBlockedSystem from '../../system/period_dates_blocked/PeriodDatesBlockedSystem';

import { useTranslation, Trans } from 'react-i18next';

const noUrl = '/none';

const defaultCommandBarButtonStyles = buttonStylesFor({
  backgroundColor: themeLight.backgroundSecondary,
  backgroundColorFocused: themeLight.backgroundSecondaryFocused,
});
/**
 * @TODO usar theme en lugar de default pallete
 */
const buttonStylesForProjects = buttonStylesFor({
  backgroundColor: DefaultPalette.themePrimary,
  backgroundColorFocused: DefaultPalette.themeSecondary,
  textColor: DefaultPalette.themeLighterAlt,
  iconsColor: DefaultPalette.themeLighterAlt,
});

interface IProjectViewProps {
  system: TimeTaxSupportSystemClient;
  courier: MessageCourier;
  user: User;
  changeBlockUI: (change: boolean) => void;
  config: SystemConfiguration;
  periodsBlocked: PeriodDatesBlockedSystem;
}

export const KEY_VIEW_DOCS_PROJECTS = '1';
export const KEY_VIEW_ACTIVITIES_PROJECTS = '2';
export const KEY_VIEW_SETTLEMENTS_PROJECTS = '3';
export const KEY_VIEW_DEFENSE_FILE_PROJECTS = '4';
export const KEY_VIEW_CONTROL_DOCS_PROJECTS = '5';
export const KEY_VIEW_MAILS_PROJECTS = '6';

const ProjectView: React.FC<IProjectViewProps> = ({ system, ...props }) => {
  const { t, i18n } = useTranslation();
  const [shouldUpdate, setShouldUpdate] = React.useState<boolean>(false);
  const [actualizateProject, setActualizateProject] = React.useState<boolean>(false);
  let { id } = useParams();
  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();
  let [view, setView] = React.useState(searchParams.get('view'));

  React.useEffect(() => {
    if (view) {
      setSelectedView(view);
    }
  }, [view, searchParams]);

  const projectView: INavLinkGroup[] = [
    {
      links: [
        {
          name: '',
          url: noUrl,
          key: KEY_VIEW_DOCS_PROJECTS,
          title: t('Documentos'),
          icon: 'Attach',
        },
        {
          name: '',
          url: noUrl,
          key: KEY_VIEW_ACTIVITIES_PROJECTS,
          title: t('Actividades'),
          icon: 'AccountActivity',
        },
        {
          name: '',
          url: noUrl,
          key: KEY_VIEW_CONTROL_DOCS_PROJECTS,
          title: t('Documentos obligatorios'),
          icon: 'ProtectedDocument',
        },
        // {
        //   name: '',
        //   url: noUrl,
        //   key: KEY_VIEW_MAILS_PROJECTS,
        //   title: t('Mails (MMI)'),
        //   icon: 'Mail',
        // },
      ],
    },
  ];

  const projects = useAsyncDataCourier(() => system.getProjects(), [system], props.courier);
  const selectedProject = useAsyncDataCourier(
      () => system.getProjectSystem().getById(id!),
      [system, id, actualizateProject],
      props.courier,
      props.changeBlockUI
  );

  const [selectedView, setSelectedView] = React.useState(
      searchParams.get('view') ? String(searchParams.get('view')) : projectView[0].links[0].key!
  );
  // const [selectedView, setSelectedView] = React.useState(projectView[0].links[0].key!);

  const [showMiniPanel, setShowMiniPanel] = React.useState<boolean>(
      localStorage.getItem('showMiniPanel') === '0' ? false : true
  );

  React.useEffect(() => {
    setShouldUpdate(!shouldUpdate);
    setControlDocument(undefined);
  }, [selectedProject]);

  const onClickProject = (project: IProjectDescriptor) => () => {
    navigate(`${routes.project}/${project.getId()}`);
  };

  const [controlDocument, setControlDocument] = React.useState<DocumentUploadControlSaved | undefined>(
      undefined
  );
  const onSelectDocumentControl = (controlDocument: DocumentUploadControlSaved) => {
    setControlDocument(controlDocument);
    setSelectedView(KEY_VIEW_DOCS_PROJECTS);
  };

  const items = React.useMemo(
      (): ICommandBarItemProps[] => [
        {
          key: 'project',
          text: selectedProject?.getCode(),
          itemProps: {},
          iconProps: { iconName: 'Teamwork', color: DefaultPalette.themeLighterAlt },
          subMenuProps: {
            items: projects
                ? projects.map((project) => ({
                  key: project.getCode(),
                  text: project.getCode(),
                  onClick: onClickProject(project),
                }))
                : [],
          },
          buttonStyles: buttonStylesForProjects,
        },
        {
          key: 'download',
          text: t('Config'),
          // href: routes.project_config,
          onClick: () => navigate(`${routes.project_config}/${selectedProject?.getId()}`),
          iconProps: { iconName: 'Settings' },
          buttonStyles: defaultCommandBarButtonStyles,
        },
        {
          key: 'panel',
          text: t('Panel'),
          onClick: () => navigate(`/${routes.project_panel}/${selectedProject?.getId()}`),

          iconProps: { iconName: 'DashboardAdd' },
          buttonStyles: defaultCommandBarButtonStyles,
        },
        {
          key: 'viewRequiredDocuments',
          text: t('Docs. obligatorios'),
          iconProps: { iconName: 'ProtectedDocument' },
          onClick: () => setSelectedView(KEY_VIEW_CONTROL_DOCS_PROJECTS),
          buttonStyles: defaultCommandBarButtonStyles,
        },
        // {
        //   key: 'mmi',
        //   text: t('MMI (Massive Mail Inspector)'),
        //   iconProps: { iconName: 'Mail' },
        //   onClick: () => navigate(`${routes.massive_mail_inspector}/${id}`),
        //   buttonStyles: defaultCommandBarButtonStyles,
        //   disabled: DISABLE_MMI_OPTION,
        // },
      ],
      [selectedProject, projects, id, navigate]
  );

  const farItems: ICommandBarItemProps[] = React.useMemo(
      () => [
        {
          key: 'info',
          text: t('Info & MiniPanel'),
          ariaLabel: 'Info',
          iconOnly: true,
          iconProps: { iconName: 'BIDashboard' },
          buttonStyles: defaultCommandBarButtonStyles,
          onClick: () => {
            localStorage.setItem('showMiniPanel', showMiniPanel ? '0' : '1');
            setShowMiniPanel(!showMiniPanel);
          },
        },
      ],
      [showMiniPanel]
  );

  const onViewNavClick = (ev?: React.MouseEvent<HTMLElement, MouseEvent>, item?: INavLink) => {
    ev?.preventDefault();
    if (item && item.key) setSelectedView(item.key);
  };

  const backToControlDocsSection = () => {
    setControlDocument(undefined);
    setSelectedView(KEY_VIEW_CONTROL_DOCS_PROJECTS);
  };

  const setViewControlDocsWrapper = () => {
    setSelectedView(KEY_VIEW_CONTROL_DOCS_PROJECTS);
  };

  return (
      <ViewWrapperWithCommandBar
          title={t('Proyectos')}
          items={items}
          farItems={farItems}
          overflowButtonStyles={defaultCommandBarButtonStyles}
          variant="highligth"
      >
        {/* <Stack horizontal verticalFill styles={{ root: { alignItems: 'stretch' } }}> */}
        <div className="ms-Grid" dir="ltr">
          {selectedProject && showMiniPanel && (
              <ProjectMiniPanelWrapper
                  selectedProject={selectedProject}
                  system={system}
                  courier={props.courier}
                  setViewControlDocsWrapper={setViewControlDocsWrapper}
                  user={props.user}
                  actualizateProject={() => setActualizateProject(!actualizateProject)}
                  changeBlockUI={props.changeBlockUI}
              />
          )}
          <div className="ms-Grid-row">
            <div className="ms-Grid-col ms-sm1" style={{ maxWidth: '4rem' }}>
              <Nav
                  selectedKey={selectedView}
                  ariaLabel={t('Proyecto')}
                  groups={projectView}
                  onLinkClick={onViewNavClick}
                  styles={{
                    root: { backgroundColor: '#f0f0f0' },
                    navItem: { padding: '5px', maxWidth: '2.5rem' },
                  }}
                  // className="full-height"
              />
            </div>
            <div className="ms-Grid-col ms-sm11">
              {selectedView === KEY_VIEW_DOCS_PROJECTS && selectedProject && (
                  <ProjectDocumentsView
                      user={props.user}
                      project={selectedProject}
                      courier={props.courier}
                      system={system}
                      controlDocument={controlDocument}
                      backToControlDocsSection={backToControlDocsSection}
                  />
              )}
              {selectedView === KEY_VIEW_ACTIVITIES_PROJECTS && selectedProject && projects && (
                  <ActivitySectionList
                      project={selectedProject}
                      system={system}
                      user={props.user}
                      projects={projects}
                      courier={props.courier}
                      changeBlockUI={props.changeBlockUI}
                      config={props.config}
                      periodsBlocked={props.periodsBlocked}
                  />
              )}

              {selectedView === KEY_VIEW_CONTROL_DOCS_PROJECTS && selectedProject && (
                  <ProjectRequiredDocumentList
                      changeBlockUI={props.changeBlockUI}
                      user={props.user}
                      courier={props.courier}
                      system={system}
                      project={selectedProject}
                      onSelectDocumentControl={onSelectDocumentControl}
                  ></ProjectRequiredDocumentList>
              )}

              {selectedView === KEY_VIEW_MAILS_PROJECTS && selectedProject && projects && (
                  <MailSectionList
                      project={selectedProject}
                      system={system}
                      user={props.user}
                      projects={projects}
                      courier={props.courier}
                      changeBlockUI={props.changeBlockUI}
                      config={props.config}
                  ></MailSectionList>
              )}
            </div>
            {/* </Stack> */}
          </div>
        </div>
      </ViewWrapperWithCommandBar>
  );
};

export default ProjectView;
