import React, { useState } from 'react';
import {
  DetailsList,
  DefaultPalette,
  SelectionMode,
  DetailsListLayoutMode,
  IColumn,
  DefaultButton,
  ActionButton,
  TextField,
} from '@fluentui/react';

import { ICommandBarItemProps } from '@fluentui/react/lib/CommandBar';

import ViewWrapperWithCommandBar from '../Layout/ViewWrapperWithCommandBar';
import {
  titleCommandBarButtonStyles,
  defaultCommandBarButtonStyles,
  buttonStylesForProjects,
} from '../../styles/button-styles';

import TimeTaxSupportSystemClient from '../../system';
import MessageCourier from '../../lib/MessageCourier';
import useAsyncDataCourier from '../../common/custom-hooks/useAsyncDataCourier';
import { User } from '../../system/User';
import { useTranslation } from 'react-i18next';
import { Text, Icon, PersonaSize, OverflowButtonType, Facepile } from '@fluentui/react';
import { Activity } from '../../system/activity/Activity';
import { DateCustom } from '../../lib/DateCustom';
import {
  ChoiceGroup,
  IChoiceGroupOption,
  IChoiceGroupOptionStyleProps,
  IChoiceGroupOptionStyles,
  IChoiceGroupStyles,
} from '@fluentui/react/lib/ChoiceGroup';
import { CalendarEventCallout } from '../Calendar/CalendarEventCallout';
import useSystemCall from '../../common/custom-hooks/useSystemCall';
import SystemConfiguration from '../../system/configuration/SystemConfiguration';
import { IActivitiesProcessed } from '../../system/activity/ActivitySystem';
import { ActivityPending } from '../../system/activity/ActivityPending';
import PeriodDatesBlockedSystem from '../../system/period_dates_blocked/PeriodDatesBlockedSystem';
import { DetailListPagination } from '../Common/DetailsList/DetailListPagination';

interface IMassiveActivitiesChecker {
  system: TimeTaxSupportSystemClient;
  courier: MessageCourier;
  user: User;
  changeBlockUI: (change: boolean) => void;
  config: SystemConfiguration;
  periodsBlocked: PeriodDatesBlockedSystem;
}

export interface IActivitiesForProcess {
  id: string;
  accept: boolean;
  decline: boolean;
}
export const MassiveActivitiesChecker = (props: IMassiveActivitiesChecker) => {
  const { t, i18n } = useTranslation();
  const [activitiesToProcess, setActivitiesToProcess] = React.useState<IActivitiesForProcess[]>([]);
  const [shouldRefreshPendingActivities, setShouldRefreshPendingActivities] = React.useState<boolean>(false);
  const [activityTextValue, setActivityTextValue] = React.useState<string>('');
  const [projectTextValue, setProjectTextValue] = React.useState<string>('');
  const [pendingActivitiesFiltered, setPendingActivitiesFiltered] = React.useState<Activity[]>([]);
  const [loading, setLoading] = React.useState(true);

  const [processedActivities, setProcessedActivities] = React.useState<IActivitiesProcessed[]>([]);
  //paginacion
  const [page, setPage] = React.useState(1);
  const [perPage, setPerPage] = React.useState(10);
  const [totalPages, setTotalPages] = React.useState(0);

  const processPendingActivitiesWrapper = (activitiesToProcess: IActivitiesForProcess[]) => {
    processPendingActivities(activitiesToProcess);
  };

  const pendingActivities = useAsyncDataCourier(
      () => props.system.getActivitySystem().getAllPendingActivities(page,perPage),
      [props.system, shouldRefreshPendingActivities,page,perPage],
      props.courier,
      props.changeBlockUI
  );

  React.useEffect(() => {
    if (pendingActivities) {
      setPendingActivitiesFiltered(pendingActivities.activities);
      setTotalPages(pendingActivities.total);
      setLoading(false);
    }
  }, [pendingActivities,page,perPage]);
  

  const processPendingActivities = async (activitiesToProcess: IActivitiesForProcess[]) => {
    props.changeBlockUI(true);
    try {
      const ret = await props.system.getActivitySystem().processPendingActivities(activitiesToProcess);
      setProcessedActivities(ret);
      setShouldRefreshPendingActivities(!shouldRefreshPendingActivities);
      setActivitiesToProcess([]);
      setActivityTextValue('');
      setProjectTextValue('');

      let ok = ret.filter((it) => it.ok).length;
      let withError = ret.filter((it) => !it.ok).length;

      if (withError === 0) {
        props.courier.messageSuccess(t('msgProcessActivitiesChecker'));
      } else {
        props.courier.messageError(
            t('msgProcessActivitiesCheckerWithError', { ok: ok, withError: withError })
        );
      }
    } catch (error) {
      props.courier.messageError(error);
    } finally {
      props.changeBlockUI(false);
    }
  };

  const projects = useSystemCall(props.system, (system) => system.getProjects());
  const [activitySelected, setActivitySelected] = React.useState<Activity | undefined>(undefined);
  const [showDialog, setShowDialog] = React.useState<boolean>(false);
  const commonContext = useSystemCall(props.system, (system) =>
      system.getActivitySystem().getCommonContext()
  );

  const ACCEPT = 'accept';
  const DECLINE = 'decline';

  const markAllAs = (decision: string) => {
    if (pendingActivitiesFiltered) {
      let _activitiesToProcess: IActivitiesForProcess[] = [...activitiesToProcess];
      pendingActivitiesFiltered.forEach((act) => {
        const idx = _activitiesToProcess.findIndex((atp) => atp.id === act.getId());

        if (idx > -1) {
          _activitiesToProcess[idx].accept = decision === ACCEPT;
          _activitiesToProcess[idx].decline = decision === DECLINE;
        } else {
          _activitiesToProcess.push({
            id: act.getId(),
            accept: decision === ACCEPT,
            decline: decision === DECLINE,
          });
        }
      });

      // _activitiesToProcess.concat(_activitiesToProcess);
      setActivitiesToProcess(_activitiesToProcess);
    }
  };

  const itemsMenu = React.useMemo((): ICommandBarItemProps[] => {
    let ret: ICommandBarItemProps[] = [
      {
        key: 'title',
        text: t('Actividades pendientes'),
        iconProps: {
          iconName: 'AccountActivity',
          color: DefaultPalette.themeLighterAlt,
          className: 'text-title-btn',
        },
        buttonStyles: titleCommandBarButtonStyles,
        className: 'text-title-btn',
      },
      {
        key: 'process',
        text: t('Procesar'),
        iconProps: {
          iconName: 'Processing',
          color: DefaultPalette.themeLighterAlt,
          className: 'text-title-btn',
        },
        disabled:
            activitiesToProcess.filter((atp) => (atp.accept && !atp.decline) || (!atp.accept && atp.decline))
                .length === 0,
        buttonStyles: buttonStylesForProjects,
        className: 'text-title-btn',
        onClick: () => processPendingActivitiesWrapper(activitiesToProcess),
      },
      {
        key: 'acceptAll',
        text: t('Marcar todas como aceptadas'),
        iconProps: {
          iconName: 'EventAccepted',
          color: DefaultPalette.themeLighterAlt,
          className: 'text-title-btn',
        },
        buttonStyles: defaultCommandBarButtonStyles,
        className: 'text-title-btn',
        onClick: () => markAllAs(ACCEPT),
      },
      {
        key: 'declineAll',
        text: t('Marcar todas como declinadas'),
        iconProps: {
          iconName: 'EventDeclined',
          color: DefaultPalette.themeLighterAlt,
          className: 'text-title-btn',
        },
        buttonStyles: defaultCommandBarButtonStyles,
        className: 'text-title-btn',
        onClick: () => markAllAs(DECLINE),
      },
      {
        key: 'cancel',
        text: t('Cancelar todas las selecciones'),
        iconProps: {
          iconName: 'Cancel',
          color: DefaultPalette.themeLighterAlt,
          className: 'text-title-btn',
        },
        disabled: activitiesToProcess.length === 0,
        buttonStyles: defaultCommandBarButtonStyles,
        className: 'text-title-btn',
        onClick: () => {
          setActivitiesToProcess([]);
          setActivityTextValue('');
          setProjectTextValue('');
          if (pendingActivities) {
            setPendingActivitiesFiltered(pendingActivities.activities);
          }
        },
      },
    ];
    return ret;
  }, [activitiesToProcess, pendingActivities, pendingActivitiesFiltered]);

  const optionsAcceptOrDecline: IChoiceGroupOption[] = [
    { key: ACCEPT, text: t('Aceptar'), iconProps: { iconName: 'EventAccepted' } },
    {
      key: DECLINE,
      text: t('Declinar'),
      className: 'declined-option',
      iconProps: { iconName: 'EventDeclined' },
    },
  ];

  const onClickOption = (activity: Activity, decision: string) => {
    let _activitiesToProcess = [...activitiesToProcess];
    let _activity = _activitiesToProcess.find((act) => activity.getId() === act.id);
    if (_activity) {
      if (decision === ACCEPT) {
        _activity.accept = true;
        _activity.decline = false;
      }
      if (decision === DECLINE) {
        _activity.accept = false;
        _activity.decline = true;
      }
    } else {
      let newItem = {
        id: activity.getId(),
        accept: decision === ACCEPT,
        decline: decision === DECLINE,
      };
      _activitiesToProcess.push(newItem);
    }
    setActivitiesToProcess(_activitiesToProcess);
  };

  const onCancelOption = (activity: Activity) => {
    let _activitiesToProcess = [...activitiesToProcess];
    const idx = _activitiesToProcess.findIndex((atp: any) => atp.id === activity.getId());
    if (idx > -1) {
      _activitiesToProcess.splice(idx, 1);
    }
    setActivitiesToProcess(_activitiesToProcess);
  };

  const getSelectedKey = (activity: Activity) => {
    const act = activitiesToProcess.find((_act) => _act.id === activity.getId());
    if (act && act.accept) return ACCEPT;
    if (act && act.decline) return DECLINE;
    return '';
  };

  const choiceGroupStyles: IChoiceGroupOptionStyles = { choiceFieldWrapper: { border: 'red' } };

  const onClickVerActividad = async (item: Activity) => {
    if (item) {
      props.changeBlockUI(true);
      try {
        // const activity = await props.system.getActivitySystem().getActivity(item.getId());
        // setActivitySelected(activity);
        setActivitySelected(item);
        setShowDialog(true);
      } catch (error) {
        props.courier.messageError(error);
      } finally {
        props.changeBlockUI(false);
      }
    }
  };

  const getError = (actId: string) => {
    const item = processedActivities.find((pa) => pa.actId === actId);
    return item && !item.ok ? item.msgError : '';
  };

  const columns: IColumn[] = React.useMemo(
      () => [
        {
          key: 'name',
          isResizable: true,
          name: t('Actividad'),
          fieldName: 'name',
          minWidth: 150,
          maxWidth: 200,
          // className: 'fs-for-small-pill mt-2px',
          onRender: (item: Activity) => {
            return (
                <div>
                  <Text block variant="smallPlus" className="" title={item.getName()}>
                    {item.getName()}
                  </Text>
                </div>
            );
          },
        },

        {
          key: 'hours',
          isResizable: true,
          name: t('Info. Actividad'),
          fieldName: 'hours',
          minWidth: 250,
          maxWidth: 350,
          // className: 'fs-for-small-pill mt-2px',
          onRender: (item: ActivityPending) => {
            const _colList = item.getCollaboratorActivityList().map((colact) => {
              return {
                personaName: colact.getCollaborator().fullName(),
              };
            });

            return (
                <div>
                  <div className="ms-Grid-row">
                    <Icon iconName="Clock" className="text-icon" />

                    <Text
                        block
                        variant="smallPlus"
                        className="d-inline"
                        title={`${DateCustom.getDisplayText(item.getStart(), item.getEnd())} (
                  ${DateCustom.formatHorasToString(item.getHours())})`}
                    >
                      {DateCustom.getDisplayText(item.getStart(), item.getEnd())} (
                      {DateCustom.formatHorasToString(item.getHours())})
                    </Text>
                  </div>

                  <div className="ms-Grid-row mt-1">
                    <Icon iconName="Group" className="text-icon" />

                    <Facepile
                        className="d-inline-block"
                        personas={_colList}
                        personaSize={PersonaSize.size24}
                        maxDisplayablePersonas={6}
                        overflowButtonProps={{ ariaLabel: 'Ver más' }}
                        overflowButtonType={OverflowButtonType.descriptive}
                    />
                  </div>
                  <div className="ms-Grid-row">
                    <Icon iconName="ReminderPerson" className="text-icon" />

                    <Text block variant="smallPlus" className="d-inline">
                      {item
                          .getCollaboratorActivityList()
                          .find((colact) => colact.getChecked() && !colact.getTagged())
                          ?.getCollaborator()
                          .fullName()}{' '}
                      {t(' Te ha taggeado')}{' '}
                    </Text>
                  </div>
                  {item.getHasDuplicates() && (
                      <div className="ms-Grid-row mt-1">
                        <Text
                            block
                            variant="smallPlus"
                            className="warning-color"
                            title="Ya tienes una actividad aceptada con el mismo nombre y fecha"
                        >
                          <Icon iconName="warning" className="text-icon warning-color" />
                          Ya tienes una actividad aceptada con el mismo nombre y fecha
                        </Text>
                      </div>
                  )}
                  <div className="ms-Grid-row mt-1">
                    <Text block variant="smallPlus" className="mt-2 error-color">
                      {getError(item.getId())}
                    </Text>
                  </div>
                </div>
            );
          },
        },
        {
          key: 'pro_id',
          isResizable: true,
          name: t('Info. Proyecto'),
          fieldName: 'pro_id',
          minWidth: 150,
          maxWidth: 350,
          // className: 'fs-for-small-pill mt-2px',
          onRender: (item: Activity) => {
            return (
                <div>
                  <div className="ms-Grid-row  ">
                    <Icon iconName="Teamwork" className="text-icon" />
                    <Text block variant="smallPlus" className="d-inline">
                      {item.getProject()?.getTitleProject()}
                    </Text>
                  </div>

                  <div className="ms-Grid-row mt-1 ">
                    <Icon iconName="Calendar" className="text-icon" />
                    <Text block variant="smallPlus" className="d-inline">
                      {item.getProject()?.getStart().toFormat('dd/LL/yyyy')} -{' '}
                      {item.getProject()?.getEnd().toFormat('dd/LL/yyyy')}
                    </Text>
                  </div>
                </div>
            );
          },
        },
        {
          key: 'status',
          isResizable: true,
          name: t('Aceptar/Declinar'),
          fieldName: 'status',
          minWidth: 200,
          maxWidth: 200,
          className: 'fs-for-small-pill mt-2px',
          onRender: (activity: Activity) => {
            return (
                <div>
                  <ChoiceGroup
                      selectedKey={getSelectedKey(activity)}
                      options={optionsAcceptOrDecline}
                      onChange={(e, o) => onClickOption(activity, String(o!.key))}
                      styles={choiceGroupStyles}
                  />
                  {getSelectedKey(activity) && (
                      <Text
                          variant="smallPlus"
                          className="d-block link-styles ml-for-cancel-selection"
                          onClick={() => onCancelOption(activity)}
                      >
                        Cancelar selección
                      </Text>
                  )}
                </div>
            );
          },
        },
        {
          key: 'viewActivity',
          isResizable: true,
          name: t('Ver'),
          fieldName: 'viewActivity',
          minWidth: 75,
          maxWidth: 75,
          onRender: (item: Activity) => {
            return (
                <DefaultButton
                    iconProps={{ iconName: 'RedEye' }}
                    onClick={() => {
                      onClickVerActividad(item);
                    }}
                />
            );
          },
        },
      ],
      [
        activitiesToProcess,
        getSelectedKey,
        onClickOption,
        onClickVerActividad,
        showDialog,
        activitySelected,
        processedActivities,
      ]
  );

  const totalRows = totalPages;

  const handlePageChange = (action: string) => {
    if (action === 'next' && (page * perPage) < totalRows) {
      setPage(page + 1);
    } else if (action === 'back' && page > 1) {
      setPage(page - 1);
    } else if (action === 'toFirst') {
      setPage(1);
    } else if (action === 'toLast') {
      setPage(Math.ceil(totalRows / perPage));
    }
  }

  const handlePerPageChange = (event, item) => {
    setPerPage(item.key);
    setPage(1);
  };

  if (loading) {
    return (<div>Cargando...</div>);
  }

  return (
      <div>
        <ViewWrapperWithCommandBar title="" items={itemsMenu} withBorderBottom>
          <div className="padding-1">
            <div className="ms-Grid" dir="ltr">
              <div className="ms-Grid-row ">
                <div className="ms-Grid-col ms-md12 ">
                  <Text variant="medium" className="d-block">
                    {t('Esta es una sección en la que podrás ver todas las actividades que tienes pendientes')}
                  </Text>
                  <Text variant="medium">
                    {t('Seleccionando aceptar/declinar y luego procesandolas de forma rapida y sencilla')}
                  </Text>
                </div>
              </div>

              <div className="ms-Grid-row mt-2">
                <div className="ms-Grid-col ms-md12 ">
                  <Text variant="medium" className="d-block">
                    {t('En total tienes ')} {pendingActivities?.total} {t(' actividad(es), vas a aceptar ')}{' '}
                    {activitiesToProcess.filter((atp) => atp.accept).length} {t(' y vas a declinar ')}{' '}
                    {activitiesToProcess.filter((atp) => atp.decline).length}
                  </Text>
                </div>
              </div>

              <div className="ms-Grid-row mt-2">
                <div className="ms-Grid-col ms-md4 ">
                  <TextField
                      placeholder={t('Busca por actividad')}
                      className=""
                      value={activityTextValue}
                      onChange={(e: any) => {
                        const value = e.target.value;
                        const filtered = pendingActivities?.activities?.filter((act) =>
                            act.getName().toLowerCase().includes(value.toLowerCase())
                        );
                        setPendingActivitiesFiltered(filtered ? filtered : []);
                        setActivityTextValue(value);
                      }}
                  />
                </div>
                <div className="ms-Grid-col ms-md4 ">
                  <TextField
                      placeholder={t('Busca por proyecto')}
                      className=""
                      value={projectTextValue}
                      onChange={(e: any) => {
                        const value = e.target.value;
                        const filtered = pendingActivities?.activities?.filter((act) =>
                            act.getProject()?.getTitleProject().toLowerCase().includes(value.toLowerCase())
                        );
                        setPendingActivitiesFiltered(filtered ? filtered : []);
                        setProjectTextValue(value);
                      }}
                  />
                </div>
              </div>

              <div className="ms-Grid-row ">
                <div className="ms-Grid-col ms-md12 ">
                  {pendingActivitiesFiltered && pendingActivitiesFiltered.length > 0 ? (
                    <div> <DetailsList
                          className=""
                          items={pendingActivitiesFiltered}
                          columns={columns}
                          selectionMode={SelectionMode.none}
                          layoutMode={DetailsListLayoutMode.justified}
                          isHeaderVisible={true}
                          onShouldVirtualize={() => false}
                           />
                         <DetailListPagination
                          page={page} 
                          perPage={perPage}
                          totalRows={totalRows}
                          onChangePage={handlePageChange} 
                          onChangePerPage={handlePerPageChange}
                           />
                    </div>
                  ) : (
                      <Text variant="medium" className="text-center d-block">
                        {/* No tienes actividades pendientes */}
                      </Text>
                  )}
                </div>
              </div>
            </div>
          </div>
          {activitySelected && (
              <CalendarEventCallout
                  periodsBlocked={props.periodsBlocked}
                  system={props.system}
                  projects={projects ? projects : []}
                  user={props.user}
                  eventData={null}
                  targetId={''}
                  labelId={''}
                  descriptionId={''}
                  toggleIsCalloutVisible={() => {}}
                  isCalloutVisible={false}
                  commonContext={commonContext}
                  getEvents={() => {
                    return;
                  }}
                  courier={props.courier}
                  onlyShowDialogs={true}
                  activityProvided={activitySelected}
                  showDialog={showDialog}
                  toggleShowDialog={() => {
                    if (showDialog) {
                      setActivitySelected(undefined);
                    }
                    setShowDialog(!showDialog);
                  }}
                  changeBlockUI={props.changeBlockUI}
                  config={props.config}
                  hideBtnEdit={true}
                  hideMenuOptions={true}
              />
          )}
        </ViewWrapperWithCommandBar>
      </div>
  );
};
