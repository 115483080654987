import { mergeStyleSets, FontWeights } from '@fluentui/react';

export const styles = mergeStyleSets({
  button: {
    width: 130,
  },
  callout: {
    width: 420,
    maxWidth: '90%',
  },
  container: {
    padding: '20px 24px',
  },
  title: {
    marginBottom: 5,
    fontWeight: FontWeights.semibold,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  header: {
    minWidth: '100%',
    backgroundColor: '#2083d9',
    textAlign: 'right',
    padding: '5px 0px',
  },
  link: {
    display: 'block',
    marginTop: 20,
  },
  buttonControls: {
    padding: 0,
    backgroundColorFocussed: '#d0e7f8',
  },
  secondButtonControl: {
    marginLeft: 7,
    backgroundColorFocussed: '#d0e7f8',
  },
  footer: {
    backgroundColor: '#ebf5ff',
    textAlign: 'left',
    paddingLeft: '24px',
  },

  subtitleActivity: {
    fontSize: 16,
    marginBottom: 5,
  },
  descriptionActivity: {
    fontSize: 14,
    marginBottom: 5,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxHeight: '60px',
  },

  // New event

  calloutNewEvent: {
    width: 450,
    maxWidth: '90%',
  },
  input: {
    display: 'inline-block',
    width: '90%',
  },
  inputTextArea: {
    display: 'inline-block',
    width: '90%',
    paddingTop: 10,
  },
  footerNewEvent: {
    backgroundColor: '#ebf5ff',
    textAlign: 'left',
    padding: '8px 24px',
  },
  defaultButtonControl: {
    marginLeft: 7,
    // backgroundColorFocussed: '#d0e7f8',
  },
  moreOptions: {
    float: 'right',
    backgroundColorFocussed: '#d0e7f8',
    paddingBottom: 6,
  },
  inputDropdown: {
    display: 'inline-block',
    width: '90%',
    paddingTop: 10,
  },
  iconDropdown: {
    top: -5,
    fontSize: 19,
    marginRight: '0.5rem',
    position: 'relative',
  },
  iconComboBox: {
    top: 5,
    fontSize: 19,
    marginRight: '0.5rem',
    position: 'relative',
  },

  iconText: {
    top: 5,
    fontSize: 19,
    marginRight: '0.5rem',
    position: 'relative',
  },
  iconDelete: {
    marginLeft: '30px',
    cursor: 'pointer',
  },
  iconForText: {
    top: 3,
    fontSize: 19,
    marginRight: '0.5rem',
    position: 'relative',
  },
  iconForTextError: {
    top: 3,
    fontSize: 19,
    marginRight: '0.5rem',
    position: 'relative',
    color: 'red',
  },

  // documentToAttach: {
  //   margin: '5px 0px',
  //   width: '94%',
  // },
  inputCalendar: {
    display: 'inline-block',
    width: '55%',
    padding: '10px 27px 0px 27px',
  },
  inputCalendarReplicate: {
    display: 'inline-block',
    width: '100%',
    padding: '10px 0px',
  },
  inputDropdownDuracion: {
    display: 'inline-block',
    paddingTop: 10,
    margin: 0,
    width: '40%',
  },
  btnCommand: { height: '44px' },
  subtitle: { marginBottom: 5 },
  subtitleCard: { marginBottom: 5, fontWeight: 500, marginTop: 25 },
  subtitleCardDist: { marginBottom: 20, fontWeight: 500, marginTop: 25, paddingRight: 20 },
  dropdown: { width: '95%' },
  dropdownDocsCont: {
    marginBottom: '5px',
  },
  personaTeam: {
    marginTop: '5px',
    marginBottom: '5px',
  },
  facepileTeamContainer: {
    marginTop: '10px',
  },
  sectionText: {
    width: '85%',
    display: 'inline-block',
  },
  sectionDelete: {
    width: '15%',
    display: 'inline-block',
  },
  documentsSection: {
    padding: '3px 0px',
  },

  documentsSectionHover: {
    padding: '3px 0px',
    cursor: 'pointer',
  },
  groupIcon: {
    fontSize: 19,
    marginRight: '0.5rem',
    position: 'relative',
    top: '0.3rem',
  },
  facepileTeam: {
    display: 'inline-block',
  },
  sectionTextDist: {
    width: '70%',
    display: 'inline-block',
  },
  sectionInputDist: {
    width: '30%',
    display: 'inline-block',
  },
  distEmpContainer: {
    margin: '5px 0px',
  },

  dateSelector: {
    padding: '0px 19px',
  },
  dateSelectorCallout: {
    padding: '0px 5px 10px 20px',
  },
});
