import { useEffect, useState } from "react";
import { IInformacionGeneral, getCurrentDateTime, spanishDatePickerStrings } from "../collaboratorCreateView";
import formatearFecha from "../../../../common/formatDate";
import { DatePicker,Icon,TextField,Toggle, TooltipHost} from "@fluentui/react";
import { DateCustom } from "../../../../lib/DateCustom";
import { convertStringToDateProyectoEnalce } from "../../../../common/custom-hooks/TodayDate";


export const ProyectoEnlace = ({register,errors,setValue,watch}:IInformacionGeneral) => {

    const [clickPorcent,setClickPorcent] = useState<boolean>(false);
    const [minDateProyect,setMinDateProyect] = useState<Date | undefined>(undefined);
    const [minDateProyectFin,setMinDateProyectFin] = useState<Date | undefined>(undefined);
    const [pageRose,setPageRose] = useState<boolean>(false);
  
    const handleDateChange = (date,name:string) => {
      if(!date)return;
      register(name, { required: false });
      const formattedDate = formatearFecha(date);
      setValue(name,formattedDate); 
    };
  
    const handlerClick = ()=>{
      setClickPorcent(!clickPorcent);
    }

    useEffect(()=>{
  if (pageRose) {
    if(watch("vigencia")){
      const data = convertStringToDateProyectoEnalce(watch("vigencia"));
      setMinDateProyectFin(data);
    }else{
      setMinDateProyectFin(undefined);
    }
  }
    },[watch("vigencia")]);
    
    useEffect(()=>{
      if (pageRose === true) {
        if(watch("fechaingreso")){
        const data = convertStringToDateProyectoEnalce(watch("fechaingreso"));
        setMinDateProyect(data);
      }else{
        setMinDateProyect(undefined)
      }
      }else{  setPageRose(true)}
    },[watch("fechaingreso")])

    useEffect(()=>{
    register("vigencia", {required: clickPorcent});
    setValue("vigencia",""); 
    setValue("fechafin",""); 
    },[clickPorcent])
    return (
    <div className="form_informacion_general">
         <p className="titulo_form_create">
          <Icon iconName="script" style={{fontSize:"25px"}}  />
          Proyecto fijo
        </p>
        <div className="cuerpo_izquierdo_form">
          <span className="input_reusable" >
            <Toggle label="Pertenece al proyecto fijo" onClick={handlerClick}/>
          </span>
          <div style={{display:"flex",flexWrap:"wrap",width:"391px" ,height:"36px", justifyContent:"space-between"}}>
             <span className={clickPorcent ?"input_reusable":"input_desaparese"} >
              <TextField 
                key="000345" {...register("participacion",{required:clickPorcent,pattern:/^\d+$/,maxLength:3})}
                label="% Participacion" placeholder="Escriba el porcentage" type="text" 
                styles={{root:{width:"170px"}}} 
              />
              { errors.participacion?.type === "required" && <span className="error_TextFile_proyectofijo" >El % de participación no puede ser vacío.</span>}
              { errors.participacion?.type === "pattern" && <span className="error_TextFile_proyectofijo" >Este campo solo permite números.</span>}
              { errors.participacion?.type === "maxLength" && <span className="error_TextFile_proyectofijo" >Este campo solo permite tres dígitos.</span>}
              
              </span>
            <div style={{display:"flex",flexWrap:"wrap",gap:"5px"}}>
             <span className="label_reusable">
              <TooltipHost content={"Seleccione la fecha de ingreso"}>
               <DatePicker  allowTextInput
               strings={spanishDatePickerStrings}
               formatDate={DateCustom.formatDateForDatePicker}
                placeholder="Seleccione la fecha de ingreso"
                label="Vigencia"
                styles={{root:{width:"170px"}}} onSelectDate={(date)=>handleDateChange(date,"vigencia")}
                disabled={!clickPorcent}
                minDate={minDateProyect}
              />
              </TooltipHost>
             </span> 
             <p style={{marginTop:"30px"}}>hasta</p>
             <span style={{marginTop:"25px" }} >
              <TooltipHost content={"Seleccione la fecha de fin"}>
              <DatePicker  allowTextInput
                strings={spanishDatePickerStrings}
                formatDate={DateCustom.formatDateForDatePicker}
                placeholder="Seleccione la fecha de fin"
                styles={{root:{width:"170px"}}} onSelectDate={(date)=>handleDateChange(date,"fechafin")}
                disabled={!clickPorcent}
                minDate={minDateProyectFin}
              /></TooltipHost>
             </span>
  
            </div>
            { errors.vigencia && <span className="error_stilos_Organizacional">La fecha de ingreso al proyecto no puede ser vacío.</span>}
  
          </div>
        </div>
    </div>
  );
  }