import { downloadExcel } from 'react-export-table-to-excel';
import { IReporteGralHoras, IReportePreliquidacion } from '../../system/reporting/ReportingSystem';

class ReportByPreliquidationExporter {
  private constructor(
    private items: IReportePreliquidacion[],
    private fileName: string,
    private sheetName: string,
  ) {}

  static exportFor(items: IReportePreliquidacion[], fileName = 'Reporte', sheetName = 'reporte') {
    const exporter = new this(items, fileName, sheetName);
    exporter.export();
  }

  export() {
    downloadExcel({
      fileName: this.fileName,
      sheet: this.sheetName,
      tablePayload: {
        header: this.getHeader(),
        body: this.getBody(),
      },
    });
  }

  private getHeader() {
    return ['Concepto', 'Período', 'Pendientes', 'Horas confirmadas', 'Importe'];
  }

  getBody() {
    return this.items.map((item) => {
      return [
        item.concept,
        item.periodo,
        item.horas_pendientes,
        item.horas_confirmadas,
        item.importe_costo_total,
      ];
    });
  }
}

export default ReportByPreliquidationExporter;