import { DateTime } from 'luxon';
import { ICollaboratorPosition } from './CollaboratorSystem';
import { ProvidedCollaboratorPosition } from './ProvidedCollaboratorPosition';

export class CollaboratorPositionHelper {
  private id: string | undefined;
  private collaboratorId: string | undefined;
  private positionId: string | undefined;
  private nodeId: string | undefined;
  private from: DateTime | undefined;
  private to: DateTime | undefined;

  private constructor() {
    this.id = undefined;
    this.collaboratorId = undefined;
    this.positionId = undefined;
    this.nodeId = undefined;
    this.from = undefined;
    this.to = undefined;
  }

  static fromProvided(collaboratorPosition: ProvidedCollaboratorPosition) {
    const instance = new this();
    instance.setId(collaboratorPosition.getId());
    instance.collaboratorId = collaboratorPosition.getCollaboratorId();
    instance.from = collaboratorPosition.getFrom();
    const to = collaboratorPosition.getTo();
    instance.to = to || undefined;
    collaboratorPosition.getOrganizationNode().then((node) => {
      instance.setNodeId(node.getId());
    });
    collaboratorPosition.getPosition().then((position) => {
      instance.setPositionId(position.getId());
    });

    return instance;
  }

  static fromNotProvided() {
    return new this();
  }

  toICollaboratorPosition(): ICollaboratorPosition {
    return {
      carcol_id: this.id || '',
      carcol_colid: this.collaboratorId || '',
      carcol_norid: this.nodeId || '',
      carcol_cargoid: this.positionId || '',
      carcol_activo: true,
      carcol_fecha_desde: this.from ? this.from.toUTC().toISO()! : '',
      carcol_fecha_hasta: this.to ? this.to.toUTC().toISO()! : '',
      cargo: null,
    };
  }

  setPositionId(id: string) {
    this.positionId = id;
  }

  setNodeId(id: string) {
    this.nodeId = id;
  }

  setId(id: string) {
    this.id = id;
  }

  getPositionId() {
    return this.positionId;
  }

  validateId() {
    return !!this.positionId && this.positionId.length > 0 && !!this.nodeId && this.nodeId.length > 0;
  }
}
