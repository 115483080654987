import {
  CheckboxVisibility,
  DefaultButton,
  DefaultPalette,
  DetailsList,
  DetailsListLayoutMode,
  Dropdown,
  IColumn,
  Icon,
  IconButton,
  IContextualMenuProps,
  IIconProps,
  PrimaryButton,
  Text,
  TextField,
} from '@fluentui/react';
import { ICommandBarItemProps } from '@fluentui/react/lib/CommandBar';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { routes } from '../../../common/constants';
import MessageCourier from '../../../lib/MessageCourier';
import { defaultCommandBarButtonStyles, titleCommandBarButtonStyles } from '../../../styles/button-styles';
import TimeTaxSupportSystemClient from '../../../system';
import SystemUploadedDocument from '../../../system/documents/SystemUploadedDocument';
import { Settlement } from '../../../system/settlement/Settlement';
import { SettlementProjectInPeriod } from '../../../system/settlement/SettlementProjectInPeriod';
import { SettlementProjectInPeriodItem } from '../../../system/settlement/SettlementProjectInPeriodItem';
import { SettlementRelatedDocument } from '../../../system/settlement/SettlementRelatedDocument';
import ViewWrapperWithCommandBar from '../../Layout/ViewWrapperWithCommandBar';
import { useTranslation } from 'react-i18next';
import {
  ColumnGroupedDetailsList,
  GroupedDetailList,
} from '../../Common/GroupedDetailList/GroupedDetailList';
import { formatNumberToLocale } from '../../../common/list-utils';
import { ProjectStatus } from '../../Common/ProjectStatus';

interface IDirectSettlementDetail {
  system: TimeTaxSupportSystemClient;
  courier: MessageCourier;
  changeBlockUI: (v: boolean) => void;
}

export interface ISettlementItemsGrouped {
  ldi_sector: string;
  ldi_periodo: string;
  promedio_costo_hora: string;
  ldi_horas_confirmadas: string;
  ldi_importe_costo_neto: string;
  ldi_importe_costo_total: string;
  concept: string;
}

interface IFormattedGroups {
  key: string;
  name: string;
  startIndex: number;
  count: number;
  level?: number;
  isCollapsed?: boolean;
}

const DirectSettlementDetail = (props: IDirectSettlementDetail) => {
  const { t } = useTranslation();
  const [settlement, setSettlement] = useState<Settlement>();
  const [settlementItems, setSettlementItems] = useState<ISettlementItemsGrouped[]>();
  const [itemsFiltered, setItemsFiltered] = React.useState<ISettlementItemsGrouped[]>([]);
  let { id } = useParams();
  const navigate = useNavigate();
  const TEMPORARY_KEY = t('Provisoria');
  const CONFIRMED_KEY = t('Confirmada');

  const AllItems = React.useMemo(() => {
    return settlementItems;
  }, [settlementItems]);

  const iconProps = { iconName: 'Search' };

  React.useEffect(() => {
    if (AllItems) setItemsFiltered(AllItems);
  }, [AllItems]);

  let projectStatus = t('Inactivo');

  const [groupBy, setGroupBy] = React.useState<string>('empresa');

  const options = React.useMemo(() => {
    return [
      { key: 'empresa', text: t('Empresa') },
      { key: 'colaborador', text: t('Colaborador') },
    ];
  }, []);

  const GlobalNavButtonIcon: IIconProps = { iconName: 'GlobalNavButton' };

  const contextualMenuItems = [
    {
      key: 'confirm',
      text: t('Confirmar'),
      onClick: () => {
        confirm();
      },
      iconProps: { iconName: 'ActivateOrders' },
      className: settlement && settlement.getStatus() == TEMPORARY_KEY ? '' : 'hide-project-btn',
    },
    {
      key: 'reject',
      text: t('Rechazar'),
      onClick: () => {
        deactivate();
      },
      iconProps: { iconName: 'DeactivateOrders' },
      // className: projectStatus === t('Inactivo') ? 'hide-project-btn' : '',
    },
    // {
    //   key: 'finalize',
    //   text: t('Finalize'),
    //   // onClick: () => {
    //   //   finalizeProject();
    //   // },
    //   iconProps: { iconName: 'Completed' },
    //   className: projectStatus === t('Finalizado') ? 'hide-project-btn' : '',
    // },
  ];

  const menuProps: IContextualMenuProps = {
    shouldFocusOnMount: true,
    items: contextualMenuItems,
  };

  // React.useEffect(() => {
  //   if (groupBy) {
  //     getSettlementItems(groupBy);
  //   }
  // }, [groupBy]);
  const companyColumns: ColumnGroupedDetailsList[] = React.useMemo(() => {
    let ret = [
      {
        key: 'concept',
        name: groupBy == 'empresa' ? t('Empresa') : t('Colaborador'),
        fieldName: 'concept',
        couldGroup: true,
        minWidth: 200,
      },
      {
        key: 'ldi_sector',
        name: t('Sector'),
        fieldName: 'ldi_sector',
        couldGroup: true,
        minWidth: 350,
      },

      {
        key: 'ldi_periodo',
        name: t('Período'),
        fieldName: 'ldi_periodo',
        couldGroup: true,
        minWidth: 100,
        maxWidth: 100,
      },
      {
        key: 'ldi_horas_confirmadas',
        name: t('Horas chequeadas'),
        fieldName: 'ldi_horas_confirmadas',
        className: 'ta-end',
        couldTotal: true,
        minWidth: 100,
        maxWidth: 100,
        // formatTotal: (number: number) => {
        //   return <span>{number.toFixed(2)}</span>;
        // },

        onRender: (item: any) => {
          return <span>{formatNumberToLocale(Number(item.ldi_horas_confirmadas))}</span>;
        },

        onRenderHeader: (item: any) => {
          return (
              <span>
              <Icon iconName="Clock" title={t('Horas')} /> &nbsp; {t('Confirmadas')}
            </span>
          );
        },
      },
      {
        key: 'ldi_importe_costo_total',
        name: t('Importe'),
        fieldName: 'ldi_importe_costo_total',
        className: 'ta-end',
        couldTotal: true,
        minWidth: 100,
        maxWidth: 100,
        // formatTotal: (number: number) => {
        //   return <span>{+number.toFixed(2)}</span>;
        // },
        onRenderHeader: (item: any) => {
          return (
              <span>
              <Icon iconName="Money" title={t('Importe')} /> &nbsp; {t('Importe')}
            </span>
          );
        },

        onRender: (item: any) => {
          return (
              <span>
              {'$'} {formatNumberToLocale(Number(item.ldi_importe_costo_total))}
            </span>
          );
        },
      },
    ];
    if (groupBy == 'empresa') {
      // remueve la columna de sector
      ret.splice(1, 1);
    }
    return ret;
  }, [groupBy]);

  const filterItems = (val: string) => {
    if (AllItems) {
      let _ret: ISettlementItemsGrouped[] = [];
      _ret = AllItems.filter((item) => item.concept.toLowerCase().includes(val.toLowerCase()));
      setItemsFiltered(_ret);
    }
  };

  const getSettlementResponse = async () => {
    if (id) {
      // props.changeBlockUI(true);
      props.changeBlockUI(true);
      try {
        const settlement = await props.system.getSettlementSystem().getById(id);
        if (settlement) {
          setSettlement(settlement);
        }
        await getSettlementItems();
      } catch (error) {
        props.courier.messageError(error);
      } finally {
        props.changeBlockUI(false);
      }
    }
  };

  const getSettlementItems = async (concept?: string) => {
    if (id) {
      props.changeBlockUI(true);
      try {
        const settlementItems = await props.system.getSettlementSystem().getItemsGroupedById(id, concept);
        setSettlementItems(settlementItems);
      } catch (error) {
        props.courier.messageError(error);
      } finally {
        props.changeBlockUI(false);
      }
    }
  };

  const confirm = async () => {
    if (id) {
      props.changeBlockUI(true);
      try {
        await props.system.getSettlementSystem().confirm(id);
      } catch (error) {
        props.courier.messageError(error);
      } finally {
        props.changeBlockUI(false);
        getSettlementResponse();
      }
    }
  };

  const deactivate = async () => {
    if (id) {
      props.changeBlockUI(true);
      try {
        await props.system.getSettlementSystem().deactivate(id);
      } catch (error) {
        props.courier.messageError(error);
      } finally {
        props.changeBlockUI(false);
        getSettlementResponse();
      }
    }
  };

  useEffect(() => {
    if (id) {
      getSettlementResponse();
      // getSettlementItems();
    }
  }, [id]);

  const getSettlementPDF = async (settlement: Settlement) => {
    await props.system.getSettlementSystem().downloadSettlementItems(settlement.getId());
  };

  const itemsMenuCalendar = React.useMemo(
      (): ICommandBarItemProps[] => [
        {
          key: 'title',
          text: t('Liquidaciones directas'),
          iconProps: {
            iconName: 'ReminderPerson',
            color: DefaultPalette.themeLighterAlt,
            className: 'text-title-btn',
          },
          buttonStyles: titleCommandBarButtonStyles,
          className: 'text-title-btn',
        },
        {
          key: 'back',
          text: t('Volver'),
          iconProps: {
            iconName: 'Back',
            color: DefaultPalette.themeLighterAlt,
            className: 'text-title-btn',
          },
          buttonStyles: titleCommandBarButtonStyles,
          className: 'text-title-btn',
          onClick: () => {
            navigate(`${routes.direct_settlement_list}`);
          },
        },
        {
          key: 'modificar',
          text: t('Modificar'),
          iconProps: { iconName: 'Edit', color: DefaultPalette.themeLighterAlt },
          buttonStyles: titleCommandBarButtonStyles,
          disabled: true,
        },
      ],
      []
  );

  const formatDate = () => {
    if (settlement) {
      return settlement.getCreationDate().toFormat('dd/LL/yyyy HH:mm');
    }
  };

  return (
      <ViewWrapperWithCommandBar
          withBorderBottom
          title={''}
          items={itemsMenuCalendar}
          overflowButtonStyles={defaultCommandBarButtonStyles}
          variant="highligth"
          titleBody={t('Liquidaciones')}
      >
        {settlement && settlementItems && (
            <div className="padding-1">
              <div className="ms-Grid bg-white  padding-1 " dir="ltr">
                <div className="ms-Grid-row ">
                  <div className="ms-Grid-col ms-lg6 ms-sm8 ">
                    <div className="ms-Grid-row">
                      <div className="ms-Grid-col ms-sm8">
                        <Text variant="large" className="fw-500">
                          {t('Detalle de liquidacion directa: ') + id}
                        </Text>
                      </div>
                      <div className="ms-Grid-col ms-sm4">
                        <ProjectStatus status={settlement.getStatus()} />

                        {settlement.getStatus() == TEMPORARY_KEY ? (
                            <IconButton
                                className="float-right"
                                iconProps={GlobalNavButtonIcon}
                                title="Opciones de actividad"
                                ariaLabel="GlobalNavButton"
                                menuProps={menuProps}
                            />
                        ) : null}

                        {settlement.getStatus() === CONFIRMED_KEY ? (
                            <IconButton
                                className="float-right"
                                iconProps={GlobalNavButtonIcon}
                                title="Opciones de actividad"
                                ariaLabel="GlobalNavButton"
                                menuProps={menuProps}
                            />
                        ) : null}

                        {/* <DefaultButton
                      text={t('Recargar items')}
                      iconProps={{ iconName: 'Refresh' }}
                      // onClick={() => getItems(settlement)}
                    ></DefaultButton> */}
                      </div>
                    </div>
                    <div className="ms-Grid-row mt-2">
                      <div className="ms-Grid-col ms-lg12 ms-sm12">
                        {' '}
                        <Text variant="medium">
                          <Icon iconName="ReminderPerson" className="text-icon"></Icon>
                          {t('Generada por: ')}
                          <span className="fw-500">{settlement.getCollaborator()?.fullName()}</span>
                        </Text>
                      </div>
                      <div className="ms-Grid-col ms-lg12 ms-sm12 mt-1">
                        {' '}
                        <Text variant="medium">
                          <Icon iconName="PublicCalendar" className="text-icon"></Icon>
                          {t('Período: ')}
                          <span className="fw-500">{settlement.period()}</span>
                        </Text>
                      </div>
                      <div className="ms-Grid-col ms-lg12 ms-sm12 mt-1">
                        {' '}
                        <Text variant="medium">
                          <Icon iconName="Money" className="text-icon"></Icon>
                          {t('Total liquidado: $')}
                          <span className="fw-500">{formatNumberToLocale(Number(settlement.getTotal()))}</span>
                        </Text>
                      </div>
                      {/* <div className="ms-Grid-col ms-lg12 ms-sm12 mt-1">
                    {' '}
                    <Text variant="medium">
                      <Icon iconName={'TeamWork'} className="text-icon"></Icon>
                      {t('Cantidad de proyectos: ')}
                      <span className="fw-500">{settlementItems.cant_proyectos}</span>
                    </Text>{' '}
                  </div> */}
                    </div>
                    <div className="ms-Grid-row ">
                      {/* <div className="ms-Grid-col ms-lg12 ms-sm12 mt-1">
                    {' '}
                    <Text variant="medium">
                      <Icon iconName="DocumentApproval" className="text-icon"></Icon>
                      {t('Nro. Factura: ')}
                      <span className="fw-500"> {settlement.getInvoiceNumber()} </span>
                    </Text>
                  </div> */}
                      <div className="ms-Grid-col ms-lg12 ms-sm12 mt-1">
                        {' '}
                        <Text variant="medium">
                          <Icon iconName="Market" className="text-icon"></Icon>
                          {t('Estado: ')}
                          <span className="fw-500">{settlement.getStatus()}</span>
                        </Text>
                      </div>
                      <div className="ms-Grid-col ms-lg12 ms-sm12 mt-1">
                        {' '}
                        <Text variant="medium">
                          <Icon iconName="CityNext2" className="text-icon"></Icon>
                          {t('Modo de distribución facturable en base a configuración ')}
                          {settlement.getModeBillableDistribution() === 'A' && (
                              <span className="fw-500">{'de actividades'}</span>
                          )}
                          {settlement.getModeBillableDistribution() === 'P' && (
                              <span className="fw-500">{'de proyectos'}</span>
                          )}
                          {settlement.getModeBillableDistribution() === 'H' && (
                              <span className="fw-500">{'híbrida'}</span>
                          )}
                        </Text>
                      </div>

                      {/* <div className="ms-Grid-col ms-lg12 ms-sm12 mt-1">
                    {' '}
                    <Text variant="medium">
                      <Icon iconName="Money" className="text-icon"></Icon>
                      {t('Importe total: ')}
                      <span className="fw-500">${settlementItems.importe_total}</span>
                    </Text>
                  </div> */}
                    </div>
                    <div className="ms-Grid-row ">
                      <div className="ms-Grid-col ms-lg12 ms-sm12 mt-1">
                        {' '}
                        <Text variant="medium">
                          <Icon iconName="EventDate" className="text-icon"></Icon>
                          {t('Creada el: ')}
                          <span className="fw-500">{formatDate()}</span>
                        </Text>
                      </div>
                    </div>
                  </div>
                  <div className="ms-Grid-col ms-sm6 stretch-column">
                    <div className="ms-Grid-row">
                      <div className="ms-Grid-col ms-sm4">
                        {/* <ProjectStatus status={settlement.getStatus()} />

                    <IconButton
                      className="float-right"
                      iconProps={GlobalNavButtonIcon}
                      title="Opciones de actividad"
                      ariaLabel="GlobalNavButton"
                      menuProps={menuProps}
                    /> */}

                        {/* <DefaultButton
                      text={t('Recargar items')}
                      iconProps={{ iconName: 'Refresh' }}
                      // onClick={() => getItems(settlement)}
                    ></DefaultButton> */}
                      </div>
                      <div className="ms-Grid-col ms-sm6 align-bottom " style={{ float: 'right' }}>
                        <PrimaryButton
                            className="float-right"
                            text={t('Descargar liquidación')}
                            iconProps={{ iconName: 'ExcelLogo', color: DefaultPalette.themeLighterAlt }}
                            onClick={() => getSettlementPDF(settlement)}
                        ></PrimaryButton>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="ms-Grid" dir="ltr">
                <div className="ms-Grid-row">
                  <div className="ms-Grid-col ms-sm6">
                    <Dropdown
                        onChange={(e, option) => {
                          const concept = String(option!.key);
                          setGroupBy(concept);
                          getSettlementItems(concept);
                        }}
                        options={options}
                        label={t('Agrupar por')}
                        selectedKey={[groupBy]}
                    />
                  </div>
                  <div className="ms-Grid-col ms-sm6">
                    <TextField
                        iconProps={iconProps}
                        label={t('Buscar')}
                        onChange={(e: any) => {
                          const val = e.target.value;
                          filterItems(val);
                        }}
                    ></TextField>
                  </div>
                </div>
              </div>
              <div className="ms-Grid-row">
                <div className="ms-Grid-col ms-sm12">
                  {settlementItems && (
                      <GroupedDetailList columns={companyColumns} items={itemsFiltered} hideGroupBy={true} />
                  )}
                </div>
              </div>
            </div>
        )}
      </ViewWrapperWithCommandBar>
  );
};

export default DirectSettlementDetail;
