import * as React from 'react';
import { Announced } from '@fluentui/react/lib/Announced';
import { TextField, ITextFieldStyles } from '@fluentui/react/lib/TextField';
import {
  DetailsList,
  DetailsListLayoutMode,
  Selection,
  IColumn,
  CheckboxVisibility,
} from '@fluentui/react/lib/DetailsList';
import { MarqueeSelection } from '@fluentui/react/lib/MarqueeSelection';
import { mergeStyles } from '@fluentui/react/lib/Styling';
import { Text } from '@fluentui/react/lib/Text';
import { DetailsListView } from '../../../Common/DetailsList/DetailListView';
import { defenseFileAssistantDropdownValues } from '../../../../common/constants';
import i18next, { t } from 'i18next';

const exampleChildClass = mergeStyles({
  display: 'block',
  marginBottom: '10px',
});

const textFieldStyles: Partial<ITextFieldStyles> = { root: { maxWidth: '300px' } };

export interface IDetailsListBasicExampleItem {
  key: number;
  name: string;
  value: number;
}

export interface IDetailsListBasicExampleState {
  items: IDetailsListBasicExampleItem[];
  selectionDetails: string;
}

interface IProjectsTable {
  data: any;
  type: string;
  getIdsFromTable: any;
  activityReport?: boolean;
  setButtonDisability: (can: boolean) => void;
}

export class DocumentTable extends React.Component<IProjectsTable, IDetailsListBasicExampleState> {
  private _selection: Selection;
  private _allItems: IDetailsListBasicExampleItem[];
  private _columns: IColumn[];

  private formatProjectName(item: any) {
    return `(${item.pro_codigo}) - ${item.pro_nombre}`;
  }

  constructor(props: IProjectsTable) {
    super(props);

    this._selection = new Selection({
      onSelectionChanged: () => {
        this.setState({ selectionDetails: this._getSelectionDetails() });
      },
    });

    // Populate with items for demos.
    this._allItems = this.props.data;
    // for (let i = 0; i < this.props.data.length; i++) {
    //   this._allItems.push({
    //     key: i,
    //     name: 'Item ' + i,
    //     value: i,
    //   });
    // }

    this._columns = [
      {
        key: 'doc_nombre',
        name: t('Documento'),
        fieldName: 'doc_nombre',
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
        // onRender: (item: any) => this.formatProjectName(item),
      },

      {
        key: 'doct_nombre',
        name: t('Tipo Doc'),
        fieldName: 'doct_nombre',
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
      },
      {
        key: 'pro_nombre',
        name: t('Proyecto'),
        fieldName: 'pro_nombre',
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
      },
      {
        key: 'type',
        name: t('Tipo de carga'),
        fieldName: 'type',
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
      },
      {
        key: 'col_nombre',
        name: t('Responsable'),
        fieldName: 'col_nombre',
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
      },
      {
        key: 'doc_carga_fecha',
        name: t('Cargado el'),
        fieldName: 'doc_carga_fecha',
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
      },
    ];

    this.state = {
      items: this._allItems,
      selectionDetails: this._getSelectionDetails(),
    };
  }

  public render(): JSX.Element {
    const { items, selectionDetails } = this.state;

    //Acá no se si conviene usar este tipo de tabla de fluent o el componente que usamos en activitycardlist
    if (this.props.data && this.props.data.length > 0) {
      return (
          <div>
            <div className={exampleChildClass}>{selectionDetails}</div>
            <Announced message={selectionDetails} />
            {/* <Announced message={`Number of items after filter applied: ${items.length}.`} /> */}
            <MarqueeSelection selection={this._selection}>
              <DetailsList
                  className="projects-table"
                  items={items}
                  columns={this._columns}
                  setKey="set"
                  layoutMode={DetailsListLayoutMode.justified}
                  checkboxVisibility={CheckboxVisibility.always}
                  selection={this._selection}
                  selectionPreservedOnEmptyClick={true}
                  ariaLabelForSelectionColumn="Toggle selection"
                  ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                  checkButtonAriaLabel="select row"
                  onItemInvoked={this._onItemInvoked}
                  onShouldVirtualize={() => false}
              />
            </MarqueeSelection>
          </div>
      );
    } else return <div>{t('No se han encontrado proyetos disponibles para seleccionar')} </div>;
  }

  private _getSelectionDetails(): string | undefined | any {
    const selectionCount = this._selection.getSelectedCount();

    let idList: any = [];
    for (let i = 0; i < this._selection.getSelectedCount(); i++) {
      let selection = this._selection.getSelection()[i];
      //@ts-ignore
      idList.push(selection.doc_id);
    }
    if (this.props.getIdsFromTable) {
      this.props.getIdsFromTable(idList);
    }
    if (this.props.setButtonDisability) {
      switch (selectionCount) {
        case 0:
          this.props.setButtonDisability(false);
          return t('0 proyectos seleccionados');
        case 1:
          this.props.setButtonDisability(true);
          //@ts-ignore
          return t('1 proyecto seleccionado: ') + this._selection.getSelection()[0].pro_nombre;
        default:
          this.props.setButtonDisability(true);
          return selectionCount + t(' proyectos seleccionados');
      }
    }
  }

  private _onFilter = (ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, text: string): void => {
    this.setState({
      items: text ? this._allItems.filter((i) => i.name.toLowerCase().indexOf(text) > -1) : this._allItems,
    });
  };

  private _onItemInvoked = (item: IDetailsListBasicExampleItem): void => {};
}
