import React, { useState, useEffect } from 'react';
import { DatePicker } from '@fluentui/react';
import { Label } from '@fluentui/react';
import { Dropdown, IDropdownStyles, IDropdownOption } from '@fluentui/react/lib/Dropdown';
import './dateSelector.css';
import { DateCustom } from '../../lib/DateCustom';
import { t } from 'i18next';
import { getLocalizedStrings } from '../../lib/LocalizedStringsForDatePicker';

interface IDateSelector {
  onChangeDuration?: (duration: any) => void;
  onChangeStart?: (start: Date) => void;
  onChangeEnd?: (end: Date) => void;
  defaultStartDate?: Date;
  disabled?: boolean;
  // Pasar duracion en horas, (por ahora, se puede mejorar)
  defaultDuration?: number;
  minDate?: Date | undefined;
  maxDate?: Date | undefined;
}

export const DateSelector = (props: IDateSelector) => {
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [selectedDuracion, setSelectedDuracion] = useState<Number | null>(null);
  const [selectedHora, setSelectedHora] = useState<Number | null>(null);
  const [selectedMinuto, setSelectedMinuto] = useState<Number | null>(null);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [hasBeenInitialized, setHasBeenInitialized] = useState<boolean>(false);
  const horaMultiplier = 60 * 60000;
  const minutoMultiplier = 60000;

  useEffect(() => {
    if (!hasBeenInitialized) {
      if (props.defaultStartDate) {
        setStartDate(props.defaultStartDate);
        setSelectedDate(props.defaultStartDate);
        setSelectedHora(props.defaultStartDate.getHours());
        setSelectedMinuto(props.defaultStartDate.getMinutes());
      }
      if (props.defaultDuration) {
        // Agrego el + '' porque necesito que sea un string
        setSelectedDuracion(props.defaultDuration * horaMultiplier);
      }
      setHasBeenInitialized(true);
    }
  }, [props.defaultStartDate, props.defaultDuration, hasBeenInitialized]);

  useEffect(() => {
    if (selectedDate && selectedHora && selectedMinuto) {
      calculateStartDate(selectedDate, selectedHora, selectedMinuto);
    } else if (selectedDate && selectedHora == null && selectedMinuto == null) {
      setSelectedHora(0);
      setSelectedMinuto(0);
      calculateStartDate(selectedDate, 0, 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate, selectedHora, selectedMinuto]);

  useEffect(() => {
    if (selectedHora && !selectedMinuto) {
      setSelectedMinuto(0);
    }
  }, [selectedHora]);

  useEffect(() => {
    if (selectedDate) {
      calculateEndDate(selectedDate, selectedHora, selectedMinuto, selectedDuracion);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate, selectedHora, selectedMinuto, selectedDuracion]);

  useEffect(() => {
    if (selectedDuracion && props.onChangeDuration) props.onChangeDuration(selectedDuracion);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDuracion]);

  useEffect(() => {
    if (startDate && props.onChangeStart) props.onChangeStart(startDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate]);

  useEffect(() => {
    if (endDate && props.onChangeEnd) props.onChangeEnd(endDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endDate]);

  const calculateStartDate = (fecha: Date, hora: any, minuto: any) => {
    if (fecha && (hora || hora == 0) && (minuto || minuto == 0)) {
      let start = new Date(fecha.setHours(hora, minuto));
      setStartDate(start);
      return start;
    }
  };

  const calculateEndDate = (fecha: Date, hora: any, minuto: any, duracion: any) => {
    if (fecha) {
      let start = calculateStartDate(fecha, parseInt(hora), parseInt(minuto));
      if (start instanceof Date) {
        let end = new Date(start.getTime() + duracion);
        setEndDate(end);
        return end;
      }
    }
  };

  const duracionOptions = React.useMemo(() => {
    let ret = [
      { key: '' + 15 * minutoMultiplier, text: t('15 minutos') },
      { key: '' + 30 * minutoMultiplier, text: t('30 minutos') },
      { key: '' + 45 * minutoMultiplier, text: t('45 minutos') },
      { key: '' + 60 * minutoMultiplier, text: t('1 hora') },
      { key: '' + 75 * minutoMultiplier, text: t('1 hora y 15 minutos') },
      { key: '' + 90 * minutoMultiplier, text: t('1 hora y 30 minutos') },
      { key: '' + 105 * minutoMultiplier, text: t('1 hora y 45 minutos') },
      { key: '' + 120 * minutoMultiplier, text: t('2 horas') },
      { key: '' + 135 * minutoMultiplier, text: t('2 horas y 15 minutos') },
      { key: '' + 150 * minutoMultiplier, text: t('2 horas y 30 minutos') },
      { key: '' + 165 * minutoMultiplier, text: t('2 horas y 45 minutos') },
      { key: '' + 180 * minutoMultiplier, text: t('3 horas') },
      { key: '' + 195 * minutoMultiplier, text: t('3 horas y 15 minutos') },
      { key: '' + 210 * minutoMultiplier, text: t('3 horas y 30 minutos') },
      { key: '' + 225 * minutoMultiplier, text: t('3 horas y 45 minutos') },
      { key: '' + 240 * minutoMultiplier, text: t('4 horas') },
      { key: '' + 255 * minutoMultiplier, text: t('4 horas y 15 minutos') },
      { key: '' + 270 * minutoMultiplier, text: t('4 horas y 30 minutos') },
      { key: '' + 285 * minutoMultiplier, text: t('4 horas y 45 minutos') },
      { key: '' + 300 * minutoMultiplier, text: t('5 horas') },
      { key: '' + 315 * minutoMultiplier, text: t('5 horas y 15 minutos') },
      { key: '' + 330 * minutoMultiplier, text: t('5 horas y 30 minutos') },
      { key: '' + 345 * minutoMultiplier, text: t('5 horas y 45 minutos') },
      { key: '' + 360 * minutoMultiplier, text: t('6 horas') },
      { key: '' + 375 * minutoMultiplier, text: t('6 horas y 15 minutos') },
      { key: '' + 390 * minutoMultiplier, text: t('6 horas y 30 minutos') },
      { key: '' + 405 * minutoMultiplier, text: t('6 horas y 45 minutos') },
      { key: '' + 420 * minutoMultiplier, text: t('7 horas') },
      { key: '' + 435 * minutoMultiplier, text: t('7 horas y 15 minutos') },
      { key: '' + 450 * minutoMultiplier, text: t('7 horas y 30 minutos') },
      { key: '' + 465 * minutoMultiplier, text: t('7 horas y 45 minutos') },
      { key: '' + 480 * minutoMultiplier, text: t('8 horas') },
    ];

    if (props.defaultDuration) {
      const defaultDurationInHours = props.defaultDuration;
      const opExist = ret.find((op) => +op.key === +defaultDurationInHours * horaMultiplier);
      if (!opExist) {
        const durationInMillis = +defaultDurationInHours * horaMultiplier;

        let iToPush = -1;
        ret.forEach((op, i) => {
          const next = ret[i + 1];
          if (i == 0 && +op.key > durationInMillis) {
            iToPush = i;
          }
          if (durationInMillis && next && durationInMillis > +op.key && durationInMillis < +next.key) {
            iToPush = i + 1;
          }
          if (!next && durationInMillis > +op.key) {
            iToPush = i + 1;
          }
        });

        if (iToPush > -1 && props.defaultDuration) {
          ret.splice(iToPush, 0, {
            key: '' + durationInMillis,
            text: DateCustom.convertirHorasAHoraYMinutos(props.defaultDuration),
          });
        }
      }
    }

    return ret;
  }, [props.defaultDuration, selectedDuracion]);

  // const duracionOptions = [
  //   { key: '' + 15 * minutoMultiplier, text: t('15 minutos') },
  //   { key: '' + 30 * minutoMultiplier, text: t('30 minutos') },
  //   { key: '' + 45 * minutoMultiplier, text: t('45 minutos') },
  //   { key: '' + 60 * minutoMultiplier, text: t('1 hora') },
  //   { key: '' + 90 * minutoMultiplier, text: t('1 hora y 30 minutos') },
  //   { key: '' + 120 * minutoMultiplier, text: t('2 horas') },
  //   { key: '' + 150 * minutoMultiplier, text: t('2 horas y 30 minutos') },
  //   { key: '' + 180 * minutoMultiplier, text: t('3 horas') },
  //   { key: '' + 210 * minutoMultiplier, text: t('3 horas y 30 minutos') },
  //   { key: '' + 240 * minutoMultiplier, text: t('4 horas') },
  //   { key: '' + 270 * minutoMultiplier, text: t('4 horas y 30 minutos') },
  //   { key: '' + 300 * minutoMultiplier, text: t('5 horas') },
  //   { key: '' + 330 * minutoMultiplier, text: t('5 horas y 30 minutos') },
  //   { key: '' + 360 * minutoMultiplier, text: t('6 horas') },
  //   { key: '' + 390 * minutoMultiplier, text: t('6 horas y 30 minutos') },
  //   { key: '' + 420 * minutoMultiplier, text: t('7 horas') },
  //   { key: '' + 450 * minutoMultiplier, text: t('7 horas y 30 minutos') },
  //   { key: '' + 480 * minutoMultiplier, text: t('8 horas') },
  // ];

  const horaOptions = [
    { key: '0', text: '00' },
    { key: '1', text: '01' },
    { key: '2', text: '02' },
    { key: '3', text: '03' },
    { key: '4', text: '04' },
    { key: '5', text: '05' },
    { key: '6', text: '06' },
    { key: '7', text: '07' },
    { key: '8', text: '08' },
    { key: '9', text: '09' },
    { key: '10', text: '10' },
    { key: '11', text: '11' },
    { key: '12', text: '12' },
    { key: '13', text: '13' },
    { key: '14', text: '14' },
    { key: '15', text: '15' },
    { key: '16', text: '16' },
    { key: '17', text: '17' },
    { key: '18', text: '18' },
    { key: '19', text: '19' },
    { key: '20', text: '20' },
    { key: '21', text: '21' },
    { key: '22', text: '22' },
    { key: '23', text: '23' },
  ];

  // const minutoOptions = [
  //   { key: '0', text: '00' },
  //   { key: '15', text: '15' },
  //   { key: '30', text: '30' },
  //   { key: '45', text: '45' },
  // ];

  const minutoOptions = React.useMemo(() => {
    let ret = [
      { key: '0', text: '00' },
      { key: '15', text: '15' },
      { key: '30', text: '30' },
      { key: '45', text: '45' },
    ];

    if (props.defaultStartDate) {
      const defaultMinutes = props.defaultStartDate.getMinutes();
      const opExist = ret.find((op) => +op.key === +defaultMinutes);
      if (!opExist) {
        let iToPush = -1;
        ret.forEach((op, i) => {
          const next = ret[i + 1];
          if (i == 0 && +op.key > defaultMinutes) {
            iToPush = i;
          }
          if (defaultMinutes && next && defaultMinutes > +op.key && defaultMinutes < +next.key) {
            iToPush = i + 1;
          }
          if (!next && defaultMinutes > +op.key) {
            iToPush = i + 1;
          }
        });

        if (iToPush > -1 && props.defaultStartDate.getMinutes()) {
          ret.splice(iToPush, 0, {
            key: '' + props.defaultStartDate.getMinutes(),
            text: '' + props.defaultStartDate.getMinutes(),
          });
        }
      }
    }

    return ret;
  }, [props.defaultDuration, props.defaultStartDate]);

  const comboBoxStyles: Partial<IDropdownStyles> = {
    root: { minWidth: 60, maxHeight: 300 },
    dropdownItemsWrapper: { maxHeight: 600 },
    //optionsContainer: { minWidth: 70, maxHeight: 300 },
  };

  const onRenderCaretDown = (): JSX.Element => {
    return <></>;
  };

  return (
      <>
        <div className="ms-Grid" dir="ltr">
          <div className="ms-Grid-row">
            <div className="ms-Grid-col ms-sm4">
              <Label>{t('Fecha')}</Label>
              <DatePicker
                  strings={getLocalizedStrings()}
                  placeholder={t('Seleccioná una fecha')}
                  ariaLabel="Seleccioná una fecha"
                  value={startDate ? startDate : undefined}
                  formatDate={DateCustom.formatDateForDatePicker}
                  onSelectDate={(date) => {
                    if (date) setSelectedDate(date);
                  }}
                  // strings={defaultDatePickerStrings}
                  disabled={props.disabled != undefined ? props.disabled : false}
                  minDate={props.minDate ? props.minDate : undefined}
                  maxDate={props.maxDate ? props.maxDate : undefined}
              />
            </div>
            <div className="ms-Grid-col ms-sm3">
              <Label>{t('Horario')}</Label>
              <div className="horario-container">
                <Dropdown
                    className="absolute horas"
                    options={horaOptions}
                    placeholder={'hh'}
                    onRenderCaretDown={onRenderCaretDown}
                    defaultSelectedKey={startDate ? startDate.getHours().toString() : ''}
                    onChange={(
                        event: React.FormEvent<HTMLDivElement>,
                        option?: IDropdownOption,
                        index?: number
                    ) => {
                      if (option) setSelectedHora(+option.key);
                    }}
                    disabled={props.disabled != undefined ? props.disabled : false}
                />
                <div className="absolute dosPuntos">:</div>

                <Dropdown
                    className="absolute minutos"
                    options={minutoOptions}
                    defaultSelectedKey={startDate ? startDate.getMinutes().toString() : ''}
                    placeholder={'mm'}
                    onRenderCaretDown={onRenderCaretDown}
                    disabled={props.disabled != undefined ? props.disabled : false}
                    onChange={(
                        event: React.FormEvent<HTMLDivElement>,
                        option?: IDropdownOption,
                        index?: number
                    ) => {
                      if (option) setSelectedMinuto(+option.key);
                    }}
                />
              </div>
            </div>
            <div className="ms-Grid-col ms-sm5">
              <Dropdown
                  defaultSelectedKey={selectedDuracion ? selectedDuracion.toString() : ''}
                  options={duracionOptions}
                  placeholder={'hh:mm'}
                  label={t('Duracion')}
                  styles={comboBoxStyles}
                  calloutProps={{ calloutWidth: undefined, calloutMinWidth: 100, calloutMaxWidth: 440 }}
                  disabled={props.disabled != undefined ? props.disabled : false}
                  onChange={(
                      event: React.FormEvent<HTMLDivElement>,
                      option?: IDropdownOption,
                      index?: number
                  ) => {
                    if (option) setSelectedDuracion(+option.key);
                  }}
              />
            </div>
          </div>
        </div>
        {/* <div>
        {'Desde > '} {startDate ? startDate.toString() : null}
      </div>
      <div>
        {'Hasta > '} {endDate ? endDate.toString() : null}
      </div> */}
      </>
  );
};
