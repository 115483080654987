import ProjectConfigurationTeamForm from '../../Components/Proyectos/ProjectConfiguration/ProjectConfigurationTeamForm';
import { Collaborator } from '../collaborators/Collaborator';
import CollaboratorSystem, { ICollaborator } from '../collaborators/CollaboratorSystem';
import CollaboratorTeam, { ICollaboratorTeam } from '../collaborators/collaborator_team/CollaboratorTeam';
import { ProjectTeamAssertions } from './ProjectTeamAssertions';

export interface IProjectTeam {
  eq_activo: boolean;
  eq_id: string;
  eq_nombre: string;
  eq_responsable_colid: string;
  colaborador: ICollaborator | null;
  colaboradorEquipoList: ICollaboratorTeam[];
}

export interface ITeam {
  collaboratorTeamList: CollaboratorTeam[];
  collaboratorResponsible: Collaborator;
}

class ProjectTeam {
  protected constructor(
      private active: boolean,
      private id: string,
      private name: string,
      private responsibleColId: string,
      private collaboratorTeamList: CollaboratorTeam[],
      private collaboratorResponsible: Collaborator | null
  ) {}

  static fromIProjectTeam(collaboratorSystem: CollaboratorSystem, projectTeam: IProjectTeam) {
    let collaborator;
    if (projectTeam.colaborador) {
      collaborator = Collaborator.fromICollaborator(collaboratorSystem, projectTeam.colaborador);
    }

    let collaboratorTeam: CollaboratorTeam[] = [];
    if (projectTeam.colaboradorEquipoList) {
      collaboratorTeam = projectTeam.colaboradorEquipoList.map((_colTeam) =>
          CollaboratorTeam.buildFrom(collaboratorSystem, _colTeam)
      );
    }

    return this.build(
        projectTeam.eq_activo,
        projectTeam.eq_id,
        projectTeam.eq_nombre,
        projectTeam.eq_responsable_colid,
        collaboratorTeam,
        collaborator ? collaborator : null
    );
  }

  static build(
      active: boolean,
      id: string,
      name: string,
      responsibleColId: string,
      collaboratorTeamList: CollaboratorTeam[],
      collaboratorResponsible: Collaborator | null
  ) {
    const assertions = ProjectTeamAssertions.build();
    assertions.evaluateAllAssertions({
      collaboratorTeamList,
      collaboratorResponsible,
    });
    return new this(active, id, name, responsibleColId, collaboratorTeamList, collaboratorResponsible);
  }

  collaborators(): Collaborator[] {
    return this.activeCollaborators().map((collaboratorInTeam) => collaboratorInTeam.getCollaborator());
  }

  private activeCollaborators() {
    return this.collaboratorTeamList.filter((collaboratorInTeam) => collaboratorInTeam.isActive());
  }

  getActive() {
    return this.active;
  }
  getId() {
    return this.id;
  }
  getName() {
    return this.name;
  }
  getResponsibleColId() {
    return this.responsibleColId;
  }
  getCollaboratorResponsible() {
    return this.collaboratorResponsible;
  }
  getCollaboratorTeamList() {
    return this.collaboratorTeamList;
  }

  setActive(value: boolean) {
    this.active = value;
  }
  setId(value: string) {
    this.id = value;
  }
  setName(value: string) {
    this.name = value;
  }
  setResponsibleColId(value: string) {
    this.responsibleColId = value;
  }
  setCollaboratorResponsible(value: Collaborator) {
    this.collaboratorResponsible = value;
  }
  setCollaboratorTeamList(value: CollaboratorTeam[]) {
    this.collaboratorTeamList = value;
  }

  toIProjectTeam(projectTeam: ProjectTeam) {
    let col = projectTeam.getCollaboratorResponsible();

    let ret: IProjectTeam = {
      eq_activo: projectTeam.getActive(),
      eq_id: projectTeam.getId(),
      eq_nombre: projectTeam.getName(),
      eq_responsable_colid: projectTeam.getResponsibleColId(),
      colaborador: col ? col.toICollaborator() : null,
      colaboradorEquipoList: projectTeam
          .getCollaboratorTeamList()
          .map((coleq) => coleq.toICollaboratorTeam(coleq, projectTeam.getId())),
    };
    return ret;
  }
}
export default ProjectTeam;
