import ObjectError from '../Assertion/ObjectError';
import CustomInputIfc from './CustomInputIfc';
import AssertionEntity from '../Assertion/AssertionEntity';

class CustomList<T = any> implements CustomInputIfc {
  private errorList: ObjectError[];
  private changed: boolean;
  private valid: boolean;
  constructor(private propertyName: string, protected items: T[], private assertionEntity: AssertionEntity) {
    this.errorList = [];
    this.changed = false;
    this.valid = false;
  }

  setValueToDefault(): void {
    this.items = [];
  }

  isChanged(): boolean {
    return this.changed;
  }

  isValid(): boolean {
    return this.valid;
  }

  evaluate() {
    this.errorList = this.assertionEntity.evaluateIndividual(this.propertyName, this.items);
    this.valid = this.errorList.length === 0;
    return this.errorList;
  }

  getErrorList(): ObjectError[] {
    return [...this.errorList];
  }

  hasErrors(): boolean {
    return this.errorList.length > 0;
  }

  getName(): string {
    return this.propertyName;
  }

  getValue() {
    return this.items;
  }

  setValue(value: T) {
    this.add(value);
  }

  onBlur() {
    return this.evaluate();
  }

  add(item: T) {
    this.items.push(item);
  }

  delete(item: T) {
    let idx = this.items.indexOf(item);
    if (idx > -1) {
      this.items.splice(idx, 1);
    }
  }
}

export default CustomList;
