import { IComboBox, IComboBoxOption, ComboBox } from '@fluentui/react';
import React from 'react';
import { CallableFn } from './CustomInput';
import CustomInputIfc from './CustomInputIfc';
import { useTranslation, Trans } from 'react-i18next';
import ErrorListForInput from './ErrorListForInput';

interface IDataDropdown {
  input: CustomInputIfc;
  onChangeExtended?: (input: CustomInputIfc) => void;
  onBlur: (input: CustomInputIfc) => void;
  label: string;
  options: IComboBoxOption[];
  clearAfterSelection?: boolean;
  getItemFromKey?: (key: string | number) => any;
  startingSelection?: string;
}

export const DataDropdown = (props: IDataDropdown) => {
  const { t, i18n } = useTranslation();

  const [updateFlag, setUpdateFlag] = React.useState(false);

  const updateViewAfterDoing = (anAction: CallableFn) => {
    setUpdateFlag((u) => {
      anAction();
      return !u;
    });
  };

  const onBlurCustomInput = () => {
    updateViewAfterDoing(() => {
      props.input.onBlur();
      props.onBlur(props.input);
    });
  };

  const [selectedKey, setSelectedKey] = React.useState<string | number | undefined>(props.startingSelection);
  const [options, setOptions] = React.useState(props.options);
  const [textDropDown, setTextDropdown] = React.useState<string>(props.startingSelection || '');

  console.log(props.startingSelection);

  const onInputValueChange = (value: string) => {
    setTextDropdown(value);
    setOptions(props.options.filter((op) => op.text.toLocaleLowerCase().includes(value.toLocaleLowerCase())));
  };

  const onChangeCustomInput = (
      event: React.FormEvent<IComboBox>,
      option?: IComboBoxOption,
      index?: number,
      value?: string
  ) => {
    if (option) {
      let key = option.key;
      updateViewAfterDoing(() => {
        props.input.setValue(option!.data);
      });
      if (props.onChangeExtended) {
        props.onChangeExtended(props.input);
      }
      setTextDropdown(option.text);
      setSelectedKey(key);
      if (props.clearAfterSelection) {
        setTextDropdown('');
      }
      // if (event?.currentTarget) event.currentTarget.focus(true, false);
    }
  };

  return (
      <>
        <ComboBox
            selectedKey={selectedKey}
            label={t(props.label)}
            text={textDropDown}
            autoComplete="off"
            allowFreeform
            openOnKeyboardFocus={true}
            options={options}
            onInputValueChange={onInputValueChange}
            onChange={onChangeCustomInput}
            onBlur={(ev) => onBlurCustomInput()}
        />
        {props.input.hasErrors() && <ErrorListForInput customInput={props.input} />}
      </>
  );
};

export default DataDropdown;
