import {
  CheckboxVisibility,
  DefaultButton,
  DefaultPalette,
  DetailsList,
  DetailsListLayoutMode,
  Dropdown,
  IColumn,
  Icon,
  IconButton,
  IContextualMenuProps,
  IIconProps,
  PrimaryButton,
  Text,
  TextField,
} from '@fluentui/react';
import { ICommandBarItemProps } from '@fluentui/react/lib/CommandBar';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import TimeTaxSupportSystemClient from '../../system';
import MessageCourier from '../../lib/MessageCourier';
import { useTranslation } from 'react-i18next';
import { defaultCommandBarButtonStyles, titleCommandBarButtonStyles } from '../../styles/button-styles';
import { routes } from '../../common/constants';
import ViewWrapperWithCommandBar from '../Layout/ViewWrapperWithCommandBar';
import { CollaboratorLicense } from '../../system/collaborators/collaborator_license/CollaboratorLicense';
import { DateTime } from 'luxon';
import DialogTemplate from '../Common/DialogTemplate';

interface ILicensesDetail {
  system: TimeTaxSupportSystemClient;
  courier: MessageCourier;
  changeBlockUI: (v: boolean) => void;
}

export interface ISettlementItemsGrouped {
  ldi_sector: string;
  ldi_periodo: string;
  promedio_costo_hora: string;
  ldi_horas_confirmadas: string;
  ldi_importe_costo_neto: string;
  ldi_importe_costo_total: string;
  concept: string;
}

interface IFormattedGroups {
  key: string;
  name: string;
  startIndex: number;
  count: number;
  level?: number;
  isCollapsed?: boolean;
}

const LicensesDetail = (props: ILicensesDetail) => {
  const { t } = useTranslation();
  let { id } = useParams();
  const navigate = useNavigate();

  const [licenseGenerated, setLicenseGenerated] = useState<CollaboratorLicense>();
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);

  const GlobalNavButtonIcon: IIconProps = { iconName: 'GlobalNavButton' };

  useEffect(() => {
    if (id) {
      getLicenseById(id);
    }
  }, [id]);

  const getLicenseById = async (id) => {
    if (id) {
      props.changeBlockUI(true);
      try {
        const response = await props.system.getCollaboratorLicenseSystem().getCollaboratorLicensesById(id);
        if (response) {
          setLicenseGenerated(response);
          // props.courier.messageSuccess(t('La defensa fue generada exitosamente'));
        }
      } catch (error) {
        props.courier.messageError(error);
      } finally {
        props.changeBlockUI(false);
      }
    }
  };

  const itemsMenuCalendar = React.useMemo(
      (): ICommandBarItemProps[] => [
        {
          key: 'title',
          text: t('Licencias'),
          iconProps: {
            iconName: 'ReminderPerson',
            color: DefaultPalette.themeLighterAlt,
            className: 'text-title-btn',
          },
          buttonStyles: titleCommandBarButtonStyles,
          className: 'text-title-btn',
        },
        {
          key: 'back',
          text: t('Volver'),
          iconProps: {
            iconName: 'Back',
            color: DefaultPalette.themeLighterAlt,
            className: 'text-title-btn',
          },
          buttonStyles: titleCommandBarButtonStyles,
          className: 'text-title-btn',
          onClick: () => {
            navigate(`${routes.licenses}`);
          },
        },
        {
          key: 'modificar',
          text: t('Modificar'),
          iconProps: { iconName: 'Edit', color: DefaultPalette.themeLighterAlt },
          buttonStyles: titleCommandBarButtonStyles,
          disabled: true,
        },
        {
          key: licenseGenerated?.getActive() ? 'desactivar' : 'activar',
          text: licenseGenerated?.getActive() ? t('Desactivar licencia') : t('Activar licencia'),
          iconProps: {
            iconName: licenseGenerated?.getActive() ? 'Delete' : 'EventDateMissed12',
            color: DefaultPalette.themeLighterAlt,
          },
          buttonStyles: titleCommandBarButtonStyles,
          onClick: () => {
            licenseGenerated?.getActive() ? deactivateLicense() : activateLicense();
          },
          // disabled: true,
        },
        {
          key: 'eliminar',
          text: t('Eliminar permanentemente'),
          iconProps: { iconName: 'Trash', color: DefaultPalette.themeLighterAlt },
          buttonStyles: titleCommandBarButtonStyles,
          onClick: () => {
            setOpenConfirm(true);
          },
        },
      ],
      [licenseGenerated]
  );

  const dateTimeToString = (date: DateTime) => {
    const _date = date.toJSDate();
    const dia = _date.getDate().toString().padStart(2, '0');
    const mes = (_date.getMonth() + 1).toString().padStart(2, '0');
    const anio = _date.getFullYear();
    return `${dia}/${mes}/${anio}`;
  };

  const formatState = (state: string) => {
    const tState = t(state.charAt(0).toUpperCase() + state.slice(1));
    return tState;
  };

  const deactivateLicense = async () => {
    if (id) {
      props.changeBlockUI(true);
      try {
        props.system.getCollaboratorLicenseSystem().deactivate(id);
        props.courier.messageSuccess('Licencia desactivada con éxito');
      } catch (error) {
        props.courier.messageError(error);
      } finally {
        setTimeout(() => {
          props.changeBlockUI(false);
          getLicenseById(id);
        }, 1000);
        // navigate(routes.licenses);
      }
    }
  };

  const deleteLicense = async () => {
    if (id) {
      props.changeBlockUI(true);
      try {
        await props.system.getCollaboratorLicenseSystem().deleteLicense(id);
        props.courier.messageSuccess('Licencia eliminada con éxito');
        navigate(`${routes.licenses}`);
      } catch (error) {
        props.courier.messageError(error);
      }
    }
  };

  const activateLicense = async () => {
    if (id) {
      props.changeBlockUI(true);
      try {
        props.system.getCollaboratorLicenseSystem().activate(id);
        props.courier.messageSuccess('Licencia activada con éxito');
      } catch (error) {
        props.courier.messageError(error);
      } finally {
        setTimeout(() => {
          props.changeBlockUI(false);
          getLicenseById(id);
        }, 1000);

        // navigate(routes.licenses);
      }
    }
  };

  return (
      <ViewWrapperWithCommandBar
          withBorderBottom
          title={''}
          items={itemsMenuCalendar}
          overflowButtonStyles={defaultCommandBarButtonStyles}
          variant="highligth"
          titleBody={t('Liquidaciones')}
      >
        <div className="padding-1">
          <div className="ms-Grid bg-white  padding-1 " dir="ltr">
            <div className="ms-Grid-row">
              <div className="ms-Grid-col ms-lg6 ms-sm6">
                {/* <Text variant="large" className="fw-500">
                {t('Detalle de licencia: ') + id}
              </Text> */}
                <div className="ms-Grid-row mt-2">
                  <div className="ms-Grid-col ms-lg12 ms-sm12">
                    {' '}
                    <Text variant="medium">
                      <Icon iconName="PublicCalendar" className="text-icon"></Icon>
                      {t('Inicio') + ': '}
                      <span className="fw-500">
                      {licenseGenerated ? dateTimeToString(licenseGenerated.getStart()) : null}
                    </span>
                    </Text>
                  </div>
                  <div className="ms-Grid-col ms-lg12 ms-sm12 mt-1">
                    {' '}
                    <Text variant="medium">
                      <Icon iconName="PublicCalendar" className="text-icon"></Icon>
                      {t('Fin') + ': '}
                      <span className="fw-500">
                      {licenseGenerated ? dateTimeToString(licenseGenerated.getEnd()) : null}
                    </span>
                    </Text>
                  </div>
                  <div className="ms-Grid-col ms-lg12 ms-sm12 mt-1">
                    {' '}
                    <Text variant="medium">
                      <Icon iconName={'AwayStatus'} className="text-icon"></Icon>
                      {t('Días') + ': '}
                      <span className="fw-500">{licenseGenerated?.getDays()}</span>
                    </Text>{' '}
                  </div>
                  <div className="ms-Grid-col ms-lg12 ms-sm12 mt-1">
                    {' '}
                    <Text variant="medium">
                      <Icon iconName={'StatusCircleQuestionMark'} className="text-icon"></Icon>
                      {t('Motivo') + ': '}
                      <span className="fw-500">{licenseGenerated?.getReason()}</span>
                    </Text>{' '}
                  </div>
                  <div className="ms-Grid-col ms-lg12 ms-sm12 mt-1">
                    {' '}
                    <Text variant="medium">
                      <Icon iconName={'StatusCircleQuestionMark'} className="text-icon"></Icon>
                      {t('Tipo') + ': '}
                      <span className="fw-500">{licenseGenerated?.getType()?.getDescription()}</span>
                    </Text>{' '}
                  </div>
                  <div className="ms-Grid-col ms-lg12 ms-sm12 mt-1">
                    {' '}
                    <Text variant="medium">
                      <Icon iconName={'Accept'} className="text-icon"></Icon>
                      {t('Activa') + ': '}
                      <span className="fw-500">{licenseGenerated?.getActive() ? t('Sí') : t('No')}</span>
                    </Text>{' '}
                  </div>
                  <div className="ms-Grid-col ms-lg12 ms-sm12 mt-1">
                    {' '}
                    <Text variant="medium">
                      <Icon iconName={'ReminderPerson'} className="text-icon"></Icon>
                      {t('Colaborador') + ': '}
                      <span className="fw-500">
                      {licenseGenerated?.getCollaborator().getName() +
                          ' ' +
                          licenseGenerated?.getCollaborator().getLastName()}
                    </span>
                    </Text>{' '}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <DialogTemplate
            minWidth={600}
            showDialog={openConfirm}
            toggleShowDialog={() => {
              setOpenConfirm(false);
            }}
            title={t('Quieres eliminar permanentemente esta licencia?')}
            onAccept={() => {
              deleteLicense();
              setOpenConfirm(false);
            }}
            onCancel={() => {
              setOpenConfirm(false);
            }}
            acceptText={t('Eliminar')}
            cancelText={t('Cancelar')}
        >
          <>
            <Text block variant="medium">
              <Icon iconName="Warning" className="text-icon warning-color" />
              {t('AVISO: una vez eliminada no se podrá volver a activar la licencia')}
            </Text>
          </>
        </DialogTemplate>
      </ViewWrapperWithCommandBar>
  );
};

export default LicensesDetail;
