import CollaboratorSystem, { ICollaborator } from '../CollaboratorSystem';
import { Collaborator } from '../Collaborator';
import { newEntityDefaultId } from '../../../common/constants';
import AssertionEntity from '../../../lib/FormValidator/Assertion/AssertionEntity';
import AssertionItem from '../../../lib/FormValidator/Assertion/AssertionItem';
import { DateTime } from 'luxon';
import { SystemDateParser } from '../../SystemDateParser';

export interface ICollaboratorLicense {
  collic_id: string;
  collic_tipolic: string;
  collic_inicio: string;
  collic_fin: string;
  collic_dias_lic: number;
  collic_colid: string;
  collic_activo: boolean;
  collic_motivo: string;
  colaborador: ICollaborator;
  licenciaTipo: ILicenseType | null;
}

export class CollaboratorLicense {
  protected constructor(
      private id: string | null,
      private active: boolean,
      private reason: string,
      private start: DateTime,
      private end: DateTime,
      private days: number,
      private type: LicenseType | null,
      private collaborator: Collaborator
  ) {}

  static identifiedBy(
      id: string | null,
      active: boolean,
      reason: string,
      start: DateTime,
      to: DateTime,
      days: number,
      type: LicenseType | null,
      collaborator: Collaborator
  ) {
    // Assertions aca
    return new this(id, active, reason, start, to, days, type, collaborator);
  }

  static buildFrom(collaboratorSystem: CollaboratorSystem, CollaboratorLicense: ICollaboratorLicense) {
    let parser = new SystemDateParser('dd/LL/yyyy');
    let collaborator = Collaborator.fromICollaborator(collaboratorSystem, CollaboratorLicense.colaborador);
    let license = CollaboratorLicense.licenciaTipo
        ? LicenseType.fromILicenseType(CollaboratorLicense.licenciaTipo)
        : null;
    return this.identifiedBy(
        CollaboratorLicense.collic_id,
        CollaboratorLicense.collic_activo,
        CollaboratorLicense.collic_motivo,
        parser.parse(CollaboratorLicense.collic_inicio),
        parser.parse(CollaboratorLicense.collic_fin),
        CollaboratorLicense.collic_dias_lic,
        license,
        collaborator
    );
  }

  static initializeFromColaborador(colab: Collaborator): CollaboratorLicense {
    // todo revisar caso de preuba en excel.
    return this.identifiedBy(null, true, '', DateTime.now(), DateTime.now(), 1, null, colab);
  }

  toICollaboratorLicense(collaboratorLicense: CollaboratorLicense) {
    let ret: ICollaboratorLicense = {
      collic_id: '',
      collic_tipolic: '',
      collic_inicio: '',
      collic_fin: '',
      collic_dias_lic: 0,
      collic_colid: '',
      collic_activo: false,
      collic_motivo: '',
      colaborador: collaboratorLicense.collaborator.toICollaborator(),
      licenciaTipo: collaboratorLicense.type ? collaboratorLicense.type.toILicenseType() : null,
    };
    return ret;
  }

  getId() {
    return this.id;
  }

  // getColLicId(){
  //   return this.collic_colid
  // }

  getDays() {
    return this.days;
  }

  getStart() {
    return this.start;
  }

  getEnd() {
    return this.end;
  }

  getReason() {
    return this.reason;
  }

  getActive() {
    return this.active;
  }

  getCollaborator() {
    return this.collaborator;
  }

  getType() {
    return this.type;
  }
}

export interface ILicenseType {
  lictipo_id: string ;
  lictipo_descripcion: string;
  lictipo_activo: boolean;
}

export class LicenseType {
  protected constructor(private id: string, private active: boolean, private description: string) {}

  static fromILicenseType(lic: ILicenseType) {
    return LicenseType.identifiedBy(lic.lictipo_id, lic.lictipo_activo, lic.lictipo_descripcion);
  }

  static identifiedBy(id: string, active: boolean, description: string) {
    return new this(id, active, description);
  }

  static buildFrom(licenseType: ILicenseType) {
    return LicenseType.identifiedBy(
        licenseType.lictipo_id,
        licenseType.lictipo_activo,
        licenseType.lictipo_descripcion
    );
  }

  getId() {
    return this.id;
  }
  setId(val) {
    this.id = val;
  }
  getActive() {
    return this.active;
  }
  setActive(val) {
    this.active = val;
  }
  getDescription() {
    return this.description;
  }
  setDescription(val) {
    this.description = val;
  }

  toILicenseType(): ILicenseType {
    return {
      lictipo_id: this.getId(),
      lictipo_descripcion: this.getDescription(),
      lictipo_activo: this.getActive(),
    };
  }
}
