import CustomForm from '../../../lib/FormValidator/Form/CustomForm';
import CollaboratorTeam from '../../../system/collaborators/collaborator_team/CollaboratorTeam';
import ProjectCollaboratorTeamAssertions from '../../../system/projects/ProjectCollaboratorTeamAssertions';

class CollaboratorTeamForm extends CustomForm {
  protected constructor() {
    const pcta = ProjectCollaboratorTeamAssertions.build();
    super('ProjectCollaboratorTeam', pcta);
  }

  private static build(percentage: string, responsible: boolean) {
    const instance = new this();
    instance.addInputAsserted('percentageAssignation', percentage);
    instance.addInputAsserted('responsible', responsible);
    return instance;
  }

  static initialize() {
    return this.build('0', false);
  }

  static with(collaboratorTeam: CollaboratorTeam, isResponsible: boolean) {
    return this.build(collaboratorTeam.getPercentage_asignation().toString(), isResponsible);
  }

  getPercentageAssignationInput() {
    return this.getInputByName('percentageAssignation');
  }

  getResponsibleInput() {
    return this.getInputByName('responsible');
  }

  setResponsible(checked: boolean) {
    this.getResponsibleInput().setValue(checked);
  }
}

export default CollaboratorTeamForm;
