import Project from './Project';
import { ProjectsSystem, IStoredProjectFolder } from './ProjectsSystem';
import ProjectFolder from './ProjectFolder';
import { ProjectRelatedDocument } from '../documents/ProjectRelatedDocument';
import { ProjectSystemFolder } from './ProjectSystemFolder';

export class ProjectExternalIntegrationFolder extends ProjectSystemFolder {
  static fromIProjectFolder(system: ProjectsSystem, project: Project, folder: IStoredProjectFolder) {
    return new this(system, project, folder.pca_id, folder.pca_nombre);
  }

  moveInto(documents: ProjectRelatedDocument[]): Promise<void> {
    throw new Error('Can not move documents to integration folder.');
  }

  addChildNamed(childName: string): Promise<ProjectFolder> {
    throw new Error('Can not add project folder to integration folder.');
  }

  isTrash(): boolean {
    return false;
  }

  belongsToUser(): boolean {
    return true;
  }
}
