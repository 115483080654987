import ProjectInSettlementItem from './ProjectInSettlementItem';

export default class ProjectInSettlementPeriod {
  protected items: ProjectInSettlementItem[];
  protected constructor(private period: string, private projectId: string) {
    this.items = [];
  }

  static forPeriod(period: string, projectId: string) {
    return new this(period, projectId);
  }

  addItem(item: ProjectInSettlementItem) {
    this.items.push(item);
  }

  removeItem(item: ProjectInSettlementItem) {
    this.items = this.items.filter((i) => i !== item);
  }

  mapItems<T>(mapClosure: (item: ProjectInSettlementItem) => T): T[] {
    return this.items.map<T>(mapClosure);
  }

  collectFilesInto(files: File[]): void {
    this.items.forEach((item) => {
      item.collectFileInto(files);
    });
  }

  projectIdIs(anId: string): boolean {
    return +this.projectId === +anId;
  }

  periodIs(period: string): boolean {
    return this.period === period;
  }

  getPeriod(): string {
    return this.period;
  }

  getProjectId(): string {
    return this.projectId;
  }

  getItems(): ProjectInSettlementItem[] {
    return this.items;
  }
}
