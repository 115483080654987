import { DateTime } from 'luxon';
import { TaxOffice } from '../tax_office/TaxOffice';

export interface IDefenseFileTaxOfficeRequirement {
  ardrf_id: string;
  ardrf_ardid: string;
  ardrf_requerimiento_descripcion_breve: string;
  ardrf_oficina_fiscal: string;
  ardrf_nro_requerimiento: string;
  ardrf_fecha: string;
  ardrf_agente_fiscal: string;
  ardrf_detalle: string;
}

export type INewDefenseFileTaxOfficeRequirement = Omit<
    IDefenseFileTaxOfficeRequirement,
    'ardrf_id' | 'ardrf_ardid'
>;

class DefenseFileTaxOfficeRequirement {
  protected constructor(
      private number: string,
      private description: string,
      private taxOffice: TaxOffice,
      private agent: string,
      private detail: string,
      private date: DateTime
  ) {}

  static forDescription(
      number: string,
      description: string,
      taxOffice: TaxOffice,
      agent: string,
      detail: string,
      date: DateTime
  ) {
    return new this(number, description, taxOffice, agent, detail, date);
  }

  toGenerationObject(): INewDefenseFileTaxOfficeRequirement {
    return {
      ardrf_nro_requerimiento: this.number,
      ardrf_requerimiento_descripcion_breve: this.description,
      ardrf_oficina_fiscal: this.taxOffice.getId(),
      ardrf_fecha: String(this.date.toISO()),
      ardrf_agente_fiscal: this.agent,
      ardrf_detalle: this.detail,
    };
  }

  getDescription(): string {
    return this.description;
  }
}

export default DefenseFileTaxOfficeRequirement;
