import { DateTime } from 'luxon';
import { Activity } from '../../../system/activity/Activity';
import { BillableDistribution } from '../../../system/billable_distribution/BillableDistribution';
import { Collaborator } from '../../../system/collaborators/Collaborator';
import CollaboratorActivity from '../../../system/collaborators/collaborator_activity/CollaboratorActivity';
import { IProjectDescriptor } from '../../../system/projects/ProjectDescriptor';
import { User } from '../../../system/User';
import { t } from 'i18next';
import { Validation } from '../../../common/Validation';

export class ActivityValidations {
   firstExecution = true;
   valueporcetntotal = 0;
  name = new Validation((name: string) => {
    return !!name;
  }, t('El nombre no puede estar vacío'));

  project = new Validation((project: IProjectDescriptor | null) => {
    return !!(project && project.getId());
  }, t('Debes seleccionar un proyecto'));

  // DateInProjectRange = new Validation((project: IProjectDescriptor, date: Date) => {
  //   return !project || (project && project.isInRange(DateTime.fromJSDate(date)));
  // }, 'La actividad esta fuera de la vigencia del proyecto');

  duration = new Validation((hours: number) => {
    return hours > 0;
  }, t('La actividad debe durar al menos 15 minutos'));

  dateInRangeProject = new Validation((params: any) => {
    const ret = !!(params.project && params.project.isInRange(DateTime.fromJSDate(params.start)));
    return ret;
  }, t('Actividad fuera de vigencia de proyecto'));

  team = new Validation((collaboratorActivity: CollaboratorActivity[]) => {
    return !!(collaboratorActivity.length > 0);
  }, t('El equipo no puede estar vacío'));

  collaboratorLogged = new Validation(
      (colLogged: Collaborator, collaboratorActivity: CollaboratorActivity[]) => {
        return !!collaboratorActivity.find((col) => col.getCollaborator().isIdentifiedAs(colLogged));
      },
      t('Debes participar de la actividad')
  );

  totalDistribution = new Validation((distribution: BillableDistribution[]) => {
    const initialValue = 0;
    const total = distribution.reduce((partialSum, dist) => partialSum + +dist.getPercentage(), initialValue);

    return total >= 0 && total <= 100;
  }, t('La suma de la distribución debe estar entre 0 y 100 '));

  individualDistribution = new Validation((distribution: BillableDistribution) => {
    const total = +distribution.getPercentage();
    return total >= 0 && total <= 100;
  }, t('La distribución debe estar entre 0 y 100 '));

 
  totalprocent = new Validation((distribution: BillableDistribution[] | BillableDistribution ) => {
    let result = 0;
    if(Array.isArray(distribution)) {
        distribution!.forEach(dis=>{
        let value = parseFloat(dis.getPercentage()) || 0;
        result += value;
      })
      this.valueporcetntotal = result
      return !(result<= 99 || result>100);
    }else if (typeof distribution === "object" && this.firstExecution) {
      if((this.valueporcetntotal<= 99 || this.valueporcetntotal>100)){
        this.firstExecution = true; 
      }else{
        this.firstExecution = false; 
      }
      return !(this.valueporcetntotal<= 99 || this.valueporcetntotal>100);
    } else if (typeof distribution === "object") {
      return true;
    }

  }, t(""));


  all(activity: Activity, user: User) {
    const EditDistribution = activity.getProject()?.getCanEditDistribution()
    const ViewDistribution = activity.getProject()?.getCanViewDistribution()
    return (
        this.name.validate(activity.getName()) &&
        this.project.validate(activity.getProject()) &&
        // this.dateInProjectRange.validate(activity.getProject()!, activity.getStart()) &&
        // this.dateInProjectRange.validate(activity.getProject()!, activity.getEnd()) &&
        this.duration.validate(activity.getHours()) &&
        this.dateInRangeProject.validate({ start: activity.getStart(), project: activity.getProject() }) &&
        this.team.validate(activity.getCollaboratorActivityList()) &&
        this.totalDistribution.validate(activity.getDistributionBillableList()) &&
        activity.getDistributionBillableList().every((dist) => this.individualDistribution.validate(dist)) &&
        activity.getDistributionBillableList().every((dist)=>EditDistribution===false || ViewDistribution===false?true: this.totalprocent.validate(dist)) /*&& 
      this.collaboratorLogged.validate(user.getCollaborator(), activity.getCollaboratorActivityList())*/
    );
  }
}
