import { Text, TextField } from '@fluentui/react';
import React from 'react';
import DialogTemplate from './Common/DialogTemplate';
import { useTranslation } from 'react-i18next';
import { TOTPHelp } from './TOTPHelp';

export interface ITOTPDialog {
  show: boolean;
  onAccept: (code: string) => void;
  onCancel: () => void;
  showTOTPDialogErrorCode: boolean;
  QRValue: string;
  hasBeenVinculated: boolean;
}

export const TOTPDialog = (props: ITOTPDialog) => {
  const { t, i18n } = useTranslation();
  const [code, setCode] = React.useState<string>('');
  const [showHelp, setShowHelp] = React.useState<boolean>(false);

  React.useEffect(() => {
    setShowHelp(!props.hasBeenVinculated);
  }, [props.hasBeenVinculated]);

  const textFieldStyles = {
    field: {
      textAlign: 'center',
    },
  };

  return (
      <DialogTemplate
          showDialog={props.show}
          toggleShowDialog={() => props.onCancel()}
          title={t('Factor de doble autenticación')}
          onAccept={() => props.onAccept(code)}
          onCancel={props.onCancel}
          acceptText={t('Enviar código')}
          cancelText={t('Cancel')}
          minWidth={500}
          hideAcceptButton={showHelp}
          hideCancelButton={showHelp}
      >
        <>
          {!showHelp && (
              <div>
                <div>
                  <Text variant="medium">
                    Ingresa en tu celular a tu aplicación de doble autenticación preferida (puede ser Google
                    Authenticator o Microsoft Authenticator)
                  </Text>
                </div>
                {!props.hasBeenVinculated && (
                    <div>
                      <Text
                          variant="medium"
                          onClick={() => {
                            setShowHelp(!showHelp);
                          }}
                          className="link-styles mt-1"
                      >
                        No tengo la aplicación en el celular
                      </Text>
                    </div>
                )}
                <div className="text-center mt-2 mb-2">
                  <TextField
                      label="Ingresa el código"
                      className="input-code-key m-auto text-center"
                      value={code}
                      maxLength={6}
                      pattern="[0-9]*"
                      styles={textFieldStyles}
                      onKeyPress={(e) => {
                        const pattern = /[0-9]/;
                        const inputChar = String.fromCharCode(e.which);
                        if (!pattern.test(inputChar)) {
                          e.preventDefault();
                        }
                      }}
                      onChange={(e, value) => {
                        if (value!.length <= 6) {
                          setCode(String(value));
                        }
                      }}
                      errorMessage={
                        props.showTOTPDialogErrorCode
                            ? 'Codigo incorrecto. (puede que el codigo haya cambiado, asegurate de refrescar el codigo en tu autenticador)'
                            : ''
                      }
                  />
                </div>
              </div>
          )}
          {showHelp && (
              <div>
                <TOTPHelp finishHelp={() => setShowHelp(false)} QRValue={props.QRValue}></TOTPHelp>
              </div>
          )}
        </>
      </DialogTemplate>
  );
};
