import { Icon, Text } from '@fluentui/react';
import React from 'react';
import DialogTemplate from '../Common/DialogTemplate';
import { useTranslation, Trans } from 'react-i18next';
import { EN_LANGUAGE_VALUE, ES_LANGUAGE_VALUE, getSessionStorageLanguage } from '../../i18n';

export interface RefreshPageDialogProps {
  show: boolean;
  onClose: (accept: boolean) => void;
}

export const RefreshPageDialog = (props: RefreshPageDialogProps) => {
  const { t, i18n } = useTranslation();

  const cancel = () => {
    props.onClose(false);
  };

  const accept = () => {
    props.onClose(true);
  };

  const getTitle = () => {
    let ret = '';
    const userLang = getSessionStorageLanguage();
    if (userLang === ES_LANGUAGE_VALUE) {
      return 'Debes refrescar la aplicación';
    }
    if (userLang === EN_LANGUAGE_VALUE) {
      return 'Must refresh the application';
    }

    return ret;
  };

  const getDescription = () => {
    let ret = '';
    const userLang = getSessionStorageLanguage();
    if (userLang === ES_LANGUAGE_VALUE) {
      return 'Para aplicar los cambios de idioma debes refrescar la aplicacion. Quieres refrescar ahora?';
    }
    if (userLang === EN_LANGUAGE_VALUE) {
      return 'To apply the language change you must update the application. Do you want to recharge now?';
    }

    return ret;
  };

  const getConfirmText = () => {
    let ret = '';
    const userLang = getSessionStorageLanguage();
    if (userLang === ES_LANGUAGE_VALUE) {
      return 'Confirmar';
    }
    if (userLang === EN_LANGUAGE_VALUE) {
      return 'Confirm';
    }

    return ret;
  };

  const getCancelText = () => {
    let ret = '';
    const userLang = getSessionStorageLanguage();
    if (userLang === ES_LANGUAGE_VALUE) {
      return 'Cancelar';
    }
    if (userLang === EN_LANGUAGE_VALUE) {
      return 'Cancel';
    }

    return ret;
  };

  return (
      <DialogTemplate
          showDialog={props.show}
          toggleShowDialog={() => props.onClose(false)}
          title={getTitle()}
          onAccept={accept}
          onCancel={cancel}
          acceptText={getConfirmText()}
          cancelText={getCancelText()}
          minWidth={600}
      >
        <div>
          <Trans>
            <Text block variant="medium">
              <Icon iconName="Warning" className="text-icon" />
              {getDescription()}
            </Text>
          </Trans>
        </div>
      </DialogTemplate>
  );
};
