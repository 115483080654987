import { DateTime } from 'luxon';

import DocumentsSystem, { IUploadedDocument } from './DocumentsSystem';
import { SystemDocument } from './SystemDocument';
import LazyInitialization from '../../lib/LazyInitialization';
import SystemDocumentType from './SystemDocumentType';
import { Collaborator } from '../collaborators/Collaborator';
import DocumentUploadControlConfirmed from './DocumentUploadControlConfirmed';

export default class SystemUploadedDocument extends SystemDocument {
  private constructor(
      private documentType: LazyInitialization<SystemDocumentType>,
      private uploadedBy: LazyInitialization<Collaborator>,
      private id: string,
      private folderId: string,
      private name: string,
      private deleted: boolean,
      private active: boolean,
      private uploadedOn: DateTime,
      private contentType: string,
      private sizeInBytes: number,
      private isConfidential: boolean,
      private refersToPeriod: DateTime,
      private deletedOn?: DateTime,
      private controlReference?: DocumentUploadControlConfirmed
  ) {
    super();
  }

  static fromJson(
      system: DocumentsSystem,
      documentType: LazyInitialization<SystemDocumentType>,
      uploadedBy: LazyInitialization<Collaborator>,
      doc: IUploadedDocument,
      controlReference?: DocumentUploadControlConfirmed
  ) {
    const dateParser = system.getDateParser();
    return new this(
        documentType,
        uploadedBy,
        doc.doc_id,
        doc.doc_pcaid,
        doc.doc_nombre,
        doc.doc_eliminado,
        doc.doc_activo,
        dateParser.parse(doc.doc_carga_fecha),
        doc.doc_content_type,
        Number(doc.doc_tamanio),
        doc.doc_es_confidencial,
        dateParser.parseNoTime(doc.doc_refiere_a_periodo),
        doc.doc_inactivo_desde ? dateParser.parse(doc.doc_inactivo_desde) : undefined,
        controlReference
    );
  }

  getSizeInBytes(): number {
    return this.sizeInBytes;
  }

  getContentType(): string {
    return this.contentType;
  }

  getFolderId(): string {
    return this.folderId;
  }

  uploaded(): boolean {
    return true;
  }

  getId(): string {
    return this.id;
  }

  getUploadedBy(): Promise<Collaborator> {
    return this.uploadedBy.value();
  }

  getUploadedDate() {
    return this.uploadedOn.toLocaleString();
  }

  getDeletedOn() {
    return this.deletedOn?.toLocaleString();
  }

  getRefersToPeriod() {
    return this.refersToPeriod;
  }

  async getTypeDescription() {
    return (await this.getType()).getName();
  }

  getType(): Promise<SystemDocumentType> {
    return this.documentType.value();
  }

  getName(): string {
    return this.name;
  }

  isActive(): boolean {
    return this.active && !this.isDeleted();
  }

  isDeleted(): boolean {
    return this.deleted;
  }

  hasControlReference() {
    return this.controlReference !== undefined;
  }

  getControlReference() {
    return this.controlReference;
  }

  isConfidentialDocument(): boolean {
    return this.isConfidential;
  }
}
