import { Collaborator } from '../collaborators/Collaborator';
import Project from '../projects/Project';
import { ProjectsSystem } from '../projects/ProjectsSystem';
import { SystemDocument } from './SystemDocument';

export abstract class ProjectRelatedDocument {
  protected constructor(
      protected system: ProjectsSystem,
      protected document: SystemDocument,
      protected project: Project
  ) {}

  abstract getId(): string;
  abstract canDeactivate(): boolean;

  // getProject(): IProjectDescriptor {
  //   return this.project;
  // }
  getProject(): Project {
    return this.project;
  }

  getSizeInBytes(): number {
    return this.document.getSizeInBytes();
  }

  isIdentifiedBy(anId: string): boolean {
    return this.getId() === anId;
  }

  /**
   * @TODO ver si aplica para los documentos
   * de actividades y liquidaciones también
   */
  deactivate() {
    return this.system.deactivateProjectDocument(this);
  }

  downloadUrl(): Promise<string> {
    return this.system.getDocumentsSystem().getDownloadLinkFor(this.document);
  }

  getDocument(): SystemDocument {
    return this.document;
  }

  uploaded(): boolean {
    return this.document.uploaded();
  }

  getUploadedBy(): Promise<Collaborator> {
    return this.document.getUploadedBy();
  }

  getUploadedDate() {
    return this.document.getUploadedDate();
  }

  getDeletedOn() {
    return this.document.getDeletedOn();
  }

  getTypeDescription() {
    return this.document.getTypeDescription();
  }

  getType() {
    return this.document.getType();
  }

  getName() {
    return this.document.getName();
  }

  getRefersToPeriod() {
    return this.document.getRefersToPeriod();
  }

  isDeleted() {
    return this.document.isDeleted();
  }

  isActive() {
    return this.document.isActive() && !this.isDeleted();
  }

  isInTrash() {
    return !this.isActive() && !this.isDeleted();
  }

  isConfidentialDocument() {
    return this.document.isConfidentialDocument();
  }

  getContentType() {
    return this.document.getContentType();
  }

  hasControlReference() {
    return this.document.hasControlReference();
  }

  getControlReference() {
    return this.document.getControlReference();
  }
}
