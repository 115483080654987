import { OrganizationChartLevel } from './OrganizationChartLevel';

export interface OrganizationChartNode {
  getId(): string;

  getName(): string;

  path(): string;

  getLevel(): OrganizationChartLevel;

  parentPath(): string;

  isIdentifiedBy(anId: string): boolean;

  isIdentifiedAs(aNode: OrganizationChartNode): boolean;

  isDescendantOf(aNode: OrganizationChartNode): boolean;
}

export const JEFATURA_VALUE = 'Jefatura';
