export class OrganizationChartLevel {
  protected constructor(private name: string, private level: number) {}

  static named(name: string, level: number) {
    return new this(name, level);
  }

  isIdentidiedBy(level: number): boolean {
    return this.level === level;
  }

  maxLevelWith(aLevel: number): number {
    return Math.max(this.level, aLevel);
  }

  getName(): string {
    return this.name;
  }
  getLevel(): number {
    return this.level;
  }
}
