import { IComboBoxOption, IIconProps } from "@fluentui/react";
import TimeTaxSupportSystemClient from "../../system";
import MessageCourier from "../../lib/MessageCourier";
import { User } from "../../system/User";
import { IActivityRecurring, ItipoConcepto } from "../../system/projects/ProjectsSystem";

export interface ICompanyPanel {
    system: TimeTaxSupportSystemClient;
    courier: MessageCourier;
    changeBlockUI: (change: boolean) => void;
    user: User;
    useAllSector?: boolean;
  };
export interface IAddRows {
    id: string;
    nombrePro: IComboBoxInput;
    nombreAct: string;
    horasCarg: string;
    file: string;
    valida: number;
    descripcionError: string;
    actividadRecurrente: string;
    lisActRecurrente: IActivityRecurring[];
    actividadRecurrenteSelect?:IActivityRecurring;
    tipoConcepto:ItipoConcepto;
    listaProyectos:IComboBoxOption[];
    filterListProyectos:IComboBoxOption[];
    descripcion:string;
}

export interface IactError {
  actVali:number;
  actvErro:number;
}

export interface ICollaborator {
  id: string;
  name: string;
  lastName: string;
  email: string;
  active: boolean;
}

interface IComboBoxInput extends IComboBoxOption{
  code?: string;
}


   export const UploadIcon: IIconProps = { iconName: 'Upload' };
   export const AddIcon: IIconProps = { iconName: 'add' };