import React, { useEffect } from 'react';
import { IStackStyles, IStackTokens, Stack, Text, Link, Icon, ICommandBarItemProps } from '@fluentui/react';
import { FontWeights, ITextStyles, mergeStyles, DefaultPalette } from '@fluentui/react';
import { User } from '../../system/User';
import MessageCourier from '../../lib/MessageCourier';
import TimeTaxSupportSystemClient from '../../system';
import useAsyncDataCourier from '../../common/custom-hooks/useAsyncDataCourier';
import { DateTime } from 'luxon';
import ViewWrapperWithCommandBar from '../Layout/ViewWrapperWithCommandBar';
import { titleCommandBarButtonStyles } from '../../styles/button-styles';
import { t } from 'i18next';

interface IBlockPeriods {
  system: TimeTaxSupportSystemClient;
  courier: MessageCourier;
  user: User;
  changeBlockUI: (change: boolean) => void;
}

export const BlockPeriods = (props: IBlockPeriods) => {
  const [year, setYear] = React.useState<number>(new Date().getFullYear());
  const [shouldRefreshPeriods, setShouldRefreshPeriods] = React.useState<boolean>(false);

  const months = React.useMemo(() => {
    const ret = [
      {
        label: t('Enero'),
        date: new Date(year, 0, 1, 12),
      },
      {
        label: t('Febrero'),
        date: new Date(year, 1, 1, 12),
      },
      {
        label: t('Marzo'),
        date: new Date(year, 2, 1, 12),
      },
      {
        label: t('Abril'),
        date: new Date(year, 3, 1, 12),
      },
      {
        label: t('Mayo'),
        date: new Date(year, 4, 1, 12),
      },
      {
        label: t('Junio'),
        date: new Date(year, 5, 1, 12),
      },
      {
        label: t('Julio'),
        date: new Date(year, 6, 1, 12),
      },
      {
        label: t('Agosto'),
        date: new Date(year, 7, 1, 12),
      },
      {
        label: t('Septiembre'),
        date: new Date(year, 8, 1, 12),
      },
      {
        label: t('Octubre'),
        date: new Date(year, 9, 1, 12),
      },
      {
        label: t('Noviembre'),
        date: new Date(year, 10, 1, 12),
      },
      {
        label: t('Diciembre'),
        date: new Date(year, 11, 1, 12),
      },
    ];

    return ret;
  }, [year, t]);

  const periodsBlocked = useAsyncDataCourier(
      () => props.system.getAllPeriodsBlocked(),
      [props.system, shouldRefreshPeriods],
      props.courier,
      props.changeBlockUI
  );

  const isMonthBlocked = (month: Date) => {
    return periodsBlocked?.some((period) => period.isInRange(DateTime.fromJSDate(month)));
  };

  const blockMonth = async (month: Date) => {
    props.changeBlockUI(true);
    try {
      await props.system.blockMonth(month);
      setShouldRefreshPeriods(!shouldRefreshPeriods);
    } catch (error) {
      props.courier.messageError(error);
    }
    props.changeBlockUI(false);
  };

  const unblockMonth = async (month: Date) => {
    props.changeBlockUI(true);
    try {
      await props.system.unblockMonth(month);
      setShouldRefreshPeriods(!shouldRefreshPeriods);
    } catch (error) {
      props.courier.messageError(error);
    }
    props.changeBlockUI(false);
  };

  const itemsMenu = React.useMemo((): ICommandBarItemProps[] => {
    let ret: ICommandBarItemProps[] = [
      {
        key: 'title',
        text: t('Bloqueo de meses específicos'),
        iconProps: {
          iconName: 'Calendar',
          color: DefaultPalette.themeLighterAlt,
          className: 'text-title-btn',
        },
        buttonStyles: titleCommandBarButtonStyles,
        className: 'text-title-btn',
      },
    ];
    return ret;
  }, []);

  return (
      <ViewWrapperWithCommandBar title="" items={itemsMenu} withBorderBottom>
        <div className="padding-1">
          <div className="ms-Grid" dir="ltr">
            <div className="ms-Grid-row">
              <div className="ms-Grid-col ms-sm12">
                <Text variant="mediumPlus">
                  <Icon iconName="Info" className="icon-info-months icon-info-color" />{' '}
                  {t('Este es el formulario para bloquear meses específicos. Los meses que figuran con')}
                  <Icon iconName="Checkmark" className="icon-info-months success-color" />{' '}
                  {t('son los que están disponibles de carga. En cambio los que figuran con')}
                  <Icon iconName="Cancel" className="icon-info-months error-color" />{' '}
                  {t('son los meses que están bloqueados.')}
                </Text>
                <Text variant="medium" className="d-block mt-1">
                  <Icon iconName="Warning" className="icon-warning-months warning-color" />{' '}
                  {t(
                      'Cuando un mes está bloqueado no está disponible la creación/edición de actividades y tampoco la confirmacion de participación de las mismas.'
                  )}
                </Text>
              </div>
            </div>
            <div className="ms-Grid-row mt-5">
              <div className="ms-Grid-col ms-sm12">
                <div className="text-center">
                  <Text variant="xxLarge">
                    <Icon
                        iconName="ChevronLeftSmall"
                        className="d-inline icon-selector-year clickable"
                        onClick={() => setYear(year - 1)}
                    />{' '}
                    {year}{' '}
                    <Icon
                        iconName="ChevronRightSmall"
                        className="d-inline icon-selector-year clickable"
                        onClick={() => setYear(year + 1)}
                    />
                  </Text>
                </div>
              </div>
            </div>

            <div className="ms-Grid-row padding-1">
              {months.map((month) => {
                return (
                    <div className="ms-Grid-col ms-sm4 text-center mt-2">
                      <div className="month-box ms-depth-4">
                        <div
                            className={
                              isMonthBlocked(month.date)
                                  ? 'ms-Grid-col ms-sm4 calendar-pill-left calendar-pill-blocked px-0 bg-white br-2'
                                  : 'ms-Grid-col ms-sm4 calendar-pill-left calendar-pill-unblocked px-0 bg-white br-2'
                            }
                        >
                          <div className="highlight-pill mt-for-calendar-pill  ">
                            <Text variant="large" className="fw-500 fs-for-pill-left text-white">
                              {month.label}
                            </Text>
                          </div>
                        </div>
                        <div className="ms-Grid-col ms-sm8 calendar-pill-right">
                          <div className="icon-for-calendar-pill">
                            {isMonthBlocked(month.date) ? (
                                <Icon
                                    iconName="Cancel"
                                    className="icon-month-box error-color clickable"
                                    onClick={() => unblockMonth(month.date)}
                                />
                            ) : (
                                <Icon
                                    iconName="Checkmark"
                                    className="icon-month-box success-color clickable"
                                    onClick={() => blockMonth(month.date)}
                                />
                            )}
                          </div>
                          <div>
                            {isMonthBlocked(month.date) ? (
                                <Text
                                    variant="medium"
                                    className="link-styles"
                                    onClick={() => unblockMonth(month.date)}
                                >
                                  {t('Desbloquear')}
                                </Text>
                            ) : (
                                <Text
                                    variant="medium"
                                    className="link-styles"
                                    onClick={() => blockMonth(month.date)}
                                >
                                  {t('Bloquear')}
                                </Text>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                );
              })}
            </div>
          </div>
        </div>
      </ViewWrapperWithCommandBar>
  );
};
