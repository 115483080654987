import * as React from 'react';
import TimeTaxSupportSystemClient from '../../system';
import { DateTime } from 'luxon';
import { DatePicker, Dropdown, IDropdownOption, PrimaryButton } from '@fluentui/react';
import { DateCustom } from '../../lib/DateCustom';
import CompanySelector from '../Company/CompanySelector';
import MessageCourier from '../../lib/MessageCourier';
import { Company } from '../../system/company/Company';
import { useTranslation, Trans } from 'react-i18next';
import { getLocalizedStrings } from '../../lib/LocalizedStringsForDatePicker';
import { OrganizationChartDropdown } from '../Common/OrganizationChartDropdown';
import useAsyncDataCourier from '../../common/custom-hooks/useAsyncDataCourier';
import { User } from '../../system/User';

interface IReportingFilters {
  system: TimeTaxSupportSystemClient;
  courier: MessageCourier;
  onChangeFromDate: (date: DateTime) => void;
  onChangeToDate: (date: DateTime) => void;
  onChangeCompany: (company?: Company) => void;
  onClickSearch: () => void;
  showCompanyFilter?: boolean;
  showSectorFilter?: boolean;
  useConcept?: boolean;
  onChangeConcept?: (concept: string) => void;
  onChangeNode?: (id: string) => void;
  user?: User;
}

export const ReportingFilters = (props: IReportingFilters) => {
  const { t, i18n } = useTranslation();
  const [fromDate, setFromDate] = React.useState<DateTime | undefined>(undefined);
  const [toDate, setToDate] = React.useState<DateTime | undefined>(undefined);
  const [concept, setConcept] = React.useState<string>('');
  const [shouldRefresh, setShouldRefresh] = React.useState<boolean>(false);

  const nodes = useAsyncDataCourier(
      () => {
        if (props.user) {
          return props.system.getOrganizationChartSystem().getAvailableNodesOf(props.user);
        }
        return Promise.resolve([]);
      },
      [props.system, props.user],
      props.courier
  );

  React.useEffect(() => {
    setFromDate(DateTime.fromJSDate(DateCustom.getFechaOneMonthAgo()));
    props.onChangeFromDate(DateTime.fromJSDate(DateCustom.getFechaOneMonthAgo()));
    setToDate(DateTime.fromJSDate(new Date()));
    props.onChangeToDate(DateTime.fromJSDate(new Date()));
    setConcept('empresa');
    if (props.onChangeConcept) props.onChangeConcept('empresa');
  }, []);

  const onChangeDate = (date: Date | null | undefined, name: string) => {
    if (date) {
      if (name === 'from') {
        setFromDate(DateTime.fromJSDate(date));
        props.onChangeFromDate(DateTime.fromJSDate(date));
        setShouldRefresh(!shouldRefresh);
        if (props.onChangeFromDate) props.onChangeFromDate(DateTime.fromJSDate(date));
      }
      if (name === 'to') {
        setToDate(DateTime.fromJSDate(date));
        props.onChangeToDate(DateTime.fromJSDate(date));
        setShouldRefresh(!shouldRefresh);
        if (props.onChangeToDate) props.onChangeToDate(DateTime.fromJSDate(date));
      }
    }
  };

  const getOptions = () => {
    let ret: IDropdownOption<any>[] = [
      { key: 'empresa', text: t('Empresas') },
      { key: 'proyecto', text: t('Proyectos') },
      { key: 'colaborador', text: t('Colaboradores') },
    ];
    return ret;
  };

  return (
      <div className="ms-Grid" dir="ltr">
        <div className="ms-Grid-row mt-12">
          {props.showCompanyFilter ? (
              <div className="ms-Grid-col ms-sm3 ">
                <CompanySelector
                    system={props.system}
                    courier={props.courier}
                    onChangeSelected={props.onChangeCompany}
                    useAll={true}
                />
              </div>
          ) : null}
          {props.showSectorFilter && props.onChangeNode && nodes ? (
              <div className="ms-Grid-col ms-sm3 ">
                <OrganizationChartDropdown
                    label={t('Sector')}
                    options={nodes}
                    useAllSector={true}
                    onSelectAll={() => {
                      if (props.onChangeNode) props.onChangeNode('');
                    }}
                    onChange={(node) => {
                      if (props.onChangeNode) props.onChangeNode(node.getId());
                    }}
                ></OrganizationChartDropdown>
              </div>
          ) : null}

          <div className="ms-Grid-col ms-sm2 ">
            <DatePicker
                strings={getLocalizedStrings()}
                label={t('Filtrar desde')}
                formatDate={DateCustom.formatDate}
                value={fromDate ? fromDate.toJSDate() : undefined}
                onSelectDate={(e) => onChangeDate(e, 'from')}
                className=""
            />
          </div>
          <div className="ms-Grid-col ms-sm2 ">
            <DatePicker
                strings={getLocalizedStrings()}
                label={t('Filtrar hasta')}
                formatDate={DateCustom.formatDate}
                value={toDate ? toDate.toJSDate() : undefined}
                onSelectDate={(e) => onChangeDate(e, 'to')}
                className=""
            />
          </div>
          {props.useConcept && (
              <div className="ms-Grid-col ms-sm3 ">
                <Dropdown
                    label={t('Agrupar por concepto')}
                    onChange={(e, option) => {
                      if (props.onChangeConcept && option) {
                        props.onChangeConcept(String(option.key));
                        setConcept(String(option!.key));
                      }
                    }}
                    selectedKey={concept}
                    options={getOptions()}
                />
              </div>
          )}

          <div className="ms-Grid-col ms-sm2 ">
            <PrimaryButton
                iconProps={{ iconName: 'Search' }}
                className="mt-for-btn-search ml-2"
                onClick={props.onClickSearch}
                text={t('Buscar')}
            />
          </div>
        </div>
      </div>
  );
};
