import CustomInputIfc from './CustomInputIfc';
import { ErrorList } from './ErrorList';

interface IErrorListForInput {
  customInput: CustomInputIfc;
}

const ErrorListForInput = (props: IErrorListForInput) => {
  return <ErrorList errors={props.customInput.getErrorList()} />;
};

export default ErrorListForInput;
