import { OrganizationChartLevel } from './OrganizationChartLevel';
import { OrganizationChartNode } from './OrganizationChartNode';

export class OrganizationChartTopNode implements OrganizationChartNode {
  protected constructor(private name: string, private id: string, private level: OrganizationChartLevel) {}

  static named(name: string, id: string, level: OrganizationChartLevel) {
    return new this(name, id, level);
  }

  getId(): string {
    return this.id;
  }

  getName(): string {
    return this.name;
  }

  getLevel(): OrganizationChartLevel {
    return this.level;
  }

  path(): string {
    return this.name;
  }

  parentPath(): string {
    return '';
  }

  isIdentifiedBy(anId: string): boolean {
    return this.id === anId;
  }

  isIdentifiedAs(node: OrganizationChartNode): boolean {
    return node.isIdentifiedBy(this.id);
  }

  isDescendantOf(aNode: OrganizationChartNode): boolean {
    return this.isIdentifiedAs(aNode);
  }
}
