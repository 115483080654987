import { DateTime } from 'luxon';
import { SystemDateParser } from '../SystemDateParser';

export interface IMail {
  mail_id: string;
  mail_subject: string;
  mail_body: string;
  mail_from: string;
  mail_to: string;
  mail_id_externo: string;
  mail_message_id: string;
  mail_fecha: string;
  mail_estado: string;
  mail_acceso_url: string | null;
  mail_fecha_carga: string;
  mail_nombre_documentos: string | null;
}

export class Mail {
  protected constructor(
      private id: string,
      private subject: string,
      private body: string,
      private from: string,
      private to: string[],
      private externalId: string,
      private messageId: string,
      private date: DateTime,
      private state: string,
      private accessUrl: string | null,
      private docsFilenames: string[]
  ) {}

  static identifiedBy(
      id: string,
      subject: string,
      body: string,
      from: string,
      to: string[],
      externalId: string,
      messageId: string,
      date: DateTime,
      state: string,
      accessUrl: string | null,
      docsFilenames: string[]
  ) {
    return new this(
        id,
        subject,
        body,
        from,
        to,
        externalId,
        messageId,
        date,
        state,
        accessUrl,
        docsFilenames
    );
  }

  static buildFrom(mail: IMail) {
    let parser = new SystemDateParser('dd/LL/yyyy HH:mm');

    const docsFilenames = mail.mail_nombre_documentos ? mail.mail_nombre_documentos.split(',') : [];
    const to = mail.mail_to.split(',');

    return this.identifiedBy(
        mail.mail_id,
        mail.mail_subject,
        mail.mail_body,
        mail.mail_from,
        to,
        mail.mail_id_externo,
        mail.mail_message_id,
        parser.parse(mail.mail_fecha),
        mail.mail_estado,
        mail.mail_acceso_url,
        docsFilenames
    );
  }

  getId() {
    return this.id;
  }
  getSubject() {
    return this.subject;
  }
  getBody() {
    return this.body;
  }
  getFrom() {
    return this.from;
  }
  getTo() {
    return this.to;
  }
  getExternalId() {
    return this.externalId;
  }
  getMessageId() {
    return this.messageId;
  }
  getDate() {
    return this.date;
  }
  getState() {
    return this.state;
  }
  getAccessUrl() {
    return this.accessUrl;
  }
  getDocsFilenames() {
    return this.docsFilenames;
  }
}
