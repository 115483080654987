import { ProjectDocumentControlForm } from '../../Components/Proyectos/ProjectConfiguration/ProjectConfigurationDocsForm';
import AssertionEntity from '../../lib/FormValidator/Assertion/AssertionEntity';
import AssertionItem from '../../lib/FormValidator/Assertion/AssertionItem';
import DocumentUploadControl from '../documents/DocumentUploadControl';
import Project from './Project';

class ProjectDocumentControlAssertions extends AssertionEntity {
  protected constructor() {
    super();
  }

  static build() {
    const instance = new this();
    instance.addAssertionForConcept();
    instance.addAssertionForDocumentType();
    instance.addAssertionForResponsible();
    return instance;
  }

  addAssertionForConcept(key = ProjectDocumentControlForm.CONCEPT) {
    this.defineAssertionForProperty(
        key,
        AssertionItem.forClosure((value) => value !== '', key, 'Concept cannot be empty')
    );
  }

  addAssertionForDocumentType(key = ProjectDocumentControlForm.DOCUMENT_TYPE) {
    this.defineAssertionForProperty(
        key,
        AssertionItem.forClosure((value) => value !== null, key, 'Document type cannot be empty')
    );
  }

  addAssertionForResponsible(key = ProjectDocumentControlForm.RESPONSIBLE) {
    this.defineAssertionForProperty(
        key,
        AssertionItem.forClosure((value) => value !== null, key, 'Responsible cannot be empty')
    );
  }

}

export default ProjectDocumentControlAssertions;
