export class FileToUpload {
  protected constructor(protected file: File, protected id: string) {}

  static identifiedBy(file: File, id: string) {
    return new this(file, id);
  }

  getFile(): File {
    return this.file;
  }

  getSize(): number {
    return this.file.size;
  }

  getName(): string {
    return this.file.name;
  }

  getType(): string {
    return this.file.type;
  }

  getId(): string {
    return this.id;
  }

  isIdentifiedBy(anId: string): boolean {
    return this.id === anId;
  }

  isFor(file: File | undefined): boolean {
    return !!file && this.file === file;
  }
}
