import React from 'react';
import TimeTaxSupportSystemClient from '../../system/TimeTaxSupportSystemClient';
import MessageCourier from '../../lib/MessageCourier';
import { Dropdown, IDropdownOption } from '@fluentui/react/lib/Dropdown';
import i18next, { t } from 'i18next';
import { Collaborator } from '../../system/collaborators/Collaborator';
import { ComboBox } from '@fluentui/react';

interface ICollaboratorSelectorProps {
  system: TimeTaxSupportSystemClient;
  courier: MessageCourier;
  default?: Collaborator;
  onChangeSelected: (newType?: Collaborator) => void;
  useAll?: boolean;
  value?: string | undefined;
}

interface ICollaboratorSelectorState {
  collaborators: Collaborator[];
  selected?: Collaborator | string;
}

class CollaboratorSelector extends React.Component<ICollaboratorSelectorProps, ICollaboratorSelectorState> {
  constructor(props: ICollaboratorSelectorProps) {
    super(props);
    this.state = {
      collaborators: [],
      selected: this.props.default,
    };
  }

  async componentDidMount() {
    const collaboratorSystem = this.props.system.getCollaboratorSystem();
    const collaborators = await collaboratorSystem.getCollaborators();
    this.setState({ collaborators: collaborators });
  }

  componentDidUpdate(prevProps: ICollaboratorSelectorProps) {
    if (this.props.default !== prevProps.default) {
      this.setState({ selected: this.props.default });
    }
  }

  private getSelectedKey() {
    if (this.state.selected instanceof Collaborator) {
      return this.state.selected.getId();
    }
    if (this.props.useAll && this.state.selected === '0') {
      return '0';
    }
    return '';
  }

  render() {
    const { collaborators, selected } = this.state;

    const options: IDropdownOption[] = collaborators.map((t) => {
      return { key: t.getId(), text: t.getLastName() + ' ' + t.getName() };
    });

    if (this.props.useAll) {
      options.unshift({ key: '0', text: 'Todos' });
    }

    return (
        <>
          <ComboBox
              label={t('Creador del proyecto')}
              onChange={this.onChangeSelection}
              selectedKey={this.props.value === '' ? '' : this.getSelectedKey()}
              options={options}
              allowFreeInput
          />
        </>
    );
  }

  private onChangeSelection = (event: unknown, option?: IDropdownOption<any> | undefined) => {
    const selectedId = option ? (option.key as string) : '';
    const selected = this.state.collaborators.find((collaborator) => collaborator.isIdentifiedBy(selectedId));
    this.setState({ ...this.state, selected: selected?.getId() ? selected : '0' });
    this.props.onChangeSelected(selected);
  };
}

export default CollaboratorSelector;
