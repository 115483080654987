import { DateTime } from 'luxon';
import { DateCustom } from '../../lib/DateCustom';
import XhrRequestHandler from '../../lib/XhrRequestHandler';
import { INonWorkingDay, NonWorkingDay } from './NonWorkingDays';

interface diaHabilRet {
  fecha: string;
}

export class NonWorkingDaysSystem {
  private readonly base = '/dias-no-laborales';
  constructor(private requestHandler: XhrRequestHandler) {}

  async getProximoDiaHabil(fecha: Date | string) {
    let refDate = DateCustom.ParseDateFromApiNoTime(fecha);
    let _refString = DateCustom.FormatDatetoAMDStr(refDate);

    return await this.requestHandler.get<diaHabilRet>(`${this.base}/prox-dia-habil/${_refString}`);
  }

  async getNonWorkingDays(start: Date, end: Date) {
    const _start = DateTime.fromJSDate(start).toFormat('yyyy-LL-dd');
    const _end = DateTime.fromJSDate(end).toFormat('yyyy-LL-dd');
    const dnw = await this.requestHandler.get<INonWorkingDay[]>(`${this.base}/feriados-en/${_start}/${_end}`);
    return dnw.map((d) => NonWorkingDay.buildFrom(d));
  }
}
