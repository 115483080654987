import { Company, CompanyJson } from '../company/Company';
import { CompanySystem } from '../company/CompanySystem';
import ProjectCompanySaved from '../projects/ProjectCompanySaved';

export interface IBillableDistribution {
  disfa_actid: string;
  disfa_activo: boolean;
  disfa_empid: string;
  disfa_id: string;
  disfa_porcentaje: string;
  empresa: CompanyJson;
  __delete?: boolean;
}

export class BillableDistribution {
  constructor(
      system: CompanySystem,
      private active: boolean,
      private id: string,
      private percentage: string,
      private company: Company,
      public __delete?: boolean
  ) {}

  static fromIBillableDistribution(
      companySystem: CompanySystem,
      billableDistribution: IBillableDistribution
  ) {
    const company = Company.fromJson(billableDistribution.empresa);
    return new this(
        companySystem,
        billableDistribution.disfa_activo,
        billableDistribution.disfa_id,
        billableDistribution.disfa_porcentaje,
        company,
        billableDistribution.__delete
    );
  }

  static toIBillableDistribution(_billableDistribution: BillableDistribution, act_id: string) {
    let ret: IBillableDistribution = {
      disfa_actid: act_id,
      disfa_activo: _billableDistribution.getActive(),
      disfa_empid: _billableDistribution.getCompany().getId(),
      disfa_id: _billableDistribution.getId(),
      disfa_porcentaje: _billableDistribution.getPercentage(),
      empresa: Company.toJson(_billableDistribution.getCompany()),
      __delete: _billableDistribution.get__delete(),
    };
    return ret;
  }

  public getActive() {
    return this.active;
  }
  public getId() {
    return this.id;
  }
  public getPercentage() {
    return this.percentage;
  }
  public getCompany() {
    return this.company;
  }
  public get__delete() {
    return this.__delete;
  }

  public setPercentage(val: string) {
    this.percentage = val;
  }

  static getBillableDistributionFromProjectContext(
      companySystem: CompanySystem,
      companies: ProjectCompanySaved[]
  ): BillableDistribution[] {
    return companies.map((company: ProjectCompanySaved): BillableDistribution => {
      return new BillableDistribution(
          companySystem,
          true,
          '',
          company.getPercentage().toString(),
          company.getCompany(),
          false
      );
    });
  }
}
