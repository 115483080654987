import ProjectFolder from './ProjectFolder';

export abstract class ProjectSystemFolder extends ProjectFolder {
  findChildIdentifiedAs(id: string): ProjectFolder | undefined {
    return undefined;
  }

  hasChild(child: ProjectFolder): boolean {
    return false;
  }

  isSystemFolder(): boolean {
    return true;
  }

  hasDescendant(folder: ProjectFolder): boolean {
    return false;
  }

  isRoot(): boolean {
    return false;
  }

  hasChildren(): boolean {
    return false;
  }

  collectPathUpToInto(path: ProjectFolder[], descendant: ProjectFolder) {}

  async getChildren(): Promise<ProjectFolder[]> {
    return [];
  }

  async resetChildren() {}

  async changeNameTo(value: string): Promise<void> {
    throw new Error('Can not change name of system folder');
  }

  moveToTrash(): Promise<void> {
    throw new Error('Can not move to trash a system folder');
  }

  restoreFromTrash(): Promise<void> {
    throw new Error('Can not move to trash a system folder');
  }

  addChild(selectedFolder: ProjectFolder): Promise<void> {
    throw new Error('Can not add a folder to a system folder');
  }

  belongsToUser(): boolean {
    return false;
  }
}
