import { Icon, Text } from '@fluentui/react';
import React from 'react';
import DialogTemplate from '../Common/DialogTemplate';
import { useTranslation, Trans } from 'react-i18next';

export interface IUnableModifyHours {
  show: boolean;
  onClose: (accept: boolean) => void;
  toTag?: boolean;
}

export const ConfirmDuplicatedActivityDialog = (props: IUnableModifyHours) => {
  const { t, i18n } = useTranslation();

  const cancel = () => {
    props.onClose(false);
  };

  const accept = () => {
    props.onClose(true);
  };

  return (
      <DialogTemplate
          showDialog={props.show}
          toggleShowDialog={() => props.onClose(false)}
          title={t('Actividad repetida')}
          onAccept={accept}
          onCancel={cancel}
          acceptText={props.toTag ? t('Aceptar de todas formas') : t('Guardar de todas formas')}
          cancelText={t('Cancelar')}
          minWidth={600}
      >
        <div>
          <Trans>
            <Text block variant="medium">
              <Icon iconName="AlertSolid" className="text-icon" />
              Tienes una actividad repetida programada para la misma hora y con el mismo nombre que la que estás
              intentando {props.toTag ? 'aceptar.' : 'guardar.'}
            </Text>
          </Trans>
        </div>
      </DialogTemplate>
  );
};
