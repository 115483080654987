import { DateCustom } from '../../../../lib/DateCustom';
import DialogTemplate from '../../../Common/DialogTemplate';
import { DatePicker, defaultDatePickerStrings } from '@fluentui/react';
import { styles } from '../../CalendarStyles';
import React from 'react';
import TimeTaxSupportSystemClient from '../../../../system';
import { Activity } from '../../../../system/activity/Activity';
import MessageCourier from '../../../../lib/MessageCourier';
import { useTranslation, Trans } from 'react-i18next';
import SystemConfiguration from '../../../../system/configuration/SystemConfiguration';
import { getLocalizedStrings } from '../../../../lib/LocalizedStringsForDatePicker';
import { getMinDate } from '../../../../lib/DatePickerUtils';

interface IDuplicateDialog {
  system: TimeTaxSupportSystemClient;
  showDialog: boolean;
  toggleShowDialog: () => void;
  activity: Activity;
  refreshEvents: () => void;
  courier: MessageCourier;
  config?: SystemConfiguration;
}

export const DuplicateDialog = (props: IDuplicateDialog) => {
  const { t, i18n } = useTranslation();
  const [dateToDuplicate, setDateToDuplicate] = React.useState<Date | undefined>(undefined);

  React.useEffect(() => {
    if (props.showDialog) {
      getNextWorkingDay();
    }
  }, [props.activity, props.showDialog]);

  const getNextWorkingDay = async () => {
    let _ret = await props.system
        .getActivitySystem()
        .getNonWorkingDaysSystem()
        .getProximoDiaHabil(props.activity.getStart());
    setDateToDuplicate(DateCustom.ParseDateFromApiNoTime(_ret.fecha));
  };

  const duplicar = async () => {
    if (dateToDuplicate) {
      let data = {
        act_inicio: dateToDuplicate,
      };

      try {
        await props.system.getActivitySystem().duplicate(props.activity, data);
        props.refreshEvents();
        props.courier.messageSuccess(t('La actividad se duplicó de manera exitosa'));
      } catch (error) {
        props.courier.messageError(error);
      }
    }
  };

  const handleDateChangeWrapper = (date: Date | null | undefined) => {
    if (date) {
      setDateToDuplicate(date);
    }
  };


  return (
      <DialogTemplate
          showDialog={props.showDialog}
          toggleShowDialog={() => props.toggleShowDialog()}
          title={t('Selecciona el día de la actividad a duplicar')}
          onAccept={duplicar}
          onCancel={() => props.toggleShowDialog()}
          acceptText={t('Duplicar')}
          cancelText={t('Cancelar')}
          minWidth={600}
      >
        <div className="ms-Grid">
          <div className="ms-Grid-row">
            <div className="display-flex">
              <DatePicker
                  strings={getLocalizedStrings()}
                  showWeekNumbers={true}
                  firstWeekOfYear={1}
                  value={dateToDuplicate}
                  className={styles.inputCalendarReplicate}
                  showMonthPickerAsOverlay={true}
                  formatDate={DateCustom.formatDateForDatePicker}
                  onSelectDate={(e) => handleDateChangeWrapper(e)}
                  minDate={getMinDate(props.config, props.activity)}
                  maxDate={
                    props.activity.getProject() ? props.activity.getProject()!.getEnd().toJSDate() : undefined
                  }
              />
            </div>
          </div>
        </div>
      </DialogTemplate>
  );
};
