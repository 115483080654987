import { ProjectSelectorTable } from './ProjectSelectorTable';
import TimeTaxSupportSystemClient from '../../../../system';
import MessageCourier from '../../../../lib/MessageCourier';
import { IProjectForDefenseFile } from '../../../../system/projects/ProjectsSystem';
import ProjectInSettlementPeriod from '../../../../system/settlement/ProjectInSettlementPeriod';

interface IProjectSelectorAssistant {
  tableData: IProjectForDefenseFile[];
  setButtonDisability: (can: boolean) => void;
  type: string;
  onProjectPeriodsChange: (list: ProjectInSettlementPeriod[]) => void;
  system: TimeTaxSupportSystemClient;
  courier: MessageCourier;
}

const ProjectSelectorAssistant = (props: IProjectSelectorAssistant) => {
  const onProjectSelectionChange = (periods: ProjectInSettlementPeriod[]) => {
    props.onProjectPeriodsChange(periods);
  };

  return (
      <div className="mt-2">
        {/* <Text>Selecciona las liquidaciones o proyectos que sustentarán la defensa</Text> */}
        <div className="ms-Grid" dir="ltr">
          <div className="ms-Grid-row">
            <div className="ms-Grid-col ms-sm12 mb-2">
              <ProjectSelectorTable
                  setButtonDisability={props.setButtonDisability}
                  data={props.tableData}
                  type={props.type}
                  onProjectSelectionChange={onProjectSelectionChange}
              />
            </div>
          </div>
          <div className="ms-Grid-row"></div>
        </div>
      </div>
  );
};

export default ProjectSelectorAssistant;
