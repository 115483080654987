import CustomForm from '../../lib/FormValidator/Form/CustomForm';
import { DateTime } from 'luxon';
import { CostPerHourAssertions } from '../../system/cost_per_hour/CostPerHourAssertions';
import { CostPerHour } from '../../system/cost_per_hour/CostPerHour';
// import { OrganizationChartName } from "../../../system/organization_chart/OrganizationChartName";
// import { OrganizationChartNameAssertions } from "../../../system/organization_chart_name/OrganizationChartNameAssertions";

class CostPerHourForm extends CustomForm {
  protected constructor(
      id: string,
      year: string,
      month: string,
      costPH: string,
      colid: string,
      coef: string,
      AVName: string
  ) {
    const cph = CostPerHourAssertions.build();
    super('CostPerHour', cph);

    this.addInputAsserted('id', id);
    this.addInputAsserted('year', year);
    this.addInputAsserted('month', month);
    this.addInputAsserted('costPH', costPH);
    this.addInputAsserted('colid', colid);
    this.addInputAsserted('coef', coef);
    this.addInputAsserted('AVName', AVName);
  }

  private static build(
      id: string,
      year: string,
      month: string,
      costPH: string,
      colid: string,
      coef: string,
      AVName: string
  ) {
    const instance = new this(id, year, month, costPH, colid, coef, AVName);
    return instance;
  }

  static initialize() {
    return this.build('', DateTime.now().year.toString(), DateTime.now().month.toString(), '', '', '', '');
  }

  static with(
      id: string,
      year: string,
      month: string,
      costPH: string,
      colid: string,
      coef: string,
      AVName: string
  ) {
    return this.build(id, year, month, costPH, colid, coef, AVName);
  }

  // getNameInput() {
  //   return this.getInputByName("id");
  // }

  getIdInput() {
    return this.getInputByName('id');
  }

  getYearInput() {
    return this.getInputByName('year');
  }

  getMonthInput() {
    return this.getInputByName('month');
  }

  getCostPHInput() {
    return this.getInputByName('costPH');
  }

  getColidInput() {
    return this.getInputByName('colid');
  }

  getCoefInput() {
    return this.getInputByName('coef');
  }

  getAVNameInput() {
    return this.getInputByName('AVName');
  }

  // setName(name: string) {
  //   this.getNameInput().setValue(name);
  // }

  toCostPerHour(): CostPerHour {
    return CostPerHour.fromJson({
      rcp_id: this.getIdInput().getValue(),
      rcp_periodo_anio: this.getYearInput().getValue(),
      rcp_periodo_mes: this.getMonthInput().getValue(),
      rcp_costo_hora: this.getCostPHInput().getValue(),
      rcp_colid: this.getColidInput().getValue(),
      rcp_coef_valor_agr: this.getCoefInput().getValue(),
      rcp_nombre_valor_agr: this.getAVNameInput().getValue(),
    });
  }
}

export default CostPerHourForm;
