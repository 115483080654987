import * as React from 'react';
import { Dropdown, IDropdownOption } from '@fluentui/react/lib/Dropdown';
import TimeTaxSupportSystemClient from '../../system';
import MessageCourier from '../../lib/MessageCourier';
import { Company } from '../../system/company/Company';
import { t } from 'i18next';

interface ICompanyMultiSelectorProps {
  system: TimeTaxSupportSystemClient;
  courier: MessageCourier;
  default?: Company;
  onChangeSelected: (newType?: string[]) => void;
  onlyBillableCompanies?: boolean;
  useAll?: boolean;
  disabled?: boolean;
}

export const CompanyMultiSelector = (props: ICompanyMultiSelectorProps) => {
  const [selectedKeys, setSelectedKeys] = React.useState<string[]>([]);
  const [companyList, setCompanyList] = React.useState<Company[]>([]);
  const [dropdownOptions, setDropdownOptions] = React.useState<IDropdownOption | undefined | any>([]);

  const getCompanyList = React.useCallback(async () => {
    const companySystem = props.system.getCompanySystem();
    const companies = await companySystem.getList();
    setCompanyList(companies);
  }, [props.system]);

  React.useEffect(() => {
    getCompanyList();
  }, [getCompanyList]);

  React.useEffect(() => {
    let options: IDropdownOption[] = companyList
        .filter((t) => !props.onlyBillableCompanies || t.getAllow_billable_project())
        .map((t) => {
          return { key: t.getId(), text: t.getName() };
        });
    setDropdownOptions(options);
  }, [companyList, props.onlyBillableCompanies]);

  const onChange = (event: React.FormEvent<HTMLDivElement>, item: any): void => {
    if (item) {
      setSelectedKeys(
          item.selected ? [...selectedKeys, item.key as string] : selectedKeys.filter((key) => key !== item.key)
      );
    }
  };

  React.useEffect(() => {
    props.onChangeSelected(selectedKeys);
  }, [selectedKeys]);

  return (
      <>
        <Dropdown
            placeholder={t('Todas las empresas')}
            label={t('Empresas')}
            selectedKeys={selectedKeys}
            onChange={onChange}
            multiSelect
            options={dropdownOptions}
            disabled={props.disabled}
        />
      </>
  );
};
