import MessageCourier from '../../lib/MessageCourier';
import useAsyncDataFull from './useAsyncDataFull';

function useAsyncDataCourier<T>(
    dataPromise: () => Promise<T | undefined>,
    dependencies: any[],
    messageCourier: MessageCourier,
    utilizeBlockUI?: (change: boolean) => void
) {
  const {
    data,
    // error,
    //  loading
    // eslint-disable-next-line react-hooks/exhaustive-deps
  } = useAsyncDataFull(dataPromise, dependencies, messageCourier, utilizeBlockUI);

  // const hasError = !loading && error;
  // if (hasError) {
  //   messageCourier.messageError(error);
  // }

  return data;
}

export default useAsyncDataCourier;
