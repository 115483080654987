import React from 'react';
import { Text } from '@fluentui/react/lib/Text';
import { Icon, IComboBoxOption } from '@fluentui/react';
import TimeTaxSupportSystemClient from '../../../system';
import MessageCourier from '../../../lib/MessageCourier';
import { Collaborator } from '../../../system/collaborators/Collaborator';
import CollaboratorTeam from '../../../system/collaborators/collaborator_team/CollaboratorTeam';
import CollaboratorPersona from '../../Collaborator/CollaboratorPersona';
import ProjectConfigurationTeamForm from './ProjectConfigurationTeamForm';
import CustomInputIfc from '../../../lib/FormValidator/Form/CustomInputIfc';
import InputField from '../../../lib/FormValidator/Form/InputField';
import ProjectConfigurationForm from './ProjectConfigurationForm';
import ComboBoxInput from '../../../lib/FormValidator/Form/ComboBoxInput';
import ButtonInput from '../../../lib/FormValidator/Form/ButtonInput';
import ErrorListForForm from '../../../lib/FormValidator/Form/ErrorListForForm';
import { useTranslation, Trans } from 'react-i18next';
import { SHOW_HORAS_ESTIMADAS } from '../../../common/constants';
import ToggleInput from '../../../lib/FormValidator/Form/ToggleInput';

interface IProjectConfigurationTeam {
  system: TimeTaxSupportSystemClient;
  courier: MessageCourier;
  collaboratorsList: Collaborator[];
  formProjectTeam: ProjectConfigurationTeamForm;
  formProject: ProjectConfigurationForm;
  onBlurCustomInput: (customInput: CustomInputIfc) => void;
  projectTeam: CollaboratorTeam[];
  onChangeProjectTeam: (team: CollaboratorTeam[]) => void;
}

export const ProjectConfigurationTeam = (props: IProjectConfigurationTeam) => {
  const { t, i18n } = useTranslation();

  const [collaboratorOptionsFiltered, setColaboratorOptionsFiltered] = React.useState<Collaborator[]>(
      props.collaboratorsList
  );

  const onChangeCollaborator = (customInput: CustomInputIfc) => {
    props.onChangeProjectTeam(
        props.formProjectTeam
            .getInputProjectTeamItems()
            .getValue()
            .filter((col) => !col.get__delete())
    );

    setCollaboratorOutsideTeam();
  };

  const collaboratorOptions = React.useMemo(() => {
    return props.collaboratorsList;
  }, [props.collaboratorsList]);

  React.useEffect(() => {
    setCollaboratorOutsideTeam();
  }, [props.collaboratorsList]);

  const setCollaboratorOutsideTeam = (val?: string) => {
    let _projectTeam = props.formProjectTeam.getInputProjectTeamItems().getValue();
    let ret: Collaborator[] = collaboratorOptions.filter((col) => {
      const colIsInTeam = _projectTeam.some(
          (colTeam) => colTeam.collaboratorIs(col) && !colTeam.get__delete()
      );
      return !colIsInTeam && col.fullNameLike(val);
    });

    setColaboratorOptionsFiltered(ret);
  };

  const onDeleteItem = (collaboratorTeam: CollaboratorTeam) => {
    props.formProjectTeam.getInputProjectTeamItems().delete(collaboratorTeam);
    onChangeCollaborator(props.formProjectTeam.getInputProjectTeamItems());
    setCollaboratorOutsideTeam();
  };

  const [collaboratorResponsible, setCollaboratorResponsible] = React.useState<Collaborator | null>(null);

  React.useEffect(() => {
    setCollaboratorResponsible(
        props.formProject.getProjectTeamForm().getCollaboratorResponsibleInput().getValue()
    );
  }, [props.formProject]);

  const onChangeCollaboratorResponsible = (_collaboratorResponsible: Collaborator) => {
    setCollaboratorResponsible(_collaboratorResponsible);
  };

  const onBlurPercentageAssignation = async (customInput: CustomInputIfc, colTeam: CollaboratorTeam) => {
    props.onBlurCustomInput(customInput);

    const collaboratorForm = props.formProjectTeam.getInputProjectTeamItems().getFormFor(colTeam);
    if (collaboratorForm.isValid()) {
      const percentage = +customInput.getValue();

      const hoursAssignated = await props.system
          .getProjectSystem()
          .calculatePercentageAssignationHours(
              props.formProject.getStartValue().toJSDate(),
              props.formProject.getEndValue().toJSDate(),
              colTeam.getCollaborator().getId(),
              percentage
          );

      colTeam.setPercentage_asignation(percentage);
      colTeam.setTotal_hours_asignation(hoursAssignated.horasAsignadas);
    }

    onChangeCollaborator(customInput);
  };

  const onChangeResponsible = (customInput: CustomInputIfc, colTeam: CollaboratorTeam) => {
    props.onChangeProjectTeam(props.formProjectTeam.setResponsibleFor(colTeam));
    onChangeCollaboratorResponsible(colTeam.getCollaborator());
  };

  const isResponsible = (colTeam: CollaboratorTeam): boolean => {
    return !!collaboratorResponsible && colTeam.collaboratorIs(collaboratorResponsible);
  };

  const comboBoxOptions = (options: Collaborator[]) => {
    const filteredOptions = options.filter((col: Collaborator) => {
      return props.projectTeam.every((projectCol: CollaboratorTeam) => {
        return !projectCol.collaboratorIs(col);
      });
    });

   const optiones = filteredOptions.map((colOption: Collaborator) => {
      let ret: IComboBoxOption = {
        key: colOption.getId(),
        text: colOption.fullName(),
      };
      return ret;
    });
    const uniqueOptions = optiones.reduce((acc: IComboBoxOption[], current: IComboBoxOption) => {
      if (!acc.some(option => option.key === current.key)) {
        acc.push(current);
      }
      return acc;
    }, []);
    return uniqueOptions;
  };

  return (
      <div className="ms-Grid bg-white-h100 ms-depth-4" dir="ltr">
        <div className="ms-Grid-row padding-1">
          <div className="ms-Grid-col ms-sm12">
            <div className="ms-Grid-row">
              <div className="ms-Grid-col ms-sm6 ">
                <Text variant={'large'} className={'project-config-section-title'}>
                  <Icon iconName="Group" className="text-icon" />
                  {t('Equipo')}
                </Text>
              </div>
              <div className="ms-Grid-row mt-2">
                <div className="ms-Grid-col ms-sm12">
                  <div className="ms-Grid-col ms-sm12">
                    <ErrorListForForm customForm={props.formProjectTeam} />
                    {/* <ErrorListForList customList={props.formProjectTeam.getInputProjectTeamItems()} /> */}
                  </div>
                </div>
              </div>
            </div>

            <div className="ms-Grid-row mt-2">
              <div className="ms-Grid-col ms-sm12">
                <ToggleInput
                    input={props.formProject.getSuggestTagInput()}
                    onBlur={props.onBlurCustomInput}
                    offText={'No taggear participantes automaticamente al crear actividades'}
                    onText={'Taggear participantes automaticamente al crear actividades'}
                />
              </div>
            </div>

            <div className="ms-Grid-row mt-2 pb-30px">
              <div className="ms-Grid-col ms-sm6 ">
                {collaboratorOptionsFiltered && (
                    <>
                      <ComboBoxInput
                          input={props.formProjectTeam.getInputProjectTeamItems()}
                          onChangeExtended={onChangeCollaborator}
                          onBlur={props.onBlurCustomInput}
                          label="Colaboradores"
                          clearAfterSelection={true}
                          options={comboBoxOptions(collaboratorOptionsFiltered)}
                          getItemFromKey={(key) => {
                            let col = collaboratorOptionsFiltered.find((item) => item.isIdentifiedBy(String(key)));
                            if (col) {
                              return CollaboratorTeam.initializeFromColaborador(col);
                            }
                          }}
                      ></ComboBoxInput>
                    </>
                )}
              </div>
            </div>
            {props.projectTeam.map((colTeam, i) => {
              return (
                  <div key={i} className="ms-Grid-row mt-2">
                    <div className="ms-Grid-col ms-sm6 ">
                      <div>
                        <CollaboratorPersona collaborator={colTeam.getCollaborator()} />
                      </div>
                    </div>
                    {props.formProjectTeam.getInputProjectTeamItems().getFormFor(colTeam) && (
                        <div className="ms-Grid-col ms-sm1 text-center">
                          {
                            <ButtonInput
                                checked={isResponsible(colTeam)}
                                onBlur={props.onBlurCustomInput}
                                input={props.formProjectTeam
                                    .getInputProjectTeamItems()
                                    .getFormFor(colTeam)
                                    .getResponsibleInput()}
                                offIcon={'UserFollowed'}
                                onIcon={'PartyLeader'}
                                onChangeExtended={(ci) => onChangeResponsible(ci, colTeam)}
                            />
                          }
                        </div>
                    )}
                    {SHOW_HORAS_ESTIMADAS ? (
                        <>
                          {props.formProjectTeam.getInputProjectTeamItems().getFormFor(colTeam) && (
                              <div className="ms-Grid-col ms-sm2 px-0">
                                <InputField
                                    input={props.formProjectTeam
                                        .getInputProjectTeamItems()
                                        .getFormFor(colTeam)
                                        .getPercentageAssignationInput()}
                                    label=""
                                    onBlur={(customInput) => onBlurPercentageAssignation(customInput, colTeam)}
                                    propsInput={{
                                      placeholder: 'Asig.',
                                      suffix: '%',
                                      maxLength: 5,
                                      title: 'Porcentaje de asignación de trabajo',
                                    }}
                                />
                              </div>
                          )}
                          <div className="ms-Grid-col ms-sm2 text-center mt-1">
                            <Text variant={'small'} className="text-overflow" title="Estimado de horas a cargar">
                              {colTeam.getTotal_hours_asignation()} Hs.
                            </Text>
                          </div>
                        </>
                    ) : (
                        <>
                          <div className="ms-Grid-col ms-sm2 px-0"></div>
                          <div className="ms-Grid-col ms-sm2 text-center mt-1"></div>
                        </>
                    )}

                    <div className="ms-Grid-col ms-sm1 mt-1">
                      <Icon
                          iconName="Trash"
                          className="cursor-pointer"
                          title={t('Eliminar del equipo')}
                          onClick={() => onDeleteItem(colTeam)}
                      />
                    </div>
                  </div>
              );
            })}
          </div>
        </div>
      </div>
  );
};
