import { ListColumn } from './ListColumn';
import { OPERATORS, TYPES } from '../../../common/list-utils';
import { ICalendarProps, IComboBoxOption, IDropdownOption } from '@fluentui/react';
import { OrganizationChartNode } from '../../../system/organization_chart/OrganizationChartNode';

export class ListColumnFilter /* extends ListColumn */ {
  name: string;
  quick?: boolean;
  type: string;
  operator: string;
  placeholder?: string;
  contextForDropdown?: IDropdownOption<any>[];
  contextForCombobox?: IComboBoxOption[];
  contextForOrganizationChartDropdown?: OrganizationChartNode[];
  labelForCheckbox?: string;
  checkBoxValue?: string;
  grow?: number;
  defaultValue?: string | Date;
  hidden?: boolean;
  disabled?: boolean;
  example?: string;
  calendarProps?: ICalendarProps | undefined;
  isMonthSelector?: boolean| undefined;
  formatDate?: ((date?: Date | undefined) => string) | undefined

  constructor(
      type: string = TYPES.INPUT,
      operator: string = OPERATORS.CONTAINS,
      name: string,
      prop: string,
      grow: number,
      defaultValue?: string | Date,
      hidden?: boolean | null,
      disabled?: boolean | null,
      example?: string | null
  ) {
    this.type = type;
    if (type === TYPES.CHECKBOX) {
      this.labelForCheckbox = name;
      this.checkBoxValue = '1';
    }
    this.name = prop;
    this.operator = operator;
    this.placeholder = name;
    this.grow = grow;
    this.defaultValue = defaultValue ? defaultValue : '';
    this.hidden = hidden ? hidden : false;
    this.disabled = disabled ? disabled : false;
    this.example = example ? example : '';
  }
}

// export const getFiltersConfig = (listColums: ListColumn<any>[]) => {
export const getFiltersConfig = (listColums: any) => {
  let _filtersConfig: any[] = [];
  listColums.forEach((col: any) => {
    if (col) {
      _filtersConfig.push(col);
    }
  });

  return _filtersConfig;
};
