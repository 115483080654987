import { useEffect, useState } from 'react';
import { DatePicker } from '@fluentui/react';
import { DateCustom } from '../../../../lib/DateCustom';
import TimeTaxSupportSystemClient from '../../../../system';
import CompanySelector from '../../../Company/CompanySelector';
import { DateTime } from 'luxon';
import MessageCourier from '../../../../lib/MessageCourier';
import { Company } from '../../../../system/company/Company';
import i18next, { t } from 'i18next';
import { getLocalizedStrings } from '../../../../lib/LocalizedStringsForDatePicker';

interface IProjectConfiguration {
  setButtonDisability?: any;
  system: TimeTaxSupportSystemClient;
  CompanySelectorData?: any;
  getDataFromCompanySelectorAssistant?: (company: Company, fromDate: DateTime, toDate: DateTime) => void;
  courier: MessageCourier;
}

const CompanySelectorAssistant = (props: IProjectConfiguration) => {
  const [company, setCompany] = useState<Company | undefined>(undefined);
  const [fromDate, setFromDate] = useState<DateTime>(DateTime.fromJSDate(DateCustom.getFechaOneMonthAgo()));
  const [toDate, setToDate] = useState<DateTime>(DateTime.fromJSDate(new Date()));

  useEffect(() => {
    if (props.CompanySelectorData) {
      if (props.CompanySelectorData.company) {
        setCompany(props.CompanySelectorData.company);
      }
      if (props.CompanySelectorData.fromDate) {
        setFromDate(props.CompanySelectorData.fromDate);
      }
      if (props.CompanySelectorData.toDate) {
        setToDate(props.CompanySelectorData.toDate);
      }
    }
  }, [props.CompanySelectorData]);

  useEffect(() => {
    if (
        company &&
        fromDate &&
        toDate &&
        props.getDataFromCompanySelectorAssistant &&
        props.setButtonDisability
    ) {
      props.getDataFromCompanySelectorAssistant(company, fromDate, toDate);
      props.setButtonDisability(true);
    } else if (props.setButtonDisability) {
      props.setButtonDisability(false);
    }
  }, [company, fromDate, toDate, props]);

  const onChangeDate = (date: Date | null | undefined, name: string) => {
    if (date) {
      if (name === 'from') {
        setFromDate(DateTime.fromJSDate(date));
      }
      if (name === 'to') {
        setToDate(DateTime.fromJSDate(date));
      }
    }
  };

  return (
      <div>
        <div className="ms-Grid mt-2" dir="ltr">
          <div className="ms-Grid-row">
            <div className="ms-Grid-col  ms-sm6">
              <CompanySelector
                  onChangeSelected={(c) => setCompany(c)}
                  system={props.system}
                  courier={props.courier}
                  default={company}
                  onlyBillableCompanies
              />
            </div>
          </div>
          <div className="ms-Grid-row ">
            <div className="ms-Grid-col ms-sm3 ">
              <DatePicker
                  strings={getLocalizedStrings()}
                  label={t('Desde')}
                  formatDate={DateCustom.formatDateForDatePicker_MMYYYY}
                  value={fromDate.toJSDate()}
                  onSelectDate={(e) => onChangeDate(e, 'from')}
                  className=""
              />
            </div>
            <div className="ms-Grid-col ms-sm3 ">
              <DatePicker
                  strings={getLocalizedStrings()}
                  label={t('Hasta')}
                  formatDate={DateCustom.formatDateForDatePicker_MMYYYY}
                  value={toDate.toJSDate()}
                  onSelectDate={(e) => onChangeDate(e, 'to')}
                  className=""
              />
            </div>
          </div>
        </div>
      </div>
  );
};

export default CompanySelectorAssistant;
