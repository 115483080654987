import AssertionEntity from '../../lib/FormValidator/Assertion/AssertionEntity';
import AssertionItem from '../../lib/FormValidator/Assertion/AssertionItem';

class ProjectCollaboratorTeamAssertions extends AssertionEntity {
  protected constructor() {
    super();
  }

  static build() {
    const instance = new this();
    instance.addAssertions();
    return instance;
  }

  addAssertions() {
    this.addAssertionForPercentageAssignation();
  }

  addAssertionForPercentageAssignation(key = 'percentageAssignation') {
    this.defineAssertionForProperty(
        key,
        AssertionItem.forClosure(
            (value) => value >= 0 && value <= 100,
            key,
            'Percentage must be a number between 0 and 100'
        )
    );
  }
}

export default ProjectCollaboratorTeamAssertions;
