function formatearFecha(fecha: Date): string {
    const dia: number = fecha.getDate();
    const mes: number = fecha.getMonth() + 1;
    const año: number = fecha.getFullYear();
  
    // Añadir ceros a la izquierda si es necesario
    const diaFormateado: string = dia < 10 ? '0' + dia : dia.toString();
    const mesFormateado: string = mes < 10 ? '0' + mes : mes.toString();
  
    return año + '-' + mesFormateado + '-' + diaFormateado;
  }
  export default formatearFecha;