import { useEffect, useRef, useState } from 'react';
import { DatePicker, ICalendarProps, Text, Icon, Label, PrimaryButton, Checkbox, defaultDatePickerStrings } from '@fluentui/react';
import { DateCustom } from '../../../../lib/DateCustom';
import TimeTaxSupportSystemClient from '../../../../system';
import CompanySelector from '../../../Company/CompanySelector';
import { DateTime } from 'luxon';
import MessageCourier from '../../../../lib/MessageCourier';
import { getLocalizedStrings } from '../../../../lib/LocalizedStringsForDatePicker';
import { routes } from '../../../../common/constants';
import { useNavigate } from 'react-router-dom';
import CustomDatePicker from '../../../../lib/CustomDatePicker';
import i18next, { t } from 'i18next';
import { DISTRIBUCION_PROYECTOS, DISTRIBUCION_ACTIVIDADES, DISTRIBUCION_HIBRIDA } from '../DirectSettlementsView';

interface IProjectConfiguration {
  setButtonDisability?: any;
  system: TimeTaxSupportSystemClient;
  CompanySelectorData?: any;
  getDataFromCompanySelectorAssistant: (month: DateTime) => void;
  courier: MessageCourier;
  companyList: any[] | undefined;
  hasItems: boolean | null;
  openLockSettlement: () => void;
  month: any;
  getPreliquidacionReport: (concept: string) => void;
  COMPANY_KEY: string;
  lastSettlement: String | undefined;
  settlementMode: string;
  setSettlementMode: (m: string) => void;
}

const SettlementPeriod = (props: IProjectConfiguration) => {
  const navigate = useNavigate();
  const [minDate2, setMinDate] = useState<Date | Date>(new Date());
  const [month, setMonth] = useState<DateTime>();

  useEffect(() => {
    if (month) {
      props.getDataFromCompanySelectorAssistant(month);
    }
    if (!props.hasItems) {
      props.setButtonDisability(false);
    }
    if (props.hasItems) {
      props.setButtonDisability(true);
    }
  }, [month, props, props.hasItems]);

  useEffect(() => {
    props.system.configuration().then(dato => {
      if (dato) {
        const dt = dato.getActivitiesBlocked();
        if (dt) {
          setMinDate(dt.toJSDate());
        } else {
          setMinDate(new Date());
        }
      }
    }).catch(error => {
      console.error('Error al obtener la configuración del sistema:', error);
      setMinDate(new Date());
    });
  }, [props.system]);

  const onChangeDate = (date: Date | null | undefined, name: string) => {
    if (date) {
      let _date = new Date(date.getFullYear(), date.getMonth(), 1);
      setMonth(DateTime.fromJSDate(_date));
    }
  };

  const getLastMonth = () => {
    if (props.lastSettlement) {
      const blockedDates: { [key: number]: number[] } = {};
      const dates = props.lastSettlement.split('|');

      dates.forEach(dateStr => {
        const [year, month] = dateStr.split('-').map(Number);
        if (!blockedDates[year]) {
          blockedDates[year] = [];
        }
        blockedDates[year].push(month);
      });

      return blockedDates;
    }
    return {};
  };

  const getDatesInMonth = (year: number, month: number): Date[] => {
    const date = new Date(year, month, 1);
    const dates: Date[] = [];
    while (date.getMonth() === month) {
      dates.push(new Date(date));
      date.setDate(date.getDate() + 1);
    }
    return dates;
  };

  const disabledMonths = [
    { year: 2023, month: 9 },
    { year: 2023, month: 11 }
  ];

  const getDisabledDates = (months: { year: number, month: number }[]): Date[] => {
    let dates: Date[] = [];
    months.forEach(month => {
      dates = dates.concat(getDatesInMonth(month.year, month.month));
    });
    return dates;
  };

  const disabledDates = getDisabledDates(disabledMonths);

  const customCalendarProps: Partial<ICalendarProps> = {
    isDayPickerVisible: true,
    isMonthPickerVisible: true,
    onSelectDate: (date) => {
      console.log('Date selected: ', date);
    },
    strings: defaultDatePickerStrings,
    showGoToToday: true,
    highlightCurrentMonth: true,
    highlightSelectedMonth: true,
    restrictedDates: disabledDates,
  };

  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);

  const yearObserverRef = useRef<MutationObserver | null>(null);

  const observeYearChanges = () => {
    const holaElement = document.querySelector('.hola-date-2');
    if (holaElement) {
      const focusZoneElement = holaElement?.querySelector('.ms-FocusZone');
      if (focusZoneElement && !yearObserverRef.current) {
        const yearElement = focusZoneElement.querySelector('div[aria-label]');
        if (yearElement) {
          yearObserverRef.current = new MutationObserver((mutationsList) => {
            for (const mutation of mutationsList) {
              if (mutation.type === 'childList') {
                console.log('Año posicionado 2:', yearElement.getAttribute('aria-label'));
              }
            }
          });

          const config = { childList: true, subtree: true };
          yearObserverRef.current.observe(yearElement, config);
        }
      }
    }
  };

  const onClick = () => {
    if (!isDatePickerOpen) {
      setIsDatePickerOpen(true);
      console.log('El DatePicker se ha abierto');

      setTimeout(() => {
        const holaElement = document.querySelector('.hola-date-2');
        if (holaElement) {
          const focusZoneElement = holaElement.querySelector('.ms-FocusZone');
          if (focusZoneElement) {
            const yearElement = focusZoneElement.querySelector('div[aria-label]');
            console.log('Año posicionado:', yearElement?.getAttribute('aria-label'));
          }
          observeYearChanges();
        }
        observeYearChanges();
      }, 100);
    }
  };

  const onSelectDate = (date: Date | null | undefined) => {
    setIsDatePickerOpen(false);
    console.log('El DatePicker se ha cerrado', date);
    if (date) {
      onChangeDate(date, 'from');
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const datePicker = document.querySelector('.ms-DatePicker');
      if (datePicker && !datePicker.contains(event.target as Node)) {
        if (isDatePickerOpen) {
          setIsDatePickerOpen(false);
          console.log('El DatePicker se ha cerrado');
        }
      }
    };

    if (isDatePickerOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isDatePickerOpen]);

  return (
      <div>
        <div>
          <Text variant="smallPlus">
            <Icon iconName="Info" className="text-icon-small"></Icon>
            Es una buena práctica bloquear la carga en el periodo seleccionado antes de generar la liquidación.{' '}
            <span
                className="link-styles"
                onClick={() => {
                  navigate(routes.bloqueo_periodos);
                }}
            >
            Ir a bloqueo de carga.
          </span>
          </Text>
        </div>
        <div className="ms-Grid mt-2" dir="ltr">
          <div className="ms-Grid-row ">
            <div className="ms-Grid-col ms-sm3 ">
              {getLastMonth() ? (
                  <CustomDatePicker
                      strings={getLocalizedStrings()}
                      label={t('Mes a liquidar')}
                      value={props.month.toJSDate()}
                      minDate={minDate2}
                      blockedMonths={getLastMonth()}
                      onSelectDate={onSelectDate}
                  />
              ) : null}
            </div>
            {getLastMonth() ? (
                <div className="ms-Grid-col ms-sm3 ">
                  <Label className="invisible">{t('Buscar')}</Label>
                  <PrimaryButton
                      text={t('Buscar')}
                      onClick={() => props.getPreliquidacionReport(props.COMPANY_KEY)}
                  ></PrimaryButton>
                </div>
            ) : null}
            {props.hasItems === false ? (
                <div className="ms-Grid-col ms-sm12 mt-1">
                  {t('No hay datos de remuneracion para ningun colaborador en el período seleccionado')}
                </div>
            ) : null}
          </div>
          <div className="ms-Grid-row ">
            <div className="ms-Grid-col ms-sm12 mt-1">
              <Label>Indica el modo de distribución</Label>
              <Checkbox
                  label="En base a la distribución de proyectos"
                  checked={props.settlementMode === DISTRIBUCION_PROYECTOS}
                  onChange={() => props.setSettlementMode(DISTRIBUCION_PROYECTOS)}
              />
              <Text style={{ fontSize: 12 }}>
                En este modo se tomará en cuenta la distribución actual que esté configurada en los proyectos.
              </Text>
              <Checkbox
                  label="En base a la distribución de actividades"
                  checked={props.settlementMode === DISTRIBUCION_ACTIVIDADES}
                  onChange={() => props.setSettlementMode(DISTRIBUCION_ACTIVIDADES)}
                  className="mt-2"
              />
              <Text style={{ fontSize: 12 }}>
                En este modo se tomará en cuenta la distribución que tenga cada actividad perteneciente a los
                proyectos
              </Text>
              <Checkbox
                  label="En base a una distribución híbrida "
                  checked={props.settlementMode === DISTRIBUCION_HIBRIDA}
                  onChange={() => props.setSettlementMode(DISTRIBUCION_HIBRIDA)}
                  className="mt-2"
              />
              <Text style={{ fontSize: 12 }}>
                En este modo se tomará en cuenta la opción de los proyectos: "Usuarios pueden editar la
                distribución en actividades". Si esa opción está activa, para ese proyecto se tendrá en
                consideración la distribución de las actividades. Si no está activa, se tendrá en consideración
                la distribución configurada en el proyecto.
              </Text>
            </div>
          </div>
        </div>
      </div>
  );
};

export default SettlementPeriod;
