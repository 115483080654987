import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './translate/en.json';
import es from './translate/es.json';

export const ES_LANGUAGE_VALUE = 'es';
export const EN_LANGUAGE_VALUE = 'en';

export const saveOnSessionStorageLanguage = (val) => {
  sessionStorage.setItem('language', val);
};
export const getSessionStorageLanguage = () => {
  let ret = sessionStorage.getItem('language');
  if (ret === null) {
    var userLang = navigator.language;
    ret = userLang.substring(0, 2);
  }
  return ret;
};

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: en,
  es: es,
};

var userLang = getSessionStorageLanguage();
userLang = userLang.substring(0, 2);

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: userLang, // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
