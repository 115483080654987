import { DateTime } from 'luxon';

export class SystemDateParser {
  constructor(private defaultDateTimeFormat: string) {}

  static defaultParser() {
    return new this('dd/MM/yyyy HH:mm');
  }

  parse(dateTime: string): DateTime {
    return DateTime.fromFormat(dateTime, this.defaultDateTimeFormat);
  }

  parseUTC(dateTime: string): DateTime {
    return DateTime.fromFormat(dateTime, this.defaultDateTimeFormat, { zone: 'UTC' }).toLocal();
  }

  parseNoTime(dateTime: string): DateTime {
    return DateTime.fromFormat(dateTime, 'dd/MM/yyyy');
  }

  parseISODate(date: string): DateTime {
    return DateTime.fromSQL(date);
  }

  getUserTimezone(): string {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  }
}
