import React from 'react';
import ErrorListForInput from './ErrorListForInput';
import { Dropdown, IDropdownOption, IDropdownProps } from '@fluentui/react';
import { useTranslation, Trans } from 'react-i18next';
import CustomForm from './CustomForm';
import CustomInput, { CallableFn } from './CustomInput';
import CustomInputIfc from './CustomInputIfc';

interface IDropdownInput<T> {
  input: CustomInputIfc;
  onChangeExtended?: (input: CustomInputIfc) => void;
  onBlur: (input: CustomInputIfc) => void;
  label: string;
  options: T[];
  mapFn: (item: T) => IDropdownOption;
  onRenderLabel?: any;
}

const DropdownInput = <T extends unknown>(props: IDropdownInput<T>) => {
  const { t, i18n } = useTranslation();

  const [updateFlag, setUpdateFlag] = React.useState(false);
  const [selectedItem, setSelectedItem] = React.useState<IDropdownOption>();

  React.useEffect(() => {
    if (props.input.getValue()) {
      setSelectedItem(props.mapFn(props.input.getValue()));
    } else {
      setSelectedItem(undefined);
    }
  }, [props.input]);

  const updateViewAfterDoing = (anAction: CallableFn) => {
    setUpdateFlag((u) => {
      anAction();
      return !u;
    });
  };

  const onChangeCustomInput = (_: React.FormEvent<HTMLDivElement>, ev: IDropdownOption<any> | undefined) => {
    updateViewAfterDoing(() => {
      props.input.setValue(props.options.find((item) => props.mapFn(item).key === ev!.key));
    });
    if (props.onChangeExtended) {
      props.onChangeExtended(props.input);
    }
    setSelectedItem(ev);
  };

  const onBlurCustomInput = () => {
    updateViewAfterDoing(() => {
      props.input.onBlur();
      props.onBlur(props.input);
    });
  };

  return (
      <>
        <Dropdown
            selectedKey={selectedItem ? selectedItem.key : null}
            options={props.options.map(props.mapFn)}
            label={t(props.label)}
            onChange={onChangeCustomInput}
            onBlur={(ev) => onBlurCustomInput()}
            // selectedKeys={[selectedItem ? selectedItem.key : '']}
            onRenderLabel={props.onRenderLabel ? props.onRenderLabel : undefined}
        />
        {props.input.hasErrors() && <ErrorListForInput customInput={props.input} />}
      </>
  );
};

export default DropdownInput;
