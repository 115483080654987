class FileFolder {
  private children: this[] = [];

  constructor(private path: string, private files: File[]) {}

  static fromPath(path: string) {
    return new this(path, []);
  }

  static root() {
    return this.fromPath(this.rootPath());
  }

  static withChild(path: string, child: FileFolder) {
    // TODO: ver como sacar la referencia a la clase, si es posible
    const instance = this.fromPath(path);
    instance.addChild(child);
    return instance;
  }

  static rootPath() {
    return '/';
  }

  getPath() {
    return this.path;
  }

  pathEquals(pathToTest: string) {
    return this.path === pathToTest;
  }

  isRoot() {
    return this.pathEquals((this.constructor as typeof FileFolder).rootPath());
  }

  isChildOf(anotherFolder: this) {
    return this.path.startsWith(anotherFolder.path);
  }

  pathLengthDifferenceWith(anotherFolder: this) {
    return this.path.length - anotherFolder.path.length;
  }

  addChild(child: this) {
    this.children.push(child);
  }

  emptyChildren() {
    return this.childrenSize() === 0;
  }

  totalSize(): number {
    const allFiles: File[] = [];
    this.collectAllFilesBelowInto(allFiles);

    return allFiles.reduce((previousSize, file) => previousSize + file.size, 0);
  }

  forEachChild(closure: (child: this, relativePath: string) => void) {
    this.children.forEach((child) => {
      closure(child, this.relativePathOf(child));
    });
  }

  async forEachChildAsync(closure: (child: this, relativePath: string) => Promise<void>) {
    for (const child of this.children) {
      await closure(child, this.relativePathOf(child));
    }
  }

  addFile(file: File) {
    this.files.push(file);
  }

  forEachFile(closure: (file: File) => void) {
    this.files.forEach(closure);
  }

  mapFiles<T>(closure: (file: File) => T): T[] {
    const mappedFiles: T[] = [];
    this.forEachFile((file) => {
      mappedFiles.push(closure(file));
    });

    return mappedFiles;
  }

  collectAllFilesBelowInto(allFiles: File[]) {
    this.collectAllFilesInto(allFiles);
    this.forEachChild((child) => child.collectAllFilesBelowInto(allFiles));
  }

  collectAllFilesInto(allFiles: File[]) {
    this.forEachFile((file) => allFiles.push(file));
  }

  getFiles() {
    return [...this.files];
  }

  hasFiles() {
    return this.files.length > 0;
  }

  generateChildren() {
    const pathParts = this.path.substring(1).split('/');
    let fullPath = `/${pathParts[0]}`;
    this.path = fullPath;
    this.resetChildren();
    pathParts.splice(0, 1);

    let last = this;
    pathParts.forEach((part) => {
      fullPath += `/${part}`;
      /**
       * Typescript does not support this.constructor.
       * There is an [open ticket](https://github.com/Microsoft/TypeScript/issues/3841) in it's issue tracker, but not much progress over last 5 years.
       */
      const child = (this.constructor as typeof FileFolder).fromPath(fullPath) as this;
      last.addChild(child);
      last = child;
    });

    if (last !== this) {
      last.syncFilesWith(this);
      this.clearFiles();
    }
  }

  private resetChildren() {
    this.children = [];
  }

  private childrenSize() {
    return this.children.length;
  }

  private syncFilesWith(anotherFolder: this) {
    this.clearFiles();
    anotherFolder.forEachFile((file) => this.addFile(file));
  }

  private clearFiles() {
    this.files = [];
  }

  private relativePathOf(child: this): string {
    const relativePath = child.path.substring(this.path.length);

    return relativePath.startsWith('/') ? relativePath.substring(1) : relativePath;
  }
}

export default FileFolder;
