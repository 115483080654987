import { useEffect, useState } from 'react';
import { ComboBox, DatePicker, IComboBoxProps, Label, PrimaryButton } from '@fluentui/react';
import { DateCustom } from '../../../lib/DateCustom';
import TimeTaxSupportSystemClient from '../../../system';
// import CompanySelector from '../../../Company/CompanySelector';
import { DateTime } from 'luxon';
import MessageCourier from '../../../lib/MessageCourier';
import { Company } from '../../../system/company/Company';
import i18next, { t } from 'i18next';
import { getLocalizedStrings } from '../../../lib/LocalizedStringsForDatePicker';

interface ITemplatePeriod {
  setButtonDisability?: any;
  system: TimeTaxSupportSystemClient;
  CompanySelectorData?: any;
  getDataFromTemplatePeriod: (month: DateTime) => void;
  courier: MessageCourier;
  openLockTemplate: () => void;
  month: any;
  lastTemplate: Date | undefined;
  openModal: () => void;
  hasAccepted: boolean;
}

const TemplatePeriod = (props: ITemplatePeriod) => {
  const [month, setMonth] = useState<DateTime>(DateTime.fromJSDate(new Date()));

  useEffect(() => {
    if (month) {
      props.getDataFromTemplatePeriod(month);
      props.setButtonDisability(true);
    }
  }, [month, props]);

  const onChangeDate = (date: Date | null | undefined, name: string) => {
    if (date) {
      let _date = new Date(date.getFullYear(), date.getMonth(), 1);
      setMonth(DateTime.fromJSDate(_date));
    }
  };

  const getMonthName = () => {
    if (getLastMonth() && props.lastTemplate) {
      var mesNum = props.lastTemplate.getMonth(); // número del mes (1 para enero, 2 para febrero, etc.)
      var fecha = new Date(); // crea un objeto Date con la fecha actual
      fecha.setDate(1);
      fecha.setMonth(mesNum); // establece el mes del objeto Date al mes correspondiente
      var nombreMes = fecha.toLocaleString(t('es-ES'), { month: 'long' }); // obtiene el nombre del mes en formato largo
      return nombreMes; // "marzo"
    }
  };

  const getLastMonth = () => {
    if (props.lastTemplate) {
      let lastTemplate = new Date(props.lastTemplate);
      let _lastMonth = new Date(lastTemplate.setDate(lastTemplate.getDate() + 1));
      return _lastMonth;
    } else return new Date('01/01/1900');
  };

  return (
      <div>
        <div className="ms-Grid mt-2" dir="ltr">
          <div className="ms-Grid-row"></div>
          <div className="ms-Grid-row ">
            <div className="ms-Grid-col ms-sm3 ">
              {getLastMonth() ? (
                  <DatePicker
                      strings={getLocalizedStrings()}
                      label={t('Mes y año')}
                      formatDate={DateCustom.formatDateForDatePicker_MMYYYY}
                      value={props.month.toJSDate()}
                      onSelectDate={(e) => onChangeDate(e, 'from')}
                      className=""
                      calendarProps={{ isDayPickerVisible: false }}
                      minDate={getLastMonth()}
                  />
              ) : null}
            </div>
            {/* {getLastMonth() ? (
            <div className="ms-Grid-col ms-sm3 ">
              <Label className="invisible">{t('Buscar')}</Label>
              <PrimaryButton
                text={t('Buscar')}
                disabled={
                  props.month &&
                  getLastMonth() &&
                  props.month.toJSDate().getMonth() >= getLastMonth().getMonth()
                    ? false
                    : true
                }
                // onClick={() => sendData()}
                onClick={() => props.openModal()}
              ></PrimaryButton>
            </div>
          ) : null} */}
          </div>
        </div>
      </div>
  );
};

export default TemplatePeriod;
