import * as React from 'react';
import { Panel, PanelType } from '@fluentui/react/lib/Panel';
import { INavLinkGroup,INavStyles } from '@fluentui/react/lib/Nav';
import { User } from '../../system/User';

import AppNav from './AppNav';
import Environment from '../../lib/Environment';
import TimeTaxSupportSystemClient from '../../system';

const navStylesWhenNotInPanel: Partial<INavStyles> = {
    root: {
        height: '100%',
        boxSizing: 'border-box',
        border: '1px solid #eee',
        overflowY: 'auto',
    },
};

interface ISidebarProps {
    isOpen: boolean;
    dismissPanel: () => void;
    user: User;
}

const Sidebar: React.FC<ISidebarProps> = (props) => {
    const environment = new Environment();
    const system = TimeTaxSupportSystemClient.start(environment);
    const [menudata, setMenudata] = React.useState<INavLinkGroup[]>([]);
    const [pet, setPet] = React.useState(false); 

    const fetchMenuData = async () => {
        const data: INavLinkGroup[] = await system.menuService();
        setMenudata(data);
    };

    React.useEffect(() => {
        if (!pet) {
            fetchMenuData();
            setPet(true); 
        }
    }, [pet, system]); 
    return (
        <React.Fragment>
             { menudata[0]?.links.length  &&
        <AppNav user={props.user} className="ms-hiddenMdDown" styles={navStylesWhenNotInPanel} menudata={menudata} />}
            <Panel
                isLightDismiss
                isOpen={props.isOpen}
                onDismiss={props.dismissPanel}
                closeButtonAriaLabel="Cerrar"
                type={PanelType.smallFixedNear}
                className="ms-hiddenLgUp"
                styles={{ content: { paddingLeft: '0.5rem' } }}
            >
              { menudata[0]?.links.length  && <AppNav user={props.user} onLinkClicked={props.dismissPanel} menudata={menudata}/> }
            </Panel>
        </React.Fragment>
    );
};

export default Sidebar;
