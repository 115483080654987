import AssertionEntity from '../../../lib/FormValidator/Assertion/AssertionEntity';
import CustomForm from '../../../lib/FormValidator/Form/CustomForm';
import CustomList from '../../../lib/FormValidator/Form/CustomList';

class CompanyEmailForm extends CustomForm {
  protected constructor(companyEmail: string) {
    super('companyEmailForm', new AssertionEntity());
    this.addInputAsserted('companyEmail', companyEmail);
  }

  private static build(companyEmail: string) {
    const instance = new this(companyEmail);
    return instance;
  }

  static initialize() {
    return this.build('');
  }

  static with(companyEmail: string) {
    return this.build(companyEmail);
  }

  getCompanyEmailInput() {
    return this.getInputByName('companyEmail');
  }
}

export class MIRTCompanyEmailsListForm extends CustomList<string> {
  private companyEmailForms: CompanyEmailForm[];

  constructor(propertyName: string, items: string[], assertionEntity: AssertionEntity) {
    super(propertyName, items, assertionEntity);
    this.companyEmailForms = this.items.map((item) => CompanyEmailForm.with(item));
    super.onBlur();
  }

  delete(companyEmail: string) {
    super.delete(companyEmail);
    super.onBlur();
  }

  add(companyEmail: string) {
    super.add(companyEmail);
    super.onBlur();
  }
}

export class MIRTCompanyEmailForm extends CustomForm {
  private companyEmailsList: MIRTCompanyEmailsListForm;

  readonly COMPANY_EMAILS_ITEMS = 'companyEmails';

  protected constructor(emails: string[]) {
    const mia = new AssertionEntity();
    super('CompanyEmails', mia);

    this.companyEmailsList = new MIRTCompanyEmailsListForm(this.COMPANY_EMAILS_ITEMS, emails, mia);
    this.addListInput(this.companyEmailsList);
  }

  static initialize() {
    const instance = new this([]);
    return instance;
  }

  static with(emails: string[]) {
    const instance = new this(emails);
    return instance;
  }

  getInputCompanyEmailsItems(): MIRTCompanyEmailsListForm {
    return this.companyEmailsList;
  }
}
