import React, { useState, useMemo } from 'react';
import { CARD_MODE, GRID_MODE, OPERATORS, TYPES } from '../../../common/list-utils';
import { MailGridList } from './MailGridList';
import { ListColumnFilter } from '../../Common/list-entities/ListColumnFilter';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../../common/constants';
import DialogTemplate from '../../Common/DialogTemplate';
// import './MailList.css';
import TimeTaxSupportSystemClient from '../../../system';
import { Mail } from '../../../system/mail/Mail';
import useAsyncDataCourier from '../../../common/custom-hooks/useAsyncDataCourier';
import MessageCourier from '../../../lib/MessageCourier';
import { useTranslation, Trans } from 'react-i18next';
import { User } from '../../../system/User';
import { MailDialog } from '../MailDialog';
import {
  ContextualMenu,
  Dialog,
  DialogType,
  IComboBoxOption,
  IDropdownOption,
  Text,
  Icon,
} from '@fluentui/react';

interface IMailList {
  system: TimeTaxSupportSystemClient;
  courier: MessageCourier;
  changeBlockUI: (state: boolean) => void;
  user: User;
}

export const MailList = (props: IMailList) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [mode, setMode] = useState(GRID_MODE);

  // tiene colaboradores y empresas
  const context = useAsyncDataCourier(
      () => props.system.getProjectSystem().getContext(),
      [props.system],
      props.courier
  );

  const baseUrl = React.useMemo(() => {
    return props.system.getMailSystem().getBaseUrlForDatagrid();
  }, [props]);

  const [mail, setMail] = React.useState<Mail | undefined>(undefined);
  const [showDialog, setShowDialog] = React.useState<boolean>(false);
  const onItemClick = async (item: any) => {
    props.changeBlockUI(true);
    try {
      const mail = await props.system.getMailSystem().getMailIdentifiedBy(item.mail_id);
      setMail(mail);
    } catch (error) {
      props.courier.messageError(error);
    }
    setShowDialog(true);
    props.changeBlockUI(false);
  };
  const onCloseDialog = () => {
    setShowDialog(false);
    setMail(undefined);
  };

  const companyList = React.useMemo(() => {
    let ret: IComboBoxOption[] = [];
    if (context) {
      ret = context.companyList.map((com) => {
        return {
          key: com.getName(),
          text: com.getName(),
        };
      });
    }
    return ret;
  }, [context]);

  const collaboratorList = React.useMemo(() => {
    let ret: IComboBoxOption[] = [];
    if (context) {
      ret = context.collaboratorList.map((col) => {
        return {
          key: String(col.getEmail()).toLowerCase(),
          text: col.fullName(),
        };
      });
    }
    return ret;
  }, [context]);

  const quickFilters: ListColumnFilter[] = useMemo(
      () => [
        {
          quick: true,
          name: 'mail_subject',
          type: TYPES.INPUT,
          placeholder: t('Asunto'),
          operator: OPERATORS.CONTAINS,
        },
        {
          quick: true,
          name: 'emp_nombre',
          type: TYPES.COMBOBOX,
          placeholder: t('Empresa'),
          operator: OPERATORS.EQUAL,
          contextForCombobox: companyList || [],
        },

        {
          quick: true,
          name: 'from_to_mails',
          type: TYPES.COMBOBOX,
          placeholder: t('Incluye a'),
          operator: OPERATORS.CONTAINS,
          contextForCombobox: collaboratorList || [],
        },
        {
          quick: true,
          name: 'tiene_docs',
          type: TYPES.CHECKBOX,
          labelForCheckbox: t('Tiene docs. adjuntos'),
          checkBoxValue: '1',
          operator: OPERATORS.EQUAL,
        },

        {
          quick: true,
          name: 'mail_fecha',
          type: TYPES.DATE,
          placeholder: t('Enviado despues del'),
          operator: OPERATORS.AFTERORON,
        },
      ],
      [companyList, collaboratorList]
  );

  return (
      <>
        <MailGridList
            changeBlockUI={props.changeBlockUI}
            baseUrl={baseUrl}
            quickFilters={quickFilters}
            onItemClick={(item) => {
              onItemClick(item);
            }}
            onClickCardMode={() => setMode(CARD_MODE)}
            onClickGridMode={() => setMode(GRID_MODE)}
            mode={mode}
            courier={props.courier}
            keyList={'Mails'}
        ></MailGridList>

        {mail && <MailDialog system={props.system} showDialog={showDialog} onClose={onCloseDialog} mail={mail}></MailDialog>}
      </>
  );
};
