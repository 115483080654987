import React from 'react';
import { HorizontalBarChart, IChartProps, MultiStackedBarChart } from '@fluentui/react-charting';
import { DateTime } from 'luxon';
import i18next, { t } from 'i18next';
import { DefaultPalette } from 'office-ui-fabric-react';
import { Icon } from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import { User } from '../../system/User';

export interface ICalendarHorasEsperadasProgressBar {
  horasCargadasPlantilla: number;
  horasEsperadas: number;
  horasExcedidas: number;
  horasFaltantes: number;
  horasLicencia: number;
  fechaDesde: string;
  fechaHasta: string;
  user: User;
}

export const CalendarHorasEsperadasProgressBar = (props: ICalendarHorasEsperadasProgressBar) => {
  const hideRatio: boolean[] = [true, false];
  const { t, i18n } = useTranslation();

  const data: IChartProps[] = React.useMemo(() => {
    if (props.user.hasPowerUserPrivileges()) {
      return [
        {
          chartTitle: t(''),
          chartData: [
            {
              legend: t('Horas cargadas'),
              data: props.horasCargadasPlantilla,
              color: '#00bcf2',
            },
            {
              legend: t('Horas de licencia'),
              data: props.horasLicencia,
              color: '#b4a0ff',
            },
            {
              legend: t('Horas faltantes'),
              data: props.horasFaltantes,
              color: '#db756e',
            },
            {
              legend: t('Horas excedidas'),
              data: props.horasExcedidas,
              color: '#605e5c',
            },
          ],
        },
      ];
    } else {
      return [
        {
          chartTitle: t(''),
          chartData: [
            {
              legend: t('Horas cargadas'),
              data: props.horasCargadasPlantilla,
              color: '#00bcf2',
            },
            {
              legend: t('Horas de licencia'),
              data: props.horasLicencia,
              color: '#b4a0ff',
            },
            {
              legend: t('Horas faltantes'),
              data: props.horasFaltantes,
              color: '#db756e',
            },
          ],
        },
      ];
    }
  }, [props]);

  return (
      <div>
        <MultiStackedBarChart
            data={data}
            hideRatio={hideRatio}
            width={300}
            focusZonePropsForLegendsInHoverCard={{ 'aria-label': 'legends Container' }}
            hideLegend={true}
        />
        <div>
          <span className="rectangulo-cargadas"> </span>
          <span>
          {parseFloat(props.horasCargadasPlantilla.toFixed(2))} {t('Horas cargadas')}
        </span>
        </div>
        <div>
          <span className="rectangulo-licencia"></span>
          <span>
          {parseFloat(props.horasLicencia.toFixed(2))} {t('Horas de licencia')}
        </span>
        </div>

        {props.user.hasPowerUserPrivileges() && (
            <div>
              <span className="rectangulo-excedidas"> </span>

              <span>
            {parseFloat(props.horasExcedidas.toFixed(2))} {t('Horas excedidas')}
          </span>
            </div>
        )}
        <div>
          <span className="rectangulo-faltantes"> </span>

          <span>
          {parseFloat(props.horasFaltantes.toFixed(2))} {t('Horas faltantes')}
        </span>
        </div>

        {/* <div>
        <span className="mini-text fw-500">
          <Icon iconName="Warning" className="warning-color text-icon"></Icon>
          {t('Se incluyen las horas cargadas hasta el día de ayer')}
        </span>
      </div> */}
      </div>
  );
};
