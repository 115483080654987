import { DateTime } from 'luxon';
import { Collaborator } from '../collaborators/Collaborator';
import DocumentUploadControlConfirmed from './DocumentUploadControlConfirmed';
import { SystemDocument } from './SystemDocument';
import SystemDocumentType from './SystemDocumentType';

export class SystemNotUploadedDocument extends SystemDocument {
  isConfidentialDocument(): boolean {
    throw new Error('Method not implemented.');
  }
  getSizeInBytes(): number {
    return 0;
  }
  uploaded(): boolean {
    return false;
  }
  isActive(): boolean {
    return false;
  }
  getType(): Promise<SystemDocumentType> {
    throw new Error('Method not implemented.');
  }
  downloadUrl(): Promise<string> {
    throw new Error('Method not implemented.');
  }
  permanentlyDelete(): Promise<void> {
    throw new Error('Method not implemented.');
  }
  getContentType(): string {
    throw new Error('Method not implemented.');
  }
  getId(): string {
    throw new Error('Method not implemented.');
  }
  getUploadedBy(): Promise<Collaborator> {
    throw new Error('Method not implemented.');
  }
  getUploadedDate(): string {
    throw new Error('Method not implemented.');
  }
  getDeletedOn(): string | undefined {
    throw new Error('Method not implemented.');
  }
  getTypeDescription(): Promise<string> {
    throw new Error('Method not implemented.');
  }
  getName(): string {
    throw new Error('Method not implemented.');
  }
  getRefersToPeriod() {
    return DateTime.fromJSDate(new Date());
  }

  isDeleted(): boolean {
    return false;
  }
  hasControlReference(): boolean {
    return false;
  }
  getControlReference(): undefined | DocumentUploadControlConfirmed {
    throw new Error('Method not implemented.');
  }
}
