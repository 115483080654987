import React from 'react';
import { ComboBox, DefaultPalette, IComboBox, IComboBoxOption, Stack } from '@fluentui/react';
import { Text } from '@fluentui/react/lib/Text';
import { CommandBarButton } from '@fluentui/react/lib/Button';
import BlockUi from 'react-block-ui';

import { boldStyle } from '../Common/TextStyles';
import ProjectDocumentList from './ProjectDocumentList';
import DocumentView from './DocumentView';
import { themeLight } from '../../styles/colors';
import './ProjectDocumentView.css';
import FileDropzone from '../FileDropzone/FileDropzone';
import FileFolder from '../FileDropzone/FileFolder';
import TimeTaxSupportSystemClient, { ProjectFolder } from '../../system';
import { ProjectRelatedDocument } from '../../system/documents/ProjectRelatedDocument';
import useAsyncDataFull from '../../common/custom-hooks/useAsyncDataFull';
import MessageCourier from '../../lib/MessageCourier';
import useAsyncDataCourier from '../../common/custom-hooks/useAsyncDataCourier';
import { User } from '../../system/User';
import { DocumentUploadControlSaved } from '../../system/documents/DocumentUploadControl';
import { useTranslation, Trans } from 'react-i18next';

const DocumentNotSelected: React.FC = () => {
  const { t, i18n } = useTranslation();
  return (
      <Stack
          verticalFill
          horizontalAlign="center"
          verticalAlign="center"
          className="full-width no-document-selected-message-wrapper"
      >
        <Text styles={boldStyle} block nowrap>
          {t('Seleccionar un documento para ver su detalle')}
        </Text>
        <Text styles={{ root: { color: themeLight.secondaryText } }} block nowrap>
          {t('No hay nada seleccionado')}
        </Text>
      </Stack>
  );
};

interface IProjectSelectedFolderViewProps {
  selectedFolder: ProjectFolder;
  courier: MessageCourier;
  system: TimeTaxSupportSystemClient;
  onDropFileFolder: (fileFolder: FileFolder) => Promise<void>;
  setDocumentsBeingDragged: (documents: ProjectRelatedDocument[]) => void;
  user: User;
  controlDocument?: DocumentUploadControlSaved;
  backToControlDocsSection: () => void;
  onUploadTooLarge?: () => void;
}

const ProjectSelectedFolderView: React.FC<IProjectSelectedFolderViewProps> = ({
                                                                                selectedFolder,
                                                                                ...props
                                                                              }) => {
  const { t, i18n } = useTranslation();
  const [selectedDocument, setSelectedDocument] = React.useState<ProjectRelatedDocument>();
  const [showFilters, setShowFilters] = React.useState(false);
  const [selectedCollaboratorsIds, setSelectedCollaboratorsIds] = React.useState<string[]>([]);
  const [selectedDocumentTypesIds, setSelectedDocumentTypesIds] = React.useState<string[]>([]);

  const {
    data: documents,
    reload,
    loading,
  } = useAsyncDataFull(
      () => {
        return selectedFolder.getDocuments(true);
      },
      [selectedFolder],
      props.courier
  );

  const uploadedByList = useAsyncDataCourier(
      () => collectAsync(documents, (doc) => doc.getUploadedBy()),
      [documents],
      props.courier
  );

  const documentTypeList = useAsyncDataCourier(
      () => collectAsync(documents, (doc) => doc.getType()),
      [documents],
      props.courier
  );

  const documentsToDisplay = useAsyncDataCourier(
      async () => {
        if (!documents) return undefined;
        if (selectedCollaboratorsIds.length === 0 && selectedDocumentTypesIds.length === 0)
          return [...documents];
        const filtered: ProjectRelatedDocument[] = [];
        for (const document of documents) {
          const collaborator = await document.getUploadedBy();
          const type = await document.getType();
          const uploadedByAny =
              selectedCollaboratorsIds.length === 0 ||
              selectedCollaboratorsIds.some((anId) => collaborator.isIdentifiedBy(anId));
          const typeMatches =
              selectedDocumentTypesIds.length === 0 ||
              selectedDocumentTypesIds.some((anId) => type.isIdentifiedBy(anId));
          if (uploadedByAny && typeMatches) {
            filtered.push(document);
          }
        }
        return filtered;
      },
      [documents, selectedCollaboratorsIds, selectedDocumentTypesIds],
      props.courier
  );

  const onSelectDocument = (document: ProjectRelatedDocument) => {
    setSelectedDocument(document);
  };

  const onDragDocumentStart = (documents: ProjectRelatedDocument[]): void => {
    props.setDocumentsBeingDragged(documents);
  };

  const onDragDocumentEnd = (): void => {
    props.setDocumentsBeingDragged([]);
  };

  const onChangeDocument = () => {
    reload();
    setSelectedDocument(undefined);
  };

  const toggleFilters = () => {
    setShowFilters((p) => !p);
  };

  const onChangeFor =
      (stateSetter: (value: React.SetStateAction<string[]>) => void) =>
          (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string): void => {
            let selected = option?.selected;

            if (option) {
              stateSetter((prevSelectedKeys) =>
                  selected
                      ? [...prevSelectedKeys, option!.key as string]
                      : prevSelectedKeys.filter((k) => k !== option!.key)
              );
            }
          };

  return (
      <>
        <div className="ms-Grid-row">
          <BlockUi blocking={loading}>
            <div className="ms-Grid-col ms-sm6">
              <div className="ms-Grid-row" style={{ backgroundColor: DefaultPalette.white }}>
                <Stack horizontal>
                  <Stack.Item grow={1} styles={{ root: { display: 'flex', padding: 16 } }}>
                    <CommandBarButton
                        iconProps={{ iconName: 'Filter' }}
                        text={t('Filtrar')}
                        onClick={() => toggleFilters()}
                    />
                  </Stack.Item>
                </Stack>
              </div>
              {showFilters && (
                  <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm6">
                      {uploadedByList && uploadedByList.length > 0 && (
                          <ComboBox
                              multiSelect
                              selectedKey={selectedCollaboratorsIds}
                              label={t('Cargados por')}
                              options={uploadedByList.map((collaborator) => ({
                                key: collaborator.getId(),
                                text: collaborator.fullName(),
                              }))}
                              onChange={onChangeFor(setSelectedCollaboratorsIds)}
                          />
                      )}
                    </div>
                    <div className="ms-Grid-col ms-sm6">
                      {documentTypeList && documentTypeList.length > 0 && (
                          <ComboBox
                              multiSelect
                              selectedKey={selectedDocumentTypesIds}
                              label={t('Tipo')}
                              options={documentTypeList.map((type) => ({
                                key: type.getId(),
                                text: type.getName(),
                              }))}
                              onChange={onChangeFor(setSelectedDocumentTypesIds)}
                          />
                      )}
                    </div>
                  </div>
              )}
              <div className="ms-Grid-row">
                <FileDropzone
                    onDropStart={() => props.system.getDocumentSystem().informPreparingUpload()}
                    onDrop={props.onDropFileFolder}
                    onUploadTooLarge={props.onUploadTooLarge}
                >
                  {documentsToDisplay && (
                      <ProjectDocumentList
                          documents={documentsToDisplay}
                          selected={selectedDocument}
                          onDragDocumentStart={onDragDocumentStart}
                          onDragDocumentEnd={onDragDocumentEnd}
                          separatorOnTop
                          onSelect={onSelectDocument}
                          noDocumentsMessage={
                              t('Sin documentos cargados.') +
                              (selectedFolder.isSystemFolder()
                                  ? ''
                                  : t(' Arrastra y suelta documentos aquí para comenzar.'))
                          }
                      />
                  )}
                </FileDropzone>
              </div>
            </div>
          </BlockUi>

          <div className="ms-Grid-col ms-sm6">
            {selectedDocument ? (
                <DocumentView
                    user={props.user}
                    document={selectedDocument}
                    notifyChange={onChangeDocument}
                    courier={props.courier}
                    system={props.system}
                    controlDocument={props.controlDocument}
                    backToControlDocsSection={props.backToControlDocsSection}
                />
            ) : (
                <DocumentNotSelected />
            )}
          </div>
        </div>
      </>
  );
};

export default ProjectSelectedFolderView;

async function collectAsync<T>(
    documents: ProjectRelatedDocument[] | undefined,
    collector: (document: ProjectRelatedDocument) => Promise<T>
): Promise<T[] | undefined> {
  if (!documents) return [];
  const choices: T[] = [];
  for (const document of documents) {
    const value = await collector(document);
    if (!choices.includes(value)) choices.push(value);
  }
  return choices;
}
