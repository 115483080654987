import React from 'react';

import TimeTaxSupportSystemClient from '../../system/TimeTaxSupportSystemClient';
import MessageCourier from '../../lib/MessageCourier';
import SystemDocumentType from '../../system/documents/SystemDocumentType';
import { Dropdown, IDropdownOption } from '@fluentui/react/lib/Dropdown';
import i18next, { t } from 'i18next';

interface IDocumentTypeSelectorProps {
  system: TimeTaxSupportSystemClient;
  courier: MessageCourier;
  default?: SystemDocumentType;
  onChangeSelected: (newType?: SystemDocumentType) => void;
}

interface IDocumentTypeSelectorState {
  types: SystemDocumentType[];
  selected?: SystemDocumentType;
}

class DocumentTypeSelector extends React.Component<IDocumentTypeSelectorProps, IDocumentTypeSelectorState> {
  constructor(props: IDocumentTypeSelectorProps) {
    super(props);
    this.state = {
      types: [],
      selected: this.props.default,
    };
  }

  //
  async componentDidMount() {
    const documentsSystem = this.props.system.getDocumentSystem();
    const types = await documentsSystem.getAvailableDocumentTypes();
    this.setState({ types });
  }

  componentDidUpdate(prevProps: IDocumentTypeSelectorProps) {
    if (this.props.default !== prevProps.default) {
      this.setState({ selected: this.props.default });
    }
  }

  render() {
    const { types, selected } = this.state;

    const options: IDropdownOption[] = types.map((t) => ({ key: t.getId(), text: t.getName() }));
    const selectedId = selected ? selected.getId() : null;
    return (
        <Dropdown
            label={t('Tipo de documento')}
            onChange={this.onChangeSelection}
            selectedKey={selectedId}
            options={options}
        />
    );
  }

  private onChangeSelection = (event: unknown, option?: IDropdownOption<any> | undefined) => {
    const selectedId = option ? (option.key as string) : '';
    const selected = this.state.types.find((type) => type.isIdentifiedBy(selectedId));
    this.setState({ ...this.state, selected });
    this.props.onChangeSelected(selected);
  };
}

export default DocumentTypeSelector;
