import React from 'react';
import { DefaultButton, PrimaryButton } from '@fluentui/react/lib/Button';
import Dialog, { DialogFooter } from '@fluentui/react/lib/Dialog';
import { ProjectRelatedDocument } from '../../system/documents/ProjectRelatedDocument';
import TimeTaxSupportSystemClient, { ProjectFolder } from '../../system';
import useAsyncData from '../../common/custom-hooks/useAsyncData';
import { ChoiceGroup } from '@fluentui/react/lib/ChoiceGroup';
import Project from '../../system/projects/Project';
import { useTranslation } from 'react-i18next';

interface IDocumentRestoreDialogProps {
  system: TimeTaxSupportSystemClient;
  document: ProjectRelatedDocument;
  onRestoreInto: (folder: ProjectFolder) => void;
  onCancel: () => void;
}

export const DocumentRestoreDialog: React.FC<IDocumentRestoreDialogProps> = (props) => {
  const project = React.useMemo(() => props.document.getProject(), [props.document]);

  const originalPath = useAsyncData(
      () => props.system.getProjectSystem().originalFolderOfDocument(props.document, project),
      [project, props.document, props.system]
  );

  return (
      <RestoreToFolderDialog
          system={props.system}
          project={project}
          onRestoreInto={props.onRestoreInto}
          onCancel={props.onCancel}
          originalPath={originalPath}
      />
  );
};

interface IFolderRestoreDialogProps {
  system: TimeTaxSupportSystemClient;
  folder: ProjectFolder;
  onRestoreInto: (folder: ProjectFolder) => void;
  onCancel: () => void;
}

export const FolderRestoreDialog: React.FC<IFolderRestoreDialogProps> = (props) => {
  const project = React.useMemo(() => props.folder.getProject(), [props.folder]);

  const originalPath = useAsyncData(
      () => props.system.getProjectSystem().originalFolderOfFolder(props.folder, project),
      [project, props.folder, props.system]
  );

  return (
      <RestoreToFolderDialog
          system={props.system}
          project={project}
          onRestoreInto={props.onRestoreInto}
          onCancel={props.onCancel}
          originalPath={originalPath}
      />
  );
};

interface IRestoreToFolderDialogProps {
  system: TimeTaxSupportSystemClient;
  project: Project;
  originalPath?: ProjectFolder;
  onRestoreInto: (folder: ProjectFolder) => void;
  onCancel: () => void;
}

const RestoreToFolderDialog: React.FC<IRestoreToFolderDialogProps> = (props) => {
  const { t, i18n } = useTranslation();
  const [selectedFolder, setSelectedFolder] = React.useState<ProjectFolder>();
  const [folderOptions, setFolderOptions] = React.useState<ProjectFolder[]>([]);

  const rootFolder = useAsyncData(
      () => props.system.getProjectSystem().rootFolderOf(props.project),
      [props.project, props.system]
  );

  React.useEffect(() => {
    if (rootFolder) {
      setFolderOptions([rootFolder]);
    }
  }, [rootFolder]);

  return (
      <Dialog hidden={false} onDismiss={props.onCancel}>
        <ChoiceGroup
            selectedKey={selectedFolder?.getId()}
            options={folderOptions.map((folder) => {
              return {
                key: folder.getId(),
                text: folder.getName(),
                onRenderField: (props, render) => {
                  const onGoClick = async () => {
                    setSelectedFolder(undefined);
                    const options = await folder.getChildren();
                    setFolderOptions(options.filter((f) => !f.isSystemFolder()));
                  };

                  return (
                      <div>
                        {render!(props)}
                        <PrimaryButton
                            onClick={onGoClick}
                            iconProps={{ iconName: 'Go' }}
                            styles={{ root: { marginLeft: '0.5rem', maxHeight: '1.5rem' } }}
                        />
                      </div>
                  );
                },
              };
            })}
            onChange={(_ev, option) => {
              setSelectedFolder(option && folderOptions.find((folder) => folder.isIdentifiedBy(option.key)));
            }}
            label={t('Selecciona una carpeta')}
        />
        <DialogFooter>
          {props.originalPath && (
              <PrimaryButton
                  onClick={() => {
                    props.onRestoreInto(props.originalPath!);
                  }}
                  text={t('Recuperar en ') + props.originalPath.getName()}
              />
          )}
          <PrimaryButton
              onClick={() => {
                props.onRestoreInto(selectedFolder!);
              }}
              disabled={!selectedFolder}
              text={t('Recuperar')}
          />
          <DefaultButton onClick={props.onCancel} text={t('Cancelar')} />
        </DialogFooter>
      </Dialog>
  );
};
