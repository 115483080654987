import CustomForm from '../../../lib/FormValidator/Form/CustomForm';
import { newEntityDefaultId } from '../../../common/constants';
import { DateTime } from 'luxon';
import { ProjectDescriptorAssertions } from '../../../system/projects/ProjectDescriptorAssertions';
import ProjectDescriptor, { IProjectDescriptor } from '../../../system/projects/ProjectDescriptor';
import CustomInput, { InputObserver } from '../../../lib/FormValidator/Form/CustomInput';
import { DateCustom } from '../../../lib/DateCustom';
import ObjectError from '../../../lib/FormValidator/Assertion/ObjectError';

export const LIQUIDACION_INDIRECTA = 'I';
export const LIQUIDACION_DIRECTA = 'D';

const DEFAULT_SETTLEMENT = LIQUIDACION_DIRECTA;

class ProjectDescriptorForm extends CustomForm implements InputObserver {
  readonly ID = 'id';
  readonly CODE = 'code';
  readonly NAME = 'name';
  readonly AREA = 'area';
  readonly BILLABLE = 'billable';
  readonly SETTLEMENT = 'settlement';
  readonly CAN_VIEW_DISTRIBUTION = 'canViewDistribution';
  readonly CAN_EDIT_DISTRIBUTION = 'canEditDistribution';
  readonly STATE = 'state';
  readonly START = 'start';
  readonly END = 'end';
  readonly DESCRIPTION = 'description';
  readonly CONFIDENTIAL_DOCUMENT = 'confidentialDocument';
  readonly SUGGEST_TAG = 'suggestTag';
  readonly OWNER_COLID = 'owner_colid';

  protected constructor(
      id: string | null,
      code: string,
      name: string,
      billable: boolean,
      settlement: string,
      canViewDistribution: boolean,
      canEditDistribution: boolean,
      state: string,
      start: DateTime,
      end: DateTime,
      description: string,
      confidentialDocumentsByDefault,
      suggestTag,
      ownerColId
  ) {
    const projectAssertions = ProjectDescriptorAssertions.build();
    super('ProjectDescriptor', projectAssertions);

    this.addInputAsserted(this.ID, id);
    this.addInputAsserted(this.CODE, code);
    this.addInput(this.NAME, name);
    this.addInputAsserted(this.BILLABLE, billable);
    this.addInputAsserted(this.SETTLEMENT, settlement);
    this.addInputAsserted(this.CAN_VIEW_DISTRIBUTION, canViewDistribution);
    this.addInputAsserted(this.CAN_EDIT_DISTRIBUTION, canEditDistribution);
    this.addInputAsserted(this.CONFIDENTIAL_DOCUMENT, confidentialDocumentsByDefault);
    this.addInputAsserted(this.SUGGEST_TAG, suggestTag);
    this.addInputAsserted(this.STATE, state);
    this.addInputAsserted(this.START, start);
    this.addInputAsserted(this.OWNER_COLID, ownerColId);
    const endInput = this.addInputAsserted(this.END, end);
    endInput.addInputObserver(this);
    this.addInputAsserted(this.DESCRIPTION, description);
  }

  static build(
      id: string | null,
      code: string,
      name: string,
      billable: boolean,
      settlement: string,
      canViewDistribution: boolean,
      canEditDistribution: boolean,
      state: string,
      start: DateTime,
      end: DateTime,
      description: string,
      confidentialDocumentsByDefault,
      suggestTag,
      ownerColId: string
  ) {
    return new this(
        id,
        code,
        name,
        billable,
        settlement,
        canViewDistribution,
        canEditDistribution,
        state,
        start,
        end,
        description,
        confidentialDocumentsByDefault,
        suggestTag,
        ownerColId
    );
  }

  static initialize() {
    return this.build(
        null,
        '',
        '',

        true,
        DEFAULT_SETTLEMENT,
        false,
        false,
        '',
        DateTime.fromJSDate(new Date()),
        DateTime.fromJSDate(DateCustom.getFechaOneMonthLater(new Date())),
        '',
        false,
        true,
        ''
    );
  }

  static with(project: IProjectDescriptor) {
    const instance = this.build(
        project.getId(),
        project.getCode(),
        project.getName(),
        project.getBillable(),
        project.getSettlementType(),
        project.getCanViewDistribution(),
        project.getCanEditDistribution(),
        project.getState(),
        project.getStart(),
        project.getEnd(),
        project.getDescription(),
        project.getConfidentialDocumentsByDefault(),
        project.getSuggestTags(),
        project.getOwnerColid()
    );

    instance.evaluate();
    return instance;
  }

  toProjectDescriptor(): ProjectDescriptor {
    return ProjectDescriptor.named(
        this.getNameInput().getValue(),
        this.getCodeInput().getValue(),
        this.getIdInput().getValue(),
        this.getDescriptionInput().getValue(),
        this.getStateInput().getValue(),
        this.getStartInput().getValue(),
        this.getEndInput().getValue(),
        this.getBillableInput().getValue(),
        true,
        this.getSettlementInput().getValue(),
        this.getCanEditDistributionInput().getValue(),
        this.getCanViewDistributionInput().getValue(),
        this.getConfidentialDocumentInput().getValue(),
        this.getSuggestTagInput().getValue(),
        this.getOwnerColidInput().getValue()
    );
  }

  onEvaluate(input: CustomInput): ObjectError[] | undefined {
    return this.evaluateObject();
  }

  getIdInput() {
    return this.getInputByName(this.ID);
  }

  getCodeInput() {
    return this.getInputByName(this.CODE);
  }

  getNameInput() {
    return this.getInputByName(this.NAME);
  }

  getAreaInput() {
    return this.getInputByName(this.AREA);
  }

  getBillableInput(): CustomInput {
    return this.getInputByName(this.BILLABLE) as CustomInput;
  }

  getSettlementInput() {
    return this.getInputByName(this.SETTLEMENT);
  }

  getCanViewDistributionInput() {
    return this.getInputByName(this.CAN_VIEW_DISTRIBUTION);
  }
  getCanEditDistributionInput() {
    return this.getInputByName(this.CAN_EDIT_DISTRIBUTION);
  }

  getConfidentialDocumentInput() {
    return this.getInputByName(this.CONFIDENTIAL_DOCUMENT);
  }

  getSuggestTagInput() {
    return this.getInputByName(this.SUGGEST_TAG);
  }

  getStateInput() {
    return this.getInputByName(this.STATE);
  }

  getStartInput() {
    return this.getInputByName(this.START);
  }

  getStartValue() {
    return this.getStartInput().getValue();
  }

  getEndValue() {
    return this.getEndInput().getValue();
  }

  getEndInput() {
    return this.getInputByName(this.END);
  }

  getDescriptionInput() {
    return this.getInputByName(this.DESCRIPTION);
  }

  getOwnerColidInput() {
    return this.getInputByName(this.OWNER_COLID);
  }

  isNew(): boolean {
    const id = this.getInputByName(this.ID).getValue();
    return id === newEntityDefaultId || !id;
  }
}

export default ProjectDescriptorForm;
