import * as React from 'react';
import { Dialog, DialogType, DialogFooter } from '@fluentui/react/lib/Dialog';
import { PrimaryButton, DefaultButton } from '@fluentui/react/lib/Button';
import { ContextualMenu } from '@fluentui/react/lib/ContextualMenu';
import { Toggle } from '@fluentui/react/lib/Toggle';
import { useBoolean } from '@fluentui/react-hooks';
import DialogTemplate from './DialogTemplate';
import { FontWeights, ITextStyles, Icon, Text } from "office-ui-fabric-react";

const textBoldStyle = { fontWeight: FontWeights.semibold };
const boldStyle: Partial<ITextStyles> = {
  root: textBoldStyle,
};

// const modalPropsStyles = { main: { minWidth: 900 } };

interface IUpdateNoticeDialog {
  showDialog: boolean;
  toggleShowDialog: () => void;
  onAccept: () => void;
  onCancel: () => void;
  validateAccept?: () => boolean;
  hideCancelButton?: boolean;
}

const UpdateNoticeDialog = (props: IUpdateNoticeDialog) => {
  const [show, setShow] = React.useState<boolean>(false);

  return (
      <DialogTemplate
          showDialog={props.showDialog}
          toggleShowDialog={() => props.toggleShowDialog()}
          title={'Nueva versión disponible'}
          onAccept={() => props.toggleShowDialog()}
          onCancel={() => props.toggleShowDialog()}
          acceptText={'Actualizar'}
          cancelText={''}
          hideCancelButton={true}
          minWidth={600}
      >
        <div className="text-center">
          <Text variant="xLarge" styles={boldStyle} block={true} style={{marginTop: '40px'}}>
            Hay una nueva versión disponible de la aplicación
          </Text>
          <Text variant="xLarge" styles={boldStyle} block={true}>
            descargala en tu navegador ahora!
          </Text>

          <Icon iconName='News' className={'text-icon-for-dialog'}/>
        </div>
      </DialogTemplate>
  );
};

export default UpdateNoticeDialog;
