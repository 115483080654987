import React, { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { GRID_MODE, TYPES, OPERATORS, CARD_MODE } from '../../common/list-utils';
import MessageCourier from '../../lib/MessageCourier';
import TimeTaxSupportSystemClient from '../../system';
import { User } from '../../system/User';
import { Mail } from '../../system/mail/Mail';
import { ListColumnFilter } from '../Common/list-entities/ListColumnFilter';
import { MailDialog } from '../Mail/MailDialog';
import { ReportingMMIGridList } from './ReportingMMIGridList';
import ViewWrapperWithCommandBar from '../Layout/ViewWrapperWithCommandBar';
import { defaultCommandBarButtonStyles, titleCommandBarButtonStyles } from '../../styles/button-styles';
import { DefaultPalette } from 'office-ui-fabric-react';
import { ICommandBarItemProps } from '@fluentui/react/lib/CommandBar';
import useAsyncDataCourier from '../../common/custom-hooks/useAsyncDataCourier';
import { Company } from '../../system/company/Company';

interface IReportingMMI {
  system: TimeTaxSupportSystemClient;
  courier: MessageCourier;
  changeBlockUI: (state: boolean) => void;
  user: User;
}

export const ReportingMMI = (props: IReportingMMI) => {
  const { t, i18n } = useTranslation();
  const [mode, setMode] = useState(GRID_MODE);
  const baseUrl = React.useMemo(() => {
    return props.system.getMailSystem().getBaseUrlForDatagrid();
  }, [props]);

  const [mail, setMail] = React.useState<Mail | undefined>(undefined);
  const [showDialog, setShowDialog] = React.useState<boolean>(false);
  const onCloseDialog = () => {
    setShowDialog(false);
    setMail(undefined);
  };
  const [projectName, setProjectName] = React.useState<string | undefined>(undefined);
  const [companyProjectId, setCompanyProjectId] = React.useState<string | undefined>(undefined);

  const companies = useAsyncDataCourier(
      () => props.system.getCompanySystem().getList(),
      [props.system],
      props.courier
  );

  const filteredCompanies = (companies) => {
    return companies.map((com: Company) => {
      return { key: com.getName(), text: com.getName() };
    });
  };

  const itemsMenu = React.useMemo((): ICommandBarItemProps[] => {
    let ret: ICommandBarItemProps[] = [];
    ret.push({
      key: 'title',
      text: 'Correos (MMI)',
      iconProps: {
        iconName: 'Mail',
        color: DefaultPalette.themeLighterAlt,
        className: 'text-title-btn',
      },
      buttonStyles: titleCommandBarButtonStyles,
      className: 'text-title-btn',
    });
    return ret;
  }, []);

  const onMailClick = async (mail_id: string) => {
    props.changeBlockUI(true);
    try {
      const mail = await props.system.getMailSystem().getMailIdentifiedBy(mail_id);
      setMail(mail);
    } catch (error) {
      props.courier.messageError(error);
    }
    setShowDialog(true);
    props.changeBlockUI(false);
  };

  const quickFilters: ListColumnFilter[] = useMemo(
      () => [
        {
          quick: true,
          name: 'fecha_desde_for_filter',
          placeholder: t('Desde'),
          type: TYPES.DATE,
          operator: OPERATORS.AFTERORON,
        },
        {
          quick: true,
          name: 'fecha_hasta_for_filter',
          placeholder: t('Hasta'),
          type: TYPES.DATE,
          operator: OPERATORS.BEFOREORON,
        },
        {
          quick: true,
          name: 'mail_from',
          type: TYPES.INPUT,
          placeholder: t('Emisor (Correo)'),
          operator: OPERATORS.CONTAINS,
          example: 'emisor@dominio.com',
        },
        {
          quick: true,
          name: 'mail_to',
          type: TYPES.INPUT,
          placeholder: t('Receptor (Correo)'),
          operator: OPERATORS.CONTAINS,
          example: 'receptor@dominio.com',
        },
        {
          quick: true,
          name: 'mail_subject',
          type: TYPES.INPUT,
          placeholder: t('Asunto'),
          operator: OPERATORS.CONTAINS,
        },
        {
          quick: true,
          name: 'tiene_docs',
          type: TYPES.CHECKBOX,
          labelForCheckbox: t('Tiene documentos'),
          checkBoxValue: '1',
          operator: OPERATORS.EQUAL,
        },
        {
          quick: true,
          name: 'emp_nombre',
          type: TYPES.COMBOBOX,
          placeholder: t('Empresa'),
          operator: OPERATORS.EQUAL,
          contextForCombobox: companies ? filteredCompanies(companies) : [],
        },
        {
          quick: true,
          name: 'proyecto',
          type: TYPES.INPUT,
          placeholder: t('Proyecto'),
          operator: OPERATORS.CONTAINS,
          example: 'FA2023-152 / Calculo de pagos',
        },
        {
          quick: true,
          name: 'cantidad_ocurrencias',
          type: TYPES.CHECKBOX,
          labelForCheckbox: t('Asociado a mas de un proyecto'),
          checkBoxValue: '1',
          operator: OPERATORS.GREATERTHAN,
        },
      ],
      [companies]
  );
  return (
      <>
        <ViewWrapperWithCommandBar
            withBorderBottom
            title={''}
            items={itemsMenu}
            overflowButtonStyles={defaultCommandBarButtonStyles}
            variant="highligth"
            titleBody="Correos (MMI)"
        >
          <ReportingMMIGridList
              changeBlockUI={props.changeBlockUI}
              baseUrl={baseUrl}
              quickFilters={quickFilters}
              onItemClick={(e) => {
                setProjectName(e['proyecto']);
                setCompanyProjectId(e['mep_expid']);
                onMailClick(e['mail_id']);
              }}
              onClickCardMode={() => setMode(CARD_MODE)}
              onClickGridMode={() => setMode(GRID_MODE)}
              mode={mode}
              courier={props.courier}
              keyList={'MMI-report-mails'}
          ></ReportingMMIGridList>
        </ViewWrapperWithCommandBar>

        {mail && (
            <MailDialog
                system={props.system}
                showDialog={showDialog}
                onClose={onCloseDialog}
                mail={mail}
                nameProject={projectName}
                canUnassign
                companyProjectId={companyProjectId}
            ></MailDialog>
        )}
      </>
  );
};
