
import { DetailsList, DetailsRow, IColumn, IDetailsListProps, SelectionMode } from '@fluentui/react';

interface ICustomTable {
  items?: any[];
  columns?: IColumn[];
  onRowClick?: (item: any) => void;
}

function CustomTable({ items = [], columns, onRowClick }: ICustomTable) {

  const onRenderRow: IDetailsListProps['onRenderRow'] = (props) => {
    if (props) {
      return (
        <div
          onClick={() => {
            if (onRowClick) {
              onRowClick(props.item);
            }
          }}
        >
          <DetailsRow
            {...props}
            styles={{
              root: {
                backgroundColor: props.itemIndex % 2 === 0 ? '#f5f5f5' : 'transparent',
                selectors: {
                  ':hover': { backgroundColor: '#d9d8d8' },
                },
              },
            }}
          />
        </div>
      );
    }
    return null;
  };

  return (
    <DetailsList
      items={items}
      columns={columns}
      setKey="none"
      selectionMode={SelectionMode.none}
      layoutMode={0} 
      onRenderRow={onRenderRow}
    />
  );
}

export default CustomTable;
