import { EventInput } from '@fullcalendar/react';
import { newEntityDefaultId } from '../../common/constants';
import { Activity } from '../../system/activity/Activity';
import { DateCustom } from '../../lib/DateCustom';
import { ActivityJson } from '../../system/activity/ActivitySystem';
import { DateTime } from 'luxon';

interface ICalendarEventExtendedProps<T> {
  data: T;
  onDuplicate?: () => void;
  onReplicate?: () => void;
  onDelete?: () => void;
  onReactivar?: () => void;
  onEdit?: () => void;
}

export interface ICalendarEvent<T = any> extends Omit<EventInput, 'start' | 'end'> {
  extendedProps: ICalendarEventExtendedProps<T>;
  start: string | Date;
  end: string | Date;
}

export type TActividadEvent = ICalendarEvent<ActividadEventExtendedProps>;
export interface ActividadEventExtendedProps {
  id: string;
  checked: boolean;
  allChecked: boolean;
  documentsPending: number;
  eliminada: boolean;
  fromOutlook: boolean;
  idOutlook?: string;
  oculta?: boolean;
  aceptoTag: boolean;
  isNew: boolean;
  esOwner: boolean;
  canEdit: boolean;
  isNewFromOutlook: boolean;
  eventoOutlookEstaOculta: boolean;
  proyecto?: any;
  act_inicio: string | Date;
  act_fin: string | Date;
  act_replicada_de_actid?: string;
  colaboradorActividadList: any[];
  cantDocumentosCargados: number;
  isLicenseDay?: boolean;
  isNonWorkingDay?: boolean;
}

export class CalendarEvent {
  public static instanceEventFromActivity(actividad: Activity, colaboradorId: string): TActividadEvent {
    return this.instanceEventFromIActivity(actividad.toJson(), colaboradorId);
  }

  public static instanceEventFromIActivity(actividad: ActivityJson, colaboradorId: string): TActividadEvent {
    const checked =
        colaboradorId === actividad.act_owner_colid ||
        (actividad.colaboradorActividadList &&
            actividad.colaboradorActividadList.findIndex((elem) => {
              return elem.colact_colid === colaboradorId && elem.colact_checked;
            }) > -1);
    const allChecked =
        actividad.colaboradorActividadList &&
        actividad.colaboradorActividadList.every(
            (elem) => elem.colact_colid === actividad.act_owner_colid || elem.colact_fecha_tag
        );
    let documentsPending = 0;
    if (actividad.documentoXActividadList) {
      actividad.documentoXActividadList.forEach((doc: any) => {
        documentsPending =
            documentsPending +
            (doc.dxa_id &&
            ((doc.documento && doc.documento.doc_carga_colid && doc.documento.doc_acceso_url) ||
                !doc.dxa_activo)
                ? 0
                : 1);
      });
    }

    const i =
        actividad.colaboradorActividadList &&
        actividad.colaboradorActividadList.findIndex(
            (colAct) => +colAct.colact_colid === +colaboradorId && colAct.colact_taggeado && colAct.colact_checked
        );
    let aceptoTag = i && i > -1;
    let isNew = !actividad.act_id || actividad.act_id === newEntityDefaultId;
    let esOwner = actividad.act_owner_colid === colaboradorId;
    let canEdit = !isNew && actividad.act_activo && (esOwner || aceptoTag);
    let isNewFromOutlook = false;
    let eventoOutlookEstaOculta = false;
    let cantDocCargados = 0;
    if (actividad.documentoXActividadList) {
      cantDocCargados = actividad.documentoXActividadList.map(
          (dxa) => dxa.documento && dxa.documento.doc_id != null
      ).length;
    }

    const start = DateCustom.ParseDateFromApiStr(actividad.act_inicio);
    const hoursConfirmed = actividad.colaboradorActividadList?.find(
        (colact) => +colact.colact_colid === +colaboradorId
    )?.colact_horas;

    const _end = DateTime.fromJSDate(start).plus({ hours: hoursConfirmed });

    return {
      title: actividad.act_nombre ? actividad.act_nombre : '',
      start: DateCustom.ParseDateFromApiStr(actividad.act_inicio),
      end: DateCustom.ParseDateFromApiStr(actividad.act_fin),
      allDay: false,
      backgroundColor: '#fff', //Info (aqua)
      borderColor: '#fff', //Info (aqua)
      extendedProps: {
        data: {
          id: actividad.act_id,
          checked: checked ? checked : false,
          allChecked: allChecked ? allChecked : false,
          documentsPending,
          eliminada: !actividad.act_activo,
          fromOutlook: false,
          idOutlook: actividad.act_refer_outlook ? actividad.act_refer_outlook : undefined,
          aceptoTag: aceptoTag ? aceptoTag : false,
          isNew: isNew,
          esOwner: esOwner,
          canEdit: canEdit ? canEdit : false,
          isNewFromOutlook: isNewFromOutlook,
          eventoOutlookEstaOculta: eventoOutlookEstaOculta,
          proyecto: actividad.proyecto ? actividad.proyecto : undefined,
          act_inicio: actividad.act_inicio,
          act_fin: actividad.act_fin,
          act_replicada_de_actid: actividad.act_replicada_de_actid,
          cantDocumentosCargados: cantDocCargados,
          colaboradorActividadList: actividad.colaboradorActividadList
              ? actividad.colaboradorActividadList
              : [],
        },
      },
    };
  }
}
