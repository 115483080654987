import { DateTime } from 'luxon';

class SystemConfiguration {
  private readonly base = '/configuraciones';
  private constructor(private activitiesBlockedBefore: DateTime | null) {}

  static with(activitiesBlockedBefore: DateTime | null) {
    return new this(activitiesBlockedBefore);
  }

  static default() {
    return this.with(null);
  }

  canUploadActivitiesOn(aDate: Date): boolean {
    return (
        this.activitiesBlockedBefore === null || DateTime.fromJSDate(aDate) >= this.activitiesBlockedBefore
    );
  }

  getActivitiesBlocked(): DateTime | null {
    return this.activitiesBlockedBefore;
  }
}

export default SystemConfiguration;
