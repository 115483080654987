import * as React from 'react';
import { Icon, Text } from '@fluentui/react';
import { DefaultPalette } from '@fluentui/react/lib/Styling';

import ProjectMiniPanelChart from './ProjectMiniPanelChart';
import { IProjectSummary } from '../../../system/projects/ProjectsSystem';
import ProjectMiniDocumentSummary from './ProjectMiniDocumentSummary';
import ProjectMiniActivitySummary from './ProjectMiniActivitySummary';
import { useNavigate, useParams } from 'react-router-dom';

import BasicChartPad from '../../Common/BasicChartPad';
import { PillFluent } from '../../Common/PillFluent';
import { DISABLE_MMI_OPTION, routes, SHOW_MMI_OPTION } from '../../../common/constants';
import { KEY_VIEW_CONTROL_DOCS_PROJECTS } from '../ProjectView';
import { TooltipInfoMiniPanel } from './TooltipInfoMiniPanel';
import i18next, { t } from 'i18next';

interface IProjectMiniPanelProps {
  summary: IProjectSummary;
  setViewControlDocsWrapper?: () => void;
}

export const ProjectMiniPanel = (props: IProjectMiniPanelProps) => {
  let { id } = useParams();
  const navigate = useNavigate();

  const documents = props.summary.documents;
  const activities = props.summary.activities;
  const collaborators = props.summary.collaborators;
  const emails = props.summary.emailsMMI;

  const goToControlDocs = () => {
    if (props.setViewControlDocsWrapper) {
      props.setViewControlDocsWrapper();
    } else {
      navigate(`${routes.project}/${id}?view=${KEY_VIEW_CONTROL_DOCS_PROJECTS}`);
    }
  };

  return (
      <div className="ms-Grid" dir="ltr">
        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-md12 ms-lg12 ms-xl6 ms-xxl6  text-center ">
            <div className="ms-Grid-row text-center">
              <Text nowrap className="last-days" block title={'últimos 28 días'}>
                {t('Últimos 28 días')}
              </Text>
            </div>

            <div className="ms-Grid-row padding-for-container">
              <div
                  className={
                    !SHOW_MMI_OPTION
                        ? 'ms-Grid-col ms-sm12 ms-md6 ms-xl6 ms-xxl6 text-center project-mini-panel-chart-container'
                        : 'ms-Grid-col ms-sm12 ms-md4 ms-xl4 ms-xxl4 text-center project-mini-panel-chart-container'
                  }
              >
                <ProjectMiniPanelChart data={props.summary.activitiesByWeek} title={t('Actividades')} />
              </div>
              <div
                  className={
                    !SHOW_MMI_OPTION
                        ? 'ms-Grid-col ms-sm12 ms-md6 ms-xl6 ms-xxl6 text-center project-mini-panel-chart-container'
                        : 'ms-Grid-col ms-sm12 ms-md4 ms-xl4 ms-xxl4 text-center project-mini-panel-chart-container'
                  }
              >
                <ProjectMiniPanelChart
                    data={props.summary.documentsByWeek}
                    title={t('Documentos')}
                    color={DefaultPalette.blueLight}
                />
              </div>
              {SHOW_MMI_OPTION && (
                  <div className="ms-Grid-col ms-sm12 ms-md4 ms-xl4 ms-xxl4 text-center px-0 mt-2px project-mini-panel-chart-container">
                    <ProjectMiniPanelChart data={props.summary.emailsMMIByWeek} title={t('Emails')} />
                  </div>
              )}
            </div>

            <div className="text-center mt-1">
              {collaborators.active > 1 ? (
                  <Text variant="medium" className="fw-500">
                    <Icon iconName="ReminderPerson" className="text-icon"></Icon>
                    {collaborators.active} {t('colaboradores activos de un equipo de')} {collaborators.total}
                  </Text>
              ) : (
                  <Text variant="medium" className="fw-500">
                    <Icon iconName="ReminderPerson" className="text-icon"></Icon>
                    {collaborators.active} {t('colaborador activo de un equipo de')} {collaborators.total}
                  </Text>
              )}
            </div>
          </div>

          <div className="ms-Grid-col ms-md12 ms-lg12 ms-xl6 ms-xxl6 text-center mt-2-in-down-xl   ">
            <div className="ms-Grid-row text-center">
              <Text nowrap className="last-days d-inline" block title={'últimos 28 días'}>
                {t('En todo el proyecto')}
              </Text>
              <TooltipInfoMiniPanel></TooltipInfoMiniPanel>
            </div>

            <div
                className={
                  !SHOW_MMI_OPTION
                      ? 'ms-Grid-col ms-md12 ms-md12 ms-lg6 ms-xl6 ms-xxl6 mt-2-in-down-xl mt-1 '
                      : 'ms-Grid-col ms-md12 ms-md12 ms-lg4 ms-xl4 ms-xxl4 mt-2-in-down-xl mt-1 '
                }
            >
              <PillFluent
                  title={t('Actividades')}
                  iconTitle={'AccountActivity'}
                  series={[
                    { label: t('Total'), value: activities.qty, icon: 'CheckMark' },
                    { label: t('Horas'), value: activities.hours, icon: 'Clock' },
                  ]}
                  small={true}
              ></PillFluent>
            </div>

            <div
                className={
                  !SHOW_MMI_OPTION
                      ? 'ms-Grid-col ms-md12 ms-md12 ms-lg6 ms-xl6 ms-xxl6 mt-2-in-down-xl mt-1 '
                      : 'ms-Grid-col ms-md12 ms-md12 ms-lg4 ms-xl4 ms-xxl4 mt-2-in-down-xl mt-1 '
                }
            >
              <PillFluent
                  title={t('Docs. obligatorios')}
                  iconTitle={'ProtectedDocument'}
                  series={[
                    { label: t('Cargados'), value: documents.required.uploaded, icon: 'CheckMark' },
                    { label: t('Pendientes'), value: documents.required.total, icon: 'Clock' },
                  ]}
                  small={true}
              ></PillFluent>
            </div>

            {SHOW_MMI_OPTION && (
                <div className="ms-Grid-col ms-md12 ms-md12 ms-lg4 ms-xl4 ms-xxl4 text-center mt-2-in-down-xl mt-1  ">
                  <div>
                    <PillFluent
                        title={t('Emails')}
                        iconTitle={'Mail'}
                        series={[{ label: t('Total'), value: emails.qty, icon: 'CheckMark' }]}
                        small={true}
                        minHeight={85}
                    ></PillFluent>
                  </div>
                </div>
            )}

            <div className="ms-Grid-col ms-sm12 mt-2-in-down-xl mt-1">
              <div className="text-center mt-1">
                {documents.required.expired > 0 && (
                    <Text
                        variant="medium"
                        className="fw-500 error-color link-styles"
                        onClick={() => goToControlDocs()}
                    >
                      <Icon iconName="AlertSolid" className="text-icon"></Icon>
                      {t('Tienes documentos obligatorios vencidos ')}({documents.required.expired})
                    </Text>
                )}

                {documents.required.total === documents.required.uploaded && (
                    <Text variant="medium" className="fw-500">
                      <Icon iconName="CheckMark" className="text-icon success-color"></Icon>
                      {t('Estas al día')}
                    </Text>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
  );
};
