import LazyInitialization from '../../lib/LazyInitialization';
import { CollaboratorPosition } from './CollaboratorPosition';
import { ProvidedCollaboratorPosition } from './ProvidedCollaboratorPosition';
import { CollaboratorSystem, ICollaborator, ICollaboratorPosition } from './CollaboratorSystem';
import { OrganizationChartNode } from '../organization_chart/OrganizationChartNode';

export class Collaborator {
  protected constructor(
      private id: string,
      private name: string,
      private lastName: string,
      private email: string,
      private birthDay: string,
      private initials: string,
      private cargoColaborador: ICollaboratorPosition | null,
      private position: LazyInitialization<CollaboratorPosition>,
      private active: boolean
  ) {}

  static named(
      id: string,
      name: string,
      lastName: string,
      email: string,
      birthDay: string,
      initials: string,
      cargoColaborador: ICollaboratorPosition | null,
      position: CollaboratorPosition,
      active: boolean
  ) {
    return new this(
        id,
        name,
        lastName,
        email,
        birthDay,
        initials,
        cargoColaborador,
        new LazyInitialization(() => Promise.resolve(position)),
        active
    );
  }

  static fromICollaborator(
      system: CollaboratorSystem,
      collaborator: ICollaborator,
      nodes: OrganizationChartNode[] = []
  ) {
    const position = new LazyInitialization<CollaboratorPosition>(() => {
      if (!collaborator.cargoColaborador) {
        return system.getCollaboratorPositionIdentifiedBy(collaborator.col_id);
      }
      return Promise.resolve(
          ProvidedCollaboratorPosition.fromICollaboratorPosition(system, collaborator.cargoColaborador, nodes)
      );
    });

    return new this(
        collaborator.col_id,
        collaborator.col_nombre,
        collaborator.col_apellido,
        collaborator.col_email,
        collaborator.col_fecha_nacimiento,
        collaborator.col_iniciales,
        collaborator.cargoColaborador,
        position,
        collaborator.col_activo
    );
  }

  toICollaborator(): ICollaborator {
    return {
      cargoColaborador: this.getCargoColaborador(),
      col_id: this.getId(),
      col_nombre: this.getName(),
      col_apellido: this.getLastName(),
      col_email: this.getEmail(),
      col_fecha_nacimiento: this.getBirthday(),
      // col_documento_numero: this.getDocument(),
      // col_telefono_movil: this.getPhone(),
      col_imagen_url: null,
      col_iniciales: this.getInitials(),
      col_activo: this.getActive(),
      col_id_sap: '',
      // col_carcolid: this.getCargoColaborador().carcol_cargoid, //CAMBIAR ESTO
    };
  }

  isIdentifiedBy(anId: string): boolean {
    return this.id === anId;
  }

  isIdentifiedAs(aCollaborator: Collaborator): boolean {
    return aCollaborator.isIdentifiedBy(this.id);
  }

  getId(): string {
    return this.id;
  }
  getName(): string {
    return this.name;
  }

  setName(name: string): void {
    this.name = name;
  }

  getLastName(): string {
    return this.lastName;
  }

  setLastName(lastName: string): void {
    this.lastName = lastName;
  }

  getEmail(): string {
    return this.email;
  }

  getCargoColaborador(): ICollaboratorPosition | null {
    return this.cargoColaborador;
  }

  // getPosition(): ICompanyPosition {

  // }

  getBirthday(): string {
    return this.birthDay;
  }

  setBirthday(birthday: string): void {
    this.birthDay = birthday;
  }

  // getDocument():string|null{
  //   return this.document;
  // }

  // getPhone():string{
  //   return this.phone;
  // }

  getInitials(): string {
    return this.initials;
  }

  fullName(): string {
    return `${this.name} ${this.lastName}`;
  }

  fullNameLike(val: string | undefined): boolean {
    return !val || this.fullName().toLowerCase().includes(val.toLowerCase());
  }

  getActive(): boolean {
    return this.active;
  }

  setActive(active: boolean): void {
    this.active = active;
  }

  async nodeIs(node: OrganizationChartNode): Promise<boolean> {
    const pos = await this.getPosition();
    return pos.nodeIs(node);
  }

  getPositionForContext(): string | undefined {
    if (this.cargoColaborador?.cargo) return this.cargoColaborador.cargo.cargo_nombre;
  }

  async getPositionName(): Promise<string> {
    const position = await this.getPosition();

    return position.getPositionName();
  }

  getCompanyPositionName(): string | undefined {
    if (this.cargoColaborador?.cargo) return this.cargoColaborador.cargo.cargo_nombre;
  }

  getPosition(): Promise<CollaboratorPosition> {
    return this.position.value();
  }
}
