import {
  DefaultButton,
  ITextFieldStyleProps,
  ITextFieldStyles,
  Icon,
  PrimaryButton,
  Text,
  TextField,
} from '@fluentui/react';
import React from 'react';
import DialogTemplate from './Common/DialogTemplate';
import { useTranslation, Trans } from 'react-i18next';
import QRCode from 'react-qr-code';

export interface ITOTPHelp {
  QRValue: string;
  finishHelp: () => void;
}

const PASO_INSTALACION = 1;
const PASO_AGREGAR_AUTH = 2;

const ANDROID = 'Android';
const IOS = 'iOS';
export const TOTPHelp = (props: ITOTPHelp) => {
  const { t, i18n } = useTranslation();
  const [step, setStep] = React.useState<number>(PASO_INSTALACION);
  const [forSO, setForSO] = React.useState<string | undefined>(undefined);

  return (
      <div>
        <div>
          <div className="ms-Grid" dir="ltr">
            {step === PASO_INSTALACION && (
                <>
                  <div className="ms-Grid-row mb-2">
                    <div className="ms-Grid-col ms-sm12 text-center">
                      <Text variant="smallPlus">
                        Instala Google Authenticator {forSO ? 'para tu dispositivo ' + forSO : ''}
                      </Text>
                    </div>
                  </div>

                  <div className="ms-Grid-row">
                    {!forSO && (
                        <div className="ms-Grid-col ms-sm6 text-center clickable" onClick={() => setForSO(ANDROID)}>
                          <Text variant="smallPlus" className="d-block link-styles">
                            Click aqui para ver el QR
                          </Text>
                          <img className="android-logo" src="images/so-logos/android-logo.png" alt="Android"></img>
                        </div>
                    )}
                    {!forSO && (
                        <div className="ms-Grid-col ms-sm6 text-center clickable" onClick={() => setForSO(IOS)}>
                          <Text variant="smallPlus" className="d-block  link-styles">
                            Click aqui para ver el QR
                          </Text>
                          <img className="ios-logo mt-1" src="images/so-logos/ios-logo.png" alt="iOS"></img>
                        </div>
                    )}

                    <div className="ms-Grid-row">
                      <div className="ms-Grid-col ms-sm12 text-center">
                        {forSO === ANDROID && (
                            <img
                                className="qr-size"
                                src="https://chart.googleapis.com/chart?cht=qr&chl=https%3A%2F%2Fplay.google.com%2Fstore%2Fapps%2Fdetails%3Fid%3Dcom.google.android.apps.authenticator2&chs=180x180&choe=UTF-8&chld=L|2"
                                alt="qr code"
                            ></img>
                        )}
                        {forSO === IOS && (
                            <img
                                className="qr-size"
                                src="https://chart.googleapis.com/chart?cht=qr&chl=https%3A%2F%2Fapps.apple.com%2Fes%2Fapp%2Fgoogle-authenticator%2Fid388497605&chs=180x180&choe=UTF-8&chld=L|2"
                                alt="qr code"
                            ></img>
                        )}
                      </div>
                      {forSO !== undefined && (
                          <Text
                              variant="smallPlus"
                              className="d-block text-center link-styles"
                              onClick={() => {
                                setForSO(undefined);
                              }}
                          >
                            Para otro sistema operativo
                          </Text>
                      )}
                    </div>
                  </div>
                </>
            )}

            {step === PASO_AGREGAR_AUTH && (
                <>
                  <div className="ms-Grid-row mt-1 mb-1">
                    <Text variant="smallPlus" className="text-center d-block">
                      Una vez instalado ingresa en el autenticador, haz click en agregar y{' '}
                    </Text>
                    <Text variant="smallPlus" className="text-center d-block">
                      escanea el siguiente codigo QR.
                    </Text>
                  </div>
                  <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 text-center mb-1">
                      <QRCode size={100} value={props.QRValue} />
                    </div>
                  </div>
                </>
            )}
            <div className="ms-Grid-row mt-2">
              <div className="ms-Grid-col ms-sm12">
                <PrimaryButton
                    className="float-right ml-2"
                    onClick={() => {
                      if (step === PASO_AGREGAR_AUTH) props.finishHelp();
                      else setStep(step + 1);
                    }}
                    text={step === PASO_AGREGAR_AUTH ? 'Finalizar' : 'Siguiente'}
                />

                <DefaultButton
                    className="float-right "
                    onClick={() => {
                      if (step !== PASO_INSTALACION) setStep(step - 1);
                    }}
                    disabled={step === PASO_INSTALACION}
                    text={'Anterior'}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
  );
};
