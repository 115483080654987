import * as React from 'react';
import { Link } from '@fluentui/react/lib/Link';
import {
  DetailsHeader,
  DetailsList,
  IGroup,
  IGroupDividerProps,
  IDetailsListProps,
  IDetailsGroupRenderProps,
  CheckboxVisibility,
  DetailsRow,
} from '@fluentui/react/lib/DetailsList';
import { getTheme, mergeStyleSets } from '@fluentui/react/lib/Styling';
import { Dropdown } from '@fluentui/react/lib/Dropdown';
import { DefaultButton } from 'office-ui-fabric-react';
import i18next, { t } from 'i18next';

export interface ColumnGroupedDetailsList {
  key: string;
  name: string;
  fieldName: string;
  minWidth: number;
  maxWidth?: number;
  couldTotal?: boolean | null;
  couldGroup?: boolean | null;
  formatTotal?: (total: number) => JSX.Element;
  onRender?: (item: any) => JSX.Element;
  onRenderHeader?: (item: any) => JSX.Element;
  className?: string;
}

const ROW_HEIGHT: number = 42; // from DEFAULT_ROW_HEIGHTS in DetailsRow.styles.ts
const GROUP_HEADER_AND_FOOTER_SPACING: number = 8;
const GROUP_HEADER_AND_FOOTER_BORDER_WIDTH: number = 1;
const GROUP_HEADER_HEIGHT: number = 95;
const GROUP_FOOTER_HEIGHT: number =
    GROUP_HEADER_AND_FOOTER_SPACING * 4 + GROUP_HEADER_AND_FOOTER_BORDER_WIDTH * 2;

const theme = getTheme();
const classNames = mergeStyleSets(
    {
      header: {
        borderTop: `${GROUP_HEADER_AND_FOOTER_BORDER_WIDTH}px solid ${theme.palette.neutralQuaternary}`,
        borderBottom: `${GROUP_HEADER_AND_FOOTER_BORDER_WIDTH}px solid ${theme.palette.neutralQuaternary}`,
        padding: GROUP_HEADER_AND_FOOTER_SPACING,
        margin: `${GROUP_HEADER_AND_FOOTER_SPACING}px 0`,
        background: theme.palette.neutralLighterAlt,
        // Overlay the sizer bars
        position: 'relative',
        zIndex: 100,
      },
      headerTitle: [
        theme.fonts.xLarge,
        {
          padding: '4px 0',
        },
      ],
      headerLinkSet: {
        margin: '4px -8px',
      },
      headerLink: {
        margin: '0 8px',
      },
    },
    {
      Footer: {
        borderTop: `${GROUP_HEADER_AND_FOOTER_BORDER_WIDTH}px solid ${theme.palette.neutralQuaternary}`,
        borderBottom: `${GROUP_HEADER_AND_FOOTER_BORDER_WIDTH}px solid ${theme.palette.neutralQuaternary}`,
        padding: GROUP_HEADER_AND_FOOTER_SPACING,
        margin: `${GROUP_HEADER_AND_FOOTER_SPACING}px 0`,
        background: theme.palette.neutralLighterAlt,
        textAlign: 'right',
        // Overlay the sizer bars
        position: 'relative',
        zIndex: 100,
      },
      headerTitle: [
        theme.fonts.xLarge,
        {
          padding: '4px 0',
        },
      ],
      headerLinkSet: {
        margin: '4px -8px',
      },
      headerLink: {
        margin: '0 8px',
      },
    }
);

interface IGroupedDetailListProps {
  items: any[];
  columns: ColumnGroupedDetailsList[];
  hideGroupBy?: boolean
}

export const GroupedDetailList = (props: IGroupedDetailListProps) => {
  const [items, setItems] = React.useState<any[]>(props.items);
  const [columns, setColumns] = React.useState<any[]>(props.columns);
  const [groups, setGroups] = React.useState<IGroup[]>();
  const [groupByField, setGroupByField] = React.useState<string>('');

  React.useEffect(() => {
    setItems(props.items);
    setColumns(props.columns);
  }, [props]);

  const _onRenderDetailsHeader: IDetailsListProps['onRenderDetailsHeader'] = (props) => {
    if (props) {
      return <DetailsHeader {...props} ariaLabelForToggleAllGroupsButton={'Toggle selection'} />;
    }
    return null;
  };

  const _onRenderGroupHeader: IDetailsGroupRenderProps['onRenderHeader'] = (props) => {
    if (props) {
      return (
          <div className={classNames.header}>
            <div className={classNames.headerTitle}>{`${props.group!.name}`}</div>
            <div className={classNames.headerLinkSet}>
              <Link className={classNames.headerLink} onClick={_onToggleCollapse(props)}>
                {props.group!.isCollapsed ? t('Expandir grupo') : t('Ocultar grupo')}
              </Link>
            </div>
          </div>
      );
    }

    return null;
  };

  const _onRenderRow: IDetailsListProps['onRenderRow'] = (props) => {
    if (props) {
      return <DetailsRow className="bg-footer-row-grouped" {...props} />;
    }
    return null;
  };

  const _onRenderGroupFooter: IDetailsGroupRenderProps['onRenderFooter'] = (propsGroupFooter) => {
    if (propsGroupFooter && props) {
      let _columns = [...props.columns];
      _columns.unshift({
        key: 'arrowKey',
        name: '',
        fieldName: '',
        minWidth: 17,
        maxWidth: 17,
      });
      let _conceptsToTotal = _columns.filter((item) => item.couldTotal).map((item) => item.fieldName);

      let _item: any = {};

      _columns.forEach((column, i) => {
        _item[column.fieldName] = '';
        if (i === 1) {
          _item[column.fieldName] = t('Totales');
        }

        let totalExist = _conceptsToTotal.find((item) => item === column.fieldName);
        if (totalExist) {
          _item[column.fieldName] = propsGroupFooter.group?.data[totalExist];
        }

        let totalConcept = _conceptsToTotal.find((item) => item === column.fieldName);

        if (totalConcept) {
          _item[totalConcept] = propsGroupFooter.group?.data[totalConcept];

          _item[totalConcept] = column.formatTotal
              ? column.formatTotal(_item[totalConcept])
              : _item[totalConcept];
        }
      });

      _columns.forEach((col) => (col.className = 'fw-500'));

      return (
          <DetailsList
              items={[_item]}
              columns={_columns}
              onRenderDetailsHeader={() => null}
              ariaLabelForSelectionColumn="Toggle selection"
              ariaLabelForSelectAllCheckbox="Toggle selection for all items"
              checkButtonAriaLabel="select row"
              checkboxVisibility={CheckboxVisibility.hidden}
              onRenderRow={_onRenderRow}
              onShouldVirtualize={() => false}
          />
      );
    }

    return null;
  };

  const _getGroupTotalRowHeight = (group: IGroup): number => {
    return group.isCollapsed ? 0 : ROW_HEIGHT * group.count;
  };

  const _getGroupHeight = (group: IGroup, _groupIndex: number): number => {
    return GROUP_HEADER_HEIGHT + GROUP_FOOTER_HEIGHT + _getGroupTotalRowHeight(group);
  };

  const _onToggleCollapse = (props: IGroupDividerProps) => {
    return () => {
      props!.onToggleCollapse!(props!.group!);
    };
  };

  const dropdownGroupByOptions = React.useMemo(() => {
    let ret: any[] = [];
    if (props.columns) {
      ret = props.columns
          .filter((column) => column.couldGroup)
          .map((column) => {
            return {
              key: column.key,
              text: column.name,
            };
          });
    }
    return ret;
  }, [props.columns]);

  React.useEffect(() => {
    let _items = [...props.items];
    let _columns = [...props.columns];
    if (groupByField) {
      _items.sort((a, b) => a[groupByField].localeCompare(b[groupByField]));

      let itemsRev = _items.slice().reverse();

      // itemsRev.sort((a, b) => b[groupByField].localeCompare(a[groupByField]));

      let groupByConcepts = _items.map((item) => item[groupByField]);
      let groupByConceptsUniques = [...new Set(groupByConcepts)];

      let _conceptsToTotal = _columns.filter((item) => item.couldTotal).map((item) => item.fieldName);

      let _groups: IGroup[] = [];
      if (groupByConceptsUniques) {
        groupByConceptsUniques.forEach((groupConcept, i) => {
          const _startIndex = _items.findIndex((item) => item[groupByField] === groupConcept);
          let lastIndex = 0;
          _items.forEach((item, i) => {
            if (item[groupByField] === groupConcept) lastIndex = i;
          });

          const _count = lastIndex - _startIndex + 1;
          // const _count = _items.length - itemsRev.findIndex((item) => item[groupByField] === groupConcept);

          const dataForGroup = {};

          if (_conceptsToTotal.length > 0) {
            _items.forEach((item) => {
              if (item[groupByField] === groupConcept) {
                _conceptsToTotal.forEach((conceptToTotal) => {
                  if (!dataForGroup[conceptToTotal]) {
                    dataForGroup[conceptToTotal] = 0;
                  }

                  dataForGroup[conceptToTotal] += item[conceptToTotal];
                });
              }
            });
          }

          _groups.push({
            key: 'grouped' + i,
            name: groupConcept,
            startIndex: _startIndex,
            count: _count,
            data: dataForGroup,
          });
        });
      }

      setGroups(_groups);
    }
    setItems(_items);
    setColumns(_columns);
  }, [groupByField, props.columns, props.items]);

  React.useEffect(() => {
    if (!groupByField && props.columns && props.items) {
      let _columns = [...props.columns];
      let _items = [...props.items];

      let _conceptsToTotal = _columns.filter((item) => item.couldTotal).map((item) => item.fieldName);

      let _item: any = {};

      _columns.forEach((column, i) => {
        _item[column.fieldName] = '';
        if (i === 0) {
          _item[column.fieldName] = t('Totales');
        }

        let totalConcept = _conceptsToTotal.find((item) => item === column.fieldName);
        if (totalConcept) {
          _item[totalConcept] = 0;
          _items.forEach((it) => {
            _item[totalConcept!] += Number(it[totalConcept!]);
          });

          _item[totalConcept] = column.formatTotal
              ? column.formatTotal(_item[totalConcept])
              : _item[totalConcept];
        }
      });
      _items.push(_item);
      setItems(_items);
    }
  }, [groupByField, props.columns, props.items]);

  return (
      <>
        <div className="ms-Grid" dir="ltr">
          {!props.hideGroupBy &&
              <div className="ms-Grid-row">
                <div className="ms-Grid-col ms-sm4">
                  <Dropdown
                      label={t('Agrupar por')}
                      selectedKey={groupByField}
                      onChange={(e, o) => (o && o.key ? setGroupByField(String(o.key)) : null)}
                      options={dropdownGroupByOptions}
                  />
                </div>
                <div className="ms-Grid-col ms-sm2">
                  {groupByField && (
                      <DefaultButton
                          styles={{ root: { marginTop: 29 } }}
                          onClick={() => {
                            setItems(props.items);
                            setGroups([]);
                            setColumns(props.columns);
                            setGroupByField('');
                          }}
                      >
                        {t('Eliminar agrupamiento')}
                      </DefaultButton>
                  )}
                </div>
              </div>
          }
          <div className="ms-Grid-row mt-2">
            <div className="ms-Grid-col ms-sm12">
              <div className="full-height" data-is-scrollable="true">
                <DetailsList
                    items={items}
                    columns={columns}
                    groups={groups}
                    groupProps={{
                      onRenderHeader: _onRenderGroupHeader,
                      onRenderFooter: _onRenderGroupFooter,
                    }}
                    getGroupHeight={_getGroupHeight}
                    ariaLabelForSelectionColumn="Toggle selection"
                    ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                    checkButtonAriaLabel="select row"
                    onRenderDetailsHeader={_onRenderDetailsHeader}
                    checkboxVisibility={CheckboxVisibility.hidden}
                    onShouldVirtualize={() => false}
                    // styles={{
                    //   root: {
                    //     maxHeight: 600,
                    //   },
                    // }}
                />
              </div>
            </div>
          </div>
        </div>
      </>
  );
};
