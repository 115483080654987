import React from 'react';
import { Text, ITextProps } from '@fluentui/react/lib/Text';
import { TextField, MaskedTextField, ITextFieldStyles } from '@fluentui/react/lib/TextField';
import { DefaultButton, PrimaryButton } from '@fluentui/react/lib/Button';
import { Checkbox, ICheckboxStyles, MessageBar, MessageBarType } from '@fluentui/react';
import { Link } from '@fluentui/react';
import TimeTaxSupportSystemClient from '../../system';
import MessageCourier from '../../lib/MessageCourier';
import i18next, { t } from 'i18next';
import { APP_NAME, LOGO_PATH } from '../../common/constants';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

interface IRecoverPasswordView {
  system: TimeTaxSupportSystemClient;
  courier: MessageCourier;
  tokenGetter: () => Promise<string>;
}
class RecoverPasswordView extends React.Component<IRecoverPasswordView, any> {
  constructor(props: IRecoverPasswordView) {
    super(props);
    this.state = {
      email: null,
      formErrors: { email: '', password: '', repeat_password: '' },
      emailValid: false,
      passwordValid: false,
      repeatPasswordValid: false,
      step: 1,
      showPwd1: false,
      showPwd2: false,
      codigo_recuperacion: '',
      isLoading: false,
    };
  }

  handleUserInput = (e: any) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({ [name]: value });
  };

  validateField = (e: any) => {
    const fieldName = e.target.name;
    const value = e.target.value;
    let fieldValidationErrors = this.state.formErrors;
    let emailValid = this.state.emailValid;
    let passwordValid = this.state.passwordValid;
    let repeatPasswordValid = this.state.repeatPasswordValid;

    switch (fieldName) {
      case 'email':
        emailValid = value.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/);
        fieldValidationErrors.email = emailValid ? '' : t('Email es inválido');
        break;
      case 'password':
        passwordValid = value.length >= 6;
        fieldValidationErrors.password = passwordValid ? '' : t('Contraseña es demasiado corta');
        break;
      case 'repeat_password':
        repeatPasswordValid = value === this.state.password;
        fieldValidationErrors.repeat_password = repeatPasswordValid ? '' : t('Contraseñas deben coincidir');
        break;
      default:
        break;
    }
    this.setState({
      formErrors: fieldValidationErrors,
      emailValid: emailValid,
      passwordValid: passwordValid,
      repeatPasswordValid: repeatPasswordValid,
    });
  };

  initLoading() {
    this.setState({
      isLoading: true,
    });
  }

  stopLoading() {
    this.setState({
      isLoading: false,
    });
  }

  sendRecoveryEmail = async () => {
    if (this.state.emailValid) {
      this.initLoading();
      try {
        const token = await this.props.tokenGetter();
        if (this.state.email && token) {
          await this.props.system.getRecoverPasswordSystem().sendEmail(this.state.email, token);
          this.showNextStep();
        }
      } catch (error) {
        this.props.courier.messageError(error);
      }
      this.stopLoading();
    }
  };

  submitRecoveryCode = async () => {
    if (this.state.codigo_recuperacion !== '' && this.state.codigo_recuperacion.length === 5) {
      this.initLoading();

      try {
        await this.props.system.getRecoverPasswordSystem().validateCode(this.state.codigo_recuperacion);
        this.showNextStep();
      } catch (error) {
        this.props.courier.messageError(error);
      }

      this.stopLoading();
    }
  };

  changePassword = async () => {
    if (this.state.passwordValid && this.state.repeatPasswordValid) {
      this.initLoading();

      try {
        if (this.state.password) {
          await this.props.system.getRecoverPasswordSystem().changePassword(this.state.password);
          this.showNextStep();
        }
      } catch (error) {
        this.props.courier.messageError(error);
      }
      this.stopLoading();
    }
  };

  componentDidMount(): void {
    if (this.props.courier) {
      this.props.courier.subscribe(this);
    }
  }

  receiveError(message: string): void {
    this.setState({ message, messageType: MessageBarType.error });
    setTimeout(() => {
      this.onDismissMessage();
    }, 30000);
  }

  receiveSuccess(message: string): void {
    this.setState({ message, messageType: MessageBarType.success });
    setTimeout(() => {
      this.onDismissMessage();
    }, 30000);
  }

  private onDismissMessage = () => {
    this.setState({ message: undefined });
  };

  showPreviousStep = () => {
    if (this.state.step >= 2 && this.state.step <= 4) {
      let currentStep = this.state.step;
      currentStep -= 1;
      this.setState({ step: currentStep });
    }
  };

  showNextStep = () => {
    if (this.state.step >= 1 && this.state.step <= 3) {
      let currentStep = this.state.step;
      currentStep += 1;
      this.setState({ step: currentStep });
    }
  };

  render() {
    const inputFieldStyles: Partial<ITextFieldStyles> = {
      // fieldGroup: {
      //   display: 'block',
      //   width: '100%',
      //   height: '35px',
      //   padding: '6px 16px',
      // },
      icon: {
        float: 'left',
      },
    };

    const { message, messageType } = this.state;
    return (
        <div className="ms-Grid page-body form-back" style={{ padding: '0px 20px' }} dir="ltr">
          <div className="ms-Grid-row">
            <div
                className="ms-Grid-col ms-xl3 ms-lg6 ms-sm12"
                style={{ margin: '0 auto', textAlign: 'center', float: 'none' }}
            >
              <div
                  className="login-container panel"
                  style={{
                    width: '320px',
                    height: '100%',
                    marginRight: 'auto',
                    marginLeft: 'auto',
                  }}
              >
                <div className="panel-heading" style={{ width: '100%', padding: '20px 0' }}>
                  <img src={LOGO_PATH} alt="logo" className="tts-logo"></img>
                </div>
                <section className="section-container">
                  {message && (
                      <MessageBar
                          messageBarType={messageType}
                          isMultiline={false}
                          onDismiss={this.onDismissMessage}
                          dismissButtonAriaLabel="Close"
                      >
                        {message}
                      </MessageBar>
                  )}
                </section>

                <div className="wrapper-login">
                  {this.state.step === 1 ? (
                      <>
                        <Text
                            variant="mediumPlus"
                            nowrap
                            block
                            style={{
                              textAlign: 'center',
                              marginBottom: '15px',
                              marginTop: '15px',
                            }}
                        >
                          {t('¿Olvidaste tu contraseña?')}
                        </Text>
                        <Text
                            variant="medium"
                            block
                            style={{
                              textAlign: 'center',
                              marginBottom: '15px',
                            }}
                        >
                          {t(
                              'Ingresa tu correo electrónico y te enviaremos un código para que puedas reestablecer tu contraseña'
                          )}
                        </Text>
                        <div style={{ marginTop: '10px' }}>
                          <TextField
                              styles={inputFieldStyles}
                              placeholder="Email"
                              className="login-input-field"
                              value={this.state.email}
                              onChange={(e) => this.handleUserInput(e)}
                              name="email"
                              onBlur={(e) => this.validateField(e)}
                              errorMessage={this.state.formErrors.email}
                          />
                          <PrimaryButton
                              className="login-button"
                              text={t('Obtener código')}
                              disabled={this.state.isLoading}
                              allowDisabledFocus
                              onClick={this.sendRecoveryEmail}
                          />
                        </div>
                      </>
                  ) : null}

                  {this.state.step === 2 ? (
                      <>
                        <Text
                            variant="medium"
                            block
                            style={{
                              textAlign: 'center',
                              marginBottom: '15px',
                              marginTop: '15px',
                            }}
                        >
                          {t(
                              'Ingresa el código que te enviamos por email. Si no lo encuentras en tu bandeja de entrada revisa en tu sección de spam.'
                          )}
                        </Text>
                        <div style={{ marginTop: '10px' }}>
                          <TextField
                              styles={inputFieldStyles}
                              placeholder={t('Código de recuperación')}
                              id="codigo-recuperacion"
                              className="login-input-field"
                              value={this.state.codigo_recuperacion}
                              onChange={(e) => this.handleUserInput(e)}
                              name="codigo_recuperacion"
                          />
                          <PrimaryButton
                              className="login-button"
                              text={t('Recuperar contraseña')}
                              disabled={this.state.isLoading}
                              allowDisabledFocus
                              onClick={this.submitRecoveryCode}
                          />
                        </div>
                      </>
                  ) : null}

                  {this.state.step === 3 ? (
                      <>
                        <Text
                            variant="medium"
                            block
                            style={{
                              textAlign: 'center',
                              marginBottom: '15px',
                              marginTop: '15px',
                            }}
                        >
                          {t('Genial, ahora ingresa tu contraseña nueva.')}
                        </Text>
                        <div style={{ marginTop: '10px' }}>
                          <TextField
                              styles={inputFieldStyles}
                              placeholder={t('Nueva contraseña')}
                              id="pwd_1"
                              type="password"
                              className="login-input-field"
                              value={this.state.pwd_1}
                              onChange={(e) => this.handleUserInput(e)}
                              name="password"
                              onBlur={(e) => this.validateField(e)}
                              errorMessage={this.state.formErrors.password}
                          />
                          <TextField
                              styles={inputFieldStyles}
                              placeholder={t('Repite la contraseña')}
                              id="pwd_2"
                              type="password"
                              className="login-input-field"
                              value={this.state.pwd_2}
                              onChange={(e) => this.handleUserInput(e)}
                              name="repeat_password"
                              onBlur={(e) => this.validateField(e)}
                              errorMessage={this.state.formErrors.repeat_password}
                          />
                          <PrimaryButton
                              className="login-button"
                              text={t('Cambiar contraseña')}
                              disabled={this.state.isLoading}
                              allowDisabledFocus
                              onClick={this.changePassword}
                          />
                        </div>
                      </>
                  ) : null}

                  {this.state.step === 4 ? (
                      <>
                        <Text
                            variant="medium"
                            nowrap
                            block
                            style={{
                              textAlign: 'center',
                              marginBottom: '15px',
                              marginTop: '15px',
                            }}
                        >
                          {t('Cambiaste tu contraseña con éxito.')}
                        </Text>
                        <Link href="/login">
                          <PrimaryButton
                              className="login-button"
                              text={t('Regresar al login')}
                              allowDisabledFocus
                              style={{ marginTop: '10px' }}
                          />
                        </Link>
                      </>
                  ) : null}
                </div>
                <div
                    className="panel-footer"
                    style={{ width: '320px', marginRight: 'auto', marginLeft: 'auto', display: 'block' }}
                >
                  <Text
                      variant="medium"
                      nowrap
                      block
                      style={{
                        textAlign: 'center',
                      }}
                  >
                    © {new Date().getFullYear()} - {APP_NAME}
                  </Text>
                </div>
              </div>

              {/* <PrimaryButton
              className="login-button"
              text="<"
              allowDisabledFocus
              onClick={this.showPreviousStep}
              style={{ width: '10%' }}
            />
            <PrimaryButton
              className="login-button"
              text=">"
              allowDisabledFocus
              onClick={this.showNextStep}
              style={{ width: '10%' }}
            /> */}
            </div>
          </div>
        </div>
    );
  }
}

interface IRecoverPasswordViewWrapper {
  system: TimeTaxSupportSystemClient;
  courier: MessageCourier;
  tokenAction: string;
}

const RecoverPasswordViewWrapper = (props: IRecoverPasswordViewWrapper) => {
  const captcha = useGoogleReCaptcha();
  const getToken = async () => {
    return captcha.executeRecaptcha ? await captcha.executeRecaptcha(props.tokenAction) : '';
  };

  return <RecoverPasswordView system={props.system} tokenGetter={getToken} courier={props.courier} />;
};

export default RecoverPasswordViewWrapper;
