import useAsyncDataFull from './useAsyncDataFull';

function useAsyncData<T>(dataPromise: () => Promise<T | undefined>, dependencies: any[]) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const { data } = useAsyncDataFull(dataPromise, dependencies);

  return data;
}

export default useAsyncData;
