import AssertionEntity from '../../lib/FormValidator/Assertion/AssertionEntity';
import AssertionItem from '../../lib/FormValidator/Assertion/AssertionItem';

export class ProjectCompanyAssertions extends AssertionEntity {
  private constructor () {
    super();
  }

  static build() {
    const instance = new this();
    instance.addAssertionForPercentage();
    return instance;
  }

  addAssertionForPercentage(key = 'percentage') {
    this.defineAssertionForProperty(
        key,
        AssertionItem.forClosure(
            (value) => value >= 0 && value <= 100,
            key,
            'Percentage must be a number between 0 and 100'
        )
    );
  }
}
