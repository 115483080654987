import * as React from 'react';
import { Announced } from '@fluentui/react/lib/Announced';
import {
  DetailsList,
  DetailsListLayoutMode,
  Selection,
  IColumn,
  CheckboxVisibility,
} from '@fluentui/react/lib/DetailsList';
import { MarqueeSelection } from '@fluentui/react/lib/MarqueeSelection';
import { mergeStyles } from '@fluentui/react/lib/Styling';
import ProjectInSettlementPeriod from '../../../../system/settlement/ProjectInSettlementPeriod';
import { IProjectForDefenseFile } from '../../../../system/projects/ProjectsSystem';
import i18next, { t } from 'i18next';

const exampleChildClass = mergeStyles({
  display: 'block',
  marginBottom: '10px',
});

export interface IDetailsListBasicExampleItem {
  key: number;
  name: string;
  value: number;
}

export interface IDetailsListBasicExampleState {
  items: IProjectForDefenseFile[];
  // items: IDetailsListBasicExampleItem[];
  selectionDetails: string;
}

interface IProjectsTable {
  data: IProjectForDefenseFile[];
  type: string;
  setButtonDisability: (can: boolean) => void;
  onProjectSelectionChange: (periods: ProjectInSettlementPeriod[]) => void;
}

export class ProjectSelectorTable extends React.Component<IProjectsTable, IDetailsListBasicExampleState> {
  private _selection: Selection<IProjectForDefenseFile>;
  private _allItems: IProjectForDefenseFile[];
  private _columns: IColumn[];

  private formatProjectName(item: any) {
    return `(${item.pro_codigo}) - ${item.pro_nombre}`;
  }

  constructor(props: IProjectsTable) {
    super(props);

    this._selection = new Selection<IProjectForDefenseFile>({
      onSelectionChanged: () => {
        this.setState({ selectionDetails: this._getSelectionDetails() });
      },
      getKey(item) {
        return `${item.pro_id} - ${item.periodo}`;
      },
    });

    this._allItems = this.props.data;

    this._columns = [
      {
        key: 'proyecto',
        name: t('Proyecto'),
        fieldName: 'pro_nombre',
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
        onRender: (item: any) => this.formatProjectName(item),
      },

      {
        key: 'sector',
        name: t('Sector'),
        fieldName: 'sector',
        minWidth: 200,
        maxWidth: 400,
        isResizable: true,
      },
      {
        key: 'periodo',
        name: t('Período'),
        fieldName: 'periodo',
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
      },
      {
        key: 'colaboradores',
        name: t('# Colaboradores'),
        fieldName: 'colaboradores_cant',
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
      },
    ];

    this.state = {
      items: this._allItems,
      selectionDetails: this._getSelectionDetails(),
    };
  }

  public render(): JSX.Element {
    const { items, selectionDetails } = this.state;

    //Acá no se si conviene usar este tipo de tabla de fluent o el componente que usamos en activitycardlist
    if (this.props.data && this.props.data.length > 0) {
      return (
          <div>
            <div className={exampleChildClass}>{selectionDetails}</div>
            <Announced message={selectionDetails} />
            {/* @ts-ignore */}
            <MarqueeSelection selection={this._selection}>
              <DetailsList
                  className="projects-table"
                  items={items}
                  columns={this._columns}
                  setKey="set"
                  layoutMode={DetailsListLayoutMode.justified}
                  checkboxVisibility={CheckboxVisibility.always}
                  // @ts-ignore
                  selection={this._selection}
                  selectionPreservedOnEmptyClick={true}
                  ariaLabelForSelectionColumn="Toggle selection"
                  ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                  checkButtonAriaLabel="select row"
                  onItemInvoked={this._onItemInvoked}
                  onShouldVirtualize={() => false}
              />
            </MarqueeSelection>
          </div>
      );
    } else return <div>{t('No se han encontrado proyetos disponibles para seleccionar')} </div>;
  }

  private _getSelectionDetails() {
    const periods = this._selection
        .getSelection()
        .map((selected: IProjectForDefenseFile) =>
            ProjectInSettlementPeriod.forPeriod(selected.periodo, selected.pro_id)
        );

    if (this.props.onProjectSelectionChange) {
      this.props.onProjectSelectionChange(periods);
    }

    const selectionCount = this._selection.getSelectedCount();
    switch (selectionCount) {
      case 0:
        this.props.setButtonDisability(false);
        return t('0 proyectos seleccionados');
      case 1:
        this.props.setButtonDisability(true);
        return t('1 proyecto seleccionado: ') + this._selection.getSelection()[0].pro_nombre;
      default:
        this.props.setButtonDisability(true);
        return selectionCount + t(' proyectos seleccionados');
    }
  }

  // private _onFilter = (ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, text: string): void => {
  //   this.setState({
  //     items: text ? this._allItems.filter((i) => i.name.toLowerCase().indexOf(text) > -1) : this._allItems,
  //   });
  // };

  private _onItemInvoked = (item: IDetailsListBasicExampleItem): void => {};
}
