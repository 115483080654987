import { DefaultPalette } from '@fluentui/react';
import { IButtonStyles } from '@fluentui/react/lib/Button';
import { themeLight } from './colors';

interface IStylesForButton {
  backgroundColor?: string;
  backgroundColorFocused?: string;
  textColor?: string;
  iconsColor?: string;
}

export const buttonStylesFor = (styles: IStylesForButton): IButtonStyles => ({
  root: { backgroundColor: styles.backgroundColor, color: styles.textColor },
  rootHovered: {
    backgroundColor: styles.backgroundColorFocused,
    color: styles.textColor,
  },
  rootFocused: {
    backgroundColor: styles.backgroundColorFocused,
    color: styles.textColor,
  },
  rootExpanded: {
    backgroundColor: styles.backgroundColorFocused,
    color: styles.textColor,
  },
  rootExpandedHovered: {
    backgroundColor: styles.backgroundColorFocused,
    color: styles.textColor,
  },
  icon: { color: styles.iconsColor },
  iconHovered: { color: styles.iconsColor },
  iconExpanded: { color: styles.iconsColor },
  iconExpandedHovered: { color: styles.iconsColor },
  iconChecked: { color: styles.iconsColor },
  iconPressed: { color: styles.iconsColor },
  menuIcon: { color: styles.iconsColor },
  menuIconHovered: { color: styles.iconsColor },
  menuIconExpanded: { color: styles.iconsColor },
  menuIconExpandedHovered: { color: styles.iconsColor },
  menuIconPressed: { color: styles.iconsColor },
});

export const defaultCommandBarButtonStyles = buttonStylesFor({
  backgroundColor: themeLight.backgroundSecondary,
  backgroundColorFocused: themeLight.backgroundSecondaryFocused,
});

export const titleCommandBarButtonStyles = buttonStylesFor({
  backgroundColor: themeLight.backgroundBody,
  backgroundColorFocused: themeLight.backgroundBody,
});

export const buttonStylesForProjects = buttonStylesFor({
  backgroundColor: DefaultPalette.themePrimary,
  backgroundColorFocused: DefaultPalette.themeSecondary,
  textColor: DefaultPalette.themeLighterAlt,
  iconsColor: DefaultPalette.themeLighterAlt,
});

// export const disabledButtonForSettlement = buttonStylesFor({
//   disabled: true,
// });
