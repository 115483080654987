import AssertionEntity from '../Assertion/AssertionEntity';
import { IndexableObject } from '../Assertion/IndexableObject';
import ObjectError from '../Assertion/ObjectError';
import CustomInput from './CustomInput';
import CustomInputIfc from './CustomInputIfc';
import CustomList from './CustomList';

class CustomForm implements CustomInputIfc {
  private inputList: CustomInputIfc[];
  private errorList: ObjectError[];

  constructor(private name: string, private assertionEntity: AssertionEntity) {
    this.inputList = [];
    this.errorList = [];
  }

  getName(): string {
    return this.name;
  }

  isValid(): boolean {
    return this.inputList.every((inputItem) => inputItem.isValid()) && this.errorList.length === 0;
  }

  isChanged(): boolean {
    return this.inputList.some((inputItem) => inputItem.isChanged());
  }

  setDefaulValue(value: any) {
    // revisar la implementación de este método
  }

  setValueToDefault(): void {
    this.setAllInputsToDefault();
  }

  getInputList(): CustomInputIfc[] {
    return this.inputList;
  }

  getInputByName(name: string): CustomInputIfc {
    const inputItem = this.inputList.find((inputItem) => inputItem.getName() === name);

    if (!inputItem) throw new Error('inputItem not found (' + name + ')');
    return inputItem;
  }

  getErrorList() {
    let errorList: ObjectError[] = [];

    this.inputList.forEach((inputItem) => {
      errorList = errorList.concat(inputItem.getErrorList());
    });
    return errorList.concat(this.errorList);
  }

  getErrorListForForm() {
    return this.errorList;
  }

  addInput(name: string, value: any): CustomInput {
    let input = new CustomInput(name, value, this.assertionEntity);
    this.inputList.push(input);
    return input;
  }

  addInputAsserted(name: string, value: any): CustomInput {
    let input = this.addInput(name, value);
    input.evaluate();
    return input;
  }

  addFormInput(subformInput: CustomForm) {
    this.inputList.push(subformInput);
  }

  addListInputFor<T>(name: string, items: T[]) {
    let customList = new CustomList(name, items, this.assertionEntity);
    this.inputList.push(customList);
    return customList;
  }

  addListInput<T>(items: CustomList<T>) {
    this.inputList.push(items);
  }

  setValue(value: any) {
    console.error('REVISAR ONCHANGE ON CUSTOMFORM');
  }

  onBlur() {
    console.error('REVISAR ONBLUR ON CUSTOMFORM');
  }

  hasErrors(): boolean {
    const errorList = this.getErrorList();
    return errorList.length > 0;
  }

  evaluate() {
    this.evaluateAllOneByOne();
    this.evaluateObject();
  }

  protected evaluateObject(): ObjectError[] {
    const objectToEvaluate: IndexableObject = this.createObjectToEvaluate();
    this.errorList = this.assertionEntity.evaluateObject(objectToEvaluate);
    return [...this.errorList];
  }

  getValue() {
    return this.inputList;
  }

  protected createObjectToEvaluate(): IndexableObject<any> {
    const objectToEvaluate: IndexableObject = {};
    this.inputList.forEach((input) => {
      objectToEvaluate[input.getName()] = input.getValue();
    });
    return objectToEvaluate;
  }

  private evaluateAllOneByOne() {
    this.inputList.forEach((inputItem) => {
      inputItem.evaluate();
    });
  }

  private setAllInputsToDefault(): void {
    this.inputList.forEach((inputItem) => {
      inputItem.setValueToDefault();
    });
  }
}

export default CustomForm;
