import * as React from 'react';
import { DefaultPalette, ICommandBarItemProps, Text, Icon } from '@fluentui/react';
import TimeTaxSupportSystemClient from '../../system';
import { DateTime } from 'luxon';
import MessageCourier from '../../lib/MessageCourier';
import ViewWrapperWithCommandBar from '../Layout/ViewWrapperWithCommandBar';
import { defaultCommandBarButtonStyles, titleCommandBarButtonStyles } from '../../styles/button-styles';
import { ReportingFilters } from './ReportingFilters';
import { IReportingDocsControlData } from '../../system/reporting/ReportingSystem';
import { Company } from '../../system/company/Company';
import { useTranslation } from 'react-i18next';
import DataGrid, {
  Scrolling,
  RemoteOperations,
  Column,
  Grouping,
  GroupPanel,
  Summary,
  GroupItem,
  SortByGroupSummaryInfo,
  Export,
  SearchPanel,
  TotalItem,
} from 'devextreme-react/data-grid';
import { User } from '../../system/User';
import { EscalableExporterOptions, exportToExcel } from '../../lib/Util';

interface ReportingDocsControl {
  system: TimeTaxSupportSystemClient;
  courier: MessageCourier;
  changeBlockUI: (state: boolean) => void;
  user: User;
}

export const ReportingDocsControl = (props: ReportingDocsControl) => {
  const { t, i18n } = useTranslation();
  const [from, setFrom] = React.useState<DateTime | undefined>(undefined);
  const [to, setTo] = React.useState<DateTime | undefined>(undefined);
  const [node, setNode] = React.useState<string>('');
  const [items, setItems] = React.useState<IReportingDocsControlData[]>([]);

  const itemsMenu = React.useMemo(
      (): ICommandBarItemProps[] => [
        {
          key: 'title',
          text: t('Reporte de documentos obligatorios por proyecto'),
          iconProps: {
            iconName: 'ReminderPerson',
            color: DefaultPalette.themeLighterAlt,
            className: 'text-title-btn',
          },
          buttonStyles: titleCommandBarButtonStyles,
          className: 'text-title-btn',
        },
      ],
      []
  );

  const onClickSearch = async () => {
    props.changeBlockUI(true);
    try {
      if (from && to) {
        const _items = await props.system.getReportingSystem().getDocsControlReport(from, to, node);
        setItems(_items);
        props.courier.messageSuccess(t('Reporte generado con éxito'));
      }
    } catch (error) {
      props.courier.messageError(error);
    }
    props.changeBlockUI(false);
  };

  const onExporting = (e: any) => {
    let options: EscalableExporterOptions = {
      title: t('Reporte de control documentos obligatorios por proyecto'),
      fileName: `reporte-control-docs-obligatorios-por-proyecto-de-${from?.toFormat(
          'dd-LL-yyyy'
      )}-a-${to?.toFormat('dd-LL-yyyy')}`,
      sheetName: t('Reporte de control documentos obligatorios por proyecto'),
      customizeCell: (options) => {
        if (options.gridCell?.rowType === 'group') {
          options.excelCell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '8DB4E2' } };
        }
        if (options.gridCell?.rowType === 'totalFooter' && options.excelCell.value) {
          options.excelCell.font.italic = true;
        }
      },
    };
    exportToExcel(e, options);
  };

  const getLabelEstado = (value) => {
    const uploaded = !!value.data.fecha_carga;
    const date = DateTime.fromFormat(value.data.fecha_vencimiento, 'yyyy-LL-dd');
    const now = DateTime.now();
    const expired = !!(now.diff(date).as('milliseconds') > 0 && !value.data.documento);

    if (uploaded) {
      return (
          <Text variant="smallPlus" className="adjust-label-docs-report">
            <Icon iconName="CheckMark" className="text-icon-for-report-docs success-color" /> Cargado{' '}
          </Text>
      );
    }
    if (expired) {
      return (
          <Text variant="smallPlus" className="adjust-label-docs-report">
            <Icon iconName="AlertSolid" className="text-icon-for-report-docs error-color" /> Vencido{' '}
          </Text>
      );
    } else {
      return (
          <Text variant="smallPlus" className="adjust-label-docs-report">
            <Icon iconName="EaseOfAccess" className="text-icon-for-report-docs " />
            Pendiente
          </Text>
      );
    }
  };

  return (
      <ViewWrapperWithCommandBar
          withBorderBottom
          title={''}
          items={itemsMenu}
          overflowButtonStyles={defaultCommandBarButtonStyles}
          variant="highligth"
          titleBody={t('Reporte de control documentos obligatorios por proyecto')}
      >
        <div className="padding-1 bg-white">
          <div className="ms-Grid" dir="ltr">
            <div className="ms-Grid-row">
              <ReportingFilters
                  system={props.system}
                  courier={props.courier}
                  onChangeFromDate={(d) => setFrom(d)}
                  onChangeToDate={(d) => setTo(d)}
                  onClickSearch={onClickSearch}
                  showCompanyFilter={false}
                  onChangeCompany={() => {}}
                  showSectorFilter={true}
                  onChangeNode={(nodeId) => {
                    setNode(nodeId);
                  }}
                  user={props.user}
              />
            </div>
            {items.length > 0 && (
                <div className="ms-Grid-row">
                  <div className="ms-Grid-col ms-sm12">
                    <DataGrid
                        height={620}
                        width="100%"
                        keyExpr={'proyecto_id'}
                        dataSource={items}
                        showBorders={true}
                        wordWrapEnabled={true}
                        onExporting={onExporting}
                        allowColumnResizing={true}
                    >
                      <Scrolling mode="virtual" />
                      <SearchPanel visible={true} placeholder={t('Buscar...')} />
                      <Grouping autoExpandAll={false} />
                      <GroupPanel visible={true} />

                      <Column dataField="proyecto" caption={t('Proyecto')} alignment={'center'} groupIndex={0} />
                      <Column
                          dataField="colaborador_responsable"
                          caption={t('Responsable')}
                          alignment={'center'}
                          width={250}
                      />
                      <Column
                          dataField="fecha_vencimiento"
                          caption={t('Vencimiento')}
                          alignment={'center'}
                          width={100}
                      />
                      <Column dataField="tipo_doc" caption={t('Tipo de documento')} alignment={'center'} />
                      <Column dataField="concepto" caption={t('Concepto')} alignment={'center'} />
                      <Column
                          dataField="estado"
                          caption={t('Estado')}
                          alignment={'center'}
                          cellRender={(d) => {
                            // d.data tiene toda la info de la row
                            return (
                                <div className="dx-datagrid-summary-item dx-datagrid-text-content dx-datagrid-group-text-content">
                                  {getLabelEstado(d)}
                                </div>
                            );
                          }}
                      />
                      <Column dataField="documento" caption={t('Nombre del documento')} alignment={'center'} />
                      <Column dataField="fecha_carga" caption={t('Cargado el')} alignment={'center'} />
                      <Summary>
                        <GroupItem
                            column="cant_horas"
                            summaryType="sum"
                            valueFormat={(d) => parseFloat(d).toFixed(2)}
                            displayFormat={`{0} hs`}
                            showInGroupFooter={true}
                        />
                        <TotalItem
                            column="cant_horas"
                            summaryType="sum"
                            valueFormat={(d) => parseFloat(d).toFixed(2)}
                            displayFormat={`Total: {0} hs`}
                        />
                      </Summary>
                      <SortByGroupSummaryInfo sortOrder={'desc'} summaryItem="sum" />
                      <Export enabled={true} />
                    </DataGrid>
                  </div>
                </div>
            )}
          </div>
        </div>
      </ViewWrapperWithCommandBar>
  );
};
