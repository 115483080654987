import React, { useEffect, useState, useMemo } from 'react';
import { Text } from '@fluentui/react/lib/Text';
import { IStackTokens, Stack } from '@fluentui/react/lib/Stack';
import { Toggle, DatePicker, Label, PrimaryButton } from '@fluentui/react';
import {
  Dropdown,
  DropdownMenuItemType,
  IDropdownStyles,
  IDropdownOption,
} from '@fluentui/react/lib/Dropdown';
import CompanySelector from '../../../Company/CompanySelector';
import { DateTime } from 'luxon';
import { useTranslation, Trans } from 'react-i18next';
import TimeTaxSupportSystemClient from '../../../../system';
import MessageCourier from '../../../../lib/MessageCourier';
import { Company } from '../../../../system/company/Company';
import { DateCustom } from '../../../../lib/DateCustom';
import { defenseFileAssistantDropdownValues, routes } from '../../../../common/constants';
import { getLocalizedStrings } from '../../../../lib/LocalizedStringsForDatePicker';
import { CompanyMultiSelector } from '../../../Company/CompanyMultiSelector';
import { useNavigate } from 'react-router-dom';

interface IEnding {
  system: TimeTaxSupportSystemClient;
}

const Ending = (props: IEnding) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  return (
      <div>
        <div className="ms-Grid mt-2" dir="ltr">
          <div className="ms-Grid-row">
            <div className="ms-Grid-col  ms-sm12">
              {t(
                  'La generación de la defensa masiva puede tomar mucho tiempo, el usuario será notificado por email'
              )}
            </div>
          </div>
          <div className="ms-Grid-row mt-2">
            <div className="ms-Grid-col  ms-sm3">
              <PrimaryButton text={t('Finalizar')} onClick={() => navigate(routes.massive_defense_list)} />
            </div>
          </div>
        </div>
      </div>
  );
};

export default Ending;
