import { getCurrentDateTime } from "../../Components/Collaborator/CollaboratorView/collaboratorCreateView"
import formatearFecha from "../formatDate";

 function TodayDate(){
    const data = getCurrentDateTime();
    return formatearFecha(data);
};

export default TodayDate


export function convertStringToDate(str: string): Date |undefined {
    let fechaIngresoDate: Date |undefined = undefined;

    // Si el formato es "01/01/2023"
    if (str.includes('/')) {
        const parts = str.split('/');
        fechaIngresoDate = new Date(parseInt(parts[2]), parseInt(parts[1]) - 1, parseInt(parts[0]));
    }
    // Si el formato es "2022-01-05"
    else if (str.includes('-')) {
        fechaIngresoDate = new Date(str);
    }

    return fechaIngresoDate;
}

export function convertStringToDateProyectoEnalce(str: string): Date | undefined {
    let fechaIngresoDate: Date | undefined = undefined;

    // Si el formato es "01/01/2023"
    if (str.includes('/')) {
        const parts = str.split('/');
        fechaIngresoDate = new Date(parseInt(parts[2]), parseInt(parts[1]) - 1, parseInt(parts[0]));
    }
    // Si el formato es "2022-01-05"
    else if (str.includes('-')) {
        fechaIngresoDate = new Date(str);
    }

    // Agregar un día a la fecha
    if (fechaIngresoDate) {
        fechaIngresoDate.setDate(fechaIngresoDate.getDate() + 1);
    }

    return fechaIngresoDate;
}