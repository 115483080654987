import * as React from 'react';
import { Text, Icon } from '@fluentui/react';
import { useTranslation, Trans } from 'react-i18next';

export const NoDataFoundedForSelectedFilters = (props: { text: string }) => {
    const { t, i18n } = useTranslation();

    return (
        <div className="text-center mt-2">
            <Text variant="medium" className="fw-500">
                <Icon iconName="AddGroup" className="text-icon"></Icon>
                {t('No hay horas registradas en ') + props.text + t(' para los filtros seleccionados')}
            </Text>
        </div>
    );
};
