import { IDatePickerStrings } from 'office-ui-fabric-react';
import { EN_LANGUAGE_VALUE, ES_LANGUAGE_VALUE, getSessionStorageLanguage } from '../i18n';

const DayPickerStrings_EN: IDatePickerStrings = {
  months: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ],

  shortMonths: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],

  days: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],

  shortDays: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],

  goToToday: 'Go to today',
  prevMonthAriaLabel: 'Go to previous month',
  nextMonthAriaLabel: 'Go to next month',
  prevYearAriaLabel: 'Go to previous year',
  nextYearAriaLabel: 'Go to next year',
};

const DayPickerStrings_ES: IDatePickerStrings = {
  months: [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre',
  ],

  shortMonths: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dec'],

  days: ['Domingo', 'Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sabado'],

  shortDays: ['D', 'L', 'M', 'M', 'J', 'V', 'S'],

  goToToday: 'Ir a día de hoy',
  prevMonthAriaLabel: 'Ir a mes anterior',
  nextMonthAriaLabel: 'Ir a mes próximo',
  prevYearAriaLabel: 'Ir a año anterior',
  nextYearAriaLabel: 'Ir a año próximo',
};

export const getLocalizedStrings = () => {
  const userLang = getSessionStorageLanguage();
  if (userLang === ES_LANGUAGE_VALUE) {
    return DayPickerStrings_ES;
  }
  if (userLang === EN_LANGUAGE_VALUE) {
    return DayPickerStrings_EN;
  }
};
