import { Icon, PrimaryButton, Text } from '@fluentui/react';
import { ActivityJson } from '../../../system/activity/ActivitySystem';
import Project from '../../../system/projects/Project';

interface IStepFinalInfo {
  qtyActividadesAEditar: number | undefined;
  project: Project;
}
export const StepFinalInfo = (props: IStepFinalInfo) => {
  return (
      <div className="ms-Grid-col ms-sm12 ">
        <div className="ms-Grid-col ms-sm12 mb-2 mt-2">
          <Text variant="mediumPlus" className="d-block">
            <Icon className="text-icon" iconName="Info"></Icon>Se han encontrado un total de{' '}
            <span style={{ fontWeight: 600 }}>
            {props.qtyActividadesAEditar ? props.qtyActividadesAEditar : 0} actividad(es)
          </span>{' '}
            en el periodo seleccionado.
          </Text>
          {props.project.getCanEditDistribution() && (
              <Text variant="mediumPlus" className="d-block mt-1">
                <Icon className="text-icon error-color blinking-icon" iconName="AlertSolid"></Icon>
                <span style={{ fontWeight: 600 }}>¡CUIDADO!</span> Este proyecto tiene la distribucion facturable
                editable, significa que los usuarios pueden cambiar la distribucion facturable de sus actividades,
                si aplicas los cambios vas a sobreescribir la distribución de actividades que podrían haber sido
                modificadas.
              </Text>
          )}
        </div>
      </div>
  );
};
