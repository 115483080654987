import * as React from 'react';
import {
  ComboBox,
  DefaultPalette,
  IComboBoxProps,
  ICommandBarItemProps,
  Icon,
  Label,
  PrimaryButton,
  TextField,
  Text,
} from '@fluentui/react';
import {
  ColumnGroupedDetailsList,
  GroupedDetailList,
} from '../../../Common/GroupedDetailList/GroupedDetailList';
import ViewWrapperWithCommandBar from '../../../Layout/ViewWrapperWithCommandBar';
import { ConfirmDownloadPreSettlement } from '../../../Reporting/ConfirmDownloadPreSettlement';
import { IReportePreliquidacion } from '../../../../system/reporting/ReportingSystem';
import { defaultCommandBarButtonStyles, titleCommandBarButtonStyles } from '../../../../styles/button-styles';
import ReportByPreliquidationExporter from '../../../Reporting/ReportByPreliquidationExporter';
import { useTranslation } from 'react-i18next';
import TimeTaxSupportSystemClient from '../../../../system';
import { User } from '../../../../system/User';
import MessageCourier from '../../../../lib/MessageCourier';
import { DateTime } from 'luxon';
import { Company } from '../../../../system/company/Company';
import { Collaborator } from '../../../../system/collaborators/Collaborator';
import { formatNumberToLocale } from '../../../../common/list-utils';
import { CollaboratorsWithNoRemunInPeriod } from './CollaboratorsWithNoRemunInPeriod';
import { DefaultButton } from 'office-ui-fabric-react';

interface IReportingSettlementPreliquidation {
  system: TimeTaxSupportSystemClient;
  courier: MessageCourier;
  changeBlockUI?: (state: boolean) => void;
  getDataFromTableAssistant: (concept: string) => void;
  user?: User;
  dataTable: IReportePreliquidacion[];
  from?: DateTime;
  COMPANY_KEY: string;
  COLLABORATOR_KEY: string;
  concept?: string;
  getPreliquidacionReport: (concept: string) => void;
  settlementMode: string;
}

export const SettlementsTable = (props: IReportingSettlementPreliquidation) => {
  const { t, i18n } = useTranslation();
  const [companySelected, setCompanySelected] = React.useState<Company>();
  const [from, setFrom] = React.useState<DateTime | undefined>(undefined);
  const [to, setTo] = React.useState<DateTime | undefined>(undefined);
  const [concept, setConcept] = React.useState<string>(props.COMPANY_KEY);
  const [items, setItems] = React.useState<IReportePreliquidacion[]>([]);
  const [itemsFiltered, setItemsFiltered] = React.useState<IReportePreliquidacion[]>([]);
  const [collaboratorList, setCollaboratorList] = React.useState<Collaborator[]>();
  const [showCollabsWithNoRemun, setShowCollabsWithNoRemun] = React.useState<boolean>(false);

  const AllItems = React.useMemo(() => {
    return props.dataTable;
  }, [props.dataTable]);

  const iconProps = { iconName: 'Search' };

  React.useEffect(() => {
    setItemsFiltered(AllItems);
  }, [AllItems]);

  React.useEffect(() => {
    if (props.from) {
      setFrom(props.from);
      // let _from = props.from.toJSDate();
      // // _from.setMonth(_from.getMonth() + 1);   USAR ESTA NORMALMENTE. AHORA POR PRUEBA USAR LINEA DE ABAJO
      // _from.setMonth(_from.getDay() + 1);
      // let _to = DateTime.fromJSDate(_from);
      let _to = props.from.plus({ months: 1 });
      setTo(_to);
    }
  }, [props.from]);

  const openCollabsWithNoRemun = () => {
    setShowCollabsWithNoRemun(true);
  };

  const getCollaboratorsWithNoRemunInPeriod = async () => {
    if (props.from) {
      // props.changeBlockUI(true);
      const response = await props.system
          .getSettlementSystem()
          .getCollaboratorsWithNoRemunInPeriod(props.from);
      if (response) {
        setCollaboratorList(response);
        // openCollabsWithNoRemun();
        // props.changeBlockUI(false);
      }
    }
  };

  const companyColumns: ColumnGroupedDetailsList[] = React.useMemo(
      () => [
        {
          key: 'concept',
          name: concept == props.COMPANY_KEY ? t('Empresa') : t('Colaborador'),
          fieldName: 'concept',
          couldGroup: true,
          minWidth: 350,
        },

        {
          key: 'periodo',
          name: t('Período'),
          fieldName: 'periodo',
          couldGroup: true,
          minWidth: 100,
          maxWidth: 100,
          // formatTotal: (number: number) => {
          //   return <span>{formatNumberToLocale(number)}</span>;
          // },
        },
        {
          key: 'horas_confirmadas',
          name: t('Horas chequeadas'),
          fieldName: 'horas_confirmadas',
          className: 'ta-end',
          couldTotal: true,
          minWidth: 100,
          maxWidth: 100,
          // formatTotal: (number: number) => {
          //   return <span>{formatNumberToLocale(number)}</span>;
          // },

          onRender: (item: any) => {
            return <span>{formatNumberToLocale(Number(item.horas_confirmadas))}</span>;
          },

          onRenderHeader: (item: any) => {
            return (
                <span>
              <Icon iconName="Clock" title={t('Horas')} /> &nbsp; {t('Confirmadas')}
            </span>
            );
          },
        },
        {
          key: 'importe_costo_total',
          name: t('Importe'),
          fieldName: 'importe_costo_total',
          className: 'ta-end',

          couldTotal: true,
          minWidth: 100,
          maxWidth: 100,
          // formatTotal: (number: number) => {
          //   return <span>{+number.toFixed(2)}</span>;
          // },

          onRender: (item: any) => {
            return (
                <span>
              {item.moneda_simbolo} {formatNumberToLocale(Number(item.importe_costo_total))}
            </span>
            );
          },

          onRenderHeader: (item: any) => {
            return (
                <span>
              <Icon iconName="Money" title={t('Importe')} /> &nbsp; {t('Importe')}
            </span>
            );
          },
        },
      ],
      [AllItems]
  );

  React.useEffect(() => {
    if (concept) props.getDataFromTableAssistant(concept);
  }, [concept]);

  React.useEffect(() => {
    getCollaboratorsWithNoRemunInPeriod();
  }, []);

  function exportReport(items: IReportePreliquidacion[]): void {
    // const fileName = `Reporte ${companySelected?.getName()}`;
    ReportByPreliquidationExporter.exportFor(items, concept);
  }

  // const onClickSearch = async () => {
  //   props.changeBlockUI(true);
  //   try {
  //     if (from && to) {
  //       const _items = await props.system.getReportingSystem().getPreliquidacionReport(from, to, concept);
  //       setItems(_items);
  //       props.courier.messageSuccess('Reporte generado con éxito');
  //     } else {
  //       props.courier.messageError('Error buscando el reporte');
  //     }
  //   } catch (error) {
  //     props.courier.messageError(error);
  //   }
  //   props.changeBlockUI(false);
  // };

  const [showConfirmDownload, setShowConfirmDownload] = React.useState<boolean>(false);

  const openConfirmDownloadPresettlement = () => {
    setShowConfirmDownload(true);
  };

  const downloadPresettlement = async () => {
    // props.changeBlockUI(true);
    try {
      if (from && to) {
        let uri = await props.system
            .getSettlementSystem()
            .downloadPreSettlement(from, to, props.settlementMode);
        // window.open(uri, '_blank');
        // window.location.assign(uri);
        props.courier.messageSuccess(t('Se ha descargado la planilla modelo con éxito'));
      }
    } catch (error) {
      props.courier.messageError(error);
    } finally {
      // props.changeBlockUI(false);
    }
  };

  const onCloseDialog = (confirm: boolean) => {
    if (confirm) {
      downloadPresettlement();
    }
    setShowCollabsWithNoRemun(false);
    setShowConfirmDownload(false);
  };

  const onChange: IComboBoxProps['onChange'] = (event, option) => {
    setConcept(option!.key as string);
    filterItems('');
    props.getPreliquidacionReport(option!.key as string);
  };

  React.useEffect(() => {
    setConcept(props.COMPANY_KEY);
  }, []);

  const filterItems = (val: string) => {
    let _ret: IReportePreliquidacion[] = [];
    _ret = AllItems.filter((item) => item.concept.toLowerCase().includes(val.toLowerCase()));
    setItemsFiltered(_ret);
  };
  return (
      <div>
        <div className="padding-1 ms-Grid" dir="ltr">
          <div className="ms-Grid-row">
            {collaboratorList ? (
                <div className="ms-Grid-col  ms-sm6 ">
                  <div>
                    <Label className="invisible">{t('Remuneraciones')}</Label>
                    {collaboratorList.length > 0 ? (
                        <Icon iconName="Warning" className="text-icon error-color" />
                    ) : null}
                    <Text className={collaboratorList.length > 0 ? 'error-color' : 'success-color'}>
                      {collaboratorList.length > 0
                          ? t('Hay colaboradores que no tienen remuneración definida. ')
                          : t('Todos los colaboradores tienen remuneración definida. ')}
                    </Text>
                    {collaboratorList.length > 0 ? (
                        <Text className="error-color pointer" onClick={() => openCollabsWithNoRemun()}>
                          <span style={{ fontWeight: 600 }}>{t('Ver más')}</span>
                        </Text>
                    ) : null}
                  </div>
                  <br></br>
                </div>
            ) : null}
            <div className="ms-Grid-col  ms-sm6 float-right">
              {/* <Label className="invisible">{t('Descargar')} </Label> */}
              <PrimaryButton
                  className="float-right"
                  text={t('Descargar')}
                  onClick={openConfirmDownloadPresettlement}
              />
            </div>
          </div>

          <div className="ms-Grid-row">
            <div className="ms-Grid-col  ms-sm6">
              <ComboBox
                  label={t('Totalizado por')}
                  defaultSelectedKey={props.concept ? props.concept : props.COMPANY_KEY}
                  onChange={onChange}
                  options={[
                    { key: props.COMPANY_KEY, text: t('Empresa') },
                    { key: props.COLLABORATOR_KEY, text: t('Colaborador') },
                  ]}
              />
            </div>
            <div className="ms-Grid-col  ms-sm6 ">
              <TextField
                  iconProps={iconProps}
                  label={t('Buscar')}
                  onChange={(e: any) => {
                    const val = e.target.value;
                    filterItems(val);
                  }}
              ></TextField>
            </div>
          </div>
          <div className="ms-Grid-row">
            <div className="ms-Grid-col ms-sm-12"></div>
            {items && (
                <GroupedDetailList
                    // columns={
                    //   concept === props.COLLABORATOR_KEY && props.collaboratorList
                    //     ? collaboratorColumns
                    //     : companyColumns
                    // }
                    // items={
                    //   concept === props.COLLABORATOR_KEY && props.collaboratorList
                    //     ? props.collaboratorList
                    //     : props.dataTable
                    // }
                    columns={companyColumns}
                    items={itemsFiltered}
                    hideGroupBy={true}
                />
            )}
          </div>
          {collaboratorList ? (
              <CollaboratorsWithNoRemunInPeriod
                  onClose={onCloseDialog}
                  show={showCollabsWithNoRemun}
                  collaboratorList={collaboratorList}
                  // periodo={`${from?.toFormat('dd/LL/yyyy')} - ${to?.toFormat('dd/LL/yyyy')}`}
              ></CollaboratorsWithNoRemunInPeriod>
          ) : null}
          <ConfirmDownloadPreSettlement
              onClose={onCloseDialog}
              show={showConfirmDownload}
              periodo={`${from?.toFormat('dd/LL/yyyy')} - ${to?.toFormat('dd/LL/yyyy')}`}
          ></ConfirmDownloadPreSettlement>
        </div>
      </div>
  );
};
