import Project from './Project';
import { ProjectsSystem, IStoredProjectFolder } from './ProjectsSystem';
import ProjectFolder from './ProjectFolder';
import { ProjectRelatedDocument } from '../documents/ProjectRelatedDocument';
import { ProjectSystemFolder } from './ProjectSystemFolder';
import { t } from 'i18next';

export class ProjectSettlementsFolder extends ProjectSystemFolder {
  static fromIProjectFolder(system: ProjectsSystem, project: Project, folder: IStoredProjectFolder) {
    return new this(system, project, folder.pca_id, t('Liquidaciones'));
  }

  moveInto(documents: ProjectRelatedDocument[]): Promise<void> {
    throw new Error('Can not move documents to settlements folder.');
  }

  addChildNamed(childName: string): Promise<ProjectFolder> {
    throw new Error('Can not add project folder to settlements');
  }

  isTrash(): boolean {
    return false;
  }
}
