import { DateTime } from 'luxon';
import { newEntityDefaultId } from '../../common/constants';
import { DateCustom } from '../../lib/DateCustom';
import { getQueryParamsExpression } from '../../lib/XhrRequestHandler';
import TimeTaxSupportSystemClient from '../../system';
import { Activity } from '../../system/activity/Activity';
import ActivitySystem, { ActivityJson } from '../../system/activity/ActivitySystem';
import { CalendarEvent, TActividadEvent } from './CalendarEvent';
import { CollaboratorLicense } from '../../system/collaborators/collaborator_license/CollaboratorLicense';
import { NonWorkingDay } from '../../system/non_working_days/NonWorkingDays';
import { useTranslation, Trans } from 'react-i18next';
import { t } from 'i18next';

export interface CalendarFilters {
  proyectoId: string;
  verDeclinadas: boolean;
  verEliminadas: boolean;
  verOcultas: boolean;
  verEventosOutlook: boolean;
  [index: string]: any;
}

export interface ActividadOutlook {
  email: string;
  start: Date | string;
  end: Date | string;
  subject: string;
  id: string;
  oculta: boolean;
}

export const getDefaultFilters = () => {
  return {
    proyectoId: 'todos',
    verEliminadas: false,
    verDeclinadas: false,
    verOcultas: false,
    verEventosOutlook: true,
  };
};

export class Calendar {

  private activityCounts = {
    general: 0,
    outlook: 0,
    license: 0,
    nonWorkingDay: 0,
    eliminado: 0,
    actAceptadas: 0,
    actDeclinadas: 0,
  };
  // filters: CalendarFilters = {
  //   proyectoId: 'todos',
  //   verEliminadas: false,
  //   verDeclinadas: false,
  //   verOcultas: false,
  //   verEventosOutlook: true,
  // };

  /* TODO: Pasar system: TimeTaxSupportSystemClient a system: ActivitySystem*/
  constructor(private system: ActivitySystem) {}

  public async getEvents(
      start: Date,
      end: Date,
      colLoggedId: string,
      filters: CalendarFilters = getDefaultFilters()
  ): Promise<TActividadEvent[]> {
    // let _formattedStart = DateCustom.FormatDatetoAMDStr(start);
    // let _formattedEnd = DateCustom.FormatDatetoAMDStr(end);
    this.resetActivityCounts();
    let _formattedStart = DateTime.fromJSDate(start).toFormat('yyyy-LL-dd');
    let _formattedEnd = DateTime.fromJSDate(end).toFormat('yyyy-LL-dd');

    const query = getQueryParamsExpression([
      {
        name: 'proId',
        value: filters.proyectoId === 'todos' ? undefined : filters.proyectoId,
      },
      { name: 'declinadas', value: filters.verDeclinadas },
      { name: 'eliminadas', value: filters.verEliminadas },
    ]);
    let _activityList: ActivityJson[] = await this.system.getListForCalendar(
        _formattedStart,
        _formattedEnd,
        query
    );

    let events = this.mapEventToCalendarTTS(_activityList, colLoggedId);
    const filterONCheked = events.filter(event => event.extendedProps.data.checked === true && event.extendedProps.data.eliminada ===false);
    const filterOFFCheked = events.filter(event => event.extendedProps.data.checked === false);
    this.activityCounts.actAceptadas = filterONCheked.length;
    this.activityCounts.actDeclinadas = filterOFFCheked.length;
    this.activityCounts.general += _activityList.length;  
    await this.getExternalEvents(start, end, events, filters);
    await this.getLicensesEvents(start, end, events);
    await this.getNonWorkingDaysEvents(start, end, events);

    return events;
  }

  private resetActivityCounts() {
    this.activityCounts = {
      general: 0,
      outlook: 0,
      license: 0,
      nonWorkingDay: 0,
      eliminado: 0,
      actAceptadas: 0,
      actDeclinadas: 0,
    };
  }

  public getActivityCountByType() {
    return this.activityCounts;
  }


  public getHorasEsperadas = async (start: Date, end: Date) => {
    let _end = new Date(end.getTime());
    let _start = new Date(start.getTime());

    _end.setDate(end.getDate());

    /*
    const datetimeEnd = DateTime.fromJSDate(new Date(end.getTime()));

    if (datetimeEnd.diffNow(['days']).days > 0) {
      _end = new Date();

      _end.setDate(_end.getDate());
    }
      */

    return await this.system.getHorasEsperadas(start, _end);
  };

  private getExternalEvents = async (
      start: Date,
      end: Date,
      events: TActividadEvent[],
      filters: CalendarFilters
  ) => {
    if (!filters.verEventosOutlook) return;
    const eventsOutlook = await this.system.getEventsOutlook(start, end, filters.verOcultas);
    if (eventsOutlook) {
      eventsOutlook.forEach((actividad) => {
        const i = events.findIndex((ev) => ev.extendedProps.data.idOutlook === actividad.id);
        if (i < 0) {
          events.push(this.eventFromActividadOutlook(actividad));
          this.activityCounts.outlook++;
        }
      });
    }
    // Actividad de ejemplo de outlook - no borrar - util para pruebas
    // let act_outlook = {
    //   title: 'ACL - control',
    //   start: DateCustom.ParseDateFromApiStr('15/07/2023 12:20'),
    //   end: DateCustom.ParseDateFromApiStr('15/07/2023 12:40'),
    //   allDay: false,
    //   backgroundColor: '#fff',
    //   borderColor: '#fff',
    //   extendedProps: {
    //     data: {
    //       id: 'AAMkADRjZjI5MjY2LTA0YTctNGI1Zi04NmU0LWI3YWYxMzJjNDcwOABGAAAAAAC6s_2FJND_TLVzg2Q8awH7BwBhcRS1ywE_SLQz6SLG16d0AAAAAAENAADhtYJbMYI8SahGKOAsCrXzAAE6cvxUAAA=',
    //       checked: true,
    //       allChecked: false,
    //       documentsPending: 0,
    //       eliminada: false,
    //       fromOutlook: true,
    //       oculta: false,
    //       aceptoTag: true,
    //       isNew: true,
    //       esOwner: true,
    //       canEdit: true,
    //       isNewFromOutlook: true,
    //       eventoOutlookEstaOculta: false,
    //       act_inicio: '15/07/2023 12:20',
    //       act_fin: '15/07/2023 12:40',
    //       cantDocumentosCargados: 0,
    //       act_replicada_de_actid: '',
    //       colaboradorActividadList: [],
    //     },
    //   },
    // };
    // events.push(act_outlook);
  };

  eventFromActividadOutlook = (actividad: ActividadOutlook): TActividadEvent => {
    let isNew = true;
    let aceptoTag = true;
    let esOwner = true;
    let canEdit = true;
    let isNewFromOutlook = true;
    let eventoOutlookEstaOculta = actividad.oculta;

    return {
      title: actividad.subject ? actividad.subject : '',
      start: DateCustom.ParseDateFromApiStr(actividad.start),
      end: DateCustom.ParseDateFromApiStr(actividad.end),
      allDay: false,
      backgroundColor: '#fff', //Info (aqua)
      borderColor: '#fff', //Info (aqua)
      extendedProps: {
        data: {
          id: actividad.id,
          checked: true,
          allChecked: false,
          documentsPending: 0,
          eliminada: false,
          fromOutlook: true,
          oculta: actividad.oculta,
          aceptoTag: aceptoTag,
          isNew: isNew,
          esOwner: esOwner,
          canEdit: canEdit,
          isNewFromOutlook: isNewFromOutlook,
          eventoOutlookEstaOculta: eventoOutlookEstaOculta,
          proyecto: undefined,
          act_inicio: actividad.start,
          act_fin: actividad.end,
          cantDocumentosCargados: 0,
          act_replicada_de_actid: '',
          colaboradorActividadList: [],
        },
      },
    };
  };

  private mapEventToCalendarTTS(_activityList: ActivityJson[], colLoggedId: string): TActividadEvent[] {
    return _activityList.map((act) => {
      if(act.act_estado === "Desactivada"){
          this.activityCounts.eliminado++;
      }
      return CalendarEvent.instanceEventFromIActivity(act, colLoggedId);
    });
  }

  private async getLicensesEvents(start: Date, end: Date, events: TActividadEvent[]) {
    const licenses = await this.system.getCollaboratorLicenseSystem().getMyLicenses(start, end);
    licenses.forEach((license) => {
      events.push(this.eventFromLicense(license));
      this.activityCounts.license++;
    });

    // let act_vacaciones = {
    //   title: 'Vacaciones',
    //   start: DateCustom.ParseDateFromApiStr('11/03/2023 00:00'),
    //   end: DateCustom.ParseDateFromApiStr('12/03/2023 00:00'),
    //   display: 'background',
    //   // allDay: true,
    //   // backgroundColor: '#808080',
    //   // borderColor: '#808080',
    //   overlap: false,
    //   extendedProps: {
    //     data: {
    //       id: 'id_vacaciones_ficticio',
    //       checked: true,
    //       allChecked: false,
    //       documentsPending: 0,
    //       eliminada: false,
    //       fromOutlook: true,
    //       oculta: false,
    //       aceptoTag: true,
    //       isNew: true,
    //       esOwner: true,
    //       canEdit: true,
    //       isNewFromOutlook: true,
    //       eventoOutlookEstaOculta: false,
    //       act_inicio: '04/11/2022 17:00',
    //       act_fin: '04/11/2022 18:00',
    //       cantDocumentosCargados: 0,
    //       act_replicada_de_actid: '',
    //       colaboradorActividadList: [],
    //     },
    //   },
    // };
    // events.push(act_vacaciones);
  }

  eventFromLicense = (license: CollaboratorLicense): TActividadEvent => {
    const start = license.getStart().toFormat('yyyy-LL-dd');
    let newEnd = license.getEnd().toJSDate();
    newEnd.setDate(newEnd.getDate() + 1);
    const end = DateTime.fromJSDate(newEnd).toFormat('yyyy-LL-dd');

    return {
      title: license.getReason(),
      start: start,
      end: end,
      display: 'background',
      backgroundColor: '#4effac', //Info (aqua)
      borderColor: '#4effac', //Info (aqua)
      color: '#4effac',
      extendedProps: {
        data: {
          id: 'LicenseDay',
          checked: false,
          allChecked: false,
          documentsPending: 0,
          eliminada: false,
          fromOutlook: false,
          idOutlook: undefined,
          aceptoTag: false,
          isNew: false,
          esOwner: true,
          canEdit: false,
          isNewFromOutlook: false,
          eventoOutlookEstaOculta: false,
          proyecto: undefined,
          act_inicio: '',
          act_fin: '',
          act_replicada_de_actid: '',
          cantDocumentosCargados: 0,
          colaboradorActividadList: [],
          isLicenseDay: true,
        },
      },
    };
  };

  private async getNonWorkingDaysEvents(start: Date, end: Date, events: TActividadEvent[]) {
    const nonWorkingDays = await this.system.getNonWorkingDaysSystem().getNonWorkingDays(start, end);
    nonWorkingDays.forEach((nonWorkingDay) => {
      events.push(this.eventFromNonWorkingDaysEvents(nonWorkingDay));
      this.activityCounts.nonWorkingDay++;  
    });
  }

  eventFromNonWorkingDaysEvents = (nonWorkingDay: NonWorkingDay): TActividadEvent => {
    const start = nonWorkingDay.getDate().toFormat('yyyy-LL-dd');
    let newEnd = nonWorkingDay.getDate().toJSDate();
    newEnd.setDate(newEnd.getDate() + 1);
    const end = DateTime.fromJSDate(newEnd).toFormat('yyyy-LL-dd');

    return {
      title: t('Feriado'),
      start: start,
      end: end,
      display: 'background',
      backgroundColor: '#4effac', //Info (aqua)
      borderColor: '#4effac', //Info (aqua)
      color: '#4effac',
      extendedProps: {
        data: {
          id: 'Feriado',
          checked: false,
          allChecked: false,
          documentsPending: 0,
          eliminada: false,
          fromOutlook: false,
          idOutlook: undefined,
          aceptoTag: false,
          isNew: false,
          esOwner: true,
          canEdit: false,
          isNewFromOutlook: false,
          eventoOutlookEstaOculta: false,
          proyecto: undefined,
          act_inicio: '',
          act_fin: '',
          act_replicada_de_actid: '',
          cantDocumentosCargados: 0,
          colaboradorActividadList: [],
          isLicenseDay: false,
          isNonWorkingDay: true,
        },
      },
    };
  };
}
