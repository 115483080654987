import { Icon, Text } from '@fluentui/react';
import React from 'react';
import DialogTemplate from '../Common/DialogTemplate';
import { useTranslation, Trans } from 'react-i18next';

export interface CalendarEventChangeDateDialogProps {
  show: boolean;
  onClose: (accept: boolean) => void;
}

export const CalendarEventChangeDateDialog = (props: CalendarEventChangeDateDialogProps) => {
  const { t, i18n } = useTranslation();

  const cancel = () => {
    props.onClose(false);
  };

  const accept = () => {
    props.onClose(true);
  };

  return (
      <DialogTemplate
          showDialog={props.show}
          toggleShowDialog={() => props.onClose(false)}
          title={t('Attention! Do you want to change the date of the activity?')}
          onAccept={accept}
          onCancel={cancel}
          acceptText={t('Confirm')}
          cancelText={t('Cancel')}
          minWidth={600}
      >
        <div>
          <Trans>
            <Text block variant="medium">
              <Icon iconName="Warning" className="text-icon" />
              {t("All team members will be notified that the date has been changed and must reconfirm the activity")}
            </Text>
          </Trans>
        </div>
      </DialogTemplate>
  );
};
