import React, { useState } from 'react';
import {
  DefaultButton,
  IButtonStyles,
  IContextualMenuItem,
  IContextualMenuItemStyles,
  IContextualMenuProps,
} from '@fluentui/react';
import { Text } from '@fluentui/react/lib/Text';

import TimeTaxSupportSystemClient from '../../system';
import MessageCourier from '../../lib/MessageCourier';
import useAsyncDataCourier from '../../common/custom-hooks/useAsyncDataCourier';
import Project from '../../system/projects/Project';
import { useTranslation } from 'react-i18next';
import './DropdownGroupedItem.css';
interface IBasicInputsView {
  system: TimeTaxSupportSystemClient;
  courier: MessageCourier;
  onSelectProject?: (id: string) => void;
  enableAll?: boolean;
  firstGroupShowed?: boolean; //Boolean que muestra el primer grupo sin estar adentro del grupo
  showGroup?: string; // Nombre del grupo que se quiere mostrar sin estar adentro del grupo
  defaultDropdownName?: string; //Texto que aparece en el dropdown
  selectedProjectId?: string;
}

export const ProjectSelector = (props: IBasicInputsView) => {
  const projects = useAsyncDataCourier(() => props.system.getProjects(), [props.system], props.courier);
  const [selection, setSelection] = React.useState<{ [key: string]: boolean }>({});
  const [selectName, setSelectName] = useState(
      props.defaultDropdownName ? props.defaultDropdownName : 'Proyectos'
  );
  const [selectedProjectId, setSelectedProjectId] = useState<string>('');

  const { t, i18n } = useTranslation();

  const onToggleSelect = React.useCallback(
      (/*ev?: React.MouseEvent<HTMLButtonElement>, */ item?: IContextualMenuItem): void => {
        // ev && ev.preventDefault();

        if (item) {
          setSelection({
            ...selection,
            [item.key]: selection[item.key] === undefined ? true : !selection[item.key],
          });
          setSelectName(item.text ? item.text : item.key);
        }
      },
      [selection]
  );

  React.useEffect(() => {
    if (selection) {
      let projectsSelected = Object.keys(selection);
      projectsSelected.forEach((idProject) => {
        if (selection[idProject]) {
          setSelectedProjectId(idProject);
        }
      });
    }
  }, [selection]);

  React.useEffect(() => {
    if (props.selectedProjectId !== undefined) {
      let project = projects?.find((project) => project.getId() === props.selectedProjectId);
      if (project) {
        setSelectName(project.getCode());
      } else {
        if (props.enableAll) setSelectName(t('All'));
        else if (props.defaultDropdownName) setSelectName(props.defaultDropdownName);
      }
    }
  }, [props.selectedProjectId]);

  React.useEffect(() => {
    if (selectedProjectId && props.onSelectProject) {
      props.onSelectProject(selectedProjectId);
    }
  }, [selectedProjectId]);

  React.useEffect(() => {
    if (props.enableAll && !selectedProjectId) setSelectName(t('All'));
  }, [props.enableAll]);

  const getProjectYear = (code: string) => {
    let idx = code.indexOf('-');
    let year = code.substr(idx - 4, 4);
    return year;
  };

  const [groupedProjects, setGroupedProjects] = React.useState<{ [string: string]: any }>([]);
  const [contexttualMenuItems, setContexttualMenuItems] = React.useState<IContextualMenuItem[]>([]);

  React.useEffect(() => {
    let _groupedProjects: any = {};
    if (projects) {
      let anio;
      projects.forEach((project) => {
        anio = getProjectYear(project.getCode());
        if (!_groupedProjects[anio]) {
          _groupedProjects[anio] = [];
        }
        _groupedProjects[anio].push(project);
      });
    }

    setGroupedProjects(_groupedProjects);
  }, [projects]);

  React.useEffect(() => {
    setContexttualMenuItems(generateYearItems());
  }, [groupedProjects]);

  const generateYearItems = () => {
    let currentYear = new Date().getFullYear();
    let _groupedProjects = { ...groupedProjects };
    let items: IContextualMenuItem[] = [];

    if (props.enableAll) {
      items.push({
        key: 'todos',
        text: t('All'),
        _onClick: onToggleSelect,
        onRender: (item: any, dismissMenu: (ev?: any, dismissAll?: boolean | undefined) => void) => {
          return (
              <div
                  onClick={() => {
                    onToggleSelect(item);
                    dismissMenu();
                  }}
                  className={item.secondaryText ? 'principal-item with-secondary' : 'principal-item '}
              >
                <Text className="d-block">{item.text}</Text>
                <Text className="d-block secondary-text-dropdown">{item.secondaryText}</Text>
              </div>
          );
        },
      });
    }

    if (_groupedProjects && _groupedProjects[currentYear.toString()]) {
      _groupedProjects[currentYear.toString()].forEach((project: Project) => {
        let it: IContextualMenuItem = {
          key: project.getId(),
          text: project.getCode(),
          secondaryText: project.getName(),
          _onClick: onToggleSelect,
          onRender: (item: any, dismissMenu: (ev?: any, dismissAll?: boolean | undefined) => void) => {
            return (
                <div
                    onClick={() => {
                      onToggleSelect(item);
                      dismissMenu();
                    }}
                    className={item.secondaryText ? 'principal-item with-secondary' : 'principal-item '}
                >
                  <Text className="d-block">{item.text}</Text>
                  <Text className="d-block secondary-text-dropdown">{item.secondaryText}</Text>
                </div>
            );
          },
        };

        items.push(it);
      });
    }

    let years = Object.keys(_groupedProjects);

    years.forEach((year: string) => {
      if (+year !== currentYear) {
        let projectsByYear = _groupedProjects[year].map((project: Project) => {
          return {
            key: project.getId(),
            text: project.getCode(),
            secondaryText: project.getName(),
            _onClick: onToggleSelect,
            onRender: (item: any, dismissMenu: (ev?: any, dismissAll?: boolean | undefined) => void) => {
              return (
                  <div
                      onClick={() => {
                        onToggleSelect(item);
                        dismissMenu(null, true);
                      }}
                      className={item.secondaryText ? 'principal-item with-secondary' : 'principal-item '}
                  >
                    <Text className="d-block">{item.text}</Text>
                    <Text className="d-block secondary-text-dropdown">{item.secondaryText}</Text>
                  </div>
              );
            },
          };
        });

        items.push({
          key: year,
          text: year,
          subMenuProps: { items: projectsByYear },
        });
      }
    });
    return items;
  };

  const stylesContextual: IContextualMenuItemStyles = {
    item: {
      color: 'pink',
    },
  };

  const menuProps: IContextualMenuProps = React.useMemo(
      () => ({
        shouldFocusOnMount: true,
        items: contexttualMenuItems,
      }),
      [contexttualMenuItems, stylesContextual]
  );

  const stylesDropdownGroupped: IButtonStyles = {
    root: {
      minWidth: '-webkit-fill-available',
      textAlign: 'left',
      padding: '0px 3px 0px 8px',
      fontWeight: 400,
    },
    label: {
      fontWeight: 400,
      marginLeft: 0,
    },
  };

  return (
      <div>
        <DefaultButton styles={stylesDropdownGroupped} text={selectName} menuProps={menuProps} />
      </div>
  );
};
