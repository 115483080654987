import React from 'react';
import {
  Facepile,
  Icon,
  IDropdownOption,
  IFacepilePersona,
  Persona,
  PersonaSize,
  DocumentCardActivity,
  IDocumentCardActivityPerson,
} from '@fluentui/react';
import { GRID_MODE } from '../../../common/list-utils';
import { DateCustom } from '../../../lib/DateCustom';
import { DetailsListView, EntityGridListProps } from '../../Common/DetailsList/DetailListView';
import i18next, { t } from 'i18next';

export const MailGridList = (props: EntityGridListProps) => {
  const columns = [
    {
      fieldName: 'mail_subject',
      name: t('Asunto'),
      _onRender: (item) => {
        return (
            <div className="text-overflow" title={item.mail_subject}>
              {item.tiene_docs && <Icon iconName="Attach" className="text-icon-attach fw-500"></Icon>}
              {item.mail_subject}
            </div>
        );
      },
    },
    { fieldName: 'mail_from', name: t('De') },
    { fieldName: 'mail_to', name: t('Para') },
    { fieldName: 'emp_nombre', name: t('Empresa') },
    { fieldName: 'pro_nombre', name: t('Proyecto') },
    { fieldName: 'mail_fecha', name: t('Fecha') },
  ];

  return (
      <>
        <DetailsListView
            keyList={'mails'}
            onClickCardMode={() => props.onClickCardMode()}
            onClickGridMode={() => props.onClickGridMode()}
            mode={props.mode}
            title={t('Mails')}
            onItemClick={props.onItemClick}
            cols={columns}
            isGridList={true}
            listFilters={[]}
            quickFilters={props.quickFilters}
            baseUrl={props.baseUrl}
            changeBlockUI={props.changeBlockUI}
            courier={props.courier}
        ></DetailsListView>
      </>
  );
};
