import { Icon, Text } from '@fluentui/react';
import React from 'react';
import DialogTemplate from '../../Common/DialogTemplate';
import { useTranslation, Trans } from 'react-i18next';

export interface SearchForDocumentControlDialogProps {
  show: boolean;
  onClose: (accept: boolean) => void;
  concept: string;
}

export const SearchForDocumentControlDialog = (props: SearchForDocumentControlDialogProps) => {
  const { t, i18n } = useTranslation();

  const cancel = () => {
    props.onClose(false);
  };

  const accept = () => {
    props.onClose(true);
  };

  return (
      <DialogTemplate
          showDialog={props.show}
          toggleShowDialog={() => props.onClose(false)}
          title={t('Buscar documento obligatorio')}
          onAccept={accept}
          onCancel={cancel}
          acceptText={t('Confirm')}
          cancelText={t('Cancel')}
          minWidth={600}
      >
        <div>
          <Text block variant="medium">
            <Icon iconName="Search" className="text-icon" />
            {t('Quieres buscar el documento obligatorio ') + props.concept + t(' en la sección de documentos?')}
          </Text>
        </div>
      </DialogTemplate>
  );
};
