import {
  CheckboxVisibility,
  DefaultButton,
  DefaultPalette,
  DetailsList,
  DetailsListLayoutMode,
  Dropdown,
  IColumn,
  Icon,
  IconButton,
  IContextualMenuProps,
  IIconProps,
  PrimaryButton,
  Text,
  TextField,
} from '@fluentui/react';
import { ICommandBarItemProps } from '@fluentui/react/lib/CommandBar';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { routes } from '../../../common/constants';
import MessageCourier from '../../../lib/MessageCourier';
import { defaultCommandBarButtonStyles, titleCommandBarButtonStyles } from '../../../styles/button-styles';
import TimeTaxSupportSystemClient from '../../../system';
import SystemUploadedDocument from '../../../system/documents/SystemUploadedDocument';
import { Settlement } from '../../../system/settlement/Settlement';
import { SettlementProjectInPeriod } from '../../../system/settlement/SettlementProjectInPeriod';
import { SettlementProjectInPeriodItem } from '../../../system/settlement/SettlementProjectInPeriodItem';
import { SettlementRelatedDocument } from '../../../system/settlement/SettlementRelatedDocument';
import ViewWrapperWithCommandBar from '../../Layout/ViewWrapperWithCommandBar';
import { useTranslation } from 'react-i18next';
import {
  ColumnGroupedDetailsList,
  GroupedDetailList,
} from '../../Common/GroupedDetailList/GroupedDetailList';
import { formatNumberToLocale } from '../../../common/list-utils';
import { ProjectStatus } from '../../Common/ProjectStatus';
import { DocumentTable } from './Wizard/DocumentTable';
import { DefenseFileBySettlementUploadedDocument } from '../../../system/defense_file/DefenseFileBySettlementUploadedDocument';
import { DefenseFileBySettlement } from '../../../system/defense_file/DefenseFileBySettlement';

interface IMassiveDefenseDetail {
  system: TimeTaxSupportSystemClient;
  courier: MessageCourier;
  changeBlockUI: (v: boolean) => void;
}

export interface ISettlementItemsGrouped {
  ldi_sector: string;
  ldi_periodo: string;
  promedio_costo_hora: string;
  ldi_horas_confirmadas: string;
  ldi_importe_costo_neto: string;
  ldi_importe_costo_total: string;
  concept: string;
}

interface IFormattedGroups {
  key: string;
  name: string;
  startIndex: number;
  count: number;
  level?: number;
  isCollapsed?: boolean;
}

const MassiveDefenseDetail = (props: IMassiveDefenseDetail) => {
  const [uploadedDocuments, setUploadedDocuments] = useState<DefenseFileBySettlementUploadedDocument[]>();
  const [defenseGenerated, setDefenseGenerated] = useState<DefenseFileBySettlement>();

  const { t } = useTranslation();
  let { id } = useParams();
  const navigate = useNavigate();

  const GlobalNavButtonIcon: IIconProps = { iconName: 'GlobalNavButton' };

  useEffect(() => {
    if (id) {
      getDefenseFileBySettlementById(id);
      getDefenseFileBySettlementUploadedDocuments(id);
    }
  }, [id]);

  const getDefenseFileBySettlementById = async (id) => {
    if (id) {
      props.changeBlockUI(true);
      try {
        const response = await props.system.getDefenseFileSystem().getDefenseFileBySettlementById(id);
        if (response) {
          setDefenseGenerated(response);
          // props.courier.messageSuccess(t('La defensa fue generada exitosamente'));
        }
      } catch (error) {
        props.courier.messageError(error);
      } finally {
        props.changeBlockUI(false);
      }
    }
  };

  const downloadUploadedDocument = async (document: DefenseFileBySettlementUploadedDocument) => {
    if (document) {
      props.changeBlockUI(true);
      try {
        const response = await props.system.getDefenseFileSystem().downloadUploadedDocument(document);
        // if (response) {
        //   // setDefenseGenerated(response);
        //   props.courier.messageSuccess(t('La defensa fue generada exitosamente'));
        // }
      } catch (error) {
        props.courier.messageError(error);
      } finally {
        props.changeBlockUI(false);
      }
    }
  };

  const getDefenseFileBySettlementUploadedDocuments = async (id) => {
    if (id) {
      props.changeBlockUI(true);
      try {
        const response = await props.system
            .getDefenseFileSystem()
            .getDefenseFileBySettlementUploadedDocuments(id);
        if (response) {
          // setDefenseGenerated(response);
          setUploadedDocuments(response);
          // props.courier.messageSuccess(t('La defensa fue generada exitosamente'));
        }
      } catch (error) {
        props.courier.messageError(error);
      } finally {
        props.changeBlockUI(false);
      }
    }
  };

  const itemsMenuCalendar = React.useMemo(
      (): ICommandBarItemProps[] => [
        {
          key: 'title',
          text: t('Liquidaciones directas'),
          iconProps: {
            iconName: 'ReminderPerson',
            color: DefaultPalette.themeLighterAlt,
            className: 'text-title-btn',
          },
          buttonStyles: titleCommandBarButtonStyles,
          className: 'text-title-btn',
        },
        {
          key: 'back',
          text: t('Volver'),
          iconProps: {
            iconName: 'Back',
            color: DefaultPalette.themeLighterAlt,
            className: 'text-title-btn',
          },
          buttonStyles: titleCommandBarButtonStyles,
          className: 'text-title-btn',
          onClick: () => {
            navigate(`${routes.massive_defense_list}`);
          },
        },
        {
          key: 'modificar',
          text: t('Modificar'),
          iconProps: { iconName: 'Edit', color: DefaultPalette.themeLighterAlt },
          buttonStyles: titleCommandBarButtonStyles,
          disabled: true,
        },
      ],
      []
  );

  const dateToString = (date: Date) => {
    const dia = date.getDate().toString().padStart(2, '0');
    const mes = (date.getMonth() + 1).toString().padStart(2, '0');
    const anio = date.getFullYear();
    return `${dia}/${mes}/${anio}`;
  };

  const formatState = (state: string) => {
    const tState = t(state.charAt(0).toUpperCase() + state.slice(1));
    return tState;
  };

  return (
      <ViewWrapperWithCommandBar
          withBorderBottom
          title={''}
          items={itemsMenuCalendar}
          overflowButtonStyles={defaultCommandBarButtonStyles}
          variant="highligth"
          titleBody={t('Liquidaciones')}
      >
        <div className="padding-1">
          <div className="ms-Grid bg-white  padding-1 " dir="ltr">
            <div className="ms-Grid-row">
              <div className="ms-Grid-col ms-lg6 ms-sm6">
                <Text variant="large" className="fw-500">
                  {t('Detalle de archivo de defensa masiva: ') + id}
                </Text>
                <div className="ms-Grid-row mt-2">
                  <div className="ms-Grid-col ms-lg12 ms-sm12">
                    {' '}
                    <Text variant="medium">
                      <Icon iconName="PublicCalendar" className="text-icon"></Icon>
                      {t('Inicio') + ': '}
                      <span className="fw-500">
                      {defenseGenerated ? dateToString(defenseGenerated.getFrom()) : null}
                    </span>
                    </Text>
                  </div>
                  <div className="ms-Grid-col ms-lg12 ms-sm12 mt-1">
                    {' '}
                    <Text variant="medium">
                      <Icon iconName="PublicCalendar" className="text-icon"></Icon>
                      {t('Fin') + ': '}
                      <span className="fw-500">
                      {defenseGenerated ? dateToString(defenseGenerated.getTo()) : null}
                    </span>
                    </Text>
                  </div>
                  <div className="ms-Grid-col ms-lg12 ms-sm12 mt-1">
                    {' '}
                    <Text variant="medium">
                      <Icon iconName={'AwayStatus'} className="text-icon"></Icon>
                      {t('Estado: ')}
                      <span className="fw-500">
                      {defenseGenerated ? formatState(defenseGenerated.getState()) : null}
                    </span>
                    </Text>{' '}
                  </div>
                  <div className="ms-Grid-col ms-lg12 ms-sm12 mt-1">
                    {' '}
                    <Text variant="medium">
                      <Icon iconName={'CityNext2'} className="text-icon"></Icon>
                      {t('Empresas') + ': '}
                      <span className="fw-500">{t('Todas')}</span>
                    </Text>{' '}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {uploadedDocuments && uploadedDocuments.length > 0 ? (
            <DocumentTable
                system={props.system}
                courier={props.courier}
                // setButtonDisability={setButtonDisability}
                dataTable={uploadedDocuments}
                downloadUploadedDocument={downloadUploadedDocument}
            />
        ) : null}
      </ViewWrapperWithCommandBar>
  );
};

export default MassiveDefenseDetail;
