import React, { useEffect, useState } from 'react';
import { Text } from '@fluentui/react/lib/Text';
import useSystemCall from '../../../common/custom-hooks/useSystemCall';
import useAsyncDataCourier from '../../../common/custom-hooks/useAsyncDataCourier';
import { ActionButton, CompoundButton, PrimaryButton } from '@fluentui/react/lib/Button';
import useAsyncData from '../../../common/custom-hooks/useAsyncData';
import { Icon } from '@fluentui/react';
import { defenseFileAssistantDropdownValues, routes } from '../../../common/constants';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';

const Ending = (props: any) => {
  const { t, i18n } = useTranslation();
  const [defenseFileResponse, setDefenseFileResponse] = useState<any>();
  const [defenseFileById, setDefenseFileById] = useState<any>();
  const [defenseFileStructure, setDefenseFileStructure] = useState<any>();
  const [defenseFileZip, setDefenseFileZip] = useState<any>();
  const [downloadDefenseFile, setDownloadDefenseFile] = useState<any>();
  const navigate = useNavigate();

  const GetDefenseFile = useSystemCall(props.system, (system) =>
      props.system && defenseFileResponse && defenseFileResponse.id
          ? props.system.defenseFileSystem.getById(defenseFileResponse.id)
          : null
  );

  // const GenerateStructure = useAsyncData(
  //   () => props.system.defenseFileSystem.generateStructure(defenseFileById),
  //   [defenseFileById, props.system.defenseFileSystem]
  // );

  // const GetDefenseFileZip = useAsyncData(
  //   () => props.system.defenseFileSystem.generateZippedFile(defenseFileById),
  //   [defenseFileById, props.system.defenseFileSystem]
  // );

  // const DownloadDefenseFile = useAsyncData(
  //   () => props.system.defenseFileSystem.downloadDefenseFileZipped(defenseFileResponse.id),
  //   [defenseFileResponse, props.system.defenseFileSystem]
  // );

  const GenerateProjectFile = useSystemCall(props.system, (system) =>
      props.data
          ? props.system.defenseFileSystem.generateForProjects(
              props.data.fromDate,
              props.data.toDate,
              props.data.company,
              props.data.activityReport,
              props.data.selectedProjectsId,
              props.data.requirementData
          )
          : null
  );

  const GenerateSettlementsFile = useSystemCall(props.system, (system) =>
      props.data
          ? props.system.defenseFileSystem.generateForSettlements(
              props.data.fromDate,
              props.data.toDate,
              props.data.company,
              props.data.activityReport,
              props.data.selectedProjectsId,
              props.data.requirementData
          )
          : null
  );
  useEffect(() => {
    if (props.type === defenseFileAssistantDropdownValues.forProjects) {
      setDefenseFileResponse(GenerateProjectFile);
    } else if (props.type === defenseFileAssistantDropdownValues.forIndirectsSettlements) {
      setDefenseFileResponse(GenerateSettlementsFile);
    }
  }, [GenerateProjectFile, GenerateSettlementsFile, props.type]);

  useEffect(() => {
    if (defenseFileResponse) {
      setDefenseFileById(defenseFileResponse);
    }
  }, [GetDefenseFile, defenseFileResponse]);

  // useEffect(() => {
  //   if (GetDefenseFileZip) {
  //     setDefenseFileZip(GetDefenseFileZip);
  //   }
  // }, [GetDefenseFileZip, defenseFileById, defenseFileResponse]);

  // useEffect(() => {
  //   if (defenseFileById) {
  //     setDefenseFileStructure(GenerateStructure);
  //   }
  // }, [GenerateStructure, defenseFileResponse, defenseFileById]);

  // useEffect(() => {
  //   if (DownloadDefenseFile) {
  //     setDownloadDefenseFile(DownloadDefenseFile);
  //   }
  // }, [DownloadDefenseFile, defenseFileResponse]);

  return (
      <div className="padding-1">
        {defenseFileResponse ? (
            <>
              <Text>
                <Icon iconName="CheckMark" className="text-icon success-color"></Icon>
                {t('Se ha generado el archivo de defensa ') +
                    (defenseFileResponse ? defenseFileResponse.id : null)}
              </Text>

              <br />
              <PrimaryButton
                  className="mt-2"
                  onClick={() => navigate(`${routes.defense_file}/${defenseFileResponse.id}`)}
              >
                {t('Ver detalle')}
              </PrimaryButton>
            </>
        ) : null}
      </div>
  );
};

export default Ending;
