import AssertionEntity from '../../../lib/FormValidator/Assertion/AssertionEntity';
import CustomForm from '../../../lib/FormValidator/Form/CustomForm';
import CustomInput from '../../../lib/FormValidator/Form/CustomInput';
import CustomList from '../../../lib/FormValidator/Form/CustomList';
import { Company } from '../../../system/company/Company';
import { ProjectCompanyInDistribution } from '../../../system/projects/IProjectCompanyInDistribution';
import { ProjectCompany } from '../../../system/projects/ProjectCompany';
import { ProjectCompanyDistribution } from '../../../system/projects/ProjectCompanyDistribution';
import { ProjectCompanyDistributionAssertions } from '../../../system/projects/ProjectCompanyDistributionAssertions';
import ProjectCompanySaved from '../../../system/projects/ProjectCompanySaved';
import ProjectCompanyForm from './ProjectCompanyForm';

export class ProjectCompanyListForm extends CustomList<ProjectCompanyInDistribution> {
  private projectCompanyForms: ProjectCompanyForm[];

  constructor(propertyName: string, items: ProjectCompanyInDistribution[], assertionEntity: AssertionEntity) {
    super(propertyName, items, assertionEntity);
    this.projectCompanyForms = this.items.map((item) =>
        ProjectCompanyForm.with(item.getPercentage(), item.getCompany())
    );
  }

  getActive(): ProjectCompanyInDistribution[] {
    return this.items.filter((company) => company.isActive());
  }

  getFormFor(projectCompany: ProjectCompanyInDistribution): ProjectCompanyForm {
    const idx = this.items.findIndex((item) => item === projectCompany);
    return this.projectCompanyForms[idx];
  }

  delete(projectCompany: ProjectCompanyInDistribution) {
    projectCompany.delete(this);
    super.evaluate();
  }

  add(projectCompany: ProjectCompanyInDistribution) {
    const companyToAdd = this.items.find((item) => item.IsForSameCompanyAs(projectCompany));
    if (companyToAdd) {
      companyToAdd.addTo(this, projectCompany);
    } else {
      super.add(projectCompany);
      this.projectCompanyForms.push(ProjectCompanyForm.with(0, projectCompany.getCompany()));
    }
  }

  deleteProjectCompany(projectCompany: ProjectCompany) {
    let idx = this.projectCompanyForms.findIndex((item) => {
      return item.getEmpresaInput().getValue().getId() === projectCompany.getCompany().getId();
    });
    if (idx > -1) {
      this.projectCompanyForms.splice(idx, 1);
    }
    super.delete(projectCompany);
  }

  deleteProjectCompanySaved(projectCompany: ProjectCompanySaved) {
    projectCompany.set__delete(true);
  }

  addProjectCompany(projectCompany: ProjectCompany, companyToAdd: ProjectCompanyInDistribution) {
    this.delete(projectCompany);
    super.add(companyToAdd);
  }

  addProjectCompanySaved(projectCompany: ProjectCompanySaved, companyToAdd: ProjectCompanyInDistribution) {
    projectCompany.set__delete(false);
    projectCompany.setPercentage(companyToAdd.getPercentage());
  }

  getCompaniesSelected() {
    const ret: Company[] = [];
    this.items.forEach((item) => {
      item.collectCompanyInto(ret);
    });
    return ret;
  }

  hasCompany(aCompany: Company) {
    return this.getCompaniesSelected().some((companySelected) => companySelected.isIdentifiedAs(aCompany));
  }

  calculatePercentage(): number {
    return this.items.reduce((partialSum, com) => com.affectTotalPercentage(partialSum), 0);
  }

  roundNumber(num): number {
    let roundedNum = Math.floor(num * 100) / 100;
    return roundedNum;
  }

  uniformAssign(): ProjectCompanyInDistribution[] {
    const companiesSelected = this.getCompaniesSelected();
    const percentage = 100 / companiesSelected.length;
    let remainingPercentage = 100;
    this.items.forEach((item, index) => {
      if (index < companiesSelected.length - 1) {
        item.setPercentage(this.roundNumber(percentage));
        this.getFormFor(item).setPercentage(this.roundNumber(percentage));
        remainingPercentage -= this.roundNumber(percentage);
      } else {
        item.setPercentage(this.roundNumber(remainingPercentage));
        this.getFormFor(item).setPercentage(this.roundNumber(remainingPercentage));
      }
    });
    return this.getActive();
  }

  resetAllCompanies() {
    this.setValueToDefault();
    this.projectCompanyForms = [];
    return [];
  }

  syncWithForm(projectCompany: ProjectCompanyInDistribution) {
    const form = this.getFormFor(projectCompany);
    if (form.isValid()) {
      projectCompany.setPercentage(Number(form.getPercentageDistributionInput().getValue()));
    }

    this.onBlur();
  }
}

class ProjectConfigurationDistributionForm extends CustomForm {
  private projectCompanyList: ProjectCompanyListForm;

  readonly COMPANY_DISTRIBUTION_ITEMS = 'companies';

  protected constructor(companies: ProjectCompanyInDistribution[], billableInput: CustomInput) {
    const cda = ProjectCompanyDistributionAssertions.buildForForm(billableInput);
    super('Company Distribution', cda);

    this.projectCompanyList = new ProjectCompanyListForm(this.COMPANY_DISTRIBUTION_ITEMS, companies, cda);
    this.addListInput(this.projectCompanyList);
  }

  static initialize(billableInput: CustomInput) {
    const instance = new this([], billableInput);
    return instance;
  }

  static with(companies: ProjectCompanyInDistribution[], billableInput: CustomInput) {
    const instance = new this(companies, billableInput);
    return instance;
  }

  toProjectCompanyDistribution(billable: boolean): ProjectCompanyDistribution {
    const list = this.getInputDistribucionFacturableItems().getValue();
    return ProjectCompanyDistribution.forCompanies([...list], billable);
  }

  getInputDistribucionFacturableItems(): ProjectCompanyListForm {
    return this.projectCompanyList;
  }

  uniformAssignment(): ProjectCompanyInDistribution[] {
    return this.projectCompanyList.uniformAssign();
  }

  resetAllCompanies() {
    return this.projectCompanyList.resetAllCompanies();
  }
}

export default ProjectConfigurationDistributionForm;
