import * as React from 'react';
import { Checkbox } from '@fluentui/react';
import TimeTaxSupportSystemClient from '../../../system';
import { DateTime } from 'luxon';
import MessageCourier from '../../../lib/MessageCourier';
import { IReporteGralHoras } from '../../../system/reporting/ReportingSystem';
import { useTranslation } from 'react-i18next';
import DataGrid, {
  Scrolling,
  Column,
  Summary,
  SortByGroupSummaryInfo,
  Export,
  SearchPanel,
  TotalItem,
} from 'devextreme-react/data-grid';
import { Panel } from '@fluentui/react/lib/Panel';
import { useBoolean } from '@fluentui/react-hooks';
import { User } from '../../../system/User';
import { EscalableExporterOptions, exportToExcel } from '../../../lib/Util';
import { ExportingEvent } from 'devextreme/ui/data_grid';
import { Button } from 'devextreme-react/button';

interface IReportingSettlementHoursSector {
  system: TimeTaxSupportSystemClient;
  courier: MessageCourier;
  changeBlockUI: (state: boolean) => void;
  user: User;
  items: IReporteGralHoras[];
  from: DateTime;
  to: DateTime;
  resetTableAfterExport: () => void;
}

interface ISelectableColumns {
  label: string;
  key: string;
  show: boolean;
}

const DIRECCION = 'direccion';
const GERENCIA = 'gerencia';
const JEFATURA = 'jefatura';
const COLABORADOR_NOMBRE = 'colaborador_nombre';
const CARGO_NOMBRE = 'cargo_nombre';
const COLABORADOR_EMAIL = 'colaborador_email';
const COLABORADOR_SAP_ID = 'colaborador_sap_id';
const HORAS = 'horas';
const CANT_HORAS_ESPERADAS = 'cant_horas_esperadas';
const PORCENTAJE_CARGA = 'porcentaje_carga';
const HORAS_ESPERADAS_DIASLABORALES = 'horas_esperadas_diasLaborales';
const HORAS_ESPERADAS_PLANTILLA_COLPLAN_HORAS_DIARIAS = 'horas_esperadas_plantilla_colplan_horas_diarias';
const HORAS_ESPERADAS_LICENCIAS = 'horas_esperadas_licencias';
const COLABORADOR_FECHA_BAJA = 'colaborador_fecha_baja';
const COLABORADOR_FECHA_ALTA = 'colaborador_fecha_alta';
const NO_INFORMA_MOTIVO = 'no_informa_motivo';
const MODALIDAD = 'modalidad';
const VALOR_AGREGADO = 'valor_agregado';
const CARGO_DESDE = 'cargo_desde';
const CARGO_HASTA = 'cargo_hasta';

export const ReportHoursSectorDetail = (props: IReportingSettlementHoursSector) => {
  const { t } = useTranslation();
  const [columns, setColumns] = React.useState<ISelectableColumns[]>([
    { label: 'Direccion', key: DIRECCION, show: true },
    { label: 'Gerencia', key: GERENCIA, show: true },
    { label: 'Jefatura', key: JEFATURA, show: true },
    { label: 'Colaborador', key: COLABORADOR_NOMBRE, show: true },
    { label: 'Cargo', key: CARGO_NOMBRE, show: true },
    { label: 'Email', key: COLABORADOR_EMAIL, show: true },
    { label: 'SAP ID', key: COLABORADOR_SAP_ID, show: true },
    { label: 'Valor agregado', key: VALOR_AGREGADO, show: true },
    { label: 'Horas cargadas', key: HORAS, show: true },
    { label: 'Horas esperadas', key: CANT_HORAS_ESPERADAS, show: true },
    { label: 'Porcentaje de carga', key: PORCENTAJE_CARGA, show: true },
    { label: 'Dias laborales', key: HORAS_ESPERADAS_DIASLABORALES, show: true },
    { label: 'Plantilla horaria', key: HORAS_ESPERADAS_PLANTILLA_COLPLAN_HORAS_DIARIAS, show: true },
    { label: 'Licencias', key: HORAS_ESPERADAS_LICENCIAS, show: true },
    { label: 'Fecha de baja', key: COLABORADOR_FECHA_BAJA, show: true },
    { label: 'Fecha de alta', key: COLABORADOR_FECHA_ALTA, show: true },
    { label: 'No informa horas', key: NO_INFORMA_MOTIVO, show: false },
    { label: 'Modalidad - No informa horas', key: MODALIDAD, show: true },
    { label: 'Período desde', key: CARGO_DESDE, show: true },
    { label: 'Período hasta', key: CARGO_HASTA, show: true },
  ]);

  const onExporting = async (e: ExportingEvent<IReporteGralHoras, any>) => {
    let options: EscalableExporterOptions = {
      title: `Reporte de horas de ${props.from?.toFormat('dd-LL-yyyy')} a ${props.to?.toFormat(
          'dd-LL-yyyy'
      )}`,
      fileName: `reporte-horas-sector-de-${props.from?.toFormat('dd-LL-yyyy')}-a-${props.to?.toFormat(
          'dd-LL-yyyy'
      )}`,
      sheetName: t('Reporte de horas por sector'),
      customizeCell: (options) => {
        if (options.gridCell?.rowType === 'header') {
          options.excelCell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'CF1B2C' } };
          options.excelCell.font = {
            color: { argb: 'FFFFFFFF' }, // Color de letra blanco en formato ARGB
            bold: true, // Texto en negrita
          };
        }

        if (options.gridCell?.rowType === 'totalFooter' && options.excelCell.value) {
          options.excelCell.font.italic = true;
        }

        if (options.gridCell?.rowType === 'data') {
          if (options.gridCell?.column?.dataField === 'licencias_tomadas') {
            let strLic = '';
            if (Array.isArray(options.gridCell?.data.horas_esperadas.licencias)) {
              options.gridCell?.data.horas_esperadas.licencias?.forEach((lic) => {
                if (strLic !== '') strLic += ' + ';
                strLic += `${lic.licenciaTipo.lictipo_descripcion} - ${lic.collic_motivo} (${lic.collic_dias_lic} días)`;
              });
            }
            options.excelCell.value = strLic;
          }
          if (options.gridCell?.column?.dataField === 'horas_esperadas.diasLaborales') {
            options.excelCell.value = options.gridCell?.value.length;
          }
        }
      },
      itemsCount: props.items.length,
    };

    await exportToExcel(e, options);
    props.resetTableAfterExport();
  };

  const getVisible = (key) => {
    const finded = columns.find((_col) => _col.key === key);
    return finded ? finded.show : false;
  };

  const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false);

  const markAll = () => {
    const cols = [...columns];
    cols.forEach((col) => {
      col.show = true;
    });
    setColumns(cols);
  };
  const unmarkAll = () => {
    const cols = [...columns];
    cols.forEach((col) => {
      col.show = false;
    });
    setColumns(cols);
  };

  const EllipsisHeader = ({ column }) => {
    const headerText = column.caption;
    return (
        <div style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>{headerText}</div>
    );
  };

  return (
      <div className="ms-Grid" dir="ltr">
        <Button
            className="btn-dinamic-table"
            id="dinamic-btn"
            icon="inserttable"
            onClick={openPanel} // Otras propiedades personalizadas
        />

        <Panel
            headerText="Tabla dinamica"
            isOpen={isOpen}
            onDismiss={dismissPanel}
            // You MUST provide this prop! Otherwise screen readers will just say "button" with no label.
            closeButtonAriaLabel="Close"
        >
          <div>
            <p>Selecciona las columnas que quieres ver</p>
            <span className="d-block link-styles" onClick={markAll}>
            Marcar todas
          </span>
            <span className="d-block link-styles mb-2" onClick={unmarkAll}>
            Desmarcar todas
          </span>
            {columns.map((col, i) => {
              return (
                  <Checkbox
                      className="d-block mt-1"
                      key={i}
                      label={col.label}
                      checked={col.show}
                      onChange={() => {
                        let cols = [...columns];
                        const idx = cols.findIndex((_col) => _col.key === col.key);
                        cols[idx].show = !cols[idx].show;
                        setColumns(cols);
                      }}
                  />
              );
            })}
          </div>
        </Panel>

        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-sm12">
            <DataGrid
                height={620}
                width="100%"
                keyExpr={'colaborador_nombre'}
                dataSource={props.items}
                showBorders={true}
                wordWrapEnabled={true}
                onExporting={onExporting}
                rowAlternationEnabled={true}
                allowColumnResizing={true}
            >
              <Export enabled={true} />
              <SearchPanel visible={true} placeholder={t('Buscar...')} />

              <Scrolling mode="virtual" showScrollbar="always" useNative={false} />
              <Column
                  cellRender={(d) => {
                    return (
                        <div className="dx-datagrid-text-content dx-datagrid-group-text-content text-overflow">
                          {d?.value}
                        </div>
                    );
                  }}
                  dataField={DIRECCION}
                  caption={t('Direccion')}
                  width={150}
                  visible={getVisible(DIRECCION)}
              />
              <Column
                  cellRender={(d) => {
                    return (
                        <div className="dx-datagrid-text-content dx-datagrid-group-text-content text-overflow">
                          {d?.value}
                        </div>
                    );
                  }}
                  dataField={GERENCIA}
                  caption={t('Gerencia')}
                  width={150}
                  visible={getVisible(GERENCIA)}
              />
              <Column
                  cellRender={(d) => {
                    return (
                        <div className="dx-datagrid-text-content dx-datagrid-group-text-content text-overflow">
                          {d?.value}
                        </div>
                    );
                  }}
                  dataField={JEFATURA}
                  caption={t('Jefatura')}
                  width={150}
                  visible={getVisible(JEFATURA)}
              />
              <Column
                  cellRender={(d) => {
                    return (
                        <div className="dx-datagrid-text-content dx-datagrid-group-text-content text-overflow">
                          {d?.value}
                        </div>
                    );
                  }}
                  dataField={COLABORADOR_NOMBRE}
                  caption={t('Colaborador')}
                  width={250}
                  visible={getVisible(COLABORADOR_NOMBRE)}
              />
              <Column
                  cellRender={(d) => {
                    return (
                        <div className="dx-datagrid-text-content dx-datagrid-group-text-content text-overflow">
                          {d?.value}
                        </div>
                    );
                  }}
                  dataField={CARGO_NOMBRE}
                  caption={t('Cargo')}
                  visible={getVisible(CARGO_NOMBRE)}
                  width={250}
              />
              <Column
                  cellRender={(d) => {
                    return (
                        <div className="dx-datagrid-text-content dx-datagrid-group-text-content text-overflow">
                          {d?.value}
                        </div>
                    );
                  }}
                  dataField={COLABORADOR_EMAIL}
                  width={250}
                  caption={t('Email')}
                  visible={getVisible(COLABORADOR_EMAIL)}
              />
              <Column
                  alignment={'center'}
                  cellRender={(d) => {
                    return (
                        <div className="dx-datagrid-text-content dx-datagrid-group-text-content text-overflow">
                          {d?.value}
                        </div>
                    );
                  }}
                  dataField={COLABORADOR_SAP_ID}
                  width={110}
                  caption={t('Sap Id')}
                  visible={getVisible(COLABORADOR_SAP_ID)}
              />
              <Column
                  cellRender={(d) => {
                    return (
                        <div className="dx-datagrid-text-content dx-datagrid-group-text-content text-overflow">
                          {d?.value}
                        </div>
                    );
                  }}
                  dataField={VALOR_AGREGADO}
                  caption={t('Valor agregado')}
                  width={120}
                  visible={getVisible(VALOR_AGREGADO)}
              />
              <Column
                  dataField={HORAS}
                  caption={t('Hs confirmadas')}
                  width={130}
                  dataType="number"
                  alignment={'center'}
                  visible={getVisible(HORAS)}
                  cellRender={(d) => {
                    return (
                        <div className="dx-datagrid-text-content dx-datagrid-group-text-content text-overflow">
                          {d.value}
                        </div>
                    );
                  }}
              />
              <Column
                  dataField={CANT_HORAS_ESPERADAS}
                  caption={t('Hs esperadas')}
                  width={130}
                  dataType="number"
                  alignment={'center'}
                  visible={getVisible(CANT_HORAS_ESPERADAS)}
                  cellRender={(d) => {
                    // d.data tiene toda la info de la row
                    let value = 0;
                    if (d.value) value = Number(d.value);

                    return (
                        <div className="dx-datagrid-text-content dx-datagrid-group-text-content text-overflow">
                          {/* {d.value} */}
                          {value.toFixed(2)}
                        </div>
                    );
                  }}
              />

              <Column
                  alignment={'center'}
                  dataField={PORCENTAJE_CARGA}
                  caption={t('Carga (%)')}
                  width={110}
                  visible={getVisible(PORCENTAJE_CARGA)}
                  cellRender={(d) => {
                    // d.data tiene toda la info de la row
                    let value = 0;
                    if (d.value) value = Number(d.value);
                    return (
                        <div className="dx-datagrid-text-content dx-datagrid-group-text-content text-overflow">
                          {value.toFixed(2)}
                        </div>
                    );
                  }}
              />

              <Column
                  width={110}
                  dataField={'horas_esperadas.diasLaborales'}
                  caption={t('Cant. días laborales')}
                  cellRender={(d) => {
                    return (
                        <div className="dx-datagrid-text-content dx-datagrid-group-text-content">
                          {d.value.length}
                        </div>
                    );
                  }}
                  visible={getVisible(HORAS_ESPERADAS_DIASLABORALES)}
              />

              <Column
                  width={110}
                  dataField={'horas_esperadas.plantilla.colplan_horas_diarias'}
                  caption={t('Plantilla horaria (por día)')}
                  cellRender={(d) => {
                    return (
                        <div className="dx-datagrid-text-content dx-datagrid-group-text-content ">{d.value}</div>
                    );
                  }}
                  visible={getVisible(HORAS_ESPERADAS_PLANTILLA_COLPLAN_HORAS_DIARIAS)}
              />
              <Column
                  width={110}
                  dataField={'licencias_tomadas'}
                  caption={t('Licencias')}
                  cellRender={(d) => {
                    let strLic = '';
                    if (d && Array.isArray(d.data.horas_esperadas.licencias)) {
                      d.data.horas_esperadas.licencias?.forEach((lic) => {
                        if (strLic !== '') strLic += ' + ';
                        if (lic.licenciaTipo.lictipo_descripcion && lic.collic_motivo && lic.collic_dias_lic) {
                          strLic += `${lic.licenciaTipo.lictipo_descripcion} - ${lic.collic_motivo} (${lic.collic_dias_lic} días)`;
                        }
                      });
                    }
                    return (
                        <div className="dx-datagrid-text-content dx-datagrid-group-text-content text-overflow">
                          {strLic}
                        </div>
                    );
                  }}
                  visible={getVisible(HORAS_ESPERADAS_LICENCIAS)}
              />
              <Column
                  alignment={'center'}
                  dataField={COLABORADOR_FECHA_BAJA}
                  caption={t('Baja')}
                  visible={getVisible(COLABORADOR_FECHA_BAJA)}
                  width={110}
              />
              <Column
                  alignment={'center'}
                  dataField={COLABORADOR_FECHA_ALTA}
                  caption={t('Alta')}
                  visible={getVisible(COLABORADOR_FECHA_ALTA)}
                  width={110}
              />
              <Column
                  width={180}
                  dataField={NO_INFORMA_MOTIVO}
                  alignment={'left'}
                  caption={t('No informa horas - Motivo')}
                  visible={getVisible(NO_INFORMA_MOTIVO)}
                  cellRender={(d) => {
                    return (
                        <div className="dx-datagrid-text-content dx-datagrid-group-text-content text-overflow">
                          {d.value}
                        </div>
                    );
                  }}
              />

              <Column
                  width={110}
                  dataField={MODALIDAD}
                  alignment={'left'}
                  caption={t('Modalidad')}
                  visible={getVisible(MODALIDAD)}
                  cellRender={(d) => {
                    return (
                        <div className="dx-datagrid-text-content dx-datagrid-group-text-content text-overflow">
                          {d.value}
                        </div>
                    );
                  }}
              />

              <Column
                  alignment={'center'}
                  dataField={CARGO_DESDE}
                  caption={t('Período desde')}
                  visible={getVisible(CARGO_DESDE)}
                  width={110}
              />

              <Column
                  alignment={'center'}
                  dataField={CARGO_HASTA}
                  caption={t('Período hasta')}
                  visible={getVisible(CARGO_HASTA)}
                  width={110}
              />

              <Summary>
                {getVisible(HORAS) && (
                    <TotalItem
                        column="horas"
                        summaryType="sum"
                        valueFormat={(d) => parseFloat(d).toFixed(2)}
                        displayFormat={`{0}`}
                    />
                )}
                {getVisible(CANT_HORAS_ESPERADAS) && (
                    <TotalItem
                        column="cant_horas_esperadas"
                        summaryType="sum"
                        valueFormat={(d) => parseFloat(d).toFixed(2)}
                        displayFormat={`{0}`}
                    />
                )}
              </Summary>
              <SortByGroupSummaryInfo sortOrder={'desc'} summaryItem="sum" />
            </DataGrid>
          </div>
        </div>
      </div>
  );
};
