import * as React from 'react';
import { DonutChart, IChartProps } from '@uifabric/charting';
import { DefaultPalette } from '@fluentui/react/lib/Styling';
import { Text, Icon } from '@fluentui/react';

import { TooltipInfo } from '../../Common/TootltipInfo';
import { IAmountByConcept } from '../../../system/panel/PanelSystem';
import { useTranslation, Trans } from 'react-i18next';

export interface IProjectPanelDonutChart {
  data: IAmountByConcept[];
  iconName: string;
  titleChart: string | JSX.Element;
  showTooltip?: boolean;
  tooltipTitle?: string;
  tooltipMessageBody?: string;
  calloutText?: string;
}

const PanelDonutChart = (props: IProjectPanelDonutChart) => {
  const { t, i18n } = useTranslation();
  const _colors = [
    DefaultPalette.blueLight,
    DefaultPalette.purpleLight,
    DefaultPalette.neutralSecondary,
    DefaultPalette.greenLight,
    DefaultPalette.tealLight,
    DefaultPalette.yellowLight,
    DefaultPalette.magentaLight,
    DefaultPalette.orangeLight,
    DefaultPalette.blue,
    DefaultPalette.teal,
    DefaultPalette.purple,
    DefaultPalette.magenta,
    DefaultPalette.yellow,
    DefaultPalette.orange,
  ];

  const _dynamicData = React.useMemo(() => {
    return props.data.map((item, i) => {
      let colorSelected = DefaultPalette.orange;

      if (_colors[i]) {
        colorSelected = _colors[i];
      } else if (_colors[item.name.length]) {
        colorSelected = _colors[item.name.length];
      }

      return {
        legend: t(item.name),
        data: item.amount,
        color: colorSelected,
        yAxisCalloutData: `${item.amount} ${props.calloutText ? props.calloutText : t('Horas')} `,
      };
    });
  }, [props.data]);

  const chartTitle = '';

  const data: IChartProps = {
    chartTitle: chartTitle,
    chartData: _dynamicData,
  };

  return (
      <div className="mt-2 text-center">
        <Text className="" variant="medium" style={{ fontSize: 16, fontWeight: 600 }}>
          <Icon iconName={props.iconName} className="text-icon"></Icon> {props.titleChart}
        </Text>
        <div className="float-right">
          {props.showTooltip && props.tooltipTitle && (
              <TooltipInfo
                  inform={props.tooltipTitle}
                  title={props.tooltipTitle}
                  messageBody={props.tooltipMessageBody}
              ></TooltipInfo>
          )}
        </div>

        <div className="mt-2" style={{ width: '100%', height: '100%' }}>
          <DonutChart
              data={data}
              innerRadius={65}
              legendProps={{
                overflowProps: {
                  focusZoneProps: {
                    'aria-label': 'Legends container',
                  },
                },
                allowFocusOnLegends: true,
              }}
          />
        </div>
      </div>
  );
};

export default PanelDonutChart;
