import React, { useEffect, useState, useMemo } from 'react';
import { Text } from '@fluentui/react/lib/Text';
import { IStackTokens, Stack } from '@fluentui/react/lib/Stack';
import { Toggle, DatePicker, TextField, DefaultButton, PrimaryButton, Icon } from '@fluentui/react';
import { DateCustom } from '../../../../lib/DateCustom';
import {
  Dropdown,
  DropdownMenuItemType,
  IDropdownStyles,
  IDropdownOption,
} from '@fluentui/react/lib/Dropdown';
import TimeTaxSupportSystemClient from '../../../../system';
import CompanySelector from '../../../Company/CompanySelector';
import { DateTime } from 'luxon';
import MessageCourier from '../../../../lib/MessageCourier';
import { Company } from '../../../../system/company/Company';
import { defenseFileAssistantDropdownValues, routes } from '../../../../common/constants';
import { useNavigate } from 'react-router-dom';
import i18next, { t } from 'i18next';
import { IReportePreliquidacion } from '../../../../system/reporting/ReportingSystem';
import { formatNumberToLocale } from '../../../../common/list-utils';

interface IGenerateSettlement {
  setButtonDisability?: any;
  system: TimeTaxSupportSystemClient;
  dataTable: IReportePreliquidacion[] | undefined | null;
  courier: MessageCourier;
  generatedSettlementResponse: any;
  generateSettlement: () => void;
}

const GenerateSettlement = (props: IGenerateSettlement) => {
  const navigate = useNavigate();

  const getTotalHours = () => {
    if (props.dataTable) {
      let hours = 0;
      props.dataTable.forEach((element) => {
        hours += parseFloat(String(element.horas_confirmadas));
      });
      return formatNumberToLocale(hours);
    }
  };

  const getTotalAmount = () => {
    if (props.dataTable) {
      let money = 0;
      props.dataTable.forEach((element) => {
        money += parseFloat(String(element.importe_costo_total));
      });
      return formatNumberToLocale(money);
    }
  };

  const period = () => {
    if (props.dataTable && props.dataTable[0]) {
      return props.dataTable[0].periodo;
    }
  };

  return (
      <div>
        {/* <Text>Selecciona la empresa usuaria a la que se ha liquidado, el período y el tipo de defensa</Text> */}
        <div className="ms-Grid mt-2" dir="ltr">
          <div className="ms-Grid-row">
            <div className="ms-Grid-col  ms-sm6">
              <div className="ms-Grid-col  ms-sm12 mt-1">
                <Text>
                  {' '}
                  <Icon iconName="Money" /> {t(' Total a liquidar en soles:') + ' $' + getTotalAmount()}
                </Text>
              </div>
              <div className="ms-Grid-col  ms-sm12 mt-1">
                <Text>
                  <Icon iconName="Clock" />
                  {t(' Total a liquidar en horas:') + ' ' + getTotalHours()}
                </Text>
              </div>
              <div className="ms-Grid-col  ms-sm12 mt-1 ">
                <Text>
                  {' '}
                  <Icon iconName="PublicCalendar" /> {t(' Período:') + ' ' + period()}
                </Text>
              </div>
              {!props.generatedSettlementResponse && (
                  <div className="ms-Grid-col  ms-sm12 mt-4">
                    <PrimaryButton text={t('Generar liquidacion')} onClick={props.generateSettlement} />
                  </div>
              )}
              {props.generatedSettlementResponse ? (
                  <div>
                    <div className="ms-Grid-col ms-sm12 mt-2">
                      <Text>
                        <Icon iconName="CheckMark" className="text-icon success-color"></Icon>
                        {t('Se ha generado la liquidación directa ')}
                        {props.generatedSettlementResponse ? props.generatedSettlementResponse.liq_id : null}
                      </Text>
                    </div>

                    <div className="ms-Grid-col ms-sm12 mt-2">
                      <DefaultButton
                          className=""
                          onClick={() =>
                              navigate(`${routes.direct_settlement}/${props.generatedSettlementResponse.liq_id}`)
                          }
                      >
                        {t('Ver detalle')}
                      </DefaultButton>
                    </div>
                  </div>
              ) : null}
            </div>
            <div className="ms-Grid-col  ms-sm12 mt-2">
              <Icon iconName="Warning"></Icon>
              {t(
                  ' AVISO: Recuerda que a partir de este momento queda bloqueada la liquidacion de este período.'
              )}
            </div>
          </div>
        </div>
      </div>
  );
};

export default GenerateSettlement;
