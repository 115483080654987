import AssertionEntity from '../../../lib/FormValidator/Assertion/AssertionEntity';
import CustomForm from '../../../lib/FormValidator/Form/CustomForm';
import CustomList from '../../../lib/FormValidator/Form/CustomList';

class KeywordForm extends CustomForm {
  protected constructor(keyword: string) {
    super('keywordForm', new AssertionEntity());
    this.addInputAsserted('keyword', keyword);
  }

  private static build(keyword: string) {
    const instance = new this(keyword);
    return instance;
  }

  static initialize() {
    return this.build('');
  }

  static with(keyword: string) {
    return this.build(keyword);
  }

  getKeywordInput() {
    return this.getInputByName('keyword');
  }
}

export class MIRTKeywordsListForm extends CustomList<string> {
  private keywordForms: KeywordForm[];

  constructor(propertyName: string, items: string[], assertionEntity: AssertionEntity) {
    super(propertyName, items, assertionEntity);
    this.keywordForms = this.items.map((item) => KeywordForm.with(item));
    super.onBlur();
  }

  delete(keyword: string) {
    super.delete(keyword);
    super.onBlur();
  }

  add(keyword: string) {
    super.add(keyword);
    super.onBlur();
  }
}

export class MIRTKeywordForm extends CustomForm {
  private keywordsList: MIRTKeywordsListForm;

  readonly KEYWORD_ITEMS = 'keywords';

  protected constructor(keywords: string[]) {
    const mia = new AssertionEntity();
    super('Keywords', mia);

    this.keywordsList = new MIRTKeywordsListForm(this.KEYWORD_ITEMS, keywords, mia);
    this.addListInput(this.keywordsList);
  }

  static initialize() {
    const instance = new this([]);
    return instance;
  }

  static with(keywords: string[]) {
    const instance = new this(keywords);
    return instance;
  }

  getInputKeywordItems(): MIRTKeywordsListForm {
    return this.keywordsList;
  }
}
