import AssertionEntity from '../../../lib/FormValidator/Assertion/AssertionEntity';
import CustomForm from '../../../lib/FormValidator/Form/CustomForm';
import CustomList from '../../../lib/FormValidator/Form/CustomList';
import { Collaborator } from '../../../system/collaborators/Collaborator';
import CollaboratorTeam from '../../../system/collaborators/collaborator_team/CollaboratorTeam';
import ProjectTeam from '../../../system/projects/ProjectTeam';
import { ProjectTeamAssertions } from '../../../system/projects/ProjectTeamAssertions';
import CollaboratorTeamForm from './CollaboratorTeamForm';

class CollaboratorTeamListForm extends CustomList<CollaboratorTeam> {
  private collaboratorTeamForms: CollaboratorTeamForm[];

  constructor(
      propertyName: string,
      items: CollaboratorTeam[],
      assertionEntity: AssertionEntity,
      collaboratorResponsible: Collaborator | null
  ) {
    super(propertyName, items, assertionEntity);
    this.collaboratorTeamForms = this.items.map((item) =>
        CollaboratorTeamForm.with(
            item,
            !!collaboratorResponsible && item.collaboratorIs(collaboratorResponsible)
        )
    );
  }

  delete(collaboratorTeam: CollaboratorTeam) {
    let collaboratorToDelete = this.items.find((item) => item === collaboratorTeam);
    if (collaboratorToDelete) {
      collaboratorToDelete.set__delete(true);
    }
  }

  add(collaboratorTeam: CollaboratorTeam) {
    let collaboratorToAdd = this.items.find((item) => item.isForSameCollaboratorAs(collaboratorTeam));
    if (collaboratorToAdd) {
      collaboratorToAdd.set__delete(false);
    } else {
      super.add(collaboratorTeam);
      this.collaboratorTeamForms.push(CollaboratorTeamForm.initialize());
    }
  }

  getFormFor(colTeam: CollaboratorTeam): CollaboratorTeamForm {
    let idx = this.items.findIndex((item) => item === colTeam);
    return this.collaboratorTeamForms[idx];
  }

  setResponsibleFor(colTeam: CollaboratorTeam): CollaboratorTeam[] {
    this.items.forEach((item) => {
      let isCollaboratorResponsible = item.isForSameCollaboratorAs(colTeam);
      if (isCollaboratorResponsible) {
        this.getFormFor(item).setResponsible(true);
      } else {
        this.getFormFor(item).setResponsible(false);
      }
      this.getFormFor(item).evaluate();
    });
    return [...this.items];
  }
}

class ProjectConfigurationTeamForm extends CustomForm {
  private collaboratorTeamList: CollaboratorTeamListForm;

  readonly PROJECT_TEAM_ITEMS = 'collaboratorTeamList';

  readonly COLLABORATOR_RESPONSIBLE = 'collaboratorResponsible';
  readonly ID = 'id';

  protected constructor(
      active: boolean = true,
      id: string | null = null,
      name: string = '',
      collaboratorTeamList: CollaboratorTeam[] = [],
      collaboratorResponsible: Collaborator | null = null
  ) {
    const pta = ProjectTeamAssertions.build();
    super('ProjectTeam', pta);

    this.addInput(this.COLLABORATOR_RESPONSIBLE, collaboratorResponsible);
    this.addInputAsserted(this.ID, id);

    this.collaboratorTeamList = new CollaboratorTeamListForm(
        this.PROJECT_TEAM_ITEMS,
        collaboratorTeamList,
        pta,
        collaboratorResponsible
    );

    this.addListInput(this.collaboratorTeamList);
  }

  static initialize() {
    const instance = new this();
    return instance;
  }

  static with(projectTeam: ProjectTeam) {
    const instance = new this(
        projectTeam.getActive(),
        projectTeam.getId(),
        projectTeam.getName(),
        projectTeam.getCollaboratorTeamList(),
        projectTeam.getCollaboratorResponsible()
    );

    return instance;
  }

  toProjectTeam(): ProjectTeam {
    return ProjectTeam.build(
        true,
        this.getIdInput().getValue(),
        '',
        this.getCollaboratorResponsibleValue()?.getId(),
        this.getInputProjectTeamItems().getValue(),
        this.getCollaboratorResponsibleValue()
    );
  }

  getInputProjectTeamItems(): CollaboratorTeamListForm {
    return this.collaboratorTeamList;
  }

  getCollaboratorResponsibleInput() {
    return this.getInputByName(this.COLLABORATOR_RESPONSIBLE);
  }

  getIdInput() {
    return this.getInputByName(this.ID);
  }

  getCollaboratorResponsibleValue() {
    return this.getInputByName(this.COLLABORATOR_RESPONSIBLE).getValue();
  }

  deleteCollaborator(colTeam: CollaboratorTeam) {
    this.getInputProjectTeamItems().delete(colTeam);
  }

  collaboratorResponsibleIs(colTeam: CollaboratorTeam) {
    let col = this.getCollaboratorResponsibleInput().getValue();
    return col && colTeam.collaboratorIs(col);
  }

  setResponsibleFor(colTeam: CollaboratorTeam) {
    this.getCollaboratorResponsibleInput().setValue(colTeam.getCollaborator());
    this.getCollaboratorResponsibleInput().evaluate();
    return this.collaboratorTeamList.setResponsibleFor(colTeam);
  }
}

export default ProjectConfigurationTeamForm;
