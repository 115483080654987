import React from 'react';
import { IStackStyles, IStackTokens, Stack, Text, Link, Icon } from '@fluentui/react';
import { useNavigate } from 'react-router-dom';

// import logo from '../logo.svg';
import { boldStyle } from '../Common/TextStyles';
import { LOGO_PATH, routes } from '../../common/constants';
import { useTranslation, Trans } from 'react-i18next';

const stackTokens: IStackTokens = { childrenGap: 15 };

const mainStackStyles: Partial<IStackStyles> = {
    root: {
        width: '100%',
        height: '100%',
        margin: '0 auto',
        textAlign: 'center',
        color: '#605e5c',
    },
};

const Maintenance: React.FC = () => {
    const { t, i18n } = useTranslation();

    const navigate = useNavigate();

    return (
        <div className="maintenance-container">
            <img
                className="maintenance-app-logo"
                src={LOGO_PATH}
                alt="logo"
                style={{ display: 'block', margin: '0 auto' }}
            />
            <Text
                className="mb-2"
                variant="large"
                styles={boldStyle}
                style={{ display: 'block', marginTop: '30px' }}
            >
                {t('La página se encuentra en mantenimiento')}
            </Text>
            <Text className="mb-2" variant="large" styles={boldStyle}>
                {t('El servicio se restablecerá hoy a las 12:00hs (GMT -5)')}
            </Text>
        </div>
    );
};

export default Maintenance;
