import React from 'react';
import { CARD_MODE, GRID_MODE, OPERATORS, TYPES } from '../../../common/list-utils';
import { useState, useEffect, useMemo } from 'react';
import { ProjectCardList } from './ProjectCardList';
import { ProjectGridList } from './ProjectGridList';
import './ProjectList.css';
import { DefaultPalette, IComboBoxOption, ICommandBarItemProps, IDropdownOption } from '@fluentui/react';
import { ListColumnFilter } from '../../Common/list-entities/ListColumnFilter';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../../common/constants';
import TimeTaxSupportSystemClient from '../../../system';
import ViewWrapperWithCommandBar from '../../Layout/ViewWrapperWithCommandBar';
import { buttonStylesForProjects, defaultCommandBarButtonStyles } from '../../../styles/button-styles';
import useAsyncDataCourier from '../../../common/custom-hooks/useAsyncDataCourier';
import MessageCourier from '../../../lib/MessageCourier';
import {
  JEFATURA_VALUE,
  OrganizationChartNode,
} from '../../../system/organization_chart/OrganizationChartNode';
import { User } from '../../../system/User';
import { useTranslation, Trans } from 'react-i18next';

interface IProjectList {
  system: TimeTaxSupportSystemClient;
  courier: MessageCourier;
  user: User;
  changeBlockUI: (state: boolean) => void;
}

export const ProjectList = (props: IProjectList) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [mode, setMode] = useState(GRID_MODE);
  const [filtersData, setFiltersData] = useState<any>(null);
  const [filterPanel, setFilterPanel] = useState(false);

  const nodes = useAsyncDataCourier(
      () => props.system.getOrganizationChartSystem().getAvailableNodesOf(props.user),
      [props.system, props.user],
      props.courier
  );

  const baseUrl = React.useMemo(() => props.system.getProjectSystem().getBaseUrlForDatagrid(), [props]);

  const getFiltersData = async () => {
    const data = await props.system.getProjectSystem().getFiltersData();
    setFiltersData(data);
  };

  useEffect(() => {
    getFiltersData();
  }, []);

  const mapContextEstadoForFilter = () => {
    var estadosMapped: IDropdownOption<any>[] = [];
    if (filtersData && filtersData.estado) {
      estadosMapped = filtersData.estado.map((estado: any) => {
        return {
          key: estado.value,
          text: estado.descripcion,
        };
      });
    }
    return estadosMapped;
  };

  const mapContextAniosForFilter = () => {
    var aniosMapped: IDropdownOption<any>[] = [];
    aniosMapped.push({ key: '2023', text: '2023' });
    aniosMapped.push({ key: '2022', text: '2022' });
    aniosMapped.push({ key: '2021', text: '2021' });
    aniosMapped.push({ key: '2020', text: '2020' });
    aniosMapped.push({ key: '2019', text: '2019' });
    return aniosMapped;
  };

  const quickFilters: ListColumnFilter[] = useMemo(() => {
    let ret: ListColumnFilter[] = [
      {
        quick: true,
        name: 'equipo',
        type: TYPES.CHECKBOX,
        labelForCheckbox: t('Mis proyectos'),
        checkBoxValue: props.user.getCollaborator().fullName(),
        operator: OPERATORS.CONTAINS,
        defaultValue: props.user.getCollaborator().fullName(),
      },

      {
        quick: true,
        name: 'es_favorito',
        type: TYPES.CHECKBOX,
        labelForCheckbox: t('Favoritos'),
        checkBoxValue: '1',
        operator: OPERATORS.CONTAINS,
      },

      {
        quick: true,
        name: 'creador',
        type: TYPES.CHECKBOX,
        labelForCheckbox: t('Creados por mi'),
        checkBoxValue: props.user.getCollaborator().fullName(),
        operator: OPERATORS.EQUAL,
      },


      {
        quick: true,
        name: 'pro_codigo',
        type: TYPES.INPUT,
        placeholder: t('Codigo'),
        operator: OPERATORS.EQUAL,
      },
      {
        quick: true,
        name: 'pro_nombre',
        type: TYPES.INPUT,
        placeholder: t('Nombre'),
        operator: OPERATORS.CONTAINS,
      },
      {
        quick: true,
        name: 'pro_activo',
        type: TYPES.CHECKBOX,
        labelForCheckbox: t('Activos'),
        checkBoxValue: '1',
        operator: OPERATORS.EQUAL,
        defaultValue: '1',
      },
      {
        quick: true,
        name: 'pro_codigo',
        type: TYPES.DROPDOWN,
        placeholder: t('Año'),
        operator: OPERATORS.CONTAINS,
        contextForDropdown: mapContextAniosForFilter(),
      },
      {
        quick: false,
        name: 'pro_inicio',
        type: TYPES.DATE,
        placeholder: t('Inicio posterior a'),
        operator: OPERATORS.BEFOREORON,
      },
      {
        quick: true,
        name: 'pro_norid',
        type: TYPES.COMBOBOX,
        placeholder: t('Jefatura'),
        operator: OPERATORS.EQUAL,
        contextForOrganizationChartDropdown: nodes?.filter((n) => n.getLevel().getName() === JEFATURA_VALUE),
      },
      {
        quick: false,
        name: 'responsable_nombre',
        type: TYPES.INPUT,
        placeholder: t('Responsable'),
        operator: OPERATORS.CONTAINS,
      },
      {
        quick: false,
        name: 'empresas',
        type: TYPES.INPUT,
        placeholder: t('Empresas'),
        operator: OPERATORS.CONTAINS,
      },
      {
        quick: false,
        name: 'pro_estado',
        type: TYPES.DROPDOWN,
        placeholder: t('Estado'),
        operator: OPERATORS.EQUAL,
        contextForDropdown: mapContextEstadoForFilter(),
      },
    ];

    if (props.user.isStandardUser()) {
      ret.shift();
    }
    return ret;
  }, [filtersData, nodes, props.user]);

  const onItemClick = (item: any) => {
    navigate(`${routes.project}/${item.pro_id}`);
  };

  const itemsMenuCalendar = React.useMemo(
      (): ICommandBarItemProps[] => [
        {
          key: 'add',
          text: t('Nuevo'),
          iconProps: { iconName: 'Add', color: DefaultPalette.themeLighterAlt },
          buttonStyles: buttonStylesForProjects,
          onClick: () => navigate(`${routes.project_config}/new`),
        },
      ],
      []
  );

  const KEY_LIST = 'proyectos';

  return (
      <>
        <ViewWrapperWithCommandBar
            withBorderBottom
            title={''}
            items={itemsMenuCalendar}
            overflowButtonStyles={defaultCommandBarButtonStyles}
            variant="standard"
        >
          {filtersData ? (
              <>
                {mode == GRID_MODE ? (
                    <ProjectGridList
                        changeBlockUI={props.changeBlockUI}
                        keyList={KEY_LIST}
                        baseUrl={baseUrl}
                        quickFilters={quickFilters}
                        onItemClick={onItemClick}
                        onClickCardMode={() => setMode(CARD_MODE)}
                        onClickGridMode={() => setMode(GRID_MODE)}
                        courier={props.courier}
                        mode={mode}
                        user={props.user}
                    ></ProjectGridList>
                ) : (
                    <ProjectCardList
                        changeBlockUI={props.changeBlockUI}
                        keyList={KEY_LIST}
                        baseUrl={baseUrl}
                        quickFilters={quickFilters}
                        filterPanel={filterPanel}
                        onItemClick={onItemClick}
                        onClickCardMode={() => setMode(CARD_MODE)}
                        onClickGridMode={() => setMode(GRID_MODE)}
                        courier={props.courier}
                        mode={mode}
                    ></ProjectCardList>
                )}
              </>
          ) : null}
        </ViewWrapperWithCommandBar>
      </>
  );
};
