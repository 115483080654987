import * as React from 'react';
import { DefaultPalette, ICommandBarItemProps } from '@fluentui/react';
import TimeTaxSupportSystemClient from '../../system';
import { DateTime } from 'luxon';
import MessageCourier from '../../lib/MessageCourier';
import ViewWrapperWithCommandBar from '../Layout/ViewWrapperWithCommandBar';
import { defaultCommandBarButtonStyles, titleCommandBarButtonStyles } from '../../styles/button-styles';
import { ReportingFilters } from './ReportingFilters';
import { IReporteProyectos } from '../../system/reporting/ReportingSystem';
import { Company } from '../../system/company/Company';
import { useTranslation } from 'react-i18next';
import DataGrid, {
  Scrolling, Column,
  Grouping,
  GroupPanel,
  Summary,
  GroupItem,
  SortByGroupSummaryInfo,
  Export,
  SearchPanel,
  TotalItem
} from 'devextreme-react/data-grid';
import { User } from '../../system/User';
import { EscalableExporterOptions, exportToExcel } from '../../lib/Util';

interface IReportingHoursProjects {
  system: TimeTaxSupportSystemClient;
  courier: MessageCourier;
  changeBlockUI: (state: boolean) => void;
  user: User;
}

export const ReportingHoursProjects = (props: IReportingHoursProjects) => {
  const { t, i18n } = useTranslation();
  const [companySelected, setCompanySelected] = React.useState<Company>();
  const [from, setFrom] = React.useState<DateTime | undefined>(undefined);
  const [to, setTo] = React.useState<DateTime | undefined>(undefined);
  const [node, setNode] = React.useState<string>('');
  const [items, setItems] = React.useState<IReporteProyectos[]>([]);

  const itemsMenu = React.useMemo(
      (): ICommandBarItemProps[] => [
        {
          key: 'title',
          text: t('Reporte de horas por proyecto'),
          iconProps: {
            iconName: 'ReminderPerson',
            color: DefaultPalette.themeLighterAlt,
            className: 'text-title-btn',
          },
          buttonStyles: titleCommandBarButtonStyles,
          className: 'text-title-btn',
        },
      ],
      []
  );

  const onClickSearch = async () => {
    props.changeBlockUI(true);
    try {
      if (from && to) {
        const _items = await props.system.getReportingSystem().getProjectReport(from, to, node);
        setItems(_items);
        props.courier.messageSuccess(t('Reporte generado con éxito'));
      }
    } catch (error) {
      props.courier.messageError(error);
    }
    props.changeBlockUI(false);
  };

  const onExporting = (e: any) => {
    let options: EscalableExporterOptions = {
      fileName: `reporte-horas-proyecto-de-${from?.toFormat('dd-LL-yyyy')}-a-${to?.toFormat('dd-LL-yyyy')}`,
      sheetName: t('Reporte de horas por proyecto'),
      customizeCell: (options) => {
        if (options.gridCell?.rowType === 'group') {
          options.excelCell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '8DB4E2' } };
        }
        if (options.gridCell?.rowType === 'totalFooter' && options.excelCell.value) {
          options.excelCell.font.italic = true;
        }
      },
    };
    exportToExcel(e, options);
  };

  return (
      <ViewWrapperWithCommandBar
          withBorderBottom
          title={''}
          items={itemsMenu}
          overflowButtonStyles={defaultCommandBarButtonStyles}
          variant="highligth"
          titleBody={t('Reporte de horas por proyecto')}
      >
        <div className="padding-1 bg-white">
          <div className="ms-Grid" dir="ltr">
            <div className="ms-Grid-row">
              <ReportingFilters
                  system={props.system}
                  courier={props.courier}
                  onChangeFromDate={(d) => setFrom(d)}
                  onChangeToDate={(d) => setTo(d)}
                  onClickSearch={onClickSearch}
                  showCompanyFilter={false}
                  onChangeCompany={() => {}}
                  showSectorFilter={true}
                  onChangeNode={(nodeId) => {
                    setNode(nodeId);
                  }}
                  user={props.user}
              />
            </div>
            {items.length > 0 && (
                <div className="ms-Grid-row">
                  <div className="ms-Grid-col ms-sm12">
                    <DataGrid
                        height={620}
                        width="100%"
                        keyExpr={'pro_id'}
                        dataSource={items}
                        showBorders={true}
                        wordWrapEnabled={true}
                        onExporting={onExporting}
                    >
                      <Scrolling mode="virtual" />
                      <SearchPanel visible={true} placeholder={t('Buscar...')} />
                      <Grouping autoExpandAll={false} />
                      <GroupPanel visible={true} />

                      <Column
                          dataField="sector"
                          caption={t('Sector')}
                          groupCellRender={(d) => {
                            return (
                                <div className="dx-datagrid-summary-item dx-datagrid-text-content dx-datagrid-group-text-content">
                                  {d.value}
                                </div>
                            );
                          }}
                      />
                      <Column
                          dataField="pro_nombre_completo"
                          caption={t('Proyecto')}
                          groupCellRender={(d) => {
                            return (
                                <div className="dx-datagrid-summary-item dx-datagrid-text-content dx-datagrid-group-text-content">
                                  {d.value}
                                </div>
                            );
                          }}
                          groupIndex={0}
                      />

                      <Column
                          dataField="emp_nombre"
                          caption={t('Empresa')}
                          groupCellRender={(d) => {
                            return (
                                <div className="dx-datagrid-summary-item dx-datagrid-text-content dx-datagrid-group-text-content">
                                  {d.value}
                                </div>
                            );
                          }}
                          groupIndex={1}
                      />

                      <Column
                          dataField="cant_colabs"
                          caption={t('Colaboradores')}
                          dataType="number"
                          alignment={'center'}
                          cellRender={(d) => {
                            return (
                                <div className="dx-datagrid-summary-item dx-datagrid-text-content dx-datagrid-group-text-content">
                                  {d.value}
                                </div>
                            );
                          }}
                      />

                      <Column
                          dataField="cant_colabs_activos"
                          caption={t('Colaboradores activos')}
                          dataType="number"
                          alignment={'center'}
                          cellRender={(d) => {
                            return (
                                <div className="dx-datagrid-summary-item dx-datagrid-text-content dx-datagrid-group-text-content">
                                  {d.value}
                                </div>
                            );
                          }}
                      />
                      <Column
                          dataField="cant_actividades"
                          caption={t('Actividades')}
                          dataType="number"
                          alignment={'center'}
                          cellRender={(d) => {
                            return (
                                <div className="dx-datagrid-summary-item dx-datagrid-text-content dx-datagrid-group-text-content">
                                  {d.value}
                                </div>
                            );
                          }}
                      />

                      <Column
                          dataField="cant_horas"
                          caption={t('Horas')}
                          dataType="number"
                          alignment={'center'}
                          cellRender={(d) => {
                            return (
                                <div className="dx-datagrid-summary-item dx-datagrid-text-content dx-datagrid-group-text-content">
                                  {d.value} Hs.
                                </div>
                            );
                          }}
                      />

                      <Column dataField="pro_estado" caption={t('Estado')} alignment={'center'} />

                      <Summary>
                        <GroupItem
                            column="cant_horas"
                            summaryType="sum"
                            alignByColumn={true}
                            valueFormat={(d) => parseFloat(d).toFixed(2)}
                            displayFormat={`{0} hs.`}
                        />

                        <GroupItem
                            column="cant_horas"
                            summaryType="sum"
                            valueFormat={(d) => parseFloat(d).toFixed(2)}
                            displayFormat={`{0} hs`}
                            showInGroupFooter={true}
                        />

                        <TotalItem
                            column="cant_horas"
                            summaryType="sum"
                            valueFormat={(d) => parseFloat(d).toFixed(2)}
                            displayFormat={`Total: {0} hs`}
                        />
                      </Summary>
                      <SortByGroupSummaryInfo sortOrder={'desc'} summaryItem="sum" />
                      <Export enabled={true} />
                    </DataGrid>
                  </div>
                </div>
            )}
          </div>
        </div>
      </ViewWrapperWithCommandBar>
  );
};
