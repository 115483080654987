import XhrRequestHandler from '../../lib/XhrRequestHandler';
import DocumentsUploader, {
  IFilesUploadTracker,
  IUploadActivityDataNew,
  IUploadProjectDataNew,
  IUploadSettlementDataNew,
  uploadItemActivityType,
  uploadItemProjectType,
  uploadItemSettlmentType,
  uploadTypeActivity,
  uploadTypeProject,
  uploadTypeSettlement,
} from './DocumentsUploader';
import Project from '../projects/Project';
import ProjectFolder from '../projects/ProjectFolder';
import SystemDocumentType from './SystemDocumentType';
import LazyInitialization from '../../lib/LazyInitialization';
import { SystemDateParser } from '../SystemDateParser';
import { ProjectRelatedDocument } from './ProjectRelatedDocument';
import { SystemDocument } from './SystemDocument';
import { Activity } from '../activity/Activity';
import { ActivityDocumentToSubmit } from './ActivityDocumentToSubmit';
import SystemUploadedDocument from './SystemUploadedDocument';
import { CollaboratorSystem, ICollaborator } from '../collaborators/CollaboratorSystem';
import { IDocumentControlSaved } from './DocumentUploadControl';
import DocumentUploadControlConfirmed from './DocumentUploadControlConfirmed';
import { FileToUpload } from './FileToUpload';
import { ISettlementGeneratedDocument } from '../settlement/SettlementSystem';
import { Collaborator } from '../collaborators/Collaborator';
import { SettlementProjectInPeriodItem } from '../settlement/SettlementProjectInPeriodItem';
import { Settlement } from '../settlement/Settlement';
import { StoredDocumentRelatedToProject } from '../projects/ProjectsSystem';
import { DateTime } from 'luxon';

export interface IProjectFolderSystem {
  getFolderById(id: string, project: Project): Promise<ProjectFolder>;
}

export interface IDocumentType {
  doct_id: string;
  doct_nombre: string;
  doct_activo: boolean;
}

export interface IUploadedDocument {
  // colaborador: null;
  // documentoTipo: null;
  doc_id: string;
  doc_nombre: string;
  doc_carga_fecha: string;
  doc_carga_colid: string;
  doc_doctid: string;
  doc_acceso_url: string;
  doc_estado: string;
  doc_activo: boolean;
  doc_owner_colid: string;
  doc_eliminado: boolean;
  doc_inactivo_desde: null | string;
  doc_referencia: string;
  doc_pcaid: string;
  doc_tamanio: string;
  doc_content_type: string;
  doc_es_confidencial: boolean;
  doc_refiere_a_periodo: string;
  referenciaDocumentoObligatorio: IDocumentControlSaved | null;
}

export interface IStoredProjectActivityDocument {
  dxa_id: string;
  dxa_docid: string;
  dxa_actid: string;
  dxa_obligatorio: boolean;
  documento: null | IUploadedDocument;
}

interface IFileWithType {
  file: FileToUpload;
  type: SystemDocumentType;
}

interface SettlementItemDocument {
  dxli_liqiid: string;
  dxli_docid: null;
}

interface SettlementItemDocumentSaved extends Omit<SettlementItemDocument, 'dxli_docid'> {
  dxli_docid: string;
  dxli_id: string;
}

export default class DocumentsSystem {
  private readonly documentTypesEntityBase = '/tipos-documento';
  private readonly documentsEntityBase = '/documentos';
  private readonly uploadedDocumentsEntityBase = '/documentos-informados';
  private readonly settlementItemsDocumentsEntityBase = '/documentos-liquidacion-items';

  private documentTypeCache: { id: string; value: LazyInitialization<SystemDocumentType> }[];

  constructor(
      private requestHandler: XhrRequestHandler,
      private documentsUploader: DocumentsUploader,
      private dateParser: SystemDateParser,
      private collaboratorSystem: CollaboratorSystem
  ) {
    this.documentTypeCache = [];
  }

  registerFileUploadTracker(tracker: IFilesUploadTracker) {
    return this.documentsUploader.registerFileUploadTracker(tracker);
  }

  informPreparingUpload() {
    return this.documentsUploader.informPreparingUpload();
  }

  async save(
      document: SystemDocument,
      name: string,
      documentType: SystemDocumentType,
      isConfidential: boolean,
      refersToPeriod: Date
  ) {
    const data = await this.requestHandler.post<
        IUploadedDocument,
        Pick<
            IUploadedDocument,
            'doc_id' | 'doc_nombre' | 'doc_doctid' | 'doc_es_confidencial' | 'doc_refiere_a_periodo'
        >
    >(`${this.documentsEntityBase}`, {
      doc_id: document.getId(),
      doc_nombre: name,
      doc_doctid: documentType.getId(),
      doc_es_confidencial: isConfidential,
      doc_refiere_a_periodo: DateTime.fromJSDate(refersToPeriod).toFormat('yyyy-LL-dd'),
    });

    // document.getF
    return data;
  }

  async getById(id: string): Promise<SystemUploadedDocument> {
    // try {
    const data = await this.requestHandler.get<IUploadedDocument>(`${this.documentsEntityBase}/${id}`);
    return this.getSystemDocumentFromIUploadedDocument(data);
    // } catch (e) {
    //   let errorMsg = 'Error inesperado';
    //   if (e instanceof XhrRequestError) {
    //     errorMsg = e.getApiErrorMessage();
    //   }

    //   return this.getFakeDocumentFromError(errorMsg);
    // }
  }

  permanentlyDelete(document: ProjectRelatedDocument): Promise<void> {
    return this.permanentlyDeleteDocument(document.getDocument());
  }

  downloadUrlOf(document: ProjectRelatedDocument): Promise<string> {
    return this.getDownloadLinkFor(document.getDocument());
  }

  relatedDocument(id: string): Promise<StoredDocumentRelatedToProject> {
    return this.requestHandler.get<StoredDocumentRelatedToProject>(
        `${this.documentsEntityBase}/related-document/${id}`
    );
  }

  async availableDocumentsFor(settlement: Settlement): Promise<SystemUploadedDocument[]> {
    const data = await this.requestHandler.get<IUploadedDocument[]>(
        `${this.documentsEntityBase}/available-for-settlement/${settlement.getId()}`
    );

    return data.map((doc) => this.getSystemDocumentFromIUploadedDocument(doc));
  }

  getFakeDocumentFromError(error: string): SystemUploadedDocument {
    let errorDocument: IUploadedDocument = {
      doc_nombre: error,
      doc_id: '',
      doc_carga_fecha: '',
      doc_carga_colid: '',
      doc_doctid: '',
      doc_acceso_url: '',
      doc_estado: '',
      doc_activo: false,
      doc_owner_colid: '',
      doc_eliminado: false,
      doc_inactivo_desde: null,
      doc_referencia: '',
      doc_pcaid: '',
      doc_tamanio: '',
      doc_content_type: '',
      doc_es_confidencial: false,
      doc_refiere_a_periodo: '',
      referenciaDocumentoObligatorio: null,
    };

    return this.getSystemDocumentFromIUploadedDocument(errorDocument);
  }

  getSystemDocumentFromIUploadedDocument(
      data: IUploadedDocument,
      uploadControlReference?: DocumentUploadControlConfirmed
  ): SystemUploadedDocument {
    return SystemUploadedDocument.fromJson(
        this,
        this.documentTypeFor(data.doc_doctid),
        this.collaboratorSystem.getCollaboratorIdentifiedByLazy(data.doc_carga_colid),
        data,
        uploadControlReference
    );
  }

  async uploadFilesToProjectFolder(files: File[], folder: ProjectFolder, documentTypeId: string) {
    const filesToUpload = this.mapFilesToFilesToUpload(files);
    const uploadData = await this.uploadDataForProjectFolder(filesToUpload, folder, documentTypeId);

    return this.documentsUploader.upload(filesToUpload, uploadData);
  }

  private mapFilesToFilesToUpload(files: File[]) {
    return files.map((f, i) => FileToUpload.identifiedBy(f, `${i}`));
  }

  async uploadFilesToActivity(pendingDocuments: ActivityDocumentToSubmit[], activity: Activity) {
    const filesToUpload = this.getFilesToUpload(pendingDocuments);
    const uploadData = this.uploadDataForActivity(filesToUpload, activity);
    const files = filesToUpload.map((f) => f.file);

    return this.documentsUploader.upload(files, uploadData);
  }

  async uploadFilesToSettlement(
      filesToUpload: FileToUpload[],
      documentReferences: ISettlementGeneratedDocument[],
      settlementId: string
  ) {
    const uploadData = await this.uploadDataForSettlement(filesToUpload, documentReferences, settlementId);

    return this.documentsUploader.upload(filesToUpload, uploadData);
  }

  async uploadFilesToSettlementItem(file: File, item: SettlementProjectInPeriodItem, settlementId: string) {
    const data = await this.requestHandler.post<SettlementItemDocumentSaved, SettlementItemDocument>(
        this.settlementItemsDocumentsEntityBase,
        { dxli_liqiid: item.getId(), dxli_docid: null }
    );

    const toUpload = FileToUpload.identifiedBy(file, '0');
    return this.uploadFilesToSettlement([toUpload], [{ dxliid: data.dxli_id, uuid: '0' }], settlementId);
  }

  private getFilesToUpload(pendingDocuments: ActivityDocumentToSubmit[]): IFileWithType[] {
    const filesToUpload: IFileWithType[] = [];
    pendingDocuments.forEach((documentToSubmit) =>
        documentToSubmit.getFiles().forEach((file, i) =>
            filesToUpload.push({
              file: FileToUpload.identifiedBy(file, `${i}`),
              type: documentToSubmit.getType(),
            })
        )
    );

    return filesToUpload;
  }

  async getDocumentTypeById(documentTypeId: string): Promise<SystemDocumentType> {
    const data = await this.requestHandler.get<IDocumentType>(
        `${this.documentTypesEntityBase}/${documentTypeId}`
    );

    return SystemDocumentType.fromIDocumentType(data);
  }

  async getAvailableDocumentTypes(): Promise<SystemDocumentType[]> {
    const data = await this.requestHandler.get<IDocumentType[]>(`${this.documentTypesEntityBase}`);

    return data.filter((d) => d.doct_activo).map((d) => SystemDocumentType.fromIDocumentType(d));
  }

  deactivateProjectDocument(document: ProjectRelatedDocument) {
    return this.requestHandler.delete(`${this.documentsEntityBase}/${document.getId()}`);
  }

  permanentlyDeleteDocument(document: SystemDocument): Promise<void> {
    this.assertCanDeleteDocument(document);
    const id = document.getId();
    return this.requestHandler.delete(`${this.documentsEntityBase}/delete-from-trash/${id}`);
  }

  async getDownloadLinkFor(document: SystemDocument): Promise<string> {
    const { link } = await this.requestHandler.get<{ link: string }>(
        `${this.documentsEntityBase}/download-link/${document.getId()}`
    );

    return link;
  }

  restoreFromTrash(document: ProjectRelatedDocument) {
    this.assertIsInTrash(document);
    return this.requestHandler.post(`${this.documentsEntityBase}/restore/${document.getId()}`);
  }

  private assertIsInTrash(document: ProjectRelatedDocument) {
    if (!document.isInTrash()) {
      throw new Error('Document must be in trash');
    }
  }

  private assertCanDeleteDocument(document: SystemDocument) {
    if (document.isActive()) {
      throw new Error('Document must be inactive');
    }
    if (document.isDeleted()) {
      throw new Error('Document was already deleted');
    }
  }

  documentTypeFor(documentTypeId: string) {
    let cached = this.documentTypeCache.find((cached) => cached.id === documentTypeId);
    if (!cached) {
      const lazy = new LazyInitialization(() => this.getDocumentTypeById(documentTypeId));
      cached = { id: documentTypeId, value: lazy };
      this.documentTypeCache.push(cached);
    }

    return cached.value;
  }

  async documentTypeOtherId(): Promise<string> {
    return this.typeId('other-type');
  }

  getDateParser() {
    return this.dateParser;
  }

  private async typeId(path: string) {
    const data = await this.requestHandler.get<IDocumentType>(`${this.documentTypesEntityBase}/${path}`);

    return data.doct_id;
  }

  private async uploadDataForProjectFolder(
      files: FileToUpload[],
      folder: ProjectFolder,
      documentTypeId: string
  ) {
    const uploadData = await this.uploadDataForProject(files, folder.getProject(), documentTypeId);
    uploadData.folderId = folder.getId();

    return uploadData;
  }

  private async uploadDataForProject(
      files: FileToUpload[],
      project: Project,
      documentTypeId: string
  ): Promise<IUploadProjectDataNew> {
    const totalBytes = files.reduce((previousSize, file) => previousSize + file.getSize(), 0);

    return {
      cmd_proid: project.getId(),
      cmd_cant_archivos: files.length,
      cmd_cant_bytes_total: totalBytes,
      cmd_tipo: uploadTypeProject,
      cargaDocumentosItems: files.map((file) => {
        return {
          cmdi_cant_bytes: file.getSize(),
          cmdi_doc_uuid: file.getId(),
          cmdi_nombre_archivo: file.getName(),
          cmdi_content_type: file.getType(),
          tipo: uploadItemProjectType,
          doctId: documentTypeId,
        };
      }),
    };
  }

  public async getAdmissibleAccessCollaborators(id: string): Promise<Collaborator[]> {
    const admissibleAccessCollaborators = await this.requestHandler.get<ICollaborator[]>(
        `${this.documentsEntityBase}/admissible-access-collaborators/${id}`
    );
    return admissibleAccessCollaborators.map((col) =>
        Collaborator.fromICollaborator(this.collaboratorSystem, col)
    );
  }

  getBaseUrlForDatagrid() {
    return this.requestHandler.requestUrl(`${this.uploadedDocumentsEntityBase}/datagrid`);
  }

  async getListForSettlement(projectIds: string[]) {
    const params = `?filter[0][name]=pro_id&filter[0][value]=${projectIds.join(
        ','
    )}&filter[0][operator]=in&filter[1][name]=doci_docid&filter[1][value]=1&filter[1][operator]=notEmpty`;

    const data = await this.requestHandler.get<unknown[]>(
        `${this.uploadedDocumentsEntityBase}/datagrid${params}`
    );

    return data;
  }

  async getListForSavedSettlement(settlement: Settlement) {
    const params = `?skip=0&limit=1000&filter[0][name]=liq_id&filter[0][value]=${settlement.getId()}&filter[0][operator]=neq`;
    return this.requestHandler.get<unknown[]>(`${this.uploadedDocumentsEntityBase}/datagrid${params}`);
  }

  private uploadDataForActivity(files: IFileWithType[], activity: Activity): IUploadActivityDataNew {
    const totalBytes = files.reduce((previousSize, { file }) => previousSize + file.getSize(), 0);

    return {
      cmd_actid: activity.getId(),
      cmd_cant_archivos: files.length,
      cmd_tipo: uploadTypeActivity,
      cmd_cant_bytes_total: totalBytes,
      cargaDocumentosItems: files.map(({ file, type }, i) => ({
        cmdi_cant_bytes: file.getSize(),
        cmdi_content_type: file.getType(),
        cmdi_doc_uuid: file.getId(),
        cmdi_nombre_archivo: file.getName(),
        doctId: type.getId(),
        actId: activity.getId(),
        tipo: uploadItemActivityType,
      })),
    };
  }

  private async uploadDataForSettlement(
      files: FileToUpload[],
      documentReferences: ISettlementGeneratedDocument[],
      settlementId: string
  ): Promise<IUploadSettlementDataNew> {
    const totalBytes = files.reduce((previousSize, file) => previousSize + file.getSize(), 0);
    const documentTypeId = await this.documentTypeOtherId();

    return {
      cmd_liqid: settlementId,
      cmd_cant_archivos: files.length,
      cmd_cant_bytes_total: totalBytes,
      cmd_tipo: uploadTypeSettlement,
      cargaDocumentosItems: files.map((file) => {
        return {
          cmdi_cant_bytes: file.getSize(),
          cmdi_doc_uuid: file.getId(),
          cmdi_nombre_archivo: file.getName(),
          cmdi_content_type: file.getType(),
          cmdi_dxliid: documentReferences.find((ref) => file.isIdentifiedBy(ref.uuid))!.dxliid,
          tipo: uploadItemSettlmentType,
          doctId: documentTypeId,
        };
      }),
    };
  }
}
