import React, { useEffect, useState, useMemo } from 'react';
import { Text } from '@fluentui/react/lib/Text';
import { IStackTokens, Stack } from '@fluentui/react/lib/Stack';
import { Toggle, DatePicker } from '@fluentui/react';
import { DateCustom } from '../../../lib/DateCustom';
import {
  Dropdown,
  DropdownMenuItemType,
  IDropdownStyles,
  IDropdownOption,
} from '@fluentui/react/lib/Dropdown';
import TimeTaxSupportSystemClient from '../../../system';
import CompanySelector from '../../Company/CompanySelector';
import { DateTime } from 'luxon';
import MessageCourier from '../../../lib/MessageCourier';
import { Company } from '../../../system/company/Company';
import { defenseFileAssistantDropdownValues } from '../../../common/constants';
import { useTranslation, Trans } from 'react-i18next';
import { getLocalizedStrings } from '../../../lib/LocalizedStringsForDatePicker';

interface IProjectConfiguration {
  setButtonDisability: (can: boolean) => void;
  system: TimeTaxSupportSystemClient;
  CompanySelectorData: any;
  getDataFromCompanySelectorAssistant: (
      company: Company,
      type: string,
      fromDate: DateTime,
      toDate: DateTime
  ) => void;
  courier: MessageCourier;
}

const CompanySelectorAssistant = (props: IProjectConfiguration) => {
  const { t, i18n } = useTranslation();
  const [company, setCompany] = useState<Company | undefined>(undefined);
  const [type, setType] = useState<string>('');
  const [fromDate, setFromDate] = useState<DateTime>(DateTime.fromJSDate(DateCustom.getFechaOneMonthAgo()));
  const [toDate, setToDate] = useState<DateTime>(DateTime.fromJSDate(new Date()));

  useEffect(() => {
    if (props.CompanySelectorData) {
      if (props.CompanySelectorData.company) {
        setCompany(props.CompanySelectorData.company);
      }
      if (props.CompanySelectorData.type) {
        setType(props.CompanySelectorData.type);
      }
      if (props.CompanySelectorData.fromDate) {
        setFromDate(props.CompanySelectorData.fromDate);
      }
      if (props.CompanySelectorData.toDate) {
        setToDate(props.CompanySelectorData.toDate);
      }
    }
  }, [props.CompanySelectorData]);

  useEffect(() => {
    if (company && type && fromDate && toDate) {
      props.getDataFromCompanySelectorAssistant(company, type, fromDate, toDate);
      props.setButtonDisability(true);
    } else {
      props.setButtonDisability(false);
    }
  }, [company, type, fromDate, toDate, props]);

  const options: IDropdownOption[] = [
    { key: defenseFileAssistantDropdownValues.forProjects, text: t('Por proyectos') },
    {
      key: defenseFileAssistantDropdownValues.forIndirectsSettlements,
      text: t('Por liquidaciones indirectas'),
      disabled: true,
    },
  ];

  const onChangeDate = (date: Date | null | undefined, name: string) => {
    if (date) {
      if (name === 'from') {
        setFromDate(DateTime.fromJSDate(date));
      }
      if (name === 'to') {
        setToDate(DateTime.fromJSDate(date));
      }
    }
  };

  const onChangeType = (event: unknown, option?: IDropdownOption<any> | undefined) => {
    setType(option ? String(option.key) : '');
  };

  return (
      <div>
        {/* <Text>Selecciona la empresa usuaria a la que se ha liquidado, el período y el tipo de defensa</Text> */}
        <div className="ms-Grid mt-2" dir="ltr">
          <div className="ms-Grid-row">
            <div className="ms-Grid-col  ms-sm6">
              <CompanySelector
                  onChangeSelected={(c) => setCompany(c)}
                  system={props.system}
                  courier={props.courier}
                  onlyBillableCompanies
                  default={company}
              />
            </div>
          </div>
          <div className="ms-Grid-row ">
            <div className="ms-Grid-col ms-sm3 ">
              <DatePicker
                  strings={getLocalizedStrings()}
                  label={t('Desde')}
                  formatDate={DateCustom.formatDateForDatePicker_MMYYYY}
                  value={fromDate.toJSDate()}
                  onSelectDate={(e) => onChangeDate(e, 'from')}
                  className=""
                  calendarProps={{ isDayPickerVisible: false }}
              />
            </div>
            <div className="ms-Grid-col ms-sm3 ">
              <DatePicker
                  strings={getLocalizedStrings()}
                  label={t('Hasta')}
                  formatDate={DateCustom.formatDateForDatePicker_MMYYYY}
                  value={toDate.toJSDate()}
                  onSelectDate={(e) => onChangeDate(e, 'to')}
                  className=""
                  calendarProps={{ isDayPickerVisible: false }}
              />
            </div>
          </div>
          <div className="ms-Grid-row">
            <div className="ms-Grid-col ms-sm6 ">
              <Dropdown
                  placeholder={t('Tipo de defensa')}
                  label={t('Tipo')}
                  options={options}
                  onChange={onChangeType}
                  defaultSelectedKey={type}
              />
            </div>
          </div>
        </div>
      </div>
  );
};

export default CompanySelectorAssistant;
