import { DateTime } from 'luxon';
import XhrRequestHandler, { getQueryParamsExpression, QueryParam } from '../../lib/XhrRequestHandler';
import { Collaborator } from '../collaborators/Collaborator';
import CollaboratorSystem, { ICollaborator } from '../collaborators/CollaboratorSystem';
import { Company, CompanyJson } from '../company/Company';
import CompanySystem from '../company/CompanySystem';
import { IUploadedDocument } from '../documents/DocumentsSystem';
import { FileToUpload } from '../documents/FileToUpload';
import SystemUploadedDocument from '../documents/SystemUploadedDocument';
import Project from '../projects/Project';
import ProjectsSystem, { ProjectJson } from '../projects/ProjectsSystem';
import { SystemDateParser } from '../SystemDateParser';
import ProjectInSettlementPeriod from './ProjectInSettlementPeriod';
import { Settlement } from './Settlement';
import { SettlementProjectInPeriod } from './SettlementProjectInPeriod';
import { SettlementProjectInPeriodItem } from './SettlementProjectInPeriodItem';
import { SettlementRelatedDocument } from './SettlementRelatedDocument';
export interface ISettlementItemsGrouped {
  ldi_sector: string;
  ldi_periodo: string;
  promedio_costo_hora: string;
  ldi_horas_confirmadas: string;
  ldi_importe_costo_neto: string;
  ldi_importe_costo_total: string;
  concept: string;
}

export class SettlementSystem {
  private readonly base = '/liquidaciones';
  private readonly itemsBase = '/liquidacion-items';
  private readonly settlementDocumentsBase = '/documentos-liquidacion';
  entityBase: any;

  constructor(
      private requestHandler: XhrRequestHandler,
      private dateParser: SystemDateParser,
      private companySystem: CompanySystem,
      private collaboratorSystem: CollaboratorSystem,
      private projectSystem: ProjectsSystem
  ) {}

  getBaseUrlForDatagrid() {
    return this.requestHandler.requestUrl(`${this.base}/datagrid`);
  }

  getBaseUrlForDatagridDirect() {
    return this.requestHandler.requestUrl(`${this.base}/datagrid-directa`);
  }

  async getList(params?: QueryParam[]): Promise<any> {
    const query = getQueryParamsExpression(params);
    return await this.requestHandler.get<ISettlementForDatagrid[]>(`${this.base}${query}`);
  }

  getDownloadPresettlementExcelURI(from: DateTime, to: DateTime) {
    let url = `${this.base}/download-preliquidacion/${from.toFormat('dd-LL-yyyy')}/${to.toFormat(
        'dd-LL-yyyy'
    )}`;
    return `${this.requestHandler.requestUrl(url)}`;
  }

  async getListForDefenseFile(CompanyId: string, from: DateTime, to: DateTime) {
    let _formattedFrom = from.toFormat('yyyy-LL');
    let _formattedTo = to.toFormat('yyyy-LL');

    let params = `?skip=0&filter[0][name]=liq_desde&filter[0][value]=${_formattedFrom}&filter[0][operator]=leq&filter[1][name]=liq_hasta&filter[1][value]=${_formattedTo}&filter[1][operator]=get&filter[2][name]=liq_activa&filter[2][value]=1&filter[2][operator]=eq&filter[3][name]=liq_empid&filter[3][value]=${CompanyId}&filter[3][operator]=eq&sort[0][name]=liq_desde&sort[0][direction]=1&sort[0][id]=liq_desde&sort[0][type]=string`;
    const data = await this.requestHandler.get<ISettlementForDatagrid[]>(`${this.base}/datagrid${params}`);

    return data;
  }

  async getById(id: string): Promise<Settlement> {
    const data = await this.requestHandler.get<ISettlementSaved>(`${this.base}/${id}`);

    return this.mapToSettlement(data);
  }

  async getSummaryById(id: string): Promise<ISettlementSummary> {
    return this.requestHandler.get<ISettlementSummary>(`${this.base}/context/${id}`);
  }

  async getItemsGroupedById(id: string, concept: string = 'empresa'): Promise<ISettlementItemsGrouped[]> {
    return this.requestHandler.get<ISettlementItemsGrouped[]>(
        `${this.base}/liquidacion-directa-items-grouped/${id}/${concept}`
    );
  }

  async getItemsOf(settlement: Settlement) {
    const data = await this.requestHandler.get<ISettlementItemData[]>(
        `${this.base}/items/${settlement.getId()}`
    );

    const projectReferences: SettlementProjectInPeriod[] = [];

    let currentProject: SettlementProjectInPeriod | undefined = undefined;
    for (const { liquidacionXProyecto, ...item } of data) {
      if (!currentProject || !currentProject.isIdentifiedBy(liquidacionXProyecto.lxp_id)) {
        if (currentProject) projectReferences.push(currentProject);
        currentProject = await this.mapToSettlementProject(liquidacionXProyecto);
      }
      if (item.liqi_activo) {
        currentProject.addItem(this.mapToSettlementProjectItem(item));
      }
    }
    if (currentProject) projectReferences.push(currentProject);

    return projectReferences;
  }

  /**
   * @see TimeTaxSupportSystemClient.addSettlementItem
   */
  async addSettlementItem(
      settlementProject: SettlementProjectInPeriod,
      amount: number,
      description: string
  ): Promise<SettlementProjectInPeriodItem> {
    const data = await this.requestHandler.post<ISettlementItemDataBase, ISettlementItem>(this.itemsBase, {
      liqi_activo: true,
      liqi_descripcion: description,
      liqi_importe: amount,
      liqi_lxpid: settlementProject.getId(),
      liqi_orden: settlementProject.nextItemOrder().toString(),
    });

    const item = this.mapToSettlementProjectItem(data);
    settlementProject.addItem(item);

    return item;
  }

  deleteItem(settlementItem: SettlementProjectInPeriodItem) {
    return this.requestHandler.delete(`${this.itemsBase}/${settlementItem.getId()}`);
  }

  downloadSettlementDocument(settlement: Settlement) {
    return this.requestHandler.open(`${this.base}/pdf/${settlement.getId()}`);
  }

  downloadSettlementItems(id: string) {
    return this.requestHandler.open(`${this.base}/download-liquidacion-items/${id}`);
  }

  downloadPreSettlement(start: DateTime, end: DateTime, settlementMode: string) {
    return this.requestHandler.open(
        `${this.base}/download-preliquidacion/${start.toFormat('yyyy-LL-dd')}/${settlementMode}`
    );
  }

  async relatedDocumentsOf(settlement: Settlement): Promise<SettlementRelatedDocument[]> {
    const data = await this.requestHandler.get<ISettlementDocument[]>(
        `${this.settlementDocumentsBase}/list/${settlement.getId()}`
    );
    const documentsSystem = this.projectSystem.getDocumentsSystem();

    return data.map((d) => {
      const systemDoc = documentsSystem.getSystemDocumentFromIUploadedDocument(d.document);
      return SettlementRelatedDocument.identifiedBy(d.dxl_id, d.dxl_activo, systemDoc);
    });
  }

  availableDocuments(settlement: Settlement): Promise<SystemUploadedDocument[]> {
    return this.projectSystem.getDocumentsSystem().availableDocumentsFor(settlement);
  }

  deleteRelatedDocument(document: SettlementRelatedDocument) {
    return this.requestHandler.delete(`${this.settlementDocumentsBase}/${document.getId()}`);
  }

  addRelatedDocument(settlement: Settlement, document: SystemUploadedDocument) {
    return this.requestHandler.post<unknown, ISettlementDocumentBase>(`${this.settlementDocumentsBase}`, {
      dxl_activo: true,
      dxl_docid: document.getId(),
      dxl_liqid: settlement.getId(),
    });
  }

  async generateSettlement(
      from: DateTime,
      to: DateTime,
      company: Company,
      projects: ProjectInSettlementPeriod[],
      additionalDocumentIds: string[],
      invoiceNumber?: string
  ) {
    const files: File[] = [];
    projects.forEach((projectReference) => projectReference.collectFilesInto(files));
    const filesToUpload: FileToUpload[] = files.map((f, i) => FileToUpload.identifiedBy(f, `${i}`));

    const data = await this.requestHandler.post<ISettlementGenerated, ISettlementData>(
        `${this.base}/generar-liquidacion`,
        {
          desde: from.toFormat('yyyy-LL'),
          hasta: to.toFormat('yyyy-LL'),
          empresa: company.getId(),
          nroFactura: invoiceNumber,
          documentosAdicionales: additionalDocumentIds,
          proyectos: projects.map((project) => ({
            periodo: project.getPeriod(),
            pro_id: project.getProjectId(),
            items: project.mapItems<IProjectItemSettlementData>((item) => ({
              descripcion: item.getDescription(),
              importe: item.getAmount().toString(),
              fileUuid: item.idOfFile(filesToUpload),
            })),
          })),
        }
    );

    const settlement = Settlement.identifiedAs(
        data.liq_id,
        data.liq_desde,
        data.liq_hasta,
        data.liq_estado,
        data.liq_nro_factura,
        data.liq_activa,
        this.dateParser.parse(data.liq_fecha_carga),
        null,
        null,
        data.liq_importe_total,
        data.liq_modo_distribucion_facturable
    );

    return { settlement, filesToUpload, documentReferences: data.docs };
  }

  private async mapToSettlementProject(
      liquidacionXProyecto: ISettlementProjectInPeriod
  ): Promise<SettlementProjectInPeriod> {
    return SettlementProjectInPeriod.identifiedAs(
        liquidacionXProyecto.lxp_id,
        liquidacionXProyecto.lxp_periodo,
        liquidacionXProyecto.lxp_orden,
        await Project.fromJson(this.projectSystem, liquidacionXProyecto.proyecto)
    );
  }

  private mapToSettlementProjectItem(item: ISettlementItemDataBase): SettlementProjectInPeriodItem {
    return SettlementProjectInPeriodItem.identifiedAs(
        item.liqi_id,
        item.cantDocumentos,
        item.liqi_importe,
        item.liqi_descripcion,
        +item.liqi_orden
    );
  }

  private mapToSettlement(data: ISettlementSaved): Settlement {
    return Settlement.identifiedAs(
        data.liq_id,
        data.liq_desde,
        data.liq_hasta,
        data.liq_estado,
        data.liq_nro_factura,
        data.liq_activa,
        this.dateParser.parse(data.liq_fecha_carga),
        data.empresa ? Company.fromJson(data.empresa) : null,
        Collaborator.fromICollaborator(this.collaboratorSystem, data.colaborador),
        data.liq_importe_total,
        data.liq_modo_distribucion_facturable
    );
  }

  public async getCollaboratorsWithNoRemunInPeriod(period: DateTime) {
    const data = await this.requestHandler.get<ICollaborator[]>(
        `${this.base}/colaboradores-sin-costo-por-periodo/${period.toFormat('yyyy')}/${period.toFormat('LL')}`
    );
    return data.map((col) => Collaborator.fromICollaborator(this.collaboratorSystem, col));
  }

  public async getLastConfirmedSettlement() {
    const data = await this.requestHandler.get<any>(`${this.base}/ultima-liquidacion-confirmada`);
    return data;
  }

  public async generateDirectSettlement(period: DateTime, settlementMode: string) {
    const data = await this.requestHandler.post<unknown, { period: string; settlementMode: string }>(
        `${this.base}/generar-liquidacion-new`,
        {
          period: period.toFormat('yyyy-LL-dd'),
          settlementMode: settlementMode,
        }
    );
    return data;
  }

  async deactivate(id: string): Promise<unknown> {
    return await this.requestHandler.put(`${this.base}/rechazar-liquidacion/${id}`, {});
  }

  async confirm(id: string): Promise<unknown> {
    return await this.requestHandler.put(`${this.base}/confirmar-liquidacion/${id}`, {});
  }

  async changeLock(lastDay: DateTime): Promise<unknown> {
    return await this.requestHandler.put(
        `${'/configuraciones'}/cambiar-bloqueo/${lastDay.toFormat('yyyy-LL-dd')}`,
        {}
    );
  }
}

// this.requestHandler.delete(`${this.settlementDocumentsBase}/${document.getId()}`);

interface ISettlementData {
  desde: string;
  hasta: string;
  empresa: string;
  proyectos: IProjectSettlementData[];
  documentosAdicionales: string[];
  nroFactura?: string;
}

interface IProjectSettlementData {
  periodo: string;
  pro_id: string;
  items: IProjectItemSettlementData[];
}

interface IProjectItemSettlementData {
  descripcion: string;
  importe: string;
  fileUuid?: string;
}

interface ISettlementForDatagrid extends ISettlement {
  cant_proyectos: string;
  col_imagen_url: null;
  col_iniciales: string;
  col_nombre: string;
  emp_nombre: string;
  importe_total: string;
}

interface ISettlement {
  liq_activa: boolean;
  liq_colid: string;
  liq_desde: string;
  liq_empid: string;
  liq_estado: string;
  liq_fecha_carga: string;
  liq_hasta: string;
  liq_id: string;
  liq_nro_factura: string | null;
  liq_importe_total: string;
  liq_modo_distribucion_facturable: string;
}

export interface ISettlementGeneratedDocument {
  dxliid: string;
  uuid: string;
}

export interface ISettlementSummary {
  cant_proyectos: number;
  importe_total: number;
}

interface ISettlementGenerated extends ISettlement {
  docs: ISettlementGeneratedDocument[];
}

interface ISettlementSaved extends ISettlement {
  empresa: CompanyJson;
  colaborador: ICollaborator;
}

interface ISettlementItem {
  liqi_lxpid: string;
  liqi_importe: number;
  liqi_descripcion: string;
  liqi_activo: boolean;
  liqi_orden: string;
}

interface ISettlementItemDataBase extends ISettlementItem {
  cantDocumentos: number;
  liqi_id: string;
}

interface ISettlementItemData extends ISettlementItemDataBase {
  liquidacionXProyecto: ISettlementProjectInPeriod;
}

interface ISettlementProjectInPeriod {
  lxp_id: string;
  lxp_liqid: string;
  lxp_proid: string;
  lxp_periodo: string;
  lxp_orden: string;
  proyecto: ProjectJson;
}

interface ISettlementDocumentBase {
  dxl_liqid: string;
  dxl_activo: boolean;
  dxl_docid: string;
}

interface ISettlementDocument extends ISettlementDocumentBase {
  document: IUploadedDocument;
  dxl_id: string;
}
