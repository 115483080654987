import * as React from 'react';
import { ITextFieldStyles } from '@fluentui/react/lib/TextField';
import {
  DetailsList,
  DetailsListLayoutMode,
  IColumn,
  CheckboxVisibility,
  IDetailsList,
} from '@fluentui/react/lib/DetailsList';
import { IExampleItem } from '@fluentui/example-data/lib/listItems';
import { DefaultButton } from '@fluentui/react';
import { mergeStyles, mergeStyleSets } from '@fluentui/react/lib/Styling';
import { getTheme } from '@fluentui/react/lib/Styling';
import { IItem } from './DetailAssistant';
import ProjectInSettlementPeriod from '../../../../system/settlement/ProjectInSettlementPeriod';
import i18next, { t } from 'i18next';

const theme = getTheme();

const GROUP_HEADER_AND_FOOTER_SPACING: number = 8;
const GROUP_HEADER_AND_FOOTER_BORDER_WIDTH: number = 1;

const classNames = mergeStyleSets({
  headerAndFooter: {
    borderTop: `${GROUP_HEADER_AND_FOOTER_BORDER_WIDTH}px solid ${theme.palette.neutralQuaternary}`,
    borderBottom: `${GROUP_HEADER_AND_FOOTER_BORDER_WIDTH}px solid ${theme.palette.neutralQuaternary}`,
    padding: GROUP_HEADER_AND_FOOTER_SPACING,
    margin: `${GROUP_HEADER_AND_FOOTER_SPACING}px 0`,
    background: theme.palette.neutralLighterAlt,
    // Overlay the sizer bars
    position: 'relative',
    zIndex: 100,
  },
  headerTitle: [
    theme.fonts.xLarge,
    {
      padding: '4px 0',
    },
  ],
  headerLinkSet: {
    margin: '4px -8px',
  },
  headerLink: {
    margin: '0 8px',
  },
});

export interface IDetailsListBasicExampleItem {
  amount: number | undefined;
  description: string | undefined | null;
  file: any;
  group: number;
  importe?: number | undefined;
  periodo?: string;
}

export interface IDetailsListBasicExampleState {
  items: IDetailsListBasicExampleItem[];
  selectionDetails: string;
}

export interface IDetailsListGroupedExampleItem {
  amount: number | undefined;
  description: string | undefined | null;
  file: any;
  group: number;
  importe?: number | undefined;
  periodo?: string;
}

export interface IDetailsListGroupedExampleState {
  items: IDetailsListGroupedExampleItem[];
  groups: any;
  showItemIndexInView: boolean;
}

interface IProjectsTable {
  data: any;
  groupData: any;
  getIdsFromTable: any;
  activityReport?: boolean;
  setButtonDisability: (can: boolean) => void;
  openDialog: any;
  items: Array<IItem>;
  selectedProjects: ProjectInSettlementPeriod[];
  removeItem: any;
}

export class DetailTable extends React.Component<IProjectsTable, IDetailsListGroupedExampleState> {
  // private _selection: Selection;
  private _allItems: IDetailsListBasicExampleItem[];
  private _columns: IColumn[];
  private _root = React.createRef<IDetailsList>();

  private formatProjectName(item: any) {
    return `(${item.pro_codigo}) - ${item.pro_nombre}`;
  }

  constructor(props: IProjectsTable) {
    super(props);

    // this._selection = new Selection({
    //   onSelectionChanged: () => {
    //     this.setState({ selectionDetails: this._getSelectionDetails() });
    //   },
    // });

    // Populate with items for demos.
    this._allItems = this.props.data;
    // for (let i = 0; i < this.props.data.length; i++) {
    //   this._allItems.push({
    //     key: i,
    //     name: 'Item ' + i,
    //     value: i,
    //   });
    // }

    this.state = {
      items: [],
      // This is based on the definition of items
      groups: this.props.groupData,
      showItemIndexInView: false,
    };

    //Las columnas de arriba, los titulos y que contiene cada una
    this._columns = [
      {
        key: 'proyecto',
        name: t('Proyecto'),
        fieldName: 'proyecto',
        minWidth: 200,
        maxWidth: 200,
        isResizable: true,
        // onRender: (item: any) => this.formatProjectName(item),
      },

      {
        key: 'periodo',
        name: t('Período'),
        fieldName: 'periodo',
        minWidth: 200,
        maxWidth: 200,
        isResizable: true,
      },
      {
        key: 'colab',
        name: t('# Colab'),
        fieldName: 'colab',
        minWidth: 100,
        maxWidth: 100,
        isResizable: true,
      },
      {
        key: 'items',
        name: t('# items'),
        fieldName: 'items',
        minWidth: 100,
        maxWidth: 100,
        isResizable: true,
      },
      {
        key: 'importe',
        name: t('Importe'),
        fieldName: 'importe',
        minWidth: 200,
        maxWidth: 200,
        isResizable: true,
      },
      {
        key: 'buttons',
        name: '',
        fieldName: 'buttons',
        minWidth: 200,
        maxWidth: 200,
        isResizable: true,
      },
    ];

    //funcion de prueba que por ahora no use
    const thumbnailColumn = this._columns.filter((column) => column.name === 'sumar')[0];

    // this.state = {
    //   items: this._allItems,
    //   selectionDetails: this._getSelectionDetails(),
    // };
  }

  componentDidUpdate(
      prevProps: Readonly<IProjectsTable>,
      prevState: Readonly<IDetailsListGroupedExampleState>,
      snapshot?: any
  ): void {
    if (this.props.groupData !== prevProps.groupData) {
      this.setState({ groups: this.props.groupData });
    }
    if (this.props.items !== prevProps.items) {
      this.setState({ items: this.props.items });
    }
  }

  //Funcion que no está siendo usada para poder customizar cada grupo
  private _onRenderGroupHeader: any = (props: any) => {
    if (props && props.group) {
      let totalAmount: number = 0;
      if (props.group.itemsTotal) {
        props.group.itemsTotal.forEach((item: any) => {
          totalAmount += item.amount;
        });
      }

      // let totalAmount =
      return (
          <div className={classNames.headerAndFooter}>
            <div className={classNames.headerTitle}>
              <div className="ms-Grid-row smaller-text-container">
                <div style={{ width: '50px' }}></div>
                <div className="settlement-group-name">
                  <div>{`${props.group!.name}`}</div>
                  <span style={{ fontSize: '10px' }}>{`${props.group!.pro_nombre}`}</span>
                </div>
                <div className="settlement-group-period">{`${props.group!.periodo}`}</div>
                <div className="settlement-group-collaborators">{`${props.group!.colaboradores_cant}`}</div>
                <div className="settlement-group-items">
                  {props.group.itemsTotal ? props.group.itemsTotal.length : null}
                </div>
                <div className="settlement-group-amount">{'$' + totalAmount}</div>
                <div className="ms-Grid-col  ms-sm1">
                  <div className="ms-Grid-row">
                    <div className="ms-Grid-col  ms-sm6">
                      <DefaultButton
                          className="button-small"
                          text="+"
                          onClick={() => {
                            this.props.openDialog(props.group.idx);
                            // this._addItem(props.group.idx);
                          }}
                      />
                    </div>
                    <div className="ms-Grid-col  ms-sm6">
                      {/* <DefaultButton
                      className="button-small"
                      text="-"
                      onClick={() => this._removeGroup(props.group.idx)}
                    /> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      );
    }

    return null;
  };

  //Funcion para agregar un item, todavía no la pude hacer funcionar (esto funciona como el boton azul de tts basefirma)
  public _addItem = (group: any): void => {
    let items = [...this.state.items];
    let groups = [...this.state.groups];

    groups[group].count++;
    // items = items.concat([
    //   {
    //     amount: 'item-' + items.length + 1,
    //     description: 'New item ' + items.length + 1,
    //     file: 'blue',
    //   },
    // ]);
    this.setState(
        {
          ...this.state,
          items,
          groups,
        },
        () => {
          if (this._root.current) {
            this._root.current.focusIndex(items.length, true);
          }
        }
    );
  };

  //Esto es para customizar una columna en especifico, en este caso es para agregarle un boton al final. o
  private _renderItemColumn: any = (item: IExampleItem | any, index: number, column: IColumn) => {
    const fieldContent = item[column.fieldName as keyof IExampleItem] as string;
    switch (column.key) {
      case 'buttons':
        return <DefaultButton text="-" onClick={(e) => this._removeItem(item, index, e)} />;
      case 'items':
        return <span>{item.file?.name}</span>;
      default:
        return <span>{fieldContent ? fieldContent : ''}</span>;
    }
  };

  public _removeItem = (item: any, index: any, e: any): void => {
    let items = [...this.state.items];
    let groups = [...this.state.groups];
    let newItems = items.splice(item, 1);
    let itemsArray: any[] = [];
    let itemsGroupArray: any[] = [];

    this.props.selectedProjects.forEach((project: ProjectInSettlementPeriod) => {
      project.mapItems((item: any) => {
        itemsArray.push(item);
      });
    });

    let startIdx: number = 0;
    groups.forEach((group: any) => {
      startIdx += group.startIndex;
      if (group && group.itemsTotal) {
        group.itemsTotal.forEach((item: any) => {
          itemsGroupArray.push(item);
        });
      }
    });

    // this.props.selectedProjects[0].removeItem(itemsArray[index]);
    itemsGroupArray.splice(index, 1);
    e.target.parentElement.parentElement.parentElement.parentElement.remove();

    this.setState({ ...this.state, items: itemsGroupArray, groups: groups }, () => {
      if (this._root.current) {
        this._root.current.focusIndex(items.length, true);
      }
    });
    this.props.removeItem(index, item.group);
  };

  public _removeGroup = (group: any): void => {
    let groups = [...this.state.groups];

    let newgroups = groups.splice(group, 1);

    this.setState({ ...this.state, items: this.state.items, groups: groups }, () => {
      if (this._root.current) {
        this._root.current.focusIndex(groups.length, true);
      }
    });
    this.setGroupsId(groups);
  };

  public setGroupsId = (groups: any) => {
    let idList: any = [];
    for (let i = 0; i < groups.length; i++) {
      let selection = groups[i];
      idList.push(selection.key);
    }
    if (this.props.getIdsFromTable) {
      this.props.getIdsFromTable(idList);
    }
  };

  public render(): JSX.Element {
    // const { items, selectionDetails } = this.state;
    const { items, groups } = this.state;
    let totalItems: any[] = [];
    groups.forEach((group: any) => {
      group.itemsTotal.forEach((item: any) => {
        totalItems.push({
          amount: item.amount,
          description: item.description,
          file: item.file,
          group: item.group,
          importe: item.amount,
          proyecto: item.description,
        });
      });
    });
    //Acá no se si conviene usar este tipo de tabla de fluent o el componente que usamos en activitycardlist
    if (this.props.data) {
      return (
          <div>
            {/* <div className={exampleChildClass}>{selectionDetails}</div>
          <Announced message={selectionDetails} /> */}
            {/* <Announced message={`Number of items after filter applied: ${items.length}.`} /> */}
            <DetailsList
                className="projects-table"
                items={totalItems} //Esto en un futuro debería ser lo que se sume en la modal
                groups={groups} //Los grupos, deberían ser los proyectos seleccionados del paso anterior
                // groupProps={{
                //   onRenderHeader: this._onRenderGroupHeader,
                // }}
                groupProps={{
                  onRenderHeader: this._onRenderGroupHeader,
                  showEmptyGroups: true,
                }}
                onShouldVirtualize={() => false}
                columns={this._columns}
                onRenderItemColumn={this._renderItemColumn}
                setKey="set"
                layoutMode={DetailsListLayoutMode.justified}
                checkboxVisibility={CheckboxVisibility.hidden}
                selectionPreservedOnEmptyClick={true}
                ariaLabelForSelectionColumn="Toggle selection"
                ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                checkButtonAriaLabel="select row"
                compact={true}

                // onItemInvoked={this._onItemInvoked}
            />
          </div>
      );
    } else return <div>{t('No se han encontrado proyetos disponibles para seleccionar')} </div>;
  }

  private _getSelectionDetails(): string | undefined | any {
    let idList: any = [];
    //   for (let i = 0; i < this._selection.getSelectedCount(); i++) {
    //     let selection = this._selection.getSelection()[i];
    //     idList.push(selection.pro_id);
    //   }
    //   if (this.props.getIdsFromTable) {
    //     this.props.getIdsFromTable(idList);
    //   }
    //   if (this.props.setButtonDisability) {
    //     switch (selectionCount) {
    //       case 0:
    //         this.props.setButtonDisability(false);
    //         return '0 proyectos seleccionados';
    //       case 1:
    //         this.props.setButtonDisability(true);
    //         return '1 proyecto seleccionado: ' + this._selection.getSelection()[0].pro_nombre;
    //       default:
    //         this.props.setButtonDisability(true);
    //         return `${selectionCount} proyectos seleccionados`;
    //     }
    //   }
    // }

    // private _onItemInvoked = (item: IDetailsListBasicExampleItem): void => {};
  }
}
