import * as React from 'react';
import { DonutChart, IChartProps } from '@uifabric/charting';
import { DefaultPalette } from '@fluentui/react/lib/Styling';
import { Text, Icon } from '@fluentui/react';
import { IProjectCompanySummary } from '../../../system/projects/ProjectsSystem';
import { useTranslation, Trans } from 'react-i18next';

import { TooltipInfo } from '../../Common/TootltipInfo';

export interface IProjectPanelDonutChart {
  data: IProjectCompanySummary[];
}

const ProjectPanelDonutChart = (props: IProjectPanelDonutChart) => {
  const { t, i18n } = useTranslation();
  const _colors = [
    DefaultPalette.blueLight,
    DefaultPalette.purpleLight,
    DefaultPalette.neutralSecondary,
    DefaultPalette.greenLight,
    DefaultPalette.tealLight,
    DefaultPalette.yellowLight,
    DefaultPalette.magentaLight,
    DefaultPalette.orangeLight,
    DefaultPalette.blue,
    DefaultPalette.teal,
    DefaultPalette.purple,
    DefaultPalette.magenta,
    DefaultPalette.yellow,
    DefaultPalette.orange,
  ];

  const _dynamicData = React.useMemo(() => {
    return props.data.map((item, i) => {
      let colorSelected = DefaultPalette.orange;

      if (_colors[i]) {
        colorSelected = _colors[i];
      } else if (_colors[item.empresa.emp_nombre.length]) {
        colorSelected = _colors[item.empresa.emp_nombre.length];
      }

      return {
        legend: item.empresa.emp_nombre,
        data: item.hours,
        color: colorSelected,
        yAxisCalloutData: item.hours + t(' Horas'),
      };
    });
  }, [props.data]);

  const chartTitle = 'Stacked Bar chart example';

  const data: IChartProps = {
    chartTitle: chartTitle,
    chartData: _dynamicData,
  };

  return (
      <div className="mt-2">
        <Text className="ml-2" variant="medium">
          <Icon iconName="CityNext2" className="text-icon"></Icon>
          {t('Horas registradas por empresa')}
        </Text>
        <div className="float-right">
          <TooltipInfo
              inform="ProjectPanelDonutChart"
              title={t('¿Cómo se calculan las horas?')}
              messageBody={t(
                  'Se toman las horas de las actividades y se dividen en base a la distribución, luego esto se multiplica por los colaboradores que hayan aceptado la actividad'
              )}
          ></TooltipInfo>
        </div>

        <div style={{ width: '100%', height: '100%' }}>
          <DonutChart
              data={data}
              innerRadius={0}
              legendProps={{
                overflowProps: {
                  focusZoneProps: {
                    'aria-label': 'Legends container',
                  },
                },
                allowFocusOnLegends: true,
              }}
          />
        </div>
      </div>
  );
};

export default ProjectPanelDonutChart;
