import { Facepile, Icon, IDropdownOption, IFacepilePersona, Persona, PersonaSize } from '@fluentui/react';
import { GRID_MODE } from '../../../common/list-utils';
import { DateCustom } from '../../../lib/DateCustom';
// import { DetailsListView, EntityGridListProps } from '../../../Common/DetailsList/DetailListViewiew';
import { useTranslation, Trans } from 'react-i18next';
import { DetailsListView, EntityGridListProps } from '../../Common/DetailsList/DetailListView';
import { DateTime } from 'luxon';

export const CostPerHourGridList = (props: EntityGridListProps) => {
  const { t, i18n } = useTranslation();

  const columns = [
    {
      fieldName: 'col_nombre_completo',
      name: t('Nombre'),
      minWidth: 100,
      maxWidth: 250,
    },
    {
      fieldName: 'rcp_costo_hora',
      name: t('Costo por hora'),
      minWidth: 100,
      maxWidth: 200,
    },
    {
      fieldName: 'rcp_coef_valor_agr',
      name: t('Coeficiente valor agregado'),
      minWidth: 100,
      maxWidth: 250,
    },
    {
      fieldName: 'periodo',
      name: t('Periodo'),
      minWidth: 100,
      maxWidth: 250,
    },
  ];
  return (
      <>
        <DetailsListView
            changeBlockUI={props.changeBlockUI}
            keyList={props.keyList}
            onClickCardMode={() => props.onClickCardMode()}
            onClickGridMode={() => props.onClickGridMode()}
            mode={props.mode}
            title={t('Costo por hora')}
            // virtualize={true}
            cols={columns}
            isGridList={true}
            courier={props.courier}
            listFilters={[]}
            quickFilters={props.quickFilters}
            onItemClick={props.onItemClick}
            baseUrl={props.baseUrl}
        ></DetailsListView>
      </>
  );
};
