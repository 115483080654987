import React from 'react';
import {
  DatePicker,
  DefaultPalette,
  DetailsList,
  DetailsListLayoutMode,
  DetailsRow,
  Dropdown,
  IColumn,
  IDetailsListProps,
  IDropdownOption,
  Icon,
  SelectionMode,
} from '@fluentui/react';
import DataGrid, {
  Column,
  Editing,
  Paging,
  AsyncRule,
  Summary,
  TotalItem,
  PatternRule,
} from 'devextreme-react/data-grid';

import { ICommandBarItemProps } from '@fluentui/react/lib/CommandBar';

import ViewWrapperWithCommandBar from '../Layout/ViewWrapperWithCommandBar';
import { titleCommandBarButtonStyles } from '../../styles/button-styles';

import TimeTaxSupportSystemClient from '../../system';
import MessageCourier from '../../lib/MessageCourier';
import useAsyncDataCourier from '../../common/custom-hooks/useAsyncDataCourier';
import { User } from '../../system/User';
import { useTranslation } from 'react-i18next';
import { Text } from '@fluentui/react';
import SystemConfiguration from '../../system/configuration/SystemConfiguration';
import { ActivityJson } from '../../system/activity/ActivitySystem';
import PeriodDatesBlockedSystem from '../../system/period_dates_blocked/PeriodDatesBlockedSystem';
import { Company } from '../../system/company/Company';
import DialogTemplate from '../Common/DialogTemplate';
import { IBillableDistribution } from '../../system/billable_distribution/BillableDistribution';
import { DateCustom } from '../../lib/DateCustom';
import { getLocalizedStrings } from '../../lib/LocalizedStringsForDatePicker';
import ProjectDescriptor from '../../system/projects/ProjectDescriptor';
import { Activity } from '../../system/activity/Activity';
import { CalendarEventCallout } from '../Calendar/CalendarEventCallout';
import useSystemCall from '../../common/custom-hooks/useSystemCall';

interface IMassiveDistributionCorrector {
  system: TimeTaxSupportSystemClient;
  courier: MessageCourier;
  user: User;
  changeBlockUI: (change: boolean) => void;
  config: SystemConfiguration;
  periodsBlocked: PeriodDatesBlockedSystem;
  hideEdit?: boolean;
  projectId?: string;
  projects?: ProjectDescriptor[];
  activitiesCreated?: ActivityJson[];
}

export interface IActivitiesForProcess {
  id: string;
  disfa_id: string;
  emp_id: string;
  pct: number;
}

const PERCENTAGE_KEY = '_percentage';

export const MassiveDistributionCorrector = (props: IMassiveDistributionCorrector) => {
  const { t } = useTranslation();
  const [date, setDate] = React.useState<Date>(DateCustom.getFirstDayOfThisMonth());
  const [from, setFrom] = React.useState<Date>(DateCustom.getFirstDayOfThisMonth());
  const [to, setTo] = React.useState<Date>(new Date());
  const [refresh, setRefresh] = React.useState<boolean>(false);
  const [showDialogCompanyNotInActivity, setShowDialogCompanyNotInActivity] = React.useState<boolean>(false);
  const [showDialogToSave, setShowDialogToSave] = React.useState<boolean>(false);
  const [projectId, setProjectId] = React.useState<string>(props.projectId ? props.projectId : 'todos');
  const [activitiesTemp, setActivitiesTemp] = React.useState<any[]>([]);
  const [pendingChanges, setPendingChanges] = React.useState<any[]>([]);
  const [descartaCambios, setDescartaCambios] = React.useState<boolean>(false);

  const projects = useAsyncDataCourier(
      () => (props.projects ? Promise.resolve(props.projects) : props.system.getProjects()),
      [props.system],
      props.courier
  );

  const activitiesCreated = useAsyncDataCourier(
      () =>
          props.activitiesCreated
              ? Promise.resolve(props.activitiesCreated)
              : props.system.getActivitySystem().getAllActivitiesCreated(from, to, projectId),
      [props.system, refresh, from, to, projectId],
      props.courier,
      props.changeBlockUI
  );

  const companyList = useAsyncDataCourier(
      () => props.system.getCompanySystem().getList(),
      [props.system],
      props.courier
  );

  const [companiesAvailables, setCompaniesAvailables] = React.useState<Company[]>([]);
  React.useEffect(() => {
    let ret: Company[] = [];
    let comIds: string[] = [];

    if (companyList && activitiesCreated) {
      activitiesCreated.forEach((a) => {
        a.distribucionFacturableList!.forEach((d) => comIds.push(d.disfa_empid));
      });
      comIds = [...new Set(comIds)];
      ret = companyList.filter((c) => !!comIds.some((id) => id === c.getId()));
      setCompaniesAvailables(ret);
    }
  }, [companyList, activitiesCreated]);

  React.useEffect(() => {
    if (activitiesCreated && companiesAvailables) {
      const _at = activitiesCreated.map((a) => {
        const colId = props.user.getCollaboratorId();
        const allowEdit = a.proyecto.pro_us_pueden_editar_distrib && a.act_owner_colid === colId;

        const ret: any = {
          act_nombre: a.act_nombre,
          act_id: a.act_id,
          act_inicio: a.act_inicio,
          act_horas: a.act_horas,
          pro_nombre: `(${a.proyecto.pro_codigo}) - ${a.proyecto.pro_nombre}`,
          editable: allowEdit,
        };
        companiesAvailables.forEach((com) => {
          const disfa = a.distribucionFacturableList?.find((dis) => dis.disfa_empid === com.getId());
          ret[com.getId() + '_name'] = com.getName();
          ret[com.getId() + PERCENTAGE_KEY] = disfa ? disfa.disfa_porcentaje : ' - ';
          ret[com.getId() + '_hours'] = disfa ? (+disfa.disfa_porcentaje * a.act_horas) / 100 : ' - ';
        });

        return ret;
      });
      setActivitiesTemp(_at);
    }
  }, [activitiesCreated, companiesAvailables, descartaCambios]);

  const calculateSelectedRow = (options) => {
    if (options.name === 'SelectedRowsSummary') {
      if (options.summaryProcess === 'start') {
        options.totalValue = 0;
      } else if (options.summaryProcess === 'calculate') {
      }
    }
  };

  const itemsMenu = React.useMemo((): ICommandBarItemProps[] => {
    let ret: ICommandBarItemProps[] = [
      {
        key: 'title',
        text: t('Distribución en Actividades'),
        iconProps: {
          iconName: 'AccountActivity',
          color: DefaultPalette.themeLighterAlt,
          className: 'text-title-btn',
        },
        buttonStyles: titleCommandBarButtonStyles,
        className: 'text-title-btn',
      },
    ];
    return ret;
  }, [t]);

  const getTotalByCompany = React.useCallback(
      (companyId: string) => {
        let ret = 0;
        activitiesTemp?.forEach((act) => {
          if (act[companyId + PERCENTAGE_KEY] !== ' - ') {
            const a = activitiesCreated?.find((a) => a.act_id === act.act_id);
            if (a) {
              const hs = (act[companyId + PERCENTAGE_KEY] * a!.act_horas) / 100;
              ret += hs;
            }
          }
        });
        return `${ret} Hs.`;
      },
      [activitiesCreated, activitiesTemp]
  );

  const onSaved = (e: any) => {
    const _pendingChanges = e.changes.map((d) => d.data);

    const at = [...activitiesTemp];
    console.log('at > ', at);

    _pendingChanges.forEach((pc) => {
      const a: ActivityJson = at.find((ati) => ati.act_id === pc.act_id);
      if (a) {
        const properties = Object.keys(pc);
        const empIdList = properties
            .filter((p) => p.includes(PERCENTAGE_KEY))
            .map((p) => p.replace(PERCENTAGE_KEY, ''));
        empIdList.forEach((emp_id) => {
          let disfa = a.distribucionFacturableList?.find((d) => d.disfa_empid === emp_id);
          if (disfa) {
            disfa.disfa_porcentaje = pc[emp_id + PERCENTAGE_KEY];
          }
        });
      }
    });

    setActivitiesTemp(at);
    setPendingChanges(_pendingChanges);
    setShowDialogToSave(true);
  };

  const saveChanges = async () => {
    props.changeBlockUI(true);
    try {
      let disfaToSave: IBillableDistribution[] = [];
      pendingChanges.forEach((a) => {
        const activity = activitiesCreated?.find((_a) => _a.act_id === a.act_id);
        if (activity) {
          companiesAvailables.forEach((com) => {
            if (a[com.getId() + PERCENTAGE_KEY] !== ' - ' && !Number.isNaN(a[com.getId() + PERCENTAGE_KEY])) {
              let disfa = activity.distribucionFacturableList?.find((dfa) => dfa.disfa_empid === com.getId());
              if (disfa) {
                disfa.disfa_porcentaje = a[com.getId() + PERCENTAGE_KEY];
                disfaToSave.push(disfa);
              } else if (!Number.isNaN(a[com.getId() + PERCENTAGE_KEY])) {
                const empresa = companyList?.find((c) => c.isIdentifiedBy(com.getId()));
                disfaToSave.push({
                  disfa_actid: a.act_id,
                  disfa_activo: true,
                  disfa_empid: com.getId(),
                  disfa_id: '',
                  disfa_porcentaje: a[com.getId() + PERCENTAGE_KEY],
                  empresa: Company.toJson(empresa!),
                });
              }
            }
          });
        }
      });
      await props.system.getActivitySystem().saveBillableDistribution(disfaToSave);
      setRefresh(!refresh);
    } catch (error) {
      props.courier.messageError(error);
    } finally {
      props.changeBlockUI(false);
    }
  };

  const validateRowAsync = (options) => {
    const { data: act } = options;

    let total = 0;
    companiesAvailables.forEach((com) => {
      if (!isNaN(+act[com.getId() + PERCENTAGE_KEY])) {
        total += +act[com.getId() + PERCENTAGE_KEY];
      }
    });

    const ret = !!(total >= 0 && total <= 100);
    return Promise.resolve(ret);
  };

  const prepareEditorRows = (e) => {
    companiesAvailables.forEach((com) => {
      const path = com.getId() + PERCENTAGE_KEY;
      if (e.dataField === path && e.parentType === 'dataRow') {
        // const disable = e.row.data[path] === ' - ' || !e.row.data.editable;
        const disable = !e.row.data.editable;
        e.editorOptions.disabled = disable;
      }
    });
  };

  const columns: IColumn[] = React.useMemo(
      () => [
        {
          key: 'act_nombre',
          name: t('Actividad'),
          fieldName: 'act_nombre',
          minWidth: 200,
          className: 'fs-for-small-pill mt-2px',
        },

        {
          key: 'act_inicio',
          name: t('Fecha'),
          fieldName: 'act_inicio',
          minWidth: 150,
          className: 'fs-for-small-pill mt-2px',
        },
        {
          key: 'pro_nombre',
          name: t('Proyecto'),
          fieldName: 'pro_nombre',
          minWidth: 250,
          className: 'fs-for-small-pill mt-2px',
        },
      ],
      [t]
  );

  const _onRenderRow: IDetailsListProps['onRenderRow'] = (props) => {
    if (props) {
      return (
          <DetailsRow
              {...props}
              styles={{
                root: {
                  backgroundColor: props.itemIndex % 2 === 0 ? '#f5f5f5' : 'transparent',
                },
              }}
          />
      );
    }
    return null;
  };

  const cellPrepared = (e) => {
    if (e.rowType === 'data') {
      if (!e.data.editable && !props.hideEdit) {
        e.cellElement.style.cssText = 'color: black; background-color: #ddd';
      }
    }
  };

  const optionProjects = React.useMemo((): IDropdownOption[] => {
    let options: IDropdownOption[] = [];

    options.push({ key: 'todos', text: 'Todos' });
    projects?.forEach((project) => {
      options.push({ key: project.getId(), text: project.getTitleProject(), data: project.getCode() });
    });

    return options;
  }, [projects]);

  const [activity, setActivity] = React.useState<Activity | undefined>(undefined);
  const [showDialog, setShowDialog] = React.useState<boolean>(false);
  const commonContext = useSystemCall(props.system, (system) =>
      system.getActivitySystem().getCommonContext()
  );

  const openActivityDialog = async (item: any) => {
    props.changeBlockUI(true);
    let activityFromAPI: Activity = await props.system.getActivitySystem().getActivity(item.data.act_id);
    setActivity(activityFromAPI);
    setShowDialog(true);
    props.changeBlockUI(false);
  };

  return (
      <div>
        <ViewWrapperWithCommandBar title="" items={props.projectId ? [] : itemsMenu} withBorderBottom>
          <div className="padding-1">
            <div className="ms-Grid" dir="ltr">
              {!props.hideEdit && (
                  <div className="ms-Grid-row ">
                    <div className="ms-Grid-col ms-md3 ">
                      <DatePicker
                          strings={getLocalizedStrings()}
                          label={t('Desde')}
                          formatDate={DateCustom.formatDateForDatePicker}
                          value={from}
                          onSelectDate={(e) => {
                            if (e) {
                              setFrom(e);
                            }
                          }}
                          className=""
                      />
                    </div>{' '}
                    <div className="ms-Grid-col ms-md3 ">
                      <DatePicker
                          strings={getLocalizedStrings()}
                          label={t('Hasta')}
                          formatDate={DateCustom.formatDateForDatePicker}
                          value={to}
                          onSelectDate={(e) => {
                            if (e) {
                              setTo(e);
                            }
                          }}
                          className=""
                      />
                    </div>
                    <div className="ms-Grid-col ms-md3 ">
                      <Dropdown
                          disabled={!!props.projectId}
                          defaultSelectedKey={['todos']}
                          label={t('Proyecto')}
                          onChange={(e, option) => (option ? setProjectId(String(option.key)) : null)}
                          options={optionProjects}
                      />
                    </div>
                  </div>
              )}
              {!props.hideEdit && (
                  <div className="ms-Grid-row ">
                    <div className="ms-Grid-col ms-md12 ">
                      <Text variant="medium" className="d-block">
                        Esta es una sección en la que podrás ver y editar la distribucion de todas las actividades
                        que has creado en el periodo seleccionado
                      </Text>
                    </div>
                    <div className="ms-Grid-col ms-md12 ">
                      <Text variant="medium" className="d-block">
                        Las filas que tienen fondo{' '}
                        <div
                            style={{
                              height: '12px',
                              width: '12px',
                              display: 'inline-block',
                              backgroundColor: '#ddd',
                            }}
                        ></div>{' '}
                        indican que no se puede editar la distribución, ya sea porque la configuración del
                        proyecto no lo permite o porque no eres el creador de la actividad
                      </Text>
                    </div>
                  </div>
              )}

              <div className="ms-Grid-row ">
                <div className="ms-Grid-col ms-md12 ">
                  <DataGrid
                      dataSource={activitiesTemp}
                      showBorders={true}
                      columnAutoWidth={true}
                      repaintChangesOnly={true}
                      height={600}
                      onSaved={(e) => onSaved(e)}
                      onEditorPreparing={prepareEditorRows}
                      onCellPrepared={cellPrepared}
                      onRowClick={(d) => {
                        console.log(d);
                        if (props.hideEdit) openActivityDialog(d);
                      }}
                      onEditingStart={(options) => {
                        const { data, column } = options;
                        if (column && data[column.dataField!] === ' - ' && data.editable) {
                          setShowDialogCompanyNotInActivity(true);
                        }
                      }}
                  >
                    {!props.hideEdit && <Paging enabled={false} />}
                    {!props.hideEdit && <Editing mode="batch" allowUpdating={true} />}
                    <Column
                        caption="Actividad"
                        dataField="act_nombre"
                        allowEditing={false}
                        width={150}
                        fixed={true}
                    />{' '}
                    {!props.hideEdit && (
                        <Column
                            width={120}
                            caption="Proyecto"
                            fixed={true}
                            dataField="pro_nombre"
                            allowEditing={false}
                        />
                    )}
                    <Column
                        caption="Fecha"
                        dataField="act_inicio"
                        allowEditing={false}
                        width={120}
                        fixed={true}
                    />{' '}
                    <Column
                        caption="Duración"
                        dataField="act_horas"
                        allowEditing={false}
                        width={120}
                        alignment={'left'}
                        fixed={true}
                        cellRender={(d) => {
                          return <span>{DateCustom.formatHorasToString(d.value)}</span>;
                        }}
                        // headerCellRender={() => <span style={{ textAlign: 'left' }}>Duración</span>}
                    />{' '}
                    {companiesAvailables?.map((company) => {
                      return (
                          <Column caption={company.getName()}>
                            <Column caption="%" dataField={company.getId() + PERCENTAGE_KEY} width={120}>
                              <AsyncRule
                                  validationCallback={validateRowAsync}
                                  message="La suma de la distribucion debe estar entre 0 y 100"
                              />

                              <PatternRule
                                  message={'La distribucion debe estar entre 0 y 100'}
                                  pattern={/^(?:100(?:\.0{1,2})?|\b\d{1,2}(?:\.\d{1,2})?| - )$/i}
                              />
                            </Column>
                            {!props.hideEdit && (
                                <Column
                                    caption="Hs."
                                    dataField={company.getId() + '_hours'}
                                    width={70}
                                    allowEditing={false}
                                    calculateDisplayValue={(params) => {
                                      let hs = ' - ';
                                      if (
                                          params[company.getId() + PERCENTAGE_KEY] !== ' - ' &&
                                          !Number.isNaN(params[company.getId() + PERCENTAGE_KEY])
                                      ) {
                                        hs = String(
                                            (params[company.getId() + PERCENTAGE_KEY] * params.act_horas) / 100
                                        );
                                      }
                                      return hs;
                                    }}
                                />
                            )}
                          </Column>
                      );
                    })}
                    <Summary calculateCustomSummary={calculateSelectedRow}>
                      {companiesAvailables?.map((company) => {
                        return (
                            <TotalItem
                                name="SelectedRowsSummary"
                                summaryType="custom"
                                displayFormat={getTotalByCompany(company.getId())}
                                showInColumn={company.getId() + '_hours'}
                            />
                        );
                      })}
                    </Summary>
                  </DataGrid>
                  {!props.hideEdit && (
                      <Text variant="medium">Se han encontrado {activitiesTemp.length} actividad(es)</Text>
                  )}
                </div>
              </div>
            </div>
            {pendingChanges.length > 0 && (
                <DialogTemplate
                    showDialog={showDialogToSave}
                    toggleShowDialog={() => {
                      setShowDialogToSave(false);
                      setDescartaCambios(!descartaCambios);
                    }}
                    title={'Editar distribución'}
                    onAccept={() => {
                      setShowDialogToSave(false);
                      saveChanges();
                    }}
                    onCancel={() => {
                      setShowDialogToSave(false);
                      setDescartaCambios(!descartaCambios);
                    }}
                    acceptText={'Guardar'}
                    cancelText={'Cancelar'}
                    minWidth={750}
                >
                  <div>
                    <div>
                      <Text variant="medium">Se detectaron {pendingChanges.length} cambio(s) en actividades</Text>
                    </div>

                    <div className="mt-2">
                      <Text variant="medium">Actividades que se guardarán:</Text>
                    </div>

                    <DetailsList
                        className=""
                        items={pendingChanges}
                        columns={columns}
                        layoutMode={DetailsListLayoutMode.justified}
                        isHeaderVisible={true}
                        onShouldVirtualize={() => false}
                        compact
                        onRenderRow={_onRenderRow}
                        selectionMode={SelectionMode.none}
                    />
                    <div className="mt-2">
                      <Text variant="medium" className="fw-500">
                        <Icon iconName="Warning" className="text-icon"></Icon>
                        AVISO: Si cancelas se descartarán los cambios
                      </Text>
                    </div>
                  </div>
                </DialogTemplate>
            )}
          </div>
        </ViewWrapperWithCommandBar>
        {activity && (
            <CalendarEventCallout
                system={props.system}
                projects={projects ? projects : []}
                user={props.user}
                eventData={null}
                targetId={''}
                labelId={''}
                descriptionId={''}
                toggleIsCalloutVisible={() => {}}
                isCalloutVisible={false}
                commonContext={commonContext}
                getEvents={() => {}}
                courier={props.courier}
                onlyShowDialogs={true}
                hideBtnEdit={true}
                activityProvided={activity}
                showDialog={showDialog}
                toggleShowDialog={() => setShowDialog(!showDialog)}
                changeBlockUI={props.changeBlockUI}
                config={props.config}
                periodsBlocked={props.periodsBlocked}
                hideMenuOptions={true}
            />
        )}

        <DialogTemplate
            showDialog={showDialogCompanyNotInActivity}
            toggleShowDialog={() => setShowDialogCompanyNotInActivity(false)}
            title={'Atención'}
            onAccept={() => setShowDialogCompanyNotInActivity(false)}
            onCancel={() => {}}
            acceptText={'Entendido'}
            cancelText={''}
            hideCancelButton
        >
          <Text variant="mediumPlus">
            <Icon iconName="Warning" className="text-icon warning-color blinking"></Icon>
            <span style={{ fontWeight: 500 }}>AVISO!</span> Estas por editar el porcentaje de distribución a una
            empresa que no está en la actividad
          </Text>
        </DialogTemplate>
      </div>
  );
};
