import { FileToUpload } from '../documents/FileToUpload';

export default class ProjectInSettlementItem {
  protected constructor(private amount: number, private description: string, private file?: File) {}

  static withFile(amount: number, description: string, file?: File) {
    return new this(amount, description, file);
  }

  static forAmount(amount: number, description: string) {
    return this.withFile(amount, description);
  }

  collectFileInto(files: File[]) {
    if (this.file) {
      files.push(this.file);
    }
  }

  getDescription(): string {
    return this.description;
  }

  getAmount(): number {
    return this.amount;
  }

  idOfFile(filesToUpload: FileToUpload[]): string | undefined {
    const f = filesToUpload.find((f) => f.isFor(this.file));
    return f ? f.getId() : undefined;
  }
}
