import React from 'react';

import TimeTaxSupportSystemClient from '../../system/TimeTaxSupportSystemClient';
import MessageCourier from '../../lib/MessageCourier';
import { Dropdown, IDropdownOption } from '@fluentui/react/lib/Dropdown';
import { TaxOffice } from '../../system/tax_office/TaxOffice';
import i18next, { t } from 'i18next';

interface ITaxOfficeSelectorProps {
  system: TimeTaxSupportSystemClient;
  courier: MessageCourier;
  default?: TaxOffice;
  onChangeSelected: (newType?: TaxOffice) => void;
}

interface ITaxOfficeSelectorState {
  offices: TaxOffice[];
  selected?: TaxOffice;
}

class TaxOfficeSelector extends React.Component<ITaxOfficeSelectorProps, ITaxOfficeSelectorState> {
  constructor(props: ITaxOfficeSelectorProps) {
    super(props);
    this.state = {
      offices: [],
      selected: this.props.default,
    };
  }

  async componentDidMount() {
    const taxOfficeSystem = this.props.system.getTaxOfficeSystem();
    const types = await taxOfficeSystem.list();
    this.setState({ offices: types });
  }

  componentDidUpdate(prevProps: ITaxOfficeSelectorProps) {
    if (this.props.default !== prevProps.default) {
      this.setState({ selected: this.props.default });
    }
  }

  render() {
    const { offices: types, selected } = this.state;

    const options: IDropdownOption[] = types.map((t) => ({ key: t.getId(), text: t.getName() }));
    const selectedId = selected ? selected.getId() : null;
    return (
        <Dropdown
            label={t('Oficina fiscal')}
            onChange={this.onChangeSelection}
            selectedKey={selectedId}
            options={options}
        />
    );
  }

  private onChangeSelection = (event: unknown, option?: IDropdownOption<any> | undefined) => {
    const selectedId = option ? (option.key as string) : '';
    const selected = this.state.offices.find((type) => type.isIdentifiedBy(selectedId));
    this.setState({ ...this.state, selected });
    this.props.onChangeSelected(selected);
  };
}

export default TaxOfficeSelector;
