import * as React from 'react';
import { ILegend, IVerticalBarChartDataPoint, VerticalBarChart } from '@fluentui/react-charting';
import { Text, Icon } from '@fluentui/react';
import { IAmountByConcept, IPanelProjectSummary } from '../../system/panel/PanelSystem';
import { IVerticalBarChartStyles } from '@uifabric/charting';

interface IPanelProjectsBarChart {
  data: IPanelProjectSummary[] | IAmountByConcept[];
  iconName: string;
  concept: string;
  titleChart: string;
  showTooltip?: boolean;
  tooltipTitle?: string;
  tooltipMessageBody?: string;
  color: string;
}

const PanelProjectsBarChart = (props: IPanelProjectsBarChart) => {
  // const colorDocs = '#ffc107';
  // const colorHours = '#3f51b5';
  // const colorActivities = '#564aa3';

  // const color = () => {
  //   if (props.concept === 'Actividades') {
  //     return colorActivities;
  //   } else if (props.concept === 'Documentos') {
  //     return colorDocs;
  //   } else {
  //     return colorHours;
  //   }
  // };

  const _data: IVerticalBarChartDataPoint[] = React.useMemo(() => {
    return props.data.map((item) => {
      return {
        x: item.code ? '(' + item.code + ') - ' + item.name : item.name,
        y: item.amount,
        key: `${props.concept}_serie`,
        color: props.color,
        legend: props.concept,
        xAxisCalloutData: item.code ? '(' + item.code + ') - ' + item.name : item.name,
        yAxisCalloutData: item.amount.toString(),
      };
    });
  }, [props.data, props.color, props.concept]);

  const _dataForHours: IVerticalBarChartDataPoint[] = React.useMemo(() => {
    return props.data.map((item) => {
      return {
        x: item.code ? '(' + item.code + ') - ' + item.name : item.name,
        y: item.amount,
        key: `${props.concept}_serie`,
        color: props.color,
        legend: props.concept,
        xAxisCalloutData: item.name,
        yAxisCalloutData: item.amount.toString(),
      };
    });
  }, [props.color, props.concept, props.data]);

  // const getLegends = () => {
  //   let opt: ILegend[] = [];
  //   props.data.forEach(async (element) => {
  //     opt.push({ title: '(' + element.code + ') - ' + element.name, color: color() });
  //   });
  //   return opt;
  // };

  return (
      <div className="mt-4">
        <Text className="ml-2" variant="medium" style={{ fontSize: '15px' }}>
          <Icon iconName={props.iconName} className="text-icon"></Icon> {props.titleChart}
        </Text>

        <div
            style={{ width: '100%', height: '100%' }}
            className={`${props.data.length <= 25 ? 'xAxisVisible' : ''}`}
        >
          {_data && props.concept !== 'Horas' ? (
              <VerticalBarChart
                  data={_data}
                  width={800}
                  height={250}
                  barWidth={props.data.length > 50 ? 3 : 10}
                  yAxisTickCount={6}
                  hideLegend={true}
                  wrapXAxisLables
                  showXAxisLablesTooltip
                  // legendProps={{ legends: getLegends() }}
                  noOfCharsToTruncate={12}
              />
          ) : null}
          {_data && props.concept === 'Horas' ? (
              <VerticalBarChart
                  data={_dataForHours}
                  width={800}
                  height={250}
                  barWidth={props.data.length > 50 ? 3 : 10}
                  yAxisTickCount={6}
                  hideLegend={true}
                  wrapXAxisLables
                  showXAxisLablesTooltip
                  noOfCharsToTruncate={12}
              />
          ) : null}
        </div>
      </div>
  );
};

export default PanelProjectsBarChart;
