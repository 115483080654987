import React, { useState } from 'react';
import {
  buttonStylesForProjects,
  defaultCommandBarButtonStyles,
  titleCommandBarButtonStyles,
} from '../../styles/button-styles';
import ViewWrapperWithCommandBar from '../Layout/ViewWrapperWithCommandBar';
import { ICommandBarItemProps } from '@fluentui/react/lib/CommandBar';
import {
  DefaultButton,
  DefaultPalette,
  Dialog,
  DialogFooter,
  DialogType,
  PrimaryButton,
} from '@fluentui/react';
import { useNavigate, useParams } from 'react-router-dom';
import { routes } from '../../common/constants';
import LevelDialogForm from './CostPerHourForm';
import TimeTaxSupportSystemClient from '../../system/TimeTaxSupportSystemClient';
import CostPerHourDetailView from './CostPerHourDetailView';
import MessageCourier from '../../lib/MessageCourier';
import { CostPerHour } from '../../system/cost_per_hour/CostPerHour';
import CostPerHourForm from './CostPerHourForm';
import { useForceUpdate } from '@fluentui/react-hooks';
import CustomInputIfc from '../../lib/FormValidator/Form/CustomInputIfc';
import { useTranslation, Trans } from 'react-i18next';

interface INewCostPerHour {
  system: TimeTaxSupportSystemClient;
  courier: MessageCourier;
  changeBlockUI: (state: boolean) => void;
}

const CostPerHourDetail = ({ system, courier, changeBlockUI }: INewCostPerHour) => {
  const navigate = useNavigate();
  const [costPerHourForm, setCostPerHourForm] = React.useState<CostPerHourForm | undefined>(undefined);
  let { costoPorHoraId } = useParams();
  const { t, i18n } = useTranslation();

  let id = costoPorHoraId;
  // =============================
  // Me agarro el id de los params
  // =============================
  const forceUpdate = useForceUpdate();
  const [formIsValid, setFormIsValid] = React.useState(false);

  // ================================
  // Chequeo la condicion de guardado
  // ================================
  const onBlurCustomInput = (customInput: CustomInputIfc) => {
    if (costPerHourForm)
      setFormIsValid(
          costPerHourForm.getIdInput().getValue() ? !costPerHourForm.hasErrors() : costPerHourForm.isValid()
      );
    forceUpdate();
  };

  // ======================================
  // Me armo el form con los datos a editar
  // ======================================
  const populateForm = async (costPerHours?: CostPerHour) => {
    if (id || costPerHours) {
      // =====================================
      // Populo el form con los datos a editar
      // =====================================
      // if (costPerHours) {
      //   instatiateCostPerHourForm(costPerHours);
      // } else
      if (id !== 'new' && id) {
        const existingCostPerHour: CostPerHour = await system.getCostPerHourSystem().getCostPerHourById(id);
        // getCollaboratorIdentifiedBy(id)
        instatiateCostPerHourForm(existingCostPerHour);
      } else {
        // ============================================
        // Inicializo un form para crear un colaborador
        // ============================================
        setCostPerHourForm(CostPerHourForm?.initialize());
      }
    }
  };

  const instatiateCostPerHourForm = (rcp: CostPerHour) => {
    setCostPerHourForm(
        CostPerHourForm.with(
            rcp.getId(),
            rcp.getYear(),
            rcp.getMonth(),
            rcp.getCostPH(),
            rcp.getColid(),
            rcp.getCoef(),
            rcp.getAVName()
        )
    );
  };

  // =========================
  // Guardar nuevo o modificar
  // =========================
  const onClickSave = async () => {
    try {
      changeBlockUI(true);
      const costPerHours = await system.getCostPerHourSystem().save(costPerHourForm?.toCostPerHour()!);
      courier.messageSuccess(`The cost per hour type ${costPerHours.getId()} was saved successfully`);
      await populateForm(costPerHours);
      navigate(`${routes.cost_per_hour_detail}/${costPerHours.getId()}`);
    } catch (err) {
      courier.messageError(err);
    } finally {
      changeBlockUI(false);
      // ======================================================
      // Siempre que llame a la API el try catch con el blockui
      // ======================================================
    }
  };

  React.useEffect(() => {
    (async () => {
      await populateForm();
    })();
  }, []);

  // ==================================================
  // Items del Menu, actualizar rutas, iconos y nombres
  // ==================================================
  const itemsMenu = React.useMemo((): ICommandBarItemProps[] => {
    let ret: ICommandBarItemProps[] = [];
    ret.push(
        {
          key: 'title',
          text: t('Costo por hora'),
          iconProps: {
            iconName: 'SwitcherStartEnd',
            color: DefaultPalette.themeLighterAlt,
            className: 'text-title-btn',
          },
          buttonStyles: titleCommandBarButtonStyles,
          className: 'text-title-btn',
          onClick: () => navigate(`${routes.cost_per_hour_list}`),
        },
        {
          key: 'save',
          text: t('Guardar'),
          iconProps: {
            iconName: 'Save',
            color: DefaultPalette.themeLighterAlt,
            className: 'text-title-btn',
          },
          buttonStyles: buttonStylesForProjects,
          className: 'text-title-btn',
          onClick: () => {
            onClickSave();
          },
          disabled: !formIsValid,
        },
        {
          key: 'back',
          text: t('Volver'),
          iconProps: {
            iconName: 'Back',
            color: DefaultPalette.themeLighterAlt,
            className: 'text-title-btn',
          },
          buttonStyles: titleCommandBarButtonStyles,
          className: 'text-title-btn',
          onClick: () => navigate(`${routes.cost_per_hour_list}`),
        }
    );
    return ret;
  }, [formIsValid, navigate]);
  return (
      <ViewWrapperWithCommandBar
          withBorderBottom
          title={''}
          items={itemsMenu}
          overflowButtonStyles={defaultCommandBarButtonStyles}
          variant="highligth"
          titleBody="Nuevo costo por hora"
      >
        {CostPerHourForm && (
            <CostPerHourDetailView
                system={system}
                form={costPerHourForm}
                courier={courier}
                onBlurCustomInput={onBlurCustomInput}
            />
        )}
      </ViewWrapperWithCommandBar>
  );
};

export default CostPerHourDetail;
