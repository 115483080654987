import CustomForm from '../../../lib/FormValidator/Form/CustomForm';
import { ProjectAssertions } from '../../../system/projects/ProjectAssertions';
import ProjectConfigurationTeamForm from './ProjectConfigurationTeamForm';
import Project from '../../../system/projects/Project';
import ProjectConfigurationDistributionForm from './ProjectConfigurationDistributionForm';
import { newEntityDefaultId } from '../../../common/constants';
import { ProjectConfigurationDocsForm } from './ProjectConfigurationDocsForm';
import ProjectDescriptorForm from './ProjectDescriptorForm';
import { OrganizationChartNode } from '../../../system/organization_chart/OrganizationChartNode';
export const LIQUIDACION_INDIRECTA = 'I';
export const LIQUIDACION_DIRECTA = 'D';

class ProjectConfigurationForm extends CustomForm {
  private readonly ORGANIZATION_NODE = 'organizationNode';
  private readonly PROJECT_TEAM_ITEMS = 'projectTeamItems';
  private readonly COMPANY_DISTRIBUTION_ITEMS = 'companyDistributionItems';
  static readonly DOCUMENT_CONTROL_ITEMS = 'documentControlItems';

  protected constructor(
      organizationNode: OrganizationChartNode | null,
      private projectDescriptorForm: ProjectDescriptorForm,
      private projectCompanyDistributionForm: ProjectConfigurationDistributionForm,
      private projectTeamForm: ProjectConfigurationTeamForm,
      private projectDocumentControl: ProjectConfigurationDocsForm
  ) {
    const projectAssertions = ProjectAssertions.build();
    super('Project', projectAssertions);

    this.addInput(this.ORGANIZATION_NODE, organizationNode);

    this.addFormInput(projectDescriptorForm);
    this.addFormInput(projectCompanyDistributionForm);
    this.addFormInput(projectTeamForm);
    this.addListInput(projectDocumentControl);
  }

  static build(
      organizationNode: OrganizationChartNode | null,
      projectDescriptorForm: ProjectDescriptorForm,
      projectCompanyDistributionForm: ProjectConfigurationDistributionForm,
      projectTeamForm: ProjectConfigurationTeamForm,
      projectDocumentControl: ProjectConfigurationDocsForm
  ) {
    return new this(
        organizationNode,
        projectDescriptorForm,
        projectCompanyDistributionForm,
        projectTeamForm,
        projectDocumentControl
    );
  }

  static initialize() {
    const projectDescriptor = ProjectDescriptorForm.initialize();
    return this.build(
        null,
        projectDescriptor,
        ProjectConfigurationDistributionForm.initialize(projectDescriptor.getBillableInput()),
        ProjectConfigurationTeamForm.initialize(),
        ProjectConfigurationDocsForm.initialize(this.DOCUMENT_CONTROL_ITEMS)
    );
  }

  static with(project: Project) {
    const projectDescriptor = ProjectDescriptorForm.with(project);
    const instance = this.build(
        project.getOrganizationChartNode(),
        projectDescriptor,
        ProjectConfigurationDistributionForm.with(
            project.getProjectCompanyList().getCompanies(),
            projectDescriptor.getBillableInput()
        ),
        ProjectConfigurationTeamForm.with(project.getProjectTeam()),
        ProjectConfigurationDocsForm.defaultBuild(this.DOCUMENT_CONTROL_ITEMS, project.getDocumentControls())
    );

    instance.evaluate();

    return instance;
  }

  toProject(): Project {
    const project = Project.build(
        this.projectDescriptorForm.toProjectDescriptor(),
        '', // revisar
        null, // revisar
        0, // revisar
        false, // revisar
        0, // revisar
        this.getProjectTeamForm().toProjectTeam(),
        this.getCompanyDistributionForm().toProjectCompanyDistribution(this.getBillableInput().getValue()),
        this.getOrganizationNodeValue()!
    );

    const docsControl = this.getProjectDocumentControlForm().getValue();
    docsControl.forEach((docControl) => {
      project.addDocumentControl(docControl);
    });

    return project;
  }

  getIdInput() {
    return this.projectDescriptorForm.getIdInput();
  }

  getCodeInput() {
    return this.projectDescriptorForm.getCodeInput();
  }

  getNameInput() {
    return this.projectDescriptorForm.getNameInput();
  }

  getBillableInput() {
    return this.projectDescriptorForm.getBillableInput();
  }

  getSettlementInput() {
    return this.projectDescriptorForm.getSettlementInput();
  }

  getCanViewDistributionInput() {
    return this.projectDescriptorForm.getCanViewDistributionInput();
  }
  getCanEditDistributionInput() {
    return this.projectDescriptorForm.getCanEditDistributionInput();
  }

  getStateInput() {
    return this.projectDescriptorForm.getStateInput();
  }

  getStartInput() {
    return this.projectDescriptorForm.getStartInput();
  }

  getStartValue() {
    return this.projectDescriptorForm.getStartValue();
  }

  getEndValue() {
    return this.projectDescriptorForm.getEndValue();
  }

  getEndInput() {
    return this.projectDescriptorForm.getEndInput();
  }

  getDescriptionInput() {
    return this.projectDescriptorForm.getDescriptionInput();
  }

  getConfidentialDocumentInput() {
    return this.projectDescriptorForm.getConfidentialDocumentInput();
  }

  getSuggestTagInput() {
    return this.projectDescriptorForm.getSuggestTagInput();
  }

  getOrganizationNodeInput() {
    return this.getInputByName(this.ORGANIZATION_NODE);
  }

  getOrganizationNodeValue(): OrganizationChartNode | null {
    return this.getOrganizationNodeInput().getValue();
  }

  getCompanyDistributionForm() {
    return this.projectCompanyDistributionForm;
  }

  getCompanyDistributionInputValues() {
    return this.projectCompanyDistributionForm.getInputByName(this.COMPANY_DISTRIBUTION_ITEMS).getValue();
  }

  getProjectTeamForm() {
    return this.projectTeamForm;
  }

  getProjectTeamInputValues() {
    return this.projectTeamForm.getInputByName(this.PROJECT_TEAM_ITEMS).getValue();
  }

  getProjectDocumentControlForm() {
    return this.projectDocumentControl;
  }

  isNew(): boolean {
    const id = this.getIdInput().getValue();
    return id === newEntityDefaultId || !id;
  }
}

export default ProjectConfigurationForm;
