import React, { useState, useMemo } from 'react';
import { CARD_MODE, GRID_MODE, OPERATORS, TYPES } from '../../common/list-utils';
import { ActivityCardList } from './List/ActivityCardList';
import { ActivityGridList } from './List/ActivityGridList';
import { ListColumnFilter } from '../Common/list-entities/ListColumnFilter';
import './ActivityList.css';
import TimeTaxSupportSystemClient from '../../system';
import { Activity } from '../../system/activity/Activity';
import MessageCourier from '../../lib/MessageCourier';
import { routes } from '../../common/constants';

import { CalendarEventCallout } from '../Calendar/CalendarEventCallout';
import useSystemCall from '../../common/custom-hooks/useSystemCall';
import { User } from '../../system/User';
import { useTranslation, Trans } from 'react-i18next';
import SystemConfiguration from '../../system/configuration/SystemConfiguration';
import PeriodDatesBlockedSystem from '../../system/period_dates_blocked/PeriodDatesBlockedSystem';
import { defaultCommandBarButtonStyles, titleCommandBarButtonStyles } from '../../styles/button-styles';
import ViewWrapperWithCommandBar from '../Layout/ViewWrapperWithCommandBar';
import { DefaultPalette, ICommandBarItemProps } from '@fluentui/react';
import { useNavigate } from 'react-router-dom';
import { DateCustom } from '../../lib/DateCustom';

interface IActivityList {
  system: TimeTaxSupportSystemClient;
  courier: MessageCourier;
  user: User;
  changeBlockUI: (state: boolean) => void;
  config: SystemConfiguration;
  periodsBlocked: PeriodDatesBlockedSystem;
}

export const ActivityList: React.FC<IActivityList> = ({ system, ...props }) => {
  const { t, i18n } = useTranslation();
  const [mode, setMode] = useState(GRID_MODE);
  const [filtersData, setFiltersData] = useState<any>();
  const [filterPanel, setFilterPanel] = useState(false);
  const baseUrl = React.useMemo(() => {
    return system.getActivitySystem().getBaseUrlForDatagrid();
  }, [props]);

  const [activity, setActivity] = React.useState<Activity | undefined>(undefined);

  const projects = useSystemCall(system, (system) => system.getProjects());
  const commonContext = useSystemCall(system, (system) => system.getActivitySystem().getCommonContext());
  const [showDialog, setShowDialog] = React.useState<boolean>(false);

  const onItemClick = async (item: any) => {
    props.changeBlockUI(true);
    let activityFromAPI: Activity = await system.getActivitySystem().getActivity(item.act_id);
    setActivity(activityFromAPI);
    setShowDialog(true);
    props.changeBlockUI(false);
  };

  const [shouldUpdate, setShouldUpdate] = React.useState<boolean>(false);

  const quickFilters: ListColumnFilter[] = useMemo(() => {
    let ret: ListColumnFilter[] = [
      {
        quick: true,
        name: 'equipo_actividad',
        type: TYPES.CHECKBOX,
        labelForCheckbox: t('Mis actividades'),
        checkBoxValue: props.user.getCollaborator().fullName(),
        operator: OPERATORS.CONTAINS,
        defaultValue: props.user.getCollaborator().fullName(),
      },

      {
        quick: true,
        name: 'act_nombre',
        type: TYPES.INPUT,
        placeholder: t('Nombre'),
        operator: OPERATORS.CONTAINS,
      },
      {
        quick: true,
        name: 'act_activo',
        type: TYPES.CHECKBOX,
        labelForCheckbox: t('Activos'),
        checkBoxValue: '1',
        operator: OPERATORS.EQUAL,
        defaultValue: '1',
      },
      {
        quick: true,
        name: 'owner_actividad_nombre',
        type: TYPES.INPUT,
        placeholder: t('Creador'),
        operator: OPERATORS.CONTAINS,
      },
      {
        quick: true,
        name: 'pro_codigo',
        type: TYPES.INPUT,
        placeholder: t('Proyecto codigo'),
        operator: OPERATORS.CONTAINS,
      },
      {
        quick: true,
        name: 'pro_nombre',
        type: TYPES.INPUT,
        placeholder: t('Proyecto nombre'),
        operator: OPERATORS.CONTAINS,
      },
      {
        quick: true,
        name: 'act_inicio',
        type: TYPES.DATE,
        placeholder: t('Inicio posterior a'),
        operator: OPERATORS.AFTERORON,
        // defaultValue: DateCustom.getFirstDayOfThisMonth(),
      },
    ];
    if (props.user.isStandardUser()) {
      ret.shift();
    }

    return ret;
  }, []);

  const refreshList = () => {
    setShouldUpdate(!shouldUpdate);
  };

  const KEY_LIST = 'actividades';

  const navigate = useNavigate();

  const itemsMenu = React.useMemo(
      (): ICommandBarItemProps[] => [
        {
          key: 'title',
          text: t('Actividades'),
          iconProps: {
            iconName: 'AccountActivity',
            color: DefaultPalette.themeLighterAlt,
            className: 'text-title-btn',
          },
          buttonStyles: defaultCommandBarButtonStyles,
          className: 'text-title-btn',
        },
        // {
        //   key: 'back',
        //   text: t('Editor de distribución facturable'),
        //   iconProps: {
        //     iconName: 'CityNext2',
        //     color: DefaultPalette.themeLighterAlt,
        //     className: 'text-title-btn',
        //   },
        //   buttonStyles: defaultCommandBarButtonStyles,
        //   className: 'text-title-btn',
        //   onClick: () => {
        //     navigate(routes.distribution_corrector);
        //   },
        // },
      ],
      []
  );

  return (
      <ViewWrapperWithCommandBar
          withBorderBottom
          title={''}
          items={itemsMenu}
          overflowButtonStyles={defaultCommandBarButtonStyles}
          variant="standard"
      >
        <>
          {mode == GRID_MODE ? (
              <ActivityGridList
                  baseUrl={baseUrl}
                  quickFilters={quickFilters}
                  onClickCardMode={() => setMode(CARD_MODE)}
                  onClickGridMode={() => setMode(GRID_MODE)}
                  mode={mode}
                  shouldUpdate={shouldUpdate}
                  onItemClick={(item: any) => onItemClick(item)}
                  keyList={KEY_LIST}
                  system={system}
                  changeBlockUI={props.changeBlockUI}
                  courier={props.courier}
              ></ActivityGridList>
          ) : (
              <ActivityCardList
                  baseUrl={baseUrl}
                  quickFilters={quickFilters}
                  onItemClick={(item: any) => onItemClick(item)}
                  onClickCardMode={() => setMode(CARD_MODE)}
                  onClickGridMode={() => setMode(GRID_MODE)}
                  mode={mode}
                  shouldUpdate={shouldUpdate}
                  keyList={KEY_LIST}
                  changeBlockUI={props.changeBlockUI}
                  courier={props.courier}
              ></ActivityCardList>
          )}

          {activity && (
              <CalendarEventCallout
                  system={system}
                  projects={projects ? projects : []}
                  user={props.user}
                  eventData={null}
                  targetId={''}
                  labelId={''}
                  descriptionId={''}
                  toggleIsCalloutVisible={() => {}}
                  isCalloutVisible={false}
                  commonContext={commonContext}
                  getEvents={() => {
                    refreshList();
                  }}
                  courier={props.courier}
                  onlyShowDialogs={true}
                  activityProvided={activity}
                  showDialog={showDialog}
                  toggleShowDialog={() => setShowDialog(!showDialog)}
                  changeBlockUI={props.changeBlockUI}
                  config={props.config}
                  periodsBlocked={props.periodsBlocked}
                  hideMenuOptions={true}
              />
          )}
        </>
      </ViewWrapperWithCommandBar>
  );
};
