import React, { useEffect, useState, useMemo } from 'react';
import { Stack, IStackTokens } from '@fluentui/react/lib/Stack';
import { Text } from '@fluentui/react/lib/Text';
import { Icon } from '@fluentui/react/lib/Icon';
import { CARD_MODE, GRID_MODE, OPERATORS, TYPES } from '../../common/list-utils';
import { DefaultButton, PrimaryButton } from '@fluentui/react/lib/Button';
import { IDocumentCardPreviewProps, IDocumentCardActivityPerson } from '@fluentui/react/lib/DocumentCard';
import { DefaultPalette, getTheme } from '@fluentui/react/lib/Styling';
import { TestImages } from '@fluentui/example-data';
import axios from 'axios';
import { ListColumnFilter } from '../Common/list-entities/ListColumnFilter';
import TimeTaxSupportSystemClient from '../../system';
import { IComboBoxOption, ICommandBarItemProps } from '@fluentui/react';
import { buttonStylesForProjects, defaultCommandBarButtonStyles } from '../../styles/button-styles';
import { routes } from '../../common/constants';
import { useNavigate } from 'react-router-dom';
import ViewWrapperWithCommandBar from '../Layout/ViewWrapperWithCommandBar';
import { useTranslation, Trans } from 'react-i18next';
import MessageCourier from '../../lib/MessageCourier';
import { LicensesGridList } from './List/LicensesGridList';
import useSystemCall from '../../common/custom-hooks/useSystemCall';
import { LicenseType } from '../../system/collaborators/collaborator_license/CollaboratorLicense';
import { User } from '../../system/User';

const stackTokens: IStackTokens = { childrenGap: 20 };
const theme = getTheme();
const { palette, fonts } = theme;

interface ILicensesList {
  system: TimeTaxSupportSystemClient;
  changeBlockUI: (state: boolean) => void;
  courier: MessageCourier;
  user: User;
}
export const LicensesList = (props: ILicensesList) => {
  const { t, i18n } = useTranslation();
  const [mode, setMode] = useState(GRID_MODE);
  const [filtersData, setFiltersData] = useState<any>();
  const [filterPanel, setFilterPanel] = useState(false);

  const [licenseTypes, setLicenseTypes] = useState<LicenseType[]>();
  const [formattedOptions, setFormattedOptions] = useState<IComboBoxOption[]>([]);

  useEffect(() => {
    getLicenseTypes();
  }, []);

  useEffect(() => {
    getFormattedTypes();
  }, [licenseTypes]);

  const getFormattedTypes = () => {
    if (licenseTypes) {
      let formatted: IComboBoxOption[] = [];
      licenseTypes.forEach((element) => {
        if (element && element.getActive() && element.getId()) {
          formatted.push({ key: element.getDescription(), text: element.getDescription() });
        }
      });
      setFormattedOptions(formatted);
    }
  };

  const getLicenseTypes = async () => {
    props.changeBlockUI(true);
    try {
      const response = await props.system.getCollaboratorLicenseSystem().getLicenseTypes();
      if (response) {
        // response.forEach((element:LicenseType) => {
        //   if(element.getActive()){

        //   }
        // });
        setLicenseTypes(response);
        // props.courier.messageSuccess(t('La defensa fue generada exitosamente'));
      }
    } catch (error) {
      props.courier.messageError(error);
    } finally {
      props.changeBlockUI(false);
    }
  };

  const navigate = useNavigate();

  const baseUrl = React.useMemo(() => {
    return props.system.getCollaboratorLicenseSystem().getBaseUrlForDatagrid();
  }, [props]);

  const onItemClick = (item: any) => {
    navigate(`${routes.licenses}/${item.collic_id}`);
  };

  const commonContext = useSystemCall(props.system, (system) =>
      system.getActivitySystem().getCommonContext()
  );

  const collaboratorList = React.useMemo(() => {
    let ret: IComboBoxOption[] = [];
    if (commonContext) {
      ret = commonContext.colaboradores.map((col) => {
        return {
          key: col.fullName(),
          text: col.fullName(),
        };
      });
    }
    return ret;
  }, [commonContext]);

  const quickFilters: ListColumnFilter[] = useMemo(() => {
    let ret: ListColumnFilter[] = [
      // {
      //   quick: true,
      //   name: 'collic_id',
      //   type: TYPES.INPUT,
      //   placeholder: t('ID'),
      //   operator: OPERATORS.EQUAL,
      // },

      {
        quick: true,
        name: 'collic_activo',
        type: TYPES.CHECKBOX,
        labelForCheckbox: t('Activas'),
        checkBoxValue: '1',
        operator: OPERATORS.EQUAL,
      },
      {
        quick: true,
        name: 'lictipo_descripcion',
        type: TYPES.COMBOBOX,
        placeholder: t('Tipo'),
        operator: OPERATORS.EQUAL,
        contextForCombobox: formattedOptions || [],
      },
      {
        quick: true,
        name: 'collic_motivo',
        type: TYPES.INPUT,
        placeholder: t('Motivo'),
        operator: OPERATORS.CONTAINS,
      },
      // {
      //   quick: false,
      //   name: 'sector',
      //   type: TYPES.INPUT,
      //   placeholder: t('Sector'),
      //   operator: OPERATORS.CONTAINS,
      // },

      {
        quick: false,
        name: 'collic_inicio',
        type: TYPES.DATE,
        placeholder: t('Inicio posterior a'),
        operator: OPERATORS.AFTERORON,
      },
      {
        quick: false,
        name: 'collic_fin',
        type: TYPES.DATE,
        placeholder: t('Fin anterior a'),
        operator: OPERATORS.BEFOREORON,
      },
      {
        quick: false,
        name: 'liq_tipo',
        type: TYPES.CHECKBOX,
        checkBoxValue: 'D',
        placeholder: t('Inicio posterior a'),
        operator: OPERATORS.EQUAL,
        hidden: true,
        defaultValue: 'D',
      },
    ];

    if (props.user.isDirectiveUser() || props.user.hasPowerUserPrivileges()) {
      ret.push({
        quick: false,
        name: 'col_nombre_completo',
        type: TYPES.COMBOBOX,
        placeholder: t('Cargado por'),
        operator: OPERATORS.EQUAL,
        contextForCombobox: collaboratorList || [],
      });
    }
    return ret;
  }, [filtersData, collaboratorList, formattedOptions]);

  const itemsMenuCalendar = React.useMemo(
      (): ICommandBarItemProps[] => [
        {
          key: 'add',
          text: t('Nuevo'),
          iconProps: { iconName: 'Add', color: DefaultPalette.themeLighterAlt },
          buttonStyles: buttonStylesForProjects,
          onClick: () => navigate(`${routes.licences_config}`),
        },
      ],
      [navigate]
  );

  return (
      <ViewWrapperWithCommandBar
          withBorderBottom
          title={''}
          items={itemsMenuCalendar}
          overflowButtonStyles={defaultCommandBarButtonStyles}
          variant="standard"
      >
        <>
          <LicensesGridList
              changeBlockUI={props.changeBlockUI}
              baseUrl={baseUrl}
              quickFilters={quickFilters}
              onClickCardMode={() => setMode(CARD_MODE)}
              onClickGridMode={() => setMode(GRID_MODE)}
              mode={mode}
              keyList={'licencias'}
              onItemClick={onItemClick}
              courier={props.courier}
          ></LicensesGridList>

          {/* {mode == GRID_MODE ? (
        ) : (
          <SettlementsCardList
            changeBlockUI={props.changeBlockUI}
            baseUrl={baseUrl}
            quickFilters={quickFilters}
            filterPanel={filterPanel}
            onClickCardMode={() => setMode(CARD_MODE)}
            onClickGridMode={() => setMode(GRID_MODE)}
            courier={props.courier}
            mode={mode}
            onItemClick={onItemClick}
          ></SettlementsCardList>
        )} */}
        </>
      </ViewWrapperWithCommandBar>
  );
};
