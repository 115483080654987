import { Activity } from "../system/activity/Activity";
import SystemConfiguration from "../system/configuration/SystemConfiguration";

export const  getMinDate = (config: SystemConfiguration | undefined, activity: Activity) => {
  if (config) {
    const dateActivitiesBlocked = config.getActivitiesBlocked();
    if (dateActivitiesBlocked ) {
      if (activity.getProject() && activity.getProject()!.getStart() > dateActivitiesBlocked) {
        return activity.getProject()!.getStart().toJSDate();
      } else {
        return dateActivitiesBlocked.toJSDate();
      }
    } else {
      if (activity.getProject()) {
        return activity.getProject()!.getStart().toJSDate();
      }
    }
  }
};
