import Project from '../projects/Project';
import { SettlementProjectInPeriodItem } from './SettlementProjectInPeriodItem';

export class SettlementProjectInPeriod {
  private items: SettlementProjectInPeriodItem[];
  private constructor(
      private id: string,
      private period: string,
      private order: string,
      private project: Project
  ) {
    this.items = [];
  }

  static identifiedAs(id: string, period: string, order: string, project: Project) {
    return new this(id, period, order, project);
  }

  isIdentifiedBy(anId: string): boolean {
    return +this.id === +anId;
  }

  addItem(item: SettlementProjectInPeriodItem) {
    this.items.push(item);
  }

  nextItemOrder(): number {
    const maxOrder = this.items.reduce((order, item) => item.maxOrder(order), 0);

    return maxOrder + 1;
  }

  getId(): string {
    return this.id;
  }

  getItems(): SettlementProjectInPeriodItem[] {
    return this.items;
  }

  getPeriod(): string {
    return this.period;
  }

  getProject(): Project {
    return this.project;
  }

  getProjectName(): string {
    return this.project.getName();
  }
}
