import * as React from 'react';
import TimeTaxSupportSystemClient from '../../../system';
import MessageCourier from '../../../lib/MessageCourier';
import { ICommandBarItemProps } from '@fluentui/react';
import { buttonStylesForProjects, defaultCommandBarButtonStyles } from '../../../styles/button-styles';
import { routes } from '../../../common/constants';
import ViewWrapperWithCommandBar from '../../Layout/ViewWrapperWithCommandBar';
import { useNavigate } from 'react-router-dom';

import CollaboratorPanels from '../../Collaborator/CollaboratorView/CollaboratorPanels';
import { User } from '../../../system/User';
import { useTranslation, Trans } from 'react-i18next';

interface ICompanyPanel {
  system: TimeTaxSupportSystemClient;
  courier: MessageCourier;
  changeBlockUI: (change: boolean) => void;
  user: User;
}

export const PanelOwn = (props: ICompanyPanel) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const items = React.useMemo((): ICommandBarItemProps[] => {
    let ret: ICommandBarItemProps[] = [
      {
        key: 'project',
        text: t('Mi Panel'),
        onClick: () => navigate(`${routes.dashboard_own}`),
        itemProps: {},
        iconProps: { iconName: 'BIDashboard' },
        buttonStyles: buttonStylesForProjects,
      },
      {
        key: 'download',
        text: t('Avance de proyectos'),
        onClick: () => navigate(`${routes.dashboard}`),
        iconProps: { iconName: 'TeamWork' },
        buttonStyles: defaultCommandBarButtonStyles,
      },
      {
        key: 'panel_companies',
        text: t('Empresas'),
        onClick: () => navigate(`${routes.dashboard_empresas}`),
        iconProps: { iconName: 'CityNext2' },
        buttonStyles: defaultCommandBarButtonStyles,
      },
      {
        key: 'colabs',
        text: t('Colaboradores'),
        onClick: () => navigate(`${routes.dashboard_colaboradores}`),
        iconProps: { iconName: 'ReminderPerson' },
        buttonStyles: defaultCommandBarButtonStyles,
      },
    ];

    if (props.user.getProfile().isStandardUser()) {
      ret = [ret[0]];
    }

    return ret;
  }, [navigate, props.user]);

  return (
      <ViewWrapperWithCommandBar
          withBorderBottom
          title={''}
          items={items}
          overflowButtonStyles={defaultCommandBarButtonStyles}
          // overflowItems={overflowItems}
          variant="highligth"
          titleBody={t('Panel')}
      >
        <div className="ms-Grid padding-1" dir="ltr">
          <CollaboratorPanels
              user={props.user}
              system={props.system}
              courier={props.courier}
              changeBlockUI={props.changeBlockUI}
          />
        </div>
      </ViewWrapperWithCommandBar>
  );
};
