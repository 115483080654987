import { downloadExcel } from 'react-export-table-to-excel';
import { OrganizationChartNode } from '../../../system/organization_chart/OrganizationChartNode';
import { IResumenColaboradoresComp } from '../../../system/panel/PanelSystem';

class CollaboratorsComparisonExporter {
  private constructor(
      private comparisonData: IResumenColaboradoresComp[],
      private nodes: OrganizationChartNode[],
      private fileName: string,
      private sheetName: string
  ) {}

  static exportFor(
      comparisonData: IResumenColaboradoresComp[],
      nodes: OrganizationChartNode[],
      fileName: string = 'Comparativa colaboradores',
      sheetName: string = 'comparativa'
  ) {
    const exporter = new this(comparisonData, nodes, fileName, sheetName);

    exporter.export();
  }

  export() {
    downloadExcel({
      fileName: this.fileName,
      sheet: this.sheetName,
      tablePayload: {
        header: this.getHeader(),
        body: this.getBody(),
      },
    });
  }

  private getBody() {
    return this.comparisonData.map((item) => {
      const node = this.nodes.find((n) =>
          n.isIdentifiedBy(item.colaborador.cargoColaborador?.carcol_norid || '')
      )!;

      return [
        item.colaborador.col_nombre,
        item.colaborador.col_apellido,
        item.colaborador.col_id_sap,
        node.path(),
        item.horas_totales,
        item.docs_totales,
      ];
    });
  }

  private getHeader() {
    return ['Nombre', 'Apellido', 'SAP ID', 'Sector', 'Horas totales', 'Documentos cargados'];
  }
}

export default CollaboratorsComparisonExporter;
