import React, { useEffect, useState } from 'react';
import ViewWrapperWithCommandBar from '../../Layout/ViewWrapperWithCommandBar';
import Wizard from '../../Wizard/Wizard';
import CompanySelectorAssistant from './Wizard/CompanySelectorAssistant';
import { DefaultPalette, ICommandBarItemProps, Text } from '@fluentui/react';
import { titleCommandBarButtonStyles, defaultCommandBarButtonStyles } from '../../../styles/button-styles';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../../common/constants';
import ProjectSelectorAssistant from './Wizard/ProjectSelectorAssistant';
import Ending from './Wizard/Ending';
import DetailAssistant from './Wizard/DetailAssistant';
import useAsyncDataCourier from '../../../common/custom-hooks/useAsyncDataCourier';
import { DateTime } from 'luxon';
import { Company } from '../../../system/company/Company';
import DocumentSelectorAssistant from './Wizard/DocumentSelectorAssistant';
import './Wizard/wizardComponents.css';
import ProjectInSettlementPeriod from '../../../system/settlement/ProjectInSettlementPeriod';
import TimeTaxSupportSystemClient from '../../../system';
import MessageCourier from '../../../lib/MessageCourier';
import { IProjectForDefenseFile } from '../../../system/projects/ProjectsSystem';
import ProjectInSettlementItem from '../../../system/settlement/ProjectInSettlementItem';
import { useTranslation, Trans } from 'react-i18next';

interface ISettlementsViewProps {
  system: TimeTaxSupportSystemClient;
  courier: MessageCourier;
}

const SettlementsView = (props: ISettlementsViewProps) => {
  const { t, i18n } = useTranslation();
  const [canGoNext, setCanGoNext] = useState(false);

  const [company, setCompany] = useState<Company>();
  const [fromDate, setFromDate] = useState<DateTime>();
  const [toDate, setToDate] = useState<DateTime>();

  const [selectedProjects, setSelectedProjects] = useState<ProjectInSettlementPeriod[]>([]);

  const [projectsTableData, setProjectsTableData] = useState<IProjectForDefenseFile[]>([]);
  const [documentsTableData, setDocumentsTableData] = useState<any>();
  const [detailTableData, setDetailTableData] = useState<IProjectForDefenseFile[]>();
  const [detailItems, setDetailItems] = useState<any>();

  const [invoiceNumber, setInvoiceNumber] = useState<string>();

  const [documentsId, setDocumentsId] = useState<Array<string> | undefined | null>();

  const [settlementResponse, setSettlementResponse] = useState<any>();

  const navigate = useNavigate();

  useEffect(() => {
    getDetailTableData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectsTableData, selectedProjects]);

  const addItemsToProjectInSettlementPeriod = (item: any) => {
    let _item;
    if (item && item.file) {
      _item = ProjectInSettlementItem.withFile(item.amount, item.description, item.file);
    } else if (item) {
      _item = ProjectInSettlementItem.forAmount(item.amount, item.description);
    }
    if (selectedProjects && _item) {
      selectedProjects[item.group].addItem(_item);
    }
  };

  const getDetailTableData = () => {
    let array: IProjectForDefenseFile[] = [];
    if (projectsTableData && selectedProjects) {
      projectsTableData.forEach((project) => {
        selectedProjects.forEach((project2: ProjectInSettlementPeriod) => {
          if (project2) {
            if (project2.projectIdIs(project.pro_id) && project2.periodIs(project.periodo)) {
              array.push(project);
            }
          }
        });
      });
    }
    setDetailTableData(array);
  };

  const getInvoiceNumber = (number: string) => {
    setInvoiceNumber(number);
  };

  const Titles = [
    t('1. Empresa/Período'),
    t('2. Proyectos'),
    t('3. Detalle'),
    t('4. Documentos'),
    t('5. Finalización'),
  ];
  const Descriptions = [
    t('Selecciona la empresa a la que se le facturará, y el período.'),
    t('Selecciona los proyectos que se van a facturar.'),
    t('Completa la liquidación con una descripción y un importe para cada proyecto.'),
    t('Selecciona documentos opcionales para soporte adicional.'),
    t('Ingresa el nro. de factura.'),
  ];

  const CompanySelectorData = {
    company: company,
    fromDate: fromDate,
    toDate: toDate,
  };

  const nodes = useAsyncDataCourier(
      () => props.system.getOrganizationChartSystem().getLastLevelNodes(),
      [props.system],
      props.courier
  );

  const selectedProjectSummary = useAsyncDataCourier(
      async () => {
        const summary = await (company && fromDate && toDate
            ? props.system.getProjectSystem().getListForSettlement(company, fromDate, toDate)
            : Promise.resolve([]));

        return summary.map((item) => {
          const node = nodes?.find((n) => n.isIdentifiedBy(item.pro_norid));
          return { ...item, sector: node?.path() || '' };
        });
      },
      [props.system, nodes, company, fromDate, toDate],
      props.courier
  );

  const selectedGroupsForDocumentTable = useAsyncDataCourier(
      () =>
          selectedProjects && selectedProjects.length && selectedProjects.length > 0 && getProjectsIds()
              ? props.system.getDocumentSystem().getListForSettlement(getProjectsIds())
              : Promise.resolve(undefined),
      [props.system, selectedProjects],
      props.courier
  );

  const getProjectsIds = () => {
    let idArray: Array<string> = [];
    if (selectedProjects) {
      selectedProjects.forEach((project: ProjectInSettlementPeriod) => {
        idArray.push(project.getProjectId());
      });
    }
    return idArray;
  };

  const generateSettlement = async () => {
    if (company && fromDate && toDate && documentsId && invoiceNumber) {
      const settlement = await props.system.generateSettlement(
          fromDate,
          toDate,
          company,
          selectedProjects,
          documentsId,
          invoiceNumber
      );
      setSettlementResponse(settlement);
    } else {
      Promise.resolve(undefined);
    }
  };

  useEffect(() => {
    if (company && fromDate && toDate && selectedProjectSummary) {
      setProjectsTableData(selectedProjectSummary);
    }
  }, [company, fromDate, toDate, selectedProjectSummary]);

  useEffect(() => {
    if (selectedProjects) {
      setDocumentsTableData(selectedGroupsForDocumentTable);
    }
  }, [selectedProjects, selectedGroupsForDocumentTable]);

  const setButtonDisability = (can: boolean) => {
    setCanGoNext(can);
  };

  const itemsMenu = React.useMemo(
      (): ICommandBarItemProps[] => [
        {
          key: 'title',
          text: t('Liquidaciones indirectas'),
          iconProps: {
            iconName: 'TableComputed',
            color: DefaultPalette.themeLighterAlt,
            className: 'text-title-btn',
          },
          buttonStyles: titleCommandBarButtonStyles,
          className: 'text-title-btn',
        },
        {
          key: 'back',
          text: t('Volver'),
          onClick: () => navigate(`${routes.settlement_list}`),
          iconProps: { iconName: 'Back' },
          buttonStyles: defaultCommandBarButtonStyles,
        },
      ],
      [navigate]
  );

  const getDataFromCompanySelectorAssistant = (company: Company, fromDate: DateTime, toDate: DateTime) => {
    setCompany(company);
    setFromDate(fromDate);
    setToDate(toDate);
  };

  const onProjectPeriodsChange = (list: ProjectInSettlementPeriod[]) => {
    setSelectedProjects(list);
  };

  const getDataFromDetail = (items: any) => {
    // setSelectedGroupsId(groupsId);
    setDetailItems(items);
  };

  const getDataFromDocumentSelector = (documents: Array<string>) => {
    setDocumentsId(documents);
  };

  const cleanDetailItems = () => {
    setDetailItems([]);
  };

  const Selectors = [
    <CompanySelectorAssistant
        system={props.system}
        courier={props.courier}
        CompanySelectorData={CompanySelectorData}
        getDataFromCompanySelectorAssistant={getDataFromCompanySelectorAssistant}
        setButtonDisability={setButtonDisability}
    />,
    <ProjectSelectorAssistant
        system={props.system}
        courier={props.courier}
        tableData={projectsTableData}
        setButtonDisability={setButtonDisability}
        type={''}
        onProjectPeriodsChange={onProjectPeriodsChange}
    />,
    <DetailAssistant
        system={props.system}
        courier={props.courier}
        selectedProjects={selectedProjects}
        setButtonDisability={setButtonDisability}
        getIdsFromTable={getDataFromDetail}
        tableData={detailTableData}
        detailItems={detailItems}
        addItemsToProjectInSettlementPeriod={addItemsToProjectInSettlementPeriod}
        cleanDetailItems={cleanDetailItems}
    />,
    <DocumentSelectorAssistant
        system={props.system}
        courier={props.courier}
        tableData={documentsTableData?.data}
        setButtonDisability={false}
        type={''}
        getIdsFromTable={getDataFromDocumentSelector}
    />,
    <Ending
        getInvoiceNumber={getInvoiceNumber}
        generateSettlement={generateSettlement}
        settlementResponse={settlementResponse}
    />,
  ];
  return (
      <ViewWrapperWithCommandBar
          withBorderBottom
          title={''}
          items={itemsMenu}
          overflowButtonStyles={defaultCommandBarButtonStyles}
          variant="highligth"
          titleBody={t('Proyectos')}
      >
        <div className="padding-1">
          <div className="ms-Grid bg-white-h100" dir="ltr">
            <div className="ms-Grid-row">
              <div className="ms-Grid-col ms-sm12 mt-2 ml-1 defense-file-container-desc">
                <Text className="ml-10px defense-file-title">
                  {t('Asistente para la generación de liquidaciones de servicios (indirectas)')}
                </Text>
              </div>
            </div>
            <div className="ms-Grid-row">
              <div className="ms-Grid-col ms-sm12">
                <Wizard
                    componentsTitlesArray={Titles}
                    componentsDescriptions={Descriptions}
                    componentsArray={Selectors}
                    canGoNext={canGoNext}
                />
              </div>
            </div>
          </div>
        </div>
      </ViewWrapperWithCommandBar>
  );
};

export default SettlementsView;
