import * as React from 'react';
import { Dialog, DialogType, DialogFooter } from '@fluentui/react/lib/Dialog';
import { PrimaryButton, DefaultButton } from '@fluentui/react/lib/Button';
import { ContextualMenu, IContextualMenuProps } from '@fluentui/react/lib/ContextualMenu';
import { Text, Icon, IconButton, IIconProps } from '@fluentui/react';
import { DateCustom } from '../../../lib/DateCustom';
import { styles } from '../CalendarStyles';
import { ActivityTeam } from './ActivityTeam';
import { ActivityDocs } from './ActivityDocs';
import { ActivityDistribution } from './ActivityDistribution';
import { Activity } from '../../../system/activity/Activity';
import { useConst } from '@fluentui/react-hooks';
import { ReplicateDialog } from './ActivityMenuOptions/ReplicateDialog';
import { DuplicateDialog } from './ActivityMenuOptions/DuplicateDialog';
import TimeTaxSupportSystemClient from '../../../system';
import { ActivityMultipleOptionsDialog } from './ActivityMenuOptions/ActivityMultipleOptionsDialog';
import MessageCourier from '../../../lib/MessageCourier';
import { DateTime } from 'luxon';
import { User } from '../../../system/User';
import { useTranslation, Trans } from 'react-i18next';
import SystemConfiguration from '../../../system/configuration/SystemConfiguration';

interface IActivityDialog {
  showDialog: boolean;
  toggleShowDialog: () => void;
  activity: Activity;
  system: TimeTaxSupportSystemClient;
  user: User;
  commonContext: any;
  onEdit: () => void;
  refreshEvents: () => void;
  courier: MessageCourier;
  hideBtnEdit?: boolean;
  hideMenuOptions?: boolean;
  changeBlockUI: (state: boolean) => void;
  isAvaliablePeriodForEditOrCreateActivity: boolean;
  config: SystemConfiguration;
}

const ActivityDialog = (props: IActivityDialog) => {
  const { t, i18n } = useTranslation();
  const [showReplicateDialog, setShowReplicateDialog] = React.useState<boolean>(false);
  const [showDeleteDialog, setShowDeleteDialog] = React.useState<boolean>(false);
  const [showDuplicateDialog, setShowDuplicateDialog] = React.useState<boolean>(false);
  const [showDeclineTagDialog, setShowDeclineTagDialog] = React.useState<boolean>(false);

  const getColactByCollaboratorLoggged = () => {
    let colact = props.activity
        .getCollaboratorActivityList()
        .find((colact) => colact.getCollaborator().getId() === props.user.getCollaboratorId());
    return colact;
  };

  const onClickDeclineTag = () => {
    if (props.activity.getReplicated_from_id()) {
      if (!showDeclineTagDialog) {
        setShowDeclineTagDialog(true);
      }
    } else {
      declineTag(false, false);
    }
  };

  const declineTag = async (allAfterThis: boolean = false, all: boolean = false) => {
    props.changeBlockUI(true);
    try {
      let colact = getColactByCollaboratorLoggged();
      if (colact && colact.getId()) {
        await props.system.getActivitySystem().declineTag(colact.getId(), allAfterThis, all);
        props.refreshEvents();
        if (allAfterThis || all) {
          props.courier.messageSuccess(t('Las actividades fueron declinadas de manera exitosa'));
        } else {
          props.courier.messageSuccess(t('La actividad fue declinada de manera exitosa'));
        }
      }
    } catch (error) {
      props.courier.messageError(error);
    } finally {
      props.changeBlockUI(false);
    }
  };

  const onClickDeleteActivity = () => {
    if (props.activity.getReplicated_from_id()) {
      if (!showDeleteDialog) {
        setShowDeleteDialog(true);
      }
    } else {
      deleteActivity(false, false);
    }
  };

  const deleteActivity = async (allAfterThis: boolean = false, all: boolean = false) => {
    props.changeBlockUI(true);
    try {
      await props.system.getActivitySystem().delete(props.activity, allAfterThis, all);
      props.refreshEvents();
      if (allAfterThis || all) {
        props.courier.messageSuccess(t('Las actividades se eliminaron de manera exitosa'));
      } else {
        props.courier.messageSuccess(t('La actividad se eliminó de manera exitosa'));
      }
    } catch (error) {
      props.courier.messageError(error);
    } finally {
      props.changeBlockUI(false);
    }
  };

  const reactivateActivity = async () => {
    props.changeBlockUI(true);
    try {
      await props.system.getActivitySystem().reactivate(props.activity);
      props.refreshEvents();
      props.courier.messageSuccess(t('La actividad se restauró de manera exitosa'));
    } catch (error) {
      props.courier.messageError(error);
    } finally {
      props.changeBlockUI(false);
    }
  };

  const userLoggedIsOwner = () => {
    return props.user.getCollaboratorId() === props.activity.getOwner_colid();
  };

  const getItemsOptions = () => {
    let ret: any = [];
    ret.push();
    if (userLoggedIsOwner()) {
      ret = [
        {
          key: 'edit',
          onClick: () => {
            props.onEdit();
          },
          iconProps: { iconName: 'Edit' },
          text: t('Editar'),
        },
        {
          key: 'duplicate',
          onClick: () => {
            setShowDuplicateDialog(true);
          },
          iconProps: { iconName: 'RepeatOne' },
          text: t('Duplicar'),
        },
        {
          key: 'replicate',
          onClick: () => {
            setShowReplicateDialog(true);
          },
          iconProps: { iconName: 'RepeatAll' },
          text: t('Replicar'),
        },
        {
          key: props.activity.isDeleted() ? 'reactivate' : 'delete',
          onClick: () => {
            props.activity.isDeleted() ? reactivateActivity() : onClickDeleteActivity();
          },
          iconProps: { iconName: props.activity.isDeleted() ? 'RemoveFromTrash' : 'Delete' },
          text: props.activity.isDeleted() ? t('Restaurar') : t('Eliminar'),
        },
      ];
    } else {
      ret = [
        {
          key: 'edit',
          onClick: () => {
            props.onEdit();
          },
          iconProps: { iconName: 'Edit' },
          text: t('Editar'),
        },
        {
          key: 'Declinar',
          onClick: () => {
            onClickDeclineTag();
          },
          iconProps: { iconName: 'EventDeclined' },
          text: t('Declinar'),
        },
      ];
    }
    return ret;
  };

  const menuProps: IContextualMenuProps = useConst({
    shouldFocusOnMount: true,
    items: getItemsOptions(),
  });

  const GlobalNavButtonIcon: IIconProps = { iconName: 'GlobalNavButton' };

  const dragOptions = {
    moveMenuItemText: t('Move'),
    closeMenuItemText: t('Close'),
    menu: ContextualMenu,
  };

  const modalProps = {
    isBlocking: true,
    // styles: modalPropsStyles,
    dragOptions: dragOptions,
  };

  const dialogContentProps = React.useMemo(() => {
    return {
      type: DialogType.normal,
      title: props.activity.getName(),
    };
  }, [props]);

  const collaboratorActivity = React.useMemo(
      () => props.activity.getCollaboratorActivityList().find((colact) => colact.referencesUser(props.user)),
      [props.activity, props.user]
  );

  const getHoursChecked = () => {
    let colact = collaboratorActivity;
    return colact && colact.getHoras();
  };
  const checked = React.useMemo(
      () => !!collaboratorActivity && collaboratorActivity.getChecked(),
      [collaboratorActivity]
  );

  const declined = React.useMemo(
      () =>
          !!(
              collaboratorActivity &&
              !collaboratorActivity.getChecked() &&
              collaboratorActivity.getChecked_by_colid()
          ),
      [collaboratorActivity]
  );

  return (
      <>
        <Dialog
            hidden={!props.showDialog}
            onDismiss={props.toggleShowDialog}
            dialogContentProps={dialogContentProps}
            modalProps={modalProps}
            minWidth={700}
        >
          <div>
            <Text block variant="large" className={styles.subtitleActivity}>
              <Icon iconName="Teamwork" className={styles.iconText} />
              {props.activity.getProject()?.getTitleProject()}
              {!props.hideMenuOptions && (
                  <IconButton
                      className="float-right"
                      iconProps={GlobalNavButtonIcon}
                      title={t('Opciones de actividad')}
                      ariaLabel="GlobalNavButton"
                      menuProps={menuProps}
                      disabled={!props.isAvaliablePeriodForEditOrCreateActivity}
                  />
              )}
            </Text>

            <Text block variant="large" className={styles.subtitleActivity}>
              <Icon iconName="Clock" className={styles.iconText} />
              {DateCustom.getDisplayText(
                  DateTime.fromJSDate(props.activity.getStart()).toFormat('dd/LL/yyyy HH:mm'),
                  DateTime.fromJSDate(props.activity.getEnd()).toFormat('dd/LL/yyyy HH:mm')
              )}
            </Text>

            {props.activity.getDescription() && (
                <Text title={props.activity.getDescription()} block variant="medium" className={'desc-text'}>
                  <Icon iconName="SearchCalendar" className={styles.iconText} />
                  {props.activity.getDescription()}
                </Text>
            )}

            <ActivityDocs
                system={props.system}
                document_types={props.commonContext.documentos_tipo}
                edit={false}
                activity={props.activity}
            ></ActivityDocs>
            <ActivityTeam
                colaborators={props.commonContext.colaboradores}
                system={props.system}
                user={props.user}
                edit={false}
                activity={props.activity}
                userLoggedIsOwner={false}
            ></ActivityTeam>
            <ActivityDistribution
                projectCompanies={props.commonContext.empresas}
                system={props.system}
                edit={true}
                activity={props.activity}
                userLoggedIsOwner={false}
            ></ActivityDistribution>

            {checked && (
                <div className="hours-checked">
                  <Icon iconName="ReminderTime" className="text-icon" />
                  <Text block variant="medium" className="d-inline">
                    {t('Aceptaste ') + DateCustom.formatHorasToString(getHoursChecked())}
                  </Text>
                  {/*
              <Text block variant="medium" className="d-inline link-styles">
                Editar participación
              </Text> */}
                </div>
            )}

            {declined && (
                <div>
                  <hr />
                  <Text block variant="small">
                    {t('Declinaste esta actividad.')}
                  </Text>
                  <hr />
                </div>
            )}
          </div>

          <DialogFooter>
            {!props.hideBtnEdit && (
                <PrimaryButton
                    onClick={props.onEdit}
                    text={t('Editar')}
                    // disabled={!props.isAvaliablePeriodForEditOrCreateActivity}
                />
            )}
            <DefaultButton onClick={props.toggleShowDialog} text={t('Cancelar')} />
          </DialogFooter>
        </Dialog>

        <div>
          <ReplicateDialog
              showDialog={showReplicateDialog}
              toggleShowDialog={() => setShowReplicateDialog(!showReplicateDialog)}
              activity={props.activity}
              system={props.system}
              courier={props.courier}
              refreshEvents={props.refreshEvents}
              changeBlockUI={props.changeBlockUI}
              config={props.config}
          ></ReplicateDialog>
          <DuplicateDialog
              system={props.system}
              courier={props.courier}
              activity={props.activity}
              showDialog={showDuplicateDialog}
              refreshEvents={props.refreshEvents}
              toggleShowDialog={() => {
                setShowDuplicateDialog(!showDuplicateDialog);
              }}
              config={props.config}
          ></DuplicateDialog>
          {props.activity.getReplicated_from_id() && (
              <ActivityMultipleOptionsDialog
                  system={props.system}
                  activity={props.activity}
                  showDialog={showDeleteDialog}
                  toggleShowDialog={() => {
                    setShowDeleteDialog(!showDeleteDialog);
                  }}
                  onAcceptCallback={deleteActivity}
                  acceptText={t('Eliminar')}
              ></ActivityMultipleOptionsDialog>
          )}

          {props.activity.getReplicated_from_id() && (
              <ActivityMultipleOptionsDialog
                  system={props.system}
                  activity={props.activity}
                  showDialog={showDeclineTagDialog}
                  toggleShowDialog={() => {
                    setShowDeclineTagDialog(!showDeclineTagDialog);
                  }}
                  onAcceptCallback={declineTag}
                  acceptText={t('Declinar')}
              ></ActivityMultipleOptionsDialog>
          )}
        </div>
      </>
  );
};

export default ActivityDialog;
