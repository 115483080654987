import { Facepile, Icon, IDropdownOption, IFacepilePersona, Persona, PersonaSize } from '@fluentui/react';
import { GRID_MODE } from '../../../../common/list-utils';
import { DateCustom } from '../../../../lib/DateCustom';
import { DetailsListView, EntityGridListProps } from '../../../Common/DetailsList/DetailListView';
import { useTranslation, Trans } from 'react-i18next';
import React from 'react';

export const ArchivosDefensaGridList = (props: EntityGridListProps) => {
  const { t, i18n } = useTranslation();

  const formatVigencia = (item: any) => {
    return (
        <span>
        {item.ard_desde} - {item.ard_hasta}
      </span>
    );
  };

  const formatEstado = (item: any) => {
    if (item.ard_estado === 'pendienteGeneracionEstructura') {
      return (
          <span>
          <Icon iconName="Shield" aria-label="En proceso" title={t("En proceso")} className="color-inProcess" />{' '}
            &nbsp;{item.ard_estado}
        </span>
      );
    } else if (item.ard_estado === 'zipGenerado') {
      return (
          <span>
          <Icon iconName="Shield" aria-label="Inactivo" title={t("Inactivo")} className="color-success" /> &nbsp;
            {item.ard_estado}
        </span>
      );
    }
  };

  const columns = [
    {
      fieldName: 'ard_id',
      name: t('ID'),
      maxWidth: 60,
      minWidth: 60,
    },
    {
      fieldName: 'col_nombre',
      name: t('Creador'),
      maxWidth: 100,
      minWidth: 100,
    },
    {
      fieldName: 'emp_nombre',
      name: t('Empresa nombre'),
    },
    {
      fieldName: 'ard_desde',
      name: t('Vigencia'),
      maxWidth: 115,
      minWidth: 115,
      _onRender: (item: any) => formatVigencia(item),
    },
    {
      fieldName: 'ard_fecha_carga',
      name: t('Fecha de carga'),
      maxWidth: 100,
      minWidth: 100,
    },
    {
      fieldName: 'ard_estado',
      name: t('Estado'),
      _onRender: (item: any) => formatEstado(item),
    },
  ];
  return (
      <>
        <DetailsListView
            changeBlockUI={props.changeBlockUI}
            keyList={'defense_file_list'}
            onClickCardMode={() => props.onClickCardMode()}
            onClickGridMode={() => props.onClickGridMode()}
            mode={props.mode}
            title={t('Archivos de Defensa')}
            onItemClick={props.onItemClick}
            cols={columns}
            isGridList={true}
            listFilters={[]}
            quickFilters={props.quickFilters}
            baseUrl={props.baseUrl}
            courier={props.courier}
        />
      </>
  );
};
