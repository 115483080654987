import React, { useEffect, useState, useMemo } from 'react';
import { IStackTokens, Stack } from '@fluentui/react/lib/Stack';
import { Text } from '@fluentui/react/lib/Text';
import {
  Dropdown,
  DropdownMenuItemType,
  IDropdownStyles,
  IDropdownOption,
} from '@fluentui/react/lib/Dropdown';
import { Toggle } from '@fluentui/react/lib/Toggle';
import { ProjectSelectorTable } from './ProjectSelectorTable';
import Project from '../../../../system/projects/Project';
import TimeTaxSupportSystemClient from '../../../../system';
import MessageCourier from '../../../../lib/MessageCourier';
import { DocumentTable } from './DocumentTable';

interface IDocumentSelectorAssistant {
  tableData: any;
  setButtonDisability: any;
  type: string;
  getIdsFromTable: any;
  system: TimeTaxSupportSystemClient;
  courier: MessageCourier;
}

const DocumentSelectorAssistant = (props: IDocumentSelectorAssistant) => {
  const [idArray, setIdArray] = useState<Array<string>>(['']);

  // function _onChange(ev: React.MouseEvent<HTMLElement>, checked?: boolean) {
  //   Set(checked ? true : false);
  // }

  const getIds = (idArray: Array<string>) => {
    setIdArray(idArray);
  };

  useEffect(() => {
    if (props.tableData) addDocumentType();
  }, [props.tableData]);

  useEffect(() => {
    if (props.getIdsFromTable) props.getIdsFromTable(idArray);
  }, [idArray]);

  const addDocumentType = () => {
    props.tableData.forEach((document: any) => {
      if (document.doci_dxaid) {
        document.type = 'Actividad';
      } else {
        document.type = 'Proyecto';
      }
    });
  };

  return (
      <div className="mt-2">
        {/* <Text>Selecciona las liquidaciones o proyectos que sustentarán la defensa</Text> */}
        <div className="ms-Grid" dir="ltr">
          <div className="ms-Grid-row">
            <div className="ms-Grid-col ms-sm12 mb-2">
              <DocumentTable
                  setButtonDisability={props.setButtonDisability}
                  data={props.tableData}
                  type={props.type}
                  getIdsFromTable={getIds}
              />
            </div>
          </div>
          <div className="ms-Grid-row"></div>
        </div>
      </div>
  );
};

export default DocumentSelectorAssistant;
