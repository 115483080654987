import React from 'react';
import { Icon, Persona, PersonaSize, IFacepilePersona, Facepile } from '@fluentui/react';
import { DetailsListView, EntityGridListProps } from '../../Common/DetailsList/DetailListView';
import { DateTime } from 'luxon';
import { useTranslation, Trans } from 'react-i18next';

export const ProjectRequiredDocumentGridList = (props: EntityGridListProps) => {
  const { t, i18n } = useTranslation();

  const formatEquipo = (item: any) => {
    var getInitials = function (string: string) {
      var names = string.split(' '),
          initials = names[0].substring(0, 1).toUpperCase();

      if (names.length > 1) {
        initials += names[1].substring(0, 1).toUpperCase();
      }
      return initials;
    };
    return (
        <Persona
            text={item.col_nombre_completo}
            size={PersonaSize.size24}
            imageInitials={getInitials(item.col_nombre_completo)}
            onRenderPrimaryText={() => <></>}
        />
    );
  };
  const formatDocument = (item: any) => {
    if (item.doc_nombre) {
      return (
          <span className="">
          <Icon iconName="CheckMark" className=" success-color text-icon"></Icon>
            {item.doc_nombre}
        </span>
      );
    }

    let _limitDateLuxon = DateTime.fromFormat(item.ccdp_fecha_limite, 'dd/LL/yyyy');
    let _todayLuxon = DateTime.fromJSDate(new Date());

    if (_todayLuxon > _limitDateLuxon) {
      return (
          <span className="">
          <Icon iconName="AlertSolid" className="text-icon error-color"></Icon>
            {t('No cargado y vencido')}
        </span>
      );
    } else {
      return (
          <span className="">
          <Icon iconName="EaseOfAccess" className="text-icon"></Icon>
            {t('No cargado')}
        </span>
      );
    }
  };

  const columns = [
    {
      key: 'concept',
      name: t('Concepto'),
      fieldName: 'ccdp_concepto',
      minWidth: 150,
      maxWidth: 150,
    },
    {
      key: 'documentType',
      name: t('Tipo de documento'),
      fieldName: 'doct_nombre',
      minWidth: 100,
      maxWidth: 100,
    },
    {
      key: 'limitDate',
      name: t('Límite'),
      fieldName: 'ccdp_fecha_limite',
      minWidth: 65,
      maxWidth: 65,
    },

    {
      key: 'responsable',
      name: t('Responsable'),
      fieldName: 'col_nombre_completo',
      minWidth: 70,
      maxWidth: 70,
      _onRender: (item: any) => formatEquipo(item),
    },
    {
      key: 'document',
      name: t('Documento'),
      fieldName: 'doc_nombre',
      minWidth: 100,
      maxWidth: 100,
      _onRender: (item: any) => formatDocument(item),
    },
  ];
  return (
      <>
        <DetailsListView
            changeBlockUI={props.changeBlockUI}
            keyList={props.keyList}
            onClickCardMode={() => props.onClickCardMode()}
            onClickGridMode={() => props.onClickGridMode()}
            mode={props.mode}
            onItemClick={props.onItemClick}
            title={t('Control de documentos obligatorios')}
            cols={columns}
            isGridList={true}
            courier={props.courier}
            listFilters={[]}
            shouldUpdate={props.shouldUpdate}
            quickFilters={props.quickFilters}
            baseUrl={props.baseUrl}
        ></DetailsListView>
      </>
  );
};
