import { DateTime } from 'luxon';

import { BillableDistribution } from '../billable_distribution/BillableDistribution';
import CollaboratorActivity from '../collaborators/collaborator_activity/CollaboratorActivity';
import { Collaborator } from '../collaborators/Collaborator';
import { ActivityDocument } from '../documents/ActivityDocument';
import { ActivityDocumentToSubmit } from '../documents/ActivityDocumentToSubmit';
import Project from '../projects/Project';
import { IProjectDescriptor } from '../projects/ProjectDescriptor';
import { SystemDateParser } from '../SystemDateParser';
import { ActivityJson } from './ActivitySystem';

export class Activity {
  private documentActivityToSubmitList: ActivityDocumentToSubmit[];

  protected constructor(
      private id: string,
      private hours: number,
      private pro_id: string,
      private name: string,
      private start: DateTime,
      private end: DateTime,
      private timezone: string,
      private description: string,
      private active: boolean,
      private upload_colid: string,
      private owner_colid: string,
      private status: string,
      private last_modified_date: string,
      private replicated_from_id: string | null,
      private refer_outlook: string | null,
      private distributionBillableList: BillableDistribution[],
      private documentActivityList: ActivityDocument[],
      private collaboratorActivityList: CollaboratorActivity[],
      private project: IProjectDescriptor | null,
      private documentErrors: string[]
  ) {
    this.documentActivityToSubmitList = [];
  }

  static fromJson(
      dateParser: SystemDateParser,
      project: Project | null,
      collaboratorActivity: CollaboratorActivity[],
      billableDistribution: BillableDistribution[],
      documentActivityList: ActivityDocument[],
      activity: ActivityJson,
      documentErrors: string[]
  ) {
    return new this(
        activity.act_id,
        activity.act_horas,
        activity.act_proid,
        activity.act_nombre,
        activity.act_inicio ? dateParser.parseUTC(activity.act_inicio) : DateTime.now(),
        activity.act_fin ? dateParser.parseUTC(activity.act_fin) : DateTime.now(),
        activity.act_timezone,
        activity.act_descripcion,
        activity.act_activo,
        activity.act_carga_colid,
        activity.act_owner_colid,
        activity.act_estado,
        activity.act_ultima_mod_fecha,
        activity.act_replicada_de_actid,
        activity.act_refer_outlook,
        billableDistribution,
        documentActivityList,
        collaboratorActivity,
        project ? project : null,
        documentErrors
    );
  }

  moveTo(start: Date, end: Date) {
    this.setStart(start);
    this.setEnd(end);
    this.calculateHours();
  }

  calculateHours() {
    this.hours = this.end.diff(this.start, 'hours').hours;
  }

  getId() {
    return this.id;
  }
  getHours() {
    return this.hours;
  }
  getPro_id() {
    return this.pro_id;
  }
  getName() {
    return this.name;
  }
  getStart(): Date {
    return this.start.toJSDate();
  }
  getEnd() {
    return this.end.toJSDate();
  }
  getTimezone(): string {
    return this.timezone;
  }
  getDescription() {
    return this.description;
  }
  getActive() {
    return this.active;
  }
  getUpload_colid() {
    return this.upload_colid;
  }
  getOwner_colid() {
    return this.owner_colid;
  }
  getStatus() {
    return this.status;
  }
  getLast_modified_date() {
    return this.last_modified_date;
  }
  getReplicated_from_id() {
    return this.replicated_from_id;
  }
  getRefer_outlook() {
    return this.refer_outlook;
  }
  getDistributionBillableList() {
    return this.distributionBillableList;
  }
  getDocumentActivityList() {
    return this.documentActivityList;
  }
  getCollaboratorActivityList() {
    return this.collaboratorActivityList;
  }
  getProject() {
    return this.project;
  }

  getDocumentActivityToSubmitList(): ActivityDocumentToSubmit[] {
    return [...this.documentActivityToSubmitList];
  }

  hasPendingDocuments() {
    return this.documentActivityToSubmitList.length > 0;
  }

  getDocumentErrors(): string[] {
    return this.documentErrors;
  }

  setId(value: string) {
    this.id = value;
  }
  setHours(value: number) {
    this.hours = value;
  }
  setPro_id(value: string) {
    this.pro_id = value;
  }
  setName(value: string) {
    this.name = value;
  }
  setStart(value: Date) {
    this.start = DateTime.fromJSDate(value);
  }
  setEnd(value: Date) {
    this.end = DateTime.fromJSDate(value);
  }
  setTimezone(tz: string) {
    this.timezone = tz;
  }
  setDescription(value: string) {
    this.description = value;
  }
  setActive(value: boolean) {
    this.active = value;
  }
  setUpload_colid(value: string) {
    this.upload_colid = value;
  }
  setOwner_colid(value: string) {
    this.owner_colid = value;
  }
  setRefer_outlook(value: string) {
    this.refer_outlook = value;
  }

  setStatus(value: string) {
    this.status = value;
  }
  setLast_modified_date(value: string) {
    this.last_modified_date = value;
  }
  setDistributionBillableList(value: any[]) {
    this.distributionBillableList = value;
  }
  setDocumentActivityList(value: any[]) {
    this.documentActivityList = value;
  }
  setCollaboratorActivityList(value: any[]) {
    this.collaboratorActivityList = value;
  }
  setProject(value: IProjectDescriptor) {
    this.project = value;
  }
  setDocumentActivityToSubmitList(value: ActivityDocumentToSubmit[]) {
    this.documentActivityToSubmitList = value;
  }

  getStartDateType() {
    return this.start.toJSDate();
    // return DateCustom.ParseDateFromApiStrWithoutGMT(this.start);
  }

  getCopy() {
    let ret = new Activity(
        this.id,
        this.hours,
        this.pro_id,
        this.name,
        this.start,
        this.end,
        this.timezone,
        this.description,
        this.active,
        this.upload_colid,
        this.owner_colid,
        this.status,
        this.last_modified_date,
        this.replicated_from_id,
        this.refer_outlook,
        this.distributionBillableList,
        this.documentActivityList,
        this.collaboratorActivityList,
        this.project,
        this.documentErrors
    );
    ret.setDocumentActivityToSubmitList(this.documentActivityToSubmitList);
    return ret;
  }

  setGeneric(_name: string, value: any) {
    let methodName = 'set' + _name.charAt(0).toUpperCase() + _name.slice(1);

    switch (methodName) {
      case 'setId':
        this.setId(value);
        break;
      case 'setHours':
        this.setHours(value);
        break;
      case 'setPro_id':
        this.setPro_id(value);
        break;
      case 'setName':
        this.setName(value);
        break;
      case 'setStart':
        this.setStart(value);
        break;
      case 'setEnd':
        this.setEnd(value);
        break;
      case 'setDescription':
        this.setDescription(value);
        break;
      case 'setActive':
        this.setActive(value);
        break;
      case 'setUpload_colid':
        this.setUpload_colid(value);
        break;
      case 'setOwner_colid':
        this.setOwner_colid(value);
        break;
      case 'setStatus':
        this.setStatus(value);
        break;
      case 'setLast_modified_date':
        this.setLast_modified_date(value);
        break;
      case 'setDistributionBillableList':
        this.setDistributionBillableList(value);
        break;
      case 'setDocumentActivityList':
        this.setDocumentActivityList(value);
        break;
      case 'setCollaboratorActivityList':
        this.setCollaboratorActivityList(value);
        break;
      case 'setDocumentActivityToSubmitList':
        this.setDocumentActivityToSubmitList(value);
        break;
    }
  }

  isDeleted() {
    return !this.getActive();
  }

  toJson(): ActivityJson {
    const _project = this.getProject();

    let ret = {
      act_id: this.getId(),
      act_horas: this.getHours(),
      act_proid: _project ? _project.getId() : '',
      act_nombre: this.getName(),
      act_inicio: String(this.start.toUTC().toISO()),
      act_fin: String(this.end.toUTC().toISO()),
      act_timezone: this.timezone,
      act_descripcion: this.description,
      act_activo: this.active,
      act_carga_colid: this.upload_colid,
      act_estado: this.status,
      act_owner_colid: this.owner_colid,
      act_ultima_mod_fecha: this.last_modified_date,
      act_refer_outlook: this.refer_outlook != null ? this.refer_outlook : '',
      act_replicada_de_actid: this.replicated_from_id != null ? this.replicated_from_id : '',
      distribucionFacturableList: this.getIDistributionBillableList(
          this.getDistributionBillableList(),
          this.id
      ),
      colaboradorActividadList: this.getICollaboratorActivityList(
          this.getCollaboratorActivityList(),
          this.id
      ),
      // documentoXActividadList: activity.get,
      documentoXActividadList: [],
      // proyecto: this.getProject(),
    };

    return ret;
  }

  /**
   * Si el colaborador que  creo la actividad esta en  el equipo lo retorna
   * TODO: Buscar en la api en el caso de que sea undefined ?
   * @returns Collaborator | undefined
   */
  getCollaboratorOwner() {
    let colact = this.collaboratorActivityList.find((colact) =>
        colact.getCollaborator().isIdentifiedBy(this.owner_colid)
    );
    return colact?.getCollaborator();
  }

  getIDistributionBillableList(_dbList: BillableDistribution[], act_id: string) {
    return _dbList.map((_db) => BillableDistribution.toIBillableDistribution(_db, act_id));
  }

  getICollaboratorActivityList(_caList: CollaboratorActivity[], act_id: string) {
    return _caList.map((_ca) => CollaboratorActivity.toICollaboratorActivity(_ca, act_id));
  }

  isNew() {
    return this.id === 'new' || this.id == null;
  }

  isDeclined(col: Collaborator): boolean {
    let _col = this.getCollaboratorActivityList().find((colact) =>
        colact.getCollaborator().isIdentifiedAs(col)
    );
    if (_col) {
      // si fue declinada
      return !!(_col.getChecked() === false && _col.getChecked_by_colid());
    }
    return false;
  }
}
