import React from 'react';
import { HorizontalBarChart, IChartProps } from '@fluentui/react-charting';
import { DateTime } from 'luxon';
import i18next, { t } from 'i18next';

interface IProjectMiniPanelTimeProgressBar {
  start: DateTime;
  end: DateTime;
}

export const ProjectMiniPanelTimeProgressBar = (props: IProjectMiniPanelTimeProgressBar) => {
  const hideRatio: boolean[] = [true, false];

  const data: IChartProps[] = React.useMemo(() => {
    let differenceInWeeksProject = props.end.diff(props.start, ['weeks']);

    let luxonToday = DateTime.fromJSDate(new Date());
    let differenceInWeeksToday = luxonToday.diff(props.start, ['weeks']);

    let percentageTranscurred = Math.ceil(
        (differenceInWeeksToday['weeks'] * 100) / differenceInWeeksProject['weeks']
    );
    return [
      {
        chartTitle: t('Tiempo transcurrido (En semanas)'),
        chartData: [
          {
            legend: 'one',
            horizontalBarChartdata: {
              x: Math.ceil(differenceInWeeksToday['weeks']),
              y: Math.ceil(differenceInWeeksProject['weeks']),
            },
            color: 'rgb(0, 120, 212)',
            xAxisCalloutData: 'Del ' + props.start.toFormat('dd/MM/yyyy') + ' hasta hoy a transcurrido:',
            yAxisCalloutData:
                percentageTranscurred +
                '% (' +
                Math.ceil(differenceInWeeksToday['weeks']) +
                ' de ' +
                Math.ceil(differenceInWeeksProject['weeks']) +
                ')',
          },
        ],
      },
    ];
  }, [props]);

  return (
      <HorizontalBarChart
          data={data}
          hideRatio={hideRatio}
          chartDataMode={'fraction'}
          className={'horizontal-bar-chart'}
      />
  );
};
