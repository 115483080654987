import React from 'react';
import { ProjectConfigurationView } from './ProjectConfigurationView';
import {
  buttonStylesForProjects,
  defaultCommandBarButtonStyles,
  titleCommandBarButtonStyles,
} from '../../../styles/button-styles';
import ViewWrapperWithCommandBar from '../../Layout/ViewWrapperWithCommandBar';
import { ICommandBarItemProps } from '@fluentui/react/lib/CommandBar';
import TimeTaxSupportSystemClient from '../../../system';
import MessageCourier from '../../../lib/MessageCourier';
import { DefaultPalette } from '@fluentui/react';
import './ProjectConfiguration.css';
import { useNavigate, useParams } from 'react-router-dom';
import Project from '../../../system/projects/Project';
import useForceUpdate from '../../../common/custom-hooks/useForceUpdate';
import { DISABLE_MMI_OPTION, newEntityDefaultId, routes } from '../../../common/constants';
import { KEY_VIEW_CONTROL_DOCS_PROJECTS, KEY_VIEW_MAILS_PROJECTS } from '../ProjectView';
import ProjectConfigurationForm from './ProjectConfigurationForm';
import CustomInputIfc from '../../../lib/FormValidator/Form/CustomInputIfc';
import CustomObjectException from '../../../lib/FormValidator/Assertion/CustomObjectException';
import useAsyncDataCourier from '../../../common/custom-hooks/useAsyncDataCourier';
import { useTranslation } from 'react-i18next';
import tutorialsJSON from '../../../tutorialVideos/tutorials.json';
import DialogTemplate from '../../Common/DialogTemplate';
import HelperModal from '../../Common/HelperModal';
import axios, { AxiosRequestConfig } from 'axios';
import { User } from '../../../system/User';
import { ItipoConcepto } from '../../../system/projects/ProjectsSystem';
import { getDataRequiereConceptType } from '../../../lib/Util';

interface IProjectConfiguration {
  system: TimeTaxSupportSystemClient;
  courier: MessageCourier;
  blockUi: (block: boolean) => void;
  user: User;
}

export const ProjectConfiguration = (props: IProjectConfiguration) => {
  const { t, i18n } = useTranslation();
  let { id } = useParams();
  const forceUpdate = useForceUpdate();
  const navigate = useNavigate();

  const contextForm = useAsyncDataCourier(
      () => props.system.getProjectSystem().getContext(),
      [props.system],
      props.courier
  );
  const [project, setProject] = React.useState<Project | undefined>(undefined);

  const projectForm = React.useMemo(() => {
    return project ? ProjectConfigurationForm.with(project) : ProjectConfigurationForm.initialize();
  }, [project]);

  React.useEffect(() => {
    if (projectForm) {
      setFormIsValid(projectForm.isValid());
    }
  }, [projectForm]);

  const [formIsValid, setFormIsValid] = React.useState(false);
  const [openHelpDialog, setOpenHelpDialog] = React.useState(false);

  const [selectedtipoConsep, setSelectedtipoConsep] = React.useState<ItipoConcepto>({key:"000",text:"",codigo:""});
  const [errortipoConcept, setErrortipoConcept] = React.useState<string>("");
  
  const onBlurCustomInput = (customInput: CustomInputIfc) => {
    if (projectForm) setFormIsValid(projectForm.isValid());
    forceUpdate();
  };

  const onSaveProject = async () => {
    if(projectForm.isNew() && getDataRequiereConceptType()==="1" && selectedtipoConsep.text === ""){
      setErrortipoConcept("El tipo concepto no puede ser vacío");
     return;
    }
    setErrortipoConcept("");
    try {
      props.blockUi(true);
      const wasNew = projectForm.isNew();
      projectForm.evaluate();
      const instance = projectForm.toProject();
      const projectSaved = await props.system.getProjectSystem().save(instance,selectedtipoConsep);
      props.courier.messageSuccess(t('El proyecto se guardo de manera exitosa'));
      setProject(projectSaved);
      if (wasNew) {
        navigate(`${routes.project_config}/${projectSaved.getId()}`);
      }
    } catch (e) {
      console.error(e);
      if (e instanceof CustomObjectException) {
        console.error(e.getErrorList());
      }
      props.courier.messageError(e);

      forceUpdate();
    } finally {
      props.blockUi(false);
    }
  };

  const onSave = () => {
    onSaveProject();
  };

  const itemsMenu = React.useMemo((): ICommandBarItemProps[] => {
    let ret: ICommandBarItemProps[] = [];
    ret.push(
        {
          key: 'title',
          text: t('Proyectos'),
          iconProps: {
            iconName: 'TeamWork',
            color: DefaultPalette.themeLighterAlt,
            className: 'text-title-btn',
          },
          buttonStyles: titleCommandBarButtonStyles,
          className: 'text-title-btn',
          onClick: () => navigate(`${routes.project_list}`),
        },
        {
          key: 'save',
          text: t('Guardar'),
          iconProps: { iconName: 'Save', color: DefaultPalette.themeLighterAlt },
          onClick: () => onSave(),
          // disabled: !formIsValid,
          buttonStyles: buttonStylesForProjects,
          // disabled: !formIsValid,
        }
    );

    if (id !== newEntityDefaultId) {
      ret.push(
          {
            key: 'manage',
            text: t('Gestión'),
            onClick: () => navigate(`${routes.project}/${id}`),
            iconProps: { iconName: 'TaskManager' },
            buttonStyles: defaultCommandBarButtonStyles,
          },
          {
            key: 'panel',
            text: t('Panel'),
            onClick: () => navigate(`/${routes.project_panel}/${id}`),
            iconProps: { iconName: 'DashboardAdd' },
            buttonStyles: defaultCommandBarButtonStyles,
          },
          {
            key: 'viewRequiredDocuments',
            text: t('Docs. obligatorios'),
            iconProps: { iconName: 'ProtectedDocument' },
            onClick: () => navigate(`${routes.project}/${id}?view=${KEY_VIEW_CONTROL_DOCS_PROJECTS}`),
            buttonStyles: defaultCommandBarButtonStyles,
          }
          // {
          //   key: 'mmi',
          //   text: t('MMI (Massive Mail Inspector)'),
          //   iconProps: { iconName: 'Mail' },
          //   onClick: () => navigate(`${routes.massive_mail_inspector}/${id}`),
          //   buttonStyles: defaultCommandBarButtonStyles,
          //   disabled: DISABLE_MMI_OPTION,
          // }
      );
    } else {
      ret.push({
        key: 'back',
        text: t('Volver'),
        onClick: () => {
          if (id !== newEntityDefaultId) navigate(`${routes.project}/${id}`);
          else navigate(`${routes.project_list}`);
        },
        iconProps: { iconName: 'Back' },
        buttonStyles: defaultCommandBarButtonStyles,
      });
    }

    return ret;
  }, [onSave, formIsValid]);

  const _farItems: ICommandBarItemProps[] = [
    {
      key: 'help',
      text: t('Ayuda'),
      iconProps: { iconName: 'Lightbulb', color: DefaultPalette.themeLighterAlt },
      onClick: () => {
        setOpenHelpDialog(true);
      },
      buttonStyles: titleCommandBarButtonStyles,
    },
  ];

  const getProject = async () => {
    if (id && id !== newEntityDefaultId) {
      let projectFromAPI: Project = await props.system.getProjectSystem().getById(id);
      setProject(projectFromAPI);
    }
  };

  React.useEffect(() => {
    getProject();
  }, [id]);

  return (
      <ViewWrapperWithCommandBar
          withBorderBottom
          title={''}
          /*farItems={_farItems}*/
          items={itemsMenu}
          overflowButtonStyles={defaultCommandBarButtonStyles}
          variant="highligth"
          titleBody="Proyectos"
      >
        {contextForm && projectForm && (
            <ProjectConfigurationView
                system={props.system}
                courier={props.courier}
                contextForm={contextForm}
                projectForm={projectForm}
                onBlurCustomInput={onBlurCustomInput}
                user={props.user}
                selectedtipoConsep={selectedtipoConsep}
                setSelectedtipoConsep={setSelectedtipoConsep}
                project={project}
                errortipoConcept = {errortipoConcept} 
                setErrortipoConcept = {setErrortipoConcept}
            />
        )}

        <HelperModal
            open={openHelpDialog}
            onClose={() => setOpenHelpDialog(false)}
            tutorialsId={['tutorial-proyectos']}
        ></HelperModal>
      </ViewWrapperWithCommandBar>
  );
};
