import * as React from 'react';
import { IChartProps, ILineChartPoints, AreaChart, LineChart } from '@uifabric/charting';
import { DefaultPalette } from '@fluentui/react/lib/Styling';
import { IQuantityByDate, ICompanyHsByDate } from '../../../system/projects/ProjectsSystem';
import { Icon, Text } from '@fluentui/react';

interface IPanelCompaniesAreaChart {
  data: ICompanyHsByDate[];
  title: string;
  color?: string;
  iconName: string;
}

let colors = [
  DefaultPalette.blue,
  DefaultPalette.green,
  DefaultPalette.magenta,
  DefaultPalette.orange,
  DefaultPalette.red,
  DefaultPalette.teal,
  DefaultPalette.purple,
  DefaultPalette.yellow
]

//devuelve un color random de la lista
const getColor = (index: number) => {
  if (colors[index]) {
    return colors[index];
  } else {
    return colors[Math.abs(index) - Math.floor((Math.abs(index)/8)) *8];
  }
}

const groupByCompany = (xs: any[], prop: any) => {
  var grouped: any = {};
  for (var i=0; i<xs.length; i++) {
    var p = xs[i][prop];
    if (!grouped[p]) { grouped[p] = []; }
    grouped[p].push(xs[i]);
  }
  return grouped;
}

const PanelCompaniesAreaChart = (props: IPanelCompaniesAreaChart) => {
  const generateData = React.useCallback(() => {
    let points: any[] = []; //puntos que se pasan al areachart para crear grafico

    let groups = groupByCompany(props.data, 'companyName'); //separacion de respuesta de api por nombre de compañia

    groups = Object.values(groups);

    //formateo de datos a formato para areachart data (x, y), legend y color
    groups.forEach((company: any, index: number) => {
      let data: any[] = [];
      company.map((element: any) => {
        let ret = {
          x: new Date(element.date),
          y: element.hours,
        };
        data.push(ret);
        return data;
      })

      let dataPoints = { data: data, legend: company[0].companyName, color: getColor(index) };
      points.push(dataPoints);
    })

    return points;
  }, [props.data]);

  const MaxValue: number = React.useMemo(
      () => props.data.reduce((p, d) => (d.hours > p ? d.hours : p), 0),
      [props]
  );

  const data = React.useMemo((): IChartProps => {
    const points: ILineChartPoints[] = generateData();
    return {
      chartTitle: props.title,
      lineChartData: points,
    };
  }, [props, generateData]);

  const rootStyle = {
    width: `100%`,
    height: `${300}px`,
    // margin: 'auto',
  };

  const total = React.useMemo(() => props.data.reduce((previous, d) => previous + d.hours, 0), [props]);
  const totalMessage = React.useMemo(() => `${total} ${props.title.toLocaleLowerCase()}`, [props, total]);

  return (
      <div style={rootStyle} className="mt-4">
        <Text className="ml-2" variant="medium" style={{fontSize: '15px'}}>
          <Icon iconName={props.iconName} className="text-icon"></Icon> {props.title}
        </Text>
        {/* <AreaChart
        data={data}
        strokeWidth={2}
        yMaxValue={MaxValue ? MaxValue : 0}
        showXAxisLablesTooltip={false}
        hideTooltip={false}
        svgProps={{ 'aria-hidden': 'true' }}
        margins={{ top: 20, bottom: 25, left: 30, right: 10 }}
        tickFormat={'%d/%m'}
        legendProps={{
          allowFocusOnLegends: true,
        }}
        // tickValues={[new Date(props.data[0].date), new Date(props.data[props.data.length - 1].date)]}
        // wrapXAxisLables={true}
      /> */}

        <LineChart
            data={data}
            strokeWidth={4}
            // tickFormat={timeFormat}
            // tickValues={tickValues}
            yMaxValue={MaxValue ? MaxValue*2 : 0}
            height={500}
            width={700}
            legendProps={{ canSelectMultipleLegends: true, allowFocusOnLegends: true }}
            // colorFillBars={colorFillBarData}
        />
        <div className="ms-Grid" dir="ltr">
          <div className="ms-Grid-row">
            <div className="ms-Grid-col ms-sm12">
              {/* <Text className="fs-for-chart ml-2" nowrap block title={totalMessage}>
              {totalMessage}
            </Text> */}
            </div>
          </div>
        </div>
      </div>
  );
};

export default PanelCompaniesAreaChart;
