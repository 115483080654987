import * as React from 'react';
import { Icon, Text } from '@fluentui/react';
import { DefaultPalette } from '@fluentui/react/lib/Styling';

import ProjectMiniPanelChart from './ProjectMiniPanelChart';
import { IProjectSummary } from '../../../system/projects/ProjectsSystem';
import ProjectMiniDocumentSummary from './ProjectMiniDocumentSummary';
import ProjectMiniActivitySummary from './ProjectMiniActivitySummary';
import BasicChartPad from '../../Common/BasicChartPad';
import { ProjectTeamFacepile } from '../../Common/ProjectTeamFacepile';
import { ProjectMiniPanel } from '../ProjectMiniPanel/ProjectMiniPanel';
import { ProjectStatus } from '../../Common/ProjectStatus';

import Project from '../../../system/projects/Project';
import { ProjectMiniPanelTimeProgressBar } from './ProjectMiniPanelTimeProgressBar';
import MessageCourier from '../../../lib/MessageCourier';
import TimeTaxSupportSystemClient from '../../../system/TimeTaxSupportSystemClient';
import useAsyncDataCourier from '../../../common/custom-hooks/useAsyncDataCourier';
import { User } from '../../../system/User';
import { useTranslation } from 'react-i18next';
interface IProjectMiniPanelWrapper {
  selectedProject: Project;
  system: TimeTaxSupportSystemClient;
  courier: MessageCourier;
  setViewControlDocsWrapper?: () => void;
  user: User;
  actualizateProject: () => void;
  changeBlockUI: (change: boolean) => void;
}

export const ProjectMiniPanelWrapper = (props: IProjectMiniPanelWrapper) => {
  const { t, i18n } = useTranslation();

  const selectedProjectSummary = useAsyncDataCourier(
      () =>
          props.selectedProject
              ? props.system.getProjectSystem().getSummaryOf(props.selectedProject)
              : Promise.resolve(undefined),
      [props.system, props.selectedProject],
      props.courier
  );

  const projectCreator = useAsyncDataCourier(
      () =>
          props.selectedProject
              ? props.system
                  .getCollaboratorSystem()
                  .getCollaboratorIdentifiedBy(props.selectedProject.getOwnerColid())
              : Promise.resolve(undefined),
      [props.system, props.selectedProject],
      props.courier
  );

  const isFavorite = () => {
    const list = props.selectedProject.getProjectTeam().getCollaboratorTeamList();
    const coleq = list.find((coleq) => coleq.getCollaborator().isIdentifiedAs(props.user.getCollaborator()));
    return coleq ? coleq.getFavorite() : false;
  };

  const onClickFavorite = async () => {
    props.changeBlockUI(true);
    try {
      if (isFavorite()) {
        const retNoFav = await props.system.getProjectSystem().unsetFavorite(props.selectedProject.getId());
        props.courier.messageSuccess(t('Desmarcado como favorito correctamente'));
      } else {
        const retFav = await props.system.getProjectSystem().setFavorite(props.selectedProject.getId());
        props.courier.messageSuccess(t('Marcado como favorito correctamente'));
      }
      props.actualizateProject();
    } catch (error) {
      props.courier.messageError(error);
    }
    props.changeBlockUI(false);
  };

  return (
      <>
        <div className="ms-Grid-row padding-for-panel ms-depth-4">
          <div className="ms-Grid-col ms-lg12 ms-xl3 ms-xxl3 text-overflow ">
            <div className="ms-Grid-row">
              <div className="ms-Grid-col ms-sm12 ms-xxl8 text-overflow ">
                <Text variant="large" nowrap className="project-title">
                  <Icon
                      iconName={isFavorite() ? 'FavoriteStarFill' : 'FavoriteStar'}
                      className="text-icon fav-color"
                      onClick={() => {
                        onClickFavorite();
                      }}
                  ></Icon>
                  {props.selectedProject.getName()}
                </Text>
              </div>
              <div className="ms-Grid-col ms-sm12  ms-xxl4  text-overflow float-right-in-xxl ">
                <ProjectStatus status={props.selectedProject.getState()}></ProjectStatus>
              </div>
            </div>

            <div className="ms-Grid-row">
              <div className="ms-Grid-col ms-sm12 text-overflow ">
                <Text variant="medium" nowrap title="Vigencia del proyecto">
                  <Icon iconName="Clock" className={'text-icon'} />
                  {props.selectedProject.getStart().toFormat('dd/LL/yyyy')} -{' '}
                  {props.selectedProject.getEnd().toFormat('dd/LL/yyyy')}
                </Text>
              </div>
            </div>
            <div className="ms-Grid-row mt-1">
              <div className="ms-Grid-col ms-sm12 text-overflow ">
                <ProjectTeamFacepile project={props.selectedProject} />
              </div>
            </div>
            <div className="ms-Grid-row">
              <div className="ms-Grid-col ms-sm12 text-overflow ">
                <ProjectMiniPanelTimeProgressBar
                    start={props.selectedProject.getStart()}
                    end={props.selectedProject.getEnd()}
                />
              </div>
            </div>
            {projectCreator && (
                <div className="ms-Grid-row">
                  <div className="ms-Grid-col ms-sm12 text-overflow ">
                    <Text variant="smallPlus" nowrap title={`Proyecto creado por: ${projectCreator.fullName()}`}>
                      <Icon iconName="CRMServices" style={{ position: 'relative', top: 2, marginRight: 5 }} />
                      Proyecto creado por: {projectCreator.fullName()}
                    </Text>
                  </div>
                </div>
            )}
          </div>
          <div className="ms-Grid-col ms-xl12 ms-xxl1 column-to-fix "></div>
          {selectedProjectSummary && (
              <div className="ms-Grid-col ms-xl12 ms-xxl9 column-to-fix-manag width-in-down-xl">
                <ProjectMiniPanel
                    summary={selectedProjectSummary}
                    setViewControlDocsWrapper={props.setViewControlDocsWrapper}
                />
              </div>
          )}
        </div>
      </>
  );
};
