import { Icon, Stack, Text } from '@fluentui/react';

function OfflineManagement() {
  const handleReconnect = () => {
    window.location.reload();
  };

  return (
    <div>
      <Stack
        horizontal
        verticalAlign="center"
        horizontalAlign="space-between"
        styles={{
          root: {
            backgroundColor: '#ffcccb',
            padding: '3px',
            flexWrap: 'nowrap',
          },
        }}
      >
        <Stack.Item>
          <Icon iconName="WifiWarning4" styles={{ root: { fontSize: 20, marginRight: 10 } }} />
        </Stack.Item>
        <Stack.Item grow>
          <Stack styles={{
              root: {
                display: 'flex',
                flexDirection: 'row',
                gridGap: '10px',
                '@media(max-width: 768px)': { 
                  flexDirection: 'column',
                  gridGap: '0px',
                },
              },
            }} >
            <Text variant="mediumPlus" styles={{ root: { fontWeight: 'bold',fontSize:"13px" } }}>
              Computadora sin conexión
            </Text>
            <Text variant="mediumPlus"  styles={{ root: { fontSize:"13px" } }}>Asegúrate de que tu computadora tenga conexión a internet activa.</Text>
          </Stack>
        </Stack.Item>
        <Stack.Item>
          <Stack horizontal verticalAlign="center" onClick={handleReconnect} style={{ cursor: 'pointer' }}>
            <Text
              variant="medium"
              styles={{
                root: {
                  color: '#0078d4',
                  marginRight: '5px',
                  transition: 'color 0.3s ease',
                  ':hover': { color: '#005a9e' },
                },
              }}
            >
              Reconectar
            </Text>
            <Icon iconName="ChevronRightSmall" />
          </Stack>
        </Stack.Item>
      </Stack>
      <div
        style={{position: 'fixed',top: 0,left: 0,right: 0,bottom: 0,zIndex: 9999,
        pointerEvents: 'auto', backgroundColor: 'rgba(0, 0, 0, 0.5)', 
        marginTop: window.innerWidth <= 768 ? "55px" : "30px"}}>
      </div>
    </div>
  );
}

export default OfflineManagement;