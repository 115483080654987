import * as React from 'react';
import {
  ComboBox,
  IComboBox,
  IComboBoxOption,
  IComboBoxStyles,
  SelectableOptionMenuItemType,
  PrimaryButton,
  IButtonStyles,
} from '@fluentui/react';
import { DateCustom } from '../../lib/DateCustom';
import { DateTime } from 'luxon';

interface ITimeSelector {
  label: string;
  onChange: (selected: string | number) => void;
  date: string | Date;
  datePre?: string | Date;
  showPostDate?: boolean;
}
export const TimeSelector = (props: ITimeSelector) => {
  const comboBoxRef = React.useRef<IComboBox>(null);
  const options: IComboBoxOption[] = [
    { key: '00:00', text: '00:00' },
    { key: '00:15', text: '00:15' },
    { key: '00:30', text: '00:30' },
    { key: '00:45', text: '00:45' },
    { key: '01:00', text: '01:00' },
    { key: '01:15', text: '01:15' },
    { key: '01:30', text: '01:30' },
    { key: '01:45', text: '01:45' },
    { key: '02:00', text: '02:00' },
    { key: '02:15', text: '02:15' },
    { key: '03:30', text: '03:30' },
    { key: '03:45', text: '03:45' },
    { key: '04:00', text: '04:00' },
    { key: '04:15', text: '04:15' },
    { key: '04:30', text: '04:30' },
    { key: '04:45', text: '04:45' },
    { key: '05:00', text: '05:00' },
    { key: '05:15', text: '05:15' },
    { key: '05:30', text: '05:30' },
    { key: '05:45', text: '05:45' },
    { key: '06:00', text: '06:00' },
    { key: '06:15', text: '06:15' },
    { key: '06:30', text: '06:30' },
    { key: '06:45', text: '06:45' },
    { key: '07:00', text: '07:00' },
    { key: '07:15', text: '07:15' },
    { key: '07:30', text: '07:30' },
    { key: '07:45', text: '07:45' },
    { key: '08:00', text: '08:00' },
    { key: '08:15', text: '08:15' },
    { key: '08:30', text: '08:30' },
    { key: '08:45', text: '08:45' },
    { key: '09:00', text: '09:00' },
    { key: '09:15', text: '09:15' },
    { key: '09:30', text: '09:30' },
    { key: '09:45', text: '09:45' },
    { key: '10:00', text: '10:00' },
    { key: '10:15', text: '10:15' },
    { key: '10:30', text: '10:30' },
    { key: '10:45', text: '10:45' },
    { key: '11:00', text: '11:00' },
    { key: '11:15', text: '11:15' },
    { key: '11:30', text: '11:30' },
    { key: '11:45', text: '11:45' },
    { key: '12:00', text: '12:00' },
    { key: '12:15', text: '12:15' },
    { key: '12:30', text: '12:30' },
    { key: '12:45', text: '12:45' },
    { key: '13:00', text: '13:00' },
    { key: '13:15', text: '13:15' },
    { key: '13:30', text: '13:30' },
    { key: '13:45', text: '13:45' },
    { key: '14:00', text: '14:00' },
    { key: '14:15', text: '14:15' },
    { key: '14:30', text: '14:30' },
    { key: '14:45', text: '14:45' },
    { key: '15:00', text: '15:00' },
    { key: '15:15', text: '15:15' },
    { key: '15:30', text: '15:30' },
    { key: '15:45', text: '15:45' },
    { key: '16:00', text: '16:00' },
    { key: '16:15', text: '16:15' },
    { key: '16:30', text: '16:30' },
    { key: '16:45', text: '16:45' },
    { key: '17:00', text: '17:00' },
    { key: '17:15', text: '17:15' },
    { key: '17:30', text: '17:30' },
    { key: '17:45', text: '17:45' },
    { key: '18:00', text: '18:00' },
    { key: '18:15', text: '18:15' },
    { key: '18:30', text: '18:30' },
    { key: '18:45', text: '18:45' },
    { key: '19:00', text: '19:00' },
    { key: '19:15', text: '19:15' },
    { key: '19:30', text: '19:30' },
    { key: '19:45', text: '19:45' },
    { key: '20:00', text: '20:00' },
    { key: '20:15', text: '20:15' },
    { key: '20:30', text: '20:30' },
    { key: '20:45', text: '20:45' },
    { key: '21:00', text: '21:00' },
    { key: '21:15', text: '21:15' },
    { key: '21:30', text: '21:30' },
    { key: '21:45', text: '21:45' },
    { key: '22:00', text: '22:00' },
    { key: '22:15', text: '22:15' },
    { key: '22:30', text: '22:30' },
    { key: '22:45', text: '22:45' },
    { key: '23:00', text: '23:00' },
    { key: '23:15', text: '23:15' },
    { key: '23:30', text: '23:30' },
    { key: '23:45', text: '23:45' },
  ];

  const mapOptions = (): IComboBoxOption[] => {
    if (props.showPostDate && props.datePre && props.datePre instanceof Date) {
      let hour = DateTime.fromJSDate(props.datePre).toFormat('HH:mm');
      let isPost = false;
      let ret: (IComboBoxOption | undefined)[] = [];
      ret = options.filter((o) => {
        if (isPost) {
          return o;
        }
        if (o.key == hour) {
          isPost = true;
        }
      });
      if (ret !== undefined) {
        //@ts-ignore
        return ret;
      }
    }
    return options;
  };

  const comboBoxStyles: Partial<IComboBoxStyles> = {
    root: { minWidth: 60, maxHeight: 300 },
    optionsContainer: { minWidth: 70, maxHeight: 300 },
  };

  const getSelectedKey = () => {
    if (props.date instanceof Date) {
      let s = DateTime.fromJSDate(props.date).toFormat('HH:mm');
      return s;
    }
  };

  return (
      <>
        <ComboBox
            onChange={(ev, option) => {
              if (option) props.onChange(option.key);
            }}
            selectedKey={getSelectedKey()}
            // allowFreeform={true}
            componentRef={comboBoxRef}
            label={props.label}
            options={mapOptions()}
            styles={comboBoxStyles}
        />
      </>
  );
};
