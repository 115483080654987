export class Utils {
  public static formatHorasToString = (horas: any): string => {
    let ret: string = '';

    horas = parseFloat(horas);
    if (horas) {
      let horaRedondeadaParaAbajo = Math.floor(horas);

      let TieneHoras = '';

      if (horaRedondeadaParaAbajo == 1) {
        ret = horaRedondeadaParaAbajo + ' hora ';
        TieneHoras = ' y ';
      }

      if (horaRedondeadaParaAbajo > 1) {
        ret = horaRedondeadaParaAbajo + ' horas ';
        TieneHoras = ' y ';
      }
      let horaConDecimales = horas.toFixed(2);
      let decimales = horaConDecimales.slice(-2);
      if (decimales == '25') {
        ret += TieneHoras + '15 minutos ';
      }

      if (decimales == '50') {
        ret += TieneHoras + '30 minutos ';
      }

      if (decimales == '75') {
        ret += TieneHoras + '45 minutos ';
      }
    }

    return ret;
  };

  // public static getLabelForDuracion(act_inicio: Date, minutos: number) {
  //   let d: Date = act_inicio;
  //   d.setMinutes(d.getMinutes() + minutos);

  //   let ret = '';
  //   ret = `${d.getHours()}:${d.getMinutes()} (${minutos} min.)`

  //   return ret;
  // }

  public static getLabelForDuracion(minutos: number) {
    return this.formatHorasToString(minutos / 60);
  }
}

export default Utils;
