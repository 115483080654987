import styled from '@emotion/styled';

const StyledFullCalendar = styled('div')`
    font-family: 'Segoe UI', 'Segoe UI Web (West European)', 'Segoe UI', -apple-system, BlinkMacSystemFont,
    Roboto, 'Helvetica Neue', sans-serif;
    /* Aligns resource area (Associate) to center instead of the default of top. */
    .fc-resource-area {
        td.fc-widget-content {
            vertical-align: middle;
        }
    }

    .fc-event-main {
        border: none !important;
        background-color: #0078d4;
        border-radius: 2px;
        color: #000 !important;
        font-size: 11px;
    }

    .fc-event-main-frame {
        font-weight: 600;
        letter-spacing: 0.02rem;
        margin-left: 4px;
    }

    .fc .fc-bg-event {
        background: #fbf2dc !important;
        opacity: 1;
    }

    .fc-scrollgrid-section-body td .fc-scroller-harness {
        height: 62vh;
        overflow-y: scroll !important;
        overflow-x: hidden !important;
        @media only screen and (min-width: 1023px) {
            height: 80vh;
        }
        @media only screen and (max-height: 910px) {
            height: 70vh;
        }
    }

    // .fc-scroller {
    //   height: inherit;
    // }

    .fc-daygrid-body {
        height: inherit;
    }

    .fc-daygrid-body table {
        height: inherit;
    }

    .fc-daygrid-body table tbody {
        height: inherit;
    }

    .fc-event {
        border: none !important;
        background-color: transparent !important;
        border: none !important;
        background-color: #71afe5 !important;
        border-radius: 2px;
        color: #080d12 !important;
        font-size: 10px;
        font-weight: 600;
        letter-spacing: 0.02rem;
        margin-left: 4px;
    }

    .fc-event-main:has(.pending-event) {
        background-color: #ff4856 !important;
    }

    .fc-event-main:has(.outlook-event) {
        background-color: #ddd500 !important;
    }

    .fc-event-main:has(.deleted-event) {
        background-color: #888 !important;
    }

    .fc-timegrid-event .fc-event-main {
        padding: 0px 0px 0px 6px;
    }

    /* Sets height for timeline row that holds events */
    td.fc-widget-content {
        height: 75px;

        .fc-event-container {
            /* Event container size reset */
            padding: 0;
            top: 0;
        }

        .fc-timeline-event {
            /* Event size reset */
            padding: 0;
            margin: 0;
            border: 0;
            background-color: transparent;
        }
    }

    section {
        display: flex;
        flex-direction: column;
        margin-top: 20px;
        margin-left: 20px;

        span {
            padding: 4px 0;
        }
    }
`;

export default StyledFullCalendar;
