import { Text } from '@fluentui/react/lib/Text';
import { PrimaryButton, Stack } from '@fluentui/react';
import {
  Dropdown,
  DropdownMenuItemType,
  IDropdownOption,
  IDropdownStyles,
} from '@fluentui/react/lib/Dropdown';
import React from 'react';
import i18next, { t } from 'i18next';

interface IDetailListPagination {
  page: number;
  perPage: number;
  totalRows: number;
  onChangePage: (action: string) => void;
  onChangePerPage: (event: React.FormEvent<HTMLDivElement>, item: any) => void;
}

const paginationPerPageOptions = [
  { key: 10, text: '10' },
  { key: 25, text: '25' },
  { key: 50, text: '50' },
  { key: 100, text: '100' },
];

const dropdownStyles: Partial<IDropdownStyles> = {
  dropdown: { width: 100 },
  root: { display: 'inline-block', marginRight: '10px' },
};

export const DetailListPagination = (props: IDetailListPagination) => {
  return (
      <div className="pagination-button-wrapper">
        <div className="pagination-dropdown-container">
          <Dropdown
              // label="Resultados por pagina"
              title={t('Resultados por pagina')}
              selectedKey={props.perPage ? props.perPage : undefined}
              // eslint-disable-next-line react/jsx-no-bind
              onChange={props.onChangePerPage}
              placeholder={t('Select an option')}
              options={paginationPerPageOptions}
              styles={dropdownStyles}
          />

          <Text className="" style={{ marginBottom: '15px', margin: 'auto 0' }}>
            {/* Mostrando resultados de {(props.page - 1) * props.perPage + 1} a{' '}
          {props.page * props.perPage > props.totalRows ? props.totalRows : props.page * props.perPage} de{' '}
          {props.totalRows}
          {' resultados totales'} */}
            {t('Mostrando ')}
            {props.page * props.perPage > props.totalRows ? props.totalRows : props.page * props.perPage}
            {t(' de ')}
            {props.totalRows}
            {t(' resultados totales')}
          </Text>
        </div>

        <React.Fragment>
          <PrimaryButton
              disabled={props.page * props.perPage >= props.totalRows}
              onClick={() => props.onChangePage('toLast')}
              allowDisabledFocus
              className="primary-button float-right pagination-btn"
              iconProps={{ iconName: 'DoubleChevronLeftMedMirrored', className:"icon-pagination-btn"  }}
          />

          <PrimaryButton
              disabled={props.page * props.perPage >= props.totalRows}
              onClick={() => props.onChangePage('next')}
              allowDisabledFocus
              className="primary-button float-right pagination-btn"
              iconProps={{ iconName: 'ChevronRightMed', className:"icon-pagination-btn"  }}
          />

          <PrimaryButton
              disabled={(props.page - 1) * props.perPage <= 0}
              onClick={() => props.onChangePage('back')}
              allowDisabledFocus
              className="primary-button float-right pagination-btn"
              iconProps={{ iconName: 'ChevronLeftMed', className:"icon-pagination-btn"  }}
          />

          <PrimaryButton
              disabled={(props.page - 1) * props.perPage <= 0}
              onClick={() => props.onChangePage('toFirst')}
              allowDisabledFocus
              className="primary-button float-right pagination-btn"
              iconProps={{ iconName: 'DoubleChevronLeftMed' , className:"icon-pagination-btn" }}
          />
        </React.Fragment>
      </div>
  );
};
