import React from 'react';
import { Text } from '@fluentui/react/lib/Text';
import {
  Icon,
  Persona,
  PersonaSize,
  PrimaryButton,
  DefaultButton,
  IColumn,
  IDetailsListProps,
  DetailsRow,
  IComboBoxOption,
  DetailsList,
  SelectionMode,
  DetailsListLayoutMode,
  IDropdownOption,
} from '@fluentui/react';

import TimeTaxSupportSystemClient from '../../../system';
import MessageCourier from '../../../lib/MessageCourier';
import { ModalCannotModifyDocs } from './ModalCannotModifyDocs';
import { ProjectConfigurationDocsForm, ProjectDocumentControlForm } from './ProjectConfigurationDocsForm';
import DropdownInput from '../../../lib/FormValidator/Form/DropdownInput';
import DocumentUploadControl from '../../../system/documents/DocumentUploadControl';
import CustomInputIfc from '../../../lib/FormValidator/Form/CustomInputIfc';
import SystemDocumentType from '../../../system/documents/SystemDocumentType';
import CollaboratorTeam from '../../../system/collaborators/collaborator_team/CollaboratorTeam';
import InputField from '../../../lib/FormValidator/Form/InputField';
import { DatePickerInput } from '../../../lib/FormValidator/Form/DatePickerInput';
import { DateTime } from 'luxon';
import { useTranslation, Trans } from 'react-i18next';
import ProjectConfigurationForm from './ProjectConfigurationForm';

interface IProjectConfigurationDocsProps {
  system: TimeTaxSupportSystemClient;
  courier: MessageCourier;
  documentControlForm: ProjectConfigurationDocsForm;
  onBlurCustomInput: (customInput: CustomInputIfc) => void;
  documentTypeList: SystemDocumentType[];
  collaboratorsInTeam: CollaboratorTeam[];
  formProject: ProjectConfigurationForm;
}

export const ProjectConfigurationDocs = (props: IProjectConfigurationDocsProps) => {
  const { t, i18n } = useTranslation();

  const [editing, setEditing] = React.useState<boolean>(false);

  const [items, setItems] = React.useState<any>([]);

  const [documentControlSelected, setDocumentControlSelected] = React.useState<
      DocumentUploadControl | undefined
  >(undefined);

  const [showCannotModifyDoc, setShowCannotModifyDoc] = React.useState<boolean>(false);

  const [currentForm, setCurrentForm] = React.useState<ProjectDocumentControlForm>(
      props.documentControlForm.initializeItemForm()
  );

  const onSave = () => {
    if (currentForm.isValid()) {
      if (!documentControlSelected) {
        props.documentControlForm.add(currentForm.buildDocumentControl());
      } else {
        props.documentControlForm.replace(currentForm.buildDocumentControl(), documentControlSelected);
      }

      const newList = props.documentControlForm.getValue();
      setItems(newList);
      setCurrentForm(props.documentControlForm.initializeItemForm());
      setEditing(false);
    }
    getItems();
  };

  React.useEffect(() => {
    getItems();
  }, [props.documentControlForm]);

  const onEditing = (documentControlSelected: DocumentUploadControl | undefined) => {
    setEditing(true);
    setDocumentControlSelected(documentControlSelected);
    if (documentControlSelected) {
      setCurrentForm(props.documentControlForm.forItem(documentControlSelected));
    } else {
      setCurrentForm(props.documentControlForm.initializeItemForm());
    }
    getItems();
  };

  const onCancel = () => {
    setEditing(false);
    setCurrentForm(props.documentControlForm.initializeItemForm());
    getItems();
  };

  const getItems = () => {
    let items = props.documentControlForm.getValue().map((item) => {
      return {
        uploaded: item.isUploaded(),
        expired: item.isExpired(),
        concept: item.getConcept(),
        documentTypeName: item.getDocumentType().getName(),
        limitDate: item.getLimitDate().toFormat('dd/LL/yyyy'),
        responsible: item.getCollaborator().fullName(),
      };
    });

    setItems(items);
  };

  const formatUploaded = (item: any) => {
    if (item.uploaded) {
      return <Icon iconName="CheckMark" className="success-color" />;
    }
    if (item.expired) {
      return <Icon iconName="AlertSolid" className="error-color" />;
    } else {
      return <Icon iconName="EaseOfAccess" className="" />;
    }
  };

  const formatEquipo = (item: any) => {
    var getInitials = function (string: string) {
      var names = string.split(' '),
          initials = names[0].substring(0, 1).toUpperCase();

      if (names.length > 1) {
        initials += names[1].substring(0, 1).toUpperCase();
      }
      return initials;
    };
    return (
        <Persona
            text={item.responsible}
            size={PersonaSize.size24}
            imageInitials={getInitials(item.responsible)}
        />
    );
  };

  const selectRow = (idx: number) => {};

  const _onRenderRow: IDetailsListProps['onRenderRow'] = (propsDr) => {
    if (propsDr) {
      return (
          <div
              onClick={() => {
                const docControl = props.documentControlForm.getValue()[propsDr.itemIndex];
                onEditing(docControl);
                selectRow(propsDr.itemIndex);
              }}
          >
            <DetailsRow {...propsDr} />
          </div>
      );
    }
    return null;
  };

  const columns: IColumn[] = React.useMemo(
      () => [
        {
          key: 'uploaded',
          name: t('Estado'),
          fieldName: 'uploaded',
          minWidth: 50,
          maxWidth: 50,
          className: 'fs-for-small-pill mt-2px',
          onRender: (item: any) => formatUploaded(item),
        },

        {
          key: 'concept',
          name: t('Concepto'),
          fieldName: 'concept',
          minWidth: 100,
          maxWidth: 100,
          className: 'fs-for-small-pill mt-2px',
        },
        {
          key: 'documentTypeName',
          name: t('Tipo de documento'),
          fieldName: 'documentTypeName',
          minWidth: 100,
          maxWidth: 100,
          className: 'fs-for-small-pill mt-2px',
        },
        {
          key: 'limitDate',
          name: t('Fecha límite'),
          fieldName: 'limitDate',
          minWidth: 100,
          maxWidth: 100,
          className: 'fs-for-small-pill mt-2px',
        },

        {
          key: 'responsable',
          name: t('Responsable'),
          fieldName: 'responsible',
          minWidth: 200,
          maxWidth: 200,
          onRender: (item: any) => formatEquipo(item),
        },
      ],
      [items]
  );

  const [formDocIsValid, setFormDocIsValid] = React.useState(false);

  const onBlurCustomInputForDocForm = (customInput: CustomInputIfc) => {
    props.onBlurCustomInput(customInput);
    setFormDocIsValid(currentForm.isValid());
  };

  return (
      <div className="ms-Grid bg-white-h100 ms-depth-4" dir="ltr">
        <div className="ms-Grid-row padding-1">
          <div className="ms-Grid-col ms-sm12">
            <div className="ms-Grid-row">
              <div className="ms-Grid-col ms-sm6 ">
                <Text variant={'large'} className={'project-config-section-title'}>
                  <Icon iconName="ProtectedDocument" className="text-icon" />
                  {t('Control de documentos obligatorios')}
                </Text>
              </div>

              {editing ? (
                  <div>
                    {documentControlSelected === undefined ? (
                        <PrimaryButton
                            text={t('Guardar')}
                            onClick={onSave}
                            disabled={!formDocIsValid}
                            iconProps={{ iconName: 'Save' }}
                            className="primary-button ml-1 float-right"
                        />
                    ) : (
                        <PrimaryButton
                            text={t('Editar')}
                            onClick={onSave}
                            disabled={!formDocIsValid}
                            iconProps={{ iconName: 'Save' }}
                            className="primary-button ml-1 float-right"
                        />
                    )}
                    <DefaultButton className="ml-1 float-right" text={t('Cancelar')} onClick={onCancel} />
                  </div>
              ) : (
                  <PrimaryButton
                      text={t('Agregar')}
                      onClick={() => onEditing(undefined)}
                      iconProps={{ iconName: 'Add' }}
                      className="primary-button ml-1 float-right"
                  />
              )}
            </div>

            {editing && (
                <div className="ms-Grid-row mt-2">
                  <div className="ms-Grid-col ms-sm3 ">
                    <DropdownInput
                        input={currentForm.getDocumentTypeInput()}
                        onBlur={onBlurCustomInputForDocForm}
                        label={t('Tipo de documento')}
                        options={props.documentTypeList}
                        mapFn={(documentType) => {
                          let ret: IDropdownOption = {
                            key: documentType.getId(),
                            text: documentType.getName(),
                          };
                          return ret;
                        }}
                    />
                  </div>
                  <>
                    <div className="ms-Grid-col ms-sm3">
                      <DropdownInput
                          input={currentForm.getResponsibleInput()}
                          onBlur={onBlurCustomInputForDocForm}
                          label={t('Responsable')}
                          options={props.collaboratorsInTeam.map((item) => item.getCollaborator())}
                          mapFn={(col) => {
                            let ret: IDropdownOption = {
                              key: col.getId(),
                              text: col.fullName(),
                            };
                            return ret;
                          }}
                      />
                    </div>

                    <div className="ms-Grid-col ms-sm3">
                      <InputField
                          input={currentForm.getConceptInput()}
                          label={t('Concepto')}
                          onBlur={onBlurCustomInputForDocForm}
                      />
                    </div>

                    <div className="ms-Grid-col ms-sm3">
                      <DatePickerInput
                          propsInput={{
                            minDate: props.formProject.getStartValue().toJSDate(),
                            maxDate: props.formProject.getEndValue().toJSDate(),
                          }}
                          input={currentForm.getLimitDateInput()}
                          onBlur={onBlurCustomInputForDocForm}
                          label={t('Fecha límite')}
                      />
                    </div>
                  </>
                </div>
            )}

            <DetailsList
                className="mt-2"
                items={items}
                columns={columns}
                selectionMode={SelectionMode.none}
                layoutMode={DetailsListLayoutMode.justified}
                isHeaderVisible={true}
                onRenderRow={_onRenderRow}
                onShouldVirtualize={() => false}
            />
          </div>

          <ModalCannotModifyDocs
              open={showCannotModifyDoc}
              onDimiss={() => setShowCannotModifyDoc(false)}
          ></ModalCannotModifyDocs>
        </div>
      </div>
  );
};
