import AssertionEntity from '../../../lib/FormValidator/Assertion/AssertionEntity';
import AssertionItem from '../../../lib/FormValidator/Assertion/AssertionItem';

class MailInspectorRuleTagAssertions extends AssertionEntity {
  protected constructor() {
    super();
  }

  static build() {
    const instance = new this();
    return instance;
  }

  addAssertions() {
    // this.addAssertionForKeywords();
  }

  // addAssertionForKeywords(key = 'keywords') {
  //   this.defineAssertionForProperty(
  //     key,
  //     AssertionItem.forClosure(
  //       // (value) => value >= 0 && value <= 100, // editar
  //       // key,
  //       // 'Percentage must be a number between 0 and 100'
  //     )
  //   );
  // }
}

export default MailInspectorRuleTagAssertions;
