import AssertionEntity from '../../../lib/FormValidator/Assertion/AssertionEntity';
import CustomForm from '../../../lib/FormValidator/Form/CustomForm';
import CustomList from '../../../lib/FormValidator/Form/CustomList';

class TeamEmailForm extends CustomForm {
  protected constructor(teamEmail: string) {
    super('teamEmailForm', new AssertionEntity());
    this.addInputAsserted('teamEmail', teamEmail);
  }

  private static build(teamEmail: string) {
    const instance = new this(teamEmail);
    return instance;
  }

  static initialize() {
    return this.build('');
  }

  static with(teamEmail: string) {
    return this.build(teamEmail);
  }

  getTeamEmailInput() {
    return this.getInputByName('teamEmail');
  }
}

export class MIRTTeamEmailsListForm extends CustomList<string> {
  private teamEmailForms: TeamEmailForm[];

  constructor(propertyName: string, items: string[], assertionEntity: AssertionEntity) {
    super(propertyName, items, assertionEntity);
    this.teamEmailForms = this.items.map((item) => TeamEmailForm.with(item));
    super.onBlur();
  }

  delete(teamEmail: string) {
    super.delete(teamEmail);
    super.onBlur();
  }

  add(teamEmail: string) {
    super.add(teamEmail);
    super.onBlur();
  }
}

export class MIRTTeamEmailForm extends CustomForm {
  private teamEmailsList: MIRTTeamEmailsListForm;

  readonly TEAM_EMAILS_ITEMS = 'teamEmails';

  protected constructor(emails: string[]) {
    const mia = new AssertionEntity();
    super('TeamEmails', mia);

    this.teamEmailsList = new MIRTTeamEmailsListForm(this.TEAM_EMAILS_ITEMS, emails, mia);
    this.addListInput(this.teamEmailsList);
  }

  static initialize() {
    const instance = new this([]);
    return instance;
  }

  static with(emails: string[]) {
    const instance = new this(emails);
    return instance;
  }

  getInputTeamEmailsItems(): MIRTTeamEmailsListForm {
    return this.teamEmailsList;
  }
}
