import { IComboBoxOption } from "@fluentui/react";
import { IInformacionGeneral, Idirection } from "../collaboratorCreateView";
import { useEffect, useState } from "react";
import { ComboBox,Icon} from "@fluentui/react";
import Environment from "../../../../lib/Environment";


interface IListtransform{
  key: string;
  text: string;
}

export const EstructuraOrganizacional = ({register,errors,setValue,watch,system}:IInformacionGeneral) => {
  const valueEdingManagement = new Environment().EditingManagement() || "1";
    const [direction,setDirection] = useState<IComboBoxOption[]>([]);
    const [directionFilter,setDirectionFilter] = useState<IComboBoxOption[]>([]);
    const [newDirection,setNewDirection] = useState<IComboBoxOption>({key:"9999",text:""});

    const [gerencia,setGerencia] = useState<IComboBoxOption[]>([]);
    const [gerenciaFilter,setGerenciaFilter] = useState<IComboBoxOption[]>([{key:"0000000001",text:"selecione una dirección"}]);
    const [newGerencia,setNewGerencia] = useState<IComboBoxOption>({key:"9999",text:""})

    const [jefatura,setJefatura] = useState<IComboBoxOption[]>([]); 
    const [jefaturaFilter,setJefaturaFilter] = useState<IComboBoxOption[]>([{key:"0000000001",text:"selecione una gerencia"}]); 
    const [newJefatura, setNewJefatura] = useState<IComboBoxOption>({key:"9999",text:""});

    const [cargo, setCargo] = useState<IListtransform[]>([]);
    const [cargofilter, setCargofilter] = useState<IListtransform[]>([]);
    const [newcargo,setNewcargo] = useState<IListtransform>({key:"9999",text:""});

    const [selectedOptionGerencia, setSelectedOptionGerencia] = useState<any>(null);
    const [selectedOptionJefatura, setSelectedOptionJefatura] = useState<any>(null);
    

    const [valueDireccion, setValueDireccion] = useState<string | undefined>("");
    const [valueGerencia, setValueGerencia] = useState<string | undefined>("");
    const [valueJefatura, setValueJefatura] = useState<string | undefined>("");

    const cleanDateInputs = ()=>{
      setValueGerencia(undefined);
      setValue("gerencia",undefined);
      setGerenciaFilter([]);
      setJefaturaFilter([]);
      if(watch("selecusuarios")?.text !== "Directivo"){
        setValueJefatura(undefined);
        setValue("jefatura",undefined);
      }
    };

    const onselectDirección = async (option:any)=>{
      cleanDateInputs();
      let data:IComboBoxOption | undefined = option;
      if(option === undefined && newDirection.text !== ""){
        data = newDirection
      }
      setValue("direccion",data);
      setValueDireccion(data?.text);
      if(option !== undefined){
       const optionss:Idirection = option 
       const response = await system!.getselcts("2",optionss.key);
       const directions :IComboBoxOption[]= response.map((item)=>{
        const obejts ={
         key:item.nor_id,
         text:item.nor_nombre,
         nor_nivel:item.nor_nivel,
         nor_padre:item.nor_padre
        }
        return obejts
       });
       setGerencia(directions);
       setGerenciaFilter(directions); 
      }
    }

    const onselectGerencia =async (option:any) => {
      let data:IComboBoxOption | undefined = option;
      if(option === undefined && newGerencia.text !== ""){
        data = newGerencia;
      }
      setValue("gerencia",data);
      setValueGerencia(data?.text);
      setSelectedOptionGerencia(option)
      setJefaturaFilter([]);
      if(option !== undefined){
       const optionss:Idirection = option 
       const response = await system!.getselcts("3",optionss.key);
       const directions :IComboBoxOption[]= response.map((item)=>{
       const obejts ={
         key:item.nor_id,
         text:item.nor_nombre,
         nor_nivel:item.nor_nivel,
         nor_padre:item.nor_padre
        }
        return obejts
       });
       setJefatura(directions);
       setJefaturaFilter(directions) 
      }
    }

    const onselectJefatura = (option) =>{
      let data:IComboBoxOption | undefined = option;
      if(option === undefined && newJefatura.text !== ""){
        data = newJefatura
      }
      setValue("jefatura",data);
      setValueJefatura(data?.text);
      setSelectedOptionJefatura(option)
    }
    
    const dataDirection = async()=>{
      if(!system)return ;
      try {
      const  response  = await system.getselcts();
      const directions :IComboBoxOption[]= response.map((item)=>{
      const obejts ={
       key:item.nor_id,
       text:item.nor_nombre,
       nor_nivel:item.nor_nivel,
       nor_padre:item.nor_padre
      }
      return obejts
     });
      setDirection(directions);
      setDirectionFilter(directions);
      } catch (error) {
        console.log(error)
      }
    }

    const cargoList = async ()=>{
      if(!system)return ;
      try {
         const response = await system.listCargo();
         const directions= response.map((item)=>{
          const obejts ={
           key:item.cargo_id,
           text:item.cargo_nombre,
          }
          return obejts
         });
         setCargo([...directions,newcargo]);
         setCargofilter([...directions,newcargo]);
      } catch (error) {
        console.log(error)
      }
    }

  const directionFilterChange = (text:string)=>{
    setNewDirection({...newDirection,text});
     let filtrado = direction.filter(d=> d.text.toLowerCase().includes(text.toLowerCase()));
     setDirectionFilter(filtrado);
  }

   const gerenciaFilterChange = (text:string)=>{
       setNewGerencia({...newGerencia,text});
       let filtrado = gerencia.filter(g=>g.text.toLowerCase().includes(text.toLowerCase()));
       setGerenciaFilter(filtrado);
   }

   const  filterFejaturaChange = (text:string)=>{
       setNewJefatura({...newJefatura, text});
       let filtrado = jefatura.filter(j=>j.text.toLowerCase().includes(text.toLowerCase()));
       setJefaturaFilter(filtrado);
   }

    const onInputValueChangeCargo = (text:string)=>{
            setNewcargo({...newcargo,text});
            let filtrado =  cargo.filter(c => c.text.toLowerCase().includes(text.toLowerCase()));
           setCargofilter(filtrado);
    }

   const onChangeCargoo = (event,option) => {
     let data = option;
     if(option === undefined && newcargo.text !== ""){
         data = newcargo;
     }
     setValue("cargo",data);
  
   }

    useEffect(() => {
      dataDirection();
      cargoList();
      register("direccion", { required: true });
      register("gerencia", { required: true });
      register("cargo", { required: true });
      
      if (watch("selecusuarios")?.text === "Directivo") {
        setValue("jefatura",{ key: "000", text: "000" })
        register("jefatura",{ required: false,value:{ key: "000", text: "000" } }); 
      } else {
        register("jefatura", { required: true });
        setValueJefatura(undefined);
        setValue("jefatura",undefined);
        setValueJefatura("")
      }
    }, [watch("selecusuarios")]);
  

    return( 
    <div className="form_informacion_general">
         <p className="titulo_form_create">
          <Icon iconName="Org" style={{fontSize:"25px"}} />
          Estructura Organizacional
        </p>
        <div className="cuerpo_izquierdo_form">
            <ComboBox
             allowFreeInput={true}
            className="label_reusable"
            placeholder="Seleccione la dirección"
            label="Dirección"
            options={directionFilter}
            styles={{ root: { width: "302px" } }}
            onChange={(_,option)=>onselectDirección(option) }
            onInputValueChange={directionFilterChange}
            text={valueDireccion}
            allowFreeform={valueEdingManagement === "1"? true:false}
            />
             {errors.direccion && <span className="error_stilos_Organizacional">La dirección no puede ser vacío.</span>}
                <ComboBox
                 allowFreeInput={true}
                className="label_reusable"
                placeholder="Seleccione la gerencia"
                label="Gerencia"
                options={gerenciaFilter}
                styles={{root:{width:"302px"}}}
                onChange={(_,option)=>onselectGerencia(option) } 
                onInputValueChange={gerenciaFilterChange}
                selectedKey={selectedOptionGerencia ? selectedOptionGerencia.key: null}
                text={valueGerencia}
              allowFreeform={valueEdingManagement === "1"? true:false}
              />
              {errors.gerencia && <span className="error_stilos_Organizacional">La gerencia no puede ser vacío.</span>}
               <ComboBox
                allowFreeInput={true}
                className="label_reusable"
                placeholder="Seleccione la jefatura"
                label="Jefatura"
                options={jefaturaFilter}
                styles={{root:{width:"302px"}}}
                onChange={(event,option)=>onselectJefatura(option)}
                onInputValueChange={filterFejaturaChange}
                selectedKey={selectedOptionJefatura ? selectedOptionJefatura.key: null}
                disabled={watch("selecusuarios")?.text === "Directivo"}
                text={valueJefatura}
                allowFreeform={valueEdingManagement === "1"? true:false}
              />
  
               { errors.jefatura && <span className="error_stilos_Organizacional">La jefatura no puede ser vacío.</span>}
                <ComboBox
                className="label_reusable"
                placeholder="Escribir el cargo"
                label="Cargo"
                options={cargofilter}
                styles={{root:{width:"170px"}}}
                onChange={(event,option)=>onChangeCargoo(event,option)}
                onInputValueChange={onInputValueChangeCargo}
                text={
               cargo.some(item => item.text.toLowerCase().includes(newcargo.text.toLowerCase()))? undefined:newcargo.text}
                allowFreeform
              />
                { errors.cargo && <span className="error_stilos_Organizacional">El cargo no puede ser vacío.</span>}
        </div>
      </div>
      );
  };
