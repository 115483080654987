import React from 'react';
import ErrorListForInput from './ErrorListForInput';
import { TextField } from '@fluentui/react';
import { useTranslation, Trans } from 'react-i18next';
import CustomForm from './CustomForm';
import CustomInput, { CallableFn } from './CustomInput';
import CustomInputIfc from './CustomInputIfc';

interface ITextAreaField {
  input: CustomInputIfc;
  onBlur: (input: CustomInputIfc) => void;
  label: string;
}

const TextArea = (props: ITextAreaField) => {
  const { t, i18n } = useTranslation();

  const [updateFlag, setUpdateFlag] = React.useState(false);

  const updateViewAfterDoing = (anAction: CallableFn) => {
    setUpdateFlag((u) => {
      anAction();
      return !u;
    });
  };

  const onChangeCustomInput = (val: any) => {
    updateViewAfterDoing(() => {
      props.input.setValue(val);
    });
  };

  const onBlurCustomInput = () => {
    updateViewAfterDoing(() => {
      props.input.onBlur();
      props.onBlur(props.input);
    });
  };

  return (
      <>
        <TextField
            label={t(props.label)}
            name={props.input.getName()}
            value={props.input.getValue()}
            onChange={(ev) => onChangeCustomInput(ev.currentTarget.value)}
            onBlur={(ev) => onBlurCustomInput()}
            multiline
            rows={4}
        />
        {props.input.hasErrors() && <ErrorListForInput customInput={props.input} />}
      </>
  );
};

export default TextArea;
