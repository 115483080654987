import * as React from 'react';
import { Text, Icon, PrimaryButton } from '@fluentui/react';

import useAsyncDataCourier from '../../../common/custom-hooks/useAsyncDataCourier';
import TimeTaxSupportSystemClient from '../../../system';
import MessageCourier from '../../../lib/MessageCourier';
import { PanelProjectsSelector } from '../PanelProjectsSelector';
import { DateTime } from 'luxon';
import BasicChartPad from '../../Common/BasicChartPad';
import PanelDonutChart from './PanelDonutChart';
import PanelVerticalBarChart from './PanelVerticalBarChart';
import { buttonStylesForProjects, defaultCommandBarButtonStyles } from '../../../styles/button-styles';
import { ICommandBarItemProps } from '@fluentui/react';

import { useNavigate } from 'react-router-dom';
import { routes } from '../../../common/constants';
import ViewWrapperWithCommandBar from '../../Layout/ViewWrapperWithCommandBar';
import { NoDataFoundedForSelectedFilters } from './NoDataFoundedForSelectedFilters';
import { CollaboratorsComparisonTable } from './CollaboratorsComparisonTable';
import CollaboratorsComparisonExporter from '../ExcelExport/CollaboratorsComparisonExporter';
import { OrganizationChartNode } from '../../../system/organization_chart/OrganizationChartNode';
import { ICollaboratorsSummary, IResumenColaboradoresComp } from '../../../system/panel/PanelSystem';
import AmountByConceptExporter from '../ExcelExport/AmountByConceptExporter';
import { User } from '../../../system/User';
import { useTranslation } from 'react-i18next';
import { PillFluent } from '../../Common/PillFluent';

interface IPanelCollaborators {
  system: TimeTaxSupportSystemClient;
  courier: MessageCourier;
  changeBlockUI: (change: boolean) => void;
  user: User;
}

export const PanelCollaborators = (props: IPanelCollaborators) => {
  const { t, i18n } = useTranslation();
  const [organizationNodeId, setOrganizationNodeId] = React.useState<string | undefined>(undefined);
  // const [from, setFrom] = React.useState<DateTime>(DateTime.fromJSDate(DateCustom.getFechaOneMonthAgo()));
  // const [to, setTo] = React.useState<DateTime>(DateTime.fromJSDate(new Date()));

  const [from, setFrom] = React.useState<DateTime | undefined>(undefined);
  const [to, setTo] = React.useState<DateTime | undefined>(undefined);
  const [collaboratorsSummary, setCollaboratorsSummary] = React.useState<ICollaboratorsSummary | undefined>(
      undefined
  );

  const navigate = useNavigate();

  const onClickSearch = async () => {
    getPanelsData();
  };

  const getPanelsData = async () => {
    if (from && to && organizationNodeId) {
      props.changeBlockUI(true);
      const _collaboratorsSummary = await props.system
          .getPanelSystem()
          .getCollaboratorsSummary(from, to, organizationNodeId);
      setCollaboratorsSummary(_collaboratorsSummary);
      props.changeBlockUI(false);
    }
  };

  const nodes = useAsyncDataCourier(
      () => props.system.getOrganizationChartSystem().getAllNodes(),
      [props.system],
      props.courier
  );

  const setSelectedOrganizationNodeId = (id: string) => {
    if (id || id === '') setOrganizationNodeId(id);
  };

  const items = React.useMemo(
      (): ICommandBarItemProps[] => [
        {
          key: 'project',
          text: t('Mi Panel'),
          onClick: () => navigate(`${routes.dashboard_own}`),
          itemProps: {},
          iconProps: { iconName: 'BIDashboard' },
          buttonStyles: defaultCommandBarButtonStyles,
        },
        {
          key: 'download',
          text: t('Avance de proyectos'),
          // href: routes.project_config,
          onClick: () => navigate(`${routes.dashboard}`),
          iconProps: { iconName: 'TeamWork' },
          buttonStyles: defaultCommandBarButtonStyles,
        },
        {
          key: 'panel_companies',
          text: t('Empresas'),
          onClick: () => navigate(`${routes.dashboard_empresas}`),
          iconProps: { iconName: 'CityNext2' },
          buttonStyles: defaultCommandBarButtonStyles,
        },
        {
          key: 'colabs',
          text: t('Colaboradores'),
          // href: routes.project_config,
          onClick: () => navigate(`${routes.dashboard_colaboradores}`),
          iconProps: { iconName: 'ReminderPerson' },
          buttonStyles: buttonStylesForProjects,
        },
      ],
      [navigate]
  );

  const isFilterByNode = () => organizationNodeId !== '' && organizationNodeId;

  const exportCollaboratorsComparison = (
      comparison: IResumenColaboradoresComp[],
      nodes: OrganizationChartNode[]
  ) => {
    CollaboratorsComparisonExporter.exportFor(comparison, nodes);
  };

  return (
      <ViewWrapperWithCommandBar
          withBorderBottom
          title={t('Panel')}
          items={items}
          overflowButtonStyles={defaultCommandBarButtonStyles}
          // overflowItems={overflowItems}
          variant="highligth"
          titleBody={t('Panel')}
      >
        <div className="ms-Grid padding-1" dir="ltr">
          <PanelProjectsSelector
              user={props.user}
              system={props.system}
              courier={props.courier}
              onChangeOrganizationNodeId={setSelectedOrganizationNodeId}
              onChangeFromDate={(_d: DateTime) => setFrom(_d)}
              onChangeToDate={(_d: DateTime) => setTo(_d)}
              onClickSearch={onClickSearch}
              useAllSector={true}
          />
          <hr></hr>
          {collaboratorsSummary && (
              <div className="ms-Grid" dir="ltr">
                <div className="ms-Grid-row">
                  <div className="ms-Grid-col ms-sm12">
                    <PrimaryButton
                        text={t('Exportar')}
                        iconProps={{ iconName: 'ExcelLogo' }}
                        className="float-right ml-2"
                        menuProps={{
                          items: [
                            // {
                            //   key: 'exportSector',
                            //   text: 'Horas por sector',
                            //   onClick: () =>
                            //     AmountByConceptExporter.exportFor(
                            //       collaboratorsSummary,
                            //       ['Sector', 'Horas'],
                            //       'Horas por sector'
                            //     ),
                            // },
                            {
                              key: 'exportSector',
                              text: t('Horas por sector'),
                              onClick: () =>
                                  AmountByConceptExporter.exportFor(
                                      collaboratorsSummary.hoursForLastLevelSector,
                                      ['Sector', 'Horas'],
                                      'Horas por sector'
                                  ),
                            },
                            {
                              key: 'exportProject',
                              text: t('Horas por proyecto'),
                              onClick: () =>
                                  AmountByConceptExporter.exportFor(
                                      collaboratorsSummary.hoursForProjects,
                                      ['Proyecto', 'Horas'],
                                      'Horas por proyecto'
                                  ),
                            },
                            {
                              key: 'exportCollaborator',
                              text: t('Horas por colaborador'),
                              onClick: () =>
                                  AmountByConceptExporter.exportFor(
                                      collaboratorsSummary.hoursForCollaborators,
                                      ['Colaborador', 'Horas'],
                                      'Horas por colaborador'
                                  ),
                            },
                            {
                              key: 'exportCompanies',
                              text: t('Horas por empresa'),
                              onClick: () =>
                                  AmountByConceptExporter.exportFor(
                                      collaboratorsSummary.hoursForCompanies,
                                      ['Empresa', 'Horas'],
                                      'Horas por empresa'
                                  ),
                            },
                          ],
                        }}
                    />
                  </div>
                </div>
                <div className="ms-Grid-row mt-2">
                  <div className="ms-Grid-col ms-sm4">
                    {/* <BasicChartPad
                  previewText={
                    collaboratorsSummary.activeCollaborators + '/' + collaboratorsSummary.totalCollaborators
                  }
                  mainText={t('Colaboradores')}
                  secondaryText={
                    collaboratorsSummary.activeCollaborators + t(' colaboradores registraron horas')
                  }
                  iconName="ReminderPerson"
                /> */}
                    <PillFluent
                        title={t('Colaboradores')}
                        iconTitle={'ReminderPerson'}
                        series={[
                          {
                            label: t('Colaboradores activos'),
                            value:
                                collaboratorsSummary.activeCollaborators +
                                '/' +
                                collaboratorsSummary.totalCollaborators,
                            icon: 'ReminderPerson',
                          },
                        ]}
                        small={false}
                    ></PillFluent>
                  </div>
                  <div className="ms-Grid-col ms-sm4">
                    {/* <BasicChartPad
                  previewText={collaboratorsSummary.activeProjects + '/' + collaboratorsSummary.totalProjects}
                  mainText={t('Proyectos')}
                  secondaryText={collaboratorsSummary.activeProjects + t(' proyectos registraron horas')}
                  iconName="TeamWork"
                /> */}
                    <PillFluent
                        title={t('Proyectos')}
                        iconTitle={'TeamWork'}
                        series={[
                          {
                            label: t('Proyectos activos'),
                            value: collaboratorsSummary.activeProjects + '/' + collaboratorsSummary.totalProjects,
                            icon: 'TeamWork',
                          },
                        ]}
                        small={false}
                    ></PillFluent>
                  </div>
                  <div className="ms-Grid-col ms-sm4">
                    {/* <BasicChartPad
                  previewText={
                    collaboratorsSummary.activeCompanies + '/' + collaboratorsSummary.totalCompanies
                  }
                  mainText={t('Empresas')}
                  secondaryText={collaboratorsSummary.activeCompanies + t(' empresas registraron horas')}
                  iconName="CityNext2"
                /> */}
                    <PillFluent
                        title={t('Empresas')}
                        iconTitle={'CityNext2'}
                        series={[
                          {
                            label: t('Empresas activas'),
                            value: collaboratorsSummary.activeCompanies + '/' + collaboratorsSummary.totalCompanies,
                            icon: 'CityNext2',
                          },
                        ]}
                        small={false}
                    ></PillFluent>
                  </div>
                </div>
                <div className="ms-Grid-row mt-2">
                  <div className="ms-Grid-col ms-sm4">
                    {/* <BasicChartPad
                  previewText={collaboratorsSummary.totalHours.toFixed(2)}
                  mainText={t('Horas totales')}
                  secondaryText={collaboratorsSummary.totalHours.toFixed(2) + t(' horas totales registradas')}
                  iconName="Clock"
                /> */}
                    <PillFluent
                        title={t('Horas totales')}
                        iconTitle={'Clock'}
                        series={[
                          {
                            label: t('Horas registradas'),
                            value: collaboratorsSummary.totalHours.toFixed(2),
                            icon: 'Clock',
                          },
                        ]}
                        small={false}
                    ></PillFluent>
                  </div>
                  <div className="ms-Grid-col ms-sm4">
                    {/* <BasicChartPad
                  previewText={collaboratorsSummary.totalDocuments}
                  mainText={t('Documentos')}
                  secondaryText={collaboratorsSummary.totalDocuments + t(' documentos registrados')}
                  iconName="TextDocument"
                /> */}
                    <PillFluent
                        title={t('Documentos')}
                        iconTitle={'TextDocument'}
                        series={[
                          {
                            label: t('Docs. registrados'),
                            value: collaboratorsSummary.totalDocuments,
                            icon: 'TextDocument',
                          },
                        ]}
                        small={false}
                    ></PillFluent>
                  </div>
                  <div className="ms-Grid-col ms-sm4"></div>
                </div>
                <div className="ms-Grid-row mt-2">
                  <div className="ms-Grid-col ms-xl12 ms-xxl4">
                    {collaboratorsSummary.hoursForLastLevelSector.length > 0 ? (
                        <PanelDonutChart
                            data={collaboratorsSummary.hoursForLastLevelSector}
                            iconName={'AddGroup'}
                            titleChart={
                              <span>
                        {t('Horas registradas por ')}
                                <span className="fw-700">{t('Area')}</span>
                      </span>
                            }
                        />
                    ) : (
                        <>{<NoDataFoundedForSelectedFilters text={t('Area')} />}</>
                    )}

                    {collaboratorsSummary.hoursForCollaborators.length > 0 && isFilterByNode() ? (
                        <PanelDonutChart
                            data={collaboratorsSummary.hoursForCollaborators}
                            iconName={'AddGroup'}
                            titleChart={
                              <span>
                        {t('Horas registradas por ')}
                                <span className="fw-700">{t('Colaboradores')}</span>
                      </span>
                            }
                        ></PanelDonutChart>
                    ) : (
                        <>{isFilterByNode() && <NoDataFoundedForSelectedFilters text={t('Colaboradores')} />}</>
                    )}
                  </div>
                  <div className="ms-Grid-col ms-xl12 ms-xxl4">
                    {collaboratorsSummary.hoursForProjects.length > 0 ? (
                        <PanelDonutChart
                            data={collaboratorsSummary.hoursForProjects}
                            iconName={'TeamWork'}
                            titleChart={t('Horas por proyectos')}
                        ></PanelDonutChart>
                    ) : (
                        <NoDataFoundedForSelectedFilters text={t('Proyectos')} />
                    )}
                  </div>
                  <div className="ms-Grid-col ms-xl12 ms-xxl4">
                    {collaboratorsSummary.hoursForCompanies.length > 0 ? (
                        <PanelDonutChart
                            data={collaboratorsSummary.hoursForCompanies}
                            iconName={'CityNext2'}
                            titleChart={t('Horas por empresas')}
                        ></PanelDonutChart>
                    ) : (
                        <NoDataFoundedForSelectedFilters text={t('Empresas')} />
                    )}
                  </div>
                </div>
                <div className="ms-Grid-row mt-2">
                  <div className="ms-Grid-col ms-xl12 ms-xxl12">
                    {collaboratorsSummary.hoursForLastLevelSector.length > 0 ? (
                        <PanelVerticalBarChart
                            data={collaboratorsSummary.hoursForLastLevelSector}
                            color={'#3f51b5'}
                            concept={t('Horas')}
                            iconName={'Clock'}
                            titleChart={
                              <span>
                        {t('Horas registradas por ')}
                                <span className="fw-700">{t('Area')}</span>
                      </span>
                            }
                        />
                    ) : (
                        <>{<NoDataFoundedForSelectedFilters text={t('Area')} />}</>
                    )}

                    {collaboratorsSummary.hoursForCollaborators.length > 0 && isFilterByNode() ? (
                        <PanelVerticalBarChart
                            data={collaboratorsSummary.hoursForCollaborators}
                            color={'#3f51b5'}
                            concept={t('Horas')}
                            iconName={'Clock'}
                            titleChart={
                              <span>
                        {t('Horas registradas por ')}
                                <span className="fw-700">{t('Colaboradores')}</span>
                      </span>
                            }
                        />
                    ) : (
                        <>{isFilterByNode() && <NoDataFoundedForSelectedFilters text={t('Colaboradores')} />}</>
                    )}
                  </div>
                </div>
                <div className="ms-Grid-row mt-2">
                  <div className="ms-Grid-col ms-xl12 ms-xxl12">
                    {collaboratorsSummary.docsForCollaborators.length > 0 && isFilterByNode() ? (
                        <PanelVerticalBarChart
                            data={collaboratorsSummary.docsForCollaborators}
                            color={'#ffc107'}
                            concept={t('Documentos')}
                            iconName={'TextDocument'}
                            titleChart={t('Documentos registrados por colaboradores')}
                            onClickItem={(item) => {
                              navigate(`${routes.documents}?col_nombre=${item.name}`);
                            }}
                        ></PanelVerticalBarChart>
                    ) : (
                        <>
                          {isFilterByNode() && (
                              <div className="text-center mt-2">
                                <Text variant="medium" className="fw-500">
                                  <Icon iconName="TextDocument" className="text-icon"></Icon>
                                  {t(
                                      'No hay documentos registrados por colaboradores para los filtros seleccionados'
                                  )}
                                </Text>
                              </div>
                          )}
                        </>
                    )}
                  </div>
                </div>
                <div className="ms-Grid-row mt-2">
                  <Text className="ml-2" variant="medium">
                    <Icon iconName={'Clock'} className="text-icon"></Icon>{' '}
                    {t('Detalle de horas registradas por colaborador')}
                  </Text>

                  <PrimaryButton
                      iconProps={{ iconName: 'ExcelLogo' }}
                      className="float-right ml-2"
                      onClick={() =>
                          exportCollaboratorsComparison(collaboratorsSummary.collaboratorComp, nodes || [])
                      }
                  >
                    {t('Exportar')}
                  </PrimaryButton>
                  {from && to && (
                      <div className="mt-2 ms-Grid-col ms-xl12 ms-xxl12">
                        <CollaboratorsComparisonTable
                            collaboratorComp={collaboratorsSummary.collaboratorComp}
                            from={from}
                            to={to}
                            nodes={nodes || []}
                        />
                      </div>
                  )}
                </div>
              </div>
          )}
        </div>
      </ViewWrapperWithCommandBar>
  );
};
