import AssertionEntity from '../Assertion/AssertionEntity';
import ObjectError from '../Assertion/ObjectError';
import CustomInputIfc from './CustomInputIfc';

export type CallableFn = () => void;

export interface InputObserver {
  onEvaluate?(input: CustomInput): ObjectError[] | undefined;
  onValueChanged?(input: CustomInput): void;
}

class CustomInput implements CustomInputIfc {
  private valid: boolean;
  private changed: boolean;
  private propertyName: string;
  private propertyValue: any;
  private assertionEntity: AssertionEntity;
  private errorList: ObjectError[];
  private defaultValue: string;
  private inputObservers: InputObserver[];

  constructor(name: string, value: any, assertionEntity: AssertionEntity) {
    this.valid = false;
    this.changed = false;
    this.propertyName = name;
    this.propertyValue = value;
    this.assertionEntity = assertionEntity;
    this.errorList = [];
    this.defaultValue = value;
    this.inputObservers = [];
  }

  addInputObserver(observer: InputObserver) {
    this.inputObservers.push(observer);
  }

  isChanged(): boolean {
    return this.changed;
  }

  isValid(): boolean {
    return this.valid;
  }

  setValue(value: any) {
    this.propertyValue = value;
    this.changed = true;
    this.inputObservers.forEach((observer) => {
      if (observer.onValueChanged) observer.onValueChanged(this);
    });
  }

  setValueToDefault() {
    this.setValue(this.defaultValue);
    this.valid = false;
    this.changed = false;
  }

  setDefaultValue(value: any) {
    this.defaultValue = value;
  }

  onBlur() {
    // todo, no debería devolver la lista de errores
    return this.evaluate();
  }

  evaluate() {
    this.errorList = this.assertionEntity.evaluateIndividual(this.propertyName, this.propertyValue);
    this.inputObservers.forEach((observer) => {
      if (!observer.onEvaluate) return;
      const errors = observer.onEvaluate(this);
      if (errors) {
        this.errorList = this.errorList.concat(errors);
      }
    });
    this.valid = this.errorList.length === 0;
    return this.errorList;
  }

  getErrorList() {
    return [...this.errorList];
  }

  hasErrors() {
    return this.errorList.length > 0;
  }

  getValue() {
    return this.propertyValue;
  }

  getName() {
    return this.propertyName;
  }
}

export default CustomInput;
