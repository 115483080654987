import React, { useEffect, useState } from 'react';
import { CalendarEventCallout } from './CalendarEventCallout';
import { useBoolean, useId } from '@fluentui/react-hooks';
import TimeTaxSupportSystemClient from '../../system';
import MessageCourier from '../../lib/MessageCourier';
import { Icon } from '@fluentui/react';
import TTSEvent from './TTSEvent';
import { IProjectDescriptor } from '../../system/projects/ProjectDescriptor';
import { User } from '../../system/User';
import SystemConfiguration from '../../system/configuration/SystemConfiguration';
import PeriodDatesBlockedSystem from '../../system/period_dates_blocked/PeriodDatesBlockedSystem';

interface ICalendarEventRender {
  system: TimeTaxSupportSystemClient | undefined;
  user: User;
  eventData: any;
  projects: IProjectDescriptor[];
  commonContext: any;
  getEvents: () => void;
  courier: MessageCourier;
  changeBlockUI: (state: boolean) => void;
  activityIdFromNotif: string | null;
  eventWasClosed: boolean;
  onCloseEvent: (b: boolean) => void;
  // openUnableModifyHoursDialog: () => void;
  config: SystemConfiguration;
  periodsBlocked: PeriodDatesBlockedSystem;
}

export const CalendarEventRender = (props: ICalendarEventRender) => {
  const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] = useBoolean(false);
  const targetId = useId('target-' + props.eventData.event._def.extendedProps.data.id.substring(0, 20));
  const labelId = useId('label-' + props.eventData.event._def.extendedProps.data.id.substring(0, 20));
  const descriptionId = useId(
      'description-' + props.eventData.event._def.extendedProps.data.id.substring(0, 20)
  );

  const toggleCallout = () => {
    if (isCalloutVisible) {
      props.onCloseEvent(true);
    }
    toggleIsCalloutVisible();
  };

  useEffect(() => {
    props.onCloseEvent(false);
  }, [props.activityIdFromNotif]);

  useEffect(() => {
    if (
        !props.eventWasClosed &&
        props.activityIdFromNotif &&
        props.activityIdFromNotif === props.eventData.event._def.extendedProps.data.id &&
        !isCalloutVisible
    ) {
      toggleIsCalloutVisible();
    }
  }, [props.activityIdFromNotif]);

  const eventData = React.useMemo(() => {
    return props.eventData;
  }, [props.eventData]);

  const getClassNameByStatus = () => {
    let extendedProps = props.eventData.event._def.extendedProps.data;
    let _class = 'calendar-event-content-container ';

    if (extendedProps.id !== 'new') {
      if (extendedProps.isNewFromOutlook && (extendedProps.idOutlook || extendedProps.fromOutlook)) {
        _class += 'outlook-event';
      } else if (extendedProps.eliminada) {
        _class += 'deleted-event';
      } else if (extendedProps.isLicenseDay || extendedProps.isNonWorkingDay) {
        _class += 'license-event';
      } else {
        if (extendedProps.checked) {
          _class += 'checked-event';
        } else {
          _class += 'pending-event';
        }
      }
    } else {
      _class += 'checked-event';
    }
    return _class;
  };

  const isFromOutlook = () =>
      props.eventData.event._def.extendedProps.data.idOutlook ||
      props.eventData.event._def.extendedProps.data.fromOutlook;

  const isLicenseDay = () => props.eventData.event._def.extendedProps.data.isLicenseDay;
  const isNonWorkingDay = () => props.eventData.event._def.extendedProps.data.isNonWorkingDay;

  return (
      <>
        {props.system && targetId && (
            <div
                onClick={() => {
                  toggleCallout();
                }}
                id={targetId}
                className={getClassNameByStatus()}
                title={eventData.timeText + ' ' + eventData.event._def.title}
            >
              <div className="text-overflow ml-1">
                {isFromOutlook() && (
                    <Icon title="Evento de outlook" iconName="OutlookLogo" className="icon-outlook-calendar" />
                )}
                {(isLicenseDay() || isNonWorkingDay()) && (
                    <Icon title="Licencia" iconName="EventDateMissed12" className="icon-outlook-calendar" />
                )}

                <span>{eventData.event._def.title}</span>
              </div>
              <CalendarEventCallout
                  // openUnableModifyHoursDialog={props.openUnableModifyHoursDialog}
                  courier={props.courier}
                  system={props.system}
                  user={props.user}
                  getEvents={props.getEvents}
                  commonContext={props.commonContext}
                  projects={props.projects}
                  eventData={eventData}
                  targetId={targetId}
                  labelId={labelId}
                  descriptionId={descriptionId}
                  toggleIsCalloutVisible={toggleCallout}
                  isCalloutVisible={isCalloutVisible}
                  changeBlockUI={props.changeBlockUI}
                  config={props.config}
                  periodsBlocked={props.periodsBlocked}
              />
            </div>
        )}
      </>
  );
};
