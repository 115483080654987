import React from 'react';
import {
  IDropdownOption,
  Label,
  PrimaryButton,
  TextField,
  Text,
  Icon,
  Dropdown,
  IFacepilePersona,
  Persona,
  PersonaSize,
  DefaultButton,
  TooltipHost,
  IIconProps,
  ITooltipHostStyles,
} from '@fluentui/react';
import { IconButton } from '@fluentui/react/lib/Button';
import { useId } from '@fluentui/react-hooks';
import TimeTaxSupportSystemClient from '../../../system';
import MessageCourier from '../../../lib/MessageCourier';
import DropdownInput from '../../../lib/FormValidator/Form/DropdownInput';
import DocumentUploadControl from '../../../system/documents/DocumentUploadControl';
import CustomInputIfc from '../../../lib/FormValidator/Form/CustomInputIfc';
import { useTranslation } from 'react-i18next';
import { MailInspectorRulesTag } from '../../../system/mail/mail_inspector_rules_tag/MailInspectorRulesTag';
import MailInspectorRuleTagForm from './MailInspectorRuleTagForm';
import Project from '../../../system/projects/Project';
import ProjectCompanySaved from '../../../system/projects/ProjectCompanySaved';
import InputField from '../../../lib/FormValidator/Form/InputField';
import useForceUpdate from '../../../common/custom-hooks/useForceUpdate';
import ToggleInput from '../../../lib/FormValidator/Form/ToggleInput';
import { ProjectCompanyInDistribution } from '../../../system/projects/IProjectCompanyInDistribution';

interface IMailInspectorRuleTagViewProps {
  system: TimeTaxSupportSystemClient;
  courier: MessageCourier;
  ruleForm: MailInspectorRuleTagForm;
  projectSelected: Project;
  // keywords: string[];
  // onChangeKeywords: (strList: string[]) => void;
  changeBlockUI: (change: boolean) => void;
  refreshList: () => void;
  rules: MailInspectorRulesTag[];
  onCancel: () => void;
  companiesAvailable: ProjectCompanyInDistribution[];
  emailsAvailable: string[];
  onCheckAvailableOptions: () => void;
}

export const MailInspectorRuleTagView = (props: IMailInspectorRuleTagViewProps) => {
  const { t, i18n } = useTranslation();
  const forceUpdate = useForceUpdate();

  const [keyInput, setKeyInput] = React.useState<string>('');
  const [companyEmailInput, setCompanyEmailInput] = React.useState<string>('');

  // React.useEffect(() => {
  //   console.log(props.ruleForm);
  //   props.onChangeKeywords(props.ruleForm.getKeywords().getInputKeywordItems().getValue());
  // }, [props.ruleForm, props.keywords, props.ruleForm.getKeywords().getInputKeywordItems().getValue()]);

  const onSave = async () => {
    props.changeBlockUI(true);

    try {
      if (props.ruleForm.isValid()) {
        const rule = props.ruleForm.toMailInspectorRuleTag();
        await props.system.getMailInspectorRulesTagSystem().save(rule);
        props.courier.messageSuccess('Regla guardada con éxito');
        props.refreshList();
      }
    } catch (e) {
      props.courier.messageError(e);
    }

    props.changeBlockUI(false);
  };

  const [formDocIsValid, setFormDocIsValid] = React.useState(false);

  const onBlurCustomInputForDocForm = (customInput: CustomInputIfc) => {
    // props.onBlurCustomInput(customInput);
    setFormDocIsValid(props.ruleForm.isValid());
  };

  const deleteKeyItem = (keyword) => {
    props.ruleForm.getKeywords().getInputKeywordItems().delete(keyword);
    // props.onChangeKeywords(props.ruleForm.getKeywords().getInputKeywordItems().getValue());
    forceUpdate();
  };

  const addKeyItem = () => {
    props.ruleForm.getKeywords().getInputKeywordItems().add(keyInput);
    // props.onChangeKeywords(props.ruleForm.getKeywords().getInputKeywordItems().getValue());
    setKeyInput('');
    forceUpdate();
  };

  const onSelectTeamEmail = (email: string) => {
    props.ruleForm.getTeamEmails().getInputTeamEmailsItems().add(email);
    forceUpdate();
    props.onCheckAvailableOptions();
  };

  const deleteTeamEmailItem = (email: string) => {
    props.ruleForm.getTeamEmails().getInputTeamEmailsItems().delete(email);
    props.onCheckAvailableOptions();
    forceUpdate();
  };

  const addEmailCompanyItem = () => {
    props.ruleForm.getCompanyEmails().getInputCompanyEmailsItems().add(companyEmailInput);
    setCompanyEmailInput('');
    forceUpdate();
  };

  const deleteEmailCompanyItem = (email: string) => {
    props.ruleForm.getCompanyEmails().getInputCompanyEmailsItems().delete(email);
    forceUpdate();
  };

  const emojiIcon: IIconProps = { iconName: 'Help' };
  const calloutProps = { gapSpace: 0 };
  const hostStyles: Partial<ITooltipHostStyles> = { root: { display: 'inline-block' } };
  const companytooltipId = useId('companytooltip');
  const keytooltipId = useId('keytooltip');
  const temailstooltipId = useId('temailstooltip');
  const cemailstooltipId = useId('cemailstooltip');

  return (
      <div className="ms-Grid bg-white-h100 ms-depth-4" dir="ltr">
        <div className="ms-Grid-row padding-1">
          <div className="ms-Grid-col ms-sm12">
            <div className="ms-Grid-row">
              <div className="ms-Grid-col ms-sm12">
                <Text variant="mediumPlus" className="fw-500 d-block">
                  Parámetros MMI
                </Text>

                <Text variant="medium" className="d-block">
                  Con estos parámetros puedes canalizar el flujo de correo hacia este proyecto en forma
                  automática.
                </Text>
              </div>
            </div>
            <div className="ms-Grid-row mt-2">
              <div className="ms-Grid-col ms-sm12">
                <ToggleInput
                    input={props.ruleForm.getActiveInput()}
                    onBlur={onBlurCustomInputForDocForm}
                    offText={'Inactivo'}
                    onText={'Activo'}
                    propsInput={{
                      className: 'float-left mt-1',
                    }}
                />

                <PrimaryButton
                    text="Guardar"
                    onClick={() => onSave()}
                    iconProps={{ iconName: 'Save' }}
                    className="float-right ml-2"
                />
                <DefaultButton text="Cancelar" onClick={() => props.onCancel()} className="float-right" />
              </div>
            </div>

            <div className="ms-Grid-row mt-1  ">
              <div className="ms-Grid-col ms-sm9 ">
                <DropdownInput
                    input={props.ruleForm.getProjectCompany()}
                    onBlur={onBlurCustomInputForDocForm}
                    label={t('Empresa')}
                    options={props.companiesAvailable}
                    mapFn={(company) => {
                      if (company instanceof ProjectCompanySaved) {
                        let ret: IDropdownOption = {
                          key: company.getId(),
                          text: company.getCompany().getName(),
                        };
                        return ret;
                      }
                      return { key: '', text: '' };
                    }}
                    onRenderLabel={(l) => {
                      return (
                          <Label>
                            {l?.label}
                            <TooltipHost
                                content="Los correos electronicos enviados y recibidos que cumplan las reglas especificadas se asignarán a la empresa seleccionada"
                                id={temailstooltipId}
                                calloutProps={calloutProps}
                                styles={hostStyles}
                            >
                              <IconButton iconProps={emojiIcon} aria-label="Help" />
                            </TooltipHost>
                          </Label>
                      );
                    }}
                />
              </div>
            </div>

            <hr />
            <div className="ms-Grid-row">
              <div className="ms-Grid-col ms-sm12">
                <Text variant="medium" className="fw-500 d-block">
                  Canal de comunicación (Emails)
                </Text>

                <Text variant="smallPlus" className="d-block">
                  Indicando los emails de los interlocutores que trabajarán el este proyecto puedes canalizar
                  todos los correos cuyos remitentes están en la lista de email del equipo y los receptores en
                  la lista de emails de la empresa usuaria y viceversa.
                </Text>
              </div>
            </div>

            <div className="ms-Grid-row mt-1  ">
              <div className="ms-Grid-col ms-sm12">
                <div className="ms-Grid-row mt-1  ">
                  <div className="ms-Grid-col ms-sm9 ">
                    <Dropdown
                        label={t('Emails de equipo')}
                        options={props.emailsAvailable.map((email) => {
                          let ret: IDropdownOption = {
                            key: email,
                            text: email,
                          };
                          return ret;
                        })}
                        selectedKey={''}
                        onChange={(_event, option) => {
                          if (option) {
                            onSelectTeamEmail(String(option.key));
                          }
                        }}
                        onRenderLabel={(l) => {
                          return (
                              <Label>
                                {l?.label}
                                <TooltipHost
                                    content="Aquí van los correos de los colaboradores que se comunicaran mediante correos electronicos con la empresa usuaria"
                                    id={temailstooltipId}
                                    calloutProps={calloutProps}
                                    styles={hostStyles}
                                >
                                  <IconButton iconProps={emojiIcon} aria-label="Help" />
                                </TooltipHost>
                              </Label>
                          );
                        }}
                    ></Dropdown>
                  </div>
                </div>

                {props.ruleForm
                    .getTeamEmails()
                    .getInputTeamEmailsItems()
                    .getValue()
                    .map((teamEmail, i) => {
                      const initials = teamEmail.substring(0, 1).toUpperCase();

                      let persona = {
                        personaName: teamEmail,
                        imageInitials: initials,
                      };

                      return (
                          <div key={i} className="ms-Grid-row mt-1">
                            <div className="ms-Grid-col ms-sm8 ">
                              <Persona {...persona} text={teamEmail} size={PersonaSize.size24}></Persona>
                            </div>

                            <div className="ms-Grid-col ms-sm1 mt-1">
                              <Icon
                                  iconName="Trash"
                                  className="cursor-pointer"
                                  title="Eliminar del equipo"
                                  onClick={() => deleteTeamEmailItem(teamEmail)}
                              />
                            </div>
                          </div>
                      );
                    })}
              </div>
            </div>

            <div className="ms-Grid-row mt-1  ">
              <div className="ms-Grid-col ms-sm12">
                <div className="ms-Grid-row">
                  <div className="ms-Grid-col ms-sm9">
                    <TextField
                        id="userCompanyInput"
                        label={t('Emails de empresa')}
                        onChange={(e, v) => setCompanyEmailInput(String(v))}
                        value={companyEmailInput}
                        onRenderLabel={(l) => {
                          return (
                              <Label>
                                {l?.label}
                                <TooltipHost
                                    content="Aquí van los correos electrónicos de la empresa con la que se comunicaran para este proyecto"
                                    id={cemailstooltipId}
                                    calloutProps={calloutProps}
                                    styles={hostStyles}
                                >
                                  <IconButton iconProps={emojiIcon} aria-label="Help" />
                                </TooltipHost>
                              </Label>
                          );
                        }}
                    />
                  </div>
                  <div className="ms-Grid-col ms-sm3">
                    <PrimaryButton
                        onClick={() => addEmailCompanyItem()}
                        iconProps={{ iconName: 'Add' }}
                        className="primary-button mt-for-btn-add"
                    />
                  </div>
                </div>

                {props.ruleForm
                    .getCompanyEmails()
                    .getInputCompanyEmailsItems()
                    .getValue()
                    .map((companyEmail, i) => {
                      const initials = companyEmail.substring(0, 1).toUpperCase();

                      let persona = {
                        personaName: companyEmail,
                        imageInitials: initials,
                      };

                      return (
                          <div key={i} className="ms-Grid-row mt-1">
                            <div className="ms-Grid-col ms-sm8 ">
                              <Persona {...persona} text={companyEmail} size={PersonaSize.size24}></Persona>
                            </div>

                            <div className="ms-Grid-col ms-sm1 mt-1">
                              <Icon
                                  iconName="Trash"
                                  className="cursor-pointer"
                                  title="Eliminar del equipo"
                                  onClick={() => deleteEmailCompanyItem(companyEmail)}
                              />
                            </div>
                          </div>
                      );
                    })}
              </div>

              <div className="ms-Grid-col ms-sm12">
                <hr />
                <div className="ms-Grid-row">
                  <div className="ms-Grid-col ms-sm12">
                    <Text variant="medium" className="fw-500 d-block">
                      Claves
                      <TooltipHost
                          content="Aquí se especificaran las palabras/frases claves que se buscaran dentro de los correos que se envien entre los participantes especificados, se recomienda ser lo mas especifico posible"
                          id={keytooltipId}
                          calloutProps={calloutProps}
                          styles={hostStyles}
                      >
                        <IconButton iconProps={emojiIcon} aria-label="Help" />
                      </TooltipHost>
                    </Text>

                    <Text variant="smallPlus" className="d-block">
                      Si indicas claves, se incluiran solo los mails que tengan alguna de las claves indicadas
                      en el cuerpo del mail o en el asunto.
                    </Text>
                  </div>
                </div>

                <div className="ms-Grid-row">
                  <div className="ms-Grid-col ms-sm9">
                    <TextField
                        id="keyInput"
                        // label={t('Claves')}
                        // onRenderLabel={(l) => {
                        // return <Label>{l?.label}</Label>;
                        // }}
                        onChange={(e, v) => setKeyInput(String(v?.replace(',', '')))}
                        value={keyInput}
                        className="mt-1"
                    />
                  </div>
                  <div className="ms-Grid-col ms-sm3">
                    <PrimaryButton
                        onClick={() => addKeyItem()}
                        iconProps={{ iconName: 'Add' }}
                        className="primary-button mt-1"
                    />
                  </div>
                </div>

                {props.ruleForm
                    .getKeywords()
                    .getInputKeywordItems()
                    .getValue()
                    .map((keyword, i) => {
                      return (
                          <div key={i} className="ms-Grid-row mt-1">
                            <div className="ms-Grid-col ms-sm8 ">
                              <Text variant="medium">
                                <Icon iconName="Permissions" className="text-icon" />
                                {keyword}
                              </Text>
                            </div>

                            <div className="ms-Grid-col ms-sm1 mt-1">
                              <Icon
                                  iconName="Trash"
                                  className="cursor-pointer"
                                  title="Eliminar del equipo"
                                  onClick={() => deleteKeyItem(keyword)}
                              />
                            </div>
                          </div>
                      );
                    })}
              </div>
            </div>

            {/* <div className="ms-Grid-row mt-2">
            <div className="ms-Grid-col ms-sm6">
              <ToggleInput
                input={props.ruleForm.getActiveInput()}
                onBlur={onBlurCustomInputForDocForm}
                offText={'Inactivo'}
                onText={'Activo'}
              />
            </div>
          </div> */}
          </div>
        </div>
      </div>
  );
};
