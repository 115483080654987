import React, { useEffect, useState, useMemo } from 'react';
import { Stack, IStackTokens } from '@fluentui/react/lib/Stack';
import { Text } from '@fluentui/react/lib/Text';
import { Icon } from '@fluentui/react/lib/Icon';
import { CARD_MODE, GRID_MODE, OPERATORS, TYPES } from '../../common/list-utils';
import { DefaultButton, PrimaryButton } from '@fluentui/react/lib/Button';
import { IDocumentCardPreviewProps, IDocumentCardActivityPerson } from '@fluentui/react/lib/DocumentCard';
import { DefaultPalette, getTheme } from '@fluentui/react/lib/Styling';
import { TestImages } from '@fluentui/example-data';
import axios from 'axios';
import { ListColumnFilter } from '../Common/list-entities/ListColumnFilter';
import { IDatagrid } from '../Common/DetailsList/DetailListView';
import { timeSince } from '../../common/list-utils';
import { boldStyle } from '../Common/TextStyles';
import TimeTaxSupportSystemClient from '../../system';
import { ICommandBarItemProps, IDropdownOption } from '@fluentui/react';
import { buttonStylesForProjects, defaultCommandBarButtonStyles } from '../../styles/button-styles';
import { routes } from '../../common/constants';
import { useNavigate } from 'react-router-dom';
import ViewWrapperWithCommandBar from '../Layout/ViewWrapperWithCommandBar';
import { useTranslation, Trans } from 'react-i18next';
import MessageCourier from '../../lib/MessageCourier';
import { CostPerHourGridList } from './List/CostPerHourGridList';
import useSystemCall from '../../common/custom-hooks/useSystemCall';
import { IComboBoxOption } from '@fluentui/react';

const stackTokens: IStackTokens = { childrenGap: 20 };
const theme = getTheme();
const { palette, fonts } = theme;

interface ICostPerHourList {
  system: TimeTaxSupportSystemClient;
  changeBlockUI: (state: boolean) => void;
  courier: MessageCourier;
}
export const CostPerHourList = (props: ICostPerHourList) => {
  const { t, i18n } = useTranslation();
  const [mode, setMode] = useState(GRID_MODE);
  const [filtersData, setFiltersData] = useState<any>();
  const [filterPanel, setFilterPanel] = useState(false);

  const navigate = useNavigate();

  const baseUrl = React.useMemo(() => {
    return props.system.getCostPerHourSystem().getBaseUrlForDatagrid();
  }, [props]);

  const onItemClick = (item: any) => {
    // alert('Click!!')
    navigate(`${routes.cost_per_hour_detail}/${item.rcp_id}`);
  };

  const [CPH, setCPH] = useState<IDropdownOption<any>[]>([]);

  // React.useEffect(() => {
  //   const getCPH = async () => {
  //     const _CPH = await props.system.getCostPerHourSystem().getCostsPerHour();
  //     // console.log(_CPH)
  //     const years: number[] = [];
  //     _CPH.forEach((costPH) => {
  //       // console.log(costPH)
  //       if (!years.includes(costPH.getYear())) {
  //         years.push(costPH.getYear());
  //       }
  //     });
  //     const mappedYears = years.map((year) => {
  //       return {
  //         key: year.toString(),
  //         text: year.toString(),
  //       };
  //     });
  //     setCPH(mappedYears);
  //   };
  //   getCPH();
  // }, []);

  const commonContext = useSystemCall(props.system, (system) =>
      system.getActivitySystem().getCommonContext()
  );

  const collaboratorList = React.useMemo(() => {
    let ret: IComboBoxOption[] = [];
    if (commonContext) {
      ret = commonContext.colaboradores.map((col) => {
        return {
          key: col.getEmail(),
          text: col.fullName(),
        };
      });
    }
    return ret;
  }, [commonContext]);

  const months: IDropdownOption<any>[] = [
    { key: '1', text: t('Enero') },
    { key: '2', text: t('Febrero') },
    { key: '3', text: t('Marzo') },
    { key: '4', text: t('Abril') },
    { key: '5', text: t('Mayo') },
    { key: '6', text: t('Junio') },
    { key: '7', text: t('Julio') },
    { key: '8', text: t('Agosto') },
    { key: '9', text: t('Septiembre') },
    { key: '10', text: t('Octubre') },
    { key: '11', text: t('Noviembre') },
    { key: '12', text: t('Diciembre') },
  ];

  const quickFilters: ListColumnFilter[] = useMemo(
      () => [
        {
          quick: true,
          name: 'col_email',
          type: TYPES.COMBOBOX,
          placeholder: t('Colaborador'),
          operator: OPERATORS.EQUAL,
          contextForCombobox: collaboratorList || [],
        },
        {
          quick: true,
          name: 'rcp_periodo_mes',
          type: TYPES.COMBOBOX,
          placeholder: t('Mes'),
          operator: OPERATORS.EQUAL,
          contextForCombobox: months,
        },
        {
          quick: true,
          name: 'rcp_periodo_anio',
          type: TYPES.INPUT,
          placeholder: t('Año'),
          operator: OPERATORS.EQUAL,
        },
      ],
      [collaboratorList, months]
  );

  const itemsMenuCalendar = React.useMemo(
      (): ICommandBarItemProps[] => [
        {
          key: 'add',
          text: t('Nuevo'),
          iconProps: { iconName: 'Add', color: DefaultPalette.themeLighterAlt },
          buttonStyles: buttonStylesForProjects,
          onClick: () => navigate(`${routes.cost_per_hour_detail}/new`),
        },
        {
          key: 'excel',
          text: t('Importador Excel'),
          iconProps: { iconName: 'ExcelLogo', color: DefaultPalette.themeLighterAlt },
          buttonStyles: defaultCommandBarButtonStyles,
          onClick: () => navigate(`${routes.cost_per_hour_view}`),
        },
      ],
      [navigate]
  );

  return (
      <ViewWrapperWithCommandBar
          withBorderBottom
          title={''}
          items={itemsMenuCalendar}
          overflowButtonStyles={defaultCommandBarButtonStyles}
          variant="standard"
      >
        <>
          <CostPerHourGridList
              changeBlockUI={props.changeBlockUI}
              baseUrl={baseUrl}
              quickFilters={quickFilters}
              onClickCardMode={() => setMode(CARD_MODE)}
              onClickGridMode={() => setMode(GRID_MODE)}
              mode={mode}
              keyList={'costPerHour'}
              onItemClick={onItemClick}
              courier={props.courier}
          ></CostPerHourGridList>

          {/* {mode == GRID_MODE ? (
        ) : (
          <SettlementsCardList
            changeBlockUI={props.changeBlockUI}
            baseUrl={baseUrl}
            quickFilters={quickFilters}
            filterPanel={filterPanel}
            onClickCardMode={() => setMode(CARD_MODE)}
            onClickGridMode={() => setMode(GRID_MODE)}
            courier={props.courier}
            mode={mode}
            onItemClick={onItemClick}
          ></SettlementsCardList>
        )} */}
        </>
      </ViewWrapperWithCommandBar>
  );
};
