import React from 'react';
import { ICommandBarItemProps } from '@fluentui/react/lib/CommandBar';

import ViewWrapperWithCommandBar from '../Layout/ViewWrapperWithCommandBar';
import { themeLight } from '../../styles/colors';
import { buttonStylesFor } from '../../styles/button-styles';
import CalendarProjectView, { CalendarFilters } from './CalendarProjectView';
import CalendarMenu, {
  ICalendarConfig,
  LAST_NAVIGATION,
  MODE_24HS,
  SHOW_WEEKENDS
} from './CalendarMenu';
import TimeTaxSupportSystemClient from '../../system';
import Project from '../../system/projects/Project';
import useSystemCall from '../../common/custom-hooks/useSystemCall';
import { newEntityDefaultId, routes } from '../../common/constants';
import TTSEvent from './TTSEvent';
import { Calendar, getDefaultFilters } from '../../classes/Calendar/Calendar';
import MessageCourier from '../../lib/MessageCourier';
import { useNavigate } from 'react-router-dom';
import { CalendarNoProjectsDialog } from './CalendarNoProjectsDialog';
import { IProjectDescriptor } from '../../system/projects/ProjectDescriptor';
import { User } from '../../system/User';
import { useSearchParams } from 'react-router-dom';
import SystemConfiguration from '../../system/configuration/SystemConfiguration';
import { ICalendarHorasEsperadasProgressBar } from './CalendarHorasEsperadasProgressBar';
import PeriodDatesBlockedSystem from '../../system/period_dates_blocked/PeriodDatesBlockedSystem';

const defaultCommandBarButtonStyles = buttonStylesFor({
  backgroundColor: themeLight.backgroundSecondary,
  backgroundColorFocused: themeLight.backgroundSecondaryFocused,
});

interface ICalendarViewProps {
  system: TimeTaxSupportSystemClient;
  courier: MessageCourier;
  config: SystemConfiguration;
  user: User;
  changeBlockUI: (state: boolean) => void;
  periodsBlocked: PeriodDatesBlockedSystem;
}

export interface IActivityCounts {
    general: number;
    outlook: number;
    license: number;
    nonWorkingDay: number;
    eliminado: number;
    actAceptadas: number;
    actDeclinadas: number;
}

const CalendarView: React.FC<ICalendarViewProps> = ({ system, ...props }) => {
  const calendar = React.useMemo(() => {
    return new Calendar(system.getActivitySystem());
  }, [system]);

  const commonContext = useSystemCall(system, (system) => system.getActivitySystem().getCommonContext());

  const get24hsFromLocalStore = (): boolean => {
    let calendarConfigStorage = localStorage.getItem('calendarConfig');
    let calendarConfig = {} as ICalendarConfig;
    if (calendarConfigStorage) {
      calendarConfig = JSON.parse(calendarConfigStorage);
      if (calendarConfig[MODE_24HS] != undefined) {
        return !!calendarConfig[MODE_24HS];
      }
    }
    return false;
  };

  const getLastNavigationFromLocalStore = (): Date | undefined => {
    let calendarConfigStorage = localStorage.getItem('calendarConfig');
    let calendarConfig = {} as ICalendarConfig;
    if (calendarConfigStorage) {
      calendarConfig = JSON.parse(calendarConfigStorage);
      if (calendarConfig[LAST_NAVIGATION] != undefined) {
        return new Date(calendarConfig[LAST_NAVIGATION]);
      }
    }
    return undefined;
  };

  const getShowWeekendsFromLocalStore = (): boolean => {
    let calendarConfigStorage = localStorage.getItem('calendarConfig');
    let calendarConfig = {} as ICalendarConfig;
    if (calendarConfigStorage) {
      calendarConfig = JSON.parse(calendarConfigStorage);
      if (calendarConfig[SHOW_WEEKENDS] != undefined) {
        return !!calendarConfig[SHOW_WEEKENDS];
      }
    }
    return true;
  };

  const ttsevent = new TTSEvent();
  const projects = useSystemCall(system, (system) => system.getProjects());
  const [selectedProject, setSelectedProject] = React.useState<IProjectDescriptor>();
  const [eventList, setEventList] = React.useState<any[]>([]);
  const [horasData, setHorasData] = React.useState<ICalendarHorasEsperadasProgressBar>({
    horasEsperadas: 0,
    horasCargadasPlantilla: 0,
    horasExcedidas: 0,
    horasFaltantes: 0,
    horasLicencia: 0,
    fechaDesde: '',
    fechaHasta: '',
    user: props.user,
  });
  const [filters, setFilters] = React.useState<CalendarFilters>(getDefaultFilters());

  const [showMenu, setShowMenu] = React.useState<boolean>(true);
  const [showNoProjectsDialog, setShowNoProjectsDialog] = React.useState<boolean>(false);
  // const [unableModifyHoursDialog, setUnableModifyHoursDialog] = React.useState<boolean>(false);
  const [showConfigMenu, setShowConfigMenu] = React.useState<boolean>(false);
  const [habilitar24Horas, setHabilitar24Horas] = React.useState<boolean>(get24hsFromLocalStore());
  const [showWeekends, setShowWeekends] = React.useState<boolean>(getShowWeekendsFromLocalStore());
  const [activeStart, setActiveStart] = React.useState<Date | undefined>();
  const [goToDate, setGoToDate] = React.useState<Date | undefined>();
  const [activeEnd, setActiveEnd] = React.useState<Date | undefined>(undefined);
  const [startCalendar, setStartCalendar] = React.useState<string>('07:00');
  const [ActivityCounts, setActivityCounts] = React.useState<IActivityCounts>({
    general: 0,
    outlook: 0,
    license: 0,
    nonWorkingDay: 0,
    eliminado: 0,
    actAceptadas: 0,
    actDeclinadas: 0,
  });

  const queryParams = new URLSearchParams(window.location.search);
  const activityIdFromNotif = queryParams.get('Actividad');

  React.useEffect(() => setSelectedProject(projects && projects[0]), [projects]);

  // React.useEffect(() => {
  //   setTimeout(() => {
  //     setGoToDate(getLastNavigationFromLocalStore());
  //   }, 3000);
  // }, []);

  React.useEffect(() => {}, [activeStart, activeEnd]);
  const navigate = useNavigate();

  React.useEffect(() => {
    if (projects && projects.length === 0) {
      setShowNoProjectsDialog(true);
    }
  }, [projects]);

  const onCloseNoProjectsDialog = (confirm: boolean) => {
    if (confirm) {
      setShowNoProjectsDialog(false);
      navigate(`${routes.project_config}/${newEntityDefaultId}`);
    } else {
      setShowNoProjectsDialog(false);
    }
  };

  // const onCloseUnableModifyHoursDialog = (confirm: boolean) => {
  //   setUnableModifyHoursDialog(false);
  //   getEvents();
  // };

  const getHorasEsperadas = async () => {
    if (activeStart && activeEnd) {
      try {
        console.log("getHorasEsperadas" + activeStart + " " + activeEnd);
        let horasData = await calendar.getHorasEsperadas(activeStart, activeEnd);
        setHorasData(horasData);
      } catch (error) {
        props.courier.messageError(error);
      }
    }
  };

  const getEvents = async () => {
    if (activeStart && activeEnd) {
      props.changeBlockUI(true);
      try {
        let _events = await calendar.getEvents(
            activeStart,
            activeEnd,
            props.user.getCollaboratorId(),
            filters
        );
        await getHorasEsperadas();
        setEventList(_events);
        const countsSateActvi = calendar.getActivityCountByType();
        setActivityCounts(countsSateActvi);
      } catch (error) {
        props.courier.messageError(error);
      }

      props.changeBlockUI(false);
    }
  };

  const [searchParams, setSearchParams] = useSearchParams();

  const onChangeCalendar = (_date: Date) => {
    setGoToDate(_date);
  };

  const onChangeFilters = async (name: string, value: any) => {
    // if (activeStart && activeEnd) {
    const _filters = { ...filters };
    // const _filters = calendar.processFilters(name, value);
    _filters[name] = value;
    setFilters(_filters);
    // }
  };

  React.useEffect(() => {
    getEvents();
    // getHorasEsperadas();
  }, [activeStart, filters]);

  const onTimeIntervalChange = (start: Date, end: Date) => {
    console.log(start,"eesta aqui las fechas modificadad ",end)
    setActiveStart(start);
    setActiveEnd(end);
  };

  const onClickProject = (project: Project) => () => {
    setSelectedProject(project);
  };

  const itemsMenuCalendar = React.useMemo(
      (): ICommandBarItemProps[] => [
        {
          key: 'showMenu',
          text: '',
          iconProps: { iconName: 'GlobalNavButton' },
          buttonStyles: defaultCommandBarButtonStyles,
          onClick: () => {
            setShowMenu(!showMenu);
          },
        },
        {
          key: 'config',
          text: '',
          iconProps: { iconName: 'Settings' },
          buttonStyles: defaultCommandBarButtonStyles,
          onClick: () => {
            if (!showMenu) setShowMenu(true);
            setShowConfigMenu(!showConfigMenu);
          },
        },
        {
          key: 'refreshEvents',
          text: '',
          iconProps: { iconName: 'Refresh' },
          buttonStyles: defaultCommandBarButtonStyles,
          onClick: () => {
            getEvents();
          },
        },
      ],
      [showConfigMenu, selectedProject, projects, showMenu, activeStart, activeEnd, filters]
  );

  const onNewEventClick = (newEvent: /*ActividadEvent*/ any) => {
    addNewEvent(newEvent);
  };

  const cleanFilters = () => {
    // const _filters = calendar.cleanFilters();
    setFilters(getDefaultFilters());
    setGoToDate(new Date());
    setSelectedProject(undefined);
  };

  const addNewEvent = (newEvent: any /*ActividadEvent*/) => {
    const events = [...eventList];
    if (
        events.length > 0 &&
        ttsevent.isNewEvent(events[events.length - 1]) &&
        (!ttsevent.isNewEventFromOutlook(events[events.length - 1]) ||
            !ttsevent.isLicenseEvent(events[events.length - 1]))
    ) {
      events.pop();
    }
    events.push(newEvent);

    setEventList(events);
    changeActividad(newEntityDefaultId, newEvent.start, newEvent.end);
  };

  const changeActividad = (id: string, start?: any, end?: any) => {
    const startDate = id === newEntityDefaultId ? start : undefined;
    const endDate = id === newEntityDefaultId ? end : undefined;
  };

  const onChangeStartCalendar = (start: string) => {
    setStartCalendar(start);
  };

  const getClassNameMenu = () => {
    if (showMenu) return 'ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl2 ms-xxl2 px-0';
    else return '';
  };

  const getClassNameCalendar = () => {
    if (showMenu) return 'ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl10 ms-xxl10 px-0';
    else return 'ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 px-0';
  };

  const onChange24hs = (checked: boolean) => {
    setHabilitar24Horas(checked);
  };

  const onChangeShowWeekends = (checked: boolean) => {
    setShowWeekends(checked);
  };

  const cleanNotifParam = (v: boolean) => {
    // if (v) {
    //   if (searchParams.has('Actividad')) {
    //     searchParams.delete('Actividad');
    //     setSearchParams(searchParams);
    //   }
    // }
  };

  return (
      <div className="ms-Grid" dir="ltr">
        <div className="ms-Grid-row">
          <div className={getClassNameMenu()}>
            <ViewWrapperWithCommandBar
                withBorderBottom
                title={''}
                items={itemsMenuCalendar}
                overflowButtonStyles={defaultCommandBarButtonStyles}
                variant="highligth"
            >
              {showMenu && (
                  <CalendarMenu
                      projects={projects}
                      courier={props.courier}
                      system={system}
                      onChange24hs={onChange24hs}
                      onChangeShowWeekends={onChangeShowWeekends}
                      onChangeFilters={onChangeFilters}
                      onChangeCalendar={onChangeCalendar}
                      onChangeStartCalendar={onChangeStartCalendar}
                      value24hs={habilitar24Horas}
                      valueShowWeekends={showWeekends}
                      valueStartCalendar={startCalendar}
                      filters={filters}
                      showConfigMenu={showConfigMenu}
                      cleanFilters={cleanFilters}
                      user={props.user}
                  />
              )}
            </ViewWrapperWithCommandBar>
          </div>
          <div className={getClassNameCalendar()}>
            {projects && (
                <CalendarProjectView
                    periodsBlocked={props.periodsBlocked}
                    horasDataPanel={horasData}
                    user={props.user}
                    habilitar24hs={habilitar24Horas}
                    showWeekends={showWeekends}
                    courier={props.courier}
                    commonContext={commonContext}
                    system={system}
                    goTo={goToDate}
                    calendarEvents={eventList}
                    projects={projects}
                    startCalendar={startCalendar}
                    onNewEventClick={onNewEventClick}
                    onTimeIntervalChange={onTimeIntervalChange}
                    getEvents={getEvents}
                    changeBlockUI={props.changeBlockUI}
                    activityIdFromNotif={activityIdFromNotif}
                    // openUnableModifyHoursDialog={() => setUnableModifyHoursDialog(true)}
                    config={props.config}
                    cleanNotifParam={cleanNotifParam}
                    activeStart={activeStart}
                    activeEnd={activeEnd}
                    ActivityCounts = {ActivityCounts}
                />
            )}
          </div>
        </div>

        <CalendarNoProjectsDialog
            show={showNoProjectsDialog}
            onClose={onCloseNoProjectsDialog}
        ></CalendarNoProjectsDialog>

        {/* <UnableModifyHoursDialog
        show={unableModifyHoursDialog}
        onClose={onCloseUnableModifyHoursDialog}
      ></UnableModifyHoursDialog> */}
      </div>
  );
};

export default CalendarView;
