import XhrRequestHandler from '../../lib/XhrRequestHandler';
import { SystemDateParser } from '../SystemDateParser';

export interface INotification {
  actividad: string | null;
  colaborador: string | null;
  not_activo: string;
  not_asunto: string;
  not_dest_colid: string;
  not_entidad: string;
  not_entidad_id: string;
  not_fecha: string;
  not_id: string;
  not_leido: boolean;
  not_leido_fecha: string;
  not_ori_colid: string | null;
  not_texto: string;
  not_tipo: string;
  // TODO: completar
}

export class NotificationSystem {
  private readonly entityBase = '/notificaciones';

  constructor(private requestHandler: XhrRequestHandler, private dateParser: SystemDateParser) {}

  async getLastNotifications(leidos = true): Promise<unknown[]> {
    let params = leidos ? '' : '?not_leido=0';
    const data = await this.requestHandler.get<INotification[]>(`${this.entityBase}${params}`);
    // TODO: generar una clase y transformar los datos
    return data;
  }

  getBaseUrlForDatagrid() {
    return this.requestHandler.requestUrl(`${this.entityBase}/datagrid`);
  }

  async getAllNotifications(): Promise<unknown[]> {
    const data = await this.requestHandler.get<INotification[]>(`${this.entityBase}?no_limit=1`);
    return data;
  }

  async markAsRead(id: string) {
    return this.requestHandler.post(`${this.entityBase}/marcar-leida/${id}?leer=1`);
  }

  async markAsReadList(idList: string[]) {
    return this.requestHandler.post(`${this.entityBase}/marcar-leida-lista?leer=1`, {
      idNotificaciones: idList,
    });
  }

  async markAsUnread(id: string) {
    return this.requestHandler.post(`${this.entityBase}/marcar-leida/${id}?leer=0`);
  }

  async markAsUnreadList(idList: string[]) {
    return this.requestHandler.post(`${this.entityBase}/marcar-leida-lista?leer=0`, {
      idNotificaciones: idList,
    });
  }

  async deleteList(idList: string[]) {
    return this.requestHandler.post(`${this.entityBase}/desactivar-notificacion-lista?leer=1`, {
      idNotificaciones: idList,
    });
  }

  async delete(id: string) {
    return this.requestHandler.post(`${this.entityBase}/desactivar-notificacion/${id}`);
  }

  async getTotal() {
    return this.requestHandler.get<number>(`${this.entityBase}/notificacion-total`);
  }
}
