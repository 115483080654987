import * as React from 'react';
import { Callout, Link, mergeStyleSets, Text, FontWeights, IIconProps } from '@fluentui/react';
import { useBoolean, useId } from '@fluentui/react-hooks';
import { DefaultButton, IconButton } from '@fluentui/react/lib/Button';
import i18next, { t } from 'i18next';

export const TooltipInfoMiniPanel: React.FunctionComponent = () => {
  const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] = useBoolean(false);
  const buttonId = useId('callout-button');
  const labelId = useId('callout-label');
  const descriptionId = useId('callout-description');
  const infoIcon: IIconProps = { iconName: 'Info' };

  return (
      <>
        <IconButton
            iconProps={infoIcon}
            title="Info"
            ariaLabel="Info"
            id={buttonId}
            onClick={toggleIsCalloutVisible}
            className="btn-custom-h"
        />

        {/* <DefaultButton
        text={isCalloutVisible ? 'Hide callout' : 'Show callout'}
        className={styles.button}
      /> */}
        {isCalloutVisible && (
            <Callout
                className={styles.callout}
                ariaLabelledBy={labelId}
                ariaDescribedBy={descriptionId}
                role="dialog"
                gapSpace={0}
                target={`#${buttonId}`}
                onDismiss={toggleIsCalloutVisible}
                setInitialFocus
            >
              <Text block variant="xLarge" className={styles.title} id={labelId}>
                {t('Recuerda')}
              </Text>
              <Text block variant="small" id={descriptionId}>
                {t(
                    'En este panel no se contabilizan horas posteriores al día de hoy, y las horas son totales, esto significa que por actividad se suman las horas de cada uno de los colaboradores que hayan confirmado su participación'
                )}
              </Text>
            </Callout>
        )}
      </>
  );
};

const styles = mergeStyleSets({
  button: {
    width: 130,
  },
  callout: {
    width: 320,
    maxWidth: '90%',
    padding: '20px 24px',
  },
  title: {
    fontWeight: FontWeights.semilight,
  },
  link: {
    display: 'block',
    marginTop: 20,
  },
});
