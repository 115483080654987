import { Text } from '@fluentui/react';
import { useTranslation, Trans } from 'react-i18next';
import ObjectError from '../Assertion/ObjectError';
import CustomInput from './CustomInput';
import CustomInputIfc from './CustomInputIfc';

interface IErrorList {
  errors: ObjectError[];
}

export const ErrorList = (props: IErrorList) => {
  const { t, i18n } = useTranslation();

  return (
      <div className="d-grid">
        {props.errors.map((error, i) => {
          return (
              <Text className="error-message" key={i} variant="medium">
                {t(error.getErrorMsg())}
              </Text>
          );
        })}
      </div>
  );
};
