class ObjectError {
  constructor(private errorMsg: string, private objProperty: string) {}

  getErrorMsg() {
    return this.errorMsg;
  }

  getObjProperty() {
    return this.objProperty;
  }
}

export default ObjectError;
