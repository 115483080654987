import * as React from 'react';
import { DetailsList, DetailsListLayoutMode, IColumn } from '@fluentui/react/lib/DetailsList';
import { CheckboxVisibility, TextField, Toggle } from 'office-ui-fabric-react';
import { IResumenColaboradoresComp } from '../../../system/panel/PanelSystem';
import { DetailsRow, IDetailsRowStyles, IDetailsListProps } from '@fluentui/react/lib/DetailsList';
import { DateTime } from 'luxon';

import { routes } from '../../../common/constants';
import { useNavigate } from 'react-router-dom';
import { OrganizationChartNode } from '../../../system/organization_chart/OrganizationChartNode';
import { useTranslation } from 'react-i18next';

interface ICollaboratorsComparisonTable {
  collaboratorComp: IResumenColaboradoresComp[];
  from: DateTime;
  to: DateTime;
  nodes: OrganizationChartNode[];
}

export const CollaboratorsComparisonTable = (props: ICollaboratorsComparisonTable) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const items = React.useMemo(() => {
    return props.collaboratorComp.map((col) => {
      return {
        id: col.colaborador.col_id,
        name: col.colaborador.col_nombre + ' ' + col.colaborador.col_apellido,
        col_id_sap: col.colaborador.col_id_sap,
        horas_totales: col.horas_totales,
        docs_totales: col.docs_totales,
        sector: props.nodes
            .find((node) => node.isIdentifiedBy(col.colaborador.cargoColaborador?.carcol_norid || ''))!
            .path(),
      };
    });
  }, [props.collaboratorComp, props.nodes]);

  const [itemsFiltered, setItemsFiltered] = React.useState<any[]>([]);
  const [filterByHs, setFilterByHs] = React.useState<boolean | undefined>(false);

  function copyAndSort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
    const key = columnKey as keyof T;

    return items
        .slice(0)
        .sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
  }

  const onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
    const newColumns: IColumn[] = columns.slice();
    const currColumn: IColumn = newColumns.filter((currCol) => column.key === currCol.key)[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
        // this.setState({
        //   announcedMessage: `${currColumn.name} is sorted ${
        //     currColumn.isSortedDescending ? 'descending' : 'ascending'
        //   }`,
        // });
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });
    const newItems = copyAndSort(itemsFiltered, currColumn.fieldName!, currColumn.isSortedDescending);
    setItemsFiltered(newItems);
    setColumns(newColumns);
  };

  const composeColumns = () => {
    setColumns([
      {
        key: 'name',
        name: t('Colaborador'),
        fieldName: 'name',
        minWidth: 250,
        maxWidth: 250,
        onColumnClick: onColumnClick,
      },
      {
        key: 'sap_id',
        name: t('SAP ID'),
        fieldName: 'col_id_sap',
        minWidth: 150,
        maxWidth: 150,
        onColumnClick: onColumnClick,
      },
      {
        key: 'sector',
        name: t('Sector'),
        fieldName: 'sector',
        minWidth: 600,
        maxWidth: 600,
        onColumnClick: onColumnClick,
      },
      {
        key: 'horas_totales',
        name: t('Horas totales'),
        fieldName: 'horas_totales',
        minWidth: 100,
        maxWidth: 100,
        onColumnClick: onColumnClick,
      },
      {
        key: 'docs_totales',
        name: t('Documentos cargados'),
        fieldName: 'docs_totales',
        minWidth: 100,
        maxWidth: 100,
        onColumnClick: onColumnClick,
      },
    ]);
  };

  const [columns, setColumns] = React.useState<IColumn[]>([]);

  React.useEffect(() => {
    setItemsFiltered(items);
  }, [items]);

  React.useEffect(() => {
    composeColumns();
  }, [itemsFiltered]);

  const mapItems = React.useCallback(() => {
    return props.collaboratorComp.map((item) => {
      console.log('item > ', item);
      if (filterByHs && item.horas_totales === 0) {
        return {
          id: item.colaborador.col_id,
          name: item.colaborador.col_nombre + ' ' + item.colaborador.col_apellido,
          col_id_sap: item.colaborador.col_id_sap,
          horas_totales: item.horas_totales,
          docs_totales: item.docs_totales,
          sector: props.nodes
              .find((node) => node.isIdentifiedBy(item.colaborador.cargoColaborador?.carcol_norid || ''))!
              .path(),
        };
      } else if (!filterByHs) {
        return {
          id: item.colaborador.col_id,
          name: item.colaborador.col_nombre + ' ' + item.colaborador.col_apellido,
          horas_totales: item.horas_totales,
          docs_totales: item.docs_totales,
          col_id_sap: item.colaborador.col_id_sap,
          sector: props.nodes
              .find((node) => node.isIdentifiedBy(item.colaborador.cargoColaborador?.carcol_norid || ''))!
              .path(),
        };
      }
    });
  }, [props.collaboratorComp, props.nodes]);

  React.useEffect(() => {
    setItemsFiltered(mapItems());
  }, [mapItems]);

  React.useEffect(() => {
    setItemsFiltered(mapItems());
  }, []);

  const onChangeText = (
      ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
      text: string | undefined
  ): void => {
    setItemsFiltered(
        text ? mapItems().filter((item) => item?.name.toLowerCase().includes(text.toLowerCase())) : mapItems()
    );
  };

  const onChangeHsFilter = (ev: React.MouseEvent<HTMLElement>, checked: boolean | undefined): void => {
    setFilterByHs(checked);
    setItemsFiltered(checked ? mapItems().filter((i) => (i ? i.horas_totales : '') === 0) : mapItems());
  };

  // const collaboratorListStyles = {
  //   contentWrapper: {
  //     cursor: 'pointer',
  //   }
  // }

  const controlStyles = {
    root: {
      display: 'inline-block',
      margin: '0 30px 20px 0',
      maxWidth: '300px',
    },
  };

  const _onRenderRow: IDetailsListProps['onRenderRow'] = (propsRow) => {
    const customStyles: Partial<IDetailsRowStyles> = {
      cell: { cursor: 'pointer' },
    };
    if (propsRow) {
      if (propsRow.item.horas_totales === 0) {
        // Every other row renders with a different background color
        customStyles.root = { backgroundColor: '#ffbbbb' };
      }

      return (
          <div
              onClick={() => {
                navigate(
                    `${routes.collaborator}/${propsRow.item.id}?showPanel=1&from=${props.from.toFormat(
                        'yyyy-LL-dd'
                    )}&to=${props.to.toFormat('yyyy-LL-dd')}`
                );
              }}
          >
            <DetailsRow {...propsRow} styles={customStyles} />
          </div>
      );
    }
    return null;
  };

  const _getKey = (item: any, index?: number): string => {
    return item.key;
  };

  return (
      <div data-is-scrollable="true">
        {itemsFiltered && (
            <div data-is-scrollable="true">
              <TextField label={t('Filter by name:')} onChange={onChangeText} styles={controlStyles} />
              <Toggle
                  label={t('Filtrar por Horas')}
                  checked={filterByHs}
                  onChange={onChangeHsFilter}
                  onText={t('Solo usuarios sin Hs')}
                  offText={t('Todos')}
                  styles={controlStyles}
              />
              <DetailsList
                  items={itemsFiltered}
                  columns={columns}
                  getKey={_getKey}
                  setKey="set"
                  layoutMode={DetailsListLayoutMode.justified}
                  checkboxVisibility={CheckboxVisibility.hidden}
                  onRenderRow={_onRenderRow}
                  onShouldVirtualize={() => false}
              />
            </div>
        )}
      </div>
  );
};
