import * as React from 'react';
import {
  ComboBox,
  DefaultPalette,
  IComboBoxProps,
  ICommandBarItemProps,
  Icon,
  Label,
  PrimaryButton,
  TextField,
  Text,
} from '@fluentui/react';
import {
  ColumnGroupedDetailsList,
  GroupedDetailList,
} from '../../../Common/GroupedDetailList/GroupedDetailList';
import ViewWrapperWithCommandBar from '../../../Layout/ViewWrapperWithCommandBar';
import { ConfirmDownloadPreSettlement } from '../../../Reporting/ConfirmDownloadPreSettlement';
import { IReportePreliquidacion } from '../../../../system/reporting/ReportingSystem';
import {
  buttonStylesForProjects,
  defaultCommandBarButtonStyles,
  titleCommandBarButtonStyles,
} from '../../../../styles/button-styles';
import ReportByPreliquidationExporter from '../../../Reporting/ReportByPreliquidationExporter';
import { useTranslation } from 'react-i18next';
import TimeTaxSupportSystemClient from '../../../../system';
import { User } from '../../../../system/User';
import MessageCourier from '../../../../lib/MessageCourier';
import { DateTime } from 'luxon';
import { Company } from '../../../../system/company/Company';
import { Collaborator } from '../../../../system/collaborators/Collaborator';
import { formatNumberToLocale } from '../../../../common/list-utils';
// import { CollaboratorsWithNoRemunInPeriod } from './CollaboratorsWithNoRemunInPeriod';
import { DefaultButton, DetailsList, IColumn, SelectionMode } from 'office-ui-fabric-react';
import { DefenseFileBySettlementUploadedDocument } from '../../../../system/defense_file/DefenseFileBySettlementUploadedDocument';
import { IExampleItem } from '@fluentui/example-data';

interface IDocumentTable {
  system: TimeTaxSupportSystemClient;
  courier: MessageCourier;
  changeBlockUI?: (state: boolean) => void;
  getDataFromTableAssistant?: (concept: string) => void;
  user?: User;
  dataTable: DefenseFileBySettlementUploadedDocument[];
  setButtonDisability?: (can: boolean) => void;
  downloadUploadedDocument: (document: DefenseFileBySettlementUploadedDocument) => void;
}

export const DocumentTable = (props: IDocumentTable) => {
  const { t, i18n } = useTranslation();
  const [items, setItems] = React.useState<DefenseFileBySettlementUploadedDocument[]>([]);
  const [itemsFiltered, setItemsFiltered] = React.useState<DefenseFileBySettlementUploadedDocument[]>([]);
  const [showCollabsWithNoRemun, setShowCollabsWithNoRemun] = React.useState<boolean>(false);

  const AllItems = React.useMemo(() => {
    return props.dataTable;
  }, [props.dataTable]);

  const iconProps = { iconName: 'Search' };

  React.useEffect(() => {
    if (props.dataTable) setItemsFiltered(AllItems);
  }, [AllItems]);

  const columns: IColumn[] = React.useMemo(
      () => [
        {
          key: 'id',
          name: t('Id'),
          fieldName: 'id',
          className: 'ta-end',
          minWidth: 50,
          maxWidth: 50,
        },
        {
          key: 'description',
          name: t('Descripción'),
          fieldName: 'description',
          // couldGroup: true,
          minWidth: 350,
          maxWidth: 400,
        },

        {
          key: 'remotePath',
          name: t('Url de acceso'),
          fieldName: 'remotePath',
          // couldGroup: true,
          minWidth: 100,
          maxWidth: 500,
        },

        {
          key: 'buttons',
          fieldName: '',
          name: '',
          minWidth: 50,
          maxWidth: 50,
          // onColumnClick: this._onColumnClick,
          onRender: (item: any) => {
            return <PrimaryButton text={t('Descargar')} onClick={() => props.downloadUploadedDocument(item)} />;
          },
        },
      ],
      [AllItems]
  );

  const [showConfirmDownload, setShowConfirmDownload] = React.useState<boolean>(false);

  const openConfirmDownloadPresettlement = () => {
    setShowConfirmDownload(true);
  };

  const filterItems = (val: string) => {
    let _ret: DefenseFileBySettlementUploadedDocument[] = [];
    _ret = AllItems.filter((item) => item.getDescription().toLowerCase().includes(val.toLowerCase()));
    setItemsFiltered(_ret);
  };

  return (
      <div>
        <div className="padding-1 ms-Grid" dir="ltr">
          <div className="ms-Grid-row">
            <div className="ms-Grid-col  ms-sm6 float-right">
              {/* <Label className="invisible">{t('Descargar')} </Label> */}
              {/* <PrimaryButton
              className="float-right"
              text={t('Descargar')}
              onClick={openConfirmDownloadPresettlement}
            /> */}
            </div>
          </div>

          <div className="ms-Grid-row">
            <div className="ms-Grid-col  ms-sm6 ">
              <TextField
                  iconProps={iconProps}
                  label={t('Buscar')}
                  onChange={(e: any) => {
                    const val = e.target.value;
                    filterItems(val);
                  }}
              ></TextField>
            </div>
          </div>
          <div className="ms-Grid-row">
            <div className="ms-Grid-col ms-sm-12"></div>
            {items && (
                <DetailsList
                    // columns={
                    //   concept === props.COLLABORATOR_KEY && props.collaboratorList
                    //     ? collaboratorColumns
                    //     : companyColumns
                    // }
                    // items={
                    //   concept === props.COLLABORATOR_KEY && props.collaboratorList
                    //     ? props.collaboratorList
                    //     : props.dataTable
                    // }
                    selectionMode={SelectionMode.none}
                    columns={columns}
                    items={itemsFiltered}
                />
            )}
          </div>
        </div>
      </div>
  );
};
