import { useParams } from 'react-router-dom';
import MessageCourier from '../../../lib/MessageCourier';
import TimeTaxSupportSystemClient from '../../../system';
import useAsyncDataCourier from '../../../common/custom-hooks/useAsyncDataCourier';
import { routes } from '../../../common/constants';

import ViewWrapperWithCommandBar from '../../Layout/ViewWrapperWithCommandBar';
import { t } from 'i18next';
import { ICommandBarItemProps } from '@fluentui/react/lib/CommandBar';
import { textBoldStyle } from '../../Common/TextStyles';
import {
  DatePicker,
  DefaultButton,
  DefaultPalette,
  Dropdown,
  IDropdownOption,
  Icon,
  Pivot,
  PivotItem,
  PrimaryButton,
  Text,
} from '@fluentui/react';
import React, { useEffect } from 'react';
import {
  titleCommandBarButtonStyles,
  defaultCommandBarButtonStyles,
  buttonStylesForProjects,
} from '../../../styles/button-styles';
import { ProjectMiniPanelWrapper } from '../ProjectMiniPanel/ProjectMiniPanelWrapper';
import { User } from '../../../system/User';
import ProjectConfigurationDistributionForm from '../ProjectConfiguration/ProjectConfigurationDistributionForm';
import CustomInput from '../../../lib/FormValidator/Form/CustomInput';
import AssertionEntity from '../../../lib/FormValidator/Assertion/AssertionEntity';
import useForceUpdate from '../../../common/custom-hooks/useForceUpdate';
import CustomInputIfc from '../../../lib/FormValidator/Form/CustomInputIfc';
import { Company } from '../../../system/company/Company';
import { ProjectCompanyInDistribution } from '../../../system/projects/IProjectCompanyInDistribution';
import { ProjectCompany } from '../../../system/projects/ProjectCompany';
import InputField from '../../../lib/FormValidator/Form/InputField';
import ComboBoxSearch from '../../Common/ComboBoxSearch';
import { DateCustom } from '../../../lib/DateCustom';
import { getLocalizedStrings } from '../../../lib/LocalizedStringsForDatePicker';
import { DateTime } from 'luxon';
import { ActivitiesToEditByPeriodTable } from './ActivitiesToEditByPeriodTable';
import { StepFinalInfo } from './StepFinalInfo';
import ErrorListForForm from '../../../lib/FormValidator/Form/ErrorListForForm';
import { useCustomInput } from '../../../common/custom-hooks/useCustomInput';
import { useNavigate } from 'react-router-dom';
import { IProjectDescriptor } from '../../../system/projects/ProjectDescriptor';
import PeriodDatesBlockedSystem from '../../../system/period_dates_blocked/PeriodDatesBlockedSystem';
import SystemConfiguration from '../../../system/configuration/SystemConfiguration';

interface IProjectDistributionByPeriodViewer {
  system: TimeTaxSupportSystemClient;
  courier: MessageCourier;
  changeBlockUI: (b: boolean) => void;
  user: User;
  config: SystemConfiguration;
  periodsBlocked: PeriodDatesBlockedSystem;
}

export const ProjectDistributionByPeriodViewer = (props: IProjectDistributionByPeriodViewer) => {
  let { id } = useParams();
  const navigate = useNavigate();

  const [verActividades, setVerActividades] = React.useState<boolean>(false);
  const [fromDate, setFromDate] = React.useState<DateTime>(
      DateTime.fromJSDate(DateCustom.getFirstDayOfThisMonth())
  );
  const [toDate, setToDate] = React.useState<DateTime>(DateTime.fromJSDate(new Date()));

  const onChangeDate = (date: Date | null | undefined, name: string) => {
    if (date) {
      if (name === 'from') {
        setFromDate(DateTime.fromJSDate(date));
      }
      if (name === 'to') {
        setToDate(DateTime.fromJSDate(date));
      }
    }
  };

  const projects = useAsyncDataCourier(() => props.system.getProjects(), [props.system], props.courier);

  const project = useAsyncDataCourier(
      () => (id ? props.system.getProjectSystem().getById(id) : Promise.resolve(undefined)),
      [props.system, id],
      props.courier,
      props.changeBlockUI
  );

  const contextForm = useAsyncDataCourier(
      () => props.system.getProjectSystem().getContext(),
      [props.system],
      props.courier
  );
  const actividadesAEditar = useAsyncDataCourier(
      () =>
          project && verActividades
              ? props.system.getProjectSystem().getActivitiesToEditByPeriod(project.getId(), fromDate, toDate)
              : Promise.resolve([]),
      [props.system, project, fromDate, toDate, verActividades],
      props.courier,
      props.changeBlockUI
  );

  const distributionForm = useCustomInput(() => {
    return ProjectConfigurationDistributionForm.with(
        [],
        new CustomInput('billable_mock', project?.getBillable(), new AssertionEntity())
    );
  }, [project]);

  const distributionItems = useCustomInput(
      () => distributionForm.getInputDistribucionFacturableItems(),
      [distributionForm],
      ['setValue', 'add', 'delete']
  );

  const onClickProject = (project: IProjectDescriptor) => () => {
    navigate(`${routes.view_distribution_by_period}/${project.getId()}`);
  };

  const itemsMenu = React.useMemo((): ICommandBarItemProps[] => {
    let ret: ICommandBarItemProps[] = [];
    ret.push(
        {
          key: 'project',
          text: project?.getCode(),
          itemProps: {},
          iconProps: { iconName: 'Teamwork', color: DefaultPalette.themeLighterAlt },
          subMenuProps: {
            items: projects
                ? projects.map((project) => ({
                  key: project.getCode(),
                  text: project.getCode(),
                  onClick: onClickProject(project),
                }))
                : [],
          },
          buttonStyles: buttonStylesForProjects,
        },
        {
          key: 'title',
          text: t('Vista de distribución facturable de actividades por período'),
          iconProps: {
            iconName: 'TeamWork',
            color: DefaultPalette.themeLighterAlt,
            className: 'text-title-btn',
          },
          buttonStyles: titleCommandBarButtonStyles,
          className: 'text-title-btn',
        },
        {
          key: 'download',
          text: t('Config'),
          onClick: () => navigate(`${routes.project_config}/${project?.getId()}`),
          iconProps: { iconName: 'Settings' },
          buttonStyles: defaultCommandBarButtonStyles,
        }
    );

    return ret;
  }, [project, projects]);

  const forceUpdate = useForceUpdate();

  const companyOptions = React.useMemo(() => {
    return contextForm?.companyList;
  }, [contextForm]);

  const getTotalPercent = () => {
    let percent = 0;
    if (distributionItems.getValue() && distributionForm)
      distributionItems.getValue().map((projectCompany, i) => {
        if (
            projectCompany &&
            distributionItems?.getFormFor(projectCompany)?.getPercentageDistributionInput()?.getValue()
        ) {
          return (percent += parseFloat(
              distributionItems.getFormFor(projectCompany).getPercentageDistributionInput().getValue()
          ));
        }
      });
    return percent;
  };

  useEffect(() => {
    getTotalPercent();
  });
  const setCompanyListOutsideProject = (val?: string) => {
    const ret = companyOptions?.filter((com) => {
      const companyIsInProject = distributionItems.hasCompany(com);

      const sameBillableType =
          project?.getBillable() === com.getAllow_billable_project() ||
          !project?.getBillable() === com.getAllow_no_billable_project();

      return !companyIsInProject && com.tradeNameLike(val) && sameBillableType;
    });
  };

  React.useEffect(() => {
    setCompanyListOutsideProject();
  }, [project?.getProjectCompanyList().getCompanies(), contextForm, id]);

  return (
      <ViewWrapperWithCommandBar
          withBorderBottom
          title={''}
          items={itemsMenu}
          overflowButtonStyles={defaultCommandBarButtonStyles}
          variant="highligth"
          titleBody="Proyectos"
      >
        <div>
          {project && contextForm && (
              <div className="mb-2">
                <div className="ms-Grid" dir="ltr">
                  <ProjectMiniPanelWrapper
                      selectedProject={project}
                      system={props.system}
                      courier={props.courier}
                      setViewControlDocsWrapper={() => {}}
                      user={props.user}
                      actualizateProject={() => {}}
                      changeBlockUI={props.changeBlockUI}
                  />
                </div>
                <div className="ms-Grid-col ms-sm12 mt-2">
                  <div className="ms-Grid bg-white-h100 ms-depth-4" dir="ltr">
                    <div className="ms-Grid-col ms-sm12 ">
                      <div className="ms-Grid-col ms-sm12 mb-2 mt-2">
                        <Text variant="mediumPlus">
                          <Icon className="text-icon" iconName="Info"></Icon>Se buscarán todas las actividades
                          pertenecientes a este proyecto en el periodo seleccionado.
                        </Text>
                      </div>
                      <div className="ms-Grid-row" style={{ display: 'flex' }}>
                        <div className="ms-Grid-col ms-sm2 mb-2">
                          <DatePicker
                              strings={getLocalizedStrings()}
                              label={t('Aplicar desde')}
                              formatDate={DateCustom.formatDate}
                              value={fromDate ? fromDate.toJSDate() : undefined}
                              onSelectDate={(e) => onChangeDate(e, 'from')}
                              className=""
                          />
                        </div>
                        <div className="ms-Grid-col ms-sm2 mb-2">
                          <DatePicker
                              strings={getLocalizedStrings()}
                              label={t('Aplicar hasta')}
                              formatDate={DateCustom.formatDate}
                              value={toDate ? toDate.toJSDate() : undefined}
                              onSelectDate={(e) => onChangeDate(e, 'to')}
                              className=""
                          />
                        </div>

                        <div className="ms-Grid-col ms-sm3 ms-smPush5 mb-2" style={{ alignSelf: 'end' }}>
                          <PrimaryButton
                              style={{ float: 'right', fontWeight: 600 }}
                              onClick={() => setVerActividades(true)}
                              iconProps={{ iconName: 'Search' }}
                          >
                            Buscar
                          </PrimaryButton>
                        </div>
                      </div>
                    </div>
                  </div>
                  {verActividades && actividadesAEditar && actividadesAEditar.length > 0 && (
                      <ActivitiesToEditByPeriodTable
                          system={props.system}
                          actividadesAEditar={actividadesAEditar}
                          courier={props.courier}
                          user={props.user}
                          changeBlockUI={props.changeBlockUI}
                          config={props.config}
                          periodsBlocked={props.periodsBlocked}
                          hideEdit
                          projectId={project.getId()}
                          projects={projects}
                      ></ActivitiesToEditByPeriodTable>
                  )}
                </div>
              </div>
          )}
        </div>
      </ViewWrapperWithCommandBar>
  );
};
