import React from 'react';
import { boldStyle } from './Common/TextStyles';
import { Stack, Text } from '@fluentui/react';
import { useTranslation, Trans } from 'react-i18next';

const NotFound: React.FC = () => {
    const { t, i18n } = useTranslation();
    return (
        <Stack horizontalAlign="center" verticalAlign="center" verticalFill>
            <Text variant="xxLarge" styles={boldStyle}>
                404 Not Found
            </Text>
            <Text variant="large">{t('La página solicitada no fue encontrada.')}</Text>
        </Stack>
    );
};

export default NotFound;
