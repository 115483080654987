export class TaxOffice {
  protected constructor(private id: string, private name: string) {}

  static named(id: string, name: string) {
    return new this(id, name);
  }

  isIdentifiedBy(anId: string): boolean {
    return +this.id === +anId;
  }

  getName(): string {
    return this.name;
  }

  getId(): string {
    return this.id;
  }
}
