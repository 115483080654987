import { downloadExcel } from 'react-export-table-to-excel';
import { OrganizationChartNode } from '../../../system/organization_chart/OrganizationChartNode';
import { ICollaboratorDetailList } from '../../../system/panel/PanelSystem';

class ProjectTableExporter {
  private constructor(
      private comparisonData: ICollaboratorDetailList[],
      private fileName: string,
      private sheetName: string
  ) {}

  static exportFor(
      comparisonData: ICollaboratorDetailList[],
      fileName: string = 'Datos proyectos',
      sheetName: string = 'proyectos'
  ) {
    const exporter = new this(comparisonData, fileName, sheetName);

    exporter.export();
  }

  export() {
    downloadExcel({
      fileName: this.fileName,
      sheet: this.sheetName,
      tablePayload: {
        header: this.getHeader(),
        body: this.getBody(),
      },
    });
  }

  private getBody() {
    return this.comparisonData.map((item) => {

      return [item.pro_name, item.act_total, item.act_confirmed, item.act_declined, item.act_pending, item.hs_confirmed, item.hs_pending, item.doc_total, item.doc_pending];
    });
  }

  private getHeader() {
    return ['Proyecto', 'Actividades totales', 'Actividades confirmadas', 'Actividades declinadas', 'Actividades pendientes', 'Horas confirmadas', 'Horas pendientes', 'Documentos totales', 'Documentos pendientes'];
  }
}

export default ProjectTableExporter;
