import React, { useEffect, useState, useMemo } from 'react';
import { DefaultButton } from '@fluentui/react/lib/Button';
import { DefaultPalette, ICommandBarItemProps, Label, Pivot, PivotItem, Text } from '@fluentui/react';
import Wizard from '../Wizard';
import CompanySelectorAssistant from './CompanySelectorAssistant';
import DocumentationSelector from './DocumentationSelector';
import Ending from './Ending';
import SettlementsSelector from './SettlementsSelector';
import './DefenseFiles.css';
import { titleCommandBarButtonStyles, defaultCommandBarButtonStyles } from '../../../styles/button-styles';
import { useNavigate, useParams } from 'react-router-dom';
import { routes, defenseFileAssistantDropdownValues } from '../../../common/constants';
import ViewWrapperWithCommandBar from '../../Layout/ViewWrapperWithCommandBar';
import { Company } from '../../../system/company/Company';
import { DateTime } from 'luxon';
import { DateCustom } from '../../../lib/DateCustom';
import useAsyncDataCourier from '../../../common/custom-hooks/useAsyncDataCourier';
import { IDefenseFileTaxOfficeRequirement } from '../../../system/defense_file/DefenseFileTaxOfficeRequirement';
import { DefenseFileRequirement } from '../../../system/defense_file/DefenseFileSystem';
import useSystemCall from '../../../common/custom-hooks/useSystemCall';
import system from '../../../system';
import { useTranslation, Trans } from 'react-i18next';

const DefenseFileContainer = (props: any) => {
  const { t, i18n } = useTranslation();
  const [company, setCompany] = useState<Company | undefined>(undefined);
  const [type, setType] = useState<string>('');
  const [fromDate, setFromDate] = useState<DateTime>(DateTime.fromJSDate(DateCustom.getFechaOneMonthAgo()));
  const [toDate, setToDate] = useState<DateTime>(DateTime.fromJSDate(new Date()));

  const [requirementType, setRequirementType] = useState<string>('');
  const [requirementData, setRequirementData] = useState<DefenseFileRequirement>();

  const [tableData, setTableData] = useState<any>();
  const [selectedProjectsId, setSelectedProjectsId] = useState<Array<string>>(['']);
  const [activityReport, setActivityReport] = useState<boolean>(false);

  const [fileResponse, setFileResponse] = useState<any>();

  const [canGoNext, setCanGoNext] = useState(false);

  const navigate = useNavigate();

  const forProjects = defenseFileAssistantDropdownValues.forProjects;
  const forIndirectsSettlements = defenseFileAssistantDropdownValues.forIndirectsSettlements;

  const setButtonDisability = (can: boolean) => {
    setCanGoNext(can);
  };

  const CompanySelectorData = {
    company: company,
    type: type,
    fromDate: fromDate,
    toDate: toDate,
  };

  const dataForEnding = {
    fromDate: fromDate,
    toDate: toDate,
    company: company,
    activityReport: activityReport,
    selectedProjectsId: selectedProjectsId,
    requirementData: requirementData,
  };

  const selectedProjectSummary = useAsyncDataCourier(
      () =>
          company
              ? props.system.getProjectSystem().getListForDefenseFile(company?.getId(), fromDate, toDate)
              : Promise.resolve(undefined),
      [props.system, company, fromDate, toDate],
      props.courier,
      props.changeBlockUI
  );

  const selectedSettlementsSummary = useAsyncDataCourier(
      () =>
          company
              ? props.system.getSettlementSystem().getListForDefenseFile(company?.getId(), fromDate, toDate)
              : Promise.resolve(undefined),
      [props.system, company, fromDate, toDate],
      props.courier,
      props.changeBlockUI
  );

  const itemsMenu = React.useMemo(
      (): ICommandBarItemProps[] => [
        {
          key: 'title',
          text: t('Archivo de defensa'),
          iconProps: {
            iconName: 'Shield',
            color: DefaultPalette.themeLighterAlt,
            className: 'text-title-btn',
          },
          buttonStyles: titleCommandBarButtonStyles,
          className: 'text-title-btn',
        },
        {
          key: 'back',
          text: t('Volver'),
          onClick: () => navigate(`${routes.defense_file}`),
          iconProps: { iconName: 'Back' },
          buttonStyles: defaultCommandBarButtonStyles,
        },
      ],
      []
  );

  // Este metodo se va a llamar cuando esten disponibles company, type, fromDate, toDate
  const getDataFromCompanySelectorAssistant = (
      company: Company,
      type: string,
      fromDate: DateTime,
      toDate: DateTime
  ) => {
    setCompany(company);
    setType(type);
    setFromDate(fromDate);
    setToDate(toDate);
  };

  const getDataFromDocumentationSelector = (
      requirementType: string,
      requirementData: DefenseFileRequirement
  ) => {
    setRequirementType(requirementType);
    setRequirementData(requirementData);
  };

  const getIdsFromTable = (list: Array<string>, activityReport: boolean) => {
    setSelectedProjectsId(list);
    setActivityReport(activityReport);
  };

  useEffect(() => {
    if (company && fromDate && toDate && type == forProjects) {
      setTableData(selectedProjectSummary);
    } else if (company && fromDate && toDate && type == forIndirectsSettlements) {
      setTableData(selectedSettlementsSummary);
    }
  }, [company, type, fromDate, toDate, selectedSettlementsSummary, selectedProjectSummary]);

  let Selectors = [
    <CompanySelectorAssistant
        getDataFromCompanySelectorAssistant={getDataFromCompanySelectorAssistant}
        system={props.system}
        courier={props.courier}
        setButtonDisability={setButtonDisability}
        CompanySelectorData={CompanySelectorData}
    />,
    <SettlementsSelector
        system={props.system}
        courier={props.courier}
        setButtonDisability={setButtonDisability}
        tableData={tableData?.data}
        type={type}
        getIdsFromTable={getIdsFromTable}
    />,
    <DocumentationSelector
        system={props.system}
        courier={props.courier}
        setButtonDisability={setButtonDisability}
        getDataFromDocumentationSelector={getDataFromDocumentationSelector}
    />,
    <Ending
        system={props.system}
        courier={props.courier}
        setButtonDisability={setButtonDisability}
        fileReponse={fileResponse}
        type={type}
        data={dataForEnding}
    />,
  ];
  let Titles = [
    t('1. Empresa / período / tipo de defensa'),
    t('2. Selección'),
    t('3. Documentación del requerimiento'),
    t('4. Finalización'),
  ];

  let Descriptions = [
    t('Selecciona la empresa usuaria a la que se ha liquidado, el período y el tipo de defensa'),
    t('Selecciona las liquidaciones o proyectos que sustentarán la defensa'),
    t('Documenta el motivo y/o los datos del requerimiento'),
    '',
  ];

  return (
      <ViewWrapperWithCommandBar
          withBorderBottom
          title={''}
          items={itemsMenu}
          overflowButtonStyles={defaultCommandBarButtonStyles}
          variant="highligth"
          titleBody={t('Proyectos')}
      >
        <div className="padding-1">
          <div className="ms-Grid bg-white-h100" dir="ltr">
            <div className="ms-Grid-row">
              <div className="ms-Grid-col ms-sm12 mt-2 ml-1 defense-file-container-desc">
                <Text className="ml-10px defense-file-title">
                  {t('Asistente para la generación del archivo de defensa ante un requerimiento fiscal')}
                </Text>
              </div>
            </div>
            <div className="ms-Grid-row">
              <div className="ms-Grid-col ms-sm12">
                <Wizard
                    componentsTitlesArray={Titles}
                    componentsDescriptions={Descriptions}
                    componentsArray={Selectors}
                    canGoNext={canGoNext}
                />
              </div>
            </div>
          </div>
        </div>
      </ViewWrapperWithCommandBar>
  );
};

export default DefenseFileContainer;
