import { ProjectsSystem } from '../projects/ProjectsSystem';
import { SystemDocument } from './SystemDocument';
import { ProjectRelatedDocument } from './ProjectRelatedDocument';
import Project from '../projects/Project';

export class ProjectDocument extends ProjectRelatedDocument {
  private id: string;
  private required: boolean;

  protected constructor(system: ProjectsSystem, document: SystemDocument, project:Project, id: string, required: boolean) {
    super(system, document, project);
    this.id = id;
    this.required = required;
  }

  /**
   * @TODO obligatorio
   */
  static fromIStoredProjectDocument(system: ProjectsSystem, systemDocument: SystemDocument, project: Project) {
    return new this(system, systemDocument, project, systemDocument.getId(), false);
  }

  getId() {
    return this.id;
  }

  canDeactivate(): boolean {
    return true;
  }

  isRequired() {
    return this.required;
  }

  hasControlReference() {
    return this.document.hasControlReference();
  }

  getControlReference() {
    return this.document.getControlReference();
  }
}
