import React from 'react';
import { Text, Icon, Dropdown } from '@fluentui/react';
import { DateTime } from 'luxon';
import useAsyncDataCourier from '../../common/custom-hooks/useAsyncDataCourier';
import { User } from '../../system/User';
import { Collaborator } from '../../system/collaborators/Collaborator';
import { Dialog, IDropdownOption, PrimaryButton, TextField } from 'office-ui-fabric-react';
import useSystemCall from '../../common/custom-hooks/useSystemCall';
import CollaboratorSelectorList from '../Collaborator/CollaboratorSelectorList';
import { styles } from '../Calendar/CalendarStyles';
import { DateSelector } from '../Common/DateSelector';
import { DateCustom } from '../../lib/DateCustom';
import DialogTemplate from '../Common/DialogTemplate';
import { ActivityFromExcelFormToPost } from './MassiveActivitiesUploadAssistant';
import Project from '../../system/projects/Project';
import { IProjectDescriptor } from '../../system/projects/ProjectDescriptor';
import { useTranslation, Trans } from 'react-i18next';

interface IEditActivityAssistantDialog {
  showDialog: boolean;
  toggleShowDialog: () => void;
  closeDialog: () => void;
  onEdit: () => void;
  projects: IProjectDescriptor[];
  activitySelected: ActivityFromExcelFormToPost;
  updateActivitySelected: (activitySelected: ActivityFromExcelFormToPost) => void;
  onSaveStartSavedInstance: (dateStr: string) => void;
  onSaveEndSavedInstance: (dateStr: string) => void;
}

export const EditActivityAssistantDialog = (props: IEditActivityAssistantDialog) => {
  const { t, i18n } = useTranslation();
  const onChangeName = (e: any) => {
    if (props.activitySelected) {
      let _actSelected = { ...props.activitySelected };
      _actSelected.impai_nombre = e.target.value;
      props.updateActivitySelected(_actSelected);
    }
  };

  const onChangeProject = (id: string, code: string) => {
    if (props.activitySelected) {
      let _actSelected = { ...props.activitySelected };
      _actSelected.impai_proid = id;
      _actSelected.impai_codigo_proyecto = code;
      props.updateActivitySelected(_actSelected);
    }
  };

  const onChangeDate = (date: any, name: string) => {
    if (props.activitySelected) {
      let _actSelected = { ...props.activitySelected };

      if (name === 'start') {
        _actSelected.impai_inicio = DateTime.fromJSDate(date).toFormat('dd/LL/yyyy HH:mm');
        props.onSaveStartSavedInstance(DateTime.fromJSDate(date).toFormat('dd/LL/yyyy HH:mm'));
      }
      if (name === 'end') {
        _actSelected.impai_fin = DateTime.fromJSDate(date).toFormat('dd/LL/yyyy HH:mm');
        props.onSaveEndSavedInstance(DateTime.fromJSDate(date).toFormat('dd/LL/yyyy HH:mm'));
      }
      props.updateActivitySelected(_actSelected);
    }
  };

  const optionProjects = React.useMemo((): IDropdownOption[] => {
    let options: IDropdownOption[] = [];

    props.projects.forEach((project) => {
      options.push({ key: project.getId(), text: project.getTitleProject(), data: project.getCode() });
    });

    return options;
  }, []);

  const getDefaultSelectedProject = (): string[] => {
    let ret: string[] = [];
    let _project = props.projects.find((project) => project.getId() === props.activitySelected.impai_proid);
    if (_project) {
      ret.push(_project.getId());
    }
    return ret;
  };

  const getDefaultHours = () => {
    let ini = DateTime.fromFormat(props.activitySelected.impai_inicio, 'dd/LL/yyyy HH:mm');
    let fin = DateTime.fromFormat(props.activitySelected.impai_fin, 'dd/LL/yyyy HH:mm');

    let dif = fin.diff(ini);

    const diferencia = fin.diff(ini, ['hours', 'minutes']);
    const diferenciaHoras = diferencia.as('hours');

    return diferenciaHoras;
  };

  return (
      <DialogTemplate
          showDialog={props.showDialog}
          toggleShowDialog={props.toggleShowDialog}
          title={props.activitySelected.impai_nombre}
          onAccept={props.onEdit}
          onCancel={props.closeDialog}
          acceptText={t('Editar')}
          cancelText={t('Cancelar')}
          minWidth={550}
      >
        <div className="ms-Grid" dir="ltr">
          <div className="ms-Grid-row">
            <div className="ms-Grid-col ms-sm12">
              <Text block variant="xLarge" className={styles.title}>
                <Icon iconName="AccountActivity" className="text-icon" />
                <TextField
                    className={styles.input}
                    placeholder={t('Nombre de la actividad')}
                    value={props.activitySelected.impai_nombre ? props.activitySelected.impai_nombre : ''}
                    name="name"
                    onChange={onChangeName}
                    underlined
                />
              </Text>
            </div>
          </div>

          <div className="ms-Grid-row ">
            <div className="ms-Grid-col ms-sm12">
              <Dropdown
                  defaultSelectedKey={getDefaultSelectedProject()}
                  label={t('Proyecto')}
                  onChange={(e, option) =>
                      option ? onChangeProject(String(option.key), String(option.data)) : null
                  }
                  options={optionProjects}
              />
            </div>
          </div>

          <div className="ms-Grid-row ">
            <div className="ms-Grid-col ms-sm12">
              <DateSelector
                  defaultStartDate={DateTime.fromFormat(
                      props.activitySelected.impai_inicio,
                      'dd/LL/yyyy HH:mm'
                  ).toJSDate()}
                  defaultDuration={getDefaultHours()}
                  onChangeStart={(valueStart) => {
                    onChangeDate(valueStart, 'start');
                  }}
                  onChangeEnd={(valueEnd: any) => {
                    onChangeDate(valueEnd, 'end');
                  }}
              />
            </div>
          </div>
        </div>
      </DialogTemplate>
  );
};
