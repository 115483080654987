import React from 'react';
import { Stack } from '@fluentui/react/lib/Stack';
import { Text } from '@fluentui/react/lib/Text';
import { Icon } from '@fluentui/react/lib/Icon';
import { DefaultButton, PrimaryButton } from '@fluentui/react/lib/Button';

import './DocumentView.css';
import BasicView from '../Layout/BasicView';
import { themeLight } from '../../styles/colors';
import CollaboratorPersona from '../Collaborator/CollaboratorPersona';
import { ProjectRelatedDocument } from '../../system/documents/ProjectRelatedDocument';
import TimeTaxSupportSystemClient, { ProjectFolder } from '../../system';
import { DocumentRestoreDialog } from './DocumentRestoreDialog';
import { DocumentContentTypeIcon } from './DocumentContentTypeIcon';
import { SizeInBytesLabel } from './SizeInBytesLabel';
import MessageCourier from '../../lib/MessageCourier';
import useAsyncDataCourier from '../../common/custom-hooks/useAsyncDataCourier';
import DocumentTypeSelector from '../Document/DocumentTypeSelector';
import SystemDocumentType from '../../system/documents/SystemDocumentType';
import { Link, TextField, Toggle, DatePicker } from '@fluentui/react';
import { IContextualMenuItem, IContextualMenuProps } from '@fluentui/react/lib/ContextualMenu';
import { useConst } from '@fluentui/react-hooks';
import DocumentControlDialog from './DocumentControlDialog';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../common/constants';
import { User } from '../../system/User';
import { UnassignDocumentControlDialog } from './UnassignDocumentControlDialog';
import { DocumentUploadControlSaved } from '../../system/documents/DocumentUploadControl';
import DocumentUploadControlPendingSaved from '../../system/documents/DocumentUploadControlSaved';
import { Collaborator } from '../../system/collaborators/Collaborator';
import { useId } from '@fluentui/react-hooks';
import { getLocalizedStrings } from '../../lib/LocalizedStringsForDatePicker';
import { DateCustom } from '../../lib/DateCustom';

import {
  mergeStyleSets,
  FocusTrapCallout,
  FocusZone,
  FocusZoneTabbableElements,
  FontWeights,
} from '@fluentui/react';
import { useTranslation } from 'react-i18next';

interface IDocumentViewProps {
  document: ProjectRelatedDocument;
  courier: MessageCourier;
  system: TimeTaxSupportSystemClient;
  notifyChange: () => void;
  user: User;
  controlDocument?: DocumentUploadControlSaved;
  backToControlDocsSection: () => void;
}

const DocumentView: React.FC<IDocumentViewProps> = ({ document, ...props }) => {
  const { t, i18n } = useTranslation();
  const [restoreDocumentDialogOpen, setRestoreDocumentDialogOpen] = React.useState(false);
  const [edit, setEdit] = React.useState(false);
  const [showControlDocumentDialog, setShowControlDocumentDialog] = React.useState(false);
  const [name, setName] = React.useState(document.getName());
  const [refersToPeriod, setRefersToPeriod] = React.useState<Date>(new Date());
  const [documentType, setDocumentType] = React.useState<SystemDocumentType>();
  const [isConfidential, setIsConfidential] = React.useState(false);
  const [shouldRefresh, setShouldRefresh] = React.useState(false);

  const isDeleted = document.isDeleted();
  const isActive = document.isActive();
  const isInTrash = document.isInTrash();

  const type = useAsyncDataCourier(() => document.getType(), [document, shouldRefresh], props.courier);
  const typeDescription = useAsyncDataCourier(
      () => document.getTypeDescription(),
      [document, shouldRefresh],
      props.courier
  );
  const uploadedBy = useAsyncDataCourier(
      () => document.getUploadedBy(),
      [document, shouldRefresh],
      props.courier
  );

  const openDocumentControl = () => {
    setShowControlDocumentDialog(true);
  };

  React.useEffect(() => {
    if (type) setDocumentType(type);
  }, [type]);

  React.useEffect(() => {
    setName(document.getName());
  }, [document]);

  React.useEffect(() => {
    const _d = document.getRefersToPeriod().toJSDate();
    setRefersToPeriod(_d);
  }, [document]);

  React.useEffect(() => {
    setCalloutVisible(false);
  }, [document]);

  React.useEffect(() => {
    setIsConfidential(document.isConfidentialDocument());
  }, [document]);

  React.useEffect(() => {
    setIsConfidential(document.isConfidentialDocument());
  }, [document]);

  React.useEffect(() => {
    setEdit(false);
    setIsConfidential(document.isConfidentialDocument());
  }, [document]);

  React.useEffect(() => {
    if (edit) {
      if (document.isConfidentialDocument() && !hasAccessToDocument()) {
        setEdit(false);
        setCalloutVisible(true);
      }
    }
    setIsConfidential(document.isConfidentialDocument());
  }, [edit]);

  React.useEffect(() => {
    if (document.isConfidentialDocument()) {
      getAdmissibleDownloadUsers();
    } else {
      setAdmissibleAccessCollaborators([]);
    }
  }, [document]);

  const [admissibleAccessCollaborators, setAdmissibleAccessCollaborators] = React.useState<Collaborator[]>(
      []
  );

  const getAdmissibleDownloadUsers = async () => {
    let cols = await props.system
        .getDocumentSystem()
        .getAdmissibleAccessCollaborators(document.getDocument().getId());
    setAdmissibleAccessCollaborators(cols);
  };

  const onDeactivateClick = async () => {
    props.system
        .getDocumentSystem()
        .deactivateProjectDocument(document)
        .catch(props.courier.messageError)
        .finally(props.notifyChange);
  };

  const onPermanentlyDelete = async () => {
    props.system
        .getDocumentSystem()
        .permanentlyDelete(document)
        .catch(props.courier.messageError)
        .finally(props.notifyChange);
  };

  const onRestore = async () => {
    // await document.restoreFromTrash();
    // props.notifyChange();
    setRestoreDocumentDialogOpen(true);
  };

  const onDownload = async () => {
    try {
      const downloadUrl = await props.system.getDocumentSystem().downloadUrlOf(document);
      window.open(downloadUrl, '_blank');
    } catch (e) {
      props.courier.messageError(e);
    }
  };

  const onRestoreInto = (folder: ProjectFolder): void => {
    folder.moveInto([document]).catch(props.courier.messageError).finally(props.notifyChange);
  };

  const onSave = () => {
    onSaveChangeType(documentType!);
  };

  const onSaveChangeType = async (_documentType: SystemDocumentType) => {
    await props.system
        .getDocumentSystem()
        .save(document.getDocument(), name, _documentType, isConfidential, refersToPeriod)
        .finally(props.notifyChange);
  };

  const getItemsOptions = () => {
    let ret: IContextualMenuItem[] = [];
    if (isActive) {
      ret.push({
        key: 'edit',
        onClick: () => setEdit((p) => !p),
        iconProps: { iconName: 'Edit' },
        text: t('Editar'),
      });
    }
    // ret.push({
    //   key: 'preview',
    //   onClick: () => {
    //     console.error('Todavia no definido');
    //   },
    //   iconProps: { iconName: 'RedEye' },
    //   text: t('Prevista'),
    // });
    return ret;
  };

  const menuProps: IContextualMenuProps = useConst({
    shouldFocusOnMount: true,
    items: getItemsOptions(),
  });

  const documentControlList = useAsyncDataCourier(
      () =>
          document.getProject()
              ? props.system.getProjectSystem().getDocumentControlList(document.getProject())
              : Promise.resolve(undefined),
      [props.system, document],
      props.courier
  );

  const onSaveDocumentForDocumentControl = async (docControlId: string) => {
    try {
      setShowControlDocumentDialog(false);
      await props.system
          .getProjectSystem()
          .assignDocumentForDocumentControl(docControlId, document.getDocument().getId());
      props.backToControlDocsSection();

      setShouldRefresh(!shouldRefresh);

      props.courier.messageSuccess(
          t('El documento fue actualizado correctamente. Ahora el documento ya es obligatorio')
      );
    } catch (error) {
      props.courier.messageError(error);
    }
    props.notifyChange();
  };

  const [showUnassignDocumentControlDialog, setShowUnassignDocumentControlDialog] =
      React.useState<boolean>(false);

  const onUnassignDocumentClick = () => {
    setShowUnassignDocumentControlDialog(true);
  };

  const onConfirmUnassignDocument = async () => {
    setShowUnassignDocumentControlDialog(false);
    try {
      let docControl = document.getControlReference();
      if (docControl !== undefined) {
        await props.system.getProjectSystem().unassignDocumentForDocumentControl(docControl.getId());
        setShouldRefresh(!shouldRefresh);
      }
      props.courier.messageSuccess(
          t('El documento fue actualizado correctamente. Ahora el documento ya no es obligatorio')
      );
    } catch (error) {
      props.courier.messageError(error);
    }
    props.notifyChange();
  };

  const onChangeTypeForDocumentControlSelected = async (newDocumentType: SystemDocumentType) => {
    setDocumentType(newDocumentType);
    await onSaveChangeType(newDocumentType);
  };

  const navigate = useNavigate();

  const goToConfigProject = () => {
    navigate(`${routes.project_config}/${document.getProject().getId()}`);
  };

  const toggleShowUnassignDocumentControlDialog = () => {
    setShowUnassignDocumentControlDialog(!showUnassignDocumentControlDialog);
  };

  const hasAccessToDocument = () => {
    if (!document.isConfidentialDocument()) return true;

    let hasAccess = admissibleAccessCollaborators.find((col) =>
        col.isIdentifiedBy(props.user.getCollaboratorId())
    );
    return !!hasAccess || props.user.hasPowerUserPrivileges();
  };

  const [isCalloutVisible, setCalloutVisible] = React.useState<boolean>(false);
  const iconId = useId('callout-icon');

  return (
      <BasicView title={document.getName()} useMenu={true} menuProps={menuProps}>
        {restoreDocumentDialogOpen && (
            <DocumentRestoreDialog
                system={props.system}
                document={document}
                onCancel={() => setRestoreDocumentDialogOpen(false)}
                onRestoreInto={onRestoreInto}
            />
        )}

        <Stack>
          {edit ? (
              <>
                <TextField
                    placeholder={t('Nombre')}
                    value={name}
                    onChange={(e, newValue) => setName(newValue || '')}
                    underlined
                    style={{ fontSize: '15px' }}
                />
                <DocumentTypeSelector
                    system={props.system}
                    courier={props.courier}
                    default={type}
                    onChangeSelected={(newDocumentType) => {
                      setDocumentType(newDocumentType);
                    }}
                />
                <div className="mt-2-imp">
                  <Toggle
                      onText={t('Es confidencial')}
                      offText={t('No es confidencial')}
                      onChange={() => setIsConfidential(!isConfidential)}
                      checked={isConfidential}
                  ></Toggle>
                </div>
                <div className="">
                  <DatePicker
                      strings={getLocalizedStrings()}
                      label={'Refiere al periodo'}
                      onSelectDate={(e) => {
                        if (e) {
                          e.setDate(1);
                          setRefersToPeriod(e);
                        }
                      }}
                      formatDate={DateCustom.formatDateMMYYYY}
                      calendarProps={{ isDayPickerVisible: false }}
                      value={refersToPeriod}
                  />
                </div>
              </>
          ) : (
              <Stack horizontal>
                <div className="text-wrapper">
                  <Icon iconName="TextDocument" className="text-icon" />
                  <Text>{typeDescription}</Text>
                </div>
              </Stack>
          )}
          <Stack horizontal>
            <div className="text-wrapper">
              <DocumentContentTypeIcon name={document.getName()} />
              <Text styles={{ root: { marginLeft: '5px' } }}>
                <SizeInBytesLabel size={document.getSizeInBytes()} />{' '}
              </Text>
            </div>
          </Stack>
          <Stack horizontal>
            <div className="text-wrapper">
              <Icon
                  iconName="CheckMark"
                  className="text-icon"
                  styles={{ root: { color: themeLight.success } }}
              />
              <Text styles={{ root: { color: themeLight.secondaryText } }}>
                {t('Cargado el ') + document.getUploadedDate()}
              </Text>
            </div>
          </Stack>

          <Stack horizontal>
            <div className="text-wrapper">
              <Icon iconName="Calendar" className="text-icon" />
              <Text styles={{ root: { color: themeLight.secondaryText } }}>
                {t('Refiere al periodo ') + document.getRefersToPeriod().toFormat('LL/yyyy')}
              </Text>
            </div>
          </Stack>

          {/* @TODO usar un visitor para no chequear tipos */}
          <Stack horizontal>
            <div className="text-wrapper">
              {document.getControlReference() && (
                  <Icon
                      iconName="ProtectedDocument"
                      className="text-icon"
                      styles={{ root: { color: themeLight.warning } }}
                  />
              )}
              <Text styles={{ root: { color: themeLight.secondaryText } }}>
                {document.hasControlReference() ? t('Es obligatorio') : t('No es obligatorio')}
              </Text>
            </div>
          </Stack>

          {document.isConfidentialDocument() && (
              <Stack horizontal>
                <div className="text-wrapper">
                  <Icon
                      id={iconId}
                      onClick={() => setCalloutVisible(!isCalloutVisible)}
                      iconName="Lock"
                      className="text-icon clickable"
                      styles={{ root: { color: themeLight.warning } }}
                  />
                  <Link className="" underline onClick={() => setCalloutVisible(!isCalloutVisible)}>
                    {t('Es confidencial')}
                  </Link>
                </div>
              </Stack>
          )}

          {isDeleted && (
              <Stack horizontal>
                <div className="text-wrapper">
                  <Icon iconName="Delete" className="text-icon" styles={{ root: { color: themeLight.error } }} />
                  <Text styles={{ root: { color: themeLight.secondaryText } }}>
                    {t('Eliminado el ') + document.getDeletedOn()}
                  </Text>
                </div>
              </Stack>
          )}

          {uploadedBy && (
              <CollaboratorPersona
                  collaborator={uploadedBy}
                  titlePrefix={t('Cargado por')}
                  className="uploaded-by-persona"
              />
          )}

          <Stack horizontal>
            <div className="button-wrapper">
              {isActive && !edit && (
                  <React.Fragment>
                    {props.controlDocument instanceof DocumentUploadControlPendingSaved && (
                        <React.Fragment>
                          <PrimaryButton
                              text={t('Asignar')}
                              onClick={openDocumentControl}
                              disabled={document.hasControlReference()}
                              iconProps={{ iconName: 'TextDocument' }}
                              className="primary-button"
                          />
                        </React.Fragment>
                    )}

                    {!props.controlDocument && (
                        <React.Fragment>
                          {!document.hasControlReference() && (
                              <Link className="mb-2 d-block" underline onClick={openDocumentControl}>
                                {t('¿Es un documento obligatorio?')}
                              </Link>
                          )}

                          <PrimaryButton
                              text={t('Descargar')}
                              onClick={onDownload}
                              disabled={!hasAccessToDocument()}
                              iconProps={{ iconName: 'DownloadDocument' }}
                              className="primary-button"
                              title={
                                !hasAccessToDocument() ? t('No tienes permisos para descargar este archivo') : ''
                              }
                          />
                          {props.user.hasPowerUserPrivileges() && document.hasControlReference() && (
                              <DefaultButton
                                  text={t('Desasignar')}
                                  className="mr-1rem"
                                  onClick={onUnassignDocumentClick}
                              />
                          )}
                          {document.canDeactivate() && (
                              <DefaultButton
                                  text={t('Eliminar')}
                                  onClick={onDeactivateClick}
                                  disabled={document.hasControlReference() || !hasAccessToDocument()}
                                  title={
                                    document.hasControlReference()
                                        ? t('El documento no puede ser eliminado porque es un documento obligatorio')
                                        : ''
                                  }
                                  iconProps={{ iconName: 'Delete' }}
                              />
                          )}
                        </React.Fragment>
                    )}
                  </React.Fragment>
              )}
              {isActive && edit && (
                  <React.Fragment>
                    <PrimaryButton
                        text={t('Guardar')}
                        onClick={onSave}
                        iconProps={{ iconName: 'Save' }}
                        className="primary-button"
                        disabled={!name || !documentType}
                    />
                    <DefaultButton
                        text={t('Cancelar')}
                        onClick={() => setEdit((p) => !p)}
                        // iconProps={{ iconName: 'Delete' }}
                    />
                  </React.Fragment>
              )}
              {isInTrash && (
                  <React.Fragment>
                    <PrimaryButton
                        text={t('Recuperar')}
                        onClick={onRestore}
                        iconProps={{ iconName: 'RemoveFromTrash' }}
                        className="primary-button"
                    />
                    <DefaultButton
                        text={t('Eliminar de la papelera')}
                        onClick={onPermanentlyDelete}
                        disabled={document.hasControlReference() || !hasAccessToDocument()}
                        iconProps={{ iconName: 'Delete' }}
                    />
                  </React.Fragment>
              )}
            </div>
          </Stack>
        </Stack>
        {documentControlList && type && (
            <DocumentControlDialog
                showDialog={showControlDocumentDialog}
                goToConfigProject={goToConfigProject}
                toggleShowDialog={() => setShowControlDocumentDialog(!showControlDocumentDialog)}
                documentsControlList={documentControlList}
                system={props.system}
                courier={props.courier}
                saveDocumentForDocumentControl={onSaveDocumentForDocumentControl}
                documentSelectedName={name}
                documentSelectedType={type}
                onChangeTypeForDocumentControlSelected={onChangeTypeForDocumentControlSelected}
                controlDocument={props.controlDocument}
                backToControlDocsSection={props.backToControlDocsSection}
            ></DocumentControlDialog>
        )}

        {name && (
            <UnassignDocumentControlDialog
                toggleShowUnassignDocumentControlDialog={toggleShowUnassignDocumentControlDialog}
                onConfirmUnassignDocument={onConfirmUnassignDocument}
                documentName={name}
                show={showUnassignDocumentControlDialog}
            ></UnassignDocumentControlDialog>
        )}

        {isCalloutVisible ? (
            <FocusTrapCallout
                role="alertdialog"
                className={styles.callout}
                gapSpace={0}
                target={`#${iconId}`}
                onDismiss={() => setCalloutVisible(!isCalloutVisible)}
                setInitialFocus
            >
              <Text block variant="xLarge" className={styles.title}>
                {t('Pueden ver o editar el documento:')}
              </Text>

              {admissibleAccessCollaborators.map((col) => {
                return (
                    <div className="mt-1">
                      <CollaboratorPersona collaborator={col}></CollaboratorPersona>
                    </div>
                );
              })}

              <FocusZone handleTabKey={FocusZoneTabbableElements.all} isCircularNavigation>
                <Stack className={styles.buttons} gap={8} horizontal>
                  <PrimaryButton onClick={() => setCalloutVisible(!isCalloutVisible)}>
                    {t('Entendido')}
                  </PrimaryButton>
                </Stack>
              </FocusZone>
            </FocusTrapCallout>
        ) : null}
      </BasicView>
  );
};

const styles = mergeStyleSets({
  callout: {
    width: 350,
    padding: '20px 24px',
  },
  title: {
    marginBottom: 12,
    fontWeight: FontWeights.semilight,
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 20,
  },
});

export default DocumentView;
