import React from 'react';
import useAsyncData from '../../common/custom-hooks/useAsyncData';
import Project from '../../system/projects/Project';
import { DefaultPalette, Facepile, Icon, IFacepilePersona, Persona, PersonaSize } from '@fluentui/react';
import CollaboratorTeam from '../../system/collaborators/collaborator_team/CollaboratorTeam';

interface IProjectTeamFacepile {
  project: Project;
}
export const ProjectTeamFacepile = (props: IProjectTeamFacepile) => {
  const project_team = useAsyncData(async () => {
    if (props.project) return props.project.getProjectTeam();
    else {
      return Promise.resolve(undefined);
    }
  }, [props.project]);

  const equipoPersonas: IFacepilePersona[] = React.useMemo(() => {
    let ret: IFacepilePersona[] = [];
    if (project_team) {
      if (project_team) {
        ret = project_team.getCollaboratorTeamList().map((_colTeam: CollaboratorTeam) => {
          return {
            personaName: _colTeam.getCollaborator().fullName(),
          };
        });
      }
    }
    return ret;
  }, [project_team]);

  return (
      <>
        <Icon iconName="Group" className={'text-icon'} />

        <Facepile
            personas={equipoPersonas}
            className={'d-inline-table'}
            personaSize={10}
            maxDisplayablePersonas={6}
            overflowButtonProps={{ ariaLabel: 'Ver más' }}
            overflowButtonType={1}
            onRenderPersona={(props) => (
                <Persona {...props} size={PersonaSize.size24} onRenderPrimaryText={() => <></>}></Persona>
            )}
        />
      </>
  );
};
