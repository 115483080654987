import React from 'react';
import { Link } from '@fluentui/react/lib/Link';
import { ProgressIndicator } from '@fluentui/react/lib/ProgressIndicator';
import { Icon, Text } from '@fluentui/react';
import { SizeInBytesLabel } from '../Proyectos/SizeInBytesLabel';
import { t } from 'i18next';
import { MessageBar, MessageBarType } from 'office-ui-fabric-react/lib/MessageBar';
import { MessageBarButton } from 'office-ui-fabric-react/lib/Button';

interface IFileUploadInfoProps {
  filesData: IFileTrackData[];
  filesWithErrors: File[];
  fileWithUploadProblem?: File;
  preparingUpload?: boolean;
  onCancelUpload?: () => void;
  onDismiss?: () => void;
}

export interface IFileTrackData {
  file: File;
  loaded: number;
  total: number;
  timeoutId?: any;
}

export class FileUploadInfo extends React.Component<IFileUploadInfoProps, { viewDetails: boolean }> {
  constructor(props: IFileUploadInfoProps) {
    super(props);
    this.state = { viewDetails: false };
    this.onDismiss = this.onDismiss.bind(this);
    this.onCancelUpload = this.onCancelUpload.bind(this);
  }

  render() {
    const { filesData, filesWithErrors, fileWithUploadProblem, preparingUpload } = this.props;
    if (preparingUpload) {
      return (
          <div className="file-upload-info ms-Grid" dir="ltr">
            <div className="ms-Grid-row">
              <div className="ms-Grid-col ms-sm11">{t('Preparando la carga de documentos...')}</div>
            </div>
          </div>
      );
    }
    if (filesData.length === 0) return null;

    const { viewDetails } = this.state;
    const uploadedFiles = this.uploadedFiles(filesData, filesWithErrors);
    const totalSize = this.totalBytesToUpload(filesData);
    const totalUploaded = this.bytesUploaded(filesData);
    const percentUploaded = totalSize > 0 ? totalUploaded / totalSize : 0;

    const hasErrors = filesWithErrors.length > 0;
    const filesUploading = filesData.filter((track) => !filesWithErrors.includes(track.file));
    const isCompleted = filesUploading.every((track) => track.loaded === track.total);

    return (
        <div className="file-upload-info ms-Grid" dir="ltr">
          <div className="ms-Grid-row">
            <div className="ms-Grid-col ms-sm11">
              {isCompleted ? t('Documentos cargados') : t('Cargando documentos...')}
            </div>
            {isCompleted && (
                <div className="ms-Grid-col ms-sm1">
                  <Icon iconName="Cancel" className="clickable" onClick={this.onDismiss} />
                </div>
            )}
          </div>
          <div className="ms-Grid-row">
            <div className="ms-Grid-col ms-sm12">
              {uploadedFiles.length + t(' de ') + filesData.length + t(' archivos subidos.')}
            </div>
          </div>
          {hasErrors && (
              <div className="ms-Grid-row">
                <div className="ms-Grid-col ms-sm12">
                  {filesWithErrors.length > 1
                      ? filesWithErrors.length + t(' archivos no pudieron cargarse.')
                      : t('1 archivo no pudo cargarse.')}
                </div>
              </div>
          )}
          {!isCompleted && (
              <>
                {fileWithUploadProblem ? (
                    <div className="ms-Grid-row">
                      <div className="ms-Grid-col ms-sm12">
                        <MessageBar
                            messageBarType={MessageBarType.warning}
                            dismissButtonAriaLabel="Close"
                            actions={
                              <div>
                                <MessageBarButton onClick={this.onCancelUpload}>
                                  {t('Cancelar carga')}
                                </MessageBarButton>
                              </div>
                            }
                        >
                          {t('UPLOAD_PROGRESS_DELAY_MSG')}
                        </MessageBar>
                      </div>
                    </div>
                ) : (
                    <div className="ms-Grid-row">
                      <div className="ms-Grid-col ms-sm12">
                        <Link underline onClick={this.onCancelUpload}>
                          {t('Cancelar')}
                        </Link>
                      </div>
                    </div>
                )}
              </>
          )}
          <div className="ms-Grid-row">
            <div className="ms-Grid-col ms-sm12">
              <ProgressIndicator
                  label={`${(percentUploaded * 100).toFixed(2)} %`}
                  description={
                    <>
                      <SizeInBytesLabel size={totalUploaded} /> / <SizeInBytesLabel size={totalSize} />
                    </>
                  }
                  percentComplete={percentUploaded}
                  styles={{
                    progressBar: {
                      backgroundColor: percentUploaded === 1 ? (hasErrors ? 'red' : 'green') : '#0078d4',
                    },
                  }}
                  barHeight={4}
              />
            </div>
          </div>
          {viewDetails && (
              <>
                <div className="file-upload-details-header">
                  <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12">{t('Detalle:')}</div>
                  </div>
                </div>
                <div className="file-upload-details">
                  {filesData.map((track, i) => {
                    const percentCompleteFile = track.total > 0 ? track.loaded / track.total : 0;
                    const withError = filesWithErrors.includes(track.file);

                    return (
                        <div className="ms-Grid-row" key={i}>
                          <div className="ms-Grid-col ms-sm12">
                            {withError ? (
                                <>
                                  <Text className="color-error" nowrap block title={track.file.name}>
                                    {t('Error al cargar ') + track.file.name}
                                  </Text>
                                </>
                            ) : (
                                <ProgressIndicator
                                    label={
                                      <>
                                        <Text nowrap block title={track.file.name}>
                                          {track.file.name}
                                        </Text>
                                      </>
                                    }
                                    description={
                                      <>
                                        {(percentCompleteFile * 100).toFixed(2)} % (
                                        <SizeInBytesLabel size={track.loaded} /> /{' '}
                                        <SizeInBytesLabel size={track.total} />)
                                      </>
                                    }
                                    percentComplete={percentCompleteFile}
                                    styles={{
                                      progressBar: { backgroundColor: percentCompleteFile === 1 ? 'green' : '#0078d4' },
                                    }}
                                />
                            )}
                          </div>
                        </div>
                    );
                  })}
                </div>
              </>
          )}

          <div className="ms-Grid-row">
            <div className="ms-Grid-col ms-sm12">
              <Link underline onClick={() => this.setState({ viewDetails: !viewDetails })}>
                {viewDetails ? t('Ocultar') : t('Ver')} {t(' detalles')}
              </Link>
            </div>
          </div>
        </div>
    );
  }

  private onDismiss() {
    if (this.props.onDismiss) {
      this.props.onDismiss();
    }
  }

  private onCancelUpload() {
    if (this.props.onCancelUpload) {
      this.props.onCancelUpload();
    }
  }

  private bytesUploaded(filesData: IFileTrackData[]) {
    return filesData.reduce((prev, file) => prev + file.loaded, 0);
  }

  private totalBytesToUpload(filesData: IFileTrackData[]) {
    return filesData.reduce((prev, file) => prev + file.total, 0);
  }

  private uploadedFiles(filesData: IFileTrackData[], errors: File[]) {
    return filesData.filter((tracker) => tracker.loaded === tracker.total && !errors.includes(tracker.file));
  }
}
