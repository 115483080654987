import { Callout, mergeStyleSets, Text, FontWeights, IIconProps } from '@fluentui/react';
import { useBoolean, useId } from '@fluentui/react-hooks';
import { IconButton } from '@fluentui/react/lib/Button';
import { t } from 'i18next';

export interface ITooltip {
  inform: string;
  title?: string;
  messageBody?: string;
}

export const TooltipInfo = (props: ITooltip) => {
  const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] = useBoolean(false);
  const buttonId = useId(`${props.inform}tooltip-button`);
  const labelId = useId(`${props.inform}tooltip-label`);
  const descriptionId = useId(`${props.inform}tooltip-description`);
  const infoIcon: IIconProps = { iconName: 'Info' };

  return (
      <>
        {/* <DefaultButton
        id={buttonId}
        onClick={toggleIsCalloutVisible}
        text={isCalloutVisible ? 'Hide callout' : 'Show callout'}
        className={styles.button}
      /> */}
        <IconButton
            id={buttonId}
            iconProps={infoIcon}
            title={t('Info')}
            ariaLabel="Info"
            onClick={toggleIsCalloutVisible}
        />

        {isCalloutVisible && (
            <Callout
                className={styles.callout}
                ariaLabelledBy={labelId}
                ariaDescribedBy={descriptionId}
                role="dialog"
                gapSpace={0}
                target={`#${buttonId}`}
                onDismiss={toggleIsCalloutVisible}
                setInitialFocus
            >
              {props.title && (
                  <Text as="h1" block variant="xLarge" className={styles.title} id={labelId}>
                    {props.title}
                  </Text>
              )}
              {props.messageBody && (
                  <Text block variant="small" id={descriptionId}>
                    {props.messageBody}{' '}
                  </Text>
              )}
            </Callout>
        )}
      </>
  );
};

const styles = mergeStyleSets({
  button: {
    width: 130,
  },
  callout: {
    width: 320,
    maxWidth: '90%',
    padding: '20px 24px',
  },
  title: {
    marginBottom: 12,
    fontWeight: FontWeights.semilight,
    marginTop: 0,
  },
  link: {
    display: 'block',
    marginTop: 20,
  },
});
