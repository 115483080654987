import React, { useState, useMemo } from 'react';
import { Stack } from '@fluentui/react/lib/Stack';
import { FontWeights, mergeStyleSets } from '@fluentui/react/lib/Styling';
import TimeTaxSupportSystemClient from '../../../system';
import { CARD_MODE, GRID_MODE } from '../../../common/list-utils';
import { ListColumnFilter } from '../../Common/list-entities/ListColumnFilter';
import Project from '../../../system/projects/Project';
import { ProjectRequiredDocumentGridList } from './ProjectRequiredDocumentGridList';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../../common/constants';
import { DocumentUploadControlSaved } from '../../../system/documents/DocumentUploadControl';
import { SearchForDocumentControlDialog } from './SearchForDocumentControlDialog';
import DocumentUploadControlPendingSaved from '../../../system/documents/DocumentUploadControlSaved';
import {
  DefaultButton,
  FocusTrapCallout,
  FocusZone,
  FocusZoneTabbableElements,
  Icon,
  Link,
  PrimaryButton,
  Text,
} from '@fluentui/react';
import { DocumentContentTypeIcon } from '../DocumentContentTypeIcon';
import DocumentUploadControlConfirmed from '../../../system/documents/DocumentUploadControlConfirmed';
import { SizeInBytesLabel } from '../SizeInBytesLabel';
import { themeLight } from '../../../styles/colors';
import MessageCourier from '../../../lib/MessageCourier';
import { User } from '../../../system/User';
import { UnassignDocumentControlDialog } from '../UnassignDocumentControlDialog';
import { Collaborator } from '../../../system/collaborators/Collaborator';
import CollaboratorPersona from '../../Collaborator/CollaboratorPersona';
import { useId } from '@fluentui/react-hooks';
import { useTranslation } from 'react-i18next';

interface IProjectRequiredDocumentList {
  system: TimeTaxSupportSystemClient;
  user: User;
  courier: MessageCourier;
  project: Project;
  onSelectDocumentControl?: (_d: DocumentUploadControlSaved) => void;
  changeBlockUI: (state: boolean) => void;
}
export const ProjectRequiredDocumentList = (props: IProjectRequiredDocumentList) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const [mode, setMode] = useState(GRID_MODE);
  const [filterPanel, setFilterPanel] = useState(false);

  const baseUrl = React.useMemo(() => {
    return props.system.getProjectSystem().getDocumentControlListForManagerBaseUrlForDatagrid(props.project);
  }, [props.system, props.project]);

  const quickFilters: ListColumnFilter[] = useMemo(() => [], []);

  const onItemClick = async (item: any) => {
    setDocumentControlConceptSelected(item.ccdp_concepto);
    getDocumentControl(item.ccdp_id);
  };
  const [shouldUpdate, setShouldUpdate] = React.useState<boolean>(false);
  const [showGoToDocumentsDialog, setShowGoToDocumentsDialog] = React.useState<boolean>(false);
  const [showUnassignDocumentControlDialog, setShowUnassignDocumentControlDialog] =
      React.useState<boolean>(false);
  const [documentControlConceptSelected, setDocumentControlConceptSelected] = React.useState<string>('');
  const [docControl, setDocControl] = React.useState<DocumentUploadControlSaved | undefined>(undefined);

  const onClose = (accept: boolean) => {
    if (accept && docControl) {
      if (props.onSelectDocumentControl) props.onSelectDocumentControl(docControl);
    }
    setShowGoToDocumentsDialog(false);
  };

  const getDocumentControl = async (id: string) => {
    if (props.project && id) {
      let docControl = await props.system
          .getProjectSystem()
          .getDocumentControlIdentifiedBy(id, props.project);
      setDocControl(docControl);
      if (docControl instanceof DocumentUploadControlPendingSaved) {
        setShowGoToDocumentsDialog(true);
      }
    }
  };

  const onDownload = async () => {
    if (docControl instanceof DocumentUploadControlConfirmed) {
      try {
        const downloadUrl = await props.system
            .getDocumentSystem()
            .getDownloadLinkFor(docControl.getDocument());
        window.open(downloadUrl, '_blank');
      } catch (e) {
        props.courier.messageError(e);
      }
    }
  };

  const onUnassignDocumentClick = () => {
    setShowUnassignDocumentControlDialog(true);
  };

  const onConfirmUnassignDocument = async () => {
    if (docControl instanceof DocumentUploadControlConfirmed) {
      setShowUnassignDocumentControlDialog(false);
      try {
        await props.system.getProjectSystem().unassignDocumentForDocumentControl(docControl.getId());
        setShouldUpdate(!shouldUpdate);
        props.courier.messageSuccess(
            t('El documento fue actualizado correctamente. Ahora el documento ya no es obligatorio')
        );
        setDocControl(undefined);
      } catch (error) {
        props.courier.messageError(error);
      }
      // props.notifyChange();
    }
  };

  const toggleShowUnassignDocumentControlDialog = () => {
    setShowUnassignDocumentControlDialog(!showUnassignDocumentControlDialog);
  };

  React.useEffect(() => {
    if (docControl instanceof DocumentUploadControlConfirmed) {
      if (docControl.getDocument().isConfidentialDocument()) {
        getAdmissibleDownloadUsers();
      } else {
        setAdmissibleAccessCollaborators([]);
      }
    }
  }, [docControl]);

  const [admissibleAccessCollaborators, setAdmissibleAccessCollaborators] = React.useState<Collaborator[]>(
      []
  );

  const getAdmissibleDownloadUsers = async () => {
    if (docControl instanceof DocumentUploadControlConfirmed) {
      let cols = await props.system
          .getDocumentSystem()
          .getAdmissibleAccessCollaborators(docControl.getDocument().getId());
      setAdmissibleAccessCollaborators(cols);
    }
  };

  const hasAccessToDocument = () => {
    if (docControl instanceof DocumentUploadControlConfirmed) {
      if (docControl.getDocument().isConfidentialDocument()) {
        let hasAccess = admissibleAccessCollaborators.find(
            (col) => col.getId() == props.user.getCollaboratorId()
        );
        return hasAccess || props.user.hasPowerUserPrivileges() ? true : false;
      } else {
        return true;
      }
    }
  };

  const [isCalloutVisible, setCalloutVisible] = React.useState<boolean>(false);
  const iconId = useId('callout-icon');

  return (
      <>
        <div className="ms-Grid" dir="ltr">
          <div className="ms-Grid-row">
            <div className="ms-Grid-col ms-sm12 padding-custom-new-doc">
              <PrimaryButton
                  iconProps={{ iconName: 'Add' }}
                  onClick={() => {
                    navigate(`${routes.project_config}/${props.project.getId()}`);
                  }}
              >
                {t('Nuevo')}
              </PrimaryButton>
            </div>
          </div>

          <div className="ms-Grid-row">
            <div className="ms-Grid-col ms-sm8">
              <ProjectRequiredDocumentGridList
                  changeBlockUI={props.changeBlockUI}
                  keyList={'project_control_document_' + props.project.getId()}
                  baseUrl={baseUrl}
                  quickFilters={quickFilters}
                  onClickCardMode={() => setMode(CARD_MODE)}
                  onClickGridMode={() => setMode(GRID_MODE)}
                  onItemClick={onItemClick}
                  courier={props.courier}
                  mode={mode}
                  shouldUpdate={shouldUpdate}
              ></ProjectRequiredDocumentGridList>
              <SearchForDocumentControlDialog
                  show={showGoToDocumentsDialog}
                  onClose={onClose}
                  concept={documentControlConceptSelected}
              ></SearchForDocumentControlDialog>
            </div>
            <div className="ms-Grid-col ms-sm4">
              {docControl instanceof DocumentUploadControlConfirmed && (
                  <div className="ms-Grid bg-white" dir="ltr">
                    <div className="ms-Grid-row">
                      <div className="ms-Grid-col ms-sm12">
                        <div className="text-wrapper ml-2">
                          <Text className="fw-500" variant="mediumPlus">
                            {docControl.getDocument().getName()}
                          </Text>
                        </div>
                      </div>
                    </div>

                    <div className="ms-Grid-row">
                      <div className="ms-Grid-col ms-sm12">
                        <div className="text-wrapper ml-2">
                          <Icon iconName="TextDocument" className="text-icon" />
                          <Text>{t(docControl.getDocumentType().getName())}</Text>
                        </div>
                      </div>
                    </div>
                    <div className="ms-Grid-row">
                      <div className="ms-Grid-col ms-sm12">
                        <div className="text-wrapper ml-2">
                          <DocumentContentTypeIcon name={docControl.getDocument().getName()} />
                          <Text styles={{ root: { marginLeft: '5px' } }}>
                            <SizeInBytesLabel size={docControl.getDocument().getSizeInBytes()} />{' '}
                          </Text>
                        </div>
                      </div>
                    </div>
                    <div className="ms-Grid-row">
                      <div className="ms-Grid-col ms-sm12">
                        <div className="text-wrapper ml-2">
                          <Icon
                              iconName="CheckMark"
                              className="text-icon"
                              styles={{ root: { color: themeLight.success } }}
                          />
                          <Text styles={{ root: { color: themeLight.secondaryText } }}>
                            {t('Cargado el ')}
                            {docControl.getDocument().getUploadedDate()}
                          </Text>
                        </div>{' '}
                      </div>
                    </div>
                    <div className="ms-Grid-row">
                      <div className="ms-Grid-col ms-sm12">
                        <div className="text-wrapper ml-2">
                          <Icon
                              iconName="ProtectedDocument"
                              className="text-icon"
                              styles={{ root: { color: themeLight.warning } }}
                          />
                          <Text styles={{ root: { color: themeLight.secondaryText } }}>
                            {t('Es obligatorio')}
                          </Text>
                        </div>{' '}
                      </div>
                    </div>

                    {docControl instanceof DocumentUploadControlConfirmed &&
                        docControl.getDocument().isConfidentialDocument() && (
                            <div className="ms-Grid-row">
                              <div className="ms-Grid-col ms-sm12">
                                <div className="text-wrapper ml-2">
                                  <Icon
                                      id={iconId}
                                      onClick={() => setCalloutVisible(!isCalloutVisible)}
                                      iconName="Lock"
                                      className="text-icon clickable"
                                      styles={{ root: { color: themeLight.warning } }}
                                  />
                                  <Link
                                      className="fw-400"
                                      underline
                                      onClick={() => setCalloutVisible(!isCalloutVisible)}
                                  >
                                    {t('Es confidencial')}
                                  </Link>
                                </div>
                              </div>
                            </div>
                        )}

                    {isCalloutVisible ? (
                        <FocusTrapCallout
                            role="alertdialog"
                            className={styles.callout}
                            gapSpace={0}
                            target={`#${iconId}`}
                            onDismiss={() => setCalloutVisible(!isCalloutVisible)}
                            setInitialFocus
                        >
                          <Text block variant="xLarge" className={styles.title}>
                            {t('Pueden ver o editar el documento:')}
                          </Text>

                          {admissibleAccessCollaborators.map((col) => {
                            return (
                                <div className="mt-1">
                                  <CollaboratorPersona collaborator={col}></CollaboratorPersona>
                                </div>
                            );
                          })}

                          <FocusZone handleTabKey={FocusZoneTabbableElements.all} isCircularNavigation>
                            <Stack className={styles.buttons} gap={8} horizontal>
                              <PrimaryButton onClick={() => setCalloutVisible(!isCalloutVisible)}>
                                {t('Entendido')}
                              </PrimaryButton>
                            </Stack>
                          </FocusZone>
                        </FocusTrapCallout>
                    ) : null}

                    <div className="ms-Grid-row mt-2 mb-2">
                      <div className="ms-Grid-col ms-sm12">
                        <PrimaryButton
                            text={t('Descargar')}
                            onClick={onDownload}
                            disabled={!hasAccessToDocument()}
                            title={
                              !hasAccessToDocument() ? t('No tienes permisos para descargar este archivo') : ''
                            }
                            iconProps={{ iconName: 'DownloadDocument' }}
                            className="primary-button ml-2"
                        />
                        {props.user.hasPowerUserPrivileges() && (
                            <DefaultButton
                                text={t('Desasignar')}
                                className="mr-1rem"
                                onClick={onUnassignDocumentClick}
                                iconProps={{ iconName: 'Unlock' }}
                            />
                        )}{' '}
                      </div>
                    </div>
                    <UnassignDocumentControlDialog
                        toggleShowUnassignDocumentControlDialog={toggleShowUnassignDocumentControlDialog}
                        onConfirmUnassignDocument={onConfirmUnassignDocument}
                        documentName={docControl.getDocument().getName()}
                        show={showUnassignDocumentControlDialog}
                    ></UnassignDocumentControlDialog>
                  </div>
              )}
            </div>
          </div>
        </div>
      </>
  );
};

const styles = mergeStyleSets({
  callout: {
    width: 350,
    padding: '20px 24px',
  },
  title: {
    marginBottom: 12,
    fontWeight: FontWeights.semilight,
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 20,
  },
});
