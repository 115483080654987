import React from 'react';
import ErrorListForInput from './ErrorListForInput';
import { Toggle } from '@fluentui/react';
import { useTranslation, Trans } from 'react-i18next';
import CustomForm from './CustomForm';
import CustomInput, { CallableFn } from './CustomInput';
import CustomInputIfc from './CustomInputIfc';
import { IToggleProps } from 'office-ui-fabric-react/lib/Toggle';

interface IToggleInput {
  input: CustomInputIfc;
  onBlur: (input: CustomInputIfc) => void;
  offText: string;
  onText: string;
  // checked: boolean;
  onChangeExtended?: (checked: boolean) => void;
  propsInput?: IToggleProps;
}

const ToggleInput = (props: IToggleInput) => {
  const { t, i18n } = useTranslation();

  const [updateFlag, setUpdateFlag] = React.useState(false);

  const updateViewAfterDoing = (anAction: CallableFn) => {
    setUpdateFlag((u) => {
      anAction();
      return !u;
    });
  };

  const onChangeCustomInput = (val: any) => {
    updateViewAfterDoing(() => {
      props.input.setValue(!!val);
      props.input.onBlur();
      props.onBlur(props.input);
    });
  };

  return (
      <div>
        <Toggle
            offText={t(props.offText)}
            onText={t(props.onText)}
            checked={props.input.getValue()}
            onChange={(ev, val) => {
              onChangeCustomInput(val);
              if (props.onChangeExtended) props.onChangeExtended(!!val);
            }}
            {...props.propsInput}
        />
        {props.input.hasErrors() && <ErrorListForInput customInput={props.input} />}
      </div>
  );
};

export default ToggleInput;
