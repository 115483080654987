import { Icon, Text } from '@fluentui/react';
import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import DialogTemplate from '../../Common/DialogTemplate';

export interface ConfirmPreloadTeamProps {
  show: boolean;
  onClose: (accept: boolean) => void;
  collaborators: number;
  nodeName: string;
}

export const ConfirmPreloadTeam = (props: ConfirmPreloadTeamProps) => {
  const { t, i18n } = useTranslation();

  const cancel = () => {
    props.onClose(false);
  };

  const accept = () => {
    props.onClose(true);
  };

  return (
      <DialogTemplate
          showDialog={props.show}
          toggleShowDialog={() => props.onClose(false)}
          title={t('Do you want preload project team?')}
          onAccept={accept}
          onCancel={cancel}
          acceptText={t('Confirm')}
          cancelText={t('Cancel')}
          minWidth={600}
      >
        <div>
          <Text block variant="medium">
            <Icon iconName="Group" className="text-icon" />
            Quieres incluir en el proyecto a todos los colaboradores que pertencen al area de {props.nodeName}?
            ({props.collaborators} {props.collaborators > 1 ? 'Colaboradores' : 'Colaborador'}).
          </Text>
        </div>
      </DialogTemplate>
  );
};
