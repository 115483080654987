import LazyInitialization from '../../lib/LazyInitialization';
import Project from './Project';
import { ProjectRelatedDocument } from '../documents/ProjectRelatedDocument';
import { ProjectsSystem } from './ProjectsSystem';

export default abstract class ProjectFolder {
  protected documents: LazyInitialization<ProjectRelatedDocument[]>;

  protected constructor(
      protected system: ProjectsSystem,
      protected project: Project,
      protected id: string,
      protected name: string,
      documentsInitializer?: LazyInitialization<ProjectRelatedDocument[]>
  ) {
    this.documents = documentsInitializer || new LazyInitialization(() => system.documentsOf(this));
  }

  isIdentifiedBy(folderId: string): boolean {
    return this.id === folderId;
  }

  getId() {
    return this.id;
  }

  getName() {
    return this.name;
  }

  getDocuments(reload = false) {
    return this.documents.value(reload);
  }

  getProject(): Project {
    return this.project;
  }

  abstract addChildNamed(childName: string): Promise<ProjectFolder>;
  abstract getChildren(): Promise<ProjectFolder[]>;
  abstract moveInto(documents: ProjectRelatedDocument[]): Promise<void>;
  abstract isSystemFolder(): boolean;
  abstract resetChildren(): Promise<void>;
  abstract changeNameTo(value: string): Promise<void>;
  abstract isRoot(): boolean;
  abstract moveToTrash(): Promise<void>;
  abstract restoreFromTrash(): Promise<void>;
  abstract addChild(selectedFolder: ProjectFolder): Promise<void>;
  /**
   * @TODO hacer las versiones asincronicas
   */
  abstract findChildIdentifiedAs(id: string): ProjectFolder | undefined;
  abstract hasChild(child: ProjectFolder): boolean;
  abstract hasDescendant(folder: ProjectFolder): boolean;
  abstract collectPathUpToInto(path: ProjectFolder[], descendant: ProjectFolder): void;
  abstract hasChildren(): boolean;
  abstract isTrash(): boolean;
  abstract belongsToUser(): boolean;
}
