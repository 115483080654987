import React from 'react';
import { DefaultButton, Icon, Text } from '@fluentui/react';
import { Nav, INavLinkGroup, INavLink } from '@fluentui/react/lib/Nav';
import { Breadcrumb } from '@fluentui/react/lib/Breadcrumb';

import './ProjectDocumentView.css';
import FileFolder from '../FileDropzone/FileFolder';
import Project from '../../system/projects/Project';
import TimeTaxSupportSystemClient, { ProjectFolder } from '../../system';
import ProjectSelectedFolderView from './ProjectSelectedFolderView';
import { ProjectRelatedDocument } from '../../system/documents/ProjectRelatedDocument';
import { ProjectCommonFolder } from '../../system/projects/ProjectCommonFolder';
import BlockUi from 'react-block-ui';
import SimpleFolderChangeDialog from './SimpleFolderChangeDialog';
import { ProjectTrashFolder } from '../../system/projects/ProjectTrashFolder';
import { FolderRestoreDialog } from './DocumentRestoreDialog';
import useAsyncDataCourier from '../../common/custom-hooks/useAsyncDataCourier';
import MessageCourier from '../../lib/MessageCourier';
import { User } from '../../system/User';
import { DocumentUploadControlSaved } from '../../system/documents/DocumentUploadControl';
import { useTranslation } from 'react-i18next';

const noUrl = '/none';

interface IProjectDocumentsViewProps {
  project: Project;
  courier: MessageCourier;
  system: TimeTaxSupportSystemClient;
  user: User;
  controlDocument?: DocumentUploadControlSaved;
  backToControlDocsSection: () => void;
}

const ProjectDocumentsView: React.FC<IProjectDocumentsViewProps> = ({ project, ...props }) => {
  const { t } = useTranslation();
  const [folderPath, setFolderPath] = React.useState<ProjectFolder[]>([]);
  const [selectedFolder, setSelectedFolder] = React.useState<ProjectFolder>();
  const [documentsBeingDragged, setDocumentsBeingDragged] = React.useState<ProjectRelatedDocument[]>([]);
  const [movingFiles, setMovingFiles] = React.useState(false);
  const [deletingFolder, setDeletingFolder] = React.useState(false);
  const [selectedFolderForAddingChild, setSelectedFolderForAddingChild] = React.useState<ProjectFolder>();
  const [selectedFolderForChangingName, setSelectedFolderForChangingName] = React.useState<ProjectFolder>();
  const [folderNavLink, setFolderNavLink] = React.useState<INavLinkGroup[]>([]);
  const [restoringFromTrash, setRestoringFromTrash] = React.useState(false);
  const [childrenFolders, setChildrenFolders] = React.useState<ProjectFolder[]>([]);

  const forceReload = () => {
    let selected: ProjectFolder | undefined = undefined;
    setSelectedFolder((p) => {
      selected = p;
      return undefined;
    });
    setSelectedFolder(selected);
  };

  const rootFolder = useAsyncDataCourier(
      () => props.system.getProjectSystem().rootFolderOf(project),
      [project, props.system],
      props.courier
  );

  const childrenFoldersAsync = useAsyncDataCourier(
      async () => {
        const result = await (selectedFolder ? selectedFolder.getChildren() : Promise.resolve([]));
        return sortFoldersWithSystemAtEnd(result);
      },
      [selectedFolder],
      props.courier
  );

  React.useEffect(() => {
    setChildrenFolders(childrenFoldersAsync || []);
  }, [childrenFoldersAsync]);

  React.useEffect(() => {
    setFolderNavLink([]);
  }, [project]);

  const setBaseNavLinks = React.useCallback(() => {
    if (rootFolder) {
      setFolderNavLink([{ name: rootFolder.getName(), links: [] }]);
    } else {
      setFolderNavLink([]);
    }
  }, [rootFolder]);

  React.useEffect(() => {
    if (rootFolder) {
      setSelectedFolder(rootFolder);
      setFolderPath([rootFolder]);
    }
    setBaseNavLinks();
  }, [rootFolder, setBaseNavLinks]);

  React.useEffect(
      () => setFolderNavLink((p) => generateFolderLinks(childrenFolders, selectedFolder, rootFolder, p)),
      [childrenFolders, selectedFolder, rootFolder]
  );

  const onFolderClick = async (ev?: React.MouseEvent<HTMLElement, MouseEvent>, item?: INavLink) => {
    ev?.preventDefault();
    if (item && item.key) {
      const folderClicked = findFolderIdentifiedAs(rootFolder, item.key);
      setSelectedFolder(folderClicked);
      setFolderPath([...rootFolder!.pathUpTo(folderClicked), folderClicked]);
    }
  };

  const onDropDocuments = (folderId: string) => {
    if (documentsBeingDragged.length === 0) return;
    const folder = findFolderIdentifiedAs(rootFolder, folderId);

    setMovingFiles(true);
    folder.moveInto([...documentsBeingDragged]).finally(() => {
      setMovingFiles(false);
      props.courier.messageSuccess(t('Documentos movidos con éxito.'));
      forceReload();
    });
  };

  const onDrop = async (fileFolder: FileFolder): Promise<void> => {
    try {
      await props.system.uploadFolderTo(fileFolder, selectedFolder!);
      // props.courier.messageSuccess('Documentos cargados con éxito.');
    } catch (e) {
      props.courier.messageError(e);
    } finally {
      selectedFolder!.resetChildren().finally(() => {
        forceReload();
      });
    }
  };

  const onUploadTooLarge = () => {
    props.courier.messageError(t(`La carga de archivos supera el tamaño máximo permitido.`));
  };

  const blockUiMessage = React.useMemo(() => {
    if (movingFiles) {
      return t('Moviendo archivos');
    }
    if (deletingFolder) {
      return t('Moviendo a la papelera...');
    }

    return 'Cargando...';
  }, [movingFiles, deletingFolder, t]);

  const addChildOf = (folderId: string) => {
    const folder = findFolderIdentifiedAs(rootFolder, folderId);
    setSelectedFolderForAddingChild(folder);
  };

  const editNameOf = (folderId: string) => {
    const folder = findFolderIdentifiedAs(rootFolder, folderId);
    setSelectedFolderForChangingName(folder);
  };

  const moveToTrash = (folder: ProjectFolder) => {
    setDeletingFolder(true);

    setFolderPath([rootFolder!]);
    setSelectedFolder(rootFolder);
    setChildrenFolders([]);
    setBaseNavLinks();
    folder.moveToTrash().finally(() => {
      rootFolder?.resetChildren().finally(forceReload);
      setDeletingFolder(false);
    });
  };

  const restoreFromTrash = () => {
    setRestoringFromTrash(true);
  };

  /**
   * @TODO mejorar el instanceof
   */
  const selectedInTrash = React.useMemo(
      () => folderPath.some((folder) => folder instanceof ProjectTrashFolder),
      [folderPath]
  );

  const onRestoreFromTrash = (folder: ProjectFolder): void => {
    setDeletingFolder(true);

    folder
        .addChild(selectedFolder!)
        .catch(props.courier.messageError)
        .finally(() => {
          setFolderPath([rootFolder!]);
          setSelectedFolder(rootFolder);
          rootFolder?.resetChildren().finally(forceReload);
          setDeletingFolder(false);
          setRestoringFromTrash(false);
        });
  };

  return (
      <>
        <BlockUi blocking={movingFiles || deletingFolder} message={blockUiMessage}>
          {props.controlDocument && (
              <div className="searching-document mt-2">
                <Text variant="large" className="fw-500">
                  <Icon iconName="Search" className="text-icon"></Icon>{' '}
                  {t('Buscando el documento obligatorio - ') + props.controlDocument.getConcept()}
                </Text>
                {/* */}
                <DefaultButton
                    iconProps={{ iconName: 'Cancel' }}
                    className="float-right"
                    onClick={() => props.backToControlDocsSection()}
                >
                  {t('Cancelar busqueda')}
                </DefaultButton>
              </div>
          )}

          <div className="folders-breadcrumb-container">
            <div style={{ display: 'inline-block' }}>
              <Breadcrumb
                  items={folderPath.map((folder) => {
                    return {
                      key: folder.getId(),
                      text: folder.getName(),
                      onClick: () => {
                        if (selectedFolder !== folder) {
                          setFolderPath(folderPath.slice(0, folderPath.findIndex((f) => f === folder) + 1));
                        }
                        setSelectedFolder(folder);
                      },
                    };
                  })}
                  maxDisplayedItems={4}
                  overflowIndex={folderPath.length > 1 ? 1 : 0}
                  overflowAriaLabel="Más carpetas"
                  onReduceData={() => undefined}
                  styles={{ itemLink: { fontSize: '16px' } }}
              />
            </div>
            {selectedFolder && !selectedFolder.isSystemFolder() && (
                <div style={{ display: 'inline-block' }}>
                  <Icon
                      iconName="Add"
                      className="clickable add-folder-icon"
                      onClick={() => addChildOf(selectedFolder.getId())}
                  />
                  {!selectedFolder.isRoot() && (
                      <>
                        <Icon
                            iconName="Edit"
                            className="clickable add-folder-icon"
                            onClick={() => editNameOf(selectedFolder.getId())}
                        />
                        {selectedInTrash ? (
                            <Icon
                                iconName="RemoveFromTrash"
                                className="clickable"
                                onClick={() => restoreFromTrash()}
                            />
                        ) : (
                            <Icon
                                iconName="Trash"
                                className="clickable"
                                onClick={() => moveToTrash(selectedFolder)}
                            />
                        )}
                      </>
                  )}
                </div>
            )}
          </div>
          <div className="ms-Grid" dir="ltr">
            <div className="ms-Grid-row">
              {selectedFolder && (
                  <>
                    <div className="ms-Grid-col ms-sm3">
                      <Nav
                          selectedKey={selectedFolder.getId()}
                          ariaLabel="Carpetas"
                          groups={folderNavLink}
                          onLinkClick={onFolderClick}
                          onRenderLink={(props, defaultRender) => {
                            const folderId = props!.key!;
                            try {
                              const folder = findFolderIdentifiedAs(rootFolder, folderId);
                              const iconName = props?.isExpanded
                                  ? 'OpenFolderHorizontal'
                                  : folder.hasChildren()
                                      ? 'DocumentSet'
                                      : folder.isTrash()
                                          ? 'Trash'
                                          : folder.belongsToUser()
                                              ? 'FabricUserFolder'
                                              : 'FabricFolder';
                              return (
                                  <div
                                      className="ms-Grid full-width"
                                      dir="ltr"
                                      onDrop={() => onDropDocuments(folderId)}
                                  >
                                    <div className="ms-Grid-row">
                                      <div className="ms-Grid-col ms-sm2">
                                        <Icon
                                            iconName={iconName}
                                            // styles={{ root: { position: 'absolute', right: '8.5rem' } }}
                                        />
                                      </div>
                                      <div className="ms-Grid-col ms-sm10">
                                        <Text block nowrap>
                                          {' '}
                                          {props?.name}
                                        </Text>
                                      </div>
                                    </div>
                                  </div>
                              );
                            } catch (e) {
                              return null;
                            }
                          }}
                          styles={{
                            chevronButton: { /*color: 'transparent',*/ fontSize: '16px' },
                            link: { textAlign: 'inherit' },
                          }}
                      />
                    </div>

                    <div className="ms-Grid-col ms-sm9">
                      <ProjectSelectedFolderView
                          controlDocument={props.controlDocument}
                          backToControlDocsSection={props.backToControlDocsSection}
                          user={props.user}
                          selectedFolder={selectedFolder}
                          onDropFileFolder={onDrop}
                          setDocumentsBeingDragged={setDocumentsBeingDragged}
                          courier={props.courier}
                          system={props.system}
                          onUploadTooLarge={onUploadTooLarge}
                      />
                    </div>

                    <SimpleFolderChangeDialog
                        hidden={!selectedFolderForAddingChild && !selectedFolderForChangingName}
                        text={
                          selectedFolderForAddingChild
                              ? t('Agregar carpeta a ') + selectedFolderForAddingChild?.getName()
                              : t('Cambiar nombre de ') + selectedFolderForChangingName?.getName()
                        }
                        initialValue={selectedFolderForChangingName?.getName()}
                        onCancel={() => {
                          setSelectedFolderForAddingChild(undefined);
                          setSelectedFolderForChangingName(undefined);
                        }}
                        onAccept={(value) => {
                          if (selectedFolderForAddingChild) {
                            selectedFolderForAddingChild
                                .addChildNamed(value)
                                .finally(() => selectedFolder.resetChildren().finally(forceReload));
                            setSelectedFolderForAddingChild(undefined);
                          }
                          if (selectedFolderForChangingName) {
                            selectedFolderForChangingName.changeNameTo(value).finally(forceReload);
                            setSelectedFolderForChangingName(undefined);
                          }
                        }}
                    />

                    {restoringFromTrash && (
                        <FolderRestoreDialog
                            system={props.system}
                            folder={selectedFolder}
                            onCancel={() => setRestoringFromTrash(false)}
                            onRestoreInto={onRestoreFromTrash}
                        />
                    )}
                  </>
              )}
            </div>
          </div>
        </BlockUi>
      </>
  );
};

export default ProjectDocumentsView;

function sortFoldersWithSystemAtEnd(result: ProjectFolder[]): ProjectFolder[] {
  return result.sort((a, b) =>
      a.isSystemFolder() && b.isSystemFolder() ? 0 : a.isSystemFolder() ? 1 : b.isSystemFolder() ? -1 : 0
  );
}

function findFolderIdentifiedAs(rootFolder: ProjectFolder | undefined, folderId: string) {
  const folder = rootFolder?.findChildIdentifiedAs(folderId);
  if (!folder) {
    throw new Error('Folder not found');
  }
  return folder;
}

function findFolderInINavLink(links: INavLink[], folderId: string): INavLink | undefined {
  for (const link of links) {
    if (link.key === folderId) {
      return link;
    }
    if (!link.links) continue;
    const possibleNavLink = findFolderInINavLink(link.links, folderId);
    if (possibleNavLink) {
      return possibleNavLink;
    }
  }
  return undefined;
}

function findFolderById(links: INavLinkGroup[], folderId: string) {
  for (const link of links) {
    const navLink = findFolderInINavLink(link.links, folderId);
    if (navLink) {
      return navLink;
    }
  }
}

function generateFolderLinks(
    childrenFolders: ProjectFolder[] | undefined,
    selectedFolder: ProjectFolder | undefined,
    rootFolder: ProjectCommonFolder | undefined,
    folderLinks: INavLinkGroup[]
): INavLinkGroup[] {
  const result = [...folderLinks];
  if (!childrenFolders || !selectedFolder || !rootFolder) return result;
  if (childrenFolders.length > 0 && !selectedFolder.hasChild(childrenFolders[0])) return result;

  let navLink: INavLinkGroup | INavLink | undefined = undefined;
  if (selectedFolder === rootFolder) {
    if (result.length === 0) return result;
    navLink = result[0];
  } else {
    navLink = findFolderById(result, selectedFolder.getId());
    // if (navLink) {
    // navLink.icon = ;
    // navLink.iconProps = { iconName: navLink.isExpanded ? 'OpenFolderHorizontal' : 'FabricFolder' };
    // }
  }
  if (!navLink) return result;

  navLink.name = selectedFolder.getName();

  navLink.links = childrenFolders.map((child) => ({
    name: child.getName(),
    url: noUrl,
    key: child.getId(),
  }));

  return result;
}
