import { ProjectCompanyListForm } from '../../Components/Proyectos/ProjectConfiguration/ProjectConfigurationDistributionForm';
import { Company } from '../company/Company';
import { ProjectCompanyInDistribution } from './IProjectCompanyInDistribution';
import { ProjectCompany, ProjectCompanyJson } from './ProjectCompany';

class ProjectCompanySaved extends ProjectCompanyInDistribution {
  private constructor(private id: string, private projectCompany: ProjectCompany, private __delete: boolean) {
    super();
  }

  static fromJson(projectCompany: ProjectCompanyJson) {
    const company = Company.fromJson(projectCompany.empresa);
    return new this(
        projectCompany.exp_id,
        ProjectCompany.with(+projectCompany.exp_porcentaje, company),
        projectCompany.__delete
    );
  }

  static toJson(projectCompany: ProjectCompanySaved) {
    let ret: ProjectCompanyJson = {
      exp_id: projectCompany.getId(),
      exp_porcentaje: projectCompany.getPercentage().toString(),
      exp_empid: projectCompany.getCompany().getId(),
      empresa: Company.toJson(projectCompany.getCompany()),
      __delete: projectCompany.get__delete(),
    };
    return ret;
  }

  asJson(): ProjectCompanyJson {
    return ProjectCompanySaved.toJson(this);
  }

  getId() {
    return this.id;
  }

  getPercentage(): number {
    return this.projectCompany.getPercentage();
  }

  getCompany() {
    return this.projectCompany.getCompany();
  }

  get__delete() {
    return this.__delete;
  }

  setId(value: string) {
    this.id = value;
  }

  setPercentage(value: number) {
    this.projectCompany = ProjectCompany.with(+value, this.getCompany());
  }

  set__delete(value: boolean) {
    this.__delete = value;
  }

  delete(list: ProjectCompanyListForm): void {
    list.deleteProjectCompanySaved(this);
  }

  addTo(list: ProjectCompanyListForm, projectCompany: ProjectCompanyInDistribution): void {
    list.addProjectCompanySaved(this, projectCompany);
  }

  collectCompanyInto(list: Company[]): void {
    if (this.isActive()) {
      list.push(this.getCompany());
    }
  }

  affectTotalPercentage(partialSum: number): number {
    if (this.get__delete()) {
      return partialSum;
    }
    return partialSum + this.getPercentage();
  }

  isActive(): boolean {
    return !this.get__delete();
  }
}
export default ProjectCompanySaved;
