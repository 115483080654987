import XhrRequestHandler from '../../lib/XhrRequestHandler';

export class RecoverPasswordSystem {
  private readonly base = '/recupero-contrasena';

  constructor(private requestHandler: XhrRequestHandler) {}

  async sendEmail(email: string, token: string) {
    return await this.requestHandler.post(`${this.base}/send-recovery-code`, {
      to: email,
      token: token
    });
  }

  async validateCode(codigo: string) {
    return await this.requestHandler.get(`${this.base}/validate-code/${codigo}`);
  }

  async changePassword(pwd: string) {
    return await this.requestHandler.put(`${this.base}/change-password`, { password: pwd });
  }
}
