import { DateTime } from 'luxon';
import moment from 'moment-timezone';
import 'moment/locale/es';
import { t } from 'i18next';
import { EN_LANGUAGE_VALUE, ES_LANGUAGE_VALUE, getSessionStorageLanguage } from '../i18n';

export class DateCustom {
  public static addMinutes(d: Date | string, minutes: number): Date {
    if (d instanceof Date) {
      const newDate = moment(d).add(minutes, 'minutes').local().toDate();
      return newDate;
    } else {
      let _d = this.ParseDateFromApiStr(d);
      const newDate = moment(_d).add(minutes, 'minutes').local().toDate();
      return newDate;
    }
  }

  public static changeOnlyDate(_old: Date | string, _new: Date) {
    if (!(_old instanceof Date)) {
      let _d = this.ParseDateFromApiStr(_old);
      _old = moment(_d).local().toDate();
    }
    let myDate = moment(_old);
    let selectedDateTime = moment(_new);

    selectedDateTime.hour(myDate.hour());
    selectedDateTime.minute(myDate.minute());
    selectedDateTime.second(myDate.second());

    return selectedDateTime.local().toDate();
  }

  /**
   * Calcula la diferencia de días entre las 2 fechas enviadas como
   * parámetro.
   *
   * @param datetime1
   * @param datetime1
   * @param dateFormat [OPCIONAL], formato de datetime
   */

  public static DatetimeDaysDiff(datetime1: Date | string, datetime2: Date | string, dateFormat?: string) {
    let _ret: number = 0;
    let dateref1: any, dateref2: any;
    let _format: string = 'DD/MM/YYYY HH:mm';

    if (dateFormat) _format = dateFormat;

    if (typeof datetime1 === 'string') {
      dateref1 = moment(datetime1, _format).startOf('day');
    } else {
      if (typeof datetime1 === 'object') dateref1 = moment(datetime1);
    }

    if (typeof datetime2 === 'string') {
      dateref2 = moment(datetime2, _format).startOf('day');
    } else {
      if (typeof datetime2 === 'object') dateref2 = moment(datetime2);
    }

    _ret = dateref1.diff(dateref2, 'days');

    return _ret;
  }

  public static DatetimeDaysHours(datetime1: Date | string, datetime2: Date | string) {
    let _ret: number = 0;
    let dateref1: any, dateref2: any;

    if (typeof datetime1 === 'object') dateref1 = moment(datetime1);
    if (typeof datetime2 === 'object') dateref2 = moment(datetime2);

    const duration = moment.duration(dateref2.diff(dateref1));
    _ret = duration.asHours();

    return _ret;
  }

  public static ParseDateFromApiStr(datetime: string | Date): Date {
    return typeof datetime === 'string'
        ? moment.utc(datetime, 'DD/MM/YYYY HH:mm').local().toDate()
        : datetime;
  }

  public static ParseDateToPostApi(datetime: string | Date): Date {
    return typeof datetime === 'string'
        ? moment(datetime, 'DD/MM/YYYY HH:mm').utc().local().toDate()
        : moment(datetime).utc().toDate();
  }

  public static ParseDateFromApiStrWithoutGMT(datetime: string | Date): Date {
    return typeof datetime === 'string' ? moment(datetime, 'DD/MM/YYYY HH:mm').toDate() : datetime;
  }

  public static ParseDateFromApiNoTime(datetime: string | Date): Date {
    return typeof datetime === 'string' ? moment(datetime, 'DD/MM/YYYY').toDate() : datetime;
  }

  public static ParseDateFromApiStrWithoutToProximoDiaLaboral(datetime: string | Date): Date {
    return typeof datetime === 'string' ? moment(datetime, 'DD/MM/YYYY').toDate() : datetime;
    // return typeof datetime === 'string'
    //   ? new Date(moment(datetime, 'DD/MM/YYYY').format('YYYY-MM-DD'))
    //   : datetime;
  }

  public static DateObjectToDMAStr(dateObj: Date) {
    return moment(dateObj).format('DD/MM/YYYY');
  }

  public static DateObjectToDMAStrGuion(dateObj: Date) {
    return moment(dateObj).format('DD-MM-YYYY');
  }

  public static DateObjectToDMA_HMtr(dateObj: Date) {
    return moment(dateObj).format('DD/MM/YYYY HH:mm');
  }

  public static FormatDatetimeToTime(d: string | Date) {
    return typeof d === 'string'
        ? moment(d, 'DD/MM/YYYY HH:mm').format('HH:mm')
        : moment.utc(d).format('HH:mm');
  }

  public static FormatDatetoAMDStr(date: Date) {
    let _datex = moment(date);
    return _datex.format('YYYY-MM-DD');
  }

  public static getDateFromDMY(date: string) {
    const myMomentObject = moment(date, 'DD-MM-YYYY');
    return myMomentObject.toDate();
  }

  public static getMonthLabel(month: number) {
    let ret = 'ene';
    switch (month) {
      case 1:
        ret = 'feb';
        break;
      case 2:
        ret = 'mar';
        break;
      case 3:
        ret = 'abr';
        break;
      case 4:
        ret = 'may';
        break;
      case 5:
        ret = 'jun';
        break;
      case 6:
        ret = 'jul';
        break;
      case 7:
        ret = 'ago';
        break;
      case 8:
        ret = 'sep';
        break;
      case 9:
        ret = 'oct';
        break;
      case 10:
        ret = 'nov';
        break;
      case 11:
        ret = 'dic';
        break;
    }
    return t(ret);
  }

  public static getMonthLabelEN(month: number) {
    let ret = 'jan';
    switch (month) {
      case 1:
        ret = 'feb';
        break;
      case 2:
        ret = 'mar';
        break;
      case 3:
        ret = 'apr';
        break;
      case 4:
        ret = 'may';
        break;
      case 5:
        ret = 'jun';
        break;
      case 6:
        ret = 'jul';
        break;
      case 7:
        ret = 'aug';
        break;
      case 8:
        ret = 'sep';
        break;
      case 9:
        ret = 'oct';
        break;
      case 10:
        ret = 'nov';
        break;
      case 11:
        ret = 'dic';
        break;
    }
    return t(ret);
  }

  public static getDisplayText(start: Date | string, end: Date | string) {
    let _start: DateTime | undefined = undefined;
    if (typeof start === 'string' || start instanceof String) {
      _start = DateTime.fromFormat(String(start), 'dd/LL/yyyy HH:mm');
    } else {
      _start = DateTime.fromJSDate(start);
    }

    let _end: DateTime | undefined = undefined;
    if (typeof end === 'string' || end instanceof String) {
      _end = DateTime.fromFormat(String(end), 'dd/LL/yyyy HH:mm');
    } else {
      _end = DateTime.fromJSDate(end);
    }

    const userLang = getSessionStorageLanguage();
    if (userLang === ES_LANGUAGE_VALUE) {
      return this.getDisplayTextES(_start, _end);
    }
    if (userLang === EN_LANGUAGE_VALUE) {
      return this.getDisplayTextEN(_start, _end);
    }
    return '';
  }

  public static getDisplayTextES(start: DateTime, end: DateTime) {
    let ret: string = '';

    let dia = start.toFormat('dd');
    let mes = this.getMonthLabel(+start.toFormat('L') - 1);
    let anio = start.toFormat('yyyy');

    let horaInicio = start.toFormat('HH:mm');
    let horaFin = end.toFormat('HH:mm');

    ret = `${dia} de ${mes}. ${anio} de ${horaInicio} a ${horaFin}`;
    return ret;
  }

  public static getDisplayTextEN(start: DateTime, end: DateTime) {
    let dia = start.toFormat('dd');
    let mes = this.getMonthLabelEN(+start.toFormat('L') - 1);
    let anio = start.toFormat('yyyy');

    let horaStart = `${start.toFormat('hh:mm')} ${start.toFormat('a')} `;
    let horaEnd = `${end.toFormat('hh:mm')} ${end.toFormat('a')} `;

    return `${mes}. ${dia}, ${anio} from ${horaStart} to ${horaEnd}`;
  }

  public static esMultiploDeMediaHora(horas: number): boolean {
    return (horas * 10) % 10 === 0 || (horas * 10) % 10 === 5;
  }

  public static esMultiploDe15Min(horas: number): boolean {
    return (
        (horas * 100) % 100 === 0 ||
        (horas * 100) % 100 === 25 ||
        (horas * 100) % 100 === 50 ||
        (horas * 100) % 100 === 75
    );
  }

  public static esMultiploDe10Min(horas: number): boolean {
    return (
        (horas * 100) % 100 === 0 ||
        (horas * 100) % 100 === 20 ||
        (horas * 100) % 100 === 40 ||
        (horas * 100) % 100 === 60 ||
        (horas * 100) % 100 === 80
    );
  }

  /**
   * Devuelve un objeto tipo Date de hace un mes, por ejemplo si hoy es 15/06 esto devolverá 15/05
   * @returns d Date
   */
  public static getFechaOneMonthAgo(): Date {
    const d = DateTime.now();
    const ret = d.minus({ months: 1 }).toJSDate();
    ret.setHours(0, 0, 0, 0);

    return ret;
  }

  public static getFirstDayOfThisMonth(): Date {
    let ret = new Date();
    ret.setDate(1);
    return ret;
  }

  public static getCustomLastNavigationForMonthView(fechaInicio: Date, fechaFin: Date) {
    // Crear objetos de fecha a partir de las cadenas de fecha
    const inicio = fechaInicio;
    const fin = fechaFin;

    // Crear un objeto de fecha para iterar dentro del rango
    let fechaActual = new Date(inicio);

    // Crear un objeto para realizar un seguimiento de la frecuencia de cada mes
    const frecuenciaMeses = {};

    // Iterar dentro del rango y contar la frecuencia de cada mes
    while (fechaActual <= fin) {
      const mes = fechaActual.getMonth() + 1; // getMonth() devuelve un valor entre 0 y 11, se suma 1 para obtener un valor entre 1 y 12

      if (frecuenciaMeses[mes]) {
        frecuenciaMeses[mes]++;
      } else {
        frecuenciaMeses[mes] = 1;
      }

      // Incrementar la fecha actual en un día
      fechaActual.setDate(fechaActual.getDate() + 1);
    }

    // Encontrar el mes más frecuente
    let modaMes: string | null = null;
    let frecuenciaMaxima = 0;
    for (const mes in frecuenciaMeses) {
      if (frecuenciaMeses[mes] > frecuenciaMaxima) {
        frecuenciaMaxima = frecuenciaMeses[mes];
        modaMes = mes;
      }
    }

    // Crear la fecha del primer día del mes más frecuente
    if (modaMes) {
      const fechaModa = new Date(inicio.getFullYear(), +modaMes - 1, 1);

      // Formatear la fecha como "dd/mm/yyyy" y devolverla como resultado
      // const dia = fechaModa.getDate().toString().padStart(2, '0');
      // const mes = (fechaModa.getMonth() + 1).toString().padStart(2, '0');
      // const año = fechaModa.getFullYear().toString();
      // const fechaFormateada = `${dia}/${mes}/${año}`;
      // return fechaFormateada;
      return fechaModa;
    }
    return new Date();
  }

  public static getLastDayOfMonth(date: Date) {
    // Configurar la fecha en el primer día del siguiente mes
    const anio = date.getFullYear();
    const mes = date.getMonth() + 1;
    var fecha = new Date(anio, mes, 1);

    // Restar 1 día para obtener el último día del mes actual
    fecha.setDate(fecha.getDate() - 1);

    return fecha;
  }

  public static getFechaSixMonthsAgo(): Date {
    const d = DateTime.now();
    const ret = d.minus({ months: 6 }).toJSDate();
    ret.setHours(0, 0, 0, 0);

    return ret;
  }

  public static getFechaOneYearAgo(): Date {
    const d = DateTime.now();
    const ret = d.minus({ years: 1 }).toJSDate();
    ret.setHours(0, 0, 0, 0);

    return ret;
  }

  public static getFechaTwelveHoursLater(d: Date): Date {
    let _ret: Date = d;
    _ret.setHours(d.getHours() + 12, 0, 0, 0);
    //d.setHours(0, 0, 0, 0);
    return _ret;
  }

  public static getFechaInitLastYear(): Date {
    var d = new Date(new Date().getFullYear() - 1, 0, 1);
    return d;
  }

  public static getFechaEndLastYear(): Date {
    var d = new Date(new Date().getFullYear() - 1, 11, 31);
    return d;
  }

  public static getFechaOneMonthLater(d: Date): Date {
    let _ret: Date = d;
    _ret.setMonth(d.getMonth() + 1);
    return _ret;
  }

  public static getFechaTwelveHoursAgo(d: Date): Date {
    let _ret: Date = d;
    _ret.setHours(d.getHours() - 12, 0, 0, 0);
    //d.setHours(0, 0, 0, 0);
    return _ret;
  }

  public static getFechaOneDayAgo(d: Date): Date {
    return moment(d).subtract(1, 'd').toDate();
  }

  public static getNameOfDay = (day: number) => {
    let name = '';
    switch (day) {
      case 0:
        name = 'domingo';
        break;
      case 1:
        name = 'lunes';
        break;
      case 2:
        name = 'martes';
        break;
      case 3:
        name = 'miércoles';
        break;
      case 4:
        name = 'jueves';
        break;
      case 5:
        name = 'viernes';
        break;
      case 6:
        name = 'sábado';
        break;
    }
    return t(name);
  };

  public static traducirPosicion(label: string) {
    let traducir = '';
    switch (label) {
      case 'first':
        traducir = 'primer';
        break;
      case 'second':
        traducir = 'segundo';
        break;
      case 'third':
        traducir = 'tercer';
        break;
      case 'fourth':
        traducir = 'cuarto';
        break;
      case 'last':
        traducir = 'último';
        break;
    }
    return t(traducir);
  }

  public static isGreaterThan(fecha1: Date, fecha2: Date) {
    return fecha1 > fecha2;
  }

  public static isEqual(fecha1: Date, fecha2: Date) {
    return (
        fecha1.getFullYear() === fecha2.getFullYear() &&
        fecha1.getMonth() === fecha2.getMonth() &&
        fecha1.getDate() === fecha2.getDate()
    );
  }

  public static isEqualDatetime(fecha1: Date, fecha2: Date) {
    return (
        this.isEqual(fecha1, fecha2) &&
        fecha1.getHours() === fecha2.getHours() &&
        fecha1.getMinutes() === fecha2.getMinutes() &&
        fecha1.getSeconds() === fecha2.getSeconds()
    );
  }

  public static isLessThan(fecha1: Date, fecha2: Date) {
    return fecha1 < fecha2;
  }

  public static isWeekend(d: Date) {
    return d.getDay() === 0 || d.getDay() === 6;
  }

  public static getTimezone() {
    const tz = moment.tz.guess();

    return tz;
  }

  public static isValid(d: Date | null) {
    // @ts-ignore
    return d && d instanceof Date && !isNaN(d);
  }

  public static formatDateForDatePicker = (date?: Date): string => {
    if (!date) return '';
    let month = String(date.getMonth() + 1);
    let day = String(date.getDate());
    let year = String(date.getFullYear());

    month = month.padStart(2, '0');
    day = day.padStart(2, '0');

    return `${day}/${month}/${year}`;
  };

  public static formatDateForDatePicker_MMYYYY = (date?: Date): string => {
    if (!date) return '';
    let month = String(date.getMonth() + 1);
    let day = String(date.getDate());
    let year = String(date.getFullYear());

    month = month.padStart(2, '0');
    day = day.padStart(2, '0');

    return `${month}/${year}`;
  };

  public static formatDateForTimeSelector = (date?: Date): string => {
    if (!date) return '';
    let month = String(date.getMonth() + 1);
    let day = String(date.getDate());
    let year = String(date.getFullYear());

    month = month.padStart(2, '0');
    day = day.padStart(2, '0');

    return `${year}-${month}-${day}`;
  };

  public static calcularHoras = (start: Date, end: Date) => {
    let horas = DateCustom.DatetimeDaysHours(start, end);
    return horas;
  };

  public static convertirHorasAHoraYMinutos(horas) {
    const horas_enteras = Math.floor(horas);
    const minutos = Math.round((horas - horas_enteras) * 60);
    let resultado = horas_enteras === 1 ? `1${t(' hora ')}` : `${horas_enteras}${t(' horas ')}`;

    if (minutos > 0) {
      const leyenda_minutos = minutos === 1 ? `1 ${t('minuto')}` : `${minutos} ${t('minutos')}`;
      resultado += ` y ${leyenda_minutos}`;
    }

    if (horas_enteras === 0) {
      resultado = resultado.replace(`0${t(' horas ')}${t(' y ')}`, '');
    }

    return resultado;
  }

  public static formatHorasToString = (horas: any): string => {
    let ret: string = '';

    horas = parseFloat(horas);
    if (horas) {
      let horaRedondeadaParaAbajo = Math.floor(horas);

      let TieneHoras = '';

      if (horaRedondeadaParaAbajo === 1) {
        ret = horaRedondeadaParaAbajo + t(' hora ');
        TieneHoras = t(' y ');
      }

      if (horaRedondeadaParaAbajo > 1) {
        ret = horaRedondeadaParaAbajo + t(' horas ');
        TieneHoras = t(' y ');
      }
      let horaConDecimales = horas.toFixed(2);
      let decimales = horaConDecimales.slice(-2);
      if (decimales === '25') {
        ret += TieneHoras + t('15 minutos ');
      }

      if (decimales === '50') {
        ret += TieneHoras + t('30 minutos ');
      }

      if (decimales === '75') {
        ret += TieneHoras + t('45 minutos ');
      }
    }

    return ret.trimEnd();
  };

  public static convertMillisToHours(millis: number) {
    let seconds = millis / 1000;
    let minutes = seconds / 60;
    let hours = minutes / 60;
    return hours;
  }

  public static formatDate = (date?: Date): string => {
    if (!date) return '';
    let month = String(date.getMonth() + 1);
    let day = String(date.getDate());
    let year = String(date.getFullYear());

    month = month.padStart(2, '0');
    day = day.padStart(2, '0');

    return `${day}/${month}/${year}`;
  };

  public static formatDateMMYYYY = (date?: Date): string => {
    if (!date) return '';
    let month = String(date.getMonth() + 1);
    let day = String(date.getDate());
    let year = String(date.getFullYear());

    month = month.padStart(2, '0');
    day = day.padStart(2, '0');

    return `${month}/${year}`;
  };

  public static formatDateYYYYMM = (date?: Date): string => {
    if (!date) return '';
    let month = String(date.getMonth() + 1);
    let day = String(date.getDate());
    let year = String(date.getFullYear());

    month = month.padStart(2, '0');
    day = day.padStart(2, '0');

    return `${year}-${month}`;
  };
}
