import { IDocumentType } from './DocumentsSystem';
import i18next, { t } from 'i18next';

export default class SystemDocumentType {
  constructor(private id: string, private name: string) {}

  static fromIDocumentType(data: IDocumentType) {
    return new this(data.doct_id, data.doct_nombre);
  }

  isIdentifiedBy(anId: string): boolean {
    return this.id === anId;
  }

  getId(): string {
    return this.id;
  }

  getName(): string {
    return t(this.name);
  }
}
