import React, { useState } from "react";
import { Icon, DefaultButton, TooltipHost, Spinner } from "@fluentui/react";
import "./cargaPorFormulario.css";

export interface IListFile {
 id: string;
 file:Ifileselect;
 path:File
}

export interface Ifileselect {
    cmd_proid: string | number;
    cmd_cant_archivos: number;
    cmd_cant_bytes_total: number;
    cmd_tipo: string;
    cargaDocumentosItems:Idocumentos[];
    folderId: string;
}

interface Idocumentos{
    cmdi_cant_bytes: number;
    cmdi_doc_uuid: string;
    cmdi_nombre_archivo: string;
    cmdi_content_type: string;
    doctId: string;
    tipo: string;
}

interface FileUploadProps {
  onFileSelect: (file: File) => void;
  disabled:boolean;
}

export interface IDocumento {
  cmd_proid: string | null;
  cmd_cant_archivos: number | null;
  cmd_cant_bytes_total: number | null;
  cmd_tipo: string | null;
  cmd_estado: string | null;
  cmd_fecha_inicio: string | null;
  cmd_id: string | null;
  cmd_carga_colid: string | null;
  cmd_owner_colid: string | null;
  cmd_actid: string | null;
  cmd_liqid: string | null;
  colaborador: Colaborador | null;
  proyecto: Proyecto | null;
  cargaDocumentosItems: CargaDocumentoItem[];
}

interface CargaDocumentoItem {
  cargaDocumentos: any | null;
  documentoXActividad: any | null;
  cmdi_id: string;
  cmdi_cmdid: string;
  cmdi_aws_bucket: string;
  cmdi_aws_key: string;
  cmdi_cant_bytes: number;
  cmdi_codigo_error: string;
  cmdi_content_type: string;
  cmdi_doc_uuid: string;
  cmdi_doctid: string;
  cmdi_dxaid: any | null;
  cmdi_dxliid: any | null;
  cmdi_estado: string;
  cmdi_fecha_aws_fin: any | null;
  cmdi_fecha_fin: any | null;
  cmdi_fecha_ultimo_progreso: any | null;
  cmdi_mensaje_error: string;
  cmdi_nombre_archivo: string;
  cmdi_pcaid: string;
  cmdi_ruta_local: string;
}

interface Colaborador {}

interface Proyecto {}

export interface Iactivities {
  impai_nombre: string;
  impai_cantidad_horas: string;
  impai_codigo_proyecto: string | undefined;
  impai_proid: string | number;
  impai_con_error: boolean;
  impai_descripcion_error: string;
  impai_descripcion: string;
}

const formatDate = (dateString:Date) => {
  const date = new Date(dateString);
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();
  return `${year}-${month}`;
}

const convertMinToHour = (minutes: number): string => {
  const sign = minutes < 0 ? "-" : "";
  const absMinutes = Math.abs(minutes);
  const hours = Math.floor(absMinutes / 60);
  const remainingMinutes = absMinutes % 60;
  const formattedMinutes = remainingMinutes < 10 ? `0${remainingMinutes}` : remainingMinutes;

  return `${sign}${hours}:${formattedMinutes}`;
};

const convertHourToMin = (hourMin: string): number => {
  const [hoursStr, minutesStr] = hourMin.split(':');

  const hours = hoursStr ? parseInt(hoursStr, 10) : 0;
  const minutes = minutesStr === 'undefined' ? 0 : (minutesStr ? parseInt(minutesStr, 10) : 0);

  return hours * 60 + minutes;
};

const FileUpload: React.FC<FileUploadProps> = ({ onFileSelect, disabled = true }) => {
  const [selectedFileName, setSelectedFileName] = useState<string | null>(null);
  const [clicked, setClicked] = useState(false);
  const fileInputRef = React.createRef<HTMLInputElement>();

  const handleClick = () => {
    setClicked(true); 
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files[0]) {
      const file = files[0];
      setSelectedFileName(file.name);
      onFileSelect(file);
    }
  };

  return (
    <DefaultButton onClick={handleClick} disabled={disabled} style={{border:"none"}} >
      <input 
        type="file"
        ref={fileInputRef}
        style={{ display: 'none' }}
        onChange={handleFileChange}
      />
        <TooltipHost content={selectedFileName || "selecione un archivo"}>
        <Icon iconName="CloudUpload" style={{fontSize:"30px",cursor:"pointer"}} 
        className={(clicked && selectedFileName) ? "icon-clicked" : ""} onAnimationEnd={() => setClicked(false)} />
        </TooltipHost>
    </DefaultButton>
  );
};


interface IconPapersProps {
    nameIcon: string;
    size?: string;
    color?: string;
    thickness?: number;
    onClick?: () => void;
  }
  
const IconPapers: React.FC<IconPapersProps> = ({
    nameIcon,
    size = "16px", // default size
    color = "black", // default color
    thickness = 1, // default thickness
    onClick,
  }) => {
    const styles = {
      fontSize: size,
      color: color,
      fontWeight: thickness * 100,
      cursor: onClick ? "pointer" : "default",
    };
  
    return <Icon iconName={nameIcon} style={styles} onClick={onClick} />;
  };

  const LoadingPage = ()=>{
    return (
      <div 
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(255, 255, 255, 0.7)',
        zIndex: 10,
        paddingTop:"10px"
      }}
    >
      <Spinner label="Cargando..." />
    </div>
    );
  }


export { FileUpload, IconPapers ,formatDate,convertMinToHour,convertHourToMin,LoadingPage };