import React, { useEffect, useState } from 'react';
import {
  DefaultButton,
  IButtonStyles,
  IContextualMenuItem,
  IContextualMenuProps,
  Label,
} from '@fluentui/react';
import TimeTaxSupportSystemClient from '../../system';
import MessageCourier from '../../lib/MessageCourier';
import { Text } from '@fluentui/react/lib/Text';
import './DropdownGroupedItem.css';

interface IBasicInputsView {
  system: TimeTaxSupportSystemClient;
  courier: MessageCourier;
  list: any; //La lista con label, value y grupo
  onClick?: Function; //Funcion que se le pasa al clickearlo
  firstGroupShowed?: boolean; //Boolean que muestra el primer grupo sin estar adentro del grupo
  showGroup?: string; // Nombre del grupo que se quiere mostrar sin estar adentro del grupo
  defaultDropdownName?: string; //Texto que aparece en el dropdown
  label?: string;
}

export const DropdownGroup = (props: IBasicInputsView) => {
  //La constante itemList va a ser la lista de items que le van a llegar al componente, por ahora queda como props pero dejo comentado el funcionamiento anterior x las dudas
  //   const itemList = useAsyncDataCourier(() => props.system.getProjects(), [props.system], props.courier);
  const itemList = props.list;

  const [firstGroupShowed, setFirstGroupShowed] = useState(false);
  const [selection, setSelection] = React.useState<{ [key: string]: boolean }>({});
  const [selectName, setSelectName] = useState(
      props.defaultDropdownName ? props.defaultDropdownName : 'Seleccioná'
  );

  useEffect(() => {
    if (props.firstGroupShowed !== false && props.firstGroupShowed !== true) {
      setFirstGroupShowed(true);
    } else {
      setFirstGroupShowed(props.firstGroupShowed);
    }
  }, [props.firstGroupShowed]);

  //Este es el formato de lista que le tiene que llegar al componente
  //   const itemList = [
  //     {
  //       label: 'label1',
  //       value: 'value1',
  //       group: 'group1',
  //       description: 'Esta es una descripcion de prueba',
  //     },
  //     {
  //       label: 'label2',
  //       value: 'value2',
  //       group: 'group1',
  //     },
  //     {
  //       label: 'label3',
  //       value: 'value3',
  //       group: 'group1',
  //     },
  //     {
  //       label: 'label4',
  //       value: 'value4',
  //       group: 'group2',
  //     },
  //     {
  //       label: 'label5',
  //       value: 'value5',
  //       group: 'group2',
  //     },
  //     {
  //       label: 'label6',
  //       value: 'value6',
  //       group: 'group3',
  //     },
  //     {
  //       label: 'label7',
  //       value: 'value7',
  //       group: 'group3',
  //     },
  //   ];

  //Esta es la función que se ejecura en el onClick
  const onToggleSelect = React.useCallback(
      (/*ev?: React.MouseEvent<HTMLButtonElement>, */ item?: IContextualMenuItem): void => {
        // ev && ev.preventDefault();

        if (item) {
          setSelection({
            ...selection,
            [item.key]: selection[item.key] === undefined ? true : !selection[item.key],
          });
          setSelectName(item.text ? item.text : item.key);
          if (props.onClick) {
            props.onClick(item);
          }
        }
      },
      [selection]
  );

  const [groupedItemList, setGroupedItemList] = React.useState<{ [string: string]: any }>([]);
  const [contexttualMenuItems, setContexttualMenuItems] = React.useState<IContextualMenuItem[]>([]);

  //Crea todos los grupos para el dropdown
  React.useEffect(() => {
    let _groupedItemList: any = {};
    if (itemList) {
      let grupo;
      itemList.forEach((item: any) => {
        grupo = item.group;
        if (!_groupedItemList[grupo]) {
          _groupedItemList[grupo] = [];
        }
        _groupedItemList[grupo].push(item);
      });
    }
    setGroupedItemList(_groupedItemList);
  }, []);

  //Lo pone en el formato que pide el componente
  React.useEffect(() => {
    setContexttualMenuItems(generateGroupItems());
  }, [groupedItemList]);

  //Genera que los objetos en el formato que el componente lo precisa y los divide dependiendo si el primer grupo de quiere ver suelto o no
  const generateGroupItems = () => {
    let _groupedItemList = { ...groupedItemList };
    let items: IContextualMenuItem[] = [];
    let groups = Object.keys(_groupedItemList);
    let showedElement = groups[0];
    if (props.showGroup) {
      let idx = groups.indexOf(props.showGroup);
      showedElement = groups[idx];
    }

    if (firstGroupShowed && _groupedItemList && _groupedItemList[showedElement]) {
      _groupedItemList[showedElement].forEach((item: any) => {
        items.push({
          key: item.value,
          text: item.label,
          secondaryText: item.description,
          _onClick: onToggleSelect,
          onRender: (item: any, dismissMenu: (ev?: any, dismissAll?: boolean | undefined) => void) => {
            return (
                <div
                    onClick={() => {
                      onToggleSelect(item);
                      dismissMenu(null, true);
                    }}
                    className={item.secondaryText ? 'principal-item with-secondary' : 'principal-item '}
                >
                  <Text className="d-block">{item.text}</Text>

                  <Text className="d-block secondary-text-dropdown">{item.secondaryText}</Text>
                </div>
            );
          },
        });
      });
    }

    groups.forEach((group: string) => {
      if ((firstGroupShowed && group != showedElement) || (!firstGroupShowed && group)) {
        let itemListByGroup = _groupedItemList[group].map((item: any) => {
          return {
            key: item.value,
            text: item.label,
            secondaryText: item.description,
            _onClick: onToggleSelect,
            onRender: (item: any, dismissMenu: (ev?: any, dismissAll?: boolean | undefined) => void) => {
              return (
                  <div
                      onClick={() => {
                        onToggleSelect(item);
                        dismissMenu(null, true);
                      }}
                      className={item.secondaryText ? 'principal-item with-secondary' : 'principal-item '}
                  >
                    <Text className="d-block">{item.text}</Text>

                    <Text className="d-block secondary-text-dropdown">{item.secondaryText}</Text>
                  </div>
              );
            },
          };
        });

        items.push({
          key: group,
          text: group,
          subMenuProps: { items: itemListByGroup },
        });
      }
    });
    return items;
  };

  const menuProps: IContextualMenuProps = React.useMemo(
      () => ({
        shouldFocusOnMount: true,
        items: contexttualMenuItems,
      }),
      [contexttualMenuItems]
  );

  const stylesDropdownGroupped: IButtonStyles = {
    root: {
      minWidth: '-webkit-fill-available',
      textAlign: 'left',
      padding: '0px 3px 0px 8px',
      fontWeight: 400,
    },
    label: {
      fontWeight: 400,
      marginLeft: 0,
    },
  };

  return (
      <div>
        {props.label && <Label>{props.label}</Label>}
        <DefaultButton styles={stylesDropdownGroupped} text={selectName} menuProps={menuProps} />
      </div>
  );
};
