import React from "react";
import { Button,ComboBox,DatePicker,Icon,TextField, TooltipHost,IDatePickerStrings} from "@fluentui/react";
import "./collaboratorCreateView.css";
import { useEffect} from "react";
import { FieldErrors, FieldValues, UseFormRegister, UseFormSetValue, UseFormWatch, ValidationRule } from "react-hook-form";
import { DateCustom } from "../../../lib/DateCustom";
import { DateTime } from "luxon";
import formatearFecha from "../../../common/formatDate";
import TimeTaxSupportSystemClient from "../../../system";


export const spanishDatePickerStrings: IDatePickerStrings = {
  months: [
    'enero',
    'febrero',
    'marzo',
    'abril',
    'mayo',
    'junio',
    'julio',
    'agosto',
    'septiembre',
    'octubre',
    'noviembre',
    'diciembre',
  ],

  shortMonths: [
    'ene',
    'feb',
    'mar',
    'abr',
    'may',
    'jun',
    'jul',
    'ago',
    'sep',
    'oct',
    'nov',
    'dic',
  ],

  days: [
    'domingo',
    'lunes',
    'martes',
    'miércoles',
    'jueves',
    'viernes',
    'sábado',
  ],

  shortDays: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],

  goToToday: 'Ir a hoy',
  prevMonthAriaLabel: 'Ir al mes anterior',
  nextMonthAriaLabel: 'Ir al mes siguiente',
  prevYearAriaLabel: 'Ir al año anterior',
  nextYearAriaLabel: 'Ir al año siguiente',
  closeButtonAriaLabel: 'Cerrar',
  isRequiredErrorMessage: 'Campo obligatorio',
  invalidInputErrorMessage: 'Formato de fecha no válido.',
};

export interface IHeaderCollaboratorCreate {
  setviewcreate: (data: number) => void;
  setToasError : (data: boolean) => void;
  errors: FieldErrors<FieldValues>
}
export interface IcustomTextFile{
  placeholder:string;
  label:string;
  type:string;
  width:string;
  height:string;
  name:string;
  register: UseFormRegister<FieldValues>;
  minLength?:number | undefined;
  pattern?:ValidationRule<RegExp> | undefined;
  maxLength?:number | undefined;
} 
export interface IInformacionGeneral{
  register: UseFormRegister<FieldValues>;
  errors:FieldErrors<FieldValues>;
  setValue:UseFormSetValue<FieldValues>;
  watch: UseFormWatch<FieldValues>
  system?:TimeTaxSupportSystemClient | undefined;
}

export interface Idirection {
  key: string;
  text: string;
  nor_nivel: string;
  nor_padre: string;
}

export default function HeaderCollaboratorCreate({
  setviewcreate,setToasError,errors
}: IHeaderCollaboratorCreate) {

  const oneMistake = ()=>{
   if(!!errors){
    setToasError(true);
    setTimeout(() => {
      setToasError(false);
    }, 5000); 
   }
  }
  return (
    <div className="collaborator_create_hader_buton">
      <span>
        <Icon
          iconName="ReminderPerson"
          style={{ color: "#0078D4", marginRight: "5px" }}
        />
        <span>Colaboradores</span>
      </span>
      <Button className="Button_view" type="submit" onClick={()=>oneMistake()}>
        <Icon iconName="save" style={{ marginRight: "5px" }} />
        <span>Guardar</span>
      </Button>
      <Button className="Button_back" onClick={() => setviewcreate(0)}>
        <Icon
          iconName="back"
          style={{ color: "#0078D4", marginRight: "3px" }}
        />
        <span>Volver</span>
      </Button>
    </div>
  );
}

export const InformacionGeneral = ({register,errors,setValue}:IInformacionGeneral) => {

  const handleDateChange = (date,name:string) => {
    if(!date)return;
    register(name,{required:true})
    const ddat = formatearFecha(date)
    setValue(name,ddat);
  };
  useEffect(()=>{
    register("selecusuarios",{required:true});
    register("fechaingreso",{required:true});
  },[])
    
  return (
    <div className="form_informacion_general">
      <p className="titulo_form_create">
        <Icon iconName="ContactCard" style={{fontSize:"30px"}} />
        Información general.
      </p>
      <div className="form_informacion_general_cuerpo">
        <CustomTextFile key="001"  width="158px" height="14px" label="Código SAP" 
        placeholder="Escriba el código SAP" type="text" name="codigosap" register={register} pattern={/^\d+$/} maxLength={10}/>
       {errors?.codigosap?.type === "required" && <span className="error_stilos">El código SAP no puede ser vacío.</span>}
       {errors?.codigosap?.type === "pattern" && <span className="error_stilos">Este campo solo permite números.</span>}
       {errors?.codigosap?.type === "maxLength" && <span className="error_stilos">El código SAP no debe contener mas de 10 números.</span>}
        <CustomTextFile key="002"  width="158px" height="14px" label="Documento"
         placeholder="Escriba el documento" type="text" name="dni" register={register}  maxLength={13}  pattern={/^\d+$/} />
        {errors?.dni?.type === "required" && <span className="error_stilos">El documento no puede ser vacío.</span>}
        {errors?.dni?.type === "maxLength" && <span className="error_stilos">El documento no debe contener mas de 13 números.</span>}
       {errors?.dni?.type === "pattern" && <span className="error_stilos">Este campo solo permite números.</span>}

        <CustomTextFile key="003" width="302px" height="14px" label="Nombre"
         placeholder="Escriba el nombre del colaborador" type="text" name="nombre" register={register} 
         pattern={/^[a-zA-Z\u00C0-\u024F\u1E00-\u1EFF]+(?: [a-zA-Z\u00C0-\u024F\u1E00-\u1EFF]+)*$/} maxLength={150}/>
        {errors?.nombre?.type === "required" && <span className="error_stilos">El nombre del colaborador no puede ser vacío.</span>}
        {errors?.nombre?.type === "pattern" && <span className="error_stilos">El nombre no debe contener números.</span>}
        {errors?.nombre?.type === "maxLength" && <span className="error_stilos">El nombre no debe contener mas de 150 caracteres.</span>}

        <CustomTextFile key="004" width="302px" height="14px" label="Apellidos" 
        placeholder="Escriba los apellidos del colaborador" type="text" name="apellido" register={register}
        pattern={/^[a-zA-Z\u00C0-\u024F\u1E00-\u1EFF]+(?: [a-zA-Z\u00C0-\u024F\u1E00-\u1EFF]+)*$/} maxLength={150}/>
        {errors?.apellido?.type === "required" && <span className="error_stilos">El apellido no puede ser vacío.</span>}
        {errors?.apellido?.type === "pattern" && <span className="error_stilos">El apellido no debe contener números.</span>}
        {errors?.apellido?.type === "maxLength" && <span className="error_stilos">El apellido no debe contener mas de 150 caracteres.</span>}

        <CustomTextFile key="005" width="302px" height="14px" label="Email" placeholder="Escriba el email del colaborador" 
        type="text" name="email" register={register} pattern={/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/} maxLength={150}/>
        {errors?.email?.type === "required" && <span className="error_stilos" >El email no puede ser vacío.</span>}
        {errors?.email?.type === "pattern" && <span className="error_stilos" >Ingresa un email válido.</span>}
        {errors?.email?.type === "maxLength" && <span className="error_stilos">El email no debe contener mas de 150 caracteres.</span>}

        <div style={{display:"flex", flexDirection:"column"}}>
             <span className="date_derecho_form">
              <DatePicker strings={spanishDatePickerStrings} allowTextInput formatDate={DateCustom.formatDateForDatePicker} label="Fecha de Ingreso" className="label_reusable" 
              placeholder="Seleccione la fecha de ingreso"
              styles={{root:{width:"220px"}}}  onSelectDate={ (date)=>handleDateChange(date,"fechaingreso")}/>
              {errors.fechaingreso && <span className="error_stilos_practicante">La fecha de ingreso no puede ser vacío.</span>}
             </span>
             <span className="date_derecho_form">
              <TooltipHost content={"Seleccione el tipo de usuario"}>
              <ComboBox
              className="label_reusable"
              placeholder="Seleccione el tipo de usuario."
              label="Seleccione el tipo de usuario"
              options={[{ key: "00000005", text: "User" },
              {key:"00000006",text:"Power User"},{key:"00000007",text:"Admin User"},{key:"00000010",text:"Directivo"} ]}
              styles={{root:{width:"220px"}}}
              onChange={(event,option)=>setValue("selecusuarios",option) }
              />
              </TooltipHost>
              {errors.selecusuarios && <span className="error_stilos_practicante" >El tipo de usuario no puede ser vacío.</span>}
              </span>
        </div>
      </div>
    </div>
  );
};


export function CustomTextFile ({placeholder,label,type,width,height, name,register,
  minLength=undefined,pattern=undefined,maxLength=undefined}:IcustomTextFile){
  return (
    <span className="input_reusable">
      <TextField
      styles={{root:{width:width, height:height}}}
        placeholder={placeholder}
        label={label}
        type={type}
       {...register(name,{required:true,minLength,pattern,maxLength})}
      />
    </span>
  );
} 

export function getCurrentDateTime() {
  // Obtiene la fecha y hora actual en la zona horaria local
  return DateTime.local().toJSDate();
}