import React, { useState, useMemo } from 'react';
import { CARD_MODE, GRID_MODE, OPERATORS, TYPES } from '../../common/list-utils';
import { ListColumnFilter } from '../Common/list-entities/ListColumnFilter';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../common/constants';
import DialogTemplate from '../Common/DialogTemplate';
// import './MailList.css';
import TimeTaxSupportSystemClient from '../../system';
import { Mail } from '../../system/mail/Mail';
import useAsyncDataCourier from '../../common/custom-hooks/useAsyncDataCourier';
import MessageCourier from '../../lib/MessageCourier';
import { useTranslation, Trans } from 'react-i18next';
import { User } from '../../system/User';
import {
  ContextualMenu,
  Dialog,
  DialogType,
  IComboBoxOption,
  IDropdownOption,
  Text,
  Icon,
  PrimaryButton,
} from '@fluentui/react';

interface IMailDialog {
  showDialog: boolean;
  onClose: () => void;
  mail: Mail;
  system: TimeTaxSupportSystemClient;
  canUnassign?: boolean;
  nameProject?: string;
  companyProjectId?: string;
}

export const MailDialog = (props: IMailDialog) => {
  const mail = React.useMemo(() => {
    return props.mail;
  }, []);

  const { t, i18n } = useTranslation();

  const onDownload = async () => {
    try {
      const downloadUrl = await props.system.getMailSystem().getDownloadLinkFor(mail);
      window.open(downloadUrl, '_blank');
    } catch (e) {
      console.error(e);
    }
  };

  const unassignMailFromProjectCompany = () => {
    try {
      alert(
          'Implementar borrado para el email ' +
          props.mail.getId() +
          ' y para la empresa: ' +
          props.companyProjectId
      );
    } catch (e) {
      console.error(e);
    }
  };

  return (
      <DialogTemplate
          showDialog={props.showDialog}
          toggleShowDialog={() => props.onClose()}
          title={t('Asunto: ') + mail.getSubject()}
          onAccept={() => props.onClose()}
          onCancel={() => unassignMailFromProjectCompany()}
          acceptText={t('Entendido')}
          cancelText={t('Eliminar del proyecto') + ' ' + props.nameProject}
          hideCancelButton={!props.canUnassign}
          minWidth={800}
      >
        <div>
          <Text block variant="medium" className="fw-500 mt-2">
            <Icon iconName="Send" className="text-icon"></Icon>
            {t('Enviado por: ')}
            <span className="fw-400">{mail.getFrom()}</span>
            <PrimaryButton className="float-right" onClick={onDownload} iconProps={{ iconName: 'Download' }}>
              {t('Descargar')}
            </PrimaryButton>
          </Text>
          <Text block variant="medium" className="fw-500 mt-2">
            <Icon iconName="SendMirrored" className="text-icon"></Icon>
            {t('Recibido por:')}{' '}
            {mail.getTo().map((to, i) => {
              return (
                  <span className="fw-400" key={i}>
                {to}
                    {'; '}
              </span>
              );
            })}
          </Text>
          <Text block variant="medium" className="fw-500 mt-2">
            <Icon iconName="Calendar" className="text-icon"></Icon>
            {t('Enviado el: ')}
            <span className="fw-400">{mail.getDate().toFormat('dd/LL/yyyy HH:mm')}</span>
          </Text>
          <Text block variant="medium" className="fw-500 mt-2">
            <Icon iconName="EditMail" className="text-icon"></Icon>
            {t('Cuerpo: ')}
            <span className="fw-400">{mail.getBody()}</span>
          </Text>
          <Text block variant="medium" className="fw-500 mt-2">
            <Icon iconName="Attach" className="text-icon"></Icon>
            {t('Documentos adjuntos:')}{' '}
            {mail.getDocsFilenames().length > 0 ? (
                <>
                  {mail.getDocsFilenames().map((dfilename) => {
                    return <span className="fw-400 link-styles">{dfilename}</span>;
                  })}
                </>
            ) : (
                <span className="fw-400">{t('No tiene')}</span>
            )}
          </Text>
        </div>
      </DialogTemplate>
  );
};
