import {
  Facepile,
  IColumn,
  Icon,
  IDropdownOption,
  IFacepilePersona,
  Persona,
  PersonaSize,
} from '@fluentui/react';
import { GRID_MODE } from '../../../common/list-utils';
import { DateCustom } from '../../../lib/DateCustom';
// import { DetailsListView, EntityGridListProps } from '../../../Common/DetailsList/DetailListViewiew';
import { useTranslation, Trans } from 'react-i18next';
import { DetailsListView, EntityGridListProps } from '../../Common/DetailsList/DetailListView';
import { DateTime } from 'luxon';
import React from 'react';

export const LicensesGridList = (props: EntityGridListProps) => {
  const { t, i18n } = useTranslation();

  const formatActive = (item: any) => {
    if (item.collic_activo === true) {
      return <Icon iconName="CheckMark" className="color-success" />;
    } else {
      return <Icon iconName="Cancel" className="color-error" />;
    }
  };

  const formatVigencia = (item: any) => {
    return (
        <span>
        {DateTime.fromFormat(item.collic_inicio, 'dd/LL/yyyy').toFormat('dd/LL/yyyy')} -{' '}
          {DateTime.fromFormat(item.collic_fin, 'dd/LL/yyyy').toFormat('dd/LL/yyyy')}
      </span>
    );
  };

  const formatImporte = (item: any) => {
    return <span>${item.liq_importe_total}</span>;
  };

  const formatIsActive = (item: any) => {
    if (item.collic_activo) {
      return <Icon iconName="CheckMark" className="success-color text-icon text-center"></Icon>;
    }
    return <Icon iconName="Cancel" className="error-color text-icon text-center"></Icon>;
  };

  const data = [
    { lat: 37.7749, lng: -122.4194, value: 5 },
    { lat: 34.0522, lng: -118.2437, value: 10 },
    // Agrega más puntos de datos aquí
  ];

  const center: [number, number] = [0, 0];
  const zoom = 2;

  const columns: IColumn[] = React.useMemo(
      () => [
        {
          key: 'lictipo_descripcion',
          fieldName: 'lictipo_descripcion',
          name: t('Tipo de licencia'),
          //   _onRender: (item: any) => formatImporte(item),
          maxWidth: 100,
          minWidth: 100,
        },

        {
          key: 'collic_motivo',
          fieldName: 'collic_motivo',
          name: t('Motivo'),
          //   _onRender: (item: any) => formatActivo(item),
          maxWidth: 150,
          minWidth: 150,
        },

        // {
        //   key: 'sector',
        //   fieldName: 'sector',
        //   name: t('Sector'),
        //   maxWidth: 150,
        //   minWidth: 150,
        // },
        {
          key: 'collic_inicio',
          fieldName: 'collic_inicio',
          name: t('Vigencia'),
          maxWidth: 130,
          minWidth: 130,
          _onRender: (item: any) => formatVigencia(item),
        },
        // {
        //   key: 'collic_fin',
        //   fieldName: 'collic_fin',
        //   name: t('Fin'),
        //   maxWidth: 70,
        //   minWidth: 70,
        //   // _onRender: (item: any) => formatVigencia(item),
        // },
        {
          key: 'collic_dias_lic',
          fieldName: 'collic_dias_lic',
          name: t('Días de licencia'),
          //   _onRender: (item: any) => formatImporte(item),
          maxWidth: 20,
          minWidth: 20,
        },
        {
          key: 'collic_activo',
          fieldName: 'collic_activo',
          name: t('Activa'),
          maxWidth: 20,
          minWidth: 20,
          _onRender: formatActive,
        },
        {
          key: 'col_nombre_completo',
          fieldName: 'col_nombre_completo',
          name: t('Colaborador'),
          maxWidth: 150,
          minWidth: 150,
        },
      ],
      [t]
  );
  return (
      <>
        <DetailsListView
            changeBlockUI={props.changeBlockUI}
            keyList={props.keyList}
            onClickCardMode={() => props.onClickCardMode()}
            onClickGridMode={() => props.onClickGridMode()}
            mode={props.mode}
            title={t('Licencias')}
            // virtualize={true}
            cols={columns}
            isGridList={true}
            courier={props.courier}
            listFilters={[]}
            quickFilters={props.quickFilters}
            onItemClick={props.onItemClick}
            baseUrl={props.baseUrl}
        ></DetailsListView>
      </>
  );
};
