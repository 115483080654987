import { IComboBox, IComboBoxOption, ComboBox } from '@fluentui/react';
import React from 'react';
import { CallableFn } from './CustomInput';
import CustomInputIfc from './CustomInputIfc';
import { useTranslation, Trans } from 'react-i18next';

interface IComboBoxInput {
  input: CustomInputIfc;
  onChangeExtended?: (input: CustomInputIfc) => void;
  onBlur: (input: CustomInputIfc) => void;
  label: string;
  options: IComboBoxOption[];
  clearAfterSelection?: boolean;
  getItemFromKey?: (key: string | number) => any;
}

export const ComboBoxInput = (props: IComboBoxInput) => {
  const { t, i18n } = useTranslation();

  const [updateFlag, setUpdateFlag] = React.useState(false);

  const updateViewAfterDoing = (anAction: CallableFn) => {
    setUpdateFlag((u) => {
      anAction();
      return !u;
    });
  };

  const onBlurCustomInput = () => {
    updateViewAfterDoing(() => {
      props.input.onBlur();
      props.onBlur(props.input);
    });
  };

  const [selectedKey, setSelectedKey] = React.useState<string | number | undefined>(undefined);
  const [options, setOptions] = React.useState(props.options);
  const [textDropDown, setTextDropdown] = React.useState<string>('');
  // const [menuClosed, setMenuClosed] = React.useState(true);

  const onInputValueChange = (value: string) => {
    setTextDropdown(value);
    setOptions(props.options.filter((op) => op.text.toLocaleLowerCase().includes(value.toLocaleLowerCase())));
  };

  const onChangeCustomInput = (
      event: React.FormEvent<IComboBox>,
      option?: IComboBoxOption,
      index?: number,
      value?: string
  ) => {
    if (option) {
      let key = option.key;
      updateViewAfterDoing(() => {
        props.input.setValue(props.getItemFromKey ? props.getItemFromKey(key!) : option!.data);
      });
      if (props.onChangeExtended) {
        props.onChangeExtended(props.input);
      }
      setSelectedKey(key);
      // setOptions(props.options.filter(op => op !== option)) Ya lo hace solo, el tema es que lo hace tras cambiar el input, Al limpiar el input deberia funcionar bien
      if (props.clearAfterSelection) {
        // Limpiar el textfield
        // usar onItemClick?
        setTextDropdown('');
        // setSelectedKey(undefined);
      }
      // LINEA COMENTADA PORQUE TIRA ERROR AL USAR COMBOBOX, NO NOTO CAMBIO EN FUNCIONAMIENTO SIN ESTO
      // if (event?.currentTarget) event.currentTarget.focus(true, false);
    }
  };

  return (
      <>
        <ComboBox
            selectedKey={selectedKey}
            label={t(props.label)}
            text={textDropDown}
            autoComplete="off"
            // onMenuDismissed={() => setMenuClosed(true)}
            // onMenuOpen={() => setMenuClosed(false)}
            allowFreeform
            openOnKeyboardFocus={true}
            options={options}
            onInputValueChange={onInputValueChange}
            onChange={onChangeCustomInput}
            onBlur={(ev) => onBlurCustomInput()}
        />
        {/* <h5>{menuClosed ? 'presione enter o la flechita para abrir las opciones' : ''}</h5> */}
      </>
  );
};

export default ComboBoxInput;
