import TimeTaxSupportSystemClient from '../../../system';
import { DateTime } from 'luxon';
import MessageCourier from '../../../lib/MessageCourier';
import { IReporteHorasCumplimientoGeneral } from '../../../system/reporting/ReportingSystem';
import { useTranslation } from 'react-i18next';
import DataGrid, {
  Scrolling,
  Column,
  Summary,
  SortByGroupSummaryInfo,
  Export,
  SearchPanel,
  TotalItem,
} from 'devextreme-react/data-grid';

import { User } from '../../../system/User';
import { EscalableExporterOptions, exportToExcel } from '../../../lib/Util';
import Button from 'devextreme-react/button';

interface IReportingSettlementHoursSector {
  system: TimeTaxSupportSystemClient;
  courier: MessageCourier;
  changeBlockUI: (state: boolean) => void;
  user: User;
  items: IReporteHorasCumplimientoGeneral[];
  from: DateTime;
  to: DateTime;
  resetTableAfterExport: () => void;
}

export const ReportHoursSectorCumplimientoGeneral = (props: IReportingSettlementHoursSector) => {
  const { t, i18n } = useTranslation();

  const onExportingCumplimientoGeneral = async (e: any) => {
    let options: EscalableExporterOptions = {
      fileName: `cumplimiento-general-de-horas-sector-de-${props.from?.toFormat(
          'dd-LL-yyyy'
      )}-a-${props.to?.toFormat('dd-LL-yyyy')}`,
      sheetName: t('Cumplimiento general de horas por sector'),
    };
    await exportToExcel(e, options);
    props.resetTableAfterExport();
  };

  return (
      <>
        <Button
            className="btn-dinamic-table"
            icon="help"
            hint="Si la cantidad de colaboradores es mayor a la esperada, probablemente se
          debe a colaboradores con más de un cargo. Los números son relativos a la cantidad de cargos distintos."
        />
        <DataGrid
            height={620}
            width="100%"
            keyExpr={'direccion'}
            dataSource={props.items}
            showBorders={true}
            wordWrapEnabled={true}
            onExporting={onExportingCumplimientoGeneral}
        >
          <SearchPanel visible={true} placeholder={t('Buscar...')} />
          <Scrolling mode="virtual" />
          <Column dataField="direccion" width={650} caption="Direccion" />
          <Column dataField="cant_colaboradores" caption="# Colaboradores" />
          <Column dataField="horas_cargadas_totales" caption="Horas confirmadas" />
          <Column dataField="horas_esperadas_totales" caption="Horas esperadas" />
          <Column dataField="porcentaje_cumplimiento" caption="% de cumplimiento" />
          <Summary>
            <TotalItem
                column="cant_colaboradores"
                summaryType="sum"
                valueFormat={(d) => parseFloat(d)}
                displayFormat={`{0}`}
            />

            <TotalItem
                column="horas_cargadas_totales"
                summaryType="sum"
                valueFormat={(d) => parseFloat(d).toFixed(2)}
                displayFormat={`{0}`}
            />
            <TotalItem
                column="horas_esperadas_totales"
                summaryType="sum"
                valueFormat={(d) => parseFloat(d).toFixed(2)}
                displayFormat={`{0}`}
            />
          </Summary>
          <SortByGroupSummaryInfo sortOrder={'desc'} summaryItem="sum" />
          <Export enabled={true} />
        </DataGrid>
      </>
  );
};
