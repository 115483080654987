import React from 'react';
import { IStackStyles, IStackTokens, Stack, Text, Link, Icon } from '@fluentui/react';
import { useNavigate } from 'react-router-dom';

// import logo from '../logo.svg';
import { boldStyle } from './Common/TextStyles';
import News from './News';
import { APP_NAME, routes } from '../common/constants';
import { useTranslation } from 'react-i18next';

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import tutorialsJSON from '../tutorialVideos/tutorials.json';
import PanelVerticalStackedBarChart from './Panel/PanelCollaborators/PanelVerticalStackedBarChart';
import MessageCourier from '../lib/MessageCourier';
import TimeTaxSupportSystemClient from '../system';
import { User } from '../system/User';
import useAsyncDataCourier from '../common/custom-hooks/useAsyncDataCourier';
import PanelProjectsVerticalStackedBarChart from './Panel/PanelCollaborators/PanelProjectsVerticalStackedBarChart';
import { PillFluent } from './Common/PillFluent';
import { AlertPillFluent } from './Common/AlertPillFluent';
import { DateTime } from 'luxon';
import { DateCustom } from '../lib/DateCustom';

const stackTokens: IStackTokens = { childrenGap: 15 };

const mainStackStyles: Partial<IStackStyles> = {
  root: {
    width: '100%',
    height: '100%',
    margin: '0 auto',
    textAlign: 'center',
    color: '#605e5c',
  },
};

interface IHome {
  system: TimeTaxSupportSystemClient;
  courier: MessageCourier;
  changeBlockUI: (change: boolean) => void;
  from?: string | null | undefined;
  to?: string | null | undefined;
  user: User;
}

const Home = (props: IHome) => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const [openNewsDialog, setOpenNewsDialog] = React.useState(false);

  const getTutorialVideos = () => {
    let tutorialJSON = tutorialsJSON;

    tutorialJSON = JSON.parse(JSON.stringify(tutorialJSON));

    return tutorialJSON.tutorials.map((tutorial) => {
      return (
          <div className="carousel-item" id={tutorial.id}>
            <span className="tutorial-item-title">{t(tutorial.title)}</span>
            <Link onClick={() => navigate(`${routes.help}?video=${tutorial.id}`)}>
              <img className="img-fluid carousel-img" src={tutorial.image} alt="" />
            </Link>
          </div>
      );
    });
  };

  React.useEffect(() => {
    getTutorialVideos();
  }, []);

  const collaboratorPanel = useAsyncDataCourier(
      () => {
        const panelSystem = props.system.getPanelSystem();
        return panelSystem.getMyPanelData(
            DateTime.fromJSDate(DateCustom.getFechaOneMonthAgo()),
            DateTime.fromJSDate(new Date())
        );
      },
      [props.system],
      props.courier,
      props.changeBlockUI
  );

  const collaboratorActivityStatus = useAsyncDataCourier(
      () => {
        const panelSystem = props.system.getPanelSystem();
        let id = props.user.getCollaboratorId();
        return panelSystem.getCollaboratorActivityStatus(id);
      },
      [props.system],
      props.courier,
      props.changeBlockUI
  );

  const pendingDocuments = () => {
    const projectList = collaboratorActivityStatus?.estadoDocumentos;
    let pendingDocs = 0;

    collaboratorActivityStatus?.estadoDocumentos.forEach((item) => {
      pendingDocs += item.cant_pendientes;
    });

    return pendingDocs;
  };

  const expiredDocuments = () => {
    const projectList = collaboratorActivityStatus?.estadoDocumentos;
    let expiredDocs = 0;

    collaboratorActivityStatus?.estadoDocumentos.forEach((item) => {
      expiredDocs += item.cant_vencidos;
    });

    return expiredDocs;
  };

  return (
      <div className="ms-Grid" dir="ltr">
        <News openNewsDialog={openNewsDialog} setOpenNewsDialog={setOpenNewsDialog}></News>
        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-sm12">
            <div className="ms-Grid-row mt-1 mb-1 text-center">
              <Text className="mb-2 d-block" variant="xxLarge" styles={boldStyle}>
                {t('Bienvenido a')} {APP_NAME}
                {/* <img src="/images/logo-tts.png" alt="" className="logo-home"></img> */}
              </Text>
              <Text
                  variant="large"
                  className={'d-block'}
                  style={{ marginBottom: '25px', marginTop: '0', fontSize: '17px' }}
              >
                {t(
                    'Una aplicación que te ayudará a organizar tu trabajo y guardar toda la documentación de sustento para servicios intragrupo'
                )}
              </Text>
            </div>

            <div className="ms-Grid-row mt-1 mb-1">
              <div className="ms-Grid-col ms-sm12">
                <div className="ms-Grid-col ms-sm12">
                  <Text variant="mediumPlus" className={'d-block text-center'}>
                    <Icon iconName="BIDashboard" className="text-icon "></Icon>
                    {t('Aquí hay un breve resumen de tu estado de actividad dentro de la aplicación')}
                  </Text>
                </div>
              </div>
            </div>
            <div
                className="ms-Grid-row mt-1 mb-1"
                style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}
            >
              <div className="ms-Grid-col ms-xl6 ms-lg12 ms-sm12">
                {collaboratorActivityStatus && (
                    <PanelVerticalStackedBarChart data={collaboratorActivityStatus} user={props.user} />
                )}
              </div>

              <div className="ms-Grid-col ms-xl3 ms-lg3 ms-sm12">
                {collaboratorActivityStatus ? (
                    <PanelProjectsVerticalStackedBarChart data={collaboratorActivityStatus} />
                ) : null}
              </div>

              <div className="ms-Grid-col ms-xl3 ms-lg9 ms-sm12">
                {collaboratorPanel && expiredDocuments() === 0 ? (
                    <div className="ms-Grid-col ms-sm12">
                      <PillFluent
                          title={t('Docs. pendientes')}
                          iconTitle={'ProtectedDocument'}
                          series={[
                            {
                              label: t('Docs. obligatorios pendientes'),
                              value: pendingDocuments(),
                              icon: 'ProtectedDocument',
                            },
                          ]}
                          small={false}
                      ></PillFluent>
                      <div className="text-center mt-1">
                        {pendingDocuments() > 0 && (
                            <Text variant="medium" className="fw-500 error-color link-styles">
                              <Icon iconName="AlertSolid" className="text-icon"></Icon>
                              {t('Tienes documentos obligatorios vencidos')}
                            </Text>
                        )}

                        {pendingDocuments() === 0 && (
                            <Text variant="medium" className="fw-500">
                              <Icon iconName="CheckMark" className="text-icon success-color"></Icon>
                              {t('Estas al día con tu proyecto')}
                            </Text>
                        )}
                      </div>
                    </div>
                ) : null}
                {collaboratorPanel && expiredDocuments() > 0 ? (
                    <div className="ms-Grid-col ms-sm12">
                      <AlertPillFluent
                          title={t('Docs. vencidos')}
                          iconTitle={'AlertSolid'}
                          series={[
                            {
                              label: t('Docs. obligatorios vencidos'),
                              value: expiredDocuments(),
                              icon: 'Cancel',
                            },
                          ]}
                          small={false}
                      ></AlertPillFluent>
                    </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        <div></div>
        <hr></hr>
        <div className="ms-Grid-row mt-1 mb-1" style={{ paddingBottom: '30px' }}>
          <div className="ms-Grid-col ms-sm6 text-center">
            <Text variant="mediumPlus" className={'d-block'}>
              <Icon iconName="Education" className={'text-icon'} />

              {t('Para tu comodidad creamos tutoriales para ')}
            </Text>
            <Text variant="mediumPlus" className={'d-block'}>
              {t('conocer como funciona la aplicación')}
            </Text>
            <br></br>
            <div className="ms-Grid-col ms-sm12">
              <OwlCarousel className="owl-theme" autoplay loop margin={10} nav items={1}>
                {getTutorialVideos()}
              </OwlCarousel>
            </div>
          </div>

          <div className="ms-Grid-col ms-sm6 text-center">
            <Text variant="mediumPlus" className={'d-block'}>
              {t('Tambien generamos accesos rapidos ')}
            </Text>
            <Text variant="mediumPlus" className={'d-block mb-1'}>
              {t('para ir a las secciones principales')}
            </Text>
            <br></br>
            <div className="ms-Grid-col ms-sm6 text-center">
              <Text variant="large" className="title-third-panel">
                <Icon iconName="Share" className={'text-icon'} />
                {t('Principales accesos')}
              </Text>
              <div className="ms-Grid-row mt-1">
                <Link className="d-block m-auto mb-1" onClick={() => navigate(routes.calendar)}>
                  <Icon iconName="Calendar" className={'text-icon'} />

                  {t('Calendario')}
                </Link>
                <Link className="d-block m-auto mb-1" onClick={() => navigate(routes.project_list)}>
                  <Icon iconName="TeamWork" className={'text-icon'} />

                  {t('Proyectos')}
                </Link>
                <Link className="d-block m-auto mb-1" onClick={() => navigate(routes.licenses)}>
                  <Icon iconName="EventDateMissed12" className={'text-icon'} />

                  {t('Licencias')}
                </Link>
                <Link className="d-block m-auto mb-1" onClick={() => setOpenNewsDialog(true)}>
                  <Icon iconName="News" className={'text-icon'} />

                  {t('Ultimas novedades')}
                </Link>
              </div>
            </div>
            <div className="ms-Grid-col ms-sm6 text-center">
              <Text variant="large" className="title-third-panel">
                <Icon iconName="Lightbulb" className={'text-icon'} />

                {t('Ayuda')}
              </Text>
              <div className="ms-Grid-row mt-1 mb-1">
                <a
                    className="link-styles d-block mb-1"
                    href="https://youtu.be/eTqSWFiBo04"
                    target="_blank"
                    rel="noreferrer"
                >
                  {t('Registro de un proyecto')}
                </a>
                <a
                    className="link-styles d-block mb-1"
                    href="https://drive.google.com/file/d/1A8OnQoD1eLaRWcR2GdkRVVw4qZzQZ6Yq/view"
                    target="_blank"
                    rel="noreferrer"
                >
                  {t('Registro de actividades')}
                </a>
                <a
                    className="link-styles d-block mb-1"
                    href="https://youtu.be/eU_jHukjj9Q"
                    target="_blank"
                    rel="noreferrer"
                >
                  {t('Subida de documentación')}
                </a>
                <a
                    className="link-styles d-block mb-1"
                    href="https://youtu.be/ZEG3auntWK8"
                    target="_blank"
                    rel="noreferrer"
                >
                  {t('Uso del complemento')}
                </a>
              </div>
            </div>
            <div className="ms-Grid-col ms-sm12 text-center mt-2">
              <Text variant="large" className="title-third-panel">
                <Icon iconName="BIDashboard" className={'text-icon'} />
                {t('Reportes')}
              </Text>
              <div className="ms-Grid-row mt-1">
                <Link className="d-inline ml-1 mr-1" onClick={() => navigate(routes.dashboard_own)}>
                  {t('Mi panel')}
                </Link>
              </div>
            </div>
          </div>
        </div>

        {/* <Stack
        horizontalAlign="center"
        verticalAlign="center"
        verticalFill
        styles={mainStackStyles}
        tokens={stackTokens}
      >
        <Text className="mb-2" variant="xxLarge" styles={boldStyle}>
          {t('Bienvenido a')} {APP_NAME}
        </Text>
        <Text variant="medium" className={'text-principal'} style={{ marginBottom: '40px', marginTop: '0' }}>
          {t(
            'Una aplicación que te ayudará a organizar tu trabajo y guardar toda la documentación de sustento para servicios intragrupo'
          )}
        </Text>
        <Text variant="xLarge" styles={boldStyle} style={{ fontSize: '24px' }}>
          <Icon iconName="AlertSolid" className={'text-icon'} />
          {t('Tutorial de uso')}
        </Text>
        <Text variant="medium" className={'text-principal'}>
          {t('Para tu comodidad creamos tutoriales para ')}
          <span style={{ fontWeight: 'bold' }}>{t('conocer como funciona la aplicación')}</span>
        </Text>
        <br></br>
        <a
          className="link-styles"
          style={{ marginTop: '5px', fontSize: '20px', marginBottom: '5px' }}
          href="https://drive.google.com/file/d/1Qb0y2rBy_A3xf4hPj5xZSo5qsyUxaBaO/view"
          target="_blank"
          rel="noreferrer"
        ></a>
        <div className="ms-Grid-col ms-sm7">
          <OwlCarousel className="owl-theme" autoplay loop margin={10} nav items={1}>
            {getTutorialVideos()}
          </OwlCarousel>
        </div>
        <Text variant="large" styles={boldStyle}>
          {t('Principales accesos')}
        </Text>
        <Stack horizontal tokens={stackTokens} horizontalAlign="center">
          <Link onClick={() => navigate(routes.calendar)}>{t('Calendario')}</Link>
          <Link onClick={() => navigate(routes.project_list)}>{t('Proyectos')}</Link>
          <Link onClick={() => navigate(routes.activity_list)}>{t('Actividades')}</Link>
          <Link onClick={() => navigate(routes.documents)}>{t('Documentos')}</Link>
          <Link onClick={() => setOpenNewsDialog(true)}>{t('Ultimas novedades')}</Link>
        </Stack>
        <Text variant="large" styles={boldStyle}>
          {t('Reportes')}
        </Text>
        <Stack horizontal tokens={stackTokens} horizontalAlign="center">
          <Link onClick={() => navigate(routes.dashboard)}>{t('Mi panel')}</Link>
          <Link onClick={() => navigate(`${routes.reports}/empresas`)}>{t('Reportes generales')}</Link>
        </Stack>
        <Text variant="large" styles={boldStyle}>
          {t('Ayuda')}
        </Text>
        <Stack horizontal tokens={stackTokens} horizontalAlign="center">
          <a className="link-styles" href="https://youtu.be/eTqSWFiBo04" target="_blank" rel="noreferrer">
            {t('Registro de un proyecto')}
          </a>
          <a
            className="link-styles"
            href="https://drive.google.com/file/d/1A8OnQoD1eLaRWcR2GdkRVVw4qZzQZ6Yq/view"
            target="_blank"
            rel="noreferrer"
          >
            {t('Registro de actividades')}
          </a>
          <a className="link-styles" href="https://youtu.be/eU_jHukjj9Q" target="_blank" rel="noreferrer">
            {t('Subida de documentación')}
          </a>
          <a className="link-styles" href="https://youtu.be/ZEG3auntWK8" target="_blank" rel="noreferrer">
            {t('Uso del complemento')}
          </a>
        </Stack>
      </Stack> */}
      </div>
  );
};

export default Home;
