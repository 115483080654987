import * as React from 'react';
import TimeTaxSupportSystemClient from '../../../system';
import { Collaborator } from '../../../system/collaborators/Collaborator';
import {
  ComboBox,
  CommandBarButton,
  DefaultButton,
  IComboBox,
  IComboBoxOption,
  IDropdownOption,
  PrimaryButton,
} from 'office-ui-fabric-react';
import { DateTime } from 'luxon';
import { DatePicker, IIconProps, IStackStyles } from '@fluentui/react';
import { DateCustom } from '../../../lib/DateCustom';
import MessageCourier from '../../../lib/MessageCourier';
import useAsyncDataCourier from '../../../common/custom-hooks/useAsyncDataCourier';
import { ALL_SECTORS, OrganizationChartDropdown } from '../../Common/OrganizationChartDropdown';
import { User } from '../../../system/User';
import { OrganizationChartNode } from '../../../system/organization_chart/OrganizationChartNode';
import { useTranslation, Trans } from 'react-i18next';
import { getLocalizedStrings } from '../../../lib/LocalizedStringsForDatePicker';

interface ICompaniesPanelSelector {
  onClickSearch: () => void;
  system: TimeTaxSupportSystemClient;
  courier: MessageCourier;
  onChangeCompanyId: (companies: string[]) => void;
  onChangeOrganizationNodeId: (id: string) => void;
  onChangeFromDate: (date: DateTime) => void;
  onChangeToDate: (date: DateTime) => void;
  user: User;
  useAllSector?: boolean;
}

export const PanelCompaniesSelector = (props: ICompaniesPanelSelector) => {
  const { t, i18n } = useTranslation();
  const [companyIdSelected, setCompanyIdSelected] = React.useState<string[]>([]);
  const [fromDate, setFromDate] = React.useState<DateTime | undefined>(undefined);
  const [toDate, setToDate] = React.useState<DateTime | undefined>(undefined);
  const [shouldRefresh, setShouldRefresh] = React.useState<boolean>(false);

  const nodeOfLoggedCol = useAsyncDataCourier(
      () => props.system.getOrganizationChartSystem().getNodeOf(props.user.getCollaborator()),
      [props.system, props.user],
      props.courier
  );

  const companies = useAsyncDataCourier(
      () => props.system.getCompanySystem().getList(),
      [props.system],
      props.courier
  );

  const nodes = useAsyncDataCourier(
      () => props.system.getOrganizationChartSystem().getAvailableNodesOf(props.user),
      [props.system, props.user],
      props.courier
  );

  React.useEffect(() => {
    const setDefaultValues = async () => {
      setFromDate(DateTime.fromJSDate(DateCustom.getFechaOneMonthAgo()));
      props.onChangeFromDate(DateTime.fromJSDate(DateCustom.getFechaOneMonthAgo()));
      setToDate(DateTime.fromJSDate(new Date()));
      props.onChangeToDate(DateTime.fromJSDate(new Date()));
      const nodeOfColLogged = await props.system
          .getOrganizationChartSystem()
          .getNodeOf(props.user.getCollaborator());
      props.onChangeOrganizationNodeId(nodeOfColLogged.getId());
    };
    setDefaultValues();
  }, []);

  const companyOptions: IDropdownOption[] = React.useMemo(() => {
    let opt: IDropdownOption[] = [];

    if (companies) {
      companies.forEach((empresa) => {
        let ret: IDropdownOption = {
          key: empresa.getId(),
          text: empresa.getName(),
        };
        opt.push(ret);
      });
    }
    return opt;
  }, [companies]);

  React.useEffect(() => {
    props.onChangeCompanyId(companyIdSelected);
  }, [props, companyIdSelected]);

  const onSelectCompany = React.useCallback(
      (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string): void => {
        let selected = option?.selected;
        if (option) {
          setCompanyIdSelected((prevSelectedKeys) =>
              selected
                  ? [...prevSelectedKeys, option!.key as string]
                  : prevSelectedKeys.filter((k) => k !== option!.key)
          );
        }
      },
      []
  );

  const onChangeDate = (date: Date | null | undefined, name: string) => {
    if (date) {
      if (name === 'from') {
        setFromDate(DateTime.fromJSDate(date));
        props.onChangeFromDate(DateTime.fromJSDate(date));
        setShouldRefresh(!shouldRefresh);
        if (props.onChangeFromDate) props.onChangeFromDate(DateTime.fromJSDate(date));
      }
      if (name === 'to') {
        setToDate(DateTime.fromJSDate(date));
        props.onChangeToDate(DateTime.fromJSDate(date));
        setShouldRefresh(!shouldRefresh);
        if (props.onChangeToDate) props.onChangeToDate(DateTime.fromJSDate(date));
      }
    }
  };

  const onClearFilters = () => {
    setCompanyIdSelected([]);
    props.onChangeCompanyId([]);
    props.onChangeOrganizationNodeId('');
  };

  const FilterClearIcon: IIconProps = { iconName: 'ClearFilter' };
  const stackStyles: Partial<IStackStyles> = {
    root: { height: 32, fontSize: 12, paddingLeft: 0, marginRight: '10px' },
  };

  const buttonStyles: Partial<IStackStyles> = {
    root: { height: 32, fontSize: 12 },
  };

  const setDateLastMonth = () => {
    setFromDate(DateTime.fromJSDate(DateCustom.getFechaOneMonthAgo()));
    props.onChangeFromDate(DateTime.fromJSDate(DateCustom.getFechaOneMonthAgo()));
    setToDate(DateTime.fromJSDate(new Date()));
    props.onChangeToDate(DateTime.fromJSDate(new Date()));
  };

  const setDateLastSemester = () => {
    setFromDate(DateTime.fromJSDate(DateCustom.getFechaSixMonthsAgo()));
    props.onChangeFromDate(DateTime.fromJSDate(DateCustom.getFechaSixMonthsAgo()));
    setToDate(DateTime.fromJSDate(new Date()));
    props.onChangeToDate(DateTime.fromJSDate(new Date()));
  };

  const setDateLastYear = () => {
    setFromDate(DateTime.fromJSDate(DateCustom.getFechaInitLastYear()));
    setToDate(DateTime.fromJSDate(DateCustom.getFechaEndLastYear()));
    props.onChangeToDate(DateTime.fromJSDate(DateCustom.getFechaInitLastYear()));
    props.onChangeFromDate(DateTime.fromJSDate(DateCustom.getFechaEndLastYear()));
  };

  return (
      <div className="ms-Grid" dir="ltr">
        {nodes && nodeOfLoggedCol && (
            <div className="ms-Grid-row mt-12">
              <div className="ms-Grid-col ms-sm12" style={{ display: 'flex', alignItems: 'flex-end' }}>
                <CommandBarButton
                    onClick={onClearFilters}
                    className="float-right-media bg-transparent"
                    allowDisabledFocus
                    styles={stackStyles}
                    iconProps={FilterClearIcon}
                    text={t('Limpiar')}
                />
              </div>
              <>
                <div className="ms-Grid-col ms-sm2 ">
                  <DatePicker
                      strings={getLocalizedStrings()}
                      label={t('Filtrar desde')}
                      formatDate={DateCustom.formatDate}
                      value={fromDate ? fromDate.toJSDate() : undefined}
                      onSelectDate={(e) => onChangeDate(e, 'from')}
                      className=""
                  />
                </div>
                <div className="ms-Grid-col ms-sm2 ">
                  <DatePicker
                      strings={getLocalizedStrings()}
                      label={t('Filtrar hasta')}
                      formatDate={DateCustom.formatDate}
                      value={toDate ? toDate.toJSDate() : undefined}
                      onSelectDate={(e) => onChangeDate(e, 'to')}
                      className=""
                  />
                </div>
              </>
              <div className="ms-Grid-col ms-sm3 ">
                <ComboBox
                    multiSelect
                    label={t('Empresas')}
                    onChange={onSelectCompany}
                    selectedKey={companyIdSelected}
                    options={companyOptions}
                />
              </div>
              <div className="ms-Grid-col ms-sm3 ">
                <OrganizationChartDropdown
                    options={nodes || []}
                    label={t('Sector')}
                    onChange={(node) => {
                      props.onChangeOrganizationNodeId(node.getId());
                    }}
                    defaultSelectedKey={nodeOfLoggedCol?.getId()}
                    useAllSector={props.useAllSector && props.user.hasPowerUserPrivileges()}
                    onSelectAll={() => props.onChangeOrganizationNodeId(ALL_SECTORS)}
                />
              </div>
              <div className="ms-Grid-col ms-sm2 ">
                <PrimaryButton
                    iconProps={{ iconName: 'Search' }}
                    className="mt-for-btn-search ml-2"
                    onClick={props.onClickSearch}
                    text={t('Buscar')}
                />
              </div>
            </div>
        )}
        <div className="ms-Grid-row mt-12">
          <div className="d-inline ml-8px">
            <DefaultButton
                onClick={setDateLastMonth}
                allowDisabledFocus
                styles={buttonStyles}
                text={t('Último mes')}
            />
          </div>
          <div className="d-inline ml-8px">
            <DefaultButton
                onClick={setDateLastSemester}
                allowDisabledFocus
                styles={buttonStyles}
                text={t('Último semestre')}
            />
          </div>
          <div className="d-inline ml-8px">
            <DefaultButton
                onClick={setDateLastYear}
                allowDisabledFocus
                styles={buttonStyles}
                text={t('Año anterior')}
            />
          </div>
        </div>
      </div>
  );
};
