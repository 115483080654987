import * as React from 'react';
import { Dialog, DialogType, DialogFooter } from '@fluentui/react/lib/Dialog';
import { PrimaryButton, DefaultButton } from '@fluentui/react/lib/Button';
import { ContextualMenu, IContextualMenuProps } from '@fluentui/react/lib/ContextualMenu';
import { Text, Icon, IconButton, IIconProps, Link, TextField } from '@fluentui/react';
import { useConst } from '@fluentui/react-hooks';
import TimeTaxSupportSystemClient from '../../system';
import MessageCourier from '../../lib/MessageCourier';
import { DateTime } from 'luxon';
import DocumentUploadControl, {
  DocumentUploadControlSaved,
} from '../../system/documents/DocumentUploadControl';
import CollaboratorPersona from '../Collaborator/CollaboratorPersona';
import SystemDocumentType from '../../system/documents/SystemDocumentType';
import { Toggle } from '@fluentui/react';
import { useTranslation, Trans } from 'react-i18next';

interface IDocumentControlDialog {
  showDialog: boolean;
  toggleShowDialog: () => void;
  goToConfigProject: () => void;
  documentsControlList: DocumentUploadControlSaved[];
  system: TimeTaxSupportSystemClient;
  courier: MessageCourier;
  saveDocumentForDocumentControl: (docControlId: string) => void;
  documentSelectedName: string;
  documentSelectedType: SystemDocumentType;
  onChangeTypeForDocumentControlSelected: (newDocumentType: any) => void;
  controlDocument?: DocumentUploadControlSaved;
  backToControlDocsSection: () => void;
}

const DocumentControlDialog = (props: IDocumentControlDialog) => {
  const { t, i18n } = useTranslation();
  const [showDocumentTypeChange, setShowDocumentTypeChange] = React.useState(false);
  const [showDocumentConfirmation, setShowDocumentConfirmation] = React.useState(false);

  const [docAssignmentConfirmationText, setDocAssignmentConfirmationText] = React.useState('');

  const [errorValidacion, setErrorValidacion] = React.useState('');

  const [documentControlSelected, setDocumentControlSelected] = React.useState<
      DocumentUploadControlSaved | undefined
  >(undefined);

  const dragOptions = {
    moveMenuItemText: t('Move'),
    closeMenuItemText: t('Close'),
    menu: ContextualMenu,
  };

  const modalProps = {
    isBlocking: true,
    // styles: modalPropsStyles,
    dragOptions: dragOptions,
  };

  const dialogContentProps = React.useMemo(() => {
    return {
      type: DialogType.normal,
      title: t('Documentos obligatorios'),
    };
  }, [props]);

  const openDocumentTypeChange = (docControl: DocumentUploadControlSaved) => {
    if (docControl.getDocumentType().getId() === props.documentSelectedType.getId()) {
      setShowDocumentConfirmation(true);
      setShowDocumentTypeChange(false);
      setDocumentControlSelected(docControl);
      // props.saveDocumentForDocumentControl(docControl.getId());
    } else {
      setShowDocumentTypeChange(true);
      setShowDocumentConfirmation(false);
      setDocumentControlSelected(docControl);
    }
  };

  React.useEffect(() => {
    if (props.showDialog) {
      if (props.controlDocument) {
        openDocumentTypeChange(props.controlDocument);
      }
    }
  }, [props.showDialog, props.controlDocument]);

  const toggleShowDocumentConfirmation = () => {
    setShowDocumentConfirmation(!showDocumentConfirmation);
  };

  const toggleShowDocumentTypeChange = () => {
    setShowDocumentTypeChange(!showDocumentTypeChange);
  };

  const confirmDocAssignment = async (docControl: DocumentUploadControlSaved) => {
    if (errorValidacion === '') {
      if (docControl.getDocumentType().getId() === props.documentSelectedType.getId()) {
        props.saveDocumentForDocumentControl(docControl.getId());
      } else {
        setNewDocumentType(docControl.getDocumentType(), docControl.getId());
      }
      toggleShowDocumentConfirmation();
    }
  };

  const setNewDocumentType = async (docType: SystemDocumentType, id: string) => {
    await props.onChangeTypeForDocumentControlSelected(docType);
    props.saveDocumentForDocumentControl(id);
  };

  const onChangeDocAssignmentConfirmationText = React.useCallback(
      (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        if (!newValue || newValue.length <= 50) {
          setDocAssignmentConfirmationText(newValue || '');
        }
      },
      []
  );

  const validateConfirmation = () => {
    if (docAssignmentConfirmationText === t('CONFIRMO AUTENTICIDAD DEL DOCUMENTO')) {
      setErrorValidacion('');
    } else {
      setErrorValidacion(t("Texto de validación debe decir 'CONFIRMO AUTENTICIDAD DEL DOCUMENTO'"));
    }
  };

  const [seeAll, setSeeAll] = React.useState(false);

  return (
      <>
        <Dialog
            hidden={!props.showDialog}
            onDismiss={props.toggleShowDialog}
            dialogContentProps={dialogContentProps}
            modalProps={modalProps}
            minWidth={900}
        >
          <div className="ms-Grid">
            <div className="ms-Grid-row">
              <div className="ms-Grid-col ms-sm12">
                <Text variant="mediumPlus">{t('Selecciona a que documento obligatorio corresponde')}</Text>
              </div>
              <div className="ms-Grid-col ms-sm12">
                <Text variant="medium">
                <span className="fw-500">
                  {props.documentSelectedType.getName()} - {props.documentSelectedName}
                </span>
                </Text>
              </div>
            </div>

            {props.documentsControlList.length === 0 && (
                <div className="ms-Grid-row mt-2">
                  <div className="ms-Grid-col ms-sm12">
                    <Text variant="mediumPlus">
                  <span className="">
                    {t('Tu proyecto no tiene ningún documento obligatorio.')}{' '}
                    <Link onClick={props.goToConfigProject}>
                      {t('¿Quieres cargar un documento obligatorio?')}
                    </Link>
                  </span>
                    </Text>
                  </div>
                </div>
            )}
            {props.documentsControlList.length !== 0 && (
                <div className="ms-Grid-row mt-2">
                  <div className="ms-Grid-col ms-sm12">
                    <Toggle
                        checked={seeAll}
                        offText={
                            t('Viendo documentos obligatorios solo de tipo ') + props.documentSelectedType.getName()
                        }
                        onText={t('Viendo documentos obligatorios de cualquier tipo')}
                        onChange={(ev, checked) => setSeeAll(!seeAll)}
                    />
                  </div>
                </div>
            )}

            {props.documentsControlList.map((docControl, i) => {
              if (
                  seeAll ||
                  (!seeAll && docControl.getDocumentType().getId() === props.documentSelectedType.getId())
              ) {
                return (
                    <div key={i}>
                      <hr></hr>
                      <div className="ms-Grid-row mt-2">
                        <div className="ms-Grid-col ms-sm12">Concepto: {docControl.getConcept()}</div>
                        <div className="ms-Grid-col ms-sm12">
                          {t('Fecha límite: ')}
                          {docControl.getLimitDate().toFormat('dd/LL/yyyy')}
                        </div>
                        <div className="ms-Grid-col ms-sm12">Tipo: {docControl.getDocumentType().getName()}</div>
                        <div className="ms-Grid-col ms-sm12 mt-1">
                          <div className="ms-Grid" dir="ltr">
                            <div className="ms-Grid-row">
                              <div className="ms-Grid-col ms-sm6 ">
                                <CollaboratorPersona
                                    collaborator={docControl.getCollaborator()}
                                ></CollaboratorPersona>
                              </div>

                              <div className="ms-Grid-col ms-sm6">
                                <PrimaryButton
                                    text={t('Asignar')}
                                    onClick={() => openDocumentTypeChange(docControl)}
                                    iconProps={{ iconName: 'TextDocument' }}
                                    className="primary-button mt-1 float-right"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                );
              }
            })}
          </div>

          {documentControlSelected && (
              <Dialog
                  hidden={!showDocumentTypeChange}
                  onDismiss={toggleShowDocumentTypeChange}
                  dialogContentProps={dialogContentProps}
                  modalProps={modalProps}
                  minWidth={500}
              >
                <Text variant="mediumPlus">
                  {t('Los tipos de documentos no son iguales ¿Quieres cambiar el tipo?')}
                </Text>
                <DialogFooter>
                  <PrimaryButton
                      onClick={() => {
                        // setNewDocumentType(
                        //   documentControlSelected.getDocumentType(),
                        //   documentControlSelected.getId()
                        // );
                        toggleShowDocumentTypeChange();
                        setShowDocumentConfirmation(true);
                      }}
                      text={t('Cambiar')}
                  />
                  <DefaultButton
                      onClick={() => {
                        toggleShowDocumentTypeChange();
                        setDocumentControlSelected(undefined);
                      }}
                      text={t('Cancelar')}
                  />
                </DialogFooter>
              </Dialog>
          )}

          {documentControlSelected && (
              <Dialog
                  hidden={!showDocumentConfirmation}
                  onDismiss={toggleShowDocumentConfirmation}
                  dialogContentProps={dialogContentProps}
                  modalProps={modalProps}
                  minWidth={500}
              >
                <Text variant="mediumPlus">
                  {t('Por favor confirme la asignación a este documento obligatorio')}
                </Text>
                <TextField
                    value={docAssignmentConfirmationText}
                    placeholder={t('CONFIRMO AUTENTICIDAD DEL DOCUMENTO')}
                    autoFocus
                    onChange={onChangeDocAssignmentConfirmationText}
                    onBlur={validateConfirmation}
                    errorMessage={errorValidacion}
                    className='mt-2'
                />
                <DialogFooter>
                  <PrimaryButton
                      onClick={() => {
                        confirmDocAssignment(documentControlSelected);
                      }}
                      text={t('Asignar')}
                  />
                  <DefaultButton
                      onClick={() => {
                        toggleShowDocumentConfirmation();
                        setDocumentControlSelected(undefined);
                      }}
                      text={t('Cancelar')}
                  />
                </DialogFooter>
              </Dialog>
          )}

          <DialogFooter>
            <DefaultButton className="mr-5" onClick={props.toggleShowDialog} text={t('Cancelar')} />
          </DialogFooter>
        </Dialog>
      </>
  );
};

export default DocumentControlDialog;
