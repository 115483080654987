import i18next, { t } from 'i18next';
import React, { useEffect, useState, useMemo } from 'react';
import { Text } from '@fluentui/react/lib/Text';
import { PrimaryButton } from 'office-ui-fabric-react';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../../common/constants';

interface IGenerateSettlement {
  massiveRemunResponseList: any;
}

const UpdatedTemplate = (props: IGenerateSettlement) => {
  const navigate = useNavigate();
  const response = () => {
    let cantOk = props.massiveRemunResponseList.cant_ok;
    let cantError = props.massiveRemunResponseList.cant_error;
    // window.location.assign(uri);
    return (
        t('De los ') +
        (cantOk + cantError) +
        t(' costos por hora, se importaron ') +
        cantOk +
        t(' de manera exitosa') +
        (cantError > 0 ? t(', y ') + cantError + t(' con error.') : '')
    );
  };
  return (
      <div>
        {/* <Text>Selecciona la empresa usuaria a la que se ha liquidado, el período y el tipo de defensa</Text> */}
        <div className="ms-Grid mt-2" dir="ltr">
          <div className="ms-Grid-row">
            <div className="ms-Grid-col  ms-sm6">
              <div className="ms-Grid-col  ms-sm12 mt-1">
                <Text>{t('Los costos de hora de los colaboradores han sido actualizados.')}</Text>
                <br />
                <div className="mt-2">
                  <Text>{response()}</Text>
                </div>
                <div className="mt-2">
                  <PrimaryButton text={t('Finalizar')} onClick={() => navigate(routes.cost_per_hour_list)} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
};

export default UpdatedTemplate;
