import { ProjectCompanyListForm } from '../../Components/Proyectos/ProjectConfiguration/ProjectConfigurationDistributionForm';
import { Company } from '../company/Company';
import { ProjectCompanyJson } from './ProjectCompany';

export abstract class ProjectCompanyInDistribution {
  abstract getCompany(): Company;
  abstract getPercentage(): number;
  abstract asJson(): ProjectCompanyJson;
  companyIs(aCompany: Company): boolean {
    return aCompany.isIdentifiedAs(this.getCompany());
  }

  IsForSameCompanyAs(anotherProjectCompany: ProjectCompanyInDistribution): boolean {
    return anotherProjectCompany.companyIs(this.getCompany());
  }

  abstract collectCompanyInto(list: Company[]): void;
  abstract delete(list: ProjectCompanyListForm): void;
  abstract addTo(list: ProjectCompanyListForm, projectCompany: ProjectCompanyInDistribution): void;
  abstract affectTotalPercentage(partialSum: number): number;
  abstract setPercentage(percentage: number): void;
  abstract isActive(): boolean;
}
