class LazyInitialization<T> {
  private initialized: boolean;
  private cachedValue: T | undefined;
  private loadingPromise: Promise<T> | undefined;

  constructor(private initializationClosure: () => Promise<T>) {
    this.initialized = false;
    this.loadingPromise = undefined;
    this.cachedValue = undefined;
  }

  async value(reload = false): Promise<T> {
    this.forceReload(reload);
    await this.initialize();

    return this.cachedValue!;
  }

  reload() {
    this.loadingPromise = undefined;
    return this.load();
  }

  withCachedValueDo<S>(successClosure: (data: T) => S, failClosure: () => S): S {
    if (!this.initialized) return failClosure();

    return successClosure(this.cachedValue!);
  }

  private forceReload(reload: boolean) {
    if (reload) {
      this.initialized = false;
      this.loadingPromise = undefined;
    }
  }

  private async initialize() {
    if (this.initialized) return;
    await this.load();
    this.initialized = true;
  }

  private async load() {
    if (!this.loadingPromise) {
      this.loadingPromise = this.initializationClosure();
    }

    this.cachedValue = await this.loadingPromise;
  }
}

export default LazyInitialization;
