import React from 'react';
import {
  DetailsList,
  Text,
  DefaultPalette,
  SelectionMode,
  DetailsListLayoutMode,
  IColumn,
  Icon,
  Toggle,
  DetailsRow,
  IDetailsListProps,
  DefaultButton,
  DatePicker,
} from '@fluentui/react';

import { ICommandBarItemProps } from '@fluentui/react/lib/CommandBar';

import ViewWrapperWithCommandBar from '../Layout/ViewWrapperWithCommandBar';
import { titleCommandBarButtonStyles } from '../../styles/button-styles';

import TimeTaxSupportSystemClient from '../../system';
import MessageCourier from '../../lib/MessageCourier';
import PlainFileDropzone from '../FileDropzone/PlainFileDropzone';
import * as XLSX from 'xlsx';
import { DateTime } from 'luxon';
import useAsyncDataCourier from '../../common/custom-hooks/useAsyncDataCourier';
import { User } from '../../system/User';
import { Collaborator } from '../../system/collaborators/Collaborator';
import { PrimaryButton } from 'office-ui-fabric-react';
import useSystemCall from '../../common/custom-hooks/useSystemCall';
import CollaboratorSelectorList from '../Collaborator/CollaboratorSelectorList';
import { EditActivityAssistantDialog } from './EditActivityAssistantDialog';
import Project from '../../system/projects/Project';
import { useTranslation } from 'react-i18next';
import { getLocalizedStrings } from '../../lib/LocalizedStringsForDatePicker';
import { DateCustom } from '../../lib/DateCustom';

interface IMassiveActivitiesUploadAssistant {
  system: TimeTaxSupportSystemClient;
  courier: MessageCourier;
  user: User;
  changeBlockUI: (change: boolean) => void;
}

interface ActivityFromExcelForm {
  date: string;
  timeRange: string;
  hours: string;
  name: string;
  projectName: string;
  projectCode: string;
  [index: string]: any;
}

export interface ActivityFromExcelFormToPost {
  impai_id?: string | null;
  impai_nombre: string;
  impai_inicio: string;
  impai_fin: string;
  impai_codigo_proyecto: string;
  impai_descripcion: string;
  impai_con_error: boolean;
  impai_descripcion_error?: string;
  impai_proid: string;
  impai_actid?: string | null;
}

export const MassiveActivitiesUploadAssistant = (props: IMassiveActivitiesUploadAssistant) => {
  const { t, i18n } = useTranslation();
  const KEY_DATE = 0;
  const KEY_TIME_INIT = 1;
  const KEY_DURATION_IN_HOURS = 2;
  const KEY_ACTIVITY_NAME = 3;
  const KEY_DESCRIPTION = 4;
  const KEY_PROJECT_CODE = 5;
  const KEY_PROJECT_NAME = 6;

  // const DATE_FORMAT_PROVIDED_FROM_EXCEL = 'L/d/yy H:mm';
  const DATE_FORMAT_PROVIDED_FROM_EXCEL = 'yyyy-LL-dd H:mm';
  const DATE_FORMAT_DMY = 'dd/LL/yyyy HH:mm';
  const RANGE_TO = 'to';
  const RANGE_FROM = 'from';

  const EXCEL_RANGE_TOP_RIGHT = 'C10';
  const EXCEL_RANGE_BOTTOM_LEFT = 'J511';

  const [collaboratorAssigned, setCollaboratorAssigned] = React.useState<Collaborator | undefined>(
      props.user.getCollaborator()
  );
  const [period, setPeriod] = React.useState<Date>(DateCustom.getFirstDayOfThisMonth());

  const projects = useAsyncDataCourier(
      () =>
          collaboratorAssigned
              ? props.system.getAllProjectsByCollaborator(collaboratorAssigned)
              : Promise.resolve([]),
      [props.system, collaboratorAssigned],
      props.courier
  );

  const commonContext = useSystemCall(props.system, (system) =>
      system.getActivitySystem().getCommonContext()
  );

  const [showOk, setShowOk] = React.useState<boolean>(false);
  const [showDialog, setShowDialog] = React.useState<boolean>(false);
  const [shouldRevalidate, setShouldRevalidate] = React.useState<boolean>(false);
  const [idxRowSelected, setIdxRowSelected] = React.useState<number | undefined>(undefined);
  const [activitySelected, setActivitySelected] = React.useState<ActivityFromExcelFormToPost | undefined>(
      undefined
  );
  const [activitiesToPost, setActivitiesToPost] = React.useState<ActivityFromExcelFormToPost[]>([]);
  const [fileName, setFileName] = React.useState<string>('');

  const itemsMenu = React.useMemo(
      (): ICommandBarItemProps[] => [
        {
          key: 'title',
          text: t('Importador de actividades desde planilla Excel'),
          iconProps: {
            iconName: 'ExcelLogo',
            color: DefaultPalette.themeLighterAlt,
            className: 'text-title-btn',
          },
          buttonStyles: titleCommandBarButtonStyles,
          className: 'text-title-btn',
        },
      ],
      []
  );

  const onDropFiles = (files: File[]): Promise<void> => {
    const f = files[0];
    var name = f.name;
    setFileName(name);
    const reader = new FileReader();
    reader.onload = async (evt) => {
      if (evt && evt.target) {
        const bstr = evt.target.result;
        const wb = XLSX.read(bstr, { type: 'binary' });

        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];

        ws['!ref'] = `${EXCEL_RANGE_TOP_RIGHT}:${EXCEL_RANGE_BOTTOM_LEFT}`;
        const data = XLSX.utils.sheet_to_json<ActivityFromExcelForm>(ws, {
          blankrows: false,
          raw: false,
        });

        let _activitiesToPost: ActivityFromExcelFormToPost[] = [];

        for (const act of data) {
          let keys: string[] = Object.keys(act);

          if (keys.length > 3) {
            let timeTo = act[keys[KEY_TIME_INIT]];
            let durationInHours = act[keys[KEY_DURATION_IN_HOURS]];
            let date = act[keys[KEY_DATE]].padStart(2, '0');

            let strDate = `${period.getFullYear()}-${String(period.getMonth() + 1).padStart(2, '0')}-${date}`;
            console.log('Armado  de fecha  > ', `${strDate} ${timeTo}`);
            let dateStart = DateTime.fromFormat(`${strDate} ${timeTo}`, DATE_FORMAT_PROVIDED_FROM_EXCEL);

            let dateStartString = dateStart.toFormat(DATE_FORMAT_DMY);
            let dateEnd = dateStart.toJSDate();
            dateEnd.setTime(dateStart.toJSDate().getTime() + durationInHours * 60 * 60 * 1000);
            let dateEndString = DateTime.fromJSDate(dateEnd).toFormat(DATE_FORMAT_DMY);

            let project: Project | undefined = undefined;
            if (projects) {
              project = projects.find(
                  (pro) =>
                      pro.getCode() === act[keys[KEY_PROJECT_CODE]] ||
                      pro.getCode() === act[keys[KEY_DESCRIPTION]]
              );
            }

            let act_for_post: ActivityFromExcelFormToPost = {
              impai_nombre: act[keys[KEY_ACTIVITY_NAME]],
              impai_inicio: dateStartString,
              impai_fin: dateEndString,
              impai_codigo_proyecto: project ? project.getCode() : '',
              impai_proid: project ? project.getId() : '',
              impai_con_error: false,
              impai_descripcion_error: '-',
              impai_descripcion: act[keys[KEY_DESCRIPTION]],
            };
            await hasError(act_for_post);

            _activitiesToPost.push(act_for_post);
          }
        }

        setActivitiesToPost(_activitiesToPost);
      }
    };
    reader.readAsBinaryString(f);

    return Promise.resolve();
  };
  const columns: IColumn[] = React.useMemo(
      () => [
        {
          key: 'impai_nombre',
          name: t('Nombre actividad'),
          fieldName: 'impai_nombre',
          minWidth: 350,
          maxWidth: 350,
          className: 'fs-for-small-pill mt-2px',
        },

        {
          key: 'impai_inicio',
          name: t('Inicio'),
          fieldName: 'impai_inicio',
          minWidth: 150,
          maxWidth: 150,
          className: 'fs-for-small-pill mt-2px',
        },
        {
          key: 'impai_fin',
          name: t('Fin'),
          fieldName: 'impai_fin',
          minWidth: 150,
          maxWidth: 150,
          className: 'fs-for-small-pill mt-2px',
        },
        {
          key: 'impai_codigo_proyecto',
          name: t('Código de proyecto'),
          fieldName: 'impai_codigo_proyecto',
          minWidth: 200,
          maxWidth: 200,
          className: 'fs-for-small-pill mt-2px',
        },
        {
          key: 'impai_con_error',
          name: t('Válida'),
          fieldName: 'impai_con_error',
          minWidth: 50,
          maxWidth: 50,
          className: 'fs-for-small-pill mt-2px',
          onRender: (item: any) => formatIsValid(item),
        },
        {
          key: 'impai_descripcion_error',
          name: t('Descripción del error'),
          fieldName: 'impai_descripcion_error',
          minWidth: 150,
          maxWidth: 150,
          className: 'fs-for-small-pill mt-2px',
        },
      ],
      []
  );

  const hasError = async (item: ActivityFromExcelFormToPost) => {
    item.impai_con_error = false;
    item.impai_descripcion_error = '';

    if (projects) {
      let start = DateTime.fromFormat(item.impai_inicio, DATE_FORMAT_DMY);
      let end = DateTime.fromFormat(item.impai_fin, DATE_FORMAT_DMY);

      if (start >= end) {
        // Si las fechas no estan ordenadas
        item.impai_descripcion_error = t('Las fechas no están en orden');
        item.impai_con_error = true;
      }

      if (!item.impai_nombre) {
        item.impai_descripcion_error = t('La actividad no tiene nombre');
        item.impai_con_error = true;
      }

      let project = projects.find((pro) => pro.getCode() === item.impai_codigo_proyecto);
      if (project) {
        let _projectTeam = project.getProjectTeam();
        let _collaboratorTeamList = [..._projectTeam.getCollaboratorTeamList()];
        let colIsInTeam = _collaboratorTeamList.find(
            (col) => collaboratorAssigned && collaboratorAssigned.getId() === col.getCollaborator().getId()
        );
        if (!colIsInTeam) {
          item.impai_con_error = true;
          item.impai_descripcion_error = t('No es parte del equipo');
        }

        if (
            !(
                project.getStart() <= start &&
                project.getEnd() >= start &&
                project.getStart() <= end &&
                project.getEnd() >= end
            )
        ) {
          item.impai_descripcion_error = t('Fuera de rango del proyecto');
          item.impai_con_error = true;
        }
      } else {
        item.impai_con_error = true;
        item.impai_descripcion_error = t('No se encontro el proyecto');
      }
    }
  };

  React.useEffect(() => {
    const rehasError = async () => {
      let _activitiesToPost = [...activitiesToPost];

      for (let act of _activitiesToPost) {
        await hasError(act);
      }
      setActivitiesToPost(_activitiesToPost);
    };
    rehasError();
  }, [shouldRevalidate]);

  const formatIsValid = (item: any) => {
    if (!item.impai_con_error) {
      return <Icon iconName="CheckMark" className="success-color text-icon text-center"></Icon>;
    }
    return <Icon iconName="Cancel" className="error-color text-icon text-center"></Icon>;
  };

  const onChangeCollaboratorSelected = async (col: Collaborator) => {
    setCollaboratorAssigned(col);
    setShouldRevalidate(!shouldRevalidate);
  };

  const selectRow = (idx: number) => {
    setIdxRowSelected(idx);
    setActivitySelected(activitiesToPost[idx]);
    setShowDialog(true);
  };

  React.useEffect(() => {
    if (!showDialog) {
      setIdxRowSelected(undefined);
      setActivitySelected(undefined);
    }
  }, [showDialog]);

  const _onRenderRow: IDetailsListProps['onRenderRow'] = (props) => {
    if (props) {
      return (
          <div
              onClick={() => {
                selectRow(props.itemIndex);
              }}
          >
            <DetailsRow {...props} />
          </div>
      );
    }
    return null;
  };

  const onEdit = () => {
    if (activitySelected) {
      let _activitySelected = { ...activitySelected };
      let _activitiesToPost = [...activitiesToPost];
      if (idxRowSelected !== undefined) {
        _activitySelected.impai_inicio = startSavedInstance;
        _activitySelected.impai_fin = endSavedInstance;
        _activitiesToPost[idxRowSelected] = _activitySelected;
      }
      setActivitiesToPost(_activitiesToPost);
      setShouldRevalidate(!shouldRevalidate);
      setShowDialog(false);
    }
  };

  const importActivities = async () => {
    if (collaboratorAssigned) {
      try {
        props.changeBlockUI(true);

        let _activitiesHoursISO = activitiesToPost.map((act) => {
          let _dateStartISO = DateTime.fromFormat(act.impai_inicio, DATE_FORMAT_DMY).toUTC().toISO();
          let _dateEndISO = DateTime.fromFormat(act.impai_fin, DATE_FORMAT_DMY).toUTC().toISO();
          return {
            ...act,
            impai_inicio: String(_dateStartISO),
            impai_fin: String(_dateEndISO),
          };
        });
        let activitiesSaved = await props.system
            .getActivitySystem()
            .saveFromExcelForm(_activitiesHoursISO, collaboratorAssigned.getId());
        let cantOk = activitiesSaved.filter((act) => act.impai_actid != null).length;
        let cantError = activitiesSaved.filter((act) => act.impai_actid == null).length;
        setActivitiesToPost(activitiesSaved);
        if (cantError == cantOk + cantError) {
          props.courier.messageError(t('No se pudo importar ninguna actividad'));
        } else {
          props.courier.messageSuccess(
              t('De las ') +
              (cantOk + cantError) +
              t(' actividades, se importaron ') +
              cantOk +
              t(' de manera exitosa') +
              (cantError > 0 ? t(', y ') + cantError + t(' con error') : '')
          );
        }
      } catch (error) {
        props.courier.messageError(error);
      }
    }
    props.changeBlockUI(false);
  };

  const [startSavedInstance, setStartSavedInstance] = React.useState<string>('');
  const [endSavedInstance, setEndSavedInstance] = React.useState<string>('');

  const resetImport = () => {
    setStartSavedInstance('');
    setEndSavedInstance('');
    setShowOk(false);
    setShowDialog(false);
    setShouldRevalidate(false);
    setIdxRowSelected(undefined);
    setActivitySelected(undefined);
    setActivitiesToPost([]);
    setFileName('');
  };

  const downloadModelExcel = async () => {
    props.changeBlockUI(true);
    if (collaboratorAssigned) {
      try {
        let uri = props.system
            .getActivitySystem()
            .getDownloadModelExcelURI(collaboratorAssigned.getId(), period);
        // window.location.assign(uri);
        window.open(uri, '_blank');

        props.courier.messageSuccess(t('Se ha descargado la planilla modelo con éxito'));
      } catch (error) {
        props.courier.messageError(error);
      }
    }
    props.changeBlockUI(false);
  };

  return (
      <div>
        {' '}
        <ViewWrapperWithCommandBar title="" items={itemsMenu} withBorderBottom>
          <div className="padding-1">
            <div className="ms-Grid" dir="ltr">
              <div className="ms-Grid-row ">
                <div className="ms-Grid-col ms-md4 ">
                  {commonContext && (
                      <CollaboratorSelectorList
                          list={commonContext.colaboradores.sort((a, b) =>
                              a.fullName().localeCompare(b.fullName())
                          )}
                          default={props.user.getCollaborator()}
                          disabled={!props.user.hasPowerUserPrivileges()}
                          onChangeSelected={(col) => (col ? onChangeCollaboratorSelected(col) : null)}
                      ></CollaboratorSelectorList>
                  )}
                </div>

                <div className="ms-Grid-col ms-md2 ">
                  {commonContext && (
                      <DatePicker
                          strings={getLocalizedStrings()}
                          label={t('Período')}
                          formatDate={DateCustom.formatDateForDatePicker_MMYYYY}
                          value={period}
                          onSelectDate={(e) => {
                            if (e) {
                              let _date = new Date(e.getFullYear(), e.getMonth(), 1);
                              setPeriod(_date);
                            }
                          }}
                          className=""
                          calendarProps={{ isDayPickerVisible: false }}
                      />
                  )}
                </div>

                <div className="ms-Grid-col ms-md6 " style={{ marginTop: '27px' }}>
                  <DefaultButton
                      iconProps={{ iconName: 'Download' }}
                      className="float-right ml-2 fw-500"
                      onClick={downloadModelExcel}
                  >
                    {t('Descargar plantilla Excel')} ({DateTime.fromJSDate(period).toFormat('LL/yyyy')})
                  </DefaultButton>

                  <PrimaryButton
                      iconProps={{ iconName: 'ExcelLogo' }}
                      className="float-right ml-2"
                      disabled={activitiesToPost.filter((act) => !act.impai_con_error).length === 0}
                      onClick={importActivities}
                      title={
                        activitiesToPost.filter((act) => !act.impai_con_error).length === 0
                            ? t('No puedes importar porque no tienes actividades válidas')
                            : ''
                      }
                  >
                    {t('Importar')}
                  </PrimaryButton>
                  {fileName && (
                      <DefaultButton
                          iconProps={{ iconName: 'Cancel' }}
                          className="float-right"
                          onClick={resetImport}
                      >
                        {t('Cancelar importación')}
                      </DefaultButton>
                  )}
                </div>
              </div>

              <div className="ms-Grid-row mt-2 ">
                <div className="ms-Grid-col ms-md6 ">
                  <Text variant="medium">{t('Arrastra y suelta el archivo de excel a importar aquí')}</Text>
                </div>
              </div>
              <div className="ms-Grid-row mt-1 ">
                <div className="ms-Grid-col ms-md6 ">
                  <Text variant="small">{fileName}</Text>
                </div>
              </div>

              <PlainFileDropzone onDrop={(files) => onDropFiles(files)} openOnClick>
                <div className="child-dropzone"></div>
              </PlainFileDropzone>

              <div className="ms-Grid-row mt-2 ml-1">
                {activitiesToPost && (
                    <Text variant="medium">
                      {/* {' '}
                  Tienes {activitiesToPost.filter((act) => !act.impai_con_error).length} actividades válidas y{' '}
              {activitiesToPost.filter((act) => act.impai_con_error).length} actividades con errores{' '} */}
                      {t('Tienes ') +
                          activitiesToPost.filter((act) => !act.impai_con_error).length +
                          t(' actividades válidas y ') +
                          activitiesToPost.filter((act) => act.impai_con_error).length +
                          t(' actividades con errores')}
                    </Text>
                )}
              </div>
              <div className="ms-Grid-row mt-2 ml-1">
                <Toggle
                    onText={t('Viendo solamente las actividades válidas')}
                    offText={t('Viendo todas las actividades')}
                    checked={showOk}
                    onChange={() => setShowOk(!showOk)}
                ></Toggle>
              </div>

              {activitiesToPost.length > 0 && (
                  <div className="ms-Grid-row">
                    <DetailsList
                        className=""
                        items={showOk ? activitiesToPost.filter((act) => !act.impai_con_error) : activitiesToPost}
                        columns={columns}
                        selectionMode={SelectionMode.none}
                        layoutMode={DetailsListLayoutMode.justified}
                        isHeaderVisible={true}
                        onRenderRow={_onRenderRow}
                        onShouldVirtualize={() => false}
                    />
                  </div>
              )}
            </div>
            {activitySelected && projects && (
                <EditActivityAssistantDialog
                    showDialog={showDialog}
                    toggleShowDialog={() => setShowDialog(!showDialog)}
                    projects={projects}
                    closeDialog={() => setShowDialog(false)}
                    onEdit={onEdit}
                    activitySelected={activitySelected}
                    updateActivitySelected={(_activitySelected: ActivityFromExcelFormToPost) => {
                      setActivitySelected(_activitySelected);
                    }}
                    onSaveStartSavedInstance={(val: string) => setStartSavedInstance(val)}
                    onSaveEndSavedInstance={(val: string) => setEndSavedInstance(val)}
                ></EditActivityAssistantDialog>
            )}
          </div>
        </ViewWrapperWithCommandBar>
      </div>
  );
};
