import React from 'react';
import FileFolder from './FileFolder';
import FileDropzone from './FileDropzone';

interface ISingleFileDropzoneProps {
  onDrop: (file: File) => void | Promise<void>;
  openOnClick?: boolean;
}

const SingleFileDropzone: React.FC<ISingleFileDropzoneProps> = (props) => {
  const onDrop = (folder: FileFolder) => {
    if (!folder.emptyChildren() || !folder.hasFiles()) {
      throw new Error('Expected plain files');
    }

    const files = folder.getFiles();
    if (files.length !== 1) {
      throw new Error('Expected single file');
    }
    return props.onDrop(files[0]);
  };

  return (
      <FileDropzone onDrop={onDrop} openOnClick={props.openOnClick} multiple={false}>
        {props.children}
      </FileDropzone>
  );
};

export default SingleFileDropzone;
