import * as React from 'react';
import {
  DefaultButton,
  DefaultPalette,
  ICommandBarItemProps,
  PrimaryButton,
  TextField,
} from '@fluentui/react';
import TimeTaxSupportSystemClient from '../../system';
import MessageCourier from '../../lib/MessageCourier';
import ViewWrapperWithCommandBar from '../Layout/ViewWrapperWithCommandBar';
import { defaultCommandBarButtonStyles, titleCommandBarButtonStyles } from '../../styles/button-styles';
import DataGrid, {
  Scrolling,
  Column,
  Export,
  TotalItem,
  Summary,
  GroupItem,
  Paging,
} from 'devextreme-react/data-grid';
import { User } from '../../system/User';
import CompanySelector from '../Company/CompanySelector';
import CollaboratorSelector from '../Collaborator/CollaboratorSelector';
import { EscalableExporterOptions, exportToExcel } from '../../lib/Util';
import { DateTime } from 'luxon';
import OrganizationChartNodeSelector from '../Common/SectorSelector';
import { OrganizationChartDropdown } from '../Common/OrganizationChartDropdown';
import useAsyncDataCourier from '../../common/custom-hooks/useAsyncDataCourier';

interface IReportingBillableDistribution {
  system: TimeTaxSupportSystemClient;
  courier: MessageCourier;
  changeBlockUI: (state: boolean) => void;
  user: User;
}

export const ReportingBillableDistribution = (props: IReportingBillableDistribution) => {
  const [items, setItems] = React.useState<any[]>([]);
  const [emp_id, setEmp_id] = React.useState<string | undefined>(undefined);
  const [pro_codigo, setPro_codigo] = React.useState<string | undefined>(undefined);
  const [pro_nombre, setPro_nombre] = React.useState<string | undefined>(undefined);
  const [pro_owner_colid, setPro_owner_colid] = React.useState<string | undefined>(undefined);
  const [pro_norid, setPro_norid] = React.useState<string | undefined>(undefined);

  const nodes = useAsyncDataCourier(
      () => {
        if (props.user) {
          return props.system.getOrganizationChartSystem().getAvailableNodesOf(props.user);
        }
        return Promise.resolve([]);
      },
      [props.system, props.user],
      props.courier
  );

  const itemsMenu = React.useMemo(
      (): ICommandBarItemProps[] => [
        {
          key: 'title',
          text: 'Reporte de distribución facturable',
          iconProps: {
            iconName: 'ReminderPerson',
            color: DefaultPalette.themeLighterAlt,
            className: 'text-title-btn',
          },
          buttonStyles: titleCommandBarButtonStyles,
          className: 'text-title-btn',
        },
      ],
      []
  );

  const onClickSearch = async () => {
    props.changeBlockUI(true);
    try {
      const _items = await props.system
          .getReportingSystem()
          .getBillableDistributionReport(pro_codigo, pro_nombre, emp_id, pro_owner_colid, pro_norid);
      setItems(_items);
      props.courier.messageSuccess('Reporte generado con éxito');
    } catch (error) {
      props.courier.messageError(error);
    }
    props.changeBlockUI(false);
  };

  const onClickCleanFilters = () => {
    setEmp_id('');
    setPro_codigo('');
    setPro_nombre('');
    setPro_owner_colid('');
    setPro_norid('');
  };

  const onExporting = (e: any) => {
    const day = DateTime.local();
    let options: EscalableExporterOptions = {
      fileName: `reporte-distribucion-facturable-${day.toFormat('dd-LL-yyyy')}`,
      sheetName: 'Reporte de distribucion facturable',

      customizeCell: (options) => {
        if (options.gridCell?.value === undefined && options.gridCell?.rowType !== 'header') {
          const nombre_empresa = options.gridCell?.column?.caption;
          const data = options.gridCell?.data;
          options.excelCell.value = data?.empresas[nombre_empresa!];
          if (+data?.empresas[nombre_empresa!] > 0) {
            options.excelCell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'e1e2e3' } };
          } else {
            options.excelCell.value = '-';
          }
        }
        if (options.gridCell?.column?.name !== 'porcentaje_total' && +options.gridCell?.value > 0) {
          options.excelCell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'e1e2e3' } };
        }
        if (options.gridCell?.column?.name !== 'porcentaje_total' && +options.gridCell?.value === 0) {
          options.excelCell.value = '-';
        }
        if (options.gridCell?.rowType === 'header') {
          options.excelCell.style = {
            border: {
              top: { style: 'medium', color: { argb: 'FF000000' } },
              left: { style: 'medium', color: { argb: 'FF000000' } },
              bottom: { style: 'medium', color: { argb: 'FF000000' } },
              right: { style: 'medium', color: { argb: 'FF000000' } },
            },
          };
          options.excelCell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'b3b4b5' },
          };
          options.excelCell.alignment = { textRotation: 45 };
        }
      },
    };
    exportToExcel(e, options);
  };

  return (
      <>
        <ViewWrapperWithCommandBar
            withBorderBottom
            title={''}
            items={itemsMenu}
            overflowButtonStyles={defaultCommandBarButtonStyles}
            variant="highligth"
            titleBody={'Reporte de distribucion facturable'}
        >
          <div className="padding-1 bg-white">
            <div className="ms-Grid" dir="ltr">
              <div className="ms-Grid-row mb-2">
                <div className="ms-Grid-col ms-sm2">
                  <TextField
                      label="Nombre del proyecto"
                      value={pro_nombre}
                      onChange={(_ev, val) => setPro_nombre(val)}
                  />
                </div>
                <div className="ms-Grid-col ms-sm2">
                  <TextField
                      label="Codigo del proyecto"
                      value={pro_codigo}
                      onChange={(_ev, val) => setPro_codigo(val)}
                  />
                </div>
                <div className="ms-Grid-col ms-sm2">
                  <CollaboratorSelector
                      system={props.system}
                      courier={props.courier}
                      onChangeSelected={(collaborator) => setPro_owner_colid(collaborator?.getId()!)}
                      useAll
                      value={pro_owner_colid}
                  />
                </div>
                <div className="ms-Grid-col ms-sm2">
                  {nodes && (
                      <OrganizationChartDropdown
                          label={'Sector'}
                          options={nodes}
                          useAllSector={true}
                          onSelectAll={() => setPro_norid(undefined)}
                          onChange={(node) => setPro_norid(node?.getId()!)}
                          value={pro_norid}
                      />
                  )}
                </div>
                <div className="ms-Grid-col ms-sm2">
                  <CompanySelector
                      system={props.system}
                      courier={props.courier}
                      onChangeSelected={(company) => setEmp_id(company?.getId()!)}
                      useAll
                      value={emp_id}
                  />
                </div>
                <div className="ms-Grid-col ms-sm1">
                  <PrimaryButton
                      iconProps={{ iconName: 'Search' }}
                      className="mt-for-btn-search ml-2"
                      onClick={onClickSearch}
                      text={'Buscar'}
                  />
                </div>
                <div className="ms-Grid-col ms-sm1">
                  <DefaultButton
                      iconProps={{ iconName: 'Broom' }}
                      className="mt-for-btn-search ml-2"
                      onClick={onClickCleanFilters}
                      text={'Limpiar filtros'}
                  />
                </div>
              </div>
              <div className="ms-Grid-row">
                {items.length > 0 && (
                    <DataGrid
                        height={620}
                        width="100%"
                        keyExpr={'proyecto_codigo'}
                        dataSource={items}
                        showBorders={true}
                        wordWrapEnabled={true}
                        onExporting={onExporting}
                    >
                      <Paging defaultPageSize={100} />
                      {/* <Scrolling mode="virtual" /> */}
                      <Column
                          dataField="proyecto_nombre"
                          caption="Nombre"
                          alignment="center"
                          fixed={true}
                          width="200px"
                      />
                      <Column
                          dataField="proyecto_codigo"
                          caption="Codigo"
                          alignment="center"
                          fixed={true}
                          width="100px"
                      />
                      <Column
                          dataField="proyecto_sector"
                          caption="Sector"
                          alignment="center"
                          fixed={true}
                          width="150px"
                      />
                      <Column dataField="proyecto_creador" caption="Creador" alignment="center" width="150px" />
                      <Column dataField="proyecto_estado" caption="Estado" alignment="center" width="100px" />
                      <Column
                          dataField="porcentaje_total"
                          caption="Porcentaje total"
                          alignment="center"
                          width="100px"
                      />
                      {Object.keys(items[0]['empresas']).map((empresa_nombre, ind) => {
                        return (
                            <Column
                                key={ind}
                                cellRender={(data) => {
                                  let value = '-';
                                  const empresa = Object.keys(data.data.empresas).find(
                                      (emp) => emp === empresa_nombre
                                  );
                                  if (empresa) {
                                    value = data.data.empresas[empresa_nombre];
                                  }
                                  return <span>{value}</span>;
                                }}
                                dataField={'empresas[' + empresa_nombre + ']'}
                                caption={empresa_nombre}
                                alignment="center"
                                width="100px"
                            />
                        );
                      })}
                      <Summary>
                        <TotalItem
                            column="proyecto_nombre"
                            summaryType="sum"
                            displayFormat={`Total: ${items.length} resultados.`}
                        />
                      </Summary>
                      <Export enabled={true} />
                    </DataGrid>
                )}
              </div>
            </div>
          </div>
        </ViewWrapperWithCommandBar>
      </>
  );
};
