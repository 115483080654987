import React, { useRef } from 'react';
import { Stack, PrimaryButton, TextField, DatePicker } from '@fluentui/react';

interface IFormManagerStatus {
  isValid: boolean;
  isChanged: boolean;
  isRequired: boolean;
}

function initFormManagerStatus() {
  let ret: IFormManagerStatus = {
    isValid: false,
    isChanged: false,
    isRequired: false,
  };
  return ret;
}

interface IFormManagerProps {}

function useFormManager(props: IFormManagerProps) {
  const [currentStatus, setcurrentStatus] = React.useState<IFormManagerStatus>(initFormManagerStatus);

  function register(refInput: any) {}

  return { currentStatus, register };
}

export const FormExample = () => {
  const { register } = useFormManager({});

  const onSubmit = (data: any) => {
    // console.log(data);
  };

  const stackTokens = { childrenGap: 10, marginLeft: 10 };

  return (
      <div className="padding-1">
        <form>
          <Stack horizontal>
            <h3>Formulario Base</h3>
          </Stack>
          <Stack tokens={stackTokens}>
            <Stack.Item>
              <TextField label="Nombre" />
            </Stack.Item>

            <Stack.Item>
              <PrimaryButton text="Submit" type="submit" />
            </Stack.Item>
          </Stack>
        </form>
      </div>
  );
};
