import {
  DocumentCard,
  DocumentCardActivity,
  DocumentCardDetails,
  DocumentCardPreview,
  DocumentCardTitle,
  IDocumentCardPreviewProps,
  DocumentCardType,
  IDocumentCardActivityPerson,
  DocumentCardStatus,
} from '@fluentui/react/lib/DocumentCard';
import { getTheme } from '@fluentui/react/lib/Styling';
import { DetailsListView } from '../../Common/DetailsList/DetailListView';
const theme = getTheme();
const { palette, fonts } = theme;

export const ProjectCardList = (props: any) => {
  const getPeople = (
      owner_actividad_iniciales: string,
      owner_actividad_nombre: string,
      owner_actividad_img_url: string
  ): IDocumentCardActivityPerson[] => {
    return [
      {
        name: owner_actividad_nombre,
        profileImageSrc: owner_actividad_img_url,
        initials: owner_actividad_iniciales,
      },
    ];
  };

  const previewPropsUsingIcon: IDocumentCardPreviewProps = {
    previewImages: [
      {
        previewIconProps: {
          iconName: 'Teamwork',
          styles: {
            root: {
              fontSize: fonts.superLarge.fontSize,
              color: '#0078d7',
              backgroundColor: palette.neutralLighterAlt,
            },
          },
        },
        width: 144,
      },
    ],
    styles: {
      previewIcon: { backgroundColor: palette.neutralLighterAlt },
    },
  };

  const mapCard = (project: any, i: number) => {
    return (
        <div className="ms-Grid-col ms-sm6 ms-md12 ms-lg6 ms-xl4" key={i}>
          <DocumentCard className="size-document-card" type={DocumentCardType.compact}>
            <DocumentCardPreview {...previewPropsUsingIcon} className="size-logo" />
            <DocumentCardDetails>
              <DocumentCardTitle title={`${project.pro_codigo}`} shouldTruncate />
              <DocumentCardTitle title={`${project.pro_nombre}`} shouldTruncate />
              <DocumentCardTitle
                  title={'Período: ' + project.pro_inicio + ' - ' + project.pro_fin}
                  className="proyect-title"
                  showAsSecondaryTitle
              />
              {/* <DocumentCardStatus statusIcon="attach" status=" 3 Documentos" /> */}
              <DocumentCardActivity
                  activity={'Responsable del proyecto'}
                  people={getPeople(
                      project.responsable_iniciales,
                      project.responsable_nombre,
                      project.responsable_img_url
                  )}
              />
            </DocumentCardDetails>
          </DocumentCard>{' '}
        </div>
    );
  };

  return (
      <DetailsListView
          changeBlockUI={props.changeBlockUI}
          keyList={props.keyList}
          onClickCardMode={() => props.onClickCardMode()}
          onClickGridMode={() => props.onClickGridMode()}
          onItemClick={props.onItemClick}
          title={'Proyectos'}
          mode={props.mode}
          // virtualize={false}
          isGridList={false}
          listFilters={[]}
          courier={props.courier}
          quickFilters={props.quickFilters}
          baseUrl={props.baseUrl}
          mapCard={mapCard}
      ></DetailsListView>
  );
};
