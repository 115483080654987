import React from 'react';
import { DefaultPalette, ICommandBarItemProps, Text, Icon, DatePicker } from '@fluentui/react';
import { DISABLE_MMI_OPTION, SHOW_MMI_OPTION, routes } from '../../../common/constants';
import MessageCourier from '../../../lib/MessageCourier';
import {
  buttonStylesForProjects,
  defaultCommandBarButtonStyles,
  titleCommandBarButtonStyles,
} from '../../../styles/button-styles';
import TimeTaxSupportSystemClient from '../../../system';
import ViewWrapperWithCommandBar from '../../Layout/ViewWrapperWithCommandBar';
import { useNavigate, useParams } from 'react-router-dom';
import { ProjectStatus } from '../../Common/ProjectStatus';
import { ProjectMiniPanelTimeProgressBar } from '../ProjectMiniPanel/ProjectMiniPanelTimeProgressBar';
import { ProjectTeamFacepile } from '../../Common/ProjectTeamFacepile';
import Project from '../../../system/projects/Project';
import useAsyncDataCourier from '../../../common/custom-hooks/useAsyncDataCourier';
import ProjectPanelAreaChart from './ProjectPanelAreaChart';
import ProjectPanelDonutChart from './ProjectPanelDonutChart';
import ProjectPanelGroupedBarCollaborator from './ProjectPanelGroupedBarCollaborator';
import BasicChartPad from '../../Common/BasicChartPad';
import { DateCustom } from '../../../lib/DateCustom';
import { DateTime } from 'luxon';
import { KEY_VIEW_CONTROL_DOCS_PROJECTS, KEY_VIEW_MAILS_PROJECTS } from '../ProjectView';
import { IProjectDescriptor } from '../../../system/projects/ProjectDescriptor';
import { useTranslation, Trans } from 'react-i18next';
import { getLocalizedStrings } from '../../../lib/LocalizedStringsForDatePicker';
import { PillFluent } from '../../Common/PillFluent';

interface IProjectPanel {
  system: TimeTaxSupportSystemClient;
  courier: MessageCourier;
}

export const ProjectPanel = (props: IProjectPanel) => {
  const { t, i18n } = useTranslation();
  let { id } = useParams();
  const navigate = useNavigate();

  const projects = useAsyncDataCourier(() => props.system.getProjects(), [props.system], props.courier);

  // const [selectedProject, setSelectedProject] = React.useState<IProjectDescriptor | undefined>(undefined);
  const selectedProject = useAsyncDataCourier(
      () => props.system.getProjectSystem().getById(id!),
      [props.system, id],
      props.courier
  );

  const onClickProject = (project: IProjectDescriptor) => () => {
    navigate(`${routes.project_panel}/${project.getId()}`);
  };

  const itemsMenu = React.useMemo(
      (): ICommandBarItemProps[] => [
        {
          key: 'selectedProject',
          text: selectedProject?.getCode(),
          itemProps: {},
          iconProps: { iconName: 'Teamwork', color: DefaultPalette.themeLighterAlt },
          subMenuProps: {
            items: projects
                ? projects.map((selectedProject) => ({
                  key: selectedProject.getCode(),
                  text: selectedProject.getCode(),
                  onClick: onClickProject(selectedProject),
                }))
                : [],
          },
          buttonStyles: buttonStylesForProjects,
        },
        {
          key: 'manage',
          text: t('Gestión'),
          onClick: () => navigate(`${routes.project}/${selectedProject?.getId()}`),
          iconProps: { iconName: 'TaskManager' },
          buttonStyles: defaultCommandBarButtonStyles,
        },

        {
          key: 'download',
          text: t('Config'),
          onClick: () => navigate(`${routes.project_config}/${selectedProject?.getId()}`),
          iconProps: { iconName: 'Settings' },
          buttonStyles: defaultCommandBarButtonStyles,
        },
        {
          key: 'viewRequiredDocuments',
          text: t('Docs. obligatorios'),
          iconProps: { iconName: 'ProtectedDocument' },
          onClick: () => navigate(`${routes.project}/${id}?view=${KEY_VIEW_CONTROL_DOCS_PROJECTS}`),
          buttonStyles: defaultCommandBarButtonStyles,
        },
        // {
        //   key: 'mmi',
        //   text: t('MMI (Massive Mail Inspector)'),
        //   iconProps: { iconName: 'Mail' },
        //   onClick: () => navigate(`${routes.massive_mail_inspector}/${id}`),
        //   buttonStyles: defaultCommandBarButtonStyles,
        //   disabled: DISABLE_MMI_OPTION,
        // },
      ],
      [selectedProject, projects, id, navigate]
  );

  const overflowItems: ICommandBarItemProps[] = React.useMemo(() => [], [id, navigate]);

  const [fromDate, setFromDate] = React.useState<DateTime | undefined>(undefined);
  const [toDate, setToDate] = React.useState<DateTime | undefined>(undefined);
  const [shouldRefresh, setShouldRefresh] = React.useState<boolean>(false);

  React.useEffect(() => {
    setFromDate(DateTime.fromJSDate(DateCustom.getFechaOneMonthAgo()));
    setToDate(DateTime.fromJSDate(new Date()));
  }, []);

  const onChangeDate = (date: Date | null | undefined, name: string) => {
    if (date) {
      if (name === 'from') {
        setFromDate(DateTime.fromJSDate(date));
        setShouldRefresh(!shouldRefresh);
      }
      if (name === 'to') {
        setToDate(DateTime.fromJSDate(date));
        setShouldRefresh(!shouldRefresh);
      }
    }
  };

  const selectedProjectSummary = useAsyncDataCourier(
      () =>
          selectedProject
              ? props.system.getProjectSystem().getSummaryOf(selectedProject, fromDate, toDate)
              : Promise.resolve(undefined),
      [props.system, selectedProject, shouldRefresh],
      props.courier
  );

  const selectedProjectCollaboratorSummary = useAsyncDataCourier(
      () =>
          selectedProject
              ? props.system.getProjectSystem().getCollaboratorSummaryOf(selectedProject, fromDate, toDate)
              : Promise.resolve(undefined),
      [props.system, selectedProject, shouldRefresh],
      props.courier
  );

  const selectedProjectCompaniesSummary = useAsyncDataCourier(
      () =>
          selectedProject
              ? props.system.getProjectSystem().getCompaniesSummaryOf(selectedProject, fromDate, toDate)
              : Promise.resolve(undefined),
      [props.system, selectedProject, shouldRefresh],
      props.courier
  );

  const emailsMMIPeriodTotalQty = () => {
    if (selectedProjectSummary) {
      let totalQty = 0;
      selectedProjectSummary.emailsMMIByDay.forEach((item) => {
        totalQty += item.qty;
      });

      return totalQty;
    }
  };

  const emailsMMIPeriodTotalAttached = () => {
    if (selectedProjectSummary) {
      let totalAttached = 0;
      selectedProjectSummary.emailsMMIByDay.forEach((item) => {
        if (item.attachs) {
          totalAttached += item.attachs;
        }
      });

      return totalAttached;
    }
  };

  return (
      <ViewWrapperWithCommandBar
          withBorderBottom
          title={''}
          items={itemsMenu}
          overflowButtonStyles={defaultCommandBarButtonStyles}
          // overflowItems={overflowItems}
          variant="highligth"
          titleBody={t('Proyectos')}
      >
        {selectedProject && (
            <div className="padding-1 body-bg">
              <div className="ms-Grid" dir="ltr">
                <div className="ms-Grid-row ">
                  <div className="ms-Grid-col ms-lg12 ms-xl6 px-0-in-down-xl">
                    <div className="ms-Grid-row padding-for-panel-selectedProject">
                      <div className="ms-Grid-col ms-lg12 ms-xl12 ms-xxl12 text-overflow ">
                        <div className="ms-Grid-row">
                          <div className="ms-Grid-col ms-sm12 text-overflow ">
                            <Text variant="large" nowrap className="selectedProject-title">
                              {selectedProject.getName()}
                            </Text>
                          </div>
                        </div>
                        <div className="ms-Grid-row">
                          <div className="ms-Grid-col ms-sm12 text-overflow ">
                            <ProjectStatus status={selectedProject.getState()}></ProjectStatus>
                          </div>
                        </div>
                        <div className="ms-Grid-row">
                          <div className="ms-Grid-col ms-sm12 text-overflow ">
                            <Text variant="medium" nowrap title="Vigencia del proyecto">
                              <Icon iconName="Clock" className={'text-icon'} />
                              {selectedProject.getStart().toFormat('dd/LL/yyyy')} -{' '}
                              {selectedProject.getEnd().toFormat('dd/LL/yyyy')}
                            </Text>
                          </div>
                        </div>
                        <div className="ms-Grid-row mt-1">
                          <div className="ms-Grid-col ms-sm12 text-overflow ">
                            <ProjectTeamFacepile project={selectedProject} />
                          </div>
                        </div>
                        <div className="ms-Grid-row">
                          <div className="ms-Grid-col ms-sm12 text-overflow ">
                            <ProjectMiniPanelTimeProgressBar
                                start={selectedProject.getStart()}
                                end={selectedProject.getEnd()}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="ms-Grid-col ms-lg12 ms-xl6 px-0-in-down-xl">
                    <div
                        className={
                          SHOW_MMI_OPTION ? 'ms-Grid-col ms-lg4 ms-xl4 mb-1' : 'ms-Grid-col ms-lg6 ms-xl6 mb-1'
                        }
                    >
                      {selectedProjectSummary && (
                          // <BasicChartPad
                          //   previewText={selectedProjectSummary.activities.qty}
                          //   mainText={t('Actividades')}
                          //   secondaryText={t('A lo largo del proyecto')}
                          //   iconName="AccountActivity"
                          // />
                          <PillFluent
                              title={t('Actividades')}
                              iconTitle={'AccountActivity'}
                              series={[
                                {
                                  label: t('A lo largo del proyecto'),
                                  value: selectedProjectSummary.activities.qty,
                                  icon: 'AccountActivity',
                                },
                              ]}
                              small={true}
                          ></PillFluent>
                      )}
                    </div>

                    <div
                        className={
                          SHOW_MMI_OPTION ? 'ms-Grid-col ms-lg4 ms-xl4 mb-1' : 'ms-Grid-col ms-lg6 ms-xl6 mb-1'
                        }
                    >
                      {selectedProjectSummary && (
                          // <BasicChartPad
                          //   previewText={selectedProjectSummary.activities.hours.toFixed(2)}
                          //   mainText={t('Horas')}
                          //   secondaryText={t('A lo largo del proyecto')}
                          //   iconName="Clock"
                          // />
                          <PillFluent
                              title={t('Horas')}
                              iconTitle={'Clock'}
                              series={[
                                {
                                  label: t('A lo largo del proyecto'),
                                  value: selectedProjectSummary.activities.hours.toFixed(2),
                                  icon: 'Clock',
                                },
                              ]}
                              small={true}
                          ></PillFluent>
                      )}
                    </div>

                    {SHOW_MMI_OPTION && (
                        <>
                          <div
                              className={
                                SHOW_MMI_OPTION ? 'ms-Grid-col ms-lg4 ms-xl4 mb-1' : 'ms-Grid-col ms-lg6 ms-xl6 mb-1'
                              }
                          >
                            {selectedProjectSummary && (
                                <PillFluent
                                    title={t('Emails')}
                                    iconTitle={'Mail'}
                                    series={[
                                      {
                                        label: t('En el período seleccionado'),
                                        value: emailsMMIPeriodTotalQty(),
                                        icon: 'Mail',
                                      },
                                    ]}
                                    small={true}
                                ></PillFluent>
                            )}
                          </div>

                          <div
                              className={SHOW_MMI_OPTION ? 'ms-Grid-col ms-lg4 ms-xl4' : 'ms-Grid-col ms-lg6 ms-xl6'}
                          >
                            {selectedProjectSummary && (
                                <PillFluent
                                    title={t('Docs. adjuntos')}
                                    iconTitle={'Attach'}
                                    series={[
                                      {
                                        label: t('En el período seleccionado'),
                                        value: emailsMMIPeriodTotalAttached(),
                                        icon: 'Attach',
                                      },
                                    ]}
                                    small={true}
                                ></PillFluent>
                            )}
                          </div>
                        </>
                    )}

                    <div
                        className={
                          SHOW_MMI_OPTION
                              ? 'ms-Grid-col ms-lg4 ms-xl4 mt-2-in-down-xl'
                              : 'ms-Grid-col ms-lg6 ms-xl6 mt-2-in-down-xl'
                        }
                    >
                      {selectedProjectSummary && (
                          <>
                            {/* <BasicChartPad
                        previewText={selectedProjectSummary.documents.uploaded}
                        mainText={t('Documentos')}
                        iconName="TextDocument"
                        secondaryText={
                          selectedProjectSummary.documents.required.uploaded +
                          '/' +
                          selectedProjectSummary.documents.required.total +
                          t(' obligatorios a lo largo del proyecto')
                        }
                      /> */}
                            <PillFluent
                                title={t('Documentos')}
                                iconTitle={'TextDocument'}
                                series={[
                                  {
                                    label: t('A lo largo del proyecto'),
                                    value:
                                        selectedProjectSummary.documents.required.uploaded +
                                        '/' +
                                        selectedProjectSummary.documents.required.total,
                                    icon: 'TextDocument',
                                  },
                                ]}
                                small={true}
                            ></PillFluent>
                          </>
                      )}
                    </div>
                    <div
                        className={
                          SHOW_MMI_OPTION
                              ? 'ms-Grid-col ms-lg4 ms-xl4 mt-2-in-down-xl'
                              : 'ms-Grid-col ms-lg6 ms-xl6 mt-2-in-down-xl'
                        }
                    >
                      {selectedProjectSummary && (
                          // <BasicChartPad
                          //   previewText={
                          //     selectedProjectSummary.collaborators.active +
                          //     '/' +
                          //     selectedProjectSummary.collaborators.total
                          //   }
                          //   mainText={t('Colaboradores activos')}
                          //   secondaryText={t('A lo largo del proyecto')}
                          //   iconName="Group"
                          // />
                          <PillFluent
                              title={t('Colaboradores activos')}
                              iconTitle={'Group'}
                              series={[
                                {
                                  label: t('A lo largo del proyecto'),
                                  value:
                                      selectedProjectSummary.collaborators.active +
                                      '/' +
                                      selectedProjectSummary.collaborators.total,
                                  icon: 'Group',
                                },
                              ]}
                              small={true}
                          ></PillFluent>
                      )}
                    </div>

                    <div className="ms-Grid-col ms-sm12 mt-2-in-down-xl mt-1">
                      <div className="text-center mt-1">
                        {selectedProjectSummary && (
                            <>
                              {selectedProjectSummary.documents.required.expired > 0 && (
                                  <Text
                                      variant="medium"
                                      className="fw-500 error-color link-styles"
                                      onClick={() =>
                                          navigate(`${routes.project}/${id}?view=${KEY_VIEW_CONTROL_DOCS_PROJECTS}`)
                                      }
                                  >
                                    <Icon iconName="AlertSolid" className="text-icon"></Icon>
                                    {t('Tienes documentos obligatorios vencidos')} (
                                    {selectedProjectSummary.documents.required.expired})
                                  </Text>
                              )}

                              {selectedProjectSummary.documents.required.total ===
                                  selectedProjectSummary.documents.required.uploaded && (
                                      <Text variant="medium" className="fw-500">
                                        <Icon iconName="CheckMark" className="text-icon success-color"></Icon>
                                        {t('Estas al día con tu proyecto')}
                                      </Text>
                                  )}
                            </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <hr></hr>

                <div className="ms-Grid-row ">
                  <div className="ms-Grid-col ms-lg6 ms-xl2">
                    <DatePicker
                        strings={getLocalizedStrings()}
                        label={t('Filtrar desde')}
                        formatDate={DateCustom.formatDate}
                        value={fromDate ? fromDate.toJSDate() : undefined}
                        onSelectDate={(e) => onChangeDate(e, 'from')}
                        className=""
                    />
                  </div>
                  <div className="ms-Grid-col ms-lg6 ms-xl2">
                    <DatePicker
                        strings={getLocalizedStrings()}
                        label={t('Filtrar hasta')}
                        formatDate={DateCustom.formatDate}
                        value={toDate ? toDate.toJSDate() : undefined}
                        onSelectDate={(e) => onChangeDate(e, 'to')}
                        className=""
                    />
                  </div>
                </div>

                <div className="ms-Grid-row mt-2">
                  <div className="ms-Grid-col ms-lg12 ms-xl6 bg-white-h100">
                    <div className="ms-Grid-row padding-for-panel-selectedProject">
                      {selectedProjectSummary && selectedProjectSummary.activitiesByDay.length > 0 && (
                          <ProjectPanelAreaChart
                              data={selectedProjectSummary.activitiesByDay}
                              title={t('Actividades')}
                              color={DefaultPalette.blueLight}
                          ></ProjectPanelAreaChart>
                      )}
                    </div>
                  </div>
                  <div className="ms-Grid-col ms-lg12 ms-xl6 mt-2-in-down-xl">
                    <div className="ms-Grid-col ms-lg6 ms-xl6">
                      {selectedProjectSummary && (
                          // <BasicChartPad
                          //   previewText={selectedProjectSummary.activitiesPeriod.qty}
                          //   mainText={t('Actividades')}
                          //   secondaryText={t('En el período seleccionado')}
                          //   iconName="AccountActivity"
                          // />
                          <PillFluent
                              title={t('Actividades')}
                              iconTitle={'AccountActivity'}
                              series={[
                                {
                                  label: t('En el período seleccionado'),
                                  value: selectedProjectSummary.activitiesPeriod.qty,
                                  icon: 'AccountActivity',
                                },
                              ]}
                              small={false}
                          ></PillFluent>
                      )}
                    </div>

                    <div className="ms-Grid-col ms-lg6 ms-xl6">
                      {selectedProjectSummary && (
                          // <BasicChartPad
                          //   previewText={selectedProjectSummary.activitiesPeriod.hours.toFixed(2)}
                          //   mainText={t('Horas')}
                          //   secondaryText={t('En el período seleccionado')}
                          //   iconName="Clock"
                          // />
                          <PillFluent
                              title={t('Horas')}
                              iconTitle={'Clock'}
                              series={[
                                {
                                  label: t('En el período seleccionado'),
                                  value: selectedProjectSummary.activitiesPeriod.hours.toFixed(2),
                                  icon: 'Clock',
                                },
                              ]}
                              small={false}
                          ></PillFluent>
                      )}
                    </div>
                  </div>
                </div>
                <div className="ms-Grid-row mt-2">
                  <div className="ms-Grid-col ms-lg12 ms-xl6 bg-white-h100">
                    <div className="ms-Grid-row padding-for-panel-selectedProject">
                      {selectedProjectSummary && selectedProjectSummary.documentsByDay.length > 0 && (
                          <ProjectPanelAreaChart
                              data={selectedProjectSummary.documentsByDay}
                              title={t('Documentos')}
                              color={DefaultPalette.blueLight}
                          ></ProjectPanelAreaChart>
                      )}
                    </div>
                  </div>
                  <div className="ms-Grid-col ms-lg12 ms-xl6 mt-2-in-down-xl ">
                    <div className="ms-Grid-col ms-lg6 ms-xl6 ">
                      {selectedProjectSummary && (
                          <>
                            {/* <BasicChartPad
                        previewText={selectedProjectSummary.documents.uploadedPeriod}
                        mainText={t('Documentos')}
                        iconName="TextDocument"
                        secondaryText={
                          selectedProjectSummary.documents.requiredPeriod.uploaded +
                          '/' +
                          selectedProjectSummary.documents.requiredPeriod.total +
                          t(' obligatorios en periodo seleccionado')
                        }
                      /> */}
                            <PillFluent
                                title={t('Documentos')}
                                iconTitle={'TextDocument'}
                                series={[
                                  {
                                    label: t('En el período seleccionado'),
                                    value:
                                        selectedProjectSummary.documents.requiredPeriod.uploaded +
                                        '/' +
                                        selectedProjectSummary.documents.requiredPeriod.total,
                                    icon: 'TextDocument',
                                  },
                                ]}
                                small={false}
                            ></PillFluent>
                            <div className="text-center mt-1">
                              {selectedProjectSummary.documents.requiredPeriod.expired > 0 && (
                                  <Text
                                      variant="medium"
                                      className="fw-500 error-color  link-styles"
                                      onClick={() =>
                                          navigate(`${routes.project}/${id}?view=${KEY_VIEW_CONTROL_DOCS_PROJECTS}`)
                                      }
                                  >
                                    <Icon iconName="AlertSolid" className="text-icon"></Icon>
                                    {t('Tienes documentos obligatorios vencidos')} (
                                    {selectedProjectSummary.documents.requiredPeriod.expired})
                                  </Text>
                              )}

                              {selectedProjectSummary.documents.requiredPeriod.total ===
                                  selectedProjectSummary.documents.requiredPeriod.uploaded && (
                                      <Text variant="medium" className="fw-500">
                                        <Icon iconName="CheckMark" className="text-icon success-color"></Icon>
                                        {t('Estas al día con tu proyecto')}
                                      </Text>
                                  )}
                            </div>
                          </>
                      )}
                    </div>
                    <div className="ms-Grid-col ms-lg6 ms-xl6">
                      {selectedProjectSummary && (
                          // <BasicChartPad
                          //   previewText={
                          //     selectedProjectSummary.collaborators.activePeriod +
                          //     '/' +
                          //     selectedProjectSummary.collaborators.total
                          //   }
                          //   mainText={t('Colaboradores activos')}
                          //   secondaryText={t('En el período seleccionado')}
                          //   iconName="Group"
                          // />
                          <PillFluent
                              title={t('Colaboradores activos')}
                              iconTitle={'Group'}
                              series={[
                                {
                                  label: t('En el período seleccionado'),
                                  value:
                                      selectedProjectSummary.collaborators.activePeriod +
                                      '/' +
                                      selectedProjectSummary.collaborators.total,
                                  icon: 'Group',
                                },
                              ]}
                              small={false}
                          ></PillFluent>
                      )}
                    </div>
                  </div>
                </div>
                {SHOW_MMI_OPTION && (
                    <div className="ms-Grid-row mt-2">
                      <div className="ms-Grid-col ms-lg12 ms-xl6 bg-white-h100">
                        <div className="ms-Grid-row padding-for-panel-selectedProject">
                          {selectedProjectSummary && selectedProjectSummary.activitiesByDay.length > 0 && (
                              <ProjectPanelAreaChart
                                  data={selectedProjectSummary.emailsMMIByDay}
                                  title={t('Emails')}
                                  color={DefaultPalette.blueLight}
                              ></ProjectPanelAreaChart>
                          )}
                        </div>
                      </div>
                      <div className="ms-Grid-col ms-lg12 ms-xl6 mt-2-in-down-xl">
                        <div className="ms-Grid-col ms-lg6 ms-xl6">
                          {selectedProjectSummary && (
                              // <BasicChartPad
                              //   previewText={selectedProjectSummary.activitiesPeriod.qty}
                              //   mainText={t('Actividades')}
                              //   secondaryText={t('En el período seleccionado')}
                              //   iconName="AccountActivity"
                              // />
                              <PillFluent
                                  title={t('Emails')}
                                  iconTitle={'Mail'}
                                  series={[
                                    {
                                      label: t('En el período seleccionado'),
                                      value: emailsMMIPeriodTotalQty(),
                                      icon: 'Mail',
                                    },
                                  ]}
                                  small={false}
                              ></PillFluent>
                          )}
                        </div>

                        <div className="ms-Grid-col ms-lg6 ms-xl6">
                          {selectedProjectSummary && (
                              // <BasicChartPad
                              //   previewText={selectedProjectSummary.activitiesPeriod.hours.toFixed(2)}
                              //   mainText={t('Horas')}
                              //   secondaryText={t('En el período seleccionado')}
                              //   iconName="Clock"
                              // />
                              <PillFluent
                                  title={t('Docs. adjuntos')}
                                  iconTitle={'Attach'}
                                  series={[
                                    {
                                      label: t('En el período seleccionado'),
                                      value: emailsMMIPeriodTotalAttached(),
                                      icon: 'Attach',
                                    },
                                  ]}
                                  small={false}
                              ></PillFluent>
                          )}
                        </div>
                      </div>
                    </div>
                )}
                <div className="ms-Grid-row mt-2">
                  <div className="ms-Grid-col ms-lg12 bg-white-h100">
                    {selectedProjectCollaboratorSummary && (
                        <ProjectPanelGroupedBarCollaborator
                            collaboratorData={selectedProjectCollaboratorSummary}
                        ></ProjectPanelGroupedBarCollaborator>
                    )}
                  </div>
                </div>

                <div className="ms-Grid-row mt-2">
                  <div className="ms-Grid-col ms-lg12 ms-xl6  bg-white-h100">
                    {selectedProjectCompaniesSummary && (
                        <ProjectPanelDonutChart data={selectedProjectCompaniesSummary}></ProjectPanelDonutChart>
                    )}
                  </div>
                </div>
              </div>
            </div>
        )}
      </ViewWrapperWithCommandBar>
  );
};
