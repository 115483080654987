import * as React from 'react';
import { DetailsList, DetailsListLayoutMode, SelectionMode, IColumn } from '@fluentui/react/lib/DetailsList';
import { DetailsHeader, Icon, IDetailsListStyles, Text } from 'office-ui-fabric-react';
import i18next, { t } from 'i18next';

export interface IProjectsProgressDetailListItem {
  key: number;
  name: string;
  value: number;
}

export interface IProjectsProgressDetailListState {
  items: IProjectsProgressDetailListItem[];
}

interface ProjectsProgressDetailListProps {
  data: any[];
}

export class PanelProjectsProgressDetailList extends React.Component<
    ProjectsProgressDetailListProps,
    IProjectsProgressDetailListState
> {
  private _allItems: any[];
  private _columns: IColumn[];

  constructor(props: ProjectsProgressDetailListProps) {
    super(props);

    this._allItems = props.data;

    this._columns = [
      {
        key: 'Código',
        name: t('Proyecto Codigo'),
        fieldName: 'code',
        minWidth: 200,
        maxWidth: 200,
        isResizable: true,
      },
      {
        key: 'Proyecto',
        name: t('Proyecto nombre'),
        fieldName: 'name',
        minWidth: 200,
        maxWidth: 400,
        isResizable: true,
      },
      {
        key: 'Actividades',
        name: 'Actividades',
        fieldName: 'amount',
        minWidth: 125,
        maxWidth: 125,
        isResizable: true,
        onRenderHeader: (item: any) => {
          return (
              <span style={{ textAlign: 'center' }}>
              <Text className="fw-500" variant="medium" style={{ display: 'block' }}>
                {t('Actividades')}
              </Text>
              <Text className="fw-500" style={{ display: 'block' }}>
                {t('Totales')}
              </Text>
            </span>
          );
        },
      },
      // {
      //   key: 'Actividades confirmadas',
      //   name: t('Confirmadas'),
      //   fieldName: 'act_confirmed',
      //   minWidth: 125,
      //   maxWidth: 125,
      //   isResizable: true,
      //   onRenderHeader: (item: any) => {
      //     return (
      //       <span style={{textAlign: 'center'}}>
      //         <Text className="fw-500" variant='medium' style={{display: 'block'}}>
      //           Actividades
      //         </Text>
      //         <Text className="fw-500" style={{display: 'block'}}>
      //           Confirmadas
      //         </Text>
      //       </span>
      //     );
      //   },
      // },
      // {
      //   key: 'Actividades declinadas',
      //   name: t('Declinadas'),
      //   fieldName: 'act_declined',
      //   minWidth: 125,
      //   maxWidth: 125,
      //   isResizable: true,
      //   onRenderHeader: (item: any) => {
      //     return (
      //       <span style={{textAlign: 'center'}}>
      //         <Text className="fw-500" variant='medium' style={{display: 'block'}}>
      //           Actividades
      //         </Text>
      //         <Text className="fw-500" style={{display: 'block'}}>
      //           Declinadas
      //         </Text>
      //       </span>
      //     );
      //   },
      // },
      // {
      //   key: 'Actividades pendientes',
      //   name: t('Pendientes'),
      //   fieldName: 'act_pending',
      //   minWidth: 125,
      //   maxWidth: 125,
      //   isResizable: true,
      //   onRenderHeader: (item: any) => {
      //     return (
      //       <span style={{textAlign: 'center'}}>
      //         <Text className="fw-500" variant='medium' style={{display: 'block'}}>
      //           Actividades
      //         </Text>
      //         <Text className="fw-500" style={{display: 'block'}}>
      //           Pendientes
      //         </Text>
      //       </span>
      //     );
      //   },
      // },
      // {
      //   key: 'Horas confirmadas',
      //   name: t('Confirmadas'),
      //   fieldName: 'hs_confirmed',
      //   minWidth: 125,
      //   maxWidth: 125,
      //   isResizable: true,
      //   onRenderHeader: (item: any) => {
      //     return (
      //       <span style={{textAlign: 'center'}}>
      //         <Text className="fw-500" variant='medium' style={{display: 'block'}}>
      //           Horas
      //         </Text>
      //         <Text className="fw-500" style={{display: 'block'}}>
      //           Confirmadas
      //         </Text>
      //       </span>
      //     );
      //   },
      // },
      // {
      //   key: 'Horas pendientes',
      //   name: t('Pendientes'),
      //   fieldName: 'hs_pending',
      //   minWidth: 125,
      //   maxWidth: 125,
      //   isResizable: true,
      //   onRenderHeader: (item: any) => {
      //     return (
      //       <span style={{textAlign: 'center'}}>
      //         <Text className="fw-500" variant='medium' style={{display: 'block'}}>
      //           Horas
      //         </Text>
      //         <Text className="fw-500" style={{display: 'block'}}>
      //           Pendientes
      //         </Text>
      //       </span>
      //     );
      //   },
      // },
      // {
      //   key: 'Documentos totales',
      //   name: t('Totales'),
      //   fieldName: 'doc_total',
      //   minWidth: 125,
      //   maxWidth: 125,
      //   isResizable: true,
      //   onRenderHeader: (item: any) => {
      //     return (
      //       <span style={{textAlign: 'center'}}>
      //         <Text className="fw-500" variant='medium' style={{display: 'block'}}>
      //           Documentos
      //         </Text>
      //         <Text className="fw-500" style={{display: 'block'}}>
      //           Totales
      //         </Text>
      //       </span>
      //     );
      //   },
      // },
      // {
      //   key: 'Documentos pendientes',
      //   name: t('Pendientes'),
      //   fieldName: 'doc_pending',
      //   minWidth: 125,
      //   maxWidth: 125,
      //   isResizable: true,
      //   onRenderHeader: (item: any) => {
      //     return (
      //       <span style={{textAlign: 'center'}}>
      //       <Text className="fw-500" variant='medium' style={{display: 'block'}}>
      //         Documentos
      //       </Text>
      //       <Text className="fw-500" style={{display: 'block'}}>
      //         Pendientes
      //       </Text>
      //     </span>
      //     );
      //   },
      // },
    ];

    this.state = {
      items: this._allItems,
    };
  }

  public render(): JSX.Element {
    const { items } = this.state;

    return (
        <div>
          <DetailsList
              items={items}
              columns={this._columns}
              setKey="set"
              layoutMode={DetailsListLayoutMode.justified}
              selectionMode={SelectionMode.none}
              ariaLabelForSelectionColumn="Toggle selection"
              ariaLabelForSelectAllCheckbox="Toggle selection for all items"
              checkButtonAriaLabel="select row"
              onItemInvoked={this._onItemInvoked}
              onShouldVirtualize={() => false}
              className="two-line-header-list"
          />
        </div>
    );
  }

  private _onFilter = (ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, text: string): void => {
    this.setState({
      items: text ? this._allItems.filter((i) => i.name.toLowerCase().indexOf(text) > -1) : this._allItems,
    });
  };

  private _onItemInvoked = (item: IProjectsProgressDetailListItem): void => {
    alert(`Item invoked: ${item.name}`);
  };
}
